// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.LNG_af]: "Afrikaans",
  [keys.LNG_sq]: "Albanian",
  [keys.LNG_ar]: "Arabic",
  [keys.LNG_az]: "Azerbaijani",
  [keys.LNG_bn]: "Bengali",
  [keys.LNG_bg]: "Bulgarian",
  [keys.LNG_ca]: "Catalan",
  [keys.LNG_zh_CN]: "Chinese (CHN)",
  [keys.LNG_zh_HK]: "Chinese (HKG)",
  [keys.LNG_zh_TW]: "Chinese (TAI)",
  [keys.LNG_hr]: "Croatian",
  [keys.LNG_cs]: "Czech",
  [keys.LNG_da]: "Danish",
  [keys.LNG_nl]: "Dutch",
  [keys.LNG_en]: "English",
  [keys.LNG_en_GB]: "English (UK)",
  [keys.LNG_en_US]: "English (US)",
  [keys.LNG_et]: "Estonian",
  [keys.LNG_fil]: "Filipino",
  [keys.LNG_fi]: "Finnish",
  [keys.LNG_fr]: "French",
  [keys.LNG_de]: "German",
  [keys.LNG_el]: "Greek",
  [keys.LNG_gu]: "Gujarati",
  [keys.LNG_ha]: "Hausa",
  [keys.LNG_he]: "Hebrew",
  [keys.LNG_hi]: "Hindi",
  [keys.LNG_hu]: "Hungarian",
  [keys.LNG_id]: "Indonesian",
  [keys.LNG_ga]: "Irish",
  [keys.LNG_it]: "Italian",
  [keys.LNG_ja]: "Japanese",
  [keys.LNG_kn]: "Kannada",
  [keys.LNG_kk]: "Kazakh",
  [keys.LNG_ko]: "Korean",
  [keys.LNG_lo]: "Lao",
  [keys.LNG_lv]: "Latvian",
  [keys.LNG_lt]: "Lithuanian",
  [keys.LNG_mk]: "Macedonian",
  [keys.LNG_ms]: "Malay",
  [keys.LNG_ml]: "Malayalam",
  [keys.LNG_mr]: "Marathi",
  [keys.LNG_nb]: "Norwegian",
  [keys.LNG_fa]: "Persian",
  [keys.LNG_pl]: "Polish",
  [keys.LNG_pt_BR]: "Portuguese (BR)",
  [keys.LNG_pt_PT]: "Portuguese (POR)",
  [keys.LNG_pa]: "Punjabi",
  [keys.LNG_ro]: "Romanian",
  [keys.LNG_ru]: "Russian",
  [keys.LNG_sr]: "Serbian",
  [keys.LNG_sk]: "Slovak",
  [keys.LNG_sl]: "Slovenian",
  [keys.LNG_es]: "Spanish",
  [keys.LNG_es_AR]: "Spanish (ARG)",
  [keys.LNG_es_ES]: "Spanish (SPA)",
  [keys.LNG_es_MX]: "Spanish (MEX)",
  [keys.LNG_sw]: "Swahili",
  [keys.LNG_sv]: "Swedish",
  [keys.LNG_ta]: "Tamil",
  [keys.LNG_te]: "Telugu",
  [keys.LNG_th]: "Thai",
  [keys.LNG_tr]: "Turkish",
  [keys.LNG_uk]: "Ukrainian",
  [keys.LNG_ur]: "Urdu",
  [keys.LNG_uz]: "Uzbek",
  [keys.LNG_vi]: "Vietnamese",
  [keys.LNG_zu]: "Zulu",
};

export default translate;
