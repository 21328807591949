// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.TMP_name]: "translation_missing",
  //  [keys.TMP_cat]: "translation_missing",
  //  [keys.TMP_preview]: "translation_missing",
  //  [keys.TMP_lang]: "translation_missing",
  //  [keys.TMP_tooltipAddLang]: "translation_missing",
  //  [keys.TMP_tooltipCopy]: "translation_missing",
  //  [keys.TMP_addTemplateButtonLabel]: "translation_missing",
  //  [keys.TMP_emptyStateTitle]: "translation_missing",
  //  [keys.TMP_emptyStateHelptext]: "translation_missing",
  //  [keys.TMP_cardHeader]: "translation_missing",
  //  [keys.TMP_namePlaceholder]: "translation_missing",
  //  [keys.TMP_nameLabel]: "translation_missing",
  //  [keys.TMP_cat1]: "translation_missing",
  //  [keys.TMP_cat2]: "translation_missing",
  //  [keys.TMP_cat3]: "translation_missing",
  //  [keys.TMP_cat4]: "translation_missing",
  //  [keys.TMP_cat5]: "translation_missing",
  //  [keys.TMP_cat6]: "translation_missing",
  //  [keys.TMP_cat7]: "translation_missing",
  //  [keys.TMP_cat8]: "translation_missing",
  //  [keys.TMP_cat9]: "translation_missing",
  //  [keys.TMP_cat10]: "translation_missing",
  //  [keys.TMP_cat11]: "translation_missing",
  //  [keys.TMP_langLabel]: "translation_missing",
  //  [keys.TMP_typeLabel]: "translation_missing",
  //  [keys.TMP_type1]: "translation_missing",
  //  [keys.TMP_type2]: "translation_missing",
  //  [keys.TMP_bodyPlaceholder]: "translation_missing",
  //  [keys.TMP_blocksHelptext]: "translation_missing",
  //  [keys.TMP_header]: "translation_missing",
  //  [keys.TMP_body]: "translation_missing",
  //  [keys.TMP_footer]: "translation_missing",
  //  [keys.TMP_button]: "translation_missing",
  //  [keys.TMP_buttons]: "translation_missing",
  //  [keys.TMP_chars]: "translation_missing",
  //  [keys.TMP_vars]: "translation_missing",
  //  [keys.TMP_headerMedia1]: "translation_missing",
  //  [keys.TMP_headerMedia2]: "translation_missing",
  //  [keys.TMP_headerMedia3]: "translation_missing",
  //  [keys.TMP_headerMedia4]: "translation_missing",
  //  [keys.TMP_footerPlaceholder]: "translation_missing",
  //  [keys.TMP_headerPlaceholder]: "translation_missing",
  //  [keys.TMP_quickReply]: "translation_missing",
  //  [keys.TMP_cta]: "translation_missing",
  //  [keys.TMP_phoneNumber]: "translation_missing",
  //  [keys.TMP_url]: "translation_missing",
  //  [keys.TMP_addButtonLabel]: "translation_missing",
  //  [keys.TMP_discardButtonLabel]: "translation_missing",
  //  [keys.TMP_previewButtonLabel]: "translation_missing",
  //  [keys.TMP_buttonLabelPlaceholder]: "translation_missing",
  //  [keys.TMP_buttonVarHelpText]: "translation_missing",
  //  [keys.TMP_previewHeader1]: "translation_missing",
  //  [keys.TMP_previewHeader2]: "translation_missing",
  //  [keys.TMP_exampleValues]: "translation_missing",
  //  [keys.TMP_varEmptyStateHelptext1]: "translation_missing",
  //  [keys.TMP_varEmptyStateHelptext2]: "translation_missing",
  //  [keys.TMP_varEmptyStateHelptext3]: "translation_missing",
  //  [keys.TMP_docsLinkLabel]: "translation_missing",
  //  [keys.TMP_saveSuccess]: "translation_missing",
  //  [keys.TMP_namePatternError]: "translation_missing",
  //  [keys.TMP_backToList]: "translation_missing",
  //  [keys.TMP_backToEditor]: "translation_missing",
  //  [keys.TMP_submit]: "translation_missing",
  //  [keys.TMP_headerImgPlaceholder]: "translation_missing",
  //  [keys.TMP_buttonUrlVarPlaceholder]: "translation_missing",
  //  [keys.TMP_headerDocumentPlaceholder]: "translation_missing",
  //  [keys.TMP_tooltipDelete]: "translation_missing",
  //  [keys.TMP_deleteAllButtonLabel]: "translation_missing",
  //  [keys.TMP_deleteConfirmText]: "translation_missing",
  //  [keys.TMP_deleteAllConfirmButtonLabel]: "translation_missing",
  //  [keys.TMP_deleteAllSuccess]: "translation_missing",
  //  [keys.TMP_deleteSingleSuccess]: "translation_missing",
  //  [keys.TMP_buttonsCounter]: "translation_missing",
  //  [keys.TMP_pleaseNote]: "translation_missing",
  //  [keys.TMP_helperBulletText1]: "translation_missing",
  //  [keys.TMP_helperBulletText2]: "translation_missing",
  //  [keys.TMP_helperBulletText3]: "translation_missing",
  //  [keys.TMP_helperBulletText4]: "translation_missing",
  //  [keys.TMP_sampleValueInfoText]: "translation_missing",
  //  [keys.TMP_missingExamplesError]: "translation_missing",
  //  [keys.TMP_buttonPreviewHelptext]: "translation_missing",
  //  [keys.TMP_syncTemplates]: "translation_missing",
  //  [keys.TMP_successSyncTemplates]: "translation_missing",
  //  [keys.TMP_syncDone]: "translation_missing",
  //  [keys.TMP_justNow]: "translation_missing",
  //  [keys.TMP_lastSync]: "translation_missing",
  //  [keys.TMP_headerVideoPlaceholder]: "translation_missing",
  //  [keys.TMP_errorInvalidUrl]: "translation_missing",
  //  [keys.TMP_nameProposal]: "translation_missing",
  //  [keys.TMP_status]: "translation_missing",
  //  [keys.TMP_balance]: "translation_missing",
  //  [keys.TMP_autoRenewalStatus]: "translation_missing",
  //  [keys.TMP_autoRenewalThreshold]: "translation_missing",
  //  [keys.TMP_autoRenewalAmount]: "translation_missing",
  //  [keys.TMP_addFunds]: "translation_missing",
  //  [keys.TMP_editAutoRenewalAmount]: "translation_missing",
  //  [keys.TMP_editAutoRenewalThreshold]: "translation_missing",
  //  [keys.TMP_errorInvalidCharacter]: "translation_missing",
  //  [keys.TMP_errorImageExtensions]: "translation_missing",
  //  [keys.TMP_errorVideoExtensions]: "translation_missing",
  //  [keys.TMP_buttonAddVariable]: "translation_missing",
  //  [keys.TMP_newLineFooterWarning]: "translation_missing",
  //  [keys.TMP_emojiWarning]: "translation_missing",
  //  [keys.TMP_searchPlaceholder]: "translation_missing",
  //  [keys.TMP_cat12]: "translation_missing",
  //  [keys.TMP_cat13]: "translation_missing",
  //  [keys.TMP_cat14]: "translation_missing",
  //  [keys.TMP_tooltipReplicate]: "translation_missing",
  //  [keys.TMP_tooltipAddLangToTemplate]: "translation_missing",
  //  [keys.TMP_tooltipEditTemplate]: "translation_missing",
  //  [keys.TMP_tooltiMetaSync]: "translation_missing",
  //  [keys.TMP_ditFieldDisabled]: "translation_missing",
  //  [keys.TMP_editSuccess]: "translation_missing",
  //  [keys.TMP_editFieldDisabled]: "translation_missing",
  //  [keys.TMP_sampleRequiredValueInfoText]: "translation_missing",
  //  [keys.TMP_metaSync]: "translation_missing",
  //  [keys.TMP_tooltiMetaSync]: "translation_missing",
  //  [keys.TMP_cat15]: "translation_missing",
  //  [keys.TMP_cat16]: "translation_missing",
  //  [keys.TMP_categoryTooltip_1]: "translation_missing",
  //  [keys.TMP_categoryTooltip_2]: "translation_missing",
  //  [keys.TMP_categoryTooltip_3]: "translation_missing",
  //  [keys.TMP_categoryRejectionTooltip_1a]: "translation_missing",
  //  [keys.TMP_categoryRejectionTooltip_1b]: "translation_missing",
  //  [keys.TMP_categoryRejectionTooltip_2_embedded]: "translation_missing",
  //  [keys.TMP_categoryRejectionTooltip_2_classic]: "translation_missing",
  //  [keys.TMP_categoryRejectionTooltip_3]: "translation_missing",
  //  [keys.TMP_allowCategoryChange]: "translation_missing",
  //  [keys.TMP_headerMedia5]: "translation_missing",
  //  [keys.TMP_allowCategoryChangeTooltip_1]: "translation_missing",
  //  [keys.TMP_allowCategoryChangeTooltip_2]: "translation_missing",
  //  [keys.TMP_headerLocationPlaceholder]: "translation_missing",
  //  [keys.TMP_timeIndicatorTooltip]: "translation_missing",
  //  [keys.TMP_securityDesclaimer]: "translation_missing",
  //  [keys.TMP_expirationWarning]: "translation_missing",
  //  [keys.TMP_timeIndicator]: "translation_missing",
  //  [keys.TMP_timeIndicatorErrorMessage]: "translation_missing",
  //  [keys.TMP_textCopiedToClipboard]: "translation_missing",
  //  [keys.TMP_seeAllOptions]: "translation_missing",
};

export default translate;
