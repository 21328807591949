import * as Types from "./clients.types";
export class UIModelClientsList {
  clients: UIModelSingleClient[];
  total: number;

  constructor(response: Types.IUIResponseClientList) {
    this.clients = response.clients.map(
      (client) => new UIModelSingleClient(client)
    );
    this.total = response.total;
  }
}

export class UIModelSingleClient {
  id: string;
  name: string;
  organisation: string;
  contactInfo: UIModelContactInfo | null;
  contactUser: UIModelContactUser | null;
  metaInfo: UIModelMetaInfo | null;
  createdAt: string;
  modifiedAt: Date;
  status: string;
  enabled_for_chat_support: boolean;
  maxChannels: number;

  constructor(response: Types.IUIResponseSingleClient) {
    this.update(response);
  }

  update = (response: Types.IUIResponseSingleClient) => {
    this.id = response.id;
    this.name = response.name;
    this.organisation = response.organisation;
    this.contactInfo = response.contactInfo
      ? new UIModelContactInfo(response.contactInfo)
      : null;
    this.metaInfo = response.metaInfo
      ? new UIModelMetaInfo(response.metaInfo)
      : null;
    this.contactUser = response.contactUser
      ? new UIModelContactUser(response.contactUser)
      : null;
    this.createdAt = response.createdAt;
    this.modifiedAt = new Date(response.modifiedAt);
    this.status = response.status;
    this.enabled_for_chat_support = response.enabled_for_chat_support;
    this.maxChannels = response.maxChannels;
  };

  get formValues() {
    return {
      name: this.name,
      organisation: this.organisation,
      webpageUrl: this.contactInfo?.webpageUrl || "",
      phone: this.contactInfo?.phone || "",
      language: this.contactInfo?.language || "",
      country: this.contactInfo?.country || "",
      streetName: this.contactInfo?.streetName || "",
      city: this.contactInfo?.city || "",
      zipCode: this.contactInfo?.zipCode || "",
      contactInfoEmail: this.contactInfo?.email || "",
      businessVertical: this.metaInfo?.businessVertical || "",
      timezone: this.metaInfo?.timezone || "",
      about: this.metaInfo?.about || "",
      businessDescription: this.metaInfo?.businessDescription || "",
      useCase: this.metaInfo?.useCase || "",
      userPhone: this.contactUser?.phone || "",
      email: this.contactUser?.email || "",
      userName: this.contactUser?.name || "",
      maxChannels: this.maxChannels,
    };
  }
}

class UIModelContactInfo {
  webpageUrl: string;
  phone: string;
  language: string;
  country: string;
  streetName: string;
  city: string;
  zipCode: string;
  email: string;
  fullName: string;

  constructor(response: Types.IUIResponseContactInfo) {
    this.webpageUrl = response.webpageUrl;
    this.phone = response.phone;
    this.language = response.language;
    this.country = response.country;
    this.streetName = response.streetName;
    this.city = response.city;
    this.zipCode = response.zipCode;
    this.email = response.email;
    this.fullName = response.fullName;
  }
}

class UIModelMetaInfo {
  businessVertical: string;
  timezone: string;
  about: string;
  businessDescription: string;
  useCase: string;

  constructor(response: Types.IUIResponseMetaInfo) {
    this.businessVertical = response.businessVertical;
    this.timezone = response.timezone;
    this.about = response.about;
    this.businessDescription = response.businessDescription;
    this.useCase = response.useCase;
  }
}

class UIModelContactUser {
  phone: string;
  email: string;
  name: string;

  constructor(response: Types.IUIResponseContactUser) {
    this.phone = response.phone;
    this.email = response.email;
    this.name = response.name;
  }
}
