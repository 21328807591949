import * as React from "react";
import { withScope, captureException } from "@sentry/nextjs";
import { showReportDialog } from "@sentry/browser";
import MPStorage from "@/services/LocalStorage";
import { Body, Spacer } from "@gcp-artifact/compass/lib/components";
import Button from "@gcp-artifact/compass/lib/components/updated/button";

const ErrorScreen = ({ eventId }: { eventId: string }) => {
  return (
    <div
      style={{
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        background: "var(--snow)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          height: "100vh",
          paddingBottom: 80,
          width: 460,
          margin: "0 auto",
        }}
      >
        <Body>
          It looks like we&apos;re having some internal issues. Our team has
          been notified. If you&apos;d like to help, you can describe what
          happened below.
        </Body>
        <Spacer size="xxl" />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: 16,
          }}
        >
          <Button
            variant="primary"
            size="small"
            onClick={() => window.open(window.location.origin, "_self")}
          >
            Reload page
          </Button>
          <Button
            variant="secondary"
            size="small"
            onClick={() => showReportDialog({ eventId })}
          >
            Let us know what happened
          </Button>
        </div>
      </div>
    </div>
  );
};

class ErrorBoundary extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean; eventId: string }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false, eventId: "" };
  }

  componentDidCatch(error: Error) {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    } else {
      this.setState({ hasError: true });
      withScope(() => {
        const eventId = captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorScreen
          eventId={MPStorage.getFromLocalStorage(
            MPStorage.customKeys.ERROR_EVENT_ID
          )}
        />
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
