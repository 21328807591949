// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.WIZ_continueLabelWithHelp]:
    "Um fortzufahren, führe die erforderlichen Schritte aus",
  [keys.WIZ_billingPlanTitle]: "Wähle deinen Tarif",
  [keys.WIZ_billingMethodTitle]: "Zahlungsdetails hinzufügen",
  [keys.WIZ_billingTermsTitle]: "Allgemeinen Geschäftsbedingungen akzeptieren",
  [keys.WIZ_paymentMethodTitleStep1]: "1. Zahlungsmethode auswählen",
  [keys.WIZ_paymentMethodTitleStep2]: "2. Zur Kasse gehen",
  [keys.WIZ_paymentMethodCountrySelectLabel]:
    "Wähle dein Land aus, um die verfügbaren Zahlungsarten anzuzeigen.",
  [keys.WIZ_termsTitle]: "360dialog Geschäftsbedingungen:",
  [keys.WIZ_createNumberTitle]:
    "WhatsApp Business Account erstellen und Nummer verbinden",
  [keys.WIZ_numberWasSetUp]:
    "Deine Nummer <NUMMER> wurde erfolgreich eingerichtet.",
  [keys.WIZ_connectInboxTitle]: "Schließe dein Onboarding mit get.chat ab",
  [keys.WIZ_connectInboxText]:
    "Um deinen get.chat-Posteingang einzurichten, folge bitte den Anweisungen in der E-Mail, die du inzwischen erhalten haben solltest. Dein WhatsApp Business Account ist bereits eingerichtet und verbunden, so dass du sofort mit der Nutzung des Posteingangs beginnen kannst.",
  [keys.WIZ_goToHubButtonLabel]: "Weiter zum 360dialog Client Hub",
  [keys.WIZ_channelPageCheckboxText1]:
    "👤 Du hast ein persönliches Meta-Konto.",
  [keys.WIZ_channelPageCheckboxText2]:
    "📞 Die Nummer, mit der du dich verbinden willst, wird derzeit nicht von einem anderen WhatsApp-Konto verwendet.",
  [keys.WIZ_channelPageCheckboxText3]:
    "📲 Durch den Empfang von SMS oder internationalen Anrufen, kannst du dich als Inhaber der Nummer verifizieren.",
  [keys.WIZ_channelPageCheckboxText4]:
    "🖋️ Du hast bereits ein Meta-Geschäftskonto, das verifiziert ist.",
  [keys.WIZ_channelPageSubtitle1]:
    "Im nächsten Schritt musst du deine Konten einrichten, indem du deine Meta-Zugangsdaten verwendest und den angegebenen Schritten folgst.",
  [keys.WIZ_channelPageSubtitle2]:
    "Danach werden wir automatisch alles für dich einrichten und du kannst die WhatsApp Business API nutzen.",
  [keys.WIZ_waitingText1]:
    "Wir sind gerade dabei, deine eigene Serverinstanz einzurichten, so dass du in wenigen Minuten mit der Nutzung der WhatsApp Business API beginnen kannst.",
  [keys.WIZ_waitingText2]:
    "Wir starten deinen gehosteten WhatsApp Business API-Client.",
  [keys.WIZ_addNumberText]:
    "Optional kannst du auch <BUTTON>jetzt eine neue Nummer hinzufügen<BUTTON>.",
  [keys.WIZ_channelTitle]:
    "Stelle sicher, dass du die folgenden Voraussetzungen erfüllst.",
  [keys.WIZ_titleSpecifiedPermission]:
    "Der Partner bittet um die Erlaubnis, auf die WhatsApp Business API für die folgende Nummer zuzugreifen:",
  [keys.WIZ_titleUnspecifiedPermission]:
    "Wähle die Nummern aus, für die du dem Partner die Erlaubnis erteilen möchtest, auf die WhatsApp Business API zuzugreifen.",
  [keys.WIZ_selectedCount]: "Ausgewählte <NUMBER> Nummern",
  [keys.WIZ_numbers]: "Nummern",
  [keys.WIZ_selectNumbers]: "Nummern auswählen",
  [keys.WIZ_noPendingChannels]:
    "Für diesen Kunden gibt es keine Nummern, die zur Genehmigung anstehen",
  [keys.WIZ_verifyOwnership]: "Verifiziere die Eigentumsverhältnisse",
  [keys.WIZ_somethingWrong]:
    "Etwas ist schief gelaufen? Kontaktiere deinen Partner zur Klärung",
  [keys.WIZ_NumberVerificationTitle]:
    "Überprüfung des Eigentümerstatus der Nummer",
  [keys.WIZ_NumberVerificationDesc]:
    "Dein Partner {{Partnername}} möchte dir Zugang zu den unten aufgeführten Telefonnummern gewähren. Du hast die Möglichkeit, sie zu verwalten und über unseren 360dialog Client Hub Einblicke zu erhalten. Bitte überprüfe die Eigentümerschaft der Nummern.",
};

export default translate;
