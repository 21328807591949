// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  FAQ_q_1: "FAQ_q_1",
  FAQ_a_1: "FAQ_a_1",
  FAQ_q_2: "FAQ_q_2",
  FAQ_a_2: "FAQ_a_2",
  FAQ_q_3: "FAQ_q_3",
  FAQ_a_3: "FAQ_a_3",
  FAQ_q_4: "FAQ_q_4",
  FAQ_a_4: "FAQ_a_4",
  FAQ_q_5: "FAQ_q_5",
  FAQ_a_5: "FAQ_a_5",
  FAQ_q_6: "FAQ_q_6",
  FAQ_a_6: "FAQ_a_6",
  FAQ_q_7: "FAQ_q_7",
  FAQ_a_7: "FAQ_a_7",
};

export default keys;
