// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  APP_inbox_title: "APP_inbox_title",
  APP_inbox_step1: "APP_inbox_step1",
  APP_inbox_step2: "APP_inbox_step2",
  APP_inbox_step3: "APP_inbox_step3",
  APP_inbox_bullet1: "APP_inbox_bullet1",
  APP_inbox_bullet2: "APP_inbox_bullet2",
  APP_inbox_termsTitle: "APP_inbox_termsTitle",
  APP_apps: "APP_apps",
  APP_addApps: "APP_addApps",
  APP_browse: "APP_browse",
  APP_addDescription: "APP_addDescription",
  APP_addSubDescription: "APP_addSubDescription",
  APP_getChat: "APP_getChat",
  APP_setUp: "APP_setUp",
  APP_connectApp: "APP_connectApp",
  APP_numberRegistration: "APP_numberRegistration",
  APP_addGetChat: "APP_addGetChat",
  APP_activateInbox: "APP_activateInbox",
  APP_faq1: "APP_faq1",
  APP_answer1: "APP_answer1",
  APP_faq2: "APP_faq2",
  APP_answer2: "APP_answer2",
  APP_faq3: "APP_faq3",
  APP_answer3: "APP_answer3",
  APP_activateInboxCheckbox: "APP_activateInboxCheckbox",
  APP_captchaNewApiMessage: "APP_captchaNewApiMessage",
  APP_captchaNewApiCheckbox: "APP_captchaNewApiCheckbox",
  APP_inboxAccess: "APP_inboxAccess",
  APP_inboxCredentials: "APP_inboxCredentials",
  APP_inboxAccessClickButton: "APP_inboxAccessClickButton",
  APP_accessInbox: "APP_accessInbox",
  APP_settingUpInboxTitle: "APP_settingUpInboxTitle",
  APP_settingUpInbox: "APP_settingUpInbox",
  APP_pageTitle: "APP_pageTitle",
  APP_pageDescription: "APP_pageDescription",
  APP_notificationEmail: "APP_notificationEmail",
  APP_checkYourEmail: "APP_checkYourEmail",
  APP_emailSent: "APP_emailSent",
  APP_followSteps: "APP_followSteps",
};

export default keys;
