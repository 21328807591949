// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.TMP_name]: "Name",
  [keys.TMP_cat]: "Kategorie",
  [keys.TMP_preview]: "Vorschau",
  [keys.TMP_lang]: "Sprachen",
  [keys.TMP_tooltipAddLang]: "Sprache hinzufügen",
  [keys.TMP_tooltipCopy]: "Kopie erstellen",
  [keys.TMP_addTemplateButtonLabel]: "Neue Vorlage",
  [keys.TMP_emptyStateTitle]:
    "Du hast noch keine Nachrichtenvorlagen erstellt.",
  [keys.TMP_emptyStateHelptext]:
    "Nachrichtenvorlagen können verwendet werden um Benachrichtigungen an Leute zu schicken, welche dir die Erlaubnis erteilt haben ihnen Nachrichten zu senden. Du kannst Nachrichtenvorlagen verwenden um eine Konversation zu beginnen nachdem das 24 Stunden Fenster für Sitzungs-Nachrichten verstrichen ist.",
  [keys.TMP_cardHeader]: "Neue Vorlage",
  [keys.TMP_namePlaceholder]: "Vorlagenname",
  [keys.TMP_nameLabel]: "Name",
  [keys.TMP_cat1]: "Automatische Antwort",
  [keys.TMP_cat2]: "Konto-Update",
  [keys.TMP_cat3]: "Zahlungs-Update",
  [keys.TMP_cat4]: "Persönliches Finanz-Update",
  [keys.TMP_cat5]: "Liefer-Update",
  [keys.TMP_cat6]: "Reservierungs-Update",
  [keys.TMP_cat7]: "Problemlösung",
  [keys.TMP_cat8]: "Termin-Update",
  [keys.TMP_cat9]: "Transport-Update",
  [keys.TMP_cat10]: "Ticket-Update",
  [keys.TMP_cat11]: "Alarm-Update",
  [keys.TMP_langLabel]: "Sprache",
  [keys.TMP_typeLabel]: "Vorlagentyp",
  [keys.TMP_type1]: "Standard (nur Text)",
  [keys.TMP_type2]: "Media & Interactive",
  [keys.TMP_bodyPlaceholder]: "Tippe deinen Vorlagen-Haupttext hier...",
  [keys.TMP_blocksHelptext]: "Wähle einzelne Vorlagen-Bausteine aus.",
  [keys.TMP_header]: "Kopfzeile",
  [keys.TMP_body]: "Haupttext",
  [keys.TMP_footer]: "Fußzeile",
  [keys.TMP_button]: "Button",
  [keys.TMP_buttons]: "Buttons",
  [keys.TMP_chars]: "Zeichen",
  [keys.TMP_vars]: "Variablen",
  [keys.TMP_headerMedia1]: "Text",
  [keys.TMP_headerMedia2]: "Bild",
  [keys.TMP_headerMedia3]: "Dokument",
  [keys.TMP_headerMedia4]: "Video",
  [keys.TMP_footerPlaceholder]: "Tippe deinen Vorlagen-Fußzeilentext hier...",
  [keys.TMP_headerPlaceholder]: "Tippe deinen Vorlagen-Kopfzeilentext hier...",
  [keys.TMP_quickReply]: "Quick reply",
  [keys.TMP_cta]: "Call to action",
  [keys.TMP_phoneNumber]: "Telefonnummer",
  [keys.TMP_url]: "Url",
  [keys.TMP_addButtonLabel]: "Button hinzufügen",
  [keys.TMP_discardButtonLabel]: "Änderungen verwerfen",
  [keys.TMP_previewButtonLabel]: "Vorschau ansehen und speichern",
  [keys.TMP_buttonLabelPlaceholder]: "Tippe deinen Button-Text hier...",
  [keys.TMP_buttonVarHelpText]: "(muss am Ende des Textes sein)",
  [keys.TMP_previewHeader1]: "Vorlage",
  [keys.TMP_previewHeader2]: "Vorschau",
  [keys.TMP_exampleValues]: "Beispielwerte",
  [keys.TMP_varEmptyStateHelptext1]:
    "Du hast in deinem Text keine Variablen-Platzhalter genutzt.",
  [keys.TMP_varEmptyStateHelptext2]:
    "Um eine Variable hinzuzufügen, gehe zurück zum Editor und füge an der entsprechenden Stell {{#}} ein, wobei # den Variablen-Index bezeichnet, welcher bei {{1}} starten muss.",
  [keys.TMP_varEmptyStateHelptext3]:
    "Für mehr Informationen schaue dir bitte die Vorlagen-Manager Dokumentation an.",
  [keys.TMP_docsLinkLabel]: "Vorlagen-Manager Dokumentation",
  [keys.TMP_saveSuccess]: "Vorlage wurde erfolgreich gespeichert!",
  [keys.TMP_namePatternError]:
    "Der Name kann nur Kleinbuchstaben und Unterstriche (_) beinhalten",
  [keys.TMP_backToList]: "Zurück zur Liste",
  [keys.TMP_backToEditor]: "Zurück zum Editor",
  [keys.TMP_submit]: "Vorlage einreichen",
  [keys.TMP_headerImgPlaceholder]: "Kopfzeilen-Bild URL",
  [keys.TMP_buttonUrlVarPlaceholder]: "Button URL",
  [keys.TMP_headerDocumentPlaceholder]: "Dokument URL",
  [keys.TMP_tooltipDelete]: "Vorlage löschen",
  [keys.TMP_deleteAllButtonLabel]: "Alle Sprachen dieser Vorlage löschen",
  [keys.TMP_deleteConfirmText]:
    "Bist du dir sicher, dass du alle Sprachen für die Vorlage <TEMPLATE_NAME> löschen möchtest?",
  [keys.TMP_deleteAllConfirmButtonLabel]: "Alle löschen",
  [keys.TMP_deleteAllSuccess]:
    "Alle Vorlagensprachen wurden erfolgreich gelöscht!",
  [keys.TMP_deleteSingleSuccess]: "Vorlage wurde erfolgreich gelöscht!",
  [keys.TMP_buttonsCounter]: "Buttons",
  [keys.TMP_pleaseNote]: "Bitte beachte:",
  [keys.TMP_helperBulletText1]:
    "Wenn dieser Vorlagenname in mehreren Sprachen existiert, werden alle Sprachen gelöscht.",
  [keys.TMP_helperBulletText2]:
    "Nachrichten, die gesendet, aber noch nicht zugestellt wurden, werden noch 30 Tage lang versucht zugestellt zu werden.",
  [keys.TMP_helperBulletText3]:
    'Wenn 30 Tage nach Löschung eine Nachricht über die Nachrichtenvorlage geschickt wird, erhältst du einen "Structure Unavailable" Fehler und der Kunde wird dieine Nachricht nicht erhalten.',
  [keys.TMP_helperBulletText4]:
    "Nachdem eine Vorlage gelöscht wurde, kann der Name des vormals genehmigten Templates erst wieder nach 30 Tagen verwendet werden. Nutze einen anderen Namen um neue Vorlagen zu erstellen.",
  [keys.TMP_sampleValueInfoText]:
    "Die angegebenen Beispielwerte werden im Zuge der Vorlagen-Einreichung an Meta übermittelt. Wenn keine relevanten Beispiele bereitgestellt werden, können die Vorlagen von Meta abgelehnt werden.",
  [keys.TMP_missingExamplesError]:
    "Bitte gebe für alle deine Variablen Beispielwerte an.",
  [keys.TMP_buttonPreviewHelptext]:
    "Klicke den Button in der Vorschau an, um deine URL zu testen.",
  [keys.TMP_syncTemplates]: "Mit Meta synchronisieren",
  [keys.TMP_successSyncTemplates]:
    "Synchronisation erfolgreich! Vorlagen wurden aktualisiert!",
  [keys.TMP_syncDone]: "Vorlagen sind aktuell.",
  [keys.TMP_justNow]: "Gerade eben",
  [keys.TMP_lastSync]: "Letzte Synchronisation:",
  [keys.TMP_headerVideoPlaceholder]: "Kopfzeilen-Video URL",
  [keys.TMP_errorInvalidUrl]:
    "Das bereitgestellte Beispiel verlinkt kein gültiges Medienformat. Bitte korrigiere die URL und versuche es erneut.",
  [keys.TMP_nameProposal]: "Nutze stattdessen <PROPOSAL>",
  [keys.TMP_status]: "Status",
  [keys.TMP_balance]: "Saldo",
  [keys.TMP_autoRenewalStatus]: "Automatisches Aufladen aktiviert",
  [keys.TMP_autoRenewalThreshold]: "Grenzwert für Automatisches Aufladen",
  [keys.TMP_autoRenewalAmount]: "Betrag für Automatisches Aufladen",
  [keys.TMP_addFunds]: "Guthaben aufladen",
  [keys.TMP_editAutoRenewalAmount]: "Betrag für Automatisches Aufladen ändern",
  [keys.TMP_editAutoRenewalThreshold]:
    "Grenzwert für Automatisches Aufladen ändern",
  [keys.TMP_errorInvalidCharacter]: "Ungültiges Zeichen",
  [keys.TMP_errorImageExtensions]:
    "Erlaubte Dateierweiterungen sind JPG und PNG",
  [keys.TMP_errorVideoExtensions]:
    "Zulässige Dateierweiterungen sind MP4 und 3gpp",
  [keys.TMP_buttonAddVariable]: "Variable hinzufügen",
  [keys.TMP_newLineFooterWarning]:
    "Die Fußzeile darf keinen Zeilenumbruch enthalten.",
  [keys.TMP_emojiWarning]: "Emojis können nur im Haupttext verwendet werden",
  [keys.TMP_searchPlaceholder]: "Suche nach Namen",
  [keys.TMP_cat12]: "Transaktionsbezogen",
  [keys.TMP_cat13]: "Marketing",
  [keys.TMP_cat14]: "Einmalige Passwörter",
  [keys.TMP_tooltipReplicate]: "Vorlage replizieren",
  [keys.TMP_tooltipAddLangToTemplate]:
    "Fügen Sie einer Vorlage eine andere Sprache hinzu",
  [keys.TMP_tooltipEditTemplate]: "Vorlage bearbeiten",
  [keys.TMP_tooltiMetaSync]:
    "Dieser Status zeigt an, ob eine Vorlage bereits an Meta übermittelt wurde oder ob die letzten Änderungen an einer Vorlage noch darauf warten, übermittelt zu werden.",
  [keys.TMP_ditFieldDisabled]: "Feld kann nicht bearbeitet werden",
  [keys.TMP_editSuccess]:
    "Die Vorlage wurde erfolgreich aktualisiert! Aufgrund von Meta-Einschränkungen kann es bis zu 24 Stunden dauern, bis die Änderungen mit Meta synchronisiert werden.",
  [keys.TMP_editFieldDisabled]:
    "Dieses Feld kann nicht bearbeitet werden, die Vorlage wurde bereits übermittelt",
  [keys.TMP_sampleRequiredValueInfoText]:
    "Die angegebenen Beispielwerte werden als Teil des Vorlagenübermittlungsprozesses an Meta übermittelt. Es ist erforderlich, Beispielwerte für alle Variablen hinzuzufügen, um die Vorlage zu übermitteln.",
  [keys.TMP_metaSync]: "Synchronisierungsstatus (Meta)",
  [keys.TMP_tooltiMetaSync]:
    "Dieser Status zeigt an, ob eine Vorlage bereits an Meta übermittelt wurde oder ob die letzten Änderungen an einer Vorlage noch auf ihre Übermittlung warten.",
  [keys.TMP_cat15]: "Dienstprogramm",
  [keys.TMP_cat16]: "Authentifizierung",
  [keys.TMP_categoryTooltip_1]:
    "Meta hat allen vorhandenen Vorlagen eine <LINK>https://developers.facebook.com/docs/whatsapp/updates-to-pricing/launch-timeline?content_id=VXiW9EWvTRnVVQ4#new-template-categories | neue Vorlagenkategorie<LINK> zugewiesen, die auf ihrem Inhalt und den <LINK>https://developers.facebook.com/docs/whatsapp/updates-to-pricing/new-template-guidelines | Vorlagenrichtlinien basiert.<LINK> Dieser Vorgang betrifft nur die Kategorie einer Vorlage. Er hat keinen Einfluss auf den Status der Vorlage.",
  [keys.TMP_categoryTooltip_2]:
    "Wenn du mit der zugewiesenen Kategorie nicht einverstanden bist, kannst du die Kategorisierung bis zum 15. Mai 2023 anfechten, indem du die Vorlage auswählst und auf die Schaltfläche Kategorie bearbeiten im <LINK>Meta's WhatsApp Manager<LINK> klickst.",
  [keys.TMP_categoryTooltip_3]:
    "Um deine Vorlagen auf neue Kategorien zu replizieren, klicke bitte auf das Replizieren-Symbol rechts neben der Vorlagenkarte, ändere den Namen und die Kategorie der Vorlage und reiche sie erneut ein.",
  [keys.TMP_categoryRejectionTooltip_1a]:
    "Diese Vorlage enthält Inhalte, die nicht mit den Richtlinien von WhatsApp übereinstimmen.",
  [keys.TMP_categoryRejectionTooltip_1b]:
    "Wir empfehlen dir, die <LINK>https://developers.facebook.com/docs/whatsapp/message-templates/guidelines/ | Vorlage<LINK> und <LINK>https://developers.facebook.com/docs/whatsapp/updates-to-pricing/new-template-guidelines | Vorlagenkategorie<LINK> Richtlinien zu lesen, die Vorlage zu bearbeiten und sie erneut zur Prüfung einzureichen.",
  [keys.TMP_categoryRejectionTooltip_2_embedded]:
    "Wenn du glaubst, dass die Vorlage nicht korrekt ist, kannst du eine Überprüfung unter <LINK>Account Quality.<LINK> beantragen.",
  [keys.TMP_categoryRejectionTooltip_2_classic]:
    "Wenn du glaubst, dass dies nicht korrekt ist, kannst du eine Überprüfung beantragen",
  [keys.TMP_categoryRejectionTooltip_3]:
    "Du kannst die Vorlage auch replizieren, indem du auf das Replikat-Symbol auf der rechten Seite der Vorlagenkarte klickst, den Namen, die Kategorie (falls erforderlich) und den Inhalt der Vorlage änderst und sie erneut einreichst.",
  [keys.TMP_allowCategoryChange]: "Änderung der Kategorie zulassen",
  [keys.TMP_headerMedia5]: "Standort",
  [keys.TMP_allowCategoryChangeTooltip_1]:
    "Wenn du die Kategorieänderung aktivierst, kann Meta jede Kategorie zuweisen, die sie für angemessen hält. Wir empfehlen, diese Option zu aktivieren, um zu vermeiden, dass Vorlagen aufgrund von falscher Kategorisierung abgelehnt werden.",
  [keys.TMP_allowCategoryChangeTooltip_2]:
    "Bitte beachte, dass Vorlagen auch aus anderen Gründen abgelehnt werden können, die nichts mit ihrer Kategorisierung zu tun haben.",
  [keys.TMP_headerLocationPlaceholder]: "Standort-URL",
  [keys.TMP_timeIndicatorTooltip]:
    "Dieser Wert gibt die Anzahl der Minuten an, die das Passwort oder der Code gültig ist. Mindestens 1 und maximal 90 Minuten.",
  [keys.TMP_securityDesclaimer]: "Sicherheitshinweis",
  [keys.TMP_expirationWarning]: "Warnung vor Ablauf der Gültigkeit",
  [keys.TMP_timeIndicator]: "Zeitindikator",
  [keys.TMP_timeIndicatorErrorMessage]:
    "Der Wert muss zwischen 1 und 90 Minuten liegen.",
  [keys.TMP_textCopiedToClipboard]: "Text in die Zwischenablage kopieren",
  [keys.TMP_seeAllOptions]: "Alle Optionen anzeigen",
};

export default translate;
