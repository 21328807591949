import format from "date-fns/format";
import * as Types from "./partnerChannels.types";
import * as Channel from "../channels/channels.model";
import * as Client from "../clients/clients.model";
import * as Project from "../projects/project.model";
import * as WabaAccount from "../wabaAccount/wabaAccount.model";
import { ISettings } from "../channels/channels.model";
// import * as wabaPolicyViolations from "../wabaAccount/wabaPolicyViolations.model";

type SummaryStatuses =
  | "newDraft"
  | "wabaIsSubmited"
  | "channelIsSubmited"
  | "allApproved"
  | "atLeastOneFailed"
  | "otherStatus";

const mrPipieMessagesMapper = {
  newDraft: "Pls Review and Submit WABA Account",
  wabaIsSubmited: "Pls Review and Submit WABA Channel",
  channelIsSubmited: "Pls wait for the Approval",
  allApproved: "Yeahaak ! Your ready",
  atLeastOneFailed: "Yaiks ! Pls check WABA Account/Channel",
  otherStatus: "oops, something went wrong, unknown pipe status",
};

export class UIModelPartnerChannels {
  total: number;
  partnerChannels: UIModelPartnerChannel[];
  limit: number;
  offset: number;
  count: number;

  constructor(response: Types.IUIResponsePartnerChannels) {
    this.total = response.total;
    this.partnerChannels = response.partnerChannels.map(
      (partnerChannel) => new UIModelPartnerChannel(partnerChannel)
    );
    this.limit = response.limit;
    this.offset = response.offset;
    this.count = response.count;
  }
}

export class UIModelPartnerChannel {
  channel: Channel.UIModelChannel;
  client: Client.UIModelSingleClient;
  project: Project.UIModelProject;
  wabaAccount: WabaAccount.UIModelWabaAccount;
  setupInfo: Channel.UIModelSetupInfo;
  currentQualityRating: string | null;
  currentLimit: string | null;
  settings: ISettings | null;
  // wabaPolicyViolations:
  //   | wabaPolicyViolations.UIModelWabaPolicyViolation[]
  //   | null;
  integration: Channel.UIModelIntegration | null;
  status: string;
  dateOfModify: string;
  hasInbox: boolean;
  isOba: boolean;

  constructor(response: Types.IUIResponsePartnerChannel) {
    const { client, project, wabaAccount, ...channelResponse } = response;
    this.channel = new Channel.UIModelChannel(channelResponse);
    this.client = new Client.UIModelSingleClient(response.client);
    this.project = new Project.UIModelProject(response.project);
    this.wabaAccount = new WabaAccount.UIModelWabaAccount(response.wabaAccount);
    this.setupInfo = new Channel.UIModelSetupInfo(response.setupInfo);
    this.currentQualityRating = response.currentQualityRating;
    this.currentLimit = response.currentLimit;
    this.settings = response.settings;
    // this.wabaPolicyViolations = response.wabaPolicyViolations
    //   ? response.wabaPolicyViolations!.map(
    //       (wabaPolicyViolation) =>
    //         new wabaPolicyViolations.UIModelWabaPolicyViolation(
    //           wabaPolicyViolation
    //         )
    //     )
    //   : null;
    this.integration = response.integration
      ? new Channel.UIModelIntegration(response.integration)
      : null;
    this.status = response.status;
    this.dateOfModify = format(new Date(response.modifiedAt), "dd/MM/yyyy");
    this.hasInbox = response.hasInbox;
    this.isOba = response.isOba;
  }

  update = (response: Types.IUIResponsePartnerChannel) => {
    const {
      client,
      project,
      wabaAccount,
      wabaPolicyViolations,
      ...channelResponse
    } = response;
    this.channel.update(channelResponse);
    this.client.update(client);
    this.project.update(project);
    if (this.wabaAccount && wabaAccount) {
      this.wabaAccount.update(wabaAccount);
    }
    // if (this.wabaPolicyViolations && wabaPolicyViolations) {
    //   this.wabaPolicyViolations.map((wabaPolicyViolations) =>
    //     wabaPolicyViolations.update(wabaPolicyViolations)
    //   );
    // }
    if (this.integration && channelResponse.integration) {
      this.integration.update(channelResponse.integration);
    }
  };

  get isNewDraft() {
    return (
      this.wabaAccount &&
      this.wabaAccount.status === "created" &&
      this.channel.status === "created"
    );
  }

  get isWabaSubmitedAdnChannelIsDraft() {
    return (
      this.wabaAccount &&
      !["created", "obo_not_approved"].includes(this.wabaAccount.status) &&
      this.channel.status === "created"
    );
  }

  get isWabaSubmitedAndChannleIsSubmited() {
    return (
      this.wabaAccount &&
      !["created"].includes(this.wabaAccount.status) &&
      this.channel.status === "unverified"
    );
  }

  get isAllApproved() {
    const statusesKeysChannel = Object.keys(
      this.channel.statuses
    ) as Channel.ChannelStatuses[];
    const statusesKeysWabaAccount =
      this.wabaAccount &&
      (Object.keys(
        this.wabaAccount.statuses
      ) as WabaAccount.WabaAccountStatuses[]);
    return (
      statusesKeysWabaAccount &&
      statusesKeysWabaAccount.every(
        (status) =>
          this.wabaAccount &&
          this.wabaAccount.statuses[status].indicator === "done"
      ) &&
      statusesKeysChannel.every(
        (status) => this.channel.statuses[status].indicator === "done"
      )
    );
  }

  get isAtLeastOneFailed() {
    const statusesKeysChannel = Object.keys(
      this.channel.statuses
    ) as Channel.ChannelStatuses[];
    const statusesKeysWabaAccount =
      this.wabaAccount &&
      (Object.keys(
        this.wabaAccount.statuses
      ) as WabaAccount.WabaAccountStatuses[]);
    return (
      (statusesKeysWabaAccount &&
        statusesKeysWabaAccount.some(
          (status) =>
            this.wabaAccount &&
            this.wabaAccount.statuses[status].indicator === "rejected"
        )) ||
      statusesKeysChannel.some(
        (status) => this.channel.statuses[status].indicator === "rejected"
      )
    );
  }

  get summmaryStatus(): SummaryStatuses {
    switch (true) {
      case this.isNewDraft: {
        return "newDraft";
      }
      case this.isWabaSubmitedAdnChannelIsDraft: {
        return "wabaIsSubmited";
      }
      case this.isWabaSubmitedAndChannleIsSubmited: {
        return "channelIsSubmited";
      }
      case this.isAllApproved: {
        return "allApproved";
      }
      case this.isAtLeastOneFailed: {
        return "atLeastOneFailed";
      }
      default: {
        return "otherStatus";
      }
    }
  }

  get mrPipieMessage(): string {
    return mrPipieMessagesMapper[this.summmaryStatus];
  }
}

export class UIModelPartnerChannelV1 extends UIModelPartnerChannel {
  constructor(response: Types.IUIResponsePartnerChannelV1) {
    const { partner, ...rest } = response;
    super(rest);
  }
}

export class UIModelPartnerChannelsV1 {
  total: number;
  partnerChannels: UIModelPartnerChannelV1[];

  constructor(response: Types.IUIResponsePartnerChannelsV1) {
    this.total = response.total;
    this.partnerChannels = response.partnerChannels.map(
      (partnerChannel) => new UIModelPartnerChannelV1(partnerChannel)
    );
  }
}
