import useAdminStore from "@/services/store/useAdminStore";

export const useAcl = () => {
  const { adminStore } = useAdminStore();
  const { currentUser, currentPartner } = adminStore;

  return {
    permissions: currentUser?.permissions,
    partnerId: currentPartner?.id,
  };
};
