// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  MIG_progressTitle: "MIG_progressTitle",
  MIG_progressStep1: "MIG_progressStep1",
  MIG_progressStep2: "MIG_progressStep2",
  MIG_progressStep3: "MIG_progressStep3",
  MIG_numberHintTitle: "MIG_numberHintTitle",
  MIG_numberhintText1: "MIG_numberhintText1",
  MIG_numberhintText2: "MIG_numberhintText2",
  MIG_mobTitle: "MIG_mobTitle",
  MIG_mobText: "MIG_mobText",
  MIG_openRequestsButtonLabel: "MIG_openRequestsButtonLabel",
  MIG_alreadyAcceptedMob: "MIG_alreadyAcceptedMob",
  MIG_pleaseReturnAfterMob: "MIG_pleaseReturnAfterMob",
  MIG_mobCheckboxLabel: "MIG_mobCheckboxLabel",
  MIG_checkStatusButtonLabel: "MIG_checkStatusButtonLabel",
  MIG_checkAgainIn: "MIG_checkAgainIn",
  MIG_mobSuccessText: "MIG_mobSuccessText",
  MIG_continueMobButtonLabel: "MIG_continueMobButtonLabel",
  MIG_verifyTitle: "MIG_verifyTitle",
  MIG_verifyText1: "MIG_verifyText1",
  MIG_verifyText2: "MIG_verifyText2",
  MIG_verifyRadioLabel: "MIG_verifyRadioLabel",
  MIG_verifyRadioOptionText: "MIG_verifyRadioOptionText",
  MIG_verifyRadioOptionVoice: "MIG_verifyRadioOptionVoice",
  MIG_verifySelectInfo: "MIG_verifySelectInfo",
  MIG_triggerRegistrationButtonLabel: "MIG_triggerRegistrationButtonLabel",
  MIG_codeWillBeSent: "MIG_codeWillBeSent",
  MIG_alreadyHaveCode: "MIG_alreadyHaveCode",
  MIG_codeWasSent: "MIG_codeWasSent",
  MIG_codeInputLabel: "MIG_codeInputLabel",
  MIG_submitCodeButtonLabel: "MIG_submitCodeButtonLabel",
  MIG_requestNewCodeIn: "MIG_requestNewCodeIn",
  MIG_requestNewCodeButtonLabel: "MIG_requestNewCodeButtonLabel",
  MIG_errorHint: "MIG_errorHint",
  MIG_setupTitle: "MIG_setupTitle",
  MIG_setupText1: "MIG_setupText1",
  MIG_setupText2: "MIG_setupText2",
  MIG_registrationFailed: "MIG_registrationFailed",
  MIG_errorMobTitle: "MIG_errorMobTitle",
  MIG_errorMobText: "MIG_errorMobText",
  MIG_errorMobInfo: "MIG_errorMobInfo",
  MIG_error2faTitle: "MIG_error2faTitle",
  MIG_error2faText: "MIG_error2faText",
  MIG_error2faInfo: "MIG_error2faInfo",
  MIG_bspDialogTitle: "MIG_bspDialogTitle",
  MIG_bspDialogText: "MIG_bspDialogText",
  MIG_buttonLabelRetrySetup: "MIG_buttonLabelRetrySetup",
  MIG_fbErrorNewNumber: "MIG_fbErrorNewNumber",
  MIG_fbErrorAlreadyPorted: "MIG_fbErrorAlreadyPorted",
  MIG_fbErrorDifferentBusiness: "MIG_fbErrorDifferentBusiness",
  MIG_fbErrorUnknown: "MIG_fbErrorUnknown",
  MIG_fbErrorInvalidNumber: "MIG_fbErrorInvalidNumber",
  MIG_fbErrorNotVerified: "MIG_fbErrorNotVerified",
  MIG_fbErrorWabaNotCorrectlySetup: "MIG_fbErrorWabaNotCorrectlySetup",
  MIG_fbErrorWaitNewCode: "MIG_fbErrorWaitNewCode",
  MIG_importNumber: "MIG_importNumber",
  MIG_enterNumber: "MIG_enterNumber",
  MIG_selectWaba: "MIG_selectWaba",
  MIG_selectWabaDescription: "MIG_selectWabaDescription",
  MIG_createWabaTitle: "MIG_createWabaTitle",
  MIG_createWabaDescription: "MIG_createWabaDescription",
  MIG_createWabaWarning: "MIG_createWabaWarning",
  MIG_createWabaSteps: "MIG_createWabaSteps",
  MIG_createWabaFollowSteps: "MIG_createWabaFollowSteps",
  MIG_createWabaStep1: "MIG_createWabaStep1",
  MIG_createWabaStep1Description: "MIG_createWabaStep1Description",
  MIG_createWabaStep2: "MIG_createWabaStep2",
  MIG_createWabaStep2Description: "MIG_createWabaStep2Description",
  MIG_createWabaStep3: "MIG_createWabaStep3",
  MIG_createWabaStep3Description: "MIG_createWabaStep3Description",
  MIG_createWabaError: "MIG_createWabaError",
  MIG_proceedInClientHub: "MIG_proceedInClientHub",
  MIG_numberMigrationTitle: "MIG_numberMigrationTitle",
  MIG_proceedMigrationText1: "MIG_proceedMigrationText1",
  MIG_proceedMigrationText2: "MIG_proceedMigrationText2",
  MIG_continueWithMigration: "MIG_continueWithMigration",
  MIG_migrateToDifferentWaba: "MIG_migrateToDifferentWaba",
  MIG_migrateSuccess: "MIG_migrateSuccess",
  MIG_validCode: "MIG_validCode",
  MIG_invalidCode: "MIG_invalidCode",
  MIG_verifyOwnership: "MIG_verifyOwnership",
  MIG_resendCode: "MIG_resendCode",
  MIG_requestNewCode: "MIG_requestNewCode",
  MIG_migrateNumber: "MIG_migrateNumber",
  MIG_importOtherBSP: "MIG_importOtherBSP",
  MIG_importTo360dialog: "MIG_importTo360dialog",
  MIG_importFromOtherBSP: "MIG_importFromOtherBSP",
  MIG_phoneNumberDescription: "MIG_phoneNumberDescription",
  MIG_accessMetaBusinessManager: "MIG_accessMetaBusinessManager",
  MIG_howCanIFindIt: "MIG_howCanIFindIt",
  MIG_howCanIFindItText: "MIG_howCanIFindItText",
  MIG_provideWabaId: "MIG_provideWabaId",
  MIG_migrateExisting: "MIG_migrateExisting",
  MIG_repeatProcess: "MIG_repeatProcess",
  MIG_settingUpNumber: "MIG_settingUpNumber",
  MIG_channelNotReady: "MIG_channelNotReady",
  MIG_disabled2faCheckbox: "MIG_disabled2faCheckbox",
  MIG_disable2faStepOne: "MIG_disable2faStepOne",
  MIG_disable2faStepTwo: "MIG_disable2faStepTwo",
  MIG_disable2fa: "MIG_disable2fa",
  MIG_disable2faDescription: "MIG_disable2faDescription",
  MIG_disable2faFollowSteps: "MIG_disable2faFollowSteps",
  MIG_businessVerifiedCheckbox: "MIG_businessVerifiedCheckbox",
  MIG_howDoIDisable2fa: "MIG_howDoIDisable2fa",
  MIG_importFromOtherProvider: "MIG_importFromOtherProvider",
  MIG_ifYouCannotFindWabaId: "MIG_ifYouCannotFindWabaId",
  MIG_clickToCreateWabaAccount: "MIG_clickToCreateWabaAccount",
  MIG_createWabaAccount: "MIG_createWabaAccount",
  MIG_iHaveCreatedTheWabaACcount: "MIG_iHaveCreatedTheWabaACcount",
  MIG_youNeedToVerify: "MIG_youNeedToVerify",
  MIG_verifyOwnershipTitle: "MIG_verifyOwnershipTitle",
  MIG_verifyNumber: "MIG_verifyNumber",
  MIG_continueWithEs: "MIG_continueWithEs",
  MIG_shareWabaWithUs1: "MIG_shareWabaWithUs1",
  MIG_shareWabaWithUs2: "MIG_shareWabaWithUs2",
  MIG_shareWabaWithUs3: "MIG_shareWabaWithUs3",
  MIG_shareWabaWithUs4: "MIG_shareWabaWithUs4",
  MIG_somethingWentWrongEs: "MIG_somethingWentWrongEs",
  MIG_disabledPlanInfo: "MIG_disabledPlanInfo",
  MIG_missingPlans: "MIG_missingPlans",
};

export default keys;
