import * as Types from "./channels.types";
import nullWhenEmptyString from "@/utilities/nullWhenEmptyString";
import mapToUndefinied from "@/utilities/mapToUndefinied";
import { unpackIntegration } from "../waba/waba.mapper";

export const unpackChannels = (
  response: Types.IServerResponseChannels
): Types.IUIResponseChannels => ({
  channels: response.channels.map((channel) => unpackChannel(channel)),
  total: response.total,
});

export const unpackChannel = (
  response: Types.IServerResponseChannel
): Types.IUIResponseChannel => ({
  id: response.id,
  type: response.type,
  partnerId: response.partner_id,
  clientId: response.client_id,
  projectId: response.project_id,
  status: response.status,
  integrationId: response.integration_id || "",
  integration: response.integration
    ? unpackIntegration(response.integration)
    : undefined,
  wabaAccountId: response.waba_account_id,
  externalId: response.external_id || "",
  consents: unpackConsents(response.consents),
  profileInfo: unpackProfileInfo(response.profile_info),
  setupInfo: unpackSetupInfo(response.setup_info),
  createdAt: response.created_at,
  modifiedAt: response.modified_at,
  redirectUrl: response.redirect_url ?? null,
  billingStartedAt: response.billing_started_at,
  version: response.version,
  accountMode: response.account_mode || "",
  cancelledAt: response.cancelled_at ?? null,
  terminatedAt: response.terminated_at ?? null,
  isMigrated: response.is_migrated ? response.is_migrated : false,
  metaStatus: response.meta_status,
  currentLimit: response.current_limit,
  currentQualityRating: response.current_quality_rating,
  hasInbox: response.has_inbox || false,
  isOba: response.is_oba || false,
  settings: response.settings,
  lastUpgradeAt: response.last_upgrade_at,
  lastDowngradeAt: response.last_downgrade_at,
  currentBalance: response.current_balance,
});

export const unpackProfileInfo = (
  response: Types.IServerResponseProfileInfo
): Types.IUIResponseProfileInfo => ({
  pictureUrl: response?.picture_url || "",
  aboutText: response?.about_text || "",
  businessVertical: response?.business_vertical || "",
  businessDescription: response?.business_description || "",
  useCaseDescription: response?.use_case_description || "",
  streetName: response?.street_name || "",
  city: response?.city || "",
  country: response?.country || "",
  zipCode: response?.zip_code || "",
  contactEmail: response?.contact_email || "",
  webpageUrl: response?.webpage_url || "",
});

export const unpackSetupInfo = (
  response: Types.IServerResponseSetupInfo
): Types.IUIResponseSetupInfo => ({
  phoneNumber: response.phone_number,
  verificationMethod: response.verification_method,
  defaultLanguage: response.default_language,
  wasInUse: response.was_in_use ?? false,
  ivr: response.ivr ?? false,
  certificate: response.certificate || "",
  phoneName: response.phone_name,
  hasIntegrationLayer: response.has_integration_layer || false,
});

export const unpackConsents = (
  response: Types.IServerResponseConsents
): Types.IUIResponseConsents => ({
  signedAt: response.signed_at ?? null,
  note: response.note || "",
  url: response.url ?? null,
});

export const packChannel = (
  request: Partial<Types.ChannelFormValues>
): Types.ServerRequestChannel => {
  const isProfileInfo =
    [
      request.pictureUrl,
      request.aboutText,
      request.businessVertical,
      request.businessDescription,
      request.useCaseDescription,
      request.streetName,
      request.city,
      request.country,
      request.zipCode,
      request.contactEmail,
      request.webpageUrl,
    ].filter(Boolean).length > 0;

  const isSetupInfo =
    [
      request.phoneNumber,
      request.verificationMethod,
      request.defaultLanguage,
      request.wasInUse,
      request.ivr,
      request.phoneName,
      request.certificate,
    ].filter((value) => value !== undefined || null).length > 0;

  return {
    waba_account_id: request.wabaAccountId,
    type: request.type,
    profile_info: isProfileInfo
      ? {
          picture_url: mapToUndefinied(request.pictureUrl),
          about_text: mapToUndefinied(request.aboutText),
          business_vertical: mapToUndefinied(request.businessVertical),
          business_description: mapToUndefinied(request.businessDescription),
          use_case_description: mapToUndefinied(request.useCaseDescription),
          street_name: mapToUndefinied(request.streetName),
          city: mapToUndefinied(request.city),
          country: mapToUndefinied(request.country),
          zip_code: mapToUndefinied(request.zipCode),
          contact_email: mapToUndefinied(request.contactEmail),
          webpage_url: mapToUndefinied(request.webpageUrl),
        }
      : undefined,
    setup_info: isSetupInfo
      ? {
          phone_number: mapToUndefinied(request.phoneNumber),
          verification_method: mapToUndefinied(request.verificationMethod),
          default_language: mapToUndefinied(request.defaultLanguage),
          was_in_use: request.wasInUse,
          ivr: request.ivr,
          phone_name: mapToUndefinied(request.phoneName),
          certificate: mapToUndefinied(request.certificate),
        }
      : undefined,
  };
};

export const packAdminChannel = (
  request: Partial<Types.ChannelFormValues>
): Types.ServerRequestChannel => {
  return {
    is_oba: request.isOba,
  };
};

export const packChannelConsents = (
  request: Types.IUIRequestChannelConsents
): Types.IServerRequestChannelConsents => ({
  signed_at: `${new Date(request.signedAt).toISOString().split(".")[0]}Z`,
  note: nullWhenEmptyString(request.note),
});

export const packUpdateBillingFinish = (
  request: Types.IUpdateBillingFinishPayload
): Types.IServerRequestUpdateBillingFinish => ({
  // billing_started_at: request.billingStartedAt ? `${new Date(request.billingStartedAt).toISOString().split('.')[0]}Z` : undefined,
  // terminated_at: request.terminatedAt ? `${new Date(request.terminatedAt).toISOString().split('.')[0]}Z` : undefined,
  finish_date: request.finishDate
    ? `${new Date(request.finishDate).toISOString().split(".")[0]}Z`
    : undefined,
});

export const packUpdateBillingStart = (
  request: Types.IUpdateBillingStartPayload
): Types.IServerRequestUpdateBillingStart => ({
  start_date: request.startDate
    ? `${new Date(request.startDate).toISOString().split(".")[0]}Z`
    : undefined,
});

export const unpackWaba = (
  response: Types.IServerResponseChannel
): Types.IUIWhatsappProfile => ({
  about: response.profile_info.about_text,
  address: response.profile_info.full_address,
  description: response.profile_info.business_description,
  email: response.profile_info.contact_email,
  vertical: response.profile_info.business_vertical,
  website: response.profile_info.webpage_url,
});

export const unpackMigrateChannel = (
  data: Types.IServerResponseMigrateChannel
): {
  clientId: string;
  wabaAccountId: string;
  channelId: string;
  projectId: string;
} => ({
  clientId: data.client_id,
  wabaAccountId: data.waba_account_id,
  channelId: data.channel_id,
  projectId: data.project_id,
});

export const unpackPermissions = (
  response: Types.IResponseChannelsPermissions
): { partnerRedirectUrl: string } => ({
  partnerRedirectUrl: response.partner_redirect_url,
});

export const unpackPermissionsList = (
  response: Types.IListGrantedChannels
): Types.IUIListGrantedChannels => ({
  id: response.id,
  accountMode: response.account_mode,
  billingStartedAt: response.billing_started_at,
  cancelledAt: response.cancelled_at,
  clientId: response.client_id,
  consents: response.consents,
  consentsSignedAt: response.consents_signed_at,
  createdAt: response.created_at,
  createdBy: response.created_by,
  externalId: response.external_id,
  integrationId: response.integration_id,
  isMigrated: response.is_migrated,
  metaStatus: response.meta_status,
  modifiedAt: response.modified_at,
  modifiedBy: response.modified_by,
  onSupport: response.on_support,
  partnerId: response.partner_id,
  profileInfo: response.profile_info,
  projectId: response.project_id,
  setupInfo: response.setup_info,
  status: response.status,
  terminatedAt: response.terminated_at,
  type: response.type,
  version: response.version,
  wabaAccountId: response.waba_account_id,
  currentLimit: response.current_limit,
  currentQualityRating: response.current_quality_rating,
});

export const unpackProfile = (
  response: Types.IServerResponseWhatsappProfile
): Types.IServerResponseWhatsappProfile => ({
  about: response.about || "",
  address: response.address || "",
  description: response.description || "",
  email: response.email || "",
  photo: response.photo || "",
  vertical: response.vertical || "",
  websites: response.websites || [],
});
