// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.FAQ_q_1]:
    "What is Meta Business verification and why do I need to do it?",
  [keys.FAQ_a_1]:
    "Business verification confirms that a Business Manager account belongs to an actual business or organization. Not all businesses need to go through business verification. You’ll only need to verify your business if you want to access certain Meta technologies or developer features.",
  [keys.FAQ_q_2]: "Which documents do I need for Meta Business verification?",
  [keys.FAQ_a_2]:
    "In our <DOCS>, we provide a list of all documents required for Meta Business Verification.",
  [keys.FAQ_q_3]: "Can I continue using the WhatsApp Business App?",
  [keys.FAQ_a_3]:
    "Once you have completed the signup process with 360dialog, we will create a WhatsApp Business API account for the chosen number, and you will not be able to downgrade to the WhatsApp Business App.",
  [keys.FAQ_q_4]:
    "I did not receive the SMS with the verification key. Why did this happen?",
  [keys.FAQ_a_4]:
    "To begin, please double check if you entered the correct phone number. If you're still not receiving the key it could be the case, that your number currently is already connected to either a private WhatsApp Profile or the WhatsApp Business App.",
  [keys.FAQ_q_5]: "I can't see the option to create my API key. Where is it?",
  [keys.FAQ_a_5]:
    "If your integration is running, you should always be able to create an API key. If you can't find the button either on the WhatsApp Accounts pager or the number's details page, there is a chance that your integration is not fully set up yet.",
  [keys.FAQ_q_6]:
    "What is message forwarding to the partner and why do I need to enable it? ",
  [keys.FAQ_a_6]:
    "If you see the option to forward your messages to your partner, you've most likely registered a product with a partner company, which needs to receive your messages in order to function as intended. Only you can give permission to the partner to receive these messages by enabling the message forwarding.",
  //  [keys.FAQ_q_7]: "translation_missing",
  [keys.FAQ_a_7]:
    "If you have registered a product with an integration partner an API is not required anymore. The only thing you need to do, is to enable the forwarding of your messages to your partner. If you're building an application on your own and therefore need to create an API key, please contact our support team.",
};

export default translate;
