// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.BOX_fetchingChannels]: "Telefonnummern werden gesucht...",
  [keys.BOX_setUpInbox]: "Setze deine get.chat Inbox auf",
  [keys.BOX_loadingText]: "Weiterleitung zur get.chat Inbox",
  [keys.BOX_selectTheNumbers]:
    "Dein Konto inkludiert eine gratis Inbox, bereitgestellt durch unseren Partner get.chat. Um damit zu starten, wähle die Nummern, welche du mit der Inbox verknüpfen möchtest. Du wirst danach gefragt ein get.chat Konto zu erstellen.",
  [keys.BOX_apiKeyWarning]:
    "Bitte sei dir bewusst, dass du für diese Nummern keinen API Schlüssel mehr erstellen kannst. Bestehende API Schlüssel werden unbrauchbar. Um API Funktionalitäten zu nutzen kannst du allerdings jederzeit auf die tget.chat WhatsApp API zugreifen.",
  [keys.BOX_createBtn]: "Erstelle meine get.chat Inbox",
  [keys.BOX_selectInboxLabel]: "Wähle welche Inbox du öffnen möchtest.",
  [keys.BOX_buttonLabelConnectNew]: "Weitere Nummer verbinden",
  [keys.BOX_disclaimerOneNumber]:
    "Aktuell kannst du nur eine Nummer pro Vorgang verbinden. Wähle die Nummer aus und speichere deine Änderungen. Danach kannst du den Prozess für weitere Nummern wiederholen.",
};

export default translate;
