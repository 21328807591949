// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.NUM_manageAccount]: "Gerencie sua conta WhatsApp Business",
  [keys.NUM_details]: "Detalhes",
  [keys.NUM_accountName]: "Nome da conta",
  [keys.NUM_dataStorageRegions]: "Regiões de armazenamento de dados",
  [keys.NUM_cloudApiNumbers]: "Disponível apenas para números com Cloud API",
  [keys.NUM_qualityRating]: "Classificação de qualidade",
  [keys.NUM_low]: "Baixo",
  [keys.NUM_medium]: "Médio",
  [keys.NUM_high]: "Alto",
  [keys.NUM_messageLimit]: "Limite de mensagens",
  [keys.NUM_newConversationsPerDay]: "novas conversas por dia",
  [keys.NUM_unlimited]: "Ilimitado",
};

export default translate;
