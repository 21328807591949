import * as Types from "./jiraTicket.types";

const extractNumber = (description: string) => {
  let numberIndex, newLineInd, numberString;
  if (
    description.startsWith("Message from 360dialog") ||
    description.startsWith("Request")
  ) {
    numberIndex = description.search("Number:");
    numberIndex += 7;
    newLineInd = description.substring(numberIndex).indexOf("\n");
    numberString = description
      .substring(numberIndex, numberIndex + newLineInd)
      .trim();
    numberString =
      numberString[numberString.length - 1] === ","
        ? numberString.substring(0, numberString.length - 1)
        : numberString;
  } else if (
    description.startsWith("Display name change requested") ||
    description.startsWith("Display name declined for client")
  ) {
    numberIndex = description.search("Phone number:");
    numberIndex += 13;
    newLineInd = description.substring(numberIndex).indexOf("\n");
    numberString = description
      .substring(numberIndex, numberIndex + newLineInd)
      .trim();
    numberString =
      numberString[numberString.length - 1] === ","
        ? numberString.substring(0, numberString.length - 1)
        : numberString;
  } else if (description.startsWith("Number billing update")) {
    numberIndex = description.search("number:");
    numberIndex += 7;
    newLineInd = description.substring(numberIndex).indexOf("\n");
    numberString = description
      .substring(numberIndex, numberIndex + newLineInd)
      .trim();
    numberString =
      numberString[numberString.length - 1] === ","
        ? numberString.substring(0, numberString.length - 1)
        : numberString;
  } else if (
    description.startsWith("Official Business Account Application") ||
    description.startsWith("Client ID")
  ) {
    numberIndex = description.search("Phone number:");
    numberIndex += 13;
    newLineInd = description.substring(numberIndex).indexOf("\n");
    numberString = description
      .substring(numberIndex, numberIndex + newLineInd)
      .trim();
    numberString =
      numberString[numberString.length - 1] === ","
        ? numberString.substring(0, numberString.length - 1)
        : numberString;
  } else if (description.startsWith("Trigger registration - Request IVR")) {
    numberIndex = description.search("phone number:");
    numberIndex += 13;
    newLineInd = description.substring(numberIndex).indexOf("\n");
    numberString = description
      .substring(numberIndex, numberIndex + newLineInd)
      .trim();
    numberString =
      numberString[numberString.length - 1] === ","
        ? numberString.substring(0, numberString.length - 1)
        : numberString;
  } else {
    return "N/A";
  }

  return numberString || "N/A";
};

export class UIModelTicket {
  fields: Types.IUITicketFields;
  id: Types.IUIResponseTicket["id"];
  key: Types.IUIResponseTicket["key"];
  self: Types.IUIResponseTicket["self"];
  expand: Types.IUIResponseTicket["expand"];

  constructor(response: Types.IUIResponseTicket) {
    const number = response.fields.description
      ? extractNumber(response.fields.description)
      : "";

    this.fields = {
      ...response.fields,
      number,
    };
    this.id = response.id;
    this.key = response.key;
    this.self = response.self;
  }
}

export class UIModelTickets {
  maxResults?: Types.IUIResponseTickets["maxResults"];
  startAt?: Types.IUIResponseTickets["startAt"];
  total: number;
  issues: Array<Types.IUIResponseTicket>;

  constructor(response: Types.IUIResponseTickets) {
    this.maxResults = response.maxResults;
    this.startAt = response.startAt;
    this.total = response.total;
    this.issues = response.issues.map(
      (issue: Types.IUIResponseTicket) => new UIModelTicket(issue)
    );
  }
}
