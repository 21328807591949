// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.BOX_fetchingChannels]: "Buscando números...",
  [keys.BOX_setUpInbox]: "Configure seu inbox get.chat",
  [keys.BOX_loadingText]: "Encaminhando para seu inbox get.chat",
  [keys.BOX_selectTheNumbers]:
    "Sua conta inclui uma caixa de entrada gratuita, impulsionada por nosso partner get.chat. Para começar, selecione abaixo os números que você deseja conecatar a seu inbox.",
  [keys.BOX_apiKeyWarning]:
    "Esteja ciente de que você não conseguirá gerar uma chave API para estes números e, portanto, não poderá usar a API para WhatsApp da 360dialog. Você pode usar as funcionalidades da API através da API de WhatsApp da get.chat.",
  [keys.BOX_createBtn]: "Criar meu inbox get.chat",
  [keys.BOX_selectInboxLabel]: "Selecione o inbox, aquele que deseja abrir",
  [keys.BOX_buttonLabelConnectNew]: "Conecte um novo número",
  [keys.BOX_disclaimerOneNumber]:
    "Neste momento você só pode adicionar um número de cada vez. Selecione um número para conectar e continuar salvando suas mudanças. Para adicionar números extra, repita o processo.",
};

export default translate;
