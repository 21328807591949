import styled from "styled-components";

export const LayoutContainer = styled.div.attrs({
  className: "LayoutContainer",
})`
  display: flex;
  flex-direction: column;
  background: var(--background);
  min-height: 100vh;
`;

export const MainContentContainer = styled.div.attrs({
  className: "MainContentContainer",
})`
  background: var(--background);
  flex: 1;
  overflow: hidden;
`;
