// File generated automatically. Do not edit manually.

import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.INT_integrations]: "Integraciones",
  [keys.INT_syncEvents]: "Sincroniza eventos con tus aplicaciones favoritas",
  //  [keys.INT_shopifyApp]: "translation_missing",
  //  [keys.INT_connectCTADescription]: "translation_missing",
  //  [keys.INT_connect]: "translation_missing",
  //  [keys.INT_supperChargingInsightDescription]: "translation_missing",
  [keys.INT_suggestIntegarationsDescription]:
    "¿Hay otras integraciones que te gustaría ver? ¡Háznoslo saber completando el formulario!",
  [keys.INT_suggestIntegarations]: "Sugerir nueva integración",
  //  [keys.INT_connectedApps]: "translation_missing",
  //  [keys.INT_upgrade]: "translation_missing",
  //  [keys.INT_shopify]: "translation_missing",
  [keys.INT_shopifyDescription]:
    "Rastrea el evento de compra en tu tienda Shopify y atribuye este evento a la conversación adecuada de WhatsApp.",
  //  [keys.INT_appNotAvailable]: "translation_missing",
  [keys.INT_comingSoon]: "Próximamente",
  // [keys.INT_install]: "translation_missing",
};

export default translate;
