// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  WIZ_continueLabelWithHelp: "WIZ_continueLabelWithHelp",
  WIZ_billingPlanTitle: "WIZ_billingPlanTitle",
  WIZ_billingMethodTitle: "WIZ_billingMethodTitle",
  WIZ_billingTermsTitle: "WIZ_billingTermsTitle",
  WIZ_paymentMethodTitleStep1: "WIZ_paymentMethodTitleStep1",
  WIZ_paymentMethodCountrySelectLabel: "WIZ_paymentMethodCountrySelectLabel",
  WIZ_termsTitle: "WIZ_termsTitle",
  WIZ_numberWasSetUp: "WIZ_numberWasSetUp",
  WIZ_connectInboxTitle: "WIZ_connectInboxTitle",
  WIZ_connectInboxText: "WIZ_connectInboxText",
  WIZ_goToHubButtonLabel: "WIZ_goToHubButtonLabel",
  WIZ_channelPageCheckboxText1: "WIZ_channelPageCheckboxText1",
  WIZ_channelPageCheckboxText3: "WIZ_channelPageCheckboxText3",
  WIZ_channelPageSubtitle1: "WIZ_channelPageSubtitle1",
  WIZ_channelPageSubtitle2: "WIZ_channelPageSubtitle2",
  WIZ_waitingText1: "WIZ_waitingText1",
  WIZ_waitingText2: "WIZ_waitingText2",
  WIZ_channelTitle: "WIZ_channelTitle",
  WIZ_titleSpecifiedPermission: "WIZ_titleSpecifiedPermission",
  WIZ_titleUnspecifiedPermission: "WIZ_titleUnspecifiedPermission",
  WIZ_saveSelection: "WIZ_saveSelection",
  WIZ_grantPermission: "WIZ_grantPermission",
  WIZ_optional: "WIZ_optional",
  WIZ_successTitle: "WIZ_successTitle",
  WIZ_successSubtitle1: "WIZ_successSubtitle1",
  WIZ_successSubtitle2: "WIZ_successSubtitle2",
  WIZ_successMainTitle: "WIZ_successMainTitle",
  WIZ_successTitleWithoutBrandName: "WIZ_successTitleWithoutBrandName",
  WIZ_successTitle1WithoutBrandName: "WIZ_successTitle1WithoutBrandName",
  WIZ_permissionsPageWarningMessage: "WIZ_permissionsPageWarningMessage",
  WIZ_addThisNumber: "WIZ_addThisNumber",
  WIZ_addSelected: "WIZ_addSelected",
  WIZ_selectPlanTitle: "WIZ_selectPlanTitle",
  WIZ_selectPlanSubtitle: "WIZ_selectPlanSubtitle",
  WIZ_addNumberText: "WIZ_addNumberText",
  WIZ_selectedCount: "WIZ_selectedCount",
  WIZ_selectedCountNewNumbers: "WIZ_selectedCountNewNumbers",
  WIZ_welcomeToWABA: "WIZ_welcomeToWABA",
  WIZ_facebookAndWhatsAppBusAccount: "WIZ_facebookAndWhatsAppBusAccount",
  WIZ_accountsSuccessfullyCreated: "WIZ_accountsSuccessfullyCreated",
  WIZ_authorizePartnerApp: "WIZ_authorizePartnerApp",
  WIZ_numbers: "WIZ_numbers",
  WIZ_selectNumbers: "WIZ_selectNumbers",
  WIZ_setupFbAndWba: "WIZ_setupFbAndWba",
  WIZ_nextSteps: "WIZ_nextSteps",
  WIZ_companyInfoText: "WIZ_companyInfoText",
  WIZ_cardDetailsError: "WIZ_cardDetailsError",
  WIZ_insufficientFundsError: "WIZ_insufficientFundsError",
  WIZ_tryAgain: "WIZ_tryAgain",
  WIZ_transactionCancelled: "WIZ_transactionCancelled",
  WIZ_transactionFailed: "WIZ_transactionFailed",
  WIZ_cardBlocked: "WIZ_cardBlocked",
  WIZ_cardExpired: "WIZ_cardExpired",
  WIZ_amountMismatch: "WIZ_amountMismatch",
  WIZ_cardInvalid: "WIZ_cardInvalid",
  WIZ_paymentNotAllowed: "WIZ_paymentNotAllowed",
  WIZ_cardLowBalance: "WIZ_cardLowBalance",
  WIZ_noPaymentMethod: "WIZ_noPaymentMethod",
  WIZ_cloudHostingIOInfo: "WIZ_cloudHostingIOInfo",
  WIZ_accountSetUpIssuesMessage: "WIZ_accountSetUpIssuesMessage",
  WIZ_accountCreationFailed: "WIZ_accountCreationFailed",
  WIZ_updatePaymentDetails: "WIZ_updatePaymentDetails",
  WIZ_noPendingChannels: "WIZ_noPendingChannels",
  WIZ_verifyOwnership: "WIZ_verifyOwnership",
  WIZ_somethingWrong: "WIZ_somethingWrong",
  WIZ_NumberVerificationTitle: "WIZ_NumberVerificationTitle",
  WIZ_NumberVerificationDesc: "WIZ_NumberVerificationDesc",
  WIZ_success: "WIZ_success",
  WIZ_successMessage: "WIZ_successMessage",
  WIZ_paymentMethodError: "WIZ_paymentMethodError",
  WIZ_isNumberConnectedToBusinessApp: "WIZ_isNumberConnectedToBusinessApp",
  WIZ_businessAppTooltip: "WIZ_businessAppTooltip",
  WIZ_isNumberConnectedToWABA: "WIZ_isNumberConnectedToWABA",
  WIZ_businessAPITooltip: "WIZ_businessAPITooltip",
  WIZ_numberConnectedToBusinessApp: "WIZ_numberConnectedToBusinessApp",
  WIZ_numberNotConnectedToBusinessApp: "WIZ_numberNotConnectedToBusinessApp",
  WIZ_numberConnectedToWABA: "WIZ_numberConnectedToWABA",
  WIZ_numberNotConnectedToWABA: "WIZ_numberNotConnectedToWABA",
  WIZ_aboutYourNumber: "WIZ_aboutYourNumber",
  WIZ_confirmStatusOfNumber: "WIZ_confirmStatusOfNumber",
};

export default keys;
