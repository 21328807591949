// File generated automatically. Do not edit manually.
// import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.AUD_audiences]: "translation_missing",
  //  [keys.AUD_generalAudience]: "translation_missing",
  //  [keys.AUD_details]: "translation_missing",
  //  [keys.AUD_undelivered]: "translation_missing",
  //  [keys.AUD_undeliveredTipTitle]: "translation_missing",
  //  [keys.AUD_undeliveredTipOne]: "translation_missing",
  //  [keys.AUD_undeliveredTipTwo]: "translation_missing",
  //  [keys.AUD_seeOnMeta]: "translation_missing",
  //  [keys.AUD_downloadAudience]: "translation_missing",
  //  [keys.AUD_noAudience]: "translation_missing",
  //  [keys.AUD_noAudienceDescription]: "translation_missing",
  //  [keys.AUD_noAudienceDescriptionToS]: "translation_missing",
  //  [keys.AUD_createAudience]: "translation_missing",
  //  [keys.AUD_createAudienceCTAText]: "translation_missing",
  // [keys.AUD_chooseAdAccount]: "translation_missing",
  // [keys.AUD_createdMsg]: "translation_missing",
  // [keys.AUD_acceptMetaTerms]: "translation_missing",
  // [keys.AUD_newText]: "translation_missing",
  // [keys.AUD_introducingAudiences]: "translation_missing",
  // [keys.AUD_introducingAudiencesDescription]: "translation_missing",
  // [keys.AUD_checkDocs]: "translation_missing",
  // [keys.AUD_changeAdAccounts]: "translation_missing",
};

export default translate;
