import { createContext } from "react";

import { ListState } from "@/services/store/listState";
import { PipesListState } from "./listState";
import { UIModelSingleClient } from "@/services/api/clients/clients.model";

const removeNestedObjectsFromClientsList = (clients: UIModelSingleClient[]) =>
  clients.map(({ contactInfo, contactUser, metaInfo, ...prop }) => ({
    ...prop,
    ...contactInfo,
    ...metaInfo,
    userName: contactUser?.name || "",
    userPhone: contactUser?.phone || "",
    email: contactUser?.email || "",
  }));

export type clientsList = {
  total: number;
  clients: ReturnType<typeof removeNestedObjectsFromClientsList>;
};

const clientsState = new ListState<clientsList>({
  withoutAppId: true,
  limit: 20,
  offset: 0,
  sortFields: ["-modifiedAt"],
  apiDataInitial: {
    clients: [],
    total: 0,
  },
  filtersLoadSave: {},
});

const partnerChannelsState = new PipesListState({
  withoutAppId: true,
  limit: 20,
  offset: 0,
  sortFields: ["-modifiedAt"],
  searchFields: ["client.name"],
  apiDataInitial: {
    partnerChannels: [],
    total: 0,
    limit: 20,
    offset: 0,
    count: 0,
  },
  filtersLoadSave: {},
});

export const PartnerChannelAndClientsState = createContext<{
  partnerChannelsState: PipesListState;
  clientsState: ListState<clientsList>;
}>(
  {} as {
    partnerChannelsState: PipesListState;
    clientsState: ListState<clientsList>;
  }
);

const PartnerChannelAndClientsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <PartnerChannelAndClientsState.Provider
      value={{
        clientsState,
        partnerChannelsState,
      }}
    >
      {children}
    </PartnerChannelAndClientsState.Provider>
  );
};

export default PartnerChannelAndClientsProvider;
