// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.FAQ_q_1]:
    "O que é a verificação de negócios do Meta e por que eu preciso fazer isso?",
  //  [keys.FAQ_a_1]: "translation_missing",
  [keys.FAQ_q_2]:
    "De quais documentos eu preciso para a verificação de negócios do Meta?",
  [keys.FAQ_a_2]:
    "Em nosso <DOCS>, fornecemos uma lista de todos os documentos necessários para a Verificação de Negócios do Meta.",
  [keys.FAQ_q_3]: "Posso continuar usando o WhatsApp Business App?",
  [keys.FAQ_a_3]:
    "Uma vez concluído o processo de inscrição com o 360dialog, criaremos uma conta WhatsApp Business API para o número escolhido, e você não poderá fazer o downgrade para a WhatsApp Business App.",
  [keys.FAQ_q_4]:
    "Eu não recebi o SMS com o código de verificação. Por que isso aconteceu?",
  [keys.FAQ_a_4]:
    "Para começar, favor verificar se você digitou o número de telefone correto. Se você ainda não estiver recebendo a chave, pode ser que seu número já esteja conectado ao WhatsApp Profile ou ao WhatsApp Business App.",
  [keys.FAQ_q_5]:
    "Não consigo ver a opção de criar minha API key. Onde ela está?",
  [keys.FAQ_a_5]:
    "Se sua conta estiver conectada, você deve sempre ser capaz de criar uma chave API. Se você não conseguir encontrar o botão na página de Contas WhatsApp ou na página de detalhes do número, há uma chance de que sua integração ainda não esteja totalmente configurada.",
  [keys.FAQ_q_6]:
    "O que é o encaminhamento de mensagem ao parceiro e por que preciso habilitá-lo? ",
  [keys.FAQ_a_6]:
    "Se você vê a opção de encaminhar suas mensagens para seu parceiro, é muito provável que você tenha registrado um produto em uma empresa parceira, que precisa receber suas mensagens para poder funcionar como previsto. Somente você pode dar permissão ao parceiro para receber estas mensagens, habilitando o encaminhamento da mensagem.",
  //  [keys.FAQ_q_7]: "translation_missing",
  [keys.FAQ_a_7]:
    "Se você tiver registrado um produto com um parceiro de integração, uma chave API não é mais necessária. A única coisa que você precisa fazer é permitir o encaminhamento de suas mensagens ao seu parceiro. Se você está construindo um aplicativo por conta própria e, portanto, precisa criar uma chave API, entre em contato com nossa equipe de suporte.",
};

export default translate;
