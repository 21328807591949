import { observable, action, makeObservable } from "mobx";
//  import {instances} from '@/middleware/RequestAxios/RequestAxiosMessagePipe'
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";

enum GrayLogTypes {
  ERROR = "ERROR",
}

export interface Context {
  url?: string;
  code?: string;
  stage?: string;
  error?: string;
  response?: string;
  request?: string;
  message?: string;
}

class ErrorSender {
  wait: number;
  amountDuringTheLimit: number;
  totalAmount: number;
  lastSentErrorTime?: Date;

  readonly PERIODIC_LIMIT = 1;
  readonly TOTAL_LIMIT = 50;
  readonly TIME_PERIOD = 1000;

  constructor() {
    this.wait = this.TIME_PERIOD;
    this.amountDuringTheLimit = 0;
    this.totalAmount = 0;
    this.lastSentErrorTime = undefined;

    makeObservable(this, {
      wait: observable,
      amountDuringTheLimit: observable,
      totalAmount: observable,
      lastSentErrorTime: observable,
      send: action,
    });
  }

  private sendErrorAPI = async ({
    message,
    context,
    type,
  }: {
    message: any;
    context: Context;
    type?: GrayLogTypes;
  }) => {
    // await instances.errorLog.post('/logs', {
    //   "level": type,
    //   "message": `${messageToSend}`,
    //   "context": context
    // })
  };

  send = async ({
    message,
    context,
    type = GrayLogTypes.ERROR,
  }: {
    message: any;
    context: Context;
    type?: GrayLogTypes;
  }) => {
    if (this.totalAmount < this.TOTAL_LIMIT) {
      if (!this.lastSentErrorTime) {
        this.lastSentErrorTime = new Date();
      }
      const timeDiff = differenceInMilliseconds(
        new Date(),
        this.lastSentErrorTime
      );

      if (
        timeDiff <= this.TIME_PERIOD &&
        this.amountDuringTheLimit >= this.PERIODIC_LIMIT
      ) {
        return false;
      }
      if (
        timeDiff > this.TIME_PERIOD &&
        this.amountDuringTheLimit >= this.PERIODIC_LIMIT
      ) {
        this.amountDuringTheLimit = 0;
        this.lastSentErrorTime = new Date();
      }
      this.totalAmount++;
      try {
        this.amountDuringTheLimit++;
        await this.sendErrorAPI({ message, context, type });
        return true;
      } catch (err) {
        return false;
      }
    }
    return true;
  };
}

export default new ErrorSender();
