// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.CH_showDetailed]: "Mostrar estado detallado",
  [keys.CH_hideDetailed]: "Ocultar el estado detallado",
  [keys.CH_templatesButton]: "Gestionar templates",
  [keys.CH_showDetailsButton]: "Mostrar detalles",
  [keys.CH_mobButton]: "Solicitud de Message on Behalf",
  [keys.CH_triggerButton]: "Registrar el número",
  [keys.CH_finishButton]: "Completar registro",
  [keys.CH_keyButton]: "Generar API key",
  [keys.CH_addNumber]: "Añadir número",
  [keys.CH_exportClientData]: "Exportar datos de clientes",
  [keys.CH_exporting]: "Exportación ...",
  [keys.CH_filterPlaceholder]: "todos los estados",
  [keys.CH_details]: "Detalles",
  [keys.CH_templates]: "Templates",
  [keys.CH_billingInfo]: "Información de facturación",
  [keys.CH_billingStartDate]: "Los ingresos comenzaron en (hora local)",
  [keys.CH_billingStartButtonLabel]: "Iniciar ingresos",
  [keys.CH_numberReg]: "Registrar el número",
  [keys.CH_pin]: "Verificación de Pin",
  [keys.CH_defaultLang]: "Idioma predeterminado para las templates",
  [keys.CH_ivrEx]: "IVR existente",
  [keys.CH_ivrAct]: "IVR activo",
  [keys.CH_verificationMethod]: "Método de verificación",
  [keys.CH_voice]: "Voz",
  [keys.CH_sms]: "SMS",
  [keys.CH_cert]: "Certificado",
  [keys.CH_consent]: "Estado de consentimiento",
  [keys.CH_consentPage]: "Página de firma de consentimientos",
  [keys.CH_displayNameErrorEmoji]: "emoji",
  [keys.CH_displayNameErrorIntro]: "El Display Name no puede contener:",
  [keys.CH_displayNameErrorWhatsApp]: '"WhatsApp"',
  [keys.CH_displayNameErrorUrl]: "Una dirección URL",
  [keys.CH_displayNameErrorPunct]: "puntuación",
  [keys.CH_displayNameErrorCap]:
    "El Display Name solo puede ir en mayúsculas cuando la empresa ya utiliza branding completamente capitalizado.",
  [keys.CH_displayNameErrorMin]:
    "El Display Name debe contener un mínimo de 3 caracteres",
  [keys.CH_whatsAppDisplayName]: "Display Name de WhatsApp",
  [keys.CH_confirmDisplayNameChange1]:
    "Su nuevo Display Name será revisado por Meta antes de ser modificado, y podría ser rechazado si no sigue las pautas de Display Name de Meta. ¿Está seguro de que desea proponer este Display Name?",
  [keys.CH_confirmDisplayNameChange2]:
    "Por favor, espere hasta 48 horas para que su Display Name sea revisado por el equipo de Trust and Safety de Meta.",
  [keys.CH_confirmDisplayNameChangeTooltip]:
    "Puede cambiar el Display Name hasta tres veces en los primeros 30 días después de que se haya creado el perfil y se haya activado el número. Una vez que haya alcanzado ese límite, debe esperar 30 días antes de volver a proponer un cambio de nombre.",
  [keys.CH_confirmDisplayNameChange3]:
    "Preguntas frecuentes: ¿con qué frecuencia puedo modificar mi Display Name?",
  [keys.CH_confirmDisplayNameChange4]: "Por favor confirme esta acción",
  [keys.CH_numberReadyToRegister]:
    "Su número aún no ha sido registrado en la API de WhatsApp, por favor proceda con el registro para poder utilizar nuestros servicios.",
  [keys.CH_pending]: "Pendiente",
  [keys.CH_wabaChannelExtId]: "WABA Channel External ID",
  [keys.CH_wabaAccountName]: "Nombre de la cuenta de WhatsApp Business",
  [keys.CH_mobName]: "Nombre de Message on Behalf",
  [keys.CH_timezone]: "ID de franja horaria",
  [keys.CH_wabaAccountId]: "ID de cuenta de WhatsApp Business",
  [keys.CH_namespace]: "Namespace",
  [keys.CH_dangerZone]: "Zona de peligro",
  [keys.CH_deleteNumber]: "Borrar",
  [keys.CH_deleteNumberText]:
    "Esto pondrá en cola al cliente alojado de la WhatsApp Business API para su eliminación. El número ya no podrá enviar ni recibir mensajes mediante la WhatsApp Business API. Nótese: El número permanecerá visible en la lista de números, pero no se le facturará después de la fecha de terminación.",
  [keys.CH_revoke]: "Revocar el pago en nombre de su cliente",
  [keys.CH_revokeText]:
    "Esta acción revocará sus pagos y le pedirá al cliente que agregue su propia información de pago para continuar usando nuestro producto.",
  [keys.CH_requestRevocationButton]: "Solicitar revocación",
  [keys.CH_deleteSuccess]:
    "Recibimos su pedido. Su número se cancelará pronto.",
  [keys.CH_deletePopupText]:
    "Si desea continuar, por favor escriba ELIMINAR en el campo a continuación.",
  [keys.CH_confirmationFieldInputValue]: "BORRAR",
  [keys.CH_deletePopup1]:
    "¿Está seguro de que desea eliminar el cliente de WhatsApp Business API para el número <NUMBER>?",
  [keys.CH_detailsTab]: "Detalles",
  [keys.CH_templatesTab]: "Templates",
  [keys.CH_wabaProfileTab]: "Perfil de WABA",
  [keys.CH_profileTab]: "Perfil",
  [keys.CH_integrationTab]: "Integración",
  [keys.CH_commentsTab]: "Comentario",
  [keys.CH_listHeaderTitle]: "Cuenta de whatsapp",
  [keys.CH_manageKeys]: "Gestionar las API keys",
  [keys.CH_manageKeysHelptext]:
    "Su integración está en funcionamiento y puede enviar y recibir mensajes. Si aún no ha creado una API key, puede crearla ahora a través del botón a continuación.",
  [keys.CH_addNumberTitle]:
    "Conecte un nuevo número de teléfono a WhatsApp Business",
  [keys.CH_addNumberHelptext]:
    'Este formulario se puede utilizar para conectar un nuevo número de teléfono a la WhatsApp Business API. Al hacer clic en "Guardar", enviará oficialmente un nuevo número de teléfono a 360dialog y se le cobrará en consecuencia.',
  [keys.CH_addNumberSelectHelptext]:
    "Seleccione la cuenta comercial de WhatsApp que desea usar:",
  [keys.CH_addNumberSuccess]:
    "¡Éxito! Su número ha sido agregado y estará disponible pronto.",
  [keys.CH_addNumberEmbeddedTitle]: "Conecte un nuevo número de teléfono",
  [keys.CH_addNumberEmbeddedHelptext]:
    "Este formulario se puede utilizar para conectar un nuevo número de teléfono a la WhatsApp Business API. Al continuar con el formulario de Embedded Signup de Meta, enviará oficialmente un nuevo número de teléfono a 360dialog y es posible que se le cobre en consecuencia.",
  [keys.CH_addNumberSelectError]: "Seleccione la cuenta",
  [keys.CH_pendingDeletion]: "Pendiente de Eliminación",
  [keys.CH_pendingDeletionHelptext]:
    "Su integración fue enviada para ser eliminada y actualmente está pendiente. Este número no puede recibir ni enviar mensajes en el momento hasta que se elimine en <detic. Hasta entonces, tiene la posibilidad de reactivar su número a continuación.",
  [keys.CH_reEnableButton]: "Reactivar número",
  [keys.CH_revokeNumberDeletionSuccess]:
    "¡Su número ha sido reactivado con éxito!",
  [keys.CH_emptyStateTitle]: "Todavía no ha registrado una cuenta de WhatsApp.",
  [keys.CH_emptyStateHelptext]:
    "Para comenzar a enviar nuevas cuentas, sus clientes deben completar el formulario individual. Todas las cuentas enviadas por este formulario aparecerán aquí.",
  [keys.CH_emptyStateHelptextIO]:
    "Para comenzar a crear cuentas, asegúrese de implementar correctamente el Integrated Onboarding y solicite a sus clientes que realicen la configuración desde su aplicación.",
  [keys.CH_openSubmissionForm]: "Abrir formulario de nuevas cuentas",
  [keys.CH_openIODoc]: "Lea la documentación del Integrated Onboarding",
  [keys.CH_copyLink]: "Copiar link",
  [keys.CH_copiedLink]: "Link copiado",
  [keys.CH_emptyStateTitleClient]: "Parece que no completó el onboarding.",
  [keys.CH_emptyStateHelptextClient]:
    "Para acceder a WhatsApp Business API, primero debe crear una cuenta comercial de WhatsApp, que se realiza durante nuestro proceso de incorporación. Siga los pasos necesarios.",
  [keys.CH_emptyStateButtonLabelClient]: "Terminar onboarding",
  [keys.CH_noChannelsFound]: "No hay resultados para esta búsqueda.",
  [keys.CH_setupProgress]: "Progreso de la configuración",
  [keys.CH_showMore]: "Mostrar más",
  [keys.CH_showLess]: "Mostrar menos",
  [keys.CH_percentageDone]: "finalizado",
  [keys.CH_actionRequired]: "Se requiere acción",
  [keys.CH_noActionRequired]: "Ninguna acción requerida",
  [keys.CH_setupNumberRunning]: "Número activo",
  [keys.CH_setupVerificationStarted]: "Business verification iniciada",
  [keys.CH_setupVerificationCompleted]: "Business verification finalizada",
  [keys.CH_setupDisplayNameApproved]: "Display name aprobado",
  [keys.CH_setupCommercePolicyChecked]:
    "Revisión de Commerce Policy finalizada",
  [keys.CH_ctaBusinessRejected]: "Se rechazó la verificación de su empresa.",
  [keys.CH_ctaNumberSetup]:
    "Su número está siendo configurado en estos momentos. Esto tomará unos minutos. Por favor, recargue esta página en alrededor de cinco minutos.",
  [keys.CH_ctaStartVerification]:
    "Acceda al Meta Business Manager y comience la verificación de su empresa.",
  [keys.CH_ctaVerificationPending]:
    "La verificación de su empresa está pendiente.",
  [keys.CH_ctaDisplayNameRejected]:
    "Su <LINK>https://developers.facebook.com/docs/whatsapp/guides/display-name/ | display name<LINK> fue rechazado. Utilice el Administrador de WhatsApp de Meta o comuníquese con nuestro soporte para cambiarlo y volver a enviarlo.",
  [keys.CH_ctaButtonLabelOpenWaManager]: "Abrir Administrador del WhatsApp",
  [keys.CH_ctaLiveMode]:
    "¡Felicidades! Su cuenta está activa y tiene acceso completo a la API empresarial de WhatsApp.",
  [keys.CH_ctaButtonLabelFbm]: "Abrir gerente comercial de Meta",
  [keys.CH_ctaButtonLabelStartVerification]:
    "Comenzar la verificación de su empresa",
  [keys.CH_ctaButtonLabelOpenSettings]: "Abrir configuración",
  [keys.CH_ctaButtonLabelAppeal]: "Crear formulario de apelación",
  [keys.CH_ctaCommercePolicyFailed]:
    "Su empresa no ha pasado los <LINK>https://www.whatsapp.com/legal/commerce-policy/?lang=en | WhatsApp Commerce Policy Checks<LINK>. Si considera que su empresa no viola estas políticas, puede apelar la decisión.",
  [keys.CH_ctaPendingDeletion]:
    "Su número está pendiente, esperando ser eliminado. No es necesaria una acción adicional.",
  [keys.CH_tooltipSandbox]:
    "Aprenda más sobre la experiencia de unverified trial.",
  [keys.CH_wabaPhotoChanged]:
    "La foto de la cuenta de WhatsApp Business fue cambiada con éxito.",
  [keys.CH_wabaProfile]: "Perfil de WhatsApp Business",
  [keys.CH_businessProfile]: "Perfil Business",
  [keys.CH_changeProfilePic]: "Cambiar la foto de perfil",
  [keys.CH_profilePic]: "Foto de perfil",
  //  [keys.CH_urlErrorMessage]: "translation_missing",
  [keys.CH_portNumberButtonLabel]: "Portar número existente a 360dialog",
  [keys.CH_portNumberText]:
    "Este formulario se puede utilizar para iniciar la migración de un número de teléfono existente a su cuenta de 360dialog. Después de proporcionar el número y la cuenta WhatsApp Business de destino, se le guiará a través del proceso de migración. Al continuar, también reconoce que enviará oficialmente un nuevo número de teléfono a 360dialog y se le cobrará en consecuencia.",
  [keys.CH_createNewWaba]: "Crear nueva cuenta de WhatsApp Business",
  [keys.CH_phoneNumberAlreadyExists]:
    "Este número de teléfono ya ha sido añadido.",
  [keys.CH_migrateNumberNextStepText]:
    "Continúe con el proceso de porte de número en la nueva pestaña, que debería haberse abierto. De lo contrario, compruebe si hay una ventana emergente bloqueada en su navegador e <LINK>inténtelo de nuevo<LINK>.",
  [keys.CH_readyForMigrationCtaText]:
    "Por favor, abra la página de verificación y siga los pasos para finalizar el porte de su número.",
  [keys.CH_waitingForMigrationCtaText]:
    "La verificación del número aún no ha sido completada. Siga los pasos de la página de verificación para reactivar el proceso y enviar un código de verificación. Este paso es necesario para finalizar el porte de su número.",
  [keys.CH_invalidBusinessId]: "El ID de Meta Business Manager no es válido",
  [keys.CH_migratedNumberLabel]: "Número migrado",
  [keys.CH_type]: "Tipo",
  [keys.CH_retrySetupText]:
    "Su integración todavia no ha empezado. Es posible que el 2FA aún no esté activo. Por favor, intente nuevamente.",
  [keys.CH_setTerminationDate]: "Definir fecha para exclusión",
  [keys.CH_successChannelAdded]: "Canal adicionado correctamente",
  [keys.CH_addBusinessInfo]: "Adicionar Informaciones de la Empresa",
  [keys.CH_businessInfoPrompt]:
    "Por favor, adicione las Informaciones de la Empresa en el Meta Business Manager para tener acceso completo a la API. ¿Ha pasado por todas las aprovaciones, pero necesita aumentar los límites de mensaje?",
  [keys.CH_startBusinessVerification]: "Empezar Verificación de la Empresa",
  [keys.CH_deletedChannel]: "Canal inactivo",
  [keys.CH_deletedChannelHelptextShort]:
    "La integración ha sido deletada y este número no está más activo. Será deletado irrevogablemente al día <date>.",
  [keys.CH_deletedChannelHelptext]:
    "La integración ha sido deletada y este número no está más activo. Será deletado irrevogablemente al día <date>. Después de esa fecha no estará más visible al 360dialog Client Hub",
  [keys.CH_reEnableHelptext]:
    "Si quere restaruar su número, por favor, contacte <PARTNER>.",
  [keys.CH_partnerFallback]: "su parcero 360dialog",
  [keys.CH_recentSearches]: "Buscar reciente",
  [keys.CH_applyFilter]: "Aplicar filtro",
  [keys.CH_sortByDate]: "Organizado por fecha de última alteración",
  [keys.CH_searchBy]:
    "Buscar por número de teléfono, Display Name o nombre de la empresa",
  [keys.CH_filters]: "Filtros",
  [keys.CH_filterSingular]: "Filtro",
  [keys.CH_variableCountError]:
    "Ha excedido el número de variables permitidas.",
  [keys.CH_phoneNumber]: "Número de teléfono",
  [keys.CH_clientEmail]: "E-mail del cliente",
  [keys.CH_submissionType]: "Tipo de sumisión",
  [keys.CH_portedNumber]: "Número portado",
  [keys.CH_channelStatus]: "Status del canal",
  [keys.CH_pendingCancellation]: "Cancelación pendiente",
  [keys.CH_accountMode]: "Modo de cuenta",
  [keys.CH_errorNotEverythingLoaded]:
    "No todos los componentes de la página han sido cargados. Por favor, intente nuevamente. Si el problema sigue, por favor, contacte nuestro apoyo.",
  [keys.CH_buttonLabelGotToSupport]: "Ir para la página de apoyo",
  [keys.CH_emptyStateOption]: "Ningún resultado para esta búsqueda",
  [keys.CH_billingStatus]: "Status de facturación",
  [keys.CH_cancelledOn]: "Cancelado el",
  [keys.CH_terminatedOn]: "Deletado el",
  [keys.CH_startedOn]: "Iniciado el",
  [keys.CH_notStarted]: "Aún no ha sito iniciado",
  [keys.CH_compressedListView]: "Vista de lista comprimida",
  [keys.CH_externalId]: "ID Externo",
  [keys.CH_status]: "Estado",
  [keys.CH_deleteNumber]: "Cancelar suscripción",
  [keys.CH_deleteNumberText]:
    "Esto pondrá el cliente de WhatsApp Business API alojado para su eliminación en cola. Después de la fecha de finalización que se establece de acuerdo con los términos de cancelación, el número ya no podrá enviar ni recibir mensajes utilizando la API de WhatsApp Business. Nota: el número permanecerá visible en la lista de números, pero no se le facturará después de la fecha de finalización.",
  [keys.CH_deleteNumberTextTerms]:
    "Términos: La suscripción puede terminarse con treinta (30) días de antelación al final de un mes.",
  [keys.CH_deletePopupText]:
    "Si desea continuar, digite CANCELAR en el siguiente campo.",
  [keys.CH_confirmationFieldInputValue]: "CANCELAR",
  [keys.CH_deletePopup1]:
    "¿Está seguro de que desea cancelar su inscripción al cliente de WhatsApp Business API para el número de teléfono <NÚMERO>?",
  [keys.CH_confirmDeleteNumberFBManagerText1]:
    'Cancelar su suscripción pondrá en cola el número para su eliminación. Tenga en cuenta que no es una eliminación instantánea. El estado del número cambiará a "Pendiente de eliminación", mientras el número esté en esta etapa, aún podrá enviar y recibir mensajes.',
  //  [keys.CH_confirmDeleteNumberFBManagerText2]: "translation_missing",
  [keys.CH_confirmDeleteNumberFBManagerText3]:
    'Si desea que este número se elimine antes de este período, marque la casilla "Eliminar inmediatamente mi número en Meta Business Manager" antes de confirmar.',
  [keys.CH_confirmDeleteNumberFBManager]:
    "Eliminar inmediatamente mi número en el administrador comercial de Meta. Tenga en cuenta que esta acción creará automáticamente un ticket de soporte y provocará la eliminación del número en 48 horas aproximadamente. Ya no estará disponible para su uso con la API de WhatsApp Business. Esto no cancelará la obligación de pagar las tarifas de licencia antes de la fecha de su eliminación completa de acuerdo con los Términos de 360dialog.",
  [keys.CH_deleteNumberSuccess]:
    "Tu número se eliminará del Administrador de Negocios de Meta (Meta Business Manager).",
  [keys.CH_messagingLimit]: "Límite de Mensajes",
  [keys.CH_learnHowDelete]: "Saiba mais sobre como apagar seu número",
  [keys.CH_emailErrorMessage]:
    "Digite una dirección de correo electrónico válida",
  [keys.CH_partnerButtonDisabled]:
    "Esta acción no está disponible actualmente para los usuarios con permisos de socio.",
  [keys.CH_usageAndBalance]: "Uso & Saldo",
  [keys.CH_prepaidSettings]: "Configuraciones de prepago",
  [keys.CH_lastRenewal]: "Última recarga",
  [keys.CH_usage]: "Uso",
  [keys.CH_paymentNotEnabled]: "Cliente no habilitado para el prepago",
  [keys.CH_hasInboxTooltip1]:
    "Este número se utiliza actualmente en combinación con el inbox de get.chat. Por lo tanto, no puede generar una clave de API para utilizarla con la API de WhatsApp de 360dialog. Sin embargo, puede utilizar las funcionalidades de la API por medio de la API de WhatsApp de get.chat.",
  [keys.CH_hasInboxTooltip2]:
    "Para desconectar su inbox y crear una clave de API de 360dialog, visite la página de detalles por el botón 'Mostrar detalles'.",
  [keys.CH_hasInboxActionBlock1]:
    "Este número se utiliza actualmente en combinación con el inbox de get.chat. Por lo tanto, no puede generar una clave de API para utilizarla con la API de WhatsApp de 360dialog. Sin embargo, puede utilizar las funcionalidades de la API por medio de la API de WhatsApp de get.chat.",
  [keys.CH_hasInboxActionBlock2]:
    "Puede desconectar su inbox de get.chat y crear una clave de API de 360dialog más tarde.",
  [keys.CH_disconnectInboxButtonLabel]: "Desconectar inbox ahora",
  [keys.CH_disconnectNotificationSuccess]:
    "Su inbox de get.chat se ha desconectado con éxito de este número.",
  [keys.CH_disconnectNotificationError]:
    "No hemos podido desconectar tu inbox de get.chat. Por favor, inténtalo más tarde. Si este problema persiste, póganse en contacto con el servicio de asistencia.",
  [keys.CH_openConsentPage]: "Abrir la pagina de firma del consentimiento",
  [keys.CH_close]: "Cerrar",
  [keys.CH_accountViolation]: "Violación de la cuenta",
  [keys.CH_businessManagerDetails]:
    "Ver detalles en el Administrador de Negocio de Meta (Meta Business Manager)",
  [keys.CH_accountRestriction]: "Restricción de cuenta",
  [keys.CH_noInformationAvailable]: "Información indisponible",
  [keys.CH_restrictionText]: "Su cuenta ha sido restricta por Meta.",
  [keys.CH_restrictionTextBusiness]:
    "Usted no tiene más permiso para enviar mensajes iniciados por la empresa.",
  [keys.CH_restrictionTextUser]:
    "Usted no tiene más permiso para contestar mensajes iniciados por el usuario.",
  [keys.CH_restrictionTextNumber]:
    "Usted no tiene más permiso para añadir nuevos números de teléfono.",
  [keys.CH_clientName]: "Nombre del cliente",
  [keys.CH_premiumPlanPricing]: "Precio del plan Premium",
  [keys.CH_standardPlanPricing]: "Precio del plan Standard",
  [keys.CH_upgrade]:
    "Al continuar con los siguientes pasos, usted hará upgrade del plan para el número <NUMBER>.",
  [keys.CH_downgrade]:
    "Si continúa con los siguientes pasos, hará el downgrade de plan para el número <NUMBER>.",
  [keys.CH_stantard]: "Standard",
  [keys.CH_premium]: "Premium",
  [keys.CH_month]: "Mes",
  [keys.CH_billedMonthly]: "Facturado mensualmente",
  [keys.CH_unlimitedIncomingMsg]:
    "Mensajes entrantes y salientes de sesión de WhatsApp ilimitados",
  [keys.CH_noMarkup]:
    "Conversaciones iniciadas por el usuario y por la empresa según <LINK>https://developers.facebook.com/docs/whatsapp/pricing | WhatsApp pricing<LINK>. Sin recargo.",
  [keys.CH_98serviceLevel]: "Nivel de Servicio de Alojamiento 98%",
  [keys.CH_99serviceLevel]: "Nivel de Servicio de Alojamiento 99%",
  [keys.CH_premiumSupport]:
    "Soporte por toda la semana (24 horas de Lunes a Viernes, de 9 a 17 horas CET el Sábado y el Domingo)",
  [keys.CH_standardSupport]:
    "Soporte en días laborales (2am - 10pm CET Lunes - Viernes)",
  [keys.CH_premiumSLA]: "Hasta < 30min SLA de Soporte (para tickets elegibles)",
  [keys.CH_standardSLA]: "Hasta < 4h SLA de Soporte (para tickets elegibles)",
  [keys.CH_costBreakdown]: "Seguir para desglose de costes",
  [keys.CH_description]: "Descripción",
  [keys.CH_qty]: "Ctd",
  [keys.CH_amount]: "Cantidad",
  [keys.CH_estimatedCost]:
    "Los costes mensuales estimados de su suscripción, excluyendo los costes de conversación y los posibles pagos de prorrateo para el mes en curso, serán los siguientes.",
  [keys.CH_unitPrice]: "Precio por unidad",
  [keys.CH_standardPricing]: "Precio Standard",
  [keys.CH_premiumPricing]: "Precio Premium",
  [keys.CH_premiumAccount]: "Cuenta Premium",
  [keys.CH_lastDowngradeTooltip1]:
    "Las cuentas pueden recibir upgrade 30 días después del últimp downgrade. Su último downgrade fue <DATE>",
  [keys.CH_lastDowngradeTooltip2]:
    "El siguiente upgrade se puede activarse <fecha>.",
  [keys.CH_lastUpgradeTooltip1]:
    "Las cuentas pueden recibir downgrade 30 días después del último upgrade. Su último upgrade fue <DATE>.",
  [keys.CH_lastUpgradeTooltip2]:
    "El siguiente downgrade se puede activarse <DATE>.",
  [keys.CH_reRegisterNumber]: "Registrar el número nuevamente",
  [keys.CH_reRegisterNumberText]:
    'Meta ha dado de baja su número. Para continuar con nuestros servicios de mensajería, debe registrarse nuevamente. Haga clic en el botón "Registrar el número nuevamente" y siga los pasos.',
  [keys.CH_saveComment]: "Guardar comentario",
  [keys.CH_activityLogs]: "Registros de actividad",
  [keys.CH_admin]: "Administrador",
  [keys.CH_dateAndTime]: "Día y hora",
  [keys.CH_actor]: "Usuario",
  [keys.CH_action]: "Acción",
  [keys.CH_businessAccount]: "Cuenta de negocios",
  [keys.CH_profilePreview]: "Vista previa del perfil",
  [keys.CH_noLogsYet]: "Aún no hay registros",
  [keys.CH_noLogsYetDescription]: "Actualmente no hay registros para mostrar.",
  [keys.CH_useFilteredData]: "¿Usar datos filtrados?",
  [keys.CH_selectDataset]:
    "Seleccione si desea descargar el conjunto de datos completo o si los filtros seleccionados deben aplicarse al conjunto de datos exportado.",
  [keys.CH_filteredExportTitle]: "Exportar datos filtrados",
  [keys.CH_filteredExport]: "Exportar conjunto de datos filtrados",
  [keys.CH_fullExportTitle]: "Exportaciones totales",
  [keys.CH_fullExport]: "Exportar conjunto de datos total",
  [keys.CH_contactSupport]: "Póngase en contacto con nuestro soporte",
  [keys.CH_contactSupportText]:
    "La configuración de su número es inesperadamente lenta. Póngase en contacto con nuestro equipo de soporte para obtener ayuda.",
  [keys.CH_proceedWithRegistration]:
    "Su número aún no se ha registrado en la API de WhatsApp. Por favor, proceda con el registro para utilizar nuestros servicios.",
  [keys.CH_readyToRegister]: "Listo para registrarse",
  [keys.CH_addNumberDisabledTwoNumbers]:
    "Actualmente no puede agregar más números a su cuenta. Si necesita más números, póngase en contacto con nuestro soporte.",
  [keys.CH_addNumberDisabledTwoNumbers2]:
    "Actualmente no puede agregar más números a su cuenta. Si necesita más números, comuníquese con su partner o nuestro soporte a través de hub.360dialog.com",
  [keys.CH_addNumberLimitExceeded]:
    "Actualmente no puede agregar más números a su cuenta debido al límite de la misma. Si necesita más números, comuníquese con su socio de integración o con nuestro servicio de asistencia.",
  [keys.CH_lockedTitle]: "Su cuenta está bloqueada temporalmente",
  [keys.CH_lockedText]:
    "Detectamos actividad sospechosa en uno de sus números de teléfono de WhatsApp y, por lo tanto, bloqueamos su cuenta temporalmente. Asegúrese de haber pagado todas las facturas pendientes de las tarifas de suscripción, así como los costos de conversación para poder continuar enviando mensajes. Si cree que esto está mal y desea que su cuenta se active nuevamente, comuníquese con nuestro soporte.",
  [keys.CH_ctaPendingReview]:
    "Nuestro equipo está revisando su cuenta actualmente. Esto no debería tomar más de 2 horas. Hasta que se complete el proceso de revisión, no podrá enviar ningún template message. Pero puede recibir mensajes y responder a conversaciones iniciadas por el usuario.",
  [keys.CH_apiKeyDisabledMessage]:
    'Las API keys están deshabilitadas para este número. El número se compartió con su partner. Con su permiso, el partner tiene acceso a la API de WhatsApp Business en su nombre. Para administrar los permisos, vaya a "Configuración de la organización" o comuníquese con su partner.',
  [keys.CH_emptyStateHelptextClientIO]:
    "Para acceder a la API de WhatsApp Business, primero debe crear una cuenta de WhatsApp Business. Continúe con el proceso de configuración a través de la aplicación de su partner.",
  [keys.CH_successfullyAddedNumber]:
    "Su número fue agregado con éxito. Lo estamos configurando y será visible en esta página en unos minutos. Se le notificará aquí y por correo electrónico.",
  [keys.CH_numberNowLive]: "Su número ahora se encuentra activo",
  [keys.CH_generateApiKeyNow]: "Generar API key ahora",
  [keys.CH_settingUpNumber]:
    "Actualmente estamos configurando su número y esto puede tardar unos minutos. Se le notificará por correo electrónico cuando esté completamente configurado. Después de eso, continúe con el registro del número.",
  [keys.CH_accountDeletionCheckbox]:
    "Entiendo que mi número será desactivado al final del período de suscripción actual.",
  [keys.CH_deleteAccount]: "Borrar cuenta",
  [keys.CH_dataNotAccessible]:
    "Los datos de esta página no son accesibles para tu usuario",
  [keys.CH_addNumberDisabledSandbox]:
    "Ha alcanzado el máximo de números (2) en el sandbox. Para agregar nuevos números, asegúrese de pasar al modo en vivo con sus números existentes.",
  [keys.CH_hostingPlatformType]: "Tipo de Plataforma de Hosting",
  [keys.CH_aboutTextRequired]: "El campo 'Sobre' es obligatorio",
  [keys.CH_synced]: "Sincronizado",
  [keys.CH_wabaType]: "Tipo de cuenta de WhatsApp Business",
  [keys.CH_disableTracker]: "Desactivar rastreo de CTWA ",
  [keys.CH_trackerNotificationContent]:
    "Si desactivas el rastreo de Click To WhatsApp, todos los datos recopilados seguirán siendo visibles, sin embargo, los eventos rastreados se archivarán automáticamente.",
  [keys.CH_trackingBannerContent]:
    "Tienes el rastreo de Click To WhatsApp desactivado para este número. ¡Activalo para continuar recopilando información de tus conversaciones!",
  [keys.CH_deleteTitle]:
    "Si quieres cancelar la suscripción del número de teléfono <NÚMERO>, escribe CANCELAR en a continuación.",
  [keys.CH_goBack]: "Atrás",
  [keys.CH_activateInbox]: "Activar Inbox",
  [keys.CH_verifyBusiness]: "Verficar Meta Business",
  [keys.CH_contactPartner]:
    "Comunícate con tu Partner para Agregar o Transferir un número de teléfono",
  //  [keys.CH_cancelPayment]: "translation_missing",
  //  [keys.CH_cancelPaymentInfo]: "translation_missing",
  //  [keys.CH_pleaseLimitDescription]: "translation_missing",
  //  [keys.CH_addNumberDisabledTwoNumbersAlt]: "translation_missing",
  //  [keys.CH_currentPlanNotSupportChangingHostingPlatform]: "translation_missing",
  //  [keys.CH_pleaseBeAwareMigrationBack]: "translation_missing",
  //  [keys.CH_signalDisabledMsg]: "translation_missing",
  //  [keys.CH_inboxDisabledMsg]: "translation_missing",
  [keys.CH_partnerApiKeyPermission]: "Permiso de llave de API del partner",
  //  [keys.CH_subscriptionPlan]: "translation_missing",
  //  [keys.CH_changePlan]: "translation_missing",
  //  [keys.CH_downgradeScheduleInfo]: "translation_missing",
  //  [keys.CH_upgradeScheduleInfo]: "translation_missing",
  //  [keys.CH_subscriptionSuccessful]: "translation_missing",
  //  [keys.CH_selectSubscription]: "translation_missing",
  //  [keys.CH_subscriptionSelectionInfo]: "translation_missing",
  //  [keys.CH_savingPlan]: "translation_missing",
  //  [keys.CH_savePlan]: "translation_missing",
  //  [keys.CH_downgradeDisabledTooltip]: "translation_missing",
  //  [keys.CH_pendingClientApprovalInfo]: "translation_missing",
  //  [keys.CH_verifyOwnership]: "translation_missing",
  //  [keys.CH_pendingClientApprovalInfoNoClientInfo]: "translation_missing",
  //  [keys.CH_viewDocumentation]: "translation_missing",
  //  [keys.CH_pendingClientApprovalInfoWithClientInfo]: "translation_missing",
  //  [keys.CH_clientUserApproved]: "translation_missing",
  //  [keys.CH_channelVerified]: "translation_missing",
  [keys.CH_displayName]: "Nombre de ex...",
  [keys.CH_qualityRating]: "Rating de calidad",
  [keys.CH_manage]: "Gestionar",
  [keys.CH_actions]: "Acciones",
  //  [keys.CH_wabaWebhookUrlError]: "translation_missing",
  //  [keys.CH_hostingType]: "translation_missing",
  //  [keys.CH_partnerPermitted]: "translation_missing",
  //  [keys.CH_partnerNotPermitted]: "translation_missing",
  //  [keys.CH_missing]: "translation_missing",
  //  [keys.CH_shared]: "translation_missing",
  //  [keys.CH_managePermissions]: "translation_missing",
  [keys.CH_planRecommended]: "Recomendado",
  [keys.CH_planBasic]: "Básico",
  [keys.CH_planRegular]: "Regular",
  [keys.CH_planPremium]: "Premium",
  [keys.CH_planMonth]: "mes",
  [keys.CH_planIncludes]: "Incluye",
  [keys.CH_planPlus]: "más",
  [keys.CH_planCloudHosting]: "Alojamiento de API en la nube",
  [keys.CH_planNinetyServiceLevel]: "90% Nivel de Servicio de Hosting",
  [keys.CH_planTicketBased]:
    "Soporte basado en tickets con tiempo de respuesta de 24 horas",
  [keys.CH_planOnPremiseHosting]: "Alojamiento On-premise",
  [keys.CH_planWabaOfficial]:
    "Solicitud de cuenta Comercial Oficial de Whatsapp",
  [keys.CH_planHighServiceLevel]: ">99% Nivel de Servicio de Hosting",
  [keys.CH_planIncludesAdsFunnel]:
    "Incluye seguimiento y medición de Ads & Funnel",
  [keys.CH_planSelect]: "Seleccionar",
  [keys.CH_planSelected]: "Seleccionado",
  [keys.CH_active]: "Activo",
  [keys.CH_manageWaba]: "Administre su cuenta de WhatsApp Business",
  [keys.CH_numberDetails]: "Detalles",
  [keys.CH_numberBusinessAccount]: "Cuenta Business",
  [keys.CH_funds]: "Fondos",
  [keys.CH_plan]: "Planes",
  [keys.CH_accountName]: "Nombre de la cuenta",
  [keys.CH_dataRegions]: "Regiones de almacenamiento de datos",
  [keys.CH_numberQuality]: "Calificación de calidad",
  [keys.CH_numberMessagingLimit]: "Límite de mensajes",
  [keys.CH_low]: "Bajo",
  [keys.CH_medium]: "Medio",
  [keys.CH_high]: "Alto",
  [keys.CH_numberPending]: "Pendiente",
  [keys.CH_unlimited]: "Ilimitado",
  [keys.CH_newConversationsPerDay]: "Nuevas conversaciones por día",
  [keys.CH_onlyAvailableCloudNumbers]: "",
  [keys.CH_qualityRatingTooltipOne]:
    "Su rating de calidad se basa en cómo se han recibido los mensajes por parte de los destinatarios durante los últimos siete días y está ponderada por la recencia. ",
  [keys.CH_qualityRatingTooltipTwo]:
    "Se determina por una combinación de señales de calidad de las conversaciones entre empresas y usuarios. Ejemplos incluyen señales de retroalimentación del usuario como bloqueos, informes y las razones que los usuarios proporcionan cuando bloquean un negocio.",
  [keys.CH_qualityRatingTooltipStatus]:
    "El rating de calidad puede tener los siguientes estados:",
  [keys.CH_qualityRatingTooltipPending]:
    "<b>Activo - Calidad pendiente</b> La plantilla de mensajes aún no ha recibido retroalimentación de calidad de los clientes. Las plantillas de mensajes con este estado se pueden enviar a los clientes y serán monitoreadas para su calificación.",
  [keys.CH_qualityRatingTooltipHigh]:
    "<b>Activo - Alta calidad</b> La plantilla ha recibido poca o ninguna retroalimentación negativa de los clientes. Las plantillas de mensajes con este estado se pueden enviar a los clientes.",
  [keys.CH_qualityRatingTooltipMedium]:
    "<b>Activo - Calidad media</b> La plantilla ha recibido retroalimentación negativa de varios clientes y pronto podría ser pausada o deshabilitada. Las plantillas de mensajes con este estado se pueden enviar a los clientes.",
  [keys.CH_qualityRatingTooltipLow]:
    "<b>Activo - Baja calidad</b> La plantilla ha recibido retroalimentación negativa de varios clientes. Las plantillas de mensajes con este estado se pueden enviar a los clientes, pero están en peligro de ser pausadas o deshabilitadas pronto, por lo que se recomienda abordar los problemas que los clientes están reportando.",
  [keys.CH_messagingLimitTooltip]:
    "El límite de mensajería determina el número máximo de conversaciones iniciadas por el negocio que cada número de teléfono puede iniciar en un período continuo de 24 horas.",
  [keys.CH_messagingLimitTooltipLimits]:
    "Los límites de conversaciones iniciadas por el negocio son: <b>250</b> · <b>1K</b> · <b>10K</b> · <b>100K</b>",
  [keys.CH_numberQualityRating]: "Rating de calidad del número",
  [keys.CH_learnMore]: "Más información",
  [keys.CH_numberMessagingLimitTitle]: "Límite de mensajes por número",
  [keys.CH_seeMore]: "Ver más",
};

export default translate;
