// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  TMP_name: "TMP_name",
  TMP_cat: "TMP_cat",
  TMP_preview: "TMP_preview",
  TMP_lang: "TMP_lang",
  TMP_tooltipAddLang: "TMP_tooltipAddLang",
  TMP_tooltipCopy: "TMP_tooltipCopy",
  TMP_addTemplateButtonLabel: "TMP_addTemplateButtonLabel",
  TMP_emptyStateTitle: "TMP_emptyStateTitle",
  TMP_emptyStateHelptext: "TMP_emptyStateHelptext",
  TMP_cardHeader: "TMP_cardHeader",
  TMP_namePlaceholder: "TMP_namePlaceholder",
  TMP_nameLabel: "TMP_nameLabel",
  TMP_cat1: "TMP_cat1",
  TMP_cat2: "TMP_cat2",
  TMP_cat3: "TMP_cat3",
  TMP_cat4: "TMP_cat4",
  TMP_cat5: "TMP_cat5",
  TMP_cat6: "TMP_cat6",
  TMP_cat7: "TMP_cat7",
  TMP_cat8: "TMP_cat8",
  TMP_cat9: "TMP_cat9",
  TMP_cat10: "TMP_cat10",
  TMP_cat11: "TMP_cat11",
  TMP_langLabel: "TMP_langLabel",
  TMP_typeLabel: "TMP_typeLabel",
  TMP_type1: "TMP_type1",
  TMP_type2: "TMP_type2",
  TMP_bodyPlaceholder: "TMP_bodyPlaceholder",
  TMP_blocksHelptext: "TMP_blocksHelptext",
  TMP_header: "TMP_header",
  TMP_body: "TMP_body",
  TMP_footer: "TMP_footer",
  TMP_button: "TMP_button",
  TMP_buttons: "TMP_buttons",
  TMP_chars: "TMP_chars",
  TMP_vars: "TMP_vars",
  TMP_headerMedia1: "TMP_headerMedia1",
  TMP_headerMedia2: "TMP_headerMedia2",
  TMP_headerMedia3: "TMP_headerMedia3",
  TMP_headerMedia4: "TMP_headerMedia4",
  TMP_footerPlaceholder: "TMP_footerPlaceholder",
  TMP_headerPlaceholder: "TMP_headerPlaceholder",
  TMP_quickReply: "TMP_quickReply",
  TMP_cta: "TMP_cta",
  TMP_phoneNumber: "TMP_phoneNumber",
  TMP_url: "TMP_url",
  TMP_addButtonLabel: "TMP_addButtonLabel",
  TMP_discardButtonLabel: "TMP_discardButtonLabel",
  TMP_previewButtonLabel: "TMP_previewButtonLabel",
  TMP_buttonLabelPlaceholder: "TMP_buttonLabelPlaceholder",
  TMP_buttonVarHelpText: "TMP_buttonVarHelpText",
  TMP_previewHeader1: "TMP_previewHeader1",
  TMP_previewHeader2: "TMP_previewHeader2",
  TMP_exampleValues: "TMP_exampleValues",
  TMP_varEmptyStateHelptext1: "TMP_varEmptyStateHelptext1",
  TMP_varEmptyStateHelptext2: "TMP_varEmptyStateHelptext2",
  TMP_varEmptyStateHelptext3: "TMP_varEmptyStateHelptext3",
  TMP_docsLinkLabel: "TMP_docsLinkLabel",
  TMP_saveSuccess: "TMP_saveSuccess",
  TMP_namePatternError: "TMP_namePatternError",
  TMP_backToList: "TMP_backToList",
  TMP_backToEditor: "TMP_backToEditor",
  TMP_submit: "TMP_submit",
  TMP_headerImgPlaceholder: "TMP_headerImgPlaceholder",
  TMP_buttonUrlVarPlaceholder: "TMP_buttonUrlVarPlaceholder",
  TMP_headerDocumentPlaceholder: "TMP_headerDocumentPlaceholder",
  TMP_tooltipDelete: "TMP_tooltipDelete",
  TMP_deleteAllButtonLabel: "TMP_deleteAllButtonLabel",
  TMP_deleteConfirmText: "TMP_deleteConfirmText",
  TMP_deleteAllConfirmButtonLabel: "TMP_deleteAllConfirmButtonLabel",
  TMP_deleteAllSuccess: "TMP_deleteAllSuccess",
  TMP_deleteSingleSuccess: "TMP_deleteSingleSuccess",
  TMP_buttonsCounter: "TMP_buttonsCounter",
  TMP_pleaseNote: "TMP_pleaseNote",
  TMP_helperBulletText1: "TMP_helperBulletText1",
  TMP_helperBulletText2: "TMP_helperBulletText2",
  TMP_helperBulletText3: "TMP_helperBulletText3",
  TMP_helperBulletText4: "TMP_helperBulletText4",
  TMP_sampleValueInfoText: "TMP_sampleValueInfoText",
  TMP_missingExamplesError: "TMP_missingExamplesError",
  TMP_buttonPreviewHelptext: "TMP_buttonPreviewHelptext",
  TMP_syncTemplates: "TMP_syncTemplates",
  TMP_successSyncTemplates: "TMP_successSyncTemplates",
  TMP_syncDone: "TMP_syncDone",
  TMP_justNow: "TMP_justNow",
  TMP_lastSync: "TMP_lastSync",
  TMP_headerVideoPlaceholder: "TMP_headerVideoPlaceholder",
  TMP_errorInvalidUrl: "TMP_errorInvalidUrl",
  TMP_nameProposal: "TMP_nameProposal",
  TMP_status: "TMP_status",
  TMP_balance: "TMP_balance",
  TMP_autoRenewalStatus: "TMP_autoRenewalStatus",
  TMP_autoRenewalThreshold: "TMP_autoRenewalThreshold",
  TMP_autoRenewalAmount: "TMP_autoRenewalAmount",
  TMP_addFunds: "TMP_addFunds",
  TMP_editAutoRenewalAmount: "TMP_editAutoRenewalAmount",
  TMP_editAutoRenewalThreshold: "TMP_editAutoRenewalThreshold",
  TMP_errorInvalidCharacter: "TMP_errorInvalidCharacter",
  TMP_errorImageExtensions: "TMP_errorImageExtensions",
  TMP_errorVideoExtensions: "TMP_errorVideoExtensions",
  TMP_buttonAddVariable: "TMP_buttonAddVariable",
  TMP_newLineFooterWarning: "TMP_newLineFooterWarning",
  TMP_emojiWarning: "TMP_emojiWarning",
  TMP_searchPlaceholder: "TMP_searchPlaceholder",
  TMP_cat12: "TMP_cat12",
  TMP_cat13: "TMP_cat13",
  TMP_cat14: "TMP_cat14",
  TMP_tooltipReplicate: "TMP_tooltipReplicate",
  TMP_tooltipAddLangToTemplate: "TMP_tooltipAddLangToTemplate",
  TMP_tooltipEditTemplate: "TMP_tooltipEditTemplate",
  TMP_tooltiMetaSync: "TMP_tooltiMetaSync",
  TMP_ditFieldDisabled: "TMP_ditFieldDisabled",
  TMP_editSuccess: "TMP_editSuccess",
  TMP_editFieldDisabled: "TMP_editFieldDisabled",
  TMP_sampleRequiredValueInfoText: "TMP_sampleRequiredValueInfoText",
  TMP_metaSync: "TMP_metaSync",
  TMP_cat15: "TMP_cat15",
  TMP_cat16: "TMP_cat16",
  TMP_categoryTooltip_1: "TMP_categoryTooltip_1",
  TMP_categoryTooltip_2: "TMP_categoryTooltip_2",
  TMP_categoryTooltip_3: "TMP_categoryTooltip_3",
  TMP_categoryRejectionTooltip_1a: "TMP_categoryRejectionTooltip_1a",
  TMP_categoryRejectionTooltip_1b: "TMP_categoryRejectionTooltip_1b",
  TMP_categoryRejectionTooltip_2_embedded:
    "TMP_categoryRejectionTooltip_2_embedded",
  TMP_categoryRejectionTooltip_2_classic:
    "TMP_categoryRejectionTooltip_2_classic",
  TMP_categoryRejectionTooltip_3: "TMP_categoryRejectionTooltip_3",
  TMP_allowCategoryChange: "TMP_allowCategoryChange",
  TMP_headerMedia5: "TMP_headerMedia5",
  TMP_allowCategoryChangeTooltip_1: "TMP_allowCategoryChangeTooltip_1",
  TMP_allowCategoryChangeTooltip_2: "TMP_allowCategoryChangeTooltip_2",
  TMP_headerLocationPlaceholder: "TMP_headerLocationPlaceholder",
  TMP_timeIndicatorTooltip: "TMP_timeIndicatorTooltip",
  TMP_securityDesclaimer: "TMP_securityDesclaimer",
  TMP_expirationWarning: "TMP_expirationWarning",
  TMP_timeIndicator: "TMP_timeIndicator",
  TMP_timeIndicatorErrorMessage: "TMP_timeIndicatorErrorMessage",
  TMP_textCopiedToClipboard: "TMP_textCopiedToClipboard",
  TMP_seeAllOptions: "TMP_seeAllOptions",
};

export default keys;
