// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.BOX_fetchingChannels]: "translation_missing",
  //  [keys.BOX_setUpInbox]: "translation_missing",
  //  [keys.BOX_loadingText]: "translation_missing",
  //  [keys.BOX_selectTheNumbers]: "translation_missing",
  //  [keys.BOX_apiKeyWarning]: "translation_missing",
  //  [keys.BOX_createBtn]: "translation_missing",
  //  [keys.BOX_selectInboxLabel]: "translation_missing",
  //  [keys.BOX_buttonLabelConnectNew]: "translation_missing",
  //  [keys.BOX_disclaimerOneNumber]: "translation_missing",
};

export default translate;
