// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.DASH_welcome]: "Bienvenido",
  [keys.DASH_welcomeBack]: "Bienvenido de nuevo",
  [keys.DASH_mainTemplateManagerInfo]:
    "¿Ha registrado ya algún template message para su número? Diríjase al template manager para crear y organizar sus plantillas.",
  [keys.DASH_learnMore]: "Más información",
  [keys.DASH_goToTemplates]: "Ir al Template Manager",
  [keys.DASH_nextSteps]: "Próximos pasos",
  [keys.DASH_firstSteps]: "Primeros pasos",
  [keys.DASH_yourWabasList]: "Tus cuentas de WhatsApp",
  [keys.DASH_resources]: "Recursos",
  [keys.DASH_faq]: "Preguntas más frecuentes",
  [keys.DASH_mainVerified]:
    "Su nuevo número <NUMBER> está casi listo para su uso. ¿Ha verificado ya su empresa?",
  [keys.DASH_mainGoToFbmButton]: "Ir a Meta Business Verification",
  [keys.DASH_mainDocsLearnMore]:
    "Saber más sobre las características del 360dialog Client Hub.",
  [keys.DASH_showDocsButton]: "Mostrar documentación",
  [keys.DASH_popupIntro]:
    "Su nuevo número <NUMBER> está casi listo para su uso. Aquí está lo que necesita hacer ahora:",
  [keys.DASH_popupBullet1]:
    "Crear una API key para usar dentro de la aplicación de su partner de integración.",
  [keys.DASH_popupBullet2]:
    "Si su empresa aún no está verificada por Meta, debe comenzar el proceso de Business Verification dentro del Meta Business Manager.",
  [keys.DASH_generateKeyButton]: "Crear API key",
  [keys.DASH_fetchingNumberPopup]:
    "Actualmente estamos preparando su número. Esto puede tomar unos momentos. Espere por favor.",
  [keys.DASH_settingUpPopup]:
    "Iniciando su cliente hosteado de WhatsApp Business API. Solo unos momentos más.",
  [keys.DASH_numbersSectionEmpty]: "No tiene ningún número registrado.",
  [keys.DASH_integrationPartnerInfo]: "Su socio de integración es <PARTNER>.",
  [keys.DASH_lastStepApi]:
    "¡Genial! Acaba de crear su API key personal para su nuevo número <NUMBER>. Ahora, diríjase al Meta Business Manager para comenzar el proceso de verificación de su empresa.",
  [keys.DASH_lastStepForwarding]:
    "¡Genial! Acaba de permitir el reenvío de mensajes para su nuevo número <NUMBER>. Ahora, diríjase al Meta Business Manager para comenzar el proceso de verificación de su empresa.",
  [keys.DASH_lastStepTitle]: "Verificación de Meta Business",
  [keys.DASH_lastStepButtonLabel]: "Ir al Meta Business Manager",
  [keys.DASH_lastStepButtonLabelClose]: "Ya he verificado mi empresa",
  [keys.DASH_lastStepVerifiedTitle]: "Todo configurado...",
  [keys.DASH_lastStepVerifiedText]:
    "¡...y listo para empezar! Con su API key personal para su nuevo número <NUMBER>, puede comenzar a usar la WhatsApp Business API de inmediato. Como su negocio ya está verificado por Meta, ya no hay nada más que deba hacer.",
  [keys.DASH_timeoutErrorText]:
    "Lamentablemente, no pudimos configurar su número. Parece que no terminó el proceso de registro. Haga clic a continuación para finalizar la configuración de su cuenta de WhatsApp Business. ",
  [keys.DASH_popupBulletBypassBeta]:
    "Asegúrate de rellenar la información de tu empresa en el Meta Business Manager para tener acceso completo a la API.",
  [keys.DASH_submissionSuccessHelptext]: "Enviado con exito",
  [keys.DASH_serverDelayMessage]:
    "Lo sentimos, pero parece que nuestros servidores están recibiendo muchas solicitudes en este momento. Su número estará listo en unos minutos. Enviaremos un correo electrónico para seguir con la configuración.",
  [keys.DASH_buttonLabelNewButton]: "Crear un nuevo usuario",
  [keys.DASH_popupBullet3]:
    "Crea una clave API para utilizarla dentro de su propia aplicación o configure su bandeja de entrada con get.chat",
  [keys.DASH_popupBullet5]:
    "Su cuenta de la API de WhatsApp con 360dialog ahora incluye una integración gratuita de la bandeja de entrada con nuestro partner get.chat.NEnget.chat es un software que le permite conectar su cuenta de la API de WhatsApp con varios usuarios y varias aplicaciones (CRM, libreta de direcciones, sistema de tickets) simultáneamente.NAtención: Si eliges activar la bandeja de entrada, no podrás crear una clave API para integrarla en tu propia aplicación.",
  [keys.DASH_createBtnInbox]:
    "Crear mi bandeja de entrada get.chat para <NUMBER>",
  [keys.DASH_generateKeyButtonInstead]: "Genere una clave API en su lugar",
  [keys.DASH_chooseDifferentName]: "Elige un Display Name diferente",
  [keys.DASH_displayNamePendingMessage]:
    "Whatsapp está tardando un poco más de lo habitual en aprobar el nombre que has elegido. Esto puede llevar más tiempo, por lo que le notificaremos el estado de este procedimiento por correo electrónico.",
  [keys.DASH_displayNameRejectedMessage]:
    "WhatsApp rechazó el Display Name que ha elegido <br/> Puede revisar la guía oficial de Display Names <LINK> <br/> Elija un Display Name diferente",
  [keys.DASH_displayNameRejectedMessagePipe]:
    "El Display Name que ha elegido ha sido rechazado por WhatsApp. Puede revisar la guía oficial sobre los Display Names <ENLACE>https://www.facebook.com/business/help/338047025165344 | aquí <ENLACE>.",
  [keys.DASH_integrationSetup]: "Configuración de integración",
  [keys.DASH_migrateNumberFromAnotherBSP]: "Migrar número de otro BSP",
  [keys.DASH_useClassicSignup]: "Usar el registro clásico",
  [keys.DASH_addNewPhoneNumber]: "Agregar un número de teléfono nuevo",
  [keys.DASH_importFromWBA]: "Importar desde la App de WhatsApp Business",
  [keys.DASH_importFromBS]: "Importar desde otro BSP",
  //  [keys.DASH_stackSetup]: "translation_missing",
  //  [keys.DASH_test]: "translation_missing",
  //  [keys.DASH_testWebhookSuccess]: "translation_missing",
  //  [keys.DASH_testWebhookError]: "translation_missing",
  //  [keys.DASH_integrationSettings]: "translation_missing",
  //  [keys.DASH_partnerWebhookUrl]: "translation_missing",
  //  [keys.DASH_webhookUrlTootip]: "translation_missing",
  //  [keys.DASH_redirectUrl]: "translation_missing",
  //  [keys.DASH_redirectUrlToolip]: "translation_missing",
  //  [keys.DASH_defaultHosting]: "translation_missing",
  //  [keys.DASH_cloudApiHosted]: "translation_missing",
  //  [keys.DASH_onPremiseHosted]: "translation_missing",
  //  [keys.DASH_infoOnHostingPlatform]: "translation_missing",
  //  [keys.DASH_partnerUpdatedSuccess]: "translation_missing",
  //  [keys.DASH_redirectUrlNorValid]: "translation_missing",
  //  [keys.DASH_paymentPlan]: "translation_missing",
  //  [keys.DASH_vsPreviousWeek]: "translation_missing",
  //  [keys.DASH_conversations]: "translation_missing",
  //  [keys.DASH_expenses]: "translation_missing",
  //  [keys.DASH_lastWeekActivity]: "translation_missing",
  //  [keys.DASH_channel]: "translation_missing",
};

export default translate;
