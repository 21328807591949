import { UIResponseLoginBlob } from "./auth.types";

export class UIModelLogin {
  accessToken?: string;
  expiresIn?: string;
  access_token?: string;

  constructor(response: UIResponseLoginBlob) {
    this.accessToken = response.accessToken;
    this.expiresIn = response.expiresIn;
  }
}
