import * as Types from "./partners.types";
import nullWhenEmptyString from "@/utilities/nullWhenEmptyString";
import { unpackSingleClient } from "../clients/clients.mapper";

export const unpackListPartner = (
  response: Types.IServerResponseListPartner
): Types.IUIResponseListPartner => ({
  createdAt: response.created_at,
  modifiedAt: response.modified_at,
  id: response.id,
  externalId: response.external_id,
  name: response.name,
  defaultOrganisation: response.default_organisation || "",
  status: response.status,
  license: response.license,
  operationUserId: response.operation_user_id,
  contactInfo: unpackContactInfo(response.contact_info),
});

export const unpackPartners = (
  response: Types.IServerResponsePartners
): Types.IUIResponsePartners => ({
  total: response.total,
  partners: response.partners.map((partner) => unpackPartner(partner)),
});

export const unpackPartner = (
  response: Types.IServerResponsePartner
): Types.IUIResponsePartner => ({
  id: response.id,
  name: response.name,
  defaultOrganisation: response.default_organisation || "",
  status: response.status,
  orderDocument: response.order_document || "",
  license: response.license || "",
  salesUserId: response.sales_user_id || "",
  financeUserId: response.finance_user_id || "",
  operationUserId: response.operation_user_id || "",
  dialogSalesUserId: response.dialog_sales_user_id || "",
  businessInfo: unpackBusinessInfo(response.business_info),
  paymentRequired: response.payment_required,
  allowClientToAddPhoneNumber: response.allow_client_to_add_phone_no,
  contactInfo: unpackContactInfo(response.contact_info),
  modifiedAt: response.modified_at,
  createdAt: response.created_at,
  brandName: response.brand_name || "",
  logoUrl: response.logo_url,
  deeplinkUrl: response.onboarding_deeplink || "",
  enabledForChatSupport: response.enabled_for_chat_support || false,
  partnerRedirectUrl: response.partner_redirect_url || "",
  paymentPlan: response.payment_plan,
  settings: response.settings,
  cloudApiPermitted: response.cloud_api_permitted,
  billing_system: response.billing_system,
});

export const unpackBusinessInfo = (
  response: Types.IServerResponseBusinessInfo
): Types.IUIResponseBusinessInfo => ({
  name: response.name || "",
  organisation: response.organisation || "",
  notes: response.notes || "",
  markets: response.markets || "",
  timezone: response.timezone || "",
  description: response.description || "",
});

export const unpackContactInfo = (
  response: Types.IServerResponseContactInfo
): Types.IUIResponseContactInfo => ({
  website: response.website || "",
  phoneNumber: response.phone_number || "",
  email: response.email || "",
  language: response.language || "",
  address: unpackContactInfoAdress(
    response.address || {
      country: "",
      street_name: "",
      city: "",
      zip_code: "",
      state: "",
    }
  ),
});

export const unpackContactInfoAdress = (
  response: Types.IServerResponseContactInfoAdress
): Types.IUIResponseContactInfoAdress => ({
  country: response.country || "",
  streetName: response.street_name || "",
  city: response.city || "",
  zipCode: response.zip_code || "",
  state: response.state || "",
});

export const packPartner = (
  request: Types.PartnerFormValues
): Types.IServerRequestPartner => ({
  name: request.name,
  license: request.license,
  default_organisation: nullWhenEmptyString(request.defaultOrganisation),
  order_document: nullWhenEmptyString(request.orderDocument),
  sales_user_id: nullWhenEmptyString(request.salesUserId),
  finance_user_id: nullWhenEmptyString(request.financeUserId),
  operation_user_id: nullWhenEmptyString(request.operationUserId),
  dialog_sales_user_id: nullWhenEmptyString(request.dialogSalesUserId),
  business_info: {
    notes: nullWhenEmptyString(request.notes),
    markets: nullWhenEmptyString(request.markets),
    timezone: nullWhenEmptyString(request.timezone),
    description: nullWhenEmptyString(request.description),
  },
  contact_info: {
    website: nullWhenEmptyString(request.website),
    phone_number: nullWhenEmptyString(request.phoneNumber),
    email: nullWhenEmptyString(request.email),
    language: nullWhenEmptyString(request.language),
    address: {
      country: nullWhenEmptyString(request.country),
      street_name: nullWhenEmptyString(request.streetName),
      city: nullWhenEmptyString(request.city),
      zip_code: nullWhenEmptyString(request.zipCode),
      state: nullWhenEmptyString(request.state),
    },
  },
  id: request.id,
  brand_name: nullWhenEmptyString(request.brandName),
  onboarding_deeplink: nullWhenEmptyString(request.deeplinkUrl),
  partner_redirect_url: nullWhenEmptyString(request.partnerRedirectUrl),
});

export const unpackFacebookBusinessAccountInfo = (
  response: Types.IServerResponseFacebookBusinessAccountInfo
): Types.IUIResponseFacebookBusinessAccountInfo => ({
  id: response.id,
  name: response.name,
  primaryPage: response.primary_page
    ? {
        name: response.primary_page.name || "",
        id: response.primary_page.id,
      }
    : null,
  vertical: response.vertical || "",
  timezoneId: response.timezone_id ? response.timezone_id.toString() : "",
  verificationStatus: response.verification_status || "",
  link: response.link || "",
  verticalId: response.vertical_id ? response.vertical_id.toString() : "",
  picture: response.picture
    ? {
        data: {
          url: response.picture.data.url || "",
        },
      }
    : null,
  profilePictureUrl: response.profile_picture_uri || "",
  client: response.client ? unpackSingleClient(response.client) : null,
});

export const unpackFilterOptions = (
  response: Types.IServerResponseFilterOptions
): Types.IUIResponseFilterOptions => {
  return {
    partnerId: response.partner_id,
    type: response.type,
    enabled: response.enabled,
    content: {
      namespaceLanguages: response.content.namespace_languages,
      timezones: response.content.timezones,
    },
  };
};

export const unpackPartnerUser = (
  response: Types.IServerResponsePartnerUser
): Types.IUIResponsePartnerUser => ({
  createdAt: response.created_at,
  modifiedAt: response.modified_at,
  blocked: response.blocked,
  emailVerified: response.email_verified,
  id: response.id,
  login: response.login,
  name: response.name,
  email: response.email,
  picture: response.picture,
  password: response.password,
  permissions: response.permissions,
});

export const packPartnerUser = (
  response: Types.IFormValuesPartnerUser
): Types.IServerRequestPartnerUser => ({
  name: response.name,
  email: response.email,
  picture: nullWhenEmptyString(response.picture),
  password: nullWhenEmptyString(response.password),
});

export const unpackAPIKey = (
  response: Types.ServerResponseApiKey
): Types.UIResponseApiKey => ({
  id: response.id,
  address: response.address,
  apiKey: response.api_key,
  appId: response.app_id,
  partnerId: response.partner_id,
});

export const unpackPartnerPaymentInfo = (
  response: Types.IServerResponsePartnerPaymentInfo
): Types.IUIResponsePartnerPaymentInfo => ({
  paymentPlan: {
    price: response.payment_plan.price,
    currency: response.payment_plan.currency,
  },
  paymentRequired: response.payment_required,
  publishableKey: response.publishable_key,
  brandName: response.brand_name,
  logoUrl: response.logo_url,
  campaign: response.campaign || undefined,
  id: response.id,
  pbsEnabled: response.pbs_enabled,
  billingSystem: response.billing_system,
  settings: response.settings,
});

export const unpackPartnerShort = (
  response: Types.IServerResponsePartnerShort
): Types.UIResponsePartnerShort => ({
  id: response.partner_id,
});

export const unpackPartnerChangeInfo = (
  response: Types.IServerResponsePartnerChangeRequestsInfo
): Types.IUIResponsePartnerChangeRequestsInfo => ({
  filters: {
    newPartnerId: response.filters.new_partner_id,
  },
  count: response.count,
  limit: response.limit,
  offset: response.offset,
  sort: response.sort,
  total: response.total,
  partnerChangeRequests: response.partner_change_requests.map((item) =>
    unpackPartnerChangeRequest(item)
  ),
});

export const unpackPartnerChangeRequest = (
  item: Types.IServerResponsePartnerChangeRequest
): Types.IUIResponsePartnerChangeRequest => ({
  clientId: item.client_id,
  createdAt: item.created_at,
  createdBy: item.created_by,
  id: item.id,
  messageForNewPartner: item.message_for_new_partner,
  migrationDate: item.migration_date,
  modifiedAt: item.modified_at,
  modifiedBy: item.modified_by,
  newPartnerId: item.new_partner_id,
  oldPartnerId: item.old_partner_id,
  rejectionReason: item.rejection_reason,
  status: item.status,
  clientActionInfo: item.client_action_info,
});

export const packRejectionData = (response: {
  rejectionReason: string;
}): { rejection_reason: string } => ({
  rejection_reason: response.rejectionReason,
});
