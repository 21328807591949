import API from "@openreplay/tracker/cjs";

let tracker: API;

const actions = {
  initOpenReplay: async (PROJECT_KEY: string) => {
    if (!tracker && process.env.NEXT_PUBLIC_APP_ENV === "production") {
      const Tracker = await import("@openreplay/tracker/cjs");
      tracker = new Tracker.default({
        projectKey: PROJECT_KEY,
        network: {
          capturePayload: true,
          captureInIframes: true,
          failuresOnly: false,
          ignoreHeaders: false,
          sessionTokenHeader: false,
        },
      });
    }
    return tracker;
  },
  setUserID: (id: string) => {
    if (tracker) {
      tracker.setUserID(id);
    }
  },
  stopOpenReplay: () => {
    if (tracker) {
      tracker.stop();
      tracker = null;
    }
  },
};

export const OpenReplay = actions;
