import * as Types from "./user.types";
export class UIModelHubUsers {
  list: UIModelHubUser[];
  total: number;

  constructor(response: Types.UIResponseHubUsers) {
    this.list = response.users
      ? response.users.map((user) => new UIModelHubUser(user))
      : [];
    this.total = response.total;
  }
}

export class UIModelHubUser {
  id: string;
  login: string;
  name: string;
  email: string;
  emailVerified: boolean;
  picture: string;
  blocked: boolean;
  createdAt: Date;
  createdBy: {
    userId: string;
    userName: string;
  };
  modifiedAt: Date;
  modifiedBy: {
    userId: string;
    userName: string;
  };
  permissions: Types.permissionsTypes[];

  constructor(params: Types.UIResponseHubUser) {
    this.update(params);
  }

  update(params: Types.UIResponseHubUser) {
    this.id = params.id;
    this.name = params.name;
    this.email = params.email;
    this.emailVerified = params.emailVerified;
    this.picture = params.picture;
    this.createdAt = new Date(params.createdAt);
    this.blocked = params.blocked;
    this.createdBy = {
      userId: params.createdBy.userId,
      userName: params.createdBy.userName,
    };
    (this.modifiedAt = new Date(params.modifiedAt)),
      (this.modifiedBy = {
        userId: params.modifiedBy.userId,
        userName: params.modifiedBy.userName,
      });
    this.permissions = params.permissions;
  }
}
