import { MPInstancesHub } from "@/api/RequestAxios/RequestAxiosHub";
import { IQueryParams, packQueryParams } from "../utils";
import PublicRequester from "@/api/RequestAxios/RequestAxiosHubPublic";
import {
  UIModelPartners,
  UIModelPartner,
  UIModelFacebookBusinessAccountInfo,
  UIModelFilterOptions,
  UIModelPartnerUser,
  UIModelPartnerPaymentInfo,
} from "./partners.model";
import {
  unpackPartners,
  unpackPartner,
  packPartner,
  unpackFacebookBusinessAccountInfo,
  unpackFilterOptions,
  unpackPartnerUser,
  packPartnerUser,
  unpackAPIKey,
  unpackPartnerPaymentInfo,
} from "./partners.mapper";
import * as Types from "./partners.types";
import { toJS } from "mobx";
import qs from "qs";
import removeNullValues from "@/utilities/removeNullValues";

const partnersURL = () => `/partners`;
const partnerURL = ({ partnerId }: { partnerId: string }) =>
  `/partners/${partnerId}`;
const partnerBusinessInfoURL = ({
  partnerId,
  fbBusinessId,
}: {
  partnerId: string;
  fbBusinessId: string;
}) => `/partners/${partnerId}/info/facebook_account/${fbBusinessId}`;
const partnerFilterOptionsURL = ({ partnerId }: { partnerId: string }) =>
  `/partners/${partnerId}/configs/filter_options`;
const partnerPublicFilterOptionsURL = ({ partnerId }: { partnerId: string }) =>
  `/partners/${partnerId}/public_configs/filter_options`;
const partnerUserURL = ({ partnerId }: { partnerId: string }) =>
  `/partners/${partnerId}/users`;
const partnerAPIKeyURL = ({ partnerId }: { partnerId: string }) =>
  `/partners/${partnerId}/api_keys`;
const partnerPaymentInfoURL = ({
  partnerId,
  coupon,
}: {
  partnerId: string;
  coupon?: string;
}) =>
  coupon
    ? `/partners/${partnerId}/data/public?coupon=${coupon}`
    : `/partners/${partnerId}/data/public`;

const paymentPlansURL = (partnerId: string) =>
  `partners/${partnerId}/payment_plan`;

export const getPartners = async (
  queryParams?: IQueryParams
): Promise<UIModelPartners> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponsePartners>(
      partnersURL(),
      {
        params: queryParams && packQueryParams(queryParams),
        paramsSerializer: (params) => {
          const { filters, ...otherParams } = params;
          const serializedQueryString = qs.stringify(otherParams, {
            arrayFormat: "repeat",
          });

          return `${serializedQueryString}&filters=${JSON.stringify(
            toJS(filters)
          )}`;
        },
      }
    );

  return new UIModelPartners(unpackPartners(data));
};

export const getPartner = async ({
  partnerId,
}: {
  partnerId: string;
}): Promise<UIModelPartner> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponsePartner>(
      partnerURL({ partnerId })
    );

  return new UIModelPartner(unpackPartner(data));
};

export const addPartner = async (
  payload: Types.PartnerFormValues
): Promise<Types.IUIResponsePartner> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponsePartner>(
      partnersURL(),
      packPartner(payload)
    );

  return unpackPartner(data);
};

export const getPartnerPaymentInfo = async ({
  partnerId,
  coupon,
}: {
  partnerId: string;
  coupon?: string;
}): Promise<UIModelPartnerPaymentInfo> => {
  const { data } =
    await PublicRequester.requester.get<Types.IServerResponsePartnerPaymentInfo>(
      partnerPaymentInfoURL({ partnerId, coupon })
    );
  return new UIModelPartnerPaymentInfo(unpackPartnerPaymentInfo(data));
};

export const editPartner = async (
  { partnerId }: { partnerId: string },
  payload: Types.PartnerFormValues
): Promise<UIModelPartner> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.patch<Types.IServerResponsePartner>(
      partnerURL({ partnerId }),
      removeNullValues(packPartner(payload))
    );

  return new UIModelPartner(unpackPartner(data));
};

export const getFacebookBusinessAccountInfo = async ({
  partnerId,
  fbBusinessId,
}: {
  partnerId: string;
  fbBusinessId: string;
}): Promise<UIModelFacebookBusinessAccountInfo> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseFacebookBusinessAccountInfo>(
      partnerBusinessInfoURL({
        partnerId,
        fbBusinessId,
      })
    );

  return new UIModelFacebookBusinessAccountInfo(
    unpackFacebookBusinessAccountInfo(data)
  );
};

export const getPartnerFilterOptions = async ({
  partnerId,
}: {
  partnerId: string;
}): Promise<UIModelFilterOptions> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseFilterOptions>(
      partnerFilterOptionsURL({ partnerId })
    );

  return new UIModelFilterOptions(unpackFilterOptions(data));
};

export const getPublicFilterOptions = async ({
  partnerId,
}: {
  partnerId: string;
}): Promise<UIModelFilterOptions> => {
  const { data } =
    await PublicRequester.requester.get<Types.IServerResponseFilterOptions>(
      partnerPublicFilterOptionsURL({ partnerId })
    );

  return new UIModelFilterOptions(unpackFilterOptions(data));
};

export const addPartnerUser = async (
  { partnerId }: { partnerId: string },
  payload: Types.IFormValuesPartnerUser
): Promise<UIModelPartnerUser> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponsePartnerUser>(
      partnerUserURL({ partnerId }),
      packPartnerUser(payload)
    );

  return new UIModelPartnerUser(unpackPartnerUser(data));
};

export const addApiKey = async (
  { partnerId }: { partnerId: string },
  payload: {
    phoneNumber: string;
  }
): Promise<Types.UIResponseApiKey> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.ServerResponseApiKey>(
      partnerAPIKeyURL({ partnerId }),
      {
        phone_number: payload.phoneNumber,
      }
    );

  return unpackAPIKey(data);
};

export const triggerActivation = async ({
  partnerId,
}: {
  partnerId: string;
}): Promise<any> => {
  const payload = {};
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    `${partnerURL({ partnerId })}/control/trigger_activation`,
    payload
  );

  return data;
};

export const approve = async ({
  partnerId,
}: {
  partnerId: string;
}): Promise<any> => {
  const payload = {};
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    `${partnerURL({ partnerId })}/control/approve`,
    payload
  );

  return data;
};

export const addLogo = async ({
  file,
  partnerId,
  onUploadProgress,
}: {
  partnerId: string;
  file: FormData;
  onUploadProgress: (ev: any) => void;
}): Promise<UIModelPartner> => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    `${partnerURL({ partnerId })}/control/upload_logo`,
    file,
    {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: onUploadProgress,
    }
  );

  return new UIModelPartner(unpackPartner(data));
};

export const getPaymentPlans = async (partnerId: string) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get(
    paymentPlansURL(partnerId)
  );

  return data;

  // if (paymentMethod === "pbs") {
  //   const pbsResponse = data as Types.PBSPaymentPlans;

  //   return pbsResponse.plans.map((plan) => ({
  //     price: plan.priceAmount[0].price,
  //     currency: plan.currency,
  //   }));
  // } else if (paymentMethod === "stripe") {
  //   const stripeResponse = data as Types.StripePaymentPlan;
  //   return {
  //     price: stripeResponse.payment_plan.price,
  //     currency: stripeResponse.payment_plan.currency,
  //   };
  // }
};
