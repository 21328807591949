// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.KEY_existingTitle]: "translation_missing",
  //  [keys.KEY_existingHelp1]: "translation_missing",
  //  [keys.KEY_existingHelp2]: "translation_missing",
  //  [keys.KEY_newTitle]: "translation_missing",
  //  [keys.KEY_newHelp]: "translation_missing",
  //  [keys.KEY_key]: "translation_missing",
  //  [keys.KEY_copy]: "translation_missing",
  //  [keys.KEY_copied]: "translation_missing",
  //  [keys.KEY_address]: "translation_missing",
  //  [keys.KEY_confirmCopy]: "translation_missing",
  //  [keys.KEY_partnerInfoText]: "translation_missing",
  //  [keys.KEY_partnerInfoButtonLabel]: "translation_missing",
  //  [keys.KEY_generateKeyIntro]: "translation_missing",
};

export default translate;
