import * as Types from "./waba.types";
import { UIModelIntegration } from "../channels/channels.model";

export const unpackWabas = (
  payload: Types.IServerResponseWabas
): Types.UIResponseWabas => ({
  total: payload.total,
  integrations: payload.integrations.map((integration) =>
    unpackIntegration(integration)
  ),
});

export const unpackIntegration = (
  payload: Types.IServerResponseIntegration
): Types.UIResponseIntegration => ({
  app_id: payload.app_id,
  created_at: payload.created_at,
  id: payload.id,
  modified_at: payload.modified_at,
  stack_id: payload.stack_id,
  state: payload.state,
  type: payload.type,
  address: payload?.auth?.address ? payload.auth.address : "",
  password: payload?.auth?.password ? payload.auth.password : "",
  username: payload?.auth?.username ? payload.auth.username : "",
  token: payload.token,
  parameters: payload.parameters,
  enabled: payload.enabled,
  premium: payload.premium,
  hostingPlatformType: payload.hosting_platform_type,
});

export const newIntegrationModelToOldResponse = (
  integration: UIModelIntegration
): Types.UIResponseIntegration => {
  return {
    app_id: integration.appId,
    created_at: "",
    id: integration.id,
    modified_at: "",
    address: "",
    password: "",
    username: "",
    stack_id: integration.stackId,
    state: integration.state,
    type: integration.type,
    token: integration.token,
    enabled: integration.enabled,
    parameters: {
      certificate: integration.parameters?.certificate,
      app_name: integration.parameters?.appName,
      organisation: integration.parameters?.organisation,
      default_language: integration.parameters?.defaultLanguage || "",
      namespace: integration.parameters?.namespace || "",
      verification_method: integration.parameters?.verificationMethod,
    },
    premium: integration.premium,
    hostingPlatformType: integration.hostingPlatformType,
  };
};
