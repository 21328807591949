import { MPInstancesHub } from "@/services/api/RequestAxios/RequestAxiosHub";
import { unpackServerLogin, unpackSignUp } from "./auth.mapper";
import * as types from "./auth.types";
import { stringifyQueryParams } from "../utils";

import { UIModelLogin } from "./auth.model";

const fetchSocialLoginUrl = () => `/token_from_facebook`;
const fetchResetPasswordUrl = () => "/dbconnections/change_password";
const authorizeSocialUrl = () => "/authorize";
const embeddedSignUp = ({ partnerId }: { partnerId: string }) =>
  `/public/partners/${partnerId}/signup`;
const retrieveOtpInfoUrl = () => `/exports/user_otp_status`;

export const retrieveOtpInfo = async () => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<types.IServerResponseOtpStatus>(
      retrieveOtpInfoUrl()
    );
  return data;
};

export const fetchSocialLogin = async (
  payload: types.SocialLoginPayload
): Promise<UIModelLogin> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<types.ServerResponseLoginBlob>(
      fetchSocialLoginUrl(),
      payload
    );
  return new UIModelLogin(unpackServerLogin(data));
};

export const fetchResetPassword = async (payload: types.resetPassword) => {
  const { data } = await MPInstancesHub.buffedInstances.auth0Api.post(
    fetchResetPasswordUrl(),
    payload
  );
  return data;
};

export const authorizeSocial = async (payload: types.authorizeSocial) => {
  const response = await MPInstancesHub.buffedInstances.auth0Api.get(
    authorizeSocialUrl(),
    {
      params: payload,
      paramsSerializer: stringifyQueryParams,
      // maxRedirects: 0
    }
  );
  // console.log(response.request.responseURL)
  return response;
};

export const signUp = async ({
  partnerId,
  payload,
}: {
  payload: types.IUISignUpRequest;
  partnerId: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    embeddedSignUp({ partnerId }),
    payload
  );

  return unpackSignUp(data);
};

export const socialSignUp = async ({
  partnerId,
  payload,
}: {
  payload: types.IUISocialSignUpRequest;
  partnerId: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    embeddedSignUp({ partnerId }),
    { ...payload }
  );

  return unpackSignUp(data);
};

export const getCannySSOToken = async (): Promise<{ token: string }> => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get<{
    token: string;
  }>("/canny/sso_token");

  return data;
};
