// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  BOX_fetchingChannels: "BOX_fetchingChannels",
  BOX_setUpInbox: "BOX_setUpInbox",
  BOX_loadingText: "BOX_loadingText",
  BOX_selectTheNumbers: "BOX_selectTheNumbers",
  BOX_apiKeyWarning: "BOX_apiKeyWarning",
  BOX_createBtn: "BOX_createBtn",
  BOX_selectInboxLabel: "BOX_selectInboxLabel",
  BOX_buttonLabelConnectNew: "BOX_buttonLabelConnectNew",
  BOX_disclaimerOneNumber: "BOX_disclaimerOneNumber",
};

export default keys;
