// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.CH_showDetailed]: "Detaillierten Status anzeigen",
  [keys.CH_hideDetailed]: "Detaillierten Status verbergen",
  [keys.CH_templatesButton]: "Vorlagen managen",
  [keys.CH_showDetailsButton]: "Details anzeigen",
  [keys.CH_mobButton]: "Message on behalf Anfrage",
  [keys.CH_triggerButton]: "Registrierung triggern",
  [keys.CH_finishButton]: "Registrierung abschließen",
  [keys.CH_keyButton]: "API Schlüssel erstellen",
  [keys.CH_addNumber]: "Eine neue Telefonnummer hinzufügen",
  [keys.CH_exportClientData]: "Kundendaten exportieren",
  [keys.CH_exporting]: "Exportiert ...",
  [keys.CH_filterPlaceholder]: "Alle Status",
  [keys.CH_details]: "Details",
  [keys.CH_templates]: "Vorlagen",
  [keys.CH_billingInfo]: "Abrechnungsinformationen",
  [keys.CH_billingStartDate]: "Abrechnung gestartet (lokale Zeit)",
  [keys.CH_billingStartButtonLabel]: "Abrechnung starten",
  [keys.CH_numberReg]: "Nummerregistrierung",
  [keys.CH_pin]: "Pin Verifikation",
  [keys.CH_defaultLang]: "Standardsprache für Vorlagen",
  [keys.CH_ivrEx]: "IVR existiert",
  [keys.CH_ivrAct]: "IVR aktiv",
  [keys.CH_verificationMethod]: "Verifikationsmethode",
  [keys.CH_voice]: "Sprache",
  [keys.CH_sms]: "SMS",
  [keys.CH_cert]: "Zertifikat",
  [keys.CH_consent]: "Einwilligungsstatus",
  [keys.CH_consentPage]: "Website zur Einwilligung",
  [keys.CH_displayNameErrorEmoji]: "Emoji",
  [keys.CH_displayNameErrorIntro]:
    "Display-Name kann folgendes nicht enthalten:",
  [keys.CH_displayNameErrorWhatsApp]: "'WhatsApp'",
  [keys.CH_displayNameErrorUrl]: "eine URL",
  [keys.CH_displayNameErrorPunct]: "Satzzeichen",
  [keys.CH_displayNameErrorCap]:
    "Der Display-Name kann nur in Großschreibweise erfolgen, wenn das Unternehmen bereits Branding in Großbuchstaben verwendet",
  [keys.CH_displayNameErrorMin]:
    "Der Display-Name muss mindestens 3 Zeichen lang sein",
  [keys.CH_whatsAppDisplayName]: "WhatsApp Display-Name",
  [keys.CH_confirmDisplayNameChange1]:
    "Dein neuer Display-Name wird zunächst von Meta geprüft, bevor die Änderung wirksam wird. Es ist ebenso möglich, dass dein Display-Name abgelehtn wird, wenn er nicht den Meta Richtlinien für Display-Namen entspricht. Bist du dir sicher, dass du den neuen Display-Name einreichen möchtest?",
  [keys.CH_confirmDisplayNameChange2]:
    "Bitte berücksichtige, dass es bis zu 48 Stunden dauern kann, bis dein Display-Name von Metas Trust & Safety Team überprüft wurde.",
  [keys.CH_confirmDisplayNameChangeTooltip]:
    "Du kannst deinen Display-Name bis zu drei Mal innerhalb der ersten 30 Tage nach Erstellung des Profils und Aktivierung der Nummer ändern. Sobald diese Grenze erreicht ist, musst du 30 Tage warten before du erneut eine Display-Name Änderung einreichen kannst.",
  [keys.CH_confirmDisplayNameChange3]:
    "Oft gefragt: Wie oft kann ich meinen Display-Name ändern?",
  [keys.CH_confirmDisplayNameChange4]: "Bitte bestätige diese Aktion",
  [keys.CH_numberReadyToRegister]:
    "Deine Nummer ist noch nicht für die WhatsApp API registriert. Bitte fahre mit der Registrierung fort, um unsere Dienste zu nutzen.",
  [keys.CH_pending]: "Ausstehend",
  [keys.CH_wabaChannelExtId]: "WABA Channel Externe ID",
  [keys.CH_wabaAccountName]: "WhatsApp Business Kontoname",
  [keys.CH_mobName]: "Message on Behalf Name",
  [keys.CH_timezone]: "Zeitzonen-ID",
  [keys.CH_wabaAccountId]: "WhatsApp Business Konto-ID",
  [keys.CH_namespace]: "Namensraum",
  [keys.CH_dangerZone]: "Gefahrenbereich",
  [keys.CH_deleteNumber]: "Nummer löschen",
  [keys.CH_deleteNumberText]:
    "Diese Aktion wird den gehosteten WhatsApp Business API Client zum Löschen freigeben. Die Nummer wird darafhin über die WhatsApp Business API keine weiteren Nachrichten senden oder empfangen können. Beachte: Die Nummer bleibt zunächst in der Nummerliste sichtbar, wird dir aber selbstverständlich nach dem Löschungsdatum entsprechend der vertraglichen Fristen nicht mehr in Rechnung gestellt.",
  [keys.CH_revoke]: "Zahlungsweise im Namen des Kunden widerrufen",
  [keys.CH_revokeText]:
    "Diese Aktion wird deine Zahlungen widerrufen und wird den Kunden dazu auffordern seine eigenen Zahlungsinformationen anzugeben um mit der Nutzung unseres Produkts fortfahren zu können.",
  [keys.CH_requestRevocationButton]: "Widerruf anfordern",
  [keys.CH_deleteSuccess]:
    "Wir haben deine Anfrage erhalten. Deine Nummer wird in kürze terminiert und gelöscht.",
  [keys.CH_deletePopupText]:
    "Wenn du fortfahren möchtest, tippe bitte im folgenden Feld LOESCHEN ein.",
  [keys.CH_confirmationFieldInputValue]: "LOESCHEN",
  [keys.CH_deletePopup1]:
    "Bist du dir sicher, dass du den WhatsApp Business API Client für die Telefonnummer <NUMBER> löschen möchtest?",
  [keys.CH_detailsTab]: "Details",
  [keys.CH_templatesTab]: "Vorlagen",
  [keys.CH_wabaProfileTab]: "Waba Profil",
  [keys.CH_profileTab]: "Profil",
  [keys.CH_integrationTab]: "Integration",
  [keys.CH_commentsTab]: "Kommentare",
  [keys.CH_listHeaderTitle]: "WhatsApp Konto",
  [keys.CH_manageKeys]: "API Schlüssel verwalten",
  [keys.CH_manageKeysHelptext]:
    "Deine Integration ist live und kann Nachrichten senden und empfangen. Wenn du noch keinen API Schlüssel erstellt hast, kannst du das über den unten stehenden Button tun.",
  [keys.CH_addNumberTitle]:
    "Eine neue Telefonnummer zu WhatsApp Business hinzufügen",
  [keys.CH_addNumberHelptext]:
    "Mit diesem Formular fügst du eine weitere Telefonnumer für die WhatsApp Business API hinzu. Sobald du 'Speichern' klickst reichst du offiziell eine neue Nummer bei 360dialog ein, welche dir entsprechend in Rechnung gestellt werden kann.",
  [keys.CH_addNumberSelectHelptext]:
    "Bitte wähle das WhatsApp Konto aus, welches du verwenden möchtest:",
  [keys.CH_addNumberSuccess]:
    "Deine Nummer wurde erfolgreich hinzugefügt und ist in wenigen Momenten verfügbar.",
  [keys.CH_addNumberEmbeddedTitle]: "Verbinde eine weitere Telefonnummer",
  [keys.CH_addNumberEmbeddedHelptext]:
    "Mit diesem Formular fügst du eine weitere Telefonnumer für die WhatsApp Business API hinzu. Durch das Fortfahren mit dem Meta-Widget fügst du offiziell eine neue Nummer bei 360dialog hinzu, welche dir entsprechend in Rechnung gestellt werden kann.",
  [keys.CH_addNumberSelectError]: "Bitte wähle das relevante Konto aus",
  [keys.CH_pendingDeletion]: "Löschung beantragt",
  [keys.CH_pendingDeletionHelptext]:
    "Die Löschung deiner Integration ist beauftraft und in Bearbeitung. Diese Telefonnummer kann weiterhin Nachrichten empfangen und senden, bis sie endgültig am <date> gelöscht wird. Bis dahin hast du die Möglichkeit deine Nummer nochmals zu reaktivieren.",
  [keys.CH_reEnableButton]: "Nummer reaktivieren",
  [keys.CH_revokeNumberDeletionSuccess]:
    "Deine Nummer wurde erfolgreich reaktiviert!",
  [keys.CH_emptyStateTitle]: "Du hast noch keine WhatsApp Konten registriert. ",
  [keys.CH_emptyStateHelptext]:
    "Um deine ersten Konten zu registrieren bitte deine Kunden dein individuelles Anmeldeformular zu nutzen. Alle Konten, die durch dieses Formular eingereicht werden, erscheinen dann hier.",
  [keys.CH_emptyStateHelptextIO]:
    "Um mit dem Einreichen von Konten zu beginnen, muss das integrierte Onboarding implementiert werden, und deine Kunden müssen aufgefordert werden, den Einrichtungsprozess innerhalb deiner Anwendung auszulösen.",
  [keys.CH_openSubmissionForm]: "Anmeldeformular öffnen",
  [keys.CH_openIODoc]: "Lies die Dokumentation zum integrierten Onboarding",
  [keys.CH_copyLink]: "Link kopieren",
  [keys.CH_copiedLink]: "Kopiert",
  [keys.CH_emptyStateTitleClient]:
    "Anscheinend hast du das initiale Onboarding nicht vollständig abgeschlossen.",
  [keys.CH_emptyStateHelptextClient]:
    "Um die WhatsApp Business API nutzen zu können, musst du zunächst ein WhatsApp Business Konto erstellen. Dies kannst du über unseren Onboarding-Prozess machen. Bitte stelle sicher, dass du alle bereitgestellten Schritte abschließt.",
  [keys.CH_emptyStateButtonLabelClient]: "Onboarding abschließen",
  [keys.CH_noChannelsFound]: "Für diese Suchanfrage gibt es kein Ergebnis.",
  [keys.CH_setupProgress]: "Einrichtungsfortschritt",
  [keys.CH_showMore]: "Mehr anzeigen",
  [keys.CH_showLess]: "Weniger anzeigen",
  [keys.CH_percentageDone]: "erledigt",
  [keys.CH_actionRequired]: "Handlungsbedarf",
  [keys.CH_noActionRequired]: "Kein Handlungsbedarf",
  [keys.CH_setupNumberRunning]: "Nummer aktiv",
  [keys.CH_setupVerificationStarted]: "Business Verifikation gestartet",
  [keys.CH_setupVerificationCompleted]: "Business Verifikation abgeschlossen",
  [keys.CH_setupDisplayNameApproved]: "Display-Name bestätigt",
  [keys.CH_setupCommercePolicyChecked]: "WhatsApp Handelsrichtlinien geprüft",
  [keys.CH_ctaBusinessRejected]:
    "Deine Business Verifikation wurde von Meta abgelehnt.",
  [keys.CH_ctaNumberSetup]:
    "Deine Nummer wird gerade aktiviert. Dies wird ein paar Momente dauern. Bitte lade diese Seite in ca. 5 Minuten erneut.",
  [keys.CH_ctaStartVerification]:
    "Öffne den Meta Business Manager, um deine Unternehmensverifikation zu starten.",
  [keys.CH_ctaVerificationPending]:
    "Deine Business Verifikation wird bearbeitet.",
  [keys.CH_ctaDisplayNameRejected]:
    "Dein <LINK>https://developers.facebook.com/docs/whatsapp/guides/display-name/ | Display-Name<LINK> wurde abgelehnt. Bitte nutze den Meta WhatsApp Manager oder kontaktiere unseren Support um den Namen zu ändern und neu einzureichen.",
  [keys.CH_ctaButtonLabelOpenWaManager]: "WhatsApp Manager öffnen",
  [keys.CH_ctaLiveMode]:
    "Gratulation! Dein Konto ist live und du hast nun vollen Zugriff auf die WhatsApp Business API!",
  [keys.CH_ctaButtonLabelFbm]: "Business Manager öffnen",
  [keys.CH_ctaButtonLabelStartVerification]: "Verifikation starten",
  [keys.CH_ctaButtonLabelOpenSettings]: "Einstellungen öffnen",
  [keys.CH_ctaButtonLabelAppeal]: "Einspruchsformular öffnen",
  [keys.CH_ctaCommercePolicyFailed]:
    "Dein Unternehmen erfüllt nicht die <LINK>https://www.whatsapp.com/legal/commerce-policy/?lang=en | WhatsApp Handelsrichtlinien<LINK>. Wenn du der Meinung bist, dass dein Unternehmen diese Richtlinien nicht verletzt, kannst du Einspruch erheben.",
  [keys.CH_ctaPendingDeletion]:
    "Deine Nummer ist zur Löschung vorgemerkt. Es besteht kein weiterer Handlungsbedarf.",
  [keys.CH_tooltipSandbox]: "Erfahre mehr zu den Testumgebungen.",
  [keys.CH_wabaPhotoChanged]:
    "Dein WhatsApp Business Konto Profilfoto wurde erfolgreich geändert!",
  [keys.CH_wabaProfile]: "WhatsApp Business Profil",
  [keys.CH_businessProfile]: "Business Profil",
  [keys.CH_changeProfilePic]: "Profilbild ändern",
  [keys.CH_profilePic]: "Profilbild",
  //  [keys.CH_urlErrorMessage]: "translation_missing",
  [keys.CH_portNumberButtonLabel]: "Nummer zu 360dialog portieren",
  [keys.CH_portNumberText]:
    "Mit diesem Formular kannst du die Portierung einer existierenden Telefonnummer von einem anderen Business Solution Provider zu 360dialog initieren. Nachdem du die Nummer und das Ziel-WhatsApp Business Konto angegeben hast, wirst du Schritt für Schritt durch den Prozess geführt. Durch das Abschicken des Formulars fügst du eine neue Nummer bei 360dialog hinzu, welche dir entsprechend in Rechnung gestellt werden kann.",
  [keys.CH_createNewWaba]: "Neues WhatsApp Business Konto erstellen",
  [keys.CH_phoneNumberAlreadyExists]: "Diese Nummer wurde bereits hinzugefügt",
  [keys.CH_migrateNumberNextStepText]:
    "Bitte folge dem Portierungsprozess im neuen Tab, welches sich geöffnet haben sollte. Falls nicht überprüfe deinen Browser auf blockierte Pop-Ups und <LINK>versuche es erneut<LINK>.",
  [keys.CH_readyForMigrationCtaText]:
    "Bitte öffne die Verifizierungsseite und folge den Schritten, um die Nummernportierung abzuschließen.",
  [keys.CH_waitingForMigrationCtaText]:
    "Die Nummer wurde noch nicht erfolgreich verifiziert. Bitte folge den Schritten auf der Verifizierungsseite und gebe erneut den Registrierungscode ein. Dieser Schritt ist notwendig um die Portierung abzuschließen.",
  [keys.CH_invalidBusinessId]: "Die Meta ID ist nicht gültig",
  [keys.CH_migratedNumberLabel]: "Portierte Nummer",
  [keys.CH_type]: "Typ",
  [keys.CH_retrySetupText]:
    "Deine Integration wurde noch nicht gestartet. Wahrscheinlich ist 2FA noch nicht deaktiviert worden. Bitte versuche das Setup erneut.",
  [keys.CH_setTerminationDate]: "Datum der Löschung",
  [keys.CH_successChannelAdded]: "Channel erfolgreich hinzugefügt",
  [keys.CH_addBusinessInfo]: "Unternehmensinfo hinzufügen",
  [keys.CH_businessInfoPrompt]:
    "Bitte stelle sicher, dass die Unternehmensinfo ausgefüllt hast, damit du die API in vollem Umfang nutzen kannst. Du hast bereits alles hinterlegt, aber möchtest deine Limits erhöhen?",
  [keys.CH_startBusinessVerification]: "Starte die Unternehmensverifizierung",
  [keys.CH_deletedChannel]: "Inaktive Nummer",
  [keys.CH_deletedChannelHelptextShort]:
    "Die Integration wurde gelöscht und diese Nummer ist nicht mehr aktiv. Sie wird unwiderruflich am <date> gelöscht.",
  [keys.CH_deletedChannelHelptext]:
    "Die Integration wurde gelöscht und diese Nummer ist nicht mehr aktiv. Sie wird unwiderruflich am <date> gelöscht. Nach diesem Datum erscheint sie außerdem nicht mehr im 360dialog Client Hub.",
  [keys.CH_reEnableHelptext]:
    "Um deine Nummer zu reaktivieren kontaktiere bitte <PARTNER>.",
  [keys.CH_partnerFallback]: "deinen 360dialog Partner",
  [keys.CH_recentSearches]: "Zuletzt gesucht",
  [keys.CH_applyFilter]: "Filter anwenden",
  [keys.CH_sortByDate]: "Sortiert nach Änderungsdatum",
  [keys.CH_searchBy]: "Suche nach Telefonnummer, Display-Name oder Unternehmen",
  [keys.CH_filters]: "Filter",
  [keys.CH_filterSingular]: "Filter",
  [keys.CH_variableCountError]: "Erlaubte Anzahl an Variablen überschritten.",
  [keys.CH_phoneNumber]: "Telefonnummer",
  [keys.CH_clientEmail]: "E-Mail Adresse",
  [keys.CH_submissionType]: "Registrierungsart",
  [keys.CH_portedNumber]: "Portierte Nummer",
  [keys.CH_channelStatus]: "Channel Status",
  [keys.CH_pendingCancellation]: "Stornierung ausstehend",
  [keys.CH_accountMode]: "Konto-Modus",
  [keys.CH_errorNotEverythingLoaded]:
    "Nicht alle Komponenten der Seite konnten geladen werden. Bitte versuche es erneut. Falls das Problem weiterhin besteht kontaktiere bitte unseren Support.",
  [keys.CH_buttonLabelGotToSupport]: "Zum Support-Hub",
  [keys.CH_emptyStateOption]: "Kein Ergebnis für diese Suche",
  [keys.CH_billingStatus]: "Abrechnungsstatus",
  [keys.CH_cancelledOn]: "Gekündigt am",
  [keys.CH_terminatedOn]: "Terminiert am",
  [keys.CH_startedOn]: "Gestartet am",
  [keys.CH_notStarted]: "Noch nicht gestartet",
  [keys.CH_compressedListView]: "Komprimierte Listenansicht",
  [keys.CH_externalId]: "Externe ID",
  [keys.CH_status]: "Status",
  [keys.CH_deleteNumber]: "Abo kündigen",
  [keys.CH_deleteNumberText]:
    "Diese Aktion wird den gehosteten WhatsApp Business API Client zum Löschen freigeben. Nach dem Terminierungsdatum, welches entsprechend der Kündigungsbedingungen gesetzt wird, kann die Nummer über die WhatsApp Business API keine weiteren Nachrichten senden oder empfangen. Beachte: Die Nummer bleibt zunächst in der Nummerliste sichtbar, wird dir aber selbstverständlich nach dem Löschungsdatum entsprechend der vertraglichen Fristen nicht mehr in Rechnung gestellt.",
  [keys.CH_deleteNumberTextTerms]:
    "Kündigungsbedingungen: Das Abo kann mit einer Kündigungsfrist von dreißig (30) Tagen zum Monatsende gekündigt werden.",
  [keys.CH_deletePopupText]:
    "Wenn du fortfahren möchtest, tippe bitte im folgenden Feld KUENDIGEN ein.",
  [keys.CH_confirmationFieldInputValue]: "KUENDIGEN",
  [keys.CH_deletePopup1]:
    "Bist du dir sicher, dass du das Abo des  WhatsApp Business API Clients für die Telefonnummer <NUMBER> kündigen möchtest?",
  [keys.CH_confirmDeleteNumberFBManagerText1]:
    'Wenn du dein Abonnement kündigst, wird die Nummer in die Warteschlange für die Löschung gestellt. Bitte beachte, dass es sich nicht um eine sofortige Löschung handelt. Der Status der Nummer wird auf "Löschung ausstehend" geändert. Solange sich die Nummer in dieser Phase befindet, kann sie weiterhin wie gewohnt Nachrichten senden und empfangen.',
  [keys.CH_confirmDeleteNumberFBManagerText2]:
    "Die Nummer wird am Ende des laufenden Monats endgültig gelöscht. Zu diesem Zeitpunkt wird die Rechnungsstellung eingestellt.",
  [keys.CH_confirmDeleteNumberFBManagerText3]:
    'Wenn du möchtest, dass diese Nummer vor Ablauf dieser Frist gelöscht wird, aktiviere bitte das Kontrollkästchen "Meine Nummer im Meta Business Manager sofort löschen", bevor du bestätigst.',
  [keys.CH_confirmDeleteNumberFBManager]:
    "Lösche meine Nummer sofort im Meta Business Manager. Bitte beachte, dass diese Aktion automatisch ein Support-Ticket erstellt und zur Löschung der Nummer innerhalb von ca. 48 Stunden führt. Die Nummer ist dann nicht mehr für die Nutzung der WhatsApp Business API verfügbar. Die Verpflichtung zur Zahlung von Lizenzgebühren vor dem Zeitpunkt der vollständigen Löschung gemäß den 360dialog-Bedingungen wird dadurch nicht aufgehoben.",
  [keys.CH_deleteNumberSuccess]:
    "Deine Nummer wird aus dem Meta Business Manager entfernt",
  [keys.CH_messagingLimit]: "Messaging-Einschränkungen",
  [keys.CH_qualityRating]: "Qualitätsbewertung",
  [keys.CH_learnHowDelete]: "Erfahre hier wie du deine Nummer löschen kannst",
  [keys.CH_emailErrorMessage]: "Bitte gib eine gültige E-Mail Adresse ein",
  [keys.CH_partnerButtonDisabled]:
    "Diese Aktion kann aktuell nicht von Partnern ausgeübt werden.",
  [keys.CH_usageAndBalance]: "Nutzung und Saldo",
  [keys.CH_prepaidSettings]: "Prepayment Einstellungen",
  [keys.CH_lastRenewal]: "Letzte Aufladung",
  [keys.CH_usage]: "Nutzung",
  [keys.CH_paymentNotEnabled]: "Kunde ist nicht für Prepayment aktiviert",
  [keys.CH_hasInboxTooltip1]:
    "Diese Nummer wird aktuell in Kombination mit einer get.chat Inbox verwendet. Aus diesem Grund kannst du aktuell keinen API Schlüssel für die 360dialog WhatsApp API erstellen. Du kannst allerdings die WhatsApp API von get.chat nutzen.",
  [keys.CH_hasInboxTooltip2]:
    "Um diese Nummer von deiner Inbox loszulösen und einen 360dialog API key zu erstellen, gehe auf die Detailansicht.",
  [keys.CH_hasInboxActionBlock1]:
    "Diese Nummer wird aktuell in Kombination mit einer get.chat Inbox verwendet. Aus diesem Grund kannst du aktuell keinen API Schlüssel für die 360dialog WhatsApp API erstellen. Du kannst allerdings die WhatsApp API von get.chat nutzen.",
  [keys.CH_hasInboxActionBlock2]:
    "Du kannst deine get.chat Inbox über den Button unten trennen und anschließend einen 360dialog API Schlüssel erstellen.",
  [keys.CH_disconnectInboxButtonLabel]: "Inbox jetzt trennen",
  [keys.CH_disconnectNotificationSuccess]:
    "Deine get.chat Inbox wurde erfolgreich von dieser Nummer getrennt.",
  [keys.CH_disconnectNotificationError]:
    "Wir konnten deine get.chat Inbox leider nicht trennen. Bitte veruche es später erneut. Wenn das Problem weiterhin besteht, kontaktiere unseren Support.",
  [keys.CH_openConsentPage]: "Seite mit Zustimmungserklärung öffnen",
  [keys.CH_close]: "Schließen",
  [keys.CH_accountViolation]: "Richtlinienverstoß",
  [keys.CH_businessManagerDetails]: "Details im Meta Business Manager einsehen",
  [keys.CH_accountRestriction]: "Durchsetzungsmaßnahmen",
  [keys.CH_noInformationAvailable]: "Keine weitere Information verfügbar.",
  [keys.CH_restrictionText]: "Dein Konto wurde von Meta eingeschränkt.",
  [keys.CH_restrictionTextBusiness]:
    "Du darfst keine vom Unternehmen initiierten Nachrichten mehr senden.",
  [keys.CH_restrictionTextUser]:
    "Du darfst auf keine vom Nutzer initiierten Nachrichten mehr antworten.",
  [keys.CH_restrictionTextNumber]:
    "Du darfst keine weiteren Telefonnummern hinzufügen.",
  [keys.CH_clientName]: "Kundenname",
  [keys.CH_premiumPlanPricing]: "Premium Tarif Preismodell",
  [keys.CH_standardPlanPricing]: "Standard Tarif Preismodell",
  [keys.CH_upgrade]:
    "Mit dem Durchführen der folgenden Schritte wirst du den Tarif für die Nummer <NUMBER> upgraden.",
  [keys.CH_downgrade]:
    "Mit dem Durchführen der folgenden Schritte wirst du den Tarif für die Nummer <NUMBER> downgraden.",
  [keys.CH_stantard]: "Standard",
  [keys.CH_premium]: "Premium",
  [keys.CH_month]: "Monat",
  [keys.CH_billedMonthly]: "Monatliche Abrechnung",
  [keys.CH_unlimitedIncomingMsg]:
    "Unbegrenzte eingehende sowie ausgehende WhatsApp Session Nachrichten.",
  [keys.CH_noMarkup]:
    "Nutzer-initiiert und unternehmens-initiierte Konversationen entsprechend <LINK>https://developers.facebook.com/docs/whatsapp/pricing | WhatsApp Preisliste<LINK>. Kein Aufschlag.",
  [keys.CH_98serviceLevel]: "98% Hosting Service Level",
  [keys.CH_99serviceLevel]: "99% Hosting Service Level",
  [keys.CH_premiumSupport]:
    "Support die ganze Woche (24 Stunden Montag - Freitag, 09:00 - 17:00 CET Samstag & Sonntag)",
  [keys.CH_standardSupport]:
    "Support an Wochentagen (02:00 - 22:00 CET Montag - Freitag)",
  [keys.CH_premiumSLA]:
    "Bis zu < 30min Support SLA (für zulässige Ticketarten)",
  [keys.CH_standardSLA]: "Bis zu < 4h Support SLA (für zulässige Ticketarten)",
  [keys.CH_costBreakdown]: "Weiter zur Kostenübersicht",
  [keys.CH_description]: "Beschreibung",
  [keys.CH_qty]: "Q.",
  [keys.CH_amount]: "Betrag",
  [keys.CH_estimatedCost]:
    "Die voraussichtlichen monatlichen Kosten für dein Abo exklusive aller Konversations-Kosten sowie möglichen Pro rata-Zahlungen werden sich wie folgt darstellen.",
  [keys.CH_unitPrice]: "Preis pro Einheit",
  [keys.CH_standardPricing]: "Standard Preis",
  [keys.CH_premiumPricing]: "Premium Preis",
  [keys.CH_premiumAccount]: "Premium Konto",
  [keys.CH_lastDowngradeTooltip1]:
    "Konten können 30 Tage nach dem letzten Downgrade wieder geupgraded werden. Dein letztes Downgrade war am <DATE>.",
  [keys.CH_lastDowngradeTooltip2]:
    "Das nächste mögliche Upgrade kann am <DATE> ausgeführt werden.",
  [keys.CH_lastUpgradeTooltip1]:
    "Konten können 30 Tage nach dem letzten Upgrade wieder gedowngraded werden. Dein letztes Upgrade war am <DATE>.",
  [keys.CH_lastUpgradeTooltip2]:
    "Das nächste mögliche Downgrade kann am <DATE> ausgeführt werden.",
  [keys.CH_reRegisterNumber]: "Nummer neu registrieren",
  [keys.CH_reRegisterNumberText]:
    'Deine Nummer wurde von Meta abgemeldet. Um unsere Messaging-Dienste weiterhin nutzen zu können, musst du sie neu registrieren. Klicke auf die Schaltfläche "Nummer neu registrieren" und befolge die Schritte.',
  [keys.CH_saveComment]: "Kommentar speichern",
  [keys.CH_activityLogs]: "Aktivitätsprotokolle",
  [keys.CH_admin]: "Verwaltung",
  [keys.CH_dateAndTime]: "Datum und Uhrzeit",
  [keys.CH_actor]: "Akteur",
  [keys.CH_action]: "Aktion",
  [keys.CH_businessAccount]: "Geschäftskonto",
  [keys.CH_profilePreview]: "Profil-Vorschau",
  [keys.CH_noLogsYet]: "Noch keine Protokolle",
  [keys.CH_noLogsYetDescription]:
    "Zurzeit sind keine Aktivitätsprotokolle vorhanden, die angezeigt werden können.",
  [keys.CH_useFilteredData]: "Gefilterte Daten verwenden?",
  [keys.CH_selectDataset]:
    "Bitte wähle aus, ob du den kompletten Datensatz herunterladen möchtest oder ob die ausgewählten Filter auf den exportierten Datensatz angewendet werden sollen.",
  [keys.CH_filteredExportTitle]: "Gefilterter Export",
  [keys.CH_filteredExport]: "Gefilterten Datensatz exportieren",
  [keys.CH_fullExportTitle]: "Vollständiger Export",
  [keys.CH_fullExport]: "Vollständigen Datensatz exportieren",
  [keys.CH_contactSupport]: "Kontakt zum Support",
  [keys.CH_contactSupportText]:
    "Die Einrichtung deiner Nummer ist unerwartet langsam. Bitte kontaktiere unser Support-Team für Hilfe.",
  [keys.CH_proceedWithRegistration]:
    "Deine Nummer wurde noch nicht für die WhatsApp API registriert. Bitte fahre mit der Registrierung fort, um unsere Dienste zu nutzen.",
  [keys.CH_readyToRegister]: "Bereit zur Registrierung",
  [keys.CH_addNumberDisabledTwoNumbers]:
    "Du kannst aktuell keine weiteren Nummern hinzufügen. Falls du mehr Nummern benötigst, kontaktiere bitte unseren Support.",
  [keys.CH_addNumberDisabledTwoNumbers2]:
    "Du kannst aktuell keine weiteren Nummern hinzufügen. Falls du mehr Nummern benötigst, kontaktiere bitte deinen Partner oder unseren Support über hub.360dialog.com",
  // [keys.CH_addNumberLimitExceeded]: "translation_missing",
  [keys.CH_lockedTitle]: "Dein Konto ist vorübergehend gesperrt",
  [keys.CH_lockedText]:
    "Wir haben verdächtige Aktivitäten auf einer deiner WhatsApp-Telefonnummern festgestellt und daher dein Konto vorübergehend gesperrt. Bitte vergewissere dich, dass du alle ausstehenden Rechnungen für Abonnementgebühren und Konversationskosten beglichen hast, damit du weiter Nachrichten versenden kannst. Wenn du das Gefühl hast, dass dies falsch ist und dein Konto wieder aktiviert werden soll, kontaktiere bitte unseren Support.",
  [keys.CH_ctaPendingReview]:
    "Dein Konto wird derzeit von unserem Team überprüft. Dies sollte nicht länger als 2 Stunden dauern. Bis die Überprüfung abgeschlossen ist, kannst du keine Vorlagennachrichten versenden. Du kannst jedoch weiterhin Nachrichten empfangen und auf von Benutzern initiierte Konversationen antworten.",
  [keys.CH_apiKeyDisabledMessage]:
    'API-Schlüssel sind für diese Nummer deaktiviert. Die Nummer wurde mit deinem Integrationspartner geteilt. Mit deiner Erlaubnis hat der Partner Zugriff auf die WhatsApp Business API in deinem Namen. Um die Berechtigungen zu verwalten, gehe zu "Organisationseinstellungen" oder kontaktiere deinen Partner.',
  [keys.CH_emptyStateHelptextClientIO]:
    "Um auf die WhatsApp Business API zugreifen zu können, musst du zuerst einen WhatsApp Business Account erstellen. Bitte setzte den Einrichtungsprozess über die Anwendung deines Partners fort.",
  [keys.CH_successfullyAddedNumber]:
    "Deine Nummer wurde erfolgreich hinzugefügt. Wir richten sie gerade ein und sie wird in wenigen Minuten auf dieser Seite zu sehen sein. Du wirst hier und per E-Mail benachrichtigt.",
  [keys.CH_numberNowLive]: "Deine Nummer ist jetzt aktiv",
  [keys.CH_generateApiKeyNow]: "API-Schlüssel generieren",
  [keys.CH_settingUpNumber]:
    "Wir sind gerade dabei, deine Nummer einzurichten. Dies kann ein paar Minuten dauern. Du wirst per E-Mail benachrichtigt, wenn deine Nummer vollständig eingerichtet ist. Danach kannst du die Registrierung der Nummer fortsetzen.",
  [keys.CH_accountDeletionCheckbox]:
    "Mir ist klar, dass meine Nummer am Ende des aktuellen Abonnementzeitraums deaktiviert wird.",
  [keys.CH_deleteAccount]: "Konto löschen",
  [keys.CH_dataNotAccessible]:
    "Die Daten auf dieser Seite sind für deinen Benutzer nicht zugänglich",
  [keys.CH_addNumberDisabledSandbox]:
    "Du hast deine maximale Anzahl von 2 Sandbox-Nummern erreicht. Um neue Nummern hinzuzufügen, musst du mit deinen bestehenden Nummern in den Live-Modus wechseln.",
  [keys.CH_hostingPlatformType]: "Typ der Hosting-Plattform",
  [keys.CH_aboutTextRequired]: "Info-Text ist ein Pflichtfeld",
  [keys.CH_synced]: "Synchronisiert",
  [keys.CH_wabaType]: "WhatsApp Business Kontotyp",
  [keys.CH_disableTracker]: "CTWA-Tracker deaktivieren",
  [keys.CH_trackerNotificationContent]:
    "Wenn du den Click To WhatsApp Tracker deaktivierst, sind alle gesammelten Daten weiterhin sichtbar, die verfolgten Ereignisse werden jedoch automatisch archiviert.",
  [keys.CH_trackingBannerContent]:
    "Du hast den Click To WhatsApp-Tracker für diese Nummer deaktiviert. Aktiviere das Tool, um weiterhin Erkenntnisse aus deinen Unterhaltungen zu gewinnen!",
  [keys.CH_deleteTitle]:
    "Wenn du dein Abonnement für die Telefonnummer <NUMMER> kündigen möchtest, gib bitte CANCEL in dieses Feld unten ein.",
  [keys.CH_goBack]: "Zurückgehen",
  [keys.CH_activateInbox]: "Posteingang aktivieren",
  [keys.CH_verifyBusiness]: "Meta Business verifizieren",
  [keys.CH_contactPartner]:
    "Bitte kontaktiere deinen Partner, um Telefonnummern hinzuzufügen oder zu portieren",
  [keys.CH_cancelPayment]: "Zahlung im Namen des Kunden stornieren",
  [keys.CH_cancelPaymentInfo]:
    "Diese Option enthebt dich deiner Verantwortung (als 360dialog Partner), im Namen des Kunden zu zahlen. Bitte beachte, dass du als Partner weiterhin vertraglich für alle mit dem Kunden verbundenen Gebühren bis zum Ende des Monats verantwortlich bist.",
  [keys.CH_pleaseLimitDescription]:
    "Bitte beschränke das Beschreibungsfeld auf 256 Zeichen",
  [keys.CH_addNumberDisabledTwoNumbersAlt]:
    "Du kannst derzeit keine weiteren Nummern zu deinem Konto hinzufügen. Wenn du mehr Nummern benötigst, wende dich bitte an deinen Integrationspartner.",
  [keys.CH_currentPlanNotSupportChangingHostingPlatform]:
    "Dein aktueller Plan unterstützt keine Änderung des Hosting-Plattformtyps.",
  [keys.CH_pleaseBeAwareMigrationBack]:
    "Bitte beachte, dass eine Migration zurück zur On-Premise-Hosting-Lösung nicht möglich ist, sobald eine Nummer über die Cloud API gehostet wird.",
  [keys.CH_signalDisabledMsg]:
    "Dein aktueller Plan beinhaltet nicht den Zugang zur Signals App.",
  [keys.CH_inboxDisabledMsg]:
    "Dein aktueller Plan beinhaltet nicht den Zugang zur get.chat App.",
  [keys.CH_partnerApiKeyPermission]: "Partner API Schlüssel Berechtigung",
  [keys.CH_subscriptionPlan]: "Abonnement-Tarif",
  [keys.CH_changePlan]: "Tarif ändern",
  [keys.CH_downgradeScheduleInfo]: "Downgrade geplant für {{date}}",
  [keys.CH_upgradeScheduleInfo]: "Upgrade geplant für {{Datum}}",
  [keys.CH_subscriptionSuccessful]:
    "Dein Abonnement-Tarif wurde erfolgreich geändert.",
  [keys.CH_selectSubscription]: "Neuen Abonnement-Tarif auswählen",
  [keys.CH_subscriptionSelectionInfo]:
    "Wenn du mit den nächsten Schritten fortfährst, änderst du den Abonnement-Tarif für die Nummer {{Nummer}}",
  [keys.CH_savingPlan]: "Tarifänderung wird gespeichert",
  [keys.CH_savePlan]: "Tarifänderung speichern",
  [keys.CH_downgradeDisabledTooltip]:
    "Konten können 30 Tage nach der letzten Tarifänderung herabgestuft werden. Deine letzte Tarifänderung war am {{updatedDate}}. Das nächste mögliche Downgrade kann am {{nextPossibleDowngradeDate}} ausgelöst werden",
  [keys.CH_pendingClientApprovalInfo]:
    "Dein Partner {{partnerName}} möchte dir Zugang zu dieser Rufnummer gewähren. Bitte verifiziere den Besitz der Nummer, damit du sie für Nachrichten auf der WhatsApp Business Plattform nutzen kannst. Nach der Überprüfung wird deine Nummer eingerichtet und {{partnerName}} wird informiert, um die Einrichtung abzuschließen:",
  [keys.CH_verifyOwnership]: "Eigentümerschaft verifizieren",
  [keys.CH_pendingClientApprovalInfoNoClientInfo]:
    "Damit du diese Nummer für Nachrichten auf der WhatsApp Business Platform nutzen kannst, muss eine Kunden-E-Mail hinzugefügt werden und der Kunde muss den Besitz dieser Nummer bestätigen. Danach wird die Nummer automatisch eingerichtet. Um mehr darüber zu erfahren, wie du eine Kunden-E-Mail anhängst {{documentationLink}}.",
  [keys.CH_viewDocumentation]: "Dokumentation ansehen",
  [keys.CH_pendingClientApprovalInfoWithClientInfo]:
    "Um diese Nummer für Nachrichten auf der WhatsApp Business Platform zu verwenden, muss der hinzugefügte Kunde den Besitz dieser Nummer bestätigen. Danach wird die Nummer automatisch eingerichtet. Bitte den Kunden, sich im 360dialog Client Hub anzumelden und den Anweisungen zu folgen, um den Besitz der Nummer zu bestätigen, damit diese Nummer aktiviert werden kann",
  [keys.CH_clientUserApproved]:
    "Die Nummer wurde erfolgreich aktiviert und wird gerade eingerichtet. Dies wird ein paar Augenblicke dauern. Bitte schau später noch einmal vorbei.",
  [keys.CH_channelVerified]: "Kanalüberprüfung erfolgreich",
  //  [keys.CH_wabaWebhookUrlError]: "translation_missing",
  //  [keys.CH_hostingType]: "translation_missing",
  //  [keys.CH_partnerPermitted]: "translation_missing",
  //  [keys.CH_partnerNotPermitted]: "translation_missing",
  //  [keys.CH_missing]: "translation_missing",
  //  [keys.CH_shared]: "translation_missing",
  //  [keys.CH_managePermissions]: "translation_missing",
  //  [keys.CH_planRecommended]: "translation_missing",
  //  [keys.CH_planBasic]: "translation_missing",
  //  [keys.CH_planRegular]: "translation_missing",
  //  [keys.CH_planPremium]: "translation_missing",
  //  [keys.CH_planMonth]: "translation_missing",
  //  [keys.CH_planIncludes]: "translation_missing",
  //  [keys.CH_planPlus]: "translation_missing",
  //  [keys.CH_planCloudHosting]: "translation_missing",
  //  [keys.CH_planNinetyServiceLevel]: "translation_missing",
  //  [keys.CH_planTicketBased]: "translation_missing",
  //  [keys.CH_planOnPremiseHosting]: "translation_missing",
  //  [keys.CH_planWabaOfficial]: "translation_missing",
  //  [keys.CH_planHighServiceLevel]: "translation_missing",
  //  [keys.CH_planIncludesAdsFunnel]: "translation_missing",
  //  [keys.CH_planSelect]: "translation_missing",
  //  [keys.CH_planSelected]: "translation_missing",
  //  [keys.CH_active]: "translation_missing",
  //  [keys.CH_manageWaba]: "translation_missing",
  //  [keys.CH_numberDetails]: "translation_missing",
  //  [keys.CH_numberBusinessAccount]: "translation_missing",
  //  [keys.CH_funds]: "translation_missing",
  //  [keys.CH_plan]: "translation_missing",
  //  [keys.CH_accountName]: "translation_missing",
  //  [keys.CH_dataRegions]: "translation_missing",
  //  [keys.CH_numberQuality]: "translation_missing",
  //  [keys.CH_numberMessagingLimit]: "translation_missing",
  //  [keys.CH_low]: "translation_missing",
  //  [keys.CH_medium]: "translation_missing",
  //  [keys.CH_high]: "translation_missing",
  //  [keys.CH_numberPending]: "translation_missing",
  //  [keys.CH_unlimited]: "translation_missing",
  //  [keys.CH_newConversationsPerDay]: "translation_missing",
  //  [keys.CH_onlyAvailableCloudNumbers]: "translation_missing",
  //  [keys.CH_qualityRatingTooltipOne]: "translation_missing",
  //  [keys.CH_qualityRatingTooltipTwo]: "translation_missing",
  //  [keys.CH_qualityRatingTooltipStatus]: "translation_missing",
  //  [keys.CH_qualityRatingTooltipPending]: "translation_missing",
  //  [keys.CH_qualityRatingTooltipHigh]: "translation_missing",
  //  [keys.CH_qualityRatingTooltipMedium]: "translation_missing",
  //  [keys.CH_qualityRatingTooltipLow]: "translation_missing",
  //  [keys.CH_messagingLimitTooltip]: "translation_missing",
  //  [keys.CH_messagingLimitTooltipLimits]: "translation_missing",
  //  [keys.CH_numberQualityRating]: "translation_missing",
  //  [keys.CH_learnMore]: "translation_missing",
  //  [keys.CH_numberMessagingLimitTitle]: "translation_missing",
  //  [keys.CH_seeMore]: "translation_missing",
};

export default translate;
