import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.InboxConversationNotFound]:
    "Conversation not found, first conversation has been loaded",
  [keys.InboxConversationMessagesLoadingProblem]:
    "An error occurred during loading the message",
  [keys.InboxConversationMessagesSendingProblem]:
    "An error occurred during sending the message",
  [keys.InboxConversationEmptyConversationList]: "No conversation",
  [keys.InboxConversationNotFoundButton]: "Reload",
  [keys.InboxPrefilterBarFilterAll]: "All",
  [keys.InboxPrefilterBarFilterMy]: "My",
  [keys.InboxPrefilterBarFilterNew]: "New",
  [keys.InboxPrefilterBarFilterUnread]: "Pending",
  [keys.InboxPrefilterBarFilterClosed]: "Closed",
  [keys.InboxWorkspaceMenuMyInbox]: "My inbox",
  [keys.AuthHeaderTitleDescription]: "Request password change",
  [keys.InboxWorkspaceMenuTeamInbox]: "Team inbox",
  [keys.InboxWorkspaceMenuTeamInboxSelected]: "selected",
  [keys.InboxAlertLinkCopiedLabel]: "Link copied",
  [keys.InboxAlertConnectionProblemLabel]: "Connection problem!",
  [keys.InboxSearchPlaceholder]: "Search in conversations",
  [keys.InboxFilterConversationPopupFilterNoneFilterConversation]: "all",
  [keys.SelectDefaultPlaceholder]: "Select...",
  [keys.InboxFilterConversationPopupFilterAllConversations]: "all",
  [keys.InboxFilterConversationPopupFilterMyConversations]: "my",
  [keys.InboxFilterConversationPopupFilterNewConversations]: "new",
  [keys.InboxFilterConversationPopupFilterUnfinishedConversations]: "pending",
  [keys.InboxFilterConversationPopupFilterRecentlyClosedConversations]:
    "recently closed",
  [keys.InboxFilterConversationPopupFilterCustom]: "Custom",
  [keys.InboxFilterConversationPopupFilterTitleDefaultFilters]:
    "Standard Filter",
  [keys.InboxFilterConversationPopupCustomFilterTitle]: "Custom filters",
  [keys.InboxFilterConversationOrderDateTitle]: "Sort",
  [keys.InboxFilterConversationOrderDateNewest]: "Newest on top",
  [keys.InboxFilterConversationOrderDateNewest]: "Oldest on top",
  [keys.ConversationSearchPlaceholder]: "Search in conversations",
  [keys.ConversationSearchHeader]: "Recent Searches",
  [keys.ConversationSearchLabel]: "Search",
  [keys.InboxConversationListNone]: "No conversations",
  [keys.InboxConversationListErrorTitle]: "Failed loading conversation list",
  [keys.InboxConversationListErrorButton]: "Reload",
  [keys.InboxConversationIndicatorAssignedAvatarTakeOver]: "Take over",
  [keys.TooltipAssignToMeLabel]: "Assign to me",
  [keys.InboxConversationHelperProfile]: "PROFILE",
  [keys.InboxConversationHelperLabels]: "LABELS",
  [keys.InboxConversationHelperAttachments]: "ATTACHMENTS",
  [keys.InboxConversationHelperLinks]: "LINKS",
  [keys.InboxConversationHelperProfileMainInfoPhone]: "Phone",
  [keys.InboxConversationHelperProfileMainInfoDouble]: "Double OPT-IN",
  [keys.InboxConversationHelperProfileMainInfoMo]: "MO-Flow",
  [keys.InboxConversationHelperProfileMainInfoLastSeen]: "Last seen",
  [keys.InboxConversationHelperProfileMainInfoCreated]: "Created",
  [keys.InboxConversationHelperProfileMainInfoSession]: "Session",
  [keys.InboxConversationHelperProfileMainInfoPushNotification]:
    "Push notification",
  [keys.InboxConversationHelperProfileMainInfoEnabled]: "Enabled",
  [keys.RichEditorPickerSearch]: "Search",
  [keys.RichEditorPickerEmojiNotFound]: "No Emoji Found",
  [keys.RichEditorPickerSearchResults]: "Search Results",
  [keys.RichEditorPickerFrequentlyUsed]: "Frequently Used",
  [keys.ManageUsers]: "User Management",
  [keys.TeamsAndUsersFirstSubHeader]:
    "Create and manage users and set permissions for them",
  [keys.TeamsAndUsersSecondSubHeader]:
    "Define teams and group users for shared visiblity on conversations",
  [keys.UsersPopupEmailSendHeader]: "Add user",
  [keys.RichEditorPickerSmileysAndPeople]: "Smileys & People",
  [keys.RichEditorPickerAnimalsAndNature]: "Animals & Nature",
  [keys.RichEditorPickerFoodAndDrink]: "Food & Drink",
  [keys.RichEditorPickerActivity]: "Activity",
  [keys.TouchpointWizardJourneySettingsLabelMobile]: "Mobile",
  [keys.TouchpointWizardJourneySettingsLabelDesktop]: "Desktop",
  [keys.RichEditorPickerTravelAndPlaces]: "Travel & Places",
  [keys.RichEditorPickerObjects]: "Objects",
  [keys.RichEditorPickerSymbols]: "Symbols",
  [keys.RichEditorPickerFlags]: "Flags",
  [keys.RichEditorPickerCustom]: "Custom",
  [keys.HighlightSearchListRecentSearches]: "Recent Searches",
  [keys.NavigationShortcutsPanelToNavigate]: "to navigate",
  [keys.NavigationShortcutsPanelToSelect]: "to select",
  [keys.NavigationShortcutsPanelESC]: "esc",
  [keys.NavigationShortcutsPanelToDismiss]: "to dismiss",
  [keys.TouchpointWizardTitleNewTouchpoints]: "New touchpoints",
  [keys.SettingTouchpointErrorMessage]: "Error during loading touchpoints",
  [keys.SettingIntegrationsErrorMessage]: "Error during loading integrations",
  [keys.TouchpointWizardTitleConsentAndDisclaimer]: "Consent & disclaimer",
  [keys.TouchpointWizardTitleDescription]:
    "Add all countries where you provide your service.",
  [keys.TouchpointWizardTitleTheming]: "Theming",
  [keys.TouchpointWizardTitleJourney]: "Journey",
  [keys.TouchpointWizardTitlePreviewAndDeployment]: "Preview & deployment",
  [keys.TouchpointTitle]: "Touchpoint",
  [keys.TouchpointDescription]:
    "Control touchpoint visibility and behaviour by defining triggers, conditions and flows",
  [keys.NotificationSheduleFooterSave]: "Save",
  [keys.NotificationSheduleFooterCancel]: "Cancel",
  [keys.NotificationSheduleHeaderReminders]: "Reminders",
  [keys.NotificationSheduleHeaderPinned]: "Pinned",
  [keys.NotificationSheduleRemindersContentMessageFrom]: "Message from",
  [keys.NotificationSheduleRemindersContentAt]: "at",
  [keys.CustomFiltersDialogFooterSaveFilter]: "Save filters",
  [keys.CustomFiltersDialogFooterCancel]: "Cancel",
  [keys.CustomFiltersDialogFooterApplyAndClose]: "Apply & close",
  [keys.CustomFiltersDialogFiltersSavedTitle]: "SAVED FILTERS",
  [keys.CustomFiltersDialogFiltersLabelMatching]: "Matching:",
  [keys.CustomFiltersDialogFiltersLabelPhoneNumber]: "Phone number:",
  [keys.CustomFiltersDialogFiltersLabelPofileName]: "Profile name:",
  [keys.CustomFiltersDialogFiltersLabelResolutionCode]: "Resolution code:",
  [keys.CustomFiltersDialogFiltersLabelCurrentlyAssignedTo]:
    "Currently assigned to:",
  [keys.CustomFiltersDialogFiltersLabelConversationStatus]:
    "Conversation states:",
  [keys.CustomFiltersDialogFiltersLabelMessageInOut]: "In/Out:",
  [keys.CustomFiltersDialogFiltersLabelMessageStatus]: "Message states:",
  [keys.CustomFiltersDialogFiltersLabelMessageType]: "Message type:",
  [keys.CustomFiltersDialogFiltersLabelConversationCreated]:
    "Conversation created:",
  [keys.CustomFiltersDialogFiltersLabelLastMessage]: "Last message:",
  [keys.CustomFiltersDialogFiltersLabelLastIncomingMessage]:
    "Last incoming message:",
  [keys.CustomFiltersDialogFiltersLabelMessageCreated]: "Message created:",
  [keys.CustomFiltersDialogFiltersDropdownMore]: "more filters",
  [keys.ProfileTooltipToogleStatus]: "Toogle status",
  [keys.ProfileTooltipToogleProfileSettings]: "Profile settings",
  [keys.ProfileTooltipToogleSignout]: "Sign Out",
  [keys.ProfileTooltipToogleLanguageLabel]: "Language",
  [keys.ConversationInputModeReply]: "Reply",
  [keys.ConversationInputModeInternalNote]: "Internal note",
  [keys.ConversationInputTakeOverConversationNotFound]: "Not found",
  [keys.InboxConversationSessionInfoTranslateSessionExpires]:
    "session expires in",
  [keys.ConversationTemplatesToolTipMessage]:
    'The conversation window is closed. You can re-engage with the user by selecting a template, using "/" or selecting the template with the template selector',
  [keys.InboxConversationAssignToLabel]: "Assign to",
  [keys.InboxConversationAssignToAgentListLabel]: "Assign to agent",
  [keys.InboxConversationAssignToAgentUnassign]: "Unassign",
  [keys.InboxConversationAssignToTeamTitle]: "Assign to team",
  [keys.InboxConversationResolutionCodesReason]: "Reason:",
  [keys.InboxConversationResolutionCodesReopen]: "Reopen",
  [keys.InboxConversationResolutionCodesClose]: "Close",
  [keys.InboxConversationTranslateAppTitle]: "Translate to",
  [keys.InboxConversationTranslateAppLangEnglish]: "English",
  [keys.InboxConversationTranslateAppLangGerman]: "German",
  [keys.InboxConversationTranslateAppLandPolish]: "Polish",
  [keys.InboxConversationRemindMeTitle]: "Remind me",
  [keys.InboxConversationRemindMeTwenty]: "In 20 minute",
  [keys.InboxConversationRemindMeOneHour]: "In 1 hour",
  [keys.InboxConversationRemindMeThreeHour]: "In 3 hours",
  [keys.InboxConversationRemindMeTomorrow]: "Tomorrow",
  [keys.InboxConversationRemindMeNextWeek]: "Next week",
  [keys.D360WhatsappMessageIn]: "Incoming messages",
  [keys.D360Register]: "New contacts",
  [keys.D360RelocationClicked]: "User from the touchpoint",
  [keys.D360WhatsappMessageSent]: "Created messages",
  [keys.D360WhatsappSuccess]: "Delivered messages",
  [keys.D360WhatsappFail]: "Failed to deliver messages",
  [keys.D360ReportActionDeleted]: "Message(s) deleted by users",
  [keys.SettingsActionGroupCancel]: "Cancel",
  [keys.SettingsActionGroupSave]: "Save",
  [keys.CompanySettingsBasicInfoAddressAddresses]: "ADDRESS",
  [keys.CompanySettingsBasicInfoAddressCity]: "CITY",
  [keys.CompanySettingsBasicInfoAddressPostalCode]: "POSTAL CODE",
  [keys.CompanySettingsBasicInfoAddressPostalCode]: "POSTAL CODE",
  [keys.CompanySettingsBasicInfoAddressCountry]: "COUNTRY",
  [keys.CompanySettingsBasicInfoAddressPhone]: "PHONE",
  [keys.CompanySettingsBasicInfoAdministrativeContactEmailAddress]: "EMAIL",
  [keys.CompanySettingsBasicInfoAdministrativeContactPhone]: "PHONE",
  [keys.CompanySettingsBasicInfoHeaderLogotype]: "LOGOTYPE",
  [keys.CompanySettingsBasicInfoHeaderCompanyName]: "COMPANY NAME",
  [keys.CompanySettingsBasicInfoHeaderCategory]: "CATEGORY",
  [keys.CompanySettingsBasicInfoHeaderBusinessDescription]:
    "BUSINESS DESCRIPTION",
  [keys.CompanySettingsBasicInfoHeaderBriefDescription]: "BRIEF DESCRIPTION",
  [keys.KpiDashboardFiltersSelect]: "Select...",
  [keys.KpiDashboardFiltersFilter]: "Filter",
  [keys.KpiDashboardFiltersTotal]: "Total",
  [keys.KpiDashboardFiltersLast7d]: "Last 7d",
  [keys.KpiDashboardFiltersLastDay]: "Last day",
  [keys.KpiDashboardFiltersLast30d]: "Last 30d",
  [keys.KpiDashboardFiltersLast90d]: "Last 90d",
  [keys.CompanySettingsBasicInfoCompanyInfo]: "Company info",
  [keys.CompanySettingsBasicInfoCompanyInfoDescription]:
    "Basic information about yourself",
  [keys.CompanySettingsBasicInfoAdministrativeContact]:
    "Administrative contact",
  [keys.CompanySettingsBasicInfoAdministrativeContactDescription]:
    "Account owner details",
  [keys.CompanySettingsBasicInfoKeysHint]: "HINT",
  [keys.CompanySettingsBasicInfoSocialFacebookBusiness]:
    "META BUSINESS MANAGER ID",
  [keys.CompanySettingsBasicInfoSocialFacebook]: "META",
  [keys.CompanySettingsBasicInfoSocialLinkedin]: "LINKEDIN",
  [keys.CompanySettingsBasicInfoSocialIstagram]: "INSTAGRAM",
  [keys.CompanySettingsBasicInfoSocialTwitter]: "TWITTER",
  [keys.CompanySettingsBasicInfoWebsiteEmailAddress]: "EMAIL",
  [keys.CompanySettingsBasicInfoWebsiteWebsite]: "WEBSITE",
  [keys.CompanySettingsOptOutFlowOptOut]: "Opt-out & Re Opt-in",
  [keys.CompanySettingsOptOutFlowOptOutDescription]:
    "Define how the user can opt-out and re opt-in",
  [keys.CompanySettingsOptOutFlowConfirmationText]: "CONFIRMATION TEXT",
  [keys.CompanySettingsOptOutFlowOptOutMessage]: "OPT-OUT MESSAGE",
  [keys.CompanySettingsOptOutFlowOptOutInMessage]: "RE OPT-IN MESSAGE",
  [keys.CompanySettingsOptOutFlowOptOutTriggerWords]: "OPT OUT TRIGGER WORDS",
  [keys.CompanySettingsOptOutFlowConfirmationWords]: "CONFIRMATION WORDS",
  [keys.CompanySettingsTooglesAutoAssignAgents]: "Auto assign agents",
  [keys.CompanySettingsTooglesAutoAssignAgentsDescription]:
    'If activated, new inbound messages will automatically dispatched to an agent using the round-robin distribution, rather than remaining "Unassigned"',
  [keys.CompanySettingsTooglesRrequencyCapping]: "Frequency Capping",
  [keys.CompanySettingsTooglesRrequencyCappingDescription]:
    "Frequency capping prevents duplicate responses from the auto-responder within 24h. If activated, the out-of-office message gets only sent once within 24h",
  [keys.CompanySettingsTooglesEnabled]: "Enabled",
  [keys.CompanySettingsWorkingHoursTitle]: "Office hours",
  [keys.CompanySettingsWorkingHoursTitleDescription]:
    "Customize your office hours",
  [keys.CompanySettingsWorkingHoursOfficeHour]: "OFFICE HOURS",
  [keys.CompanySettingsWorkingHoursExceptions]: "EXCEPTIONS",
  [keys.CompanySettingsWorkingHoursAutoResponder]: "AUTO RESPONDER",
  [keys.CompanySettingsWorkingHoursExceptionsAddException]: "ADD EXCEPTION",
  [keys.CompanySettingsWorkingHoursExceptionsOptionWholeDayOff]:
    "WHOLE DAY OFF",
  [keys.CompanySettingsWorkingHoursExceptionsOptionFrom]: "FROM",
  [keys.CompanySettingsWorkingHoursExceptionsOptionTo]: "TO",
  [keys.CompanySettingsWorkingHoursOfficeHoursFrom]: "FROM",
  [keys.CompanySettingsWorkingHoursOfficeHoursTo]: "TO",
  [keys.CompanySettingsWorkingHoursOfficeHoursDeployTouchPointButtonText]:
    "Respect office hours",
  [keys.CompanySettingsWorkingHoursOfficeHoursDeployTouchPointButtonLabel]:
    "Show Touchpoints only within Opening Hours",
  [keys.MySettingsAccountMyInformation]: "My information",
  [keys.MySettingsAccountTitleDescription]: "Basic information about yourself",
  [keys.MySettingsAccountChangePassword]: "Password change",
  [keys.MySettingsAccountChangePasswordDescription]: "Update your password",
  [keys.MySettingsAccountHint]: "hints",
  [keys.MySettingsAccountHintTwoFactorAuthentication]:
    "Two-factor authentication",
  [keys.MySettingsAccountHintTwoFactorAuthenticationDescription]:
    "Enable extra layer of security to your account",
  [keys.SettingsBasicInformationFirstName]: "FIRST NAME",
  [keys.SettingsBasicInformationLastName]: "LAST NAME",
  [keys.SettingsBasicInformationNickname]: "LOGIN",
  [keys.SettingsBasicInformationEmail]: "EMAIL",
  [keys.SettingsBasicInformationSignature]: "SIGNATURE",
  [keys.SettingsUpdateAvatarTitleUpload1]: "Drop or",
  [keys.SettingsUpdateAvatarTitleUpload2]: "upload",
  [keys.SettingsUpdateAvatarTitleUpload3]: "file",
  [keys.SettingsUpdateAvatarMaxFileSize]: "max file size",
  [keys.SettingsUpdatePasswordFormPassword]: "NEW PASSWORD",
  [keys.SettingsUpdatePasswordFormConfirmPassword]: "CONFIRM NEW PASSWORD",
  [keys.SettingsUpdatePasswordFormChangeButton]: "CHANGE NOW",
  [keys.SettingsUpdatePasswordFormToWeakError]: "Password is too weak",
  [keys.SettingsUpdatePasswordFormBeenUsedError]:
    "Password has previously been used",
  [keys.SettingsUpdatePasswordFormIsTheSameError]:
    "Password should be the same",
  [keys.SettingsUpdatePasswordFormIsToShort]:
    "Password required at least 8 characters",
  [keys.SettingsUpdatePasswordFormPasswordChanged]:
    "Password changed successfully!",
  [keys.TwoFactorAuthenticationEnable]: "Enable Two-factor authentication",
  [keys.MySettingsNotificationsBasicNotifications]: "Basic notifications",
  [keys.MySettingsNotificationsBasicNotificationsDescription]:
    "Basic information about yourself",
  [keys.MySettingsNotificationsAdvancedNotifications]: "Advanced notifications",
  [keys.MySettingsNotificationsAdvancedNotificationsDescription]:
    "Create advanced notifications to customize when and how you get notified. Advanced notifications will override basic notifications,",
  [keys.MySettingsNotificationsCreateARule]: "Create a rule",
  [keys.MySettingsNotificationsTips]: "Tips & hints",
  [keys.MySettingsNotificationsRadioGroupAllNotifications]: "All notifications",
  [keys.MySettingsNotificationsRadioGroupSmartNotifications]:
    "Smart notifications",
  [keys.MySettingsNotificationsRadioGroupOff]: "Off",
  [keys.SettingsTitleMySettings]: "My Settings",
  [keys.SettingsTitleTeamSettings]: "Team Settings",
  [keys.SettingsTitleCompanySettings]: "Company settings",
  [keys.SettingsTitleHelp]: "Help",
  [keys.SettingsCategoryTitleAccount]: "My Profile",
  [keys.SettingsCategoryTitleNotifications]: "Notifications",
  [keys.SettingsCategoryDelegationRules]: "Delegation rules",
  [keys.SettingsCategoryTitleCompany]: "Company",
  [keys.SettingsCategoryTitleManageTeammates]: "Manage teammates",
  [keys.SettingsCategoryTitleBasicInfo]: "Basic info",
  [keys.SettingsCategoryTitleWorkingHours]: "Office hours",
  [keys.SettingsCategoryTitleToogles]: "Toggles",
  [keys.SettingsCategoryTitleOptOutFlow]: "Opt-Out Flow",
  [keys.SettingsCategoryTitleRulesAndAutomation]: "Rules & Automation",
  [keys.SettingsCategoryTitleSettings]: "Settings",
  [keys.SettingsCategoryTitleTouchpoints]: "Touchpoints",
  [keys.SettingsCategoryTitleTouchpointWizard]: "Touchpoint wizard",
  [keys.SettingsCategorySupportedCountries]: "Supported countries",
  [keys.SettingsCategoryUnsupportedMedia]: "Unsupported media",
  [keys.SettingsCategoryUnsupportedMediaDescription]:
    "Whenever users send attachments that are not (yet) supported, the system will automatically respond to it",
  [keys.SettingsCategoryUnsupportedMediaEnabledDescription]:
    "Decide what media types should be allowed",
  [keys.SettingsCategoryUnsupportedMediaEnabled]: "Enabled",
  [keys.SettingsCategoryUnsupportedMediaVideo]: "Video",
  [keys.SettingsCategoryUnsupportedMediaAudio]: "Audio",
  [keys.SettingsCategoryUnsupportedMediaVoice]: "Voice",
  [keys.SettingsCategoryUnsupportedMediaImage]: "Image",
  [keys.SettingsCategoryUnsupportedMediaLocation]: "Location",
  [keys.SettingsCategoryUnsupportedMediaContact]: "Contact details",
  [keys.SettingsCategoryUnsupportedMediaDocument]: "Document",
  [keys.SettingsCategorySupportedCountriesSupportedCountries]:
    "Add all countries where you provide your service",
  [keys.SettingsCategorySupportedCountriesDescription]:
    "Users from countries that are not whitelisted will receive a message that their request cannot be handled",
  [keys.SettingsCategorySupportedCountriesDescriptionAutoResponder]:
    "Tell your users from unsupported countries why you cannot handle their request. Think about adding different language versions to provide them with a better experience",
  [keys.SettingsCategoryAutoResponder]: "Auto responder",
  [keys.AuthForgetPasswordRequestPasswordDescription]:
    "Enter the email address associated with your account and we will send an email with instructions to reset your password.",
  [keys.AuthForgetPasswordRequestPasswordSemdLink]: "Send me the link",
  [keys.AuthForgetPasswordRequestPasswordGoBack]: "go back",
  [keys.AuthForgetPasswordPlaceholderAnimateEmail]: "Email",
  [keys.InboxAssignToPanelActions]: "Actions",
  [keys.AuthHeaderTitle]: "Log In",
  [keys.AuthLoginFormWrongLoginPage]: "Wrong login page?",
  [keys.AuthLoginFormMarketingAutomation]:
    '"Marketing Automation" is this way!',
  [keys.AuthLoginFormHidePassword]: "Hide password",
  [keys.AuthLoginFormShowPassword]: "Show password",
  [keys.AuthLoginFormPlaceholderEmail]: "Email or Username",
  [keys.AuthLoginFormPlaceholderPassword]: "Password",
  [keys.AuthLoginFormSubmit]: "Login",
  [keys.AuthLoginFormPassworResetText]: "Forget your password?",
  [keys.AuthLoginFormPassworResetLink]: "Click here",
  [keys.AuthLoginFormAllRightsReserved]: "©2019 All Rights Reserved.",
  [keys.AuthLoginFormPrivacy]: "Privacy",
  [keys.AuthLoginFormAnd]: "and",
  [keys.AuthLoginFormTerms]: "Imprint",
  [keys.AuthSignupHeaderDescription]:
    "Add the WhatsApp channel to your business and build smart journeys for personalized conversations with your users.",
  [keys.AuthLoginFooterDescription]: "Need a Messagepipe account?",
  [keys.AuthLoginFooterLink]: "Request your credentials",
  [keys.CompanySettingsWorkingHoursExceptionsOptionHeaderDate]: "DATE",
  [keys.AuthSignupFormPlaceholderEmail]: "E-Mail",
  [keys.AuthSignupFormPlaceholderPassword]: "Password",
  [keys.AuthSignupFormPlaceholderRepassword]: "Re-type password",
  [keys.AuthSignupFormCheckboxTerms]: "I’m accepting Term of Use",
  [keys.AuthSignupFormSubmit]: "Register",
  [keys.TouchpointWizardFooterSkip]: "Skip & save draft",
  [keys.TouchpointWizardFooterNext]: "Next step",
  [keys.TouchpointWizardFooterBack]: "Back",
  [keys.TouchpointWizardNewTouchpointTitleTheming]: "Theming",
  [keys.TouchpointWizardNewTouchpointTitleThemingDescription]:
    "Create strategy tailored to your needs. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae condimentum purus. Etiam sit amet risus maximus, auctor lacus in, aliquam sem.Create strategy tailored to your needs. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae condimentum purus. Etiam sit amet risus maximus, auctor lacus in, aliquam sem",
  [keys.NewTouchpointTypeLabelTouchpointType]: "TOUCHPOINT TYPE",
  [keys.NewTouchpointTypeMobileOnsiteFlow]: "Mobile Onsite Flow",
  [keys.NewTouchpointTypeMobileOnsiteFlowDescription]:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae condimentum purus. Etiam sit amet risus. maximus, auctor lacus in, aliquam sem.",
  [keys.NewTouchpointTypeMobileOnsiteWrap]: "Mobile Onsite Wrap",
  [keys.NewTouchpointTypeMobileOnsiteWrapDescription]:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae condimentum purus. Etiam sit amet risus. maximus, auctor lacus in, aliquam sem.",
  [keys.NewTouchpointTypeFacebookAd]: "Meta Ad",
  [keys.NewTouchpointTypeFacebookAdDescription]:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae condimentum purus. Etiam sit amet risus. maximus, auctor lacus in, aliquam sem.",
  [keys.NewTouchpointTypeInApp]: "In app",
  [keys.NewTouchpointTypeInAppDescription]:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae condimentum purus. Etiam sit amet risus. maximus, auctor lacus in, aliquam sem.",
  [keys.NewTouchpointTypeQRCode]: "QR Code",
  [keys.NewTouchpointTypeQRCodeDescription]:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae condimentum purus. Etiam sit amet risus. maximus, auctor lacus in, aliquam sem.",
  [keys.NewTouchpointTypeBanner]: "Banner",
  [keys.NewTouchpointTypeBannerDescription]:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae condimentum purus. Etiam sit amet risus. maximus, auctor lacus in, aliquam sem.",
  [keys.TouchpointWizardNewTouchpointTitleNewTouchpoint]: "New touchpoint",
  [keys.TouchpointWizardNewTouchpointLabelNewTouchpoint]: "TOUCHPOINT NAME",
  [keys.TouchpointWizardJourneySettingsLabelActivateGaTracking]:
    "Activate GA tracking",
  [keys.TouchpointWizardNewTouchpointTitleNewTouchpointDescription]:
    "Create strategy tailored to your needs. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae condimentum purus. Etiam sit amet risus maximus, auctor lacus in, aliquam",
  [keys.TouchpointWizardNewTouchpointLabelNessanger]: "MESSENGER",
  [keys.TouchpointWizardNewTouchpointLabelNewTouchpointDescription]:
    "DESCRIPTION",
  [keys.TouchpointWizardJourneySettingsLabelTreshold]: "THRESHOLD",
  [keys.TouchpointWizardJourneyDescription]:
    "Create strategy tailored to your needs. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin vitae condimentum purus. Etiam sit amet risus maximus, auctor lacus in, aliquam sem.",
  [keys.UploadInputTitlePart1]: "Drop or",
  [keys.UploadInputTitlePart2]: "upload",
  [keys.UploadInputTitlePart3]: "file",
  [keys.UploadInputInfo]: "max file size",
  [keys.AnalogClockModeAm]: "Am",
  [keys.AnalogClockModePm]: "Pm",
  [keys.DatePickerPlaceholderFrom]: "From",
  [keys.DatePickerPlaceholderTo]: "To",
  [keys.ConversationLastMessageConversationIs]: "conversation is",
  [keys.ConversationLastMessageConversationCloseAs]: "close as",
  [keys.ConversationLastMessageConversationOpenedBy]: "opened by",
  [keys.ConversationLastMessageConversationClosedBy]: "closed by",
  [keys.ConversationLastMessageConversationUnsupportedMessage]:
    "Unsupported message type",
  [keys.InboxFilterConversationOrderDateNewest]: "Newest on top",
  [keys.InboxFilterConversationOrderDateOldest]: "Oldest on top",
  [keys.SettingsTitleMySettings]: "My Settings",
  [keys.TouchpointWizardJourneySettingsLabelName]: "Name",
  [keys.TouchpointWizardJourneySettingsLabelSendButtonLabel]:
    "Call-To-Action Label",
  [keys.TouchpointWizardJourneySettingsLabelInputPlaceholder]:
    "Input placeholder",
  [keys.TouchpointWizardJourneySettingsLabelShowOnMobile]:
    "Activate mobile browsers",
  [keys.TouchpointWizardJourneySettingsLabelInterests]: "Users preferences",
  [keys.TouchpointWizardJourneySettingsLabelInterestsKey]: "Key",
  [keys.TouchpointWizardJourneySettingsLabelInterestsValue]: "Value",
  [keys.TouchpointWizardJourneySettingsLabelShowOnDesktop]:
    "Activate desktop browsers",
  [keys.TouchpointWizardJourneySettingsLabelDisclaimerText]:
    "Privacy Disclaimer",
  [keys.TouchpointWizardJourneySettingsLabelDescription]: "Description",
  [keys.TouchpointWizardJourneySettingsLabelContainerId]: "HTML-containerID",
  [keys.TouchpointWizardJourneySettingsLabelAgreement]: "Consent agreement",
  [keys.TouchpointWizardJourneySettingsLabelTouchpointMinimizedImage]:
    "Image URL (replaces WhatsApp logo)",
  [keys.TouchpointWizardJourneySettingsLabelTouchpointShadow]:
    "WhatsApp-logo with shadow",
  [keys.TouchpointWizardJourneySettingsLabelPhoneNumber]: "Phone number",
  [keys.TouchpointWizardJourneySettingsLabelTouchpointMsisdn]:
    "WhatsApp for Business number",
  [keys.TouchpointWizardJourneySettingsLabelQrCode]: "HTML-code for QR-code",
  [keys.TouchpointWizardJourneySettingsLabelActivation]: "ACTIVATION",
  [keys.TouchpointWizardJourneySettingsLabelActivationMoDescription]:
    "The MO process asks the user for consent within WhatsApp by sending a text message.",
  [keys.TouchpointWizardJourneySettingsLabelActivationMtDescription]:
    "The MT process requires a consent form on the website before opening WhatsApp.",
  [keys.TouchpointWizardJourneySettingsLabelMoFlow]: "MO-FLOW",
  [keys.TouchpointWizardJourneySettingsLabelThanksMtMessage]:
    "Thank you for confirmation (MT)",
  [keys.TouchpointWizardJourneySettingsLabelMtFlow]: "MT-FLOW",
  [keys.TouchpointWizardJourneySettingsLabelMoFlowDescription]: "MO-FLOW",
  [keys.TouchpointWizardJourneySettingsDescriptionFlow]:
    "At least one character is required.",
  [keys.TouchpointWizardJourneySettingsTooltipMo]:
    "Mobile-originated (MO) flow requires the user to consent in WhatsApp via text message",
  [keys.TouchpointWizardJourneySettingsTooltipMt]:
    "Mobile-terminated (MT) flow requires the user to consent upfront on the website before entering WhatsApp",
  [keys.TouchpointWizardJourneySettingsLabelNameDefaultValue]:
    "My new touchpoint",
  [keys.TouchpointWizardJourneySettingsLabelSendButtonLabelDefaultValue]:
    "Agree",
  [keys.TouchpointWizardJourneySettingsLabelInputPlaceholderDefaultValue]:
    "Your phone number",
  [keys.InboxAssignToPanelSelectAll]: "Select all",
  [keys.InboxMediaMessageDropdownDownload]: "Download",
  [keys.FileUploaderTitle]: "Upload file",
  [keys.TemplatesModalHeaderEditTemplate]: "Edit template",
  [keys.TemplatesHeader]: "Templates",
  [keys.TemplatesMenageTemplates]: "Template management",
  [keys.TemplatesFirstSubHeader]:
    "Manage internal and external templates, for example WhatsApp-HSM",
  [keys.TemplatesFirstSecondHeader]:
    "WhatsApp templates will be needed to reach users once the conversation window is closed",
  [keys.TemplatesModalHeaderPreviewTemplate]: "Preview template",
  [keys.TemplatesModalHeaderCreateTemplate]: "Create template",
  [keys.TemplatesModalFooterButtonClose]: "Close",
  [keys.TemplatesModalFooterButtonCancel]: "Cancel",
  [keys.TemplatesModalFooterButtonSave]: "Save",
  [keys.TemplatesModalFooterButtonAdd]: "Add",
  [keys.TemplatesListPanelKeysButtonEdit]: "Edit",
  [keys.TemplatesListPanelKeysButtonPreview]: "Preview",
  [keys.TemplatesListPanelKeysButtonDelete]: "Delete",
  [keys.TemplateType]: "Template type",
  [keys.TemplatesPopupCloseButton]: "Close",
  [keys.TemplateContent]: "Template text",
  [keys.TemplatesIconCantTooltipText]:
    "Template cannot be removed because of whatsapp type",
  [keys.TemplatesIconDeleteTooltipText]: "Delete template",
  [keys.TemplatesGeneral]: "General",
  [keys.TemplatesCreated]: "Created",
  [keys.TemplatesLastModified]: "Last modified",
  [keys.TemplatesNotificationFailedToSave]: "Failed to save template",
  [keys.TemplatesNotificationSaved]: "Template saved successful",
  [keys.TemplatesFailedToLoadTemplate]: "Failed to load template",
  [keys.DeleteTemplateErrorMessage]:
    "An error has ocured during deleting template",
  [keys.DeleteTemplateSuccessMessage]: "Succesfully deleted template",
  [keys.NotifyNewMessage]: "New message(s)! One click away...",
  [keys.NotifyConversationMessagesError]:
    "An error has ocured during loading messages.",
  [keys.NotifyConversationError]:
    "An error occurred while loading conversation.",
  [keys.NotifyConversationFileUploadError]:
    "An error has occured during uploading file",
  [keys.CopyConversationLink]: "Copy conversation link",
  [keys.CopiedConversationLink]: "Copied!",
  [keys.NotificationsFileIsTooBig]:
    "Uploaded file is too large (limit is set to",
  [keys.NotificationErrorOnUpload]:
    "An error occurred while uploading the file",
  [keys.NotificationErrorOnSave]: "An error has occurred during data saving.",
  [keys.NotificationErrorOnSuccessfullSaveWorkingHours]:
    "Your changes will be propagated. It might take a couple of hours until they are effective.",
  [keys.NotificationIncorrectFileType]: "Unsupported file format",
  [keys.LastMessageVideo]: "Video has been sent",
  [keys.LastMessageImage]: "Image has been sent",
  [keys.LastMessageAudio]: "Audio has been sent",
  [keys.LastMessageVoice]: "Voice has been sent",
  [keys.LastMessageLocation]: "Location has been shared",
  [keys.LastMessageContact]: "Contact has been shared",
  [keys.LastMessageDocument]: "Document has been sent",
  [keys.MessageForDeletedMessage]: "🚫   This message was deleted by the user.",
  [keys.TouchpointWizardJourneySettingsLabelTouchpointIgnoreOfficeHours]:
    "Ignore Office Hours",
  [keys.NotificationMessageTooLong]:
    "Message cannot contain more than 4096 characters.",
  [keys.LoginErrorNoAppConnectedWithAccount]:
    "I can't find any application connected with this account, check profile configuration please.",
  [keys.DeleteRuleTooltipText]: "Delete rule",
  [keys.DeleteRuleSuccessMessage]: "Rule deleted successfully",
  [keys.DeleteRuleFailedMessage]:
    "An error occurred while trying to delete a rule",
  [keys.SaveRuleSuccessMessage]: "Rule data saved successfully",
  [keys.SaveRuleFailedMessage]:
    "An error occurred while trying to save rule data",
  [keys.RulesHeaderTitle]: "Manage rules",
  [keys.FailedToLoadRule]: "An error occurred while trying to load rule",
  [keys.RulesHeader]: "Rules",
  [keys.RulesFirstSubHeader]: "Set up rules for incoming messages",
  [keys.RulesSecondSubHeader]: "Example: trigger welcome message for new users",
  [keys.RulesNameLabel]: "Rule name",
  [keys.RuleColumnName]: "Name",
  [keys.RulesCreated]: "Created",
  [keys.RulesPopupLabelAllowReenter]: "Allow reenter",
  [keys.RulesPopupLabelIgnoreFrequencyCaps]: "Ignore frequency caps",
  [keys.RulesPopupLabelReenterAfterTime]: "Reenter after time",
  [keys.RulesPopupLabelReenterAfterUnitValue]: "Reenter after unit",
  [keys.RulesPopupLabelReenterAfterUnitYears]: "Years",
  [keys.RulesPopupLabelReenterAfterUnitMonths]: "Months",
  [keys.RulesPopupLabelReenterAfterUnitDays]: "Days",
  [keys.RulesPopupLabelReenterAfterUnitHours]: "Hours",
  [keys.RulesPopupLabelReenterAfterUnitMinutes]: "Minutes",
  [keys.RulesPopupLabelReenterAfterUnitSeconds]: "Seconds",
  [keys.RulesPopupLastModified]: "Last modified",
  [keys.RulesPopupGeneral]: "General",
  [keys.RulesPopupTrigger]: "Trigger",
  [keys.RulesPopupCondictions]: "Conditions",
  [keys.RulesPopupActions]: "Actions",
  [keys.RulesPopupAddTrigger]: "Add trigger",
  [keys.RulesPopupAddCondition]: "Add condition",
  [keys.RulesPopupConditionEqualTo]: "Equal to",
  [keys.RulesPopupConditionNotEqualTo]: "Not equal to",
  [keys.RulesPopupConditionGreaterThan]: "Greater than",
  [keys.RulesPopupConditionLessThan]: "Less than",
  [keys.RulesPopupConditionExactlyOrLessThan]: "Exactly or less than",
  [keys.RulesPopupConditionExactlyOrMoreThan]: "Exactly or more than",
  [keys.RulesPopupConditionIsOneOf]: "Is one of",
  [keys.RulesPopupConditionIsNoneOf]: "Is none of",
  [keys.RulesDeleteRules]: "Delete rule",
  [keys.RulesNewRule]: "New rule",
  [keys.RulesPopupActionAddAction]: "Add action",
  [keys.RulesPopupActionSelectDefault]: "Select action",
  [keys.RulesPopupActionAskForAnswerViaMessage]: "Ask for answer via message",
  [keys.RulesPopupActionAskForAnswerViaTemplate]: "Ask for answer via template",
  [keys.RulesPopupActionSendEmailToAgent]: "Send email to agent",
  [keys.RulesPopupActionAssignAgentByCustomParam]: "Assign agent",
  [keys.RulesPopupActionSendWhatsappMessage]: "Send whatsapp message",
  [keys.RulesPopupActionLabelMessage]: "Message",
  [keys.RulesPopupActionLabelPreviewUrl]: "Preview url",
  [keys.RulesPopupActionLabelSubject]: "Subject",
  [keys.RulesPopupActionLabelAssignBy]: "Assign by",
  [keys.RulesPopupActionLabelDefault]: "Default",
  [keys.RulesPopupActionLabelChoices]: "Choices",
  [keys.RulesPopupActionLabelCustomProperty]: "Custom property",
  [keys.RulesPopupActionLabelRetryMessage]: "Retry message",
  [keys.RulesPopupActionLabelValidator]: "Validator",
  [keys.RulesPopupActionLabelTemplateId]: "Template ID",
  [keys.RulesPopupActionLabelUnsupportedActionType]: "Action payload",
  [keys.RulesPopupActionUnknownAction]: "Unknown action",
  [keys.RulesPopupActionName]: "Action name",
  [keys.RulesPopupActionYourActionPayload]: "Your action payload",
  [keys.RulesPopupConditionContains]: "Contains",
  [keys.RulesPopupConditionNotContains]: "Not contains",
  [keys.TouchpointWizardJourneySettingsTooltipMoWithLlLabel]:
    "MO with Checkbox",
  [keys.UserPopupUserNotFound]: "User does not exist",
  [keys.UsersPopupAddUserDescription]:
    "Invite new users via email, set their name and permissions. New users will receive an invite email, and an email to set their password. Each new user will be considered for the license calculation.",
  [keys.UserPopupUserSaved]: "User data saved successfully",
  [keys.UserPopupUserNotSaved]:
    "An error occurred while trying to save user data",
  [keys.UserPopupUserPasswordChangeOk]: "User password changed successfully",
  [keys.UserPopupUserPasswordChangeFailed]: "User password change failed",
  [keys.PopupLastModifiedTimeLabel]: "Last modified",
  [keys.PopupPersonalDataLabel]: "Personal",
  [keys.PopupContactDataLabel]: "Contact",
  [keys.PopupPasswordDataLabel]: "Password",
  [keys.PopupPasswordRepeatDataLabel]: "Password",
  [keys.PopupPasswordSavePasswordButton]: "Change password",
  [keys.UserPopupCreateTeamText]:
    "All members of a team will be able to see conversations assigned to its team members. Conversations assigned to agents that are not assigned to a team won't be shown. This allows to control visibility.",
  [keys.UserPopupTeamAddNewTeam]: "Create new team",
  [keys.TeamPopupConfirmationTextHeader]: "Are you sure to delete the team?",
  [keys.TeamPopupConfirmationTextDescription]:
    "Deleting a team will re-assign conversations to the default team. Conversations that have been invisible to other team members might get more visibility then.",
  [keys.UserPopupTeamNewTeam]: "Team name",
  [keys.UserPopupTeamAddTeam]: "Add team",
  [keys.UserPopupTeamNotFound]: "Team does not exist",
  [keys.UserPopupTeamSaved]: "Team data saved successfully",
  [keys.UserPopupTeamSaveError]: "An error occurred while saving the team data",
  [keys.UserPopupTeamUserListError]: "Failed to fetch users list",
  [keys.UserPopupUnassignUserFromTeam]: "Unassign user from team",
  [keys.UserPopupTeamUserAssignToTeamError]:
    "An error occurred while trying to assign user to team",
  [keys.UserPopupTeamUserUnAssignToTeamError]:
    "An error occurred while trying to unassign user from team",
  [keys.NameLabel]: "Name",
  [keys.TeamDecriptionLabel]: "Team Description",
  [keys.AvatarUrlLabel]: "Avatar Url",
  [keys.TeamNameLabel]: "Team name",
  [keys.CreatedAtLabel]: "Created",
  [keys.ModifiedAtLabel]: "Last modified",
  [keys.PermissionsLabel]: "Permissions",
  [keys.UserPopupLoginLabel]: "Login",
  [keys.GenericPopupEnabled]: "Enabled",
  [keys.GenericPopupDisabled]: "Disabled",
  [keys.DescriptionLabel]: "Description",
  [keys.UsersCountLabel]: "Users count",
  [keys.NewTeam]: "New Team",
  [keys.UsersTabLabel]: "Users",
  [keys.UsersHeader]: "Users",
  [keys.TeamMembers]: "Team members",
  [keys.TeamsTabLabel]: "Teams",
  [keys.GeneralLabel]: "General",
  [keys.UserPopupSendInvitationButton]: "Add user",
  [keys.UserPopupSendNextInvitationButton]: "Add another user",
  [keys.UserPopupEmailSentStatus]: "User has been added to the application",
  [keys.OK]: "OK",
  [keys.PasswordValidationLenght]: "Must have at least 8 characters in length",
  [keys.PasswordValidationLettersNumbers]:
    "Must contain at least one lower case (a-z), upper case (A-Z) and numbers (0-9)",
  [keys.PasswordValidationSpecialChars]:
    "Must contain at least one special character (!@#$%^&*)",
  [keys.PasswordValidationPasswordsEquality]: "Passwords must be equal",
  [keys.UserAlreadyExists]: "User already exists",
  [keys.UsersListResetPasswordTooltip]: "Reset password",
  [keys.UsersListDeleteUserTooltip]: "Delete user",
  [keys.DeleteTeamTooltipText]: "Delete team",
  [keys.DeleteTeamDefaultTeamTooltipText]: "Default team cannot be removed",
  [keys.UsersListResetPasswordSuccessNotification]:
    "an email with a link to reset the password has been sent",
  [keys.UsersListResetPasswordErrotNotification]:
    "an error occurred when trying to send an email with a link to reset the password",
  [keys.UsersListDeleteUserErrorNotification]:
    "An error occurred when trying to delete a user",
  [keys.UsersListDeleteTeamErrorNotification]:
    "An error occurred when trying to delete a team",
  [keys.UsersListDeleteUserSuccessNotification]: "User successfully deleted!",
  [keys.UsersListDeleteTeamSuccessNotification]: "Team successfully deleted!",
  [keys.WebhookTestSuccessfull]: "the test has ended correctly",
  [keys.SettingsCategoryWebhook]: "Webhook",
  [keys.WebhookTestFailed]: "the test has failed",
  [keys.WebhookDescription]:
    "All incoming messages and notifications will be forwarded to the given Webhook-URL. It needs to respond with HTTP 200, otherwise the callback will be retried (with a decreasing frequency).",
  [keys.WebhookUrlLabel]: "URL",
  [keys.WebhookTestButtonLabel]: "Test",
  [keys.WebhookTestCheckboxLabel]: "Send sample events",
  [keys.WebhookTestEventsSentLabel]: "Events sent",
  [keys.WebhookTestFailureLabel]: "Failure",
  [keys.WebhookTestResultsTitleLabel]: "Test Results",
  [keys.ConfirmButtonLabel]: "Confirm",
  [keys.ListLoadError]: "Failed to load list",
  [keys.OfficeHoursWrongTimeRangeNotification]:
    "wrongly specified time range for day(s): ",
  [keys.GenericListHeaderHide]: "Minimize",
  [keys.GenericListHeaderShow]: "Maximize",
  [keys.SearchInputComponentPlaceholder]: "Search",
  [keys.GenericListConfirmationPopupDefaultText]: "Are you sure?",
  [keys.GenericListPagingNext]: "Next",
  [keys.GenericListPagingPrev]: "Prev",
  [keys.WabaListAppId]: "App id",
  [keys.WabaListModifiedAt]: "Modified At",
  [keys.WabaListLabelStatus]: "State",
  [keys.WabaListLabelStackId]: "Stack Id",
  [keys.WabaListActionTriggerRegistrationTooltip]: "Trigger Registration",
  [keys.WabaListActionFinishRegistrationTooltip]: "Finish Registration",
  [keys.WabaTriggerRegistrationPlaceholderNotChangedValues]:
    "Use value from integration",
  [keys.WabaSetupOrganisation]: "Organisation",
  [keys.WabaSetupAppName]: "App name",
  [keys.WabaSetupNamespace]: "Namespace",
  [keys.WabaSetupStackID]: "Stack ID",
  [keys.WabaSetupVerificationMethod]: "Verification Method",
  [keys.WabaSetupVerificationCode]: "Verification Code",
  [keys.WabaSetupDefaultLanguageLabel]: "Default language",
  [keys.WabaSetupCertificate]: "Certificate",
  [keys.WabaSetupSelectVerification]: "Select verification",
  [keys.WabaSetupSelectLanguageLabel]: "Select language",
  [keys.WabaSetupFailedToSetupWaba]: "Failed to setup waba",
  [keys.WabaSetupWabaSuccesfullyCreated]: "Waba succesfully created!",
  [keys.WabaTriggerRegistrationSaveButtonLabel]: "Send registration code",
  [keys.WabaFinishRegistrationSaveButtonLabel]: "Save",
  [keys.WabaSetupHeader]: "Setup new Waba",
  [keys.ContactLabelName]: "Name",
  [keys.ContactLabelEmail]: "Email",
  [keys.ContactLabelPhone]: "Phone",
  [keys.ContactLabelClickToDownload]: "Click to Download",
  [keys.MessageInputSendUrlImageUrl]: "Image url",
  [keys.MessageInputSendUrlImageCaption]: "Image caption",
  [keys.MessageInputSendUrlImageAlt]: "Url image",
  [keys.MessageInputSendUrlImageError]: "Failed to send image",
  [keys.MessageLocalizationNotSupportedErrorMessage]:
    "Geolocation is not supported by this browser.",
  [keys.MessageLocalizationDeterminationErrorMessage]:
    "Error in determination of your location.",
  [keys.InputMessageSendContactSuccess]: "The contact was successfully sent",
  [keys.InputMessageSendContactFailed]:
    "An error occurred while sending a contact",
  [keys.InputMessageImportVcardFailed]:
    "Error while reading the file, check if you have picked the vCard file (*.vcf)",
  [keys.ClientsTabGeneral]: "General",
  [keys.ClientsTabContactInfo]: "Contact Info",
  [keys.ClientsTabContactUser]: "Contact User",
  [keys.ClientsTabMetaInfo]: "Meta Info",
  [keys.ClientsCreateNewClient]: "Create new client",
  [keys.ClientsName]: "Name",
  [keys.ClientsOrganisation]: "Organisation",
  [keys.ClientsWebpageUrl]: "Webpage Url",
  [keys.ClientsPhone]: "Phone",
  [keys.ClientsLanguage]: "Language",
  [keys.ClientsCountry]: "Country",
  [keys.ClientsStreetName]: "Street Name",
  [keys.ClientsCity]: "City",
  [keys.ClientsZipCode]: "ZipCode",
  [keys.ClientsBusinessVertical]: "Business Vertical",
  [keys.ClientsTimezone]: "Timezone",
  [keys.ClientsAbout]: "About",
  [keys.ClientsBusinessDescription]: "Business Description",
  [keys.ClientsUseCase]: "Use Case",
  [keys.ClientsUserPhone]: "User Phone",
  [keys.ClientsEmail]: "Email",
  [keys.ClientsUserName]: "User Name",
  [keys.ClientsModifiedAt]: "Modified At",
  [keys.ClientsStatus]: "Status",
  [keys.ClientsNotificationFailedToLoadClient]:
    "An error occurred while trying to load client data",
  [keys.ClientsNotificationFailedToSaveClientData]:
    "An error occurred while trying to save client data",
  [keys.ClientsNotificationSuccesfullySavedClientData]:
    "Client data saved succesfully!",
  [keys.ClientsEditClientData]: "Edit client data",
  [keys.PartnersName]: "partner name",
  [keys.PartnersDefaultOrganisationLabel]: "org key",
  [keys.PartnerLegalName]: "partner - Legal Entity Name",
  [keys.PartnersLicense]: "License",
  [keys.PartnersDefaultOrganisation]: "Default organisation",
  [keys.PartnersStatus]: "Status",
  [keys.PartnersOrderDocument]: "Order document",
  [keys.PartnersSalesUserId]: "Sales user ID",
  [keys.PartnersFinanceUserId]: "Finance user ID",
  [keys.PartnersOperationUserId]: "Operation user ID",
  [keys.PartnersDialogSalesUserId]: "Dialog sales user ID",
  [keys.PartnersBusinessName]: "Name",
  [keys.PartnersOrganisation]: "Organisation",
  [keys.PartnersNotes]: "Notes",
  [keys.PartnersMarkets]: "Markets",
  [keys.PartnersTimezone]: "Timezone",
  [keys.PartnersDescription]: "Description",
  [keys.PartnersWebsite]: "Website",
  [keys.PartnersPhoneNumber]: "Phone number",
  [keys.PartnersEmail]: "Email",
  [keys.PartnersLanguage]: "Language",
  [keys.PartnersCountry]: "Country",
  [keys.PartnersStreetName]: "Street name",
  [keys.PartnersCity]: "City",
  [keys.PartnersZipCode]: "Zip code",
  [keys.PartnersState]: "State",
  [keys.PartnersModifiedAt]: "Modified At",
  [keys.PartnersFailedToSaveData]:
    "An error occurred while trying to save partner data",
  [keys.PartnersSuccesfullySavedData]: "Partner data saved succesfully!",
  [keys.PartnersFailedToLoadData]:
    "An error occurred while trying to load partner data",
  [keys.PartnersTabBusinessInfo]: "Business Info",
  [keys.PartnersTabContactInfo]: "Contact Info",
  [keys.PartnersTabGeneral]: "General",
  [keys.PartnersEditPartner]: "Edit partner data",
  [keys.PartnersCreateNewPartner]: "Create new partner",
  [keys.PartnerUserFinance]: "Finance",
  [keys.PartnerUserSales]: "Sales",
  [keys.PartnerUserDialogSales]: "Dialog sales",
  [keys.PartnerUserOperation]: "Operation",
  [keys.PartnerUserPassword]: "Password",
  [keys.PartnerUserPicture]: "Picture",
  [keys.PartnerUserType]: "User type",
  [keys.PartnerUserCreate]: "Create partner user",
  [keys.PartnerUserFailedToCreate]:
    "An error occurred while trying to save partner user data",
  [keys.SmartPanelEmojiPicker]: "Emoji-Picker",
  [keys.SmartPanelUploadFile]: "Select file for upload",
  [keys.SmartPanelShareLocation]: "Share location (using google maps URL)",
  [keys.SmartPanelSendImage]:
    "Send image using a link (instead of uploading the image)",
  [keys.SmartPanelSendTemplate]:
    "Send template-based message (for example to reach users when the 24h-window is closed)",
  [keys.SmartPanelSendButton]: "",
  [keys.ProjectName]: "Name",
  [keys.ProjectLicenseModel]: "License model",
  [keys.ProjectStatus]: "Status",
  [keys.ProjectsCreateNewProject]: "Create new project",
  [keys.ProjectFailedToSaveData]:
    "An error occurred while trying to save project data",
  [keys.ProjectSuccesfullySavedData]: "Project data saved succesfully!",
  [keys.ProjectFailedToLoadData]:
    "An error occurred while trying to load project data",
  [keys.ProjectLabelProject]: "Projects",
  [keys.ProjectEditData]: "Edit project data",
  [keys.WabaAccountName]: "Name",
  [keys.WabaAccountTimezoneListFailedToLoad]:
    "An error occurred while trying to load timezone list",
  [keys.WabaAccountCreateNewWaba]: "Create new Waba Account",
  [keys.WabaAccountExternalId]: "External ID",
  [keys.WabaAccountFbBusinessId]: "FB business ID",
  [keys.WabaAccountFbBusinessIdStatus]: "FB business ID Status",
  [keys.WabaAccountOnBehalfStatus]: "On Behalf of Business Status",
  [keys.WabaAccountNamespace]: "Namespace",
  [keys.WabaAccountTimezoneId]: "Timezone ID",
  [keys.WabaAccountPrimaryFundingId]: "Primary funding ID",
  [keys.WabaAccountFailedToSaveData]:
    "An error occurred while trying to save waba account data",
  [keys.WabaAccountSuccesfullySavedData]:
    "Waba account data saved succesfully!",
  // [keys.WabaAccountNewNameRequested]:
  //   "New display name requested",
  [keys.WabaAccountFailedToLoadData]:
    "An error occurred while trying to load waba account data",
  [keys.WabaAccountSubmitWabaAccount]: "Submit waba account",
  [keys.WabaAccountSubmitWabaAccountTooltipText]:
    "Click here to submit waba account",
  [keys.WabaAccountSubmitWabaAccountTooltipTextError]:
    "Not all required (to submit) data are filled, check Waba Account configuration",
  [keys.WabaAccountSubmitWabaAccountTooltipTextSaveFirst]:
    "WabaAccount setting has been changed, please save it before submitting",
  [keys.WabaAccountSubmitWabaAccountFailed]:
    "An error occurred while trying to submit waba account",
  [keys.WabaAccountSubmitWabaAccountSucces]:
    "Waba account succesfully submited!",
  [keys.WabaAccountUpdateTemplateNamespace]: "Update template namespace",
  [keys.WabaAccountUpdateTemplateNamespaceTooltipText]:
    "Click here to update template namespace",
  [keys.WabaAccountUpdateTemplateNamespaceFailed]:
    "An error occurred while trying to update template namespace",
  [keys.WabaAccountUpdateTemplateNamespaceSucces]:
    "Template namespace succesfully updated!",
  [keys.WabaAccountSynchronizeStatus]: "Synchronize status",
  [keys.WabaAccountSynchronizeStatusTooltipText]:
    "Click here to synchronize waba status",
  [keys.WabaAccountSynchronizeStatusSucces]:
    "Waba status succesfully synchronized!",
  [keys.WabaAccountSynchronizeStatusFailed]:
    "An error occurred while trying to synchronize waba status",
  [keys.EditWabaAccount]: "Edit waba account data",
  [keys.WabaDeleteWabaAccount]: "Delete waba account",
  [keys.WabaSuccesfullyDeletedWabaAccount]: "Waba account deleted succesfully!",
  [keys.WabaFailedToDeleteWabaAccount]:
    "An error occurred while trying to delete waba account",
  [keys.CopyToClipboard]: "Copy URL to clipboard",
  [keys.MessageInputSendUrlLocationUrl]: "Google maps url",
  [keys.MessageInputSendUrlLocationName]: "Location name",
  [keys.MessageInputSendUrlLocationAlt]: "Url image",
  [keys.MessageInputSendUrlLocationError]: "Failed to send locations",
  [keys.ChannelsSubmit]: "Submit",
  [keys.ChannelsSubmitTooltipText]: "Click here to submit channel",
  [keys.ChannelsSubmitTooltipTextError]:
    "Not all required (to submit) data are filled, check Channel configuration",
  [keys.ChannelsSubmitTooltipTextSaveFirst]:
    "Channel setting has been changed, please save it before submitting",
  [keys.ChannelsSubmitFailed]:
    "An error occurred while trying to submit channel",
  [keys.ChannelsSubmitSucces]: "Channel submited succesfully!",
  [keys.ChannelsUpdateStatus]: "Update status",
  [keys.ChannelsUpdateStatusTooltipText]: "Click here to update channel status",
  [keys.ChannelsUpdateStatusFailed]:
    "An error occurred while trying to update channel status",
  [keys.ChannelsFailedToLoadDefaultLanguageList]:
    "An error occurred while trying to load default language list",
  [keys.ChannelsUpdateStatusSucces]: "Channel status updated succesfully!",
  [keys.ChannelsWabaAccountId]: "Waba account ID",
  [keys.ChannelsPictureUrl]: "Picture URL",
  [keys.ChannelsAboutText]: "About text",
  [keys.ChannelsBusinessVertical]: "Business vertical",
  [keys.ChannelsTimezone]: "Timezone",
  [keys.ChannelsBusinessDescription]: "Business description",
  [keys.ChannelsUseCaseDescription]: "Use case description",
  [keys.ChannelsPhoneNumber]: "Phone number",
  [keys.ChannelsPhoneName]: "Display name",
  [keys.ChannelsWasInUse]: "IVR is activated?",
  [keys.ChannelsVerificationMethod]: "Verification method",
  [keys.ChannelsCertificate]: "Certificate",
  [keys.FailedToLoadPartnerChannel]:
    "An error occurred while trying to load partner channel data",
  [keys.PartnerChannelClientLegalName]: "Client Name",
  [keys.PartnerChannelFbIdName]: "Meta Business Name",
  [keys.PartnerChannelFbPrimaryPageName]: "Primary Page",
  [keys.PartnerChannelChannelId]: "Channel ID",
  [keys.PartnerChannelWrongFbID]: "Wrong FB business ID",
  [keys.PartnerChannelWabaChannelStatus]: "Waba channel status",
  [keys.PartnerChannelWabaAccountStatus]: "Waba account status",
  [keys.PartnerChannelMessageOnBehalfStatus]: "Message on behalf status",
  [keys.PartnerChannelProjectName]: "Project Name",
  [keys.PartnerChannelClientName]: "Client Name",
  [keys.PartnerTriggerActivationErrorTooltip]:
    "Not all required fields are filled, please check partner setting",
  [keys.PartnerApproveErrorTooltip]:
    "Not all required fields are filled, please check partner setting",
  [keys.PartnerTriggerActivationTooltip]: "Trigger Activation",
  [keys.PartnerApproveTooltip]: "Approve partner",
  [keys.PartnerApproveSuccessMessage]: "partner has been successfully approved",
  [keys.PartnerApproveErrorMessage]:
    "An error occurred while sending partner Approved request",
  [keys.PartnerTriggerActivationSuccessMessage]:
    "partner Trigger Activation has been successfully sent",
  [keys.PartnerTriggerActivationErrorMessage]:
    "An error occurred while sending partner Trigger Activation request",
  [keys.ChannelsDefaultLanguage]: "Default language",
  [keys.ChannelsFailedToSaveData]:
    "An error occurred while trying to save channel data",
  [keys.ChannelsSuccesfullySavedData]:
    "Channel account data saved succesfully!",
  [keys.ChannelsFailedToLoadData]:
    "An error occurred while trying to load channel data",
  [keys.ChannelsEditData]: "Edit channel data",
  [keys.ChannelsSetupInfo]: "Setup info",
  [keys.ChannelsProfileInfo]: "Profile info",
  [keys.ChannelsNewChannel]: "Create new channel",
  [keys.ChannelsApiUserEmail]: "Api user email",
  [keys.ChannelsWebhookUrl]: "Webhook url",
  [keys.Clients]: "Clients",
  [keys.Projects]: "Projects",
  [keys.WabaAccount]: "Waba account",
  [keys.OnBehalfBusinessInfo]: "On behalf business info",
  [keys.Integration]: "Integration",
  [keys.Parameters]: "Parameters",
  [keys.ShowFbBusinessAccountInfo]: "Show Meta business account info",
  [keys.FacebookBusinessInfoPreview]:
    "Go to Meta business account info preview",
  [keys.FacebookBusinessInfoPreviewFailedToLoad]:
    "An error occurred while trying to load Meta business account info data",
  [keys.FastWabaCreatePopupHeader]: "Waba Setup",
  [keys.FastWabaCreatePopupFailedToLoadData]:
    "An error occurred while trying to load waba setup necessary data",
  [keys.FastWabaCreatePopupSelectExisitingClient]: "Select existing client",
  [keys.FastWabaCreatePopupAddNewClient]: "Add new client",
  [keys.FastWabaCreatePopupSelectExistingWabaAccount]:
    "Select existing waba account",
  [keys.FastWabaCreatePopupAddNewWabaAccount]: "Add new waba account",
  [keys.FastWabaCreatePopupAddNewProject]: "Add new project",
  [keys.ContactNameFieldLabel]: "Name",
  [keys.ContactPhonesFieldLabel]: "Phones",
  [keys.ContactEmailsFieldLabel]: "Emails",
  [keys.ContactUrlsFieldLabel]: "Urls",
  [keys.ContactAddressesFieldLabel]: "Addresses",
  [keys.Contact_name_first_name]: "First name",
  [keys.Contact_name_last_name]: "Last name",
  [keys.Contact_name_formatted_name]: "display name",
  [keys.Contact_emails_email]: "Email",
  [keys.Contact_emails_type]: "Type",
  [keys.Contact_phones_phone]: "Phone",
  [keys.Contact_phones_type]: "Type",
  [keys.Contact_urls_url]: "Url",
  [keys.Contact_urls_type]: "Type",
  [keys.Contact_addresses_street]: "Street",
  [keys.Contact_addresses_city]: "City",
  [keys.Contact_addresses_state]: "State",
  [keys.Contact_addresses_zip]: "Zip",
  [keys.Contact_addresses_country]: "Country",
  [keys.Contact_addresses_country_code]: "Country code",
  [keys.Contact_addresses_type]: "Type",
  [keys.ContactUploadVCardButtonLabel]: "Upload VCard file",
  [keys.SmartPanelContactIconTooltip]: "Edit and send contact",
  [keys.ContactFormErrorMessageFormattedNameNotFound]:
    "Please provide the first and/or last name in combination with a display name for the given contact. The display name will be shown for the contact.",
  [keys.ClientContactUserEmailIsTooLong]:
    "email should be up to 128 characters",
  [keys.ClientMetaInfoDecriptionIsTooLong]:
    "description should be up to 256 characters",
  [keys.ChannelProfileInfoAboutTextIsTooLong]:
    "about text should be up to 139 characters",
  [keys.Unknown]: "unknown",
  [keys.ParterUsersPasswordMinLengthErrorInformation]:
    "The password should be at least 8 characters",
  [keys.SomethingWentWrongError]: "Something went wrong",
  [keys.PipeyRenemberToSave]: "Remember to save your changes!",
  [keys.PipeyFillRequiredData]:
    "Pls fix required to submit data to be able to submit",
  // [keys.Step]: "translation_missing",
};

export default translate;
