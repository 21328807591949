// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.LNG_af]: "Afrikaans",
  [keys.LNG_sq]: "Albanés",
  [keys.LNG_ar]: "Árabe",
  [keys.LNG_az]: "Azerí",
  [keys.LNG_bn]: "Bengalí",
  [keys.LNG_bg]: "Búlgaro",
  [keys.LNG_ca]: "Catalán",
  [keys.LNG_zh_CN]: "Chino (CHN)",
  [keys.LNG_zh_HK]: "Chino (HKG)",
  [keys.LNG_zh_TW]: "Chino (TAI)",
  [keys.LNG_hr]: "Croata",
  [keys.LNG_cs]: "Checo",
  [keys.LNG_da]: "Danés",
  [keys.LNG_nl]: "Neerlandés",
  [keys.LNG_en]: "Inglés",
  [keys.LNG_en_GB]: "Inglés (UK)",
  [keys.LNG_en_US]: "Inglés (US)",
  [keys.LNG_et]: "Estonio",
  [keys.LNG_fil]: "Filipino",
  [keys.LNG_fi]: "Finés",
  [keys.LNG_fr]: "Francés",
  [keys.LNG_de]: "Alemán",
  [keys.LNG_el]: "Griego",
  [keys.LNG_gu]: "Gujarati",
  [keys.LNG_ha]: "Hausa",
  [keys.LNG_he]: "Hebreo",
  [keys.LNG_hi]: "Hindi",
  [keys.LNG_hu]: "Húngaro",
  [keys.LNG_id]: "Indonesio",
  [keys.LNG_ga]: "Irlandés",
  [keys.LNG_it]: "Italiano",
  [keys.LNG_ja]: "Japonés",
  [keys.LNG_kn]: "Canarés",
  [keys.LNG_kk]: "Kazajo",
  [keys.LNG_ko]: "Coreano",
  [keys.LNG_lo]: "Laosiano",
  [keys.LNG_lv]: "Letonio",
  [keys.LNG_lt]: "Lituano",
  [keys.LNG_mk]: "Macedonio",
  [keys.LNG_ms]: "Malayo",
  [keys.LNG_ml]: "Malayalam",
  [keys.LNG_mr]: "Maratí",
  [keys.LNG_nb]: "Noruego",
  [keys.LNG_fa]: "Persa",
  [keys.LNG_pl]: "Polaco",
  [keys.LNG_pt_BR]: "Portugués (BR)",
  [keys.LNG_pt_PT]: "Portugués (POR)",
  [keys.LNG_pa]: "Punjabi",
  [keys.LNG_ro]: "Rumano",
  [keys.LNG_ru]: "Ruso",
  [keys.LNG_sr]: "Serbio",
  [keys.LNG_sk]: "Eslovaco",
  [keys.LNG_sl]: "Esloveno",
  [keys.LNG_es]: "Español",
  [keys.LNG_es_AR]: "Español (ARG)",
  [keys.LNG_es_ES]: "Español (ESP)",
  [keys.LNG_es_MX]: "Español (MEX)",
  [keys.LNG_sw]: "Suahili",
  [keys.LNG_sv]: "Sueco",
  [keys.LNG_ta]: "Tamil",
  [keys.LNG_te]: "Telugu",
  [keys.LNG_th]: "Tailandés",
  [keys.LNG_tr]: "Turco",
  [keys.LNG_uk]: "Ucraniano",
  [keys.LNG_ur]: "Urdu",
  [keys.LNG_uz]: "Uzbeco",
  [keys.LNG_vi]: "Vietnamita",
  [keys.LNG_zu]: "Zulu",
};

export default translate;
