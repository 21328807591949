// File generated automatically. Do not edit manually.

import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.INT_integrations]: "Integrações",
  [keys.INT_syncEvents]: "Sincronize os eventos dos seus apps favoritos",
  //  [keys.INT_shopifyApp]: "translation_missing",
  //  [keys.INT_connectCTADescription]: "translation_missing",
  //  [keys.INT_connect]: "translation_missing",
  //  [keys.INT_supperChargingInsightDescription]: "translation_missing",
  [keys.INT_suggestIntegarationsDescription]:
    "Tem alguma integração que você gostaria de ver? Nos deixe saber preenchendo o formulário abaixo!",
  [keys.INT_suggestIntegarations]: "Sugira uma integração!",
  //  [keys.INT_connectedApps]: "translation_missing",
  //  [keys.INT_upgrade]: "translation_missing",
  //  [keys.INT_shopify]: "translation_missing",
  [keys.INT_shopifyDescription]:
    "Monitore os eventos de compra da sua loja Shopify, e atribua esse evento as conversas de WhatsApp respectivas!",
  //  [keys.INT_appNotAvailable]: "translation_missing",
  [keys.INT_comingSoon]: "Em Breve",
  // [keys.INT_install]: "translation_missing",
};

export default translate;
