// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.LNG_af]: "Africâner",
  [keys.LNG_sq]: "Albanês",
  [keys.LNG_ar]: "Árabe",
  [keys.LNG_az]: "Azerbaijano",
  [keys.LNG_bn]: "Bengali",
  [keys.LNG_bg]: "Búlgaro",
  [keys.LNG_ca]: "Catalão",
  [keys.LNG_zh_CN]: "Chinês (CHN)",
  [keys.LNG_zh_HK]: "Chinês (HKG)",
  [keys.LNG_zh_TW]: "Chinês (TAI)",
  [keys.LNG_hr]: "Croata",
  [keys.LNG_cs]: "Tcheco",
  [keys.LNG_da]: "Dinamarquês",
  [keys.LNG_nl]: "Holandês",
  [keys.LNG_en]: "Inglês",
  [keys.LNG_en_GB]: "Inglês (UK)",
  [keys.LNG_en_US]: "Inglês (US)",
  [keys.LNG_et]: "Estoniano",
  [keys.LNG_fil]: "Filipino",
  [keys.LNG_fi]: "Finlandês",
  [keys.LNG_fr]: "Francês",
  [keys.LNG_de]: "Alemão",
  [keys.LNG_el]: "Grego",
  [keys.LNG_gu]: "Gujarati",
  [keys.LNG_ha]: "Hausa",
  [keys.LNG_he]: "Hebraico",
  [keys.LNG_hi]: "Hindi",
  [keys.LNG_hu]: "Húngaro",
  [keys.LNG_id]: "Indonésio",
  [keys.LNG_ga]: "Irlandês",
  [keys.LNG_it]: "Italiano",
  [keys.LNG_ja]: "Japonês",
  [keys.LNG_kn]: "Canarês",
  [keys.LNG_kk]: "Cazaque",
  [keys.LNG_ko]: "Coreano",
  [keys.LNG_lo]: "Laosiano",
  [keys.LNG_lv]: "Letão",
  [keys.LNG_lt]: "Lituano",
  [keys.LNG_mk]: "Macedônio",
  [keys.LNG_ms]: "Malaio",
  [keys.LNG_ml]: "Malayalam",
  [keys.LNG_mr]: "Marathi",
  [keys.LNG_nb]: "Norueguês",
  [keys.LNG_fa]: "Persa",
  [keys.LNG_pl]: "Polonês",
  [keys.LNG_pt_BR]: "Português (BR)",
  [keys.LNG_pt_PT]: "Português (POR)",
  [keys.LNG_pa]: "Punjabi",
  [keys.LNG_ro]: "Romeno",
  [keys.LNG_ru]: "Russo",
  [keys.LNG_sr]: "Sérvio",
  [keys.LNG_sk]: "Eslovaco",
  [keys.LNG_sl]: "Esloveno",
  [keys.LNG_es]: "Espanhol",
  [keys.LNG_es_AR]: "Espanhol (ARG)",
  [keys.LNG_es_ES]: "Espanhol (ESP)",
  [keys.LNG_es_MX]: "Espanhol (MEX)",
  [keys.LNG_sw]: "Suaíli",
  [keys.LNG_sv]: "Sueco",
  [keys.LNG_ta]: "Tâmil",
  [keys.LNG_te]: "Telugu",
  [keys.LNG_th]: "Tailandês",
  [keys.LNG_tr]: "Turco",
  [keys.LNG_uk]: "Ucraniano",
  [keys.LNG_ur]: "Urdu",
  [keys.LNG_uz]: "Usbeque",
  [keys.LNG_vi]: "Vietnamita",
  [keys.LNG_zu]: "Zulu",
};

export default translate;
