import * as Types from "./wabaAccount.types";
import mapToUndefinied from "@/utilities/mapToUndefinied";

export const unpackWabaAccount = (
  response: Types.IServerResponseWabaAccount
): Types.IUIResponseWabaAccount => ({
  id: response.id,
  partnerId: response.partner_id,
  clientId: response.client_id,
  externalId: response.external_id || "",
  fbBusinessId: response.fb_business_id || "",
  debugError: response.debug_error,
  fbAccountStatus: response.fb_account_status,
  consents: response.consents,
  hasSignedConsents: response.has_signed_consents,
  name: response.name,
  namespace: response.namespace || "",
  onBehalfOfBusinessInfo: response.on_behalf_of_business_info
    ? {
        name: response.on_behalf_of_business_info?.name,
        id: response.on_behalf_of_business_info?.id,
        status: response.on_behalf_of_business_info?.status,
        type: response.on_behalf_of_business_info?.type,
      }
    : null,
  primaryFundingId: response.primary_funding_id || "",
  status: response.status,
  timezoneId: response.timezone_id || "",
  modifiedAt: response.modified_at,
  createdAt: response.created_at,
});

export const unpackWabaAccounts = (
  response: Types.IServerResponseWabaAccounts
): Types.IUIResponseWabaAccounts => ({
  wabaAccounts: response.waba_accounts.map((wabaAccount) =>
    unpackWabaAccount(wabaAccount)
  ),
  total: response.total,
});

export const packWabaAccount = (
  request: Types.IWabaAccountFormValues
): Types.IServerRequestWabaAccount => ({
  name: request.name,
  namespace: mapToUndefinied(request.namespace),
  fb_business_id: mapToUndefinied(request.fbBusinessId),
  primary_funding_id: mapToUndefinied(request.primaryFundingId),
  external_id: mapToUndefinied(request.externalId),
  timezone_id: mapToUndefinied(request.timezoneId),
});

export const unpackWabaAccountPayment = (
  response: Types.IServerResponseWabaPaymentIntent
): Types.IUIResponseWabaPaymentIntent => ({
  publishableKey: response.publishable_key,
  clientSecret: response.client_secret,
});
