// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.A_SomethingWentWrongError]: "Что-то пошло не так",
  [keys.A_SelectDefaultPlaceholder]: "Выбирете...",
  [keys.A_phoneNumber]: "Номер телефона",
  [keys.A_waba]: "Аккаунт WhatsApp Business",
  [keys.A_client]: "Клиент",
  [keys.A_partner]: "Партнер",
  [keys.A_documentation]: "документация",
  [keys.A_email]: "Email",
  [keys.A_password]: "Пароль",
  [keys.A_back]: "Назад",
  [keys.A_sortBy]: "Сортировка по",
  [keys.A_date]: "дата",
  [keys.A_status]: "статус",
  [keys.A_displayName]: "Показать имя",
  [keys.A_filterByStatus]: "Фильтрация по статусу",
  [keys.A_searchHere]: "Поиск",
  [keys.A_account]: "Аккаунт",
  [keys.A_accountName]: "Имя аккаунта",
  [keys.A_search]: "Поиск",
  [keys.A_cancel]: "Отмена",
  [keys.A_continue]: "Продолжить",
  [keys.A_close]: "Закрыть",
  [keys.A_delete]: "Удалить",
  [keys.A_edit]: "Редактировать",
  [keys.A_and]: "и",
  [keys.A_name]: "Имя",
  [keys.A_deletePlaceholder]: "Введите <DELETE> для подтверждения",
  [keys.A_deleteHelptextDefault]:
    "Это действие является необратимым. Вы уверены что хотите продолжить?",
  [keys.A_viewDocs]: "Посмотреть документацию",
  [keys.A_defaultLanguage]: "Язык по умолчанию",
  [keys.A_save]: "Сохранить",
  [keys.A_learnMore]: "Узнать больше",
  // [keys.A_audiencesLearnMore]: "translation_missing",
  [keys.A_address]: "Адрес",
  [keys.A_description]: "Описание",
  [keys.A_website]: "Вэб-сайт",
  [keys.A_deleteImage]: "Удалить изображение",
  [keys.A_dragYourImage]: "Перетащите изображение сюда или",
  [keys.A_browse]: "выбирете файл",
  [keys.A_supportsFormats]: "Поддерживаются форматы jpg, png и svg",
  [keys.A_upload]: "Загрузка",
  [keys.A_imageMaxSize]: "Размер файла не должен превышать 3 МБ",
  [keys.A_paymentMethodCountrySelectLabel]:
    "Выберите страну, чтобы увидеть доступные способы оплаты.",
  [keys.A_intCreditCard]: "Международная кредитная карта",
  [keys.A_paymentMethodSelectLabel]: "Выберите предпочтительный способ оплаты",
  [keys.A_savePaymentMethod]: "Сохранить метод оплаты",
  [keys.A_country]: "Страна",
  [keys.A_cardExistingText]: "Вы уже привязали кредитную карту",
  [keys.A_addAnotherCardButton]: "Добавить другую карту",
  [keys.A_creditCardFormHelptext]:
    "Пожалуйста, введите информацию о вашей кредитной карте ниже.",
  [keys.A_submitCardButton]: "Привязать карту",
  [keys.A_termsStep1]: "Я согласен с",
  [keys.A_termsOfService]: "условиями пользования",
  [keys.A_termsConnect]: "и с",
  [keys.A_terms360exhibit1]: "Приложение 1 - Условия пользования 360dialog",
  [keys.A_terms360exhibit3]: "Приложение 3 - Ценообразование 360dialog",
  [keys.A_waTermsStep1]: "Я осведомлен и согласен с",
  [keys.A_terms360exhibit2]:
    "Приложение 2 - Условия Meta для использования WhatsApp",
  [keys.A_partnerTermsConnect]: "и",
  [keys.A_privacyPolicy]: "Политика конфиденциальности",
  [keys.A_partnersTermsAndConditions]: "Пользовательское соглашение",
  [keys.A_selectNumberTitle]: "Выбрать номер",
  [keys.A_addNewNumber]: "Добавить новый номер",
  [keys.A_or]: "или",
  [keys.A_tooltipEmbeddedSignup]:
    "Заявки, созданные с помощью этой формы,будут обработаны в соответсвии с процессом активации Embedded Signup. Активируйте учетные записи менее чем за 10 минут и завершите процесс верификации бизнеса-аккаунта Meta позже. <LINK>https://docs.360dialog.com/embedded-signup | Learn more.<LINK>",
  [keys.A_tooltipClassicSignup]:
    "Заявки, созданные с помощью этой формы,будут обработаны в соответсвии с классическим процессом активации. Бизнес-аккаунт Meta должен быть полностью верифицирован перед получением доступа к API. <LINK>https://docs.360dialog.com/submission-process | Learn more.<LINK>",
  [keys.A_loading]: "Загрузка",
  [keys.A_notImage]: "Загружаемый файл должен быть в формате jpg, png или svg",
  [keys.A_tooltipNumberPortingSignup]:
    "Отправка данных с использованием этой формы позволит клиентам создать учетную запись путем переноса номера от другого BSP. <LINK>https://docs.360dialog.com/360-client-hub/number-porting | Learn more.<LINK>",
  [keys.A_FbmId]: "Business Manager ID",
  [keys.A_buttonLabelReturnToHub]: "Вернуться в 360dialog Client Hub",
  //  [keys.A_notificationNoPermission]: "translation_missing",
  //  [keys.A_textNoPermission]: "translation_missing",
  [keys.A_continueNext]: "Перейти к следующему шагу",
  [keys.A_pleaseTryAgain]:
    "В настоящее время у нас возникают проблемы с входом в систему. Пожалуйста, повторите попытку через 30 минут.",
  [keys.A_clientsSearchPlaceholder]: "Поиск по имени",
  [keys.A_terms]: "Условия пользования",
  [keys.A_userNotExists]:
    "Этот пользователь не существует. Пожалуйста, зарегистрируйтесь через приложение вашего Партнера или 360dialog.com",
  [keys.A_verificationError]:
    "Что-то пошло не так, пожалуйста, попробуйте еще раз",
  [keys.A_partnerIsBlocked]: "Партнер заблокирован для новых заявок",
  [keys.A_urlCopiedToClipboard]: "URL скопирован в буфер обмена",
  [keys.A_latestSearch]: "Последний поиск",
  [keys.A_loadingBillingPortal]: "Загрузка платежного портала",
  [keys.NOT_allNotifications]: "Все",
  [keys.NOT_archivedNotifications]: "Архив",
  [keys.NOT_closeButtonTooltip]: "Поместить это уведомление в архив",
  [keys.NOT_archiveAllBtn]: "Поместить все в архив",
  [keys.NOT_clientAdded]: "Новый клиент будет добавлен в ваш партнерский хаб",
  [keys.A_errorDownloadingCSV]:
    "Произошла ошибка при попытке загрузить файл CSV. Пожалуйста, попробуйте позже.",
  [keys.A_financialReportSentToEmail]:
    "Финансовый отчет будет отправлен на ваш адрес электронной почты в ближайшее время.",
  [keys.A_errorGeneratingFinancialReport]:
    "Произошла ошибка при формировании финансового отчета. Пожалуйста, попробуйте позже.",
  [keys.A_exportAllContactData]: "Экспорт всех контактных данных",
  [keys.A_generateFinancialReport]: "Сформировать финансовый отчет",
  [keys.A_generateInvoices]: "Сформировать счета",
  [keys.A_templateMessages]: "Шаблонные сообщения",
  [keys.A_generateTemplateInvoices]: "Создать шаблоны счетов",
  [keys.A_generateTemplateReport]: "Создать шаблон отчета",
  [keys.A_conversationBasedPricing]: "Ценообразование на основе диалогов",
  [keys.A_generateCBPInvoices]: "Сгенерировать счета за диалоги",
  [keys.A_generateCBPDailyReport]: "Сгенерировать ежедневные отчет по диалогам",
  [keys.A_generateCBPMonthlyReport]:
    "Сгенерировать ежемесячный отчет по диалогам",
  [keys.A_invoiceCreationTriggered]:
    "Запущено создание счета. Счета будут отправлены по электронной почте.",
  [keys.A_invoiceDateNeedsToBeFirstOrLast]:
    "Дата выставления счета должна быть первым или последним днем месяца.",
  [keys.A_dateOfInvoice]: "Дата выставления счета",
  [keys.A_selectInvoicingDate]: "Выберите дату выставления счета",
  [keys.A_reportCreationTriggered]:
    "Запущено создание отчета. Отчет будет отправлен по электронной почте.",
  [keys.A_triggerInvoiceCreation]: "Trigger invoice creation",
  [keys.A_selectReportDate]: "Выберите дату отчета",
  [keys.A_dateOfReport]: "Дата отчета",
  [keys.A_monthlyReportCreationTriggered]:
    "Запущено создание ежемесячного отчета. Отчет будет отправлен по электронной почте.",
  [keys.A_dailyReportCreationTriggered]:
    "Запущено создание ежедневного отчета. Отчет будет отправлен по электронной почте.",
  [keys.A_selectMonthlyReportDate]: "Выберите дату ежемесячного отчета",
  [keys.A_selectDailyReportDate]: "Выберите дату ежедневного отчета",
  [keys.A_somethingWentWrongnTryAgain]:
    "Что-то пошло не так. Пожалуйста, повторите попытку позже",
  [keys.A_linkURL]: "URL адрес",
  [keys.A_parameters]: "Параметры",
  [keys.A_imageRecommendation]:
    "Рекомендация: максимальный размер файла 5 МБ и размер изображения 640x640.",
  [keys.A_displayLogoAndDeepLinkNotice]:
    "Так клиенты увидят ваш логотип и торговую марку,ks вашей прямой ссылки в различных разделах в 360dialog Client Hub.",
  [keys.A_attentionToShowLogoAndBrandSet]:
    "следующий элемент будет отображаться только в том случае если установлены и логотип компании и название бренда.",
  [keys.A_attentionToShowIfURLProvided]:
    "следующий элемент будет отображаться только в том случае если указан URL-адрес прямой ссылки.",
  [keys.A_companyLogo]: "Логотип компании",
  [keys.A_uploadLogo]: "Загрузить логотип",
  [keys.A_profilePreview]: "Предпросмотр профиля",
  [keys.A_deeplinkURL]: "URL-адрес прямой ссылки",
  [keys.A_logoChangeSuccess]: "Логотип успешно изменен",
  [keys.A_deeplinkURLToolTipContent]:
    "Если это значение установлено, пользователи смогут нажать кнопку после создания своего ключа API, что вернет их в ваше приложение, где они смогут поместить ключ API.",
  [keys.A_logoAndBrandNameVariantOne]: "Вариант 1: Форма миграции",
  [keys.A_logoAndBrandNameVariantTwo]: "Вариант 2: Логотип и название компании",
  [keys.A_featured]: "Избранное",
  [keys.A_verifyHuman]: "Подтвердите, что вы человек",
  [keys.A_insightsAndFunds]: "Информация и баланс",
  [keys.A_letsDoIt]: "Вперед!",
  [keys.A_paymentPlan]: "Выберите свой тарифный план",
  [keys.A_newNumberPlan]: "Выберите тарифный план для нового номера",
  [keys.A_betterSla]: "Улучшенный уровень обслуживания",
  [keys.A_higherScalability]: "Более высокая масштабируемость",
  [keys.A_verifyBusiness]: "Подтверите свой бизнес!",
  [keys.A_verifyFBM]:
    "Перейдите в Meta Business Manager, чтобы начать процесс подтверждения бизнеса.",
  [keys.A_accessCompanyFb]:
    "Чтобы продолжить, вам потребуется <BOLD>получить доступ к бизнес-аккаунту вашей компании на Meta.<BOLD>",
  [keys.A_continueWithFbButton]:
    "Нажав кнопку «Продолжить с Meta» ниже, вы сможете:",
  [keys.A_addNumbers]: "Добавить номера",
  [keys.A_addNumber]: "Добавить номер",
  [keys.A_addFirstNumber]:
    "Вы еще не добавили ни одного номера телефона. Добавьте свой первый номер, чтобы начать работу с WhatsApp Business Platform.",
  [keys.A_termsAndConditions]: "Условия",
  [keys.A_paymentDetails]: "Детали оплаты",
  [keys.A_facebookSetup]: "Настройка Meta",
  [keys.A_termsAndPriceList1]:
    "Я согласен с <LINK>https://www.360dialog.com/contact#privacy-policy | 360dialog Условия оказания услуг<LINK> и с",
  [keys.A_termsAndPriceList2]:
    "<LINK>https://www.360dialog.com/whatsapp-business-api?origin=new_hub#pricing | 360dialog Тарифные планы<LINK>.",
  [keys.A_user]: "Пользователь",
  [keys.A_insightsPageTitle]: "360dialog - Информация и баланс",
  [keys.A_insightsPageDescription]: "Посмотреть информацию и баланс",
  [keys.A_supportPageTitle]: "360dialog - Поддержка",
  [keys.A_supportPageDescription]: "Поддержка и контактная информация",
  [keys.A_detailsPageTitle]: "360dialog - Информация о Каналах",
  [keys.A_detailsPageDescription]: "Посмотреть информацию о канале",
  [keys.A_changeDisplayName]: "Изменить отображаемое имя",
  [keys.A_pleaseContinueRegistration]:
    "Пожалуйста, продолжите регистрацию вашего номера",
  [keys.A_continueRegistration]: "Продолжить регистрацию",
  [keys.A_continueVerification]: "Продолжить подтверждение",
  [keys.A_numbers]: "Номера",
  [keys.A_inappropriatePassword]:
    "Невозможно создать пользователя. Пароль содержит информацию о пользователе",
  [keys.A_businessVerifiedButton]: "Я уже подтвердил свой бизнес",
  [keys.A_businessNotVerifiedButton]: "Продолжить без подтверждения",
  [keys.A_chatSupportEnabled]: "Чат Поддержки подключен",
  [keys.A_acceptAndClose]: "Принять и закрыть",
  [keys.A_fullInfoDetails]: "Полная информация доступна по {{hereLink}}",
  [keys.A_here]: "ссылке",
  [keys.A_subProcessorPopupTitle]:
    "Информация о Добавлении новых субпроцессоров",
  [keys.A_thatsAllNotifications]:
    "Это все ваши уведомления за последние 30 дней.",
  [keys.A_errorPaymentToken]: "Ошибка при получении платежного токена",
  [keys.A_maintenance]:
    "Мы проводим техническое обслуживание и скоро вернемся, приносим извинения за неудобства. Чтобы узнать больше, посетите наш <LINK>https://status.360dialog.com | страница состояния <LINK>",
  [keys.A_userMaintenance]: "Обслуживание пользователей",
  [keys.A_actions]: "Действия",
  [keys.A_signals]: "Сигналы",
  [keys.A_manage]: "Управлять",
  [keys.A_qualityRating]: "Рейтинг качества",
  [keys.A_variantIOTitle]: "Вариант 2: Интегрированный онбординг",
  [keys.A_allowClientsAddNumber]: "Позволить клиентам добавлять номера",
  [keys.A_allowClientsAddNumberTooltip]:
    "Ограничивает или разрешает вашим клиентам добавлять номера телефонов. Это относится только к добавлению номеров через клиентский хаб 360dialog. Не применяется для интегрированного онбординга",
  [keys.A_allow]: "Разрешить",
  [keys.A_restrict]: "Ограничить",
  [keys.A_restrictClientMessage]:
    "Вы хотите запретить своим клиентам самостоятельно добавлять номера телефонов в 360dialog Client Hub?",
  [keys.A_allowClientMessage]:
    "Вы хотите позволить своим клиентам самостоятельно добавлять номера телефонов в 360dialog Client Hub?",
  //  [keys.A_updatedAllowedMessage]: "translation_missing",
  //  [keys.A_updatedRestrictMessage]: "translation_missing",
  //  [keys.A_infoDetailsPriceChange]: "translation_missing",
  //  [keys.A_priceChangePopupTitle]: "translation_missing",
  //  [keys.A_selectEventType]: "translation_missing",
  //  [keys.A_whereWasEvent]: "translation_missing",
  //  [keys.A_eventWithinConversation]: "translation_missing",
  //  [keys.A_eventOutsideConversation]: "translation_missing",
  //  [keys.A_messagingEvent]: "translation_missing",
  //  [keys.A_externalEvent]: "translation_missing",
  //  [keys.A_enterNumber]: "translation_missing",
  //  [keys.A_yourNumber]: "translation_missing",
  //  [keys.A_iHaveNumber]: "translation_missing",
  //  [keys.A_iDontHaveNumber]: "translation_missing",
  //  [keys.A_selectDoYouHaveNumber]: "translation_missing",
  //  [keys.A_doYouHaveNumber]: "translation_missing",
  //  [keys.A_youNeedNumberToFinalizeJourney]: "translation_missing",
  //  [keys.A_createNumber]: "translation_missing",
  //  [keys.A_basicSubPlan]: "translation_missing",
  //  [keys.A_regularSubPlan]: "translation_missing",
  //  [keys.A_premiumSubPlan]: "translation_missing",
  //  [keys.A_cloudAPIHosting]: "translation_missing",
  //  [keys.A_HostingService]: "translation_missing",
  //  [keys.A_TicketBasedSupport]: "translation_missing",
  //  [keys.A_OnPremiseHosting]: "translation_missing",
  //  [keys.A_RTChat]: "translation_missing",
  //  [keys.A_WABAReq]: "translation_missing",
  //  [keys.A_AdsFunnelTracking]: "translation_missing",
  //  [keys.A_recommended]: "translation_missing",
  //  [keys.A_choosePlan]: "translation_missing",
  //  [keys.A_SelectPlan]: "translation_missing",
  //  [keys.A_includes]: "translation_missing",
  //  [keys.A_prevTierPlus]: "translation_missing",
  //  [keys.A_continueWithSelectedPlan]: "translation_missing",
  //  [keys.A_activityPageDescription]: "translation_missing",
  //  [keys.A_activityPageTitle]: "translation_missing",
  //  [keys.A_activity]: "translation_missing",
  //  [keys.A_noActivityFound]: "translation_missing",
  //  [keys.A_noActivitySeen]: "translation_missing",
  //  [keys.A_allChannels]: "translation_missing",
  //  [keys.A_addNumberToSeeActivity]: "translation_missing",
  //  [keys.A_addNumberOverview]: "translation_missing",
  //  [keys.A_insights]: "translation_missing",
  //  [keys.A_expenseTooltip]: "translation_missing",
  //  [keys.A_downloadContacts]: "translation_missing",
  //  [keys.A_downloadContactsList1]: "translation_missing",
  //  [keys.A_downloadContactsList2]: "translation_missing",
  //  [keys.A_couldYouTellUsMore]: "translation_missing",
  //  [keys.A_downloadCsv]: "translation_missing",
  //  [keys.A_nameTheSource]: "translation_missing",
  //  [keys.A_nameTheSourceDescription]: "translation_missing",
  //  [keys.A_sourceName]: "translation_missing",
  //  [keys.A_message]: "translation_missing",
  //  [keys.A_saveSource]: "translation_missing",
  //  [keys.A_inviteMarketer]: "translation_missing",
  //  [keys.A_inviteMarketer1]: "translation_missing",
  //  [keys.A_coworkerEmail]: "translation_missing",
  //  [keys.A_invite]: "translation_missing",
  //  [keys.A_anInviteWasSent]: "translation_missing",
  //  [keys.A_messageDetails]: "translation_missing",
  //  [keys.A_checkSelectedMessages]: "translation_missing",
  //  [keys.A_sourceInvestment]: "translation_missing",
  //  [keys.A_investmentMade]: "translation_missing",
  //  [keys.A_amountCurrency]: "translation_missing",
  //  [keys.A_investedAmount]: "translation_missing",
  //  [keys.A_saveInvestment]: "translation_missing",
  //  [keys.A_requestPartnerAccess]: "translation_missing",
  //  [keys.A_trackEvent]: "translation_missing",
  //  [keys.A_selectPoolConversations]: "translation_missing",
  //  [keys.A_billingPageTitle]: "translation_missing",
  //  [keys.A_billingPageDescription]: "translation_missing",
  //  [keys.A_coworkerName]: "translation_missing",
  //  [keys.A_sentTo]: "translation_missing",
  //  [keys.A_viewConversationsInsights]: "translation_missing",
  //  [keys.A_insightsPageTitle]: "translation_missing",
  //  [keys.A_insightsPageDescription]: "translation_missing",
  //  [keys.A_measurePerformance]: "translation_missing",
  //  [keys.A_selectEventType]: "translation_missing",
  //  [keys.A_whereWasEvent]: "translation_missing",
  //  [keys.A_eventWithinConversation]: "translation_missing",
  //  [keys.A_eventOutsideConversation]: "translation_missing",
  //  [keys.A_messagingEvent]: "translation_missing",
  //  [keys.A_externalEvent]: "translation_missing",
  //  [keys.A_changeEventType]: "translation_missing",
  //  [keys.A_adAccountsPageTitle]: "translation_missing",
  //  [keys.A_adAccountsPAgeDescription]: "translation_missing",
  //  [keys.A_permissions]: "translation_missing",
  //  [keys.A_preferences]: "translation_missing",
  //  [keys.A_rename]: "translation_missing",
  //  [keys.A_options]: "translation_missing",
  //  [keys.A_adminHeaderInfo]: "translation_missing",
  //  [keys.A_Integrations]: "translation_missing",
  //  [keys.A_integrationsPageTitle]: "translation_missing",
};

export default translate;
