// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.LNG_af]: "Африкаанс",
  [keys.LNG_sq]: "Албанский",
  [keys.LNG_ar]: "Арабский",
  [keys.LNG_az]: "Азербайджанский",
  [keys.LNG_bn]: "Бенгальский",
  [keys.LNG_bg]: "Болгарский",
  [keys.LNG_ca]: "Каталанский",
  [keys.LNG_zh_CN]: "Китайский (CHN)",
  [keys.LNG_zh_HK]: "Китайский (HKG)",
  [keys.LNG_zh_TW]: "Китайский (TAI)",
  [keys.LNG_hr]: "Хорватский",
  [keys.LNG_cs]: "Чешский",
  [keys.LNG_da]: "Датский",
  [keys.LNG_nl]: "Нидерландский",
  [keys.LNG_en]: "Английский",
  [keys.LNG_en_GB]: "Английский (UK)",
  [keys.LNG_en_US]: "Английский (US)",
  [keys.LNG_et]: "Эстонский",
  [keys.LNG_fil]: "Филиппинский",
  [keys.LNG_fi]: "Финский",
  [keys.LNG_fr]: "Французский",
  [keys.LNG_de]: "Немецкий",
  [keys.LNG_el]: "Греческий",
  [keys.LNG_gu]: "Гуджарати",
  [keys.LNG_ha]: "Хауса",
  [keys.LNG_he]: "Иврит",
  [keys.LNG_hi]: "Хинди",
  [keys.LNG_hu]: "Венгерский",
  [keys.LNG_id]: "Индонезийский",
  [keys.LNG_ga]: "Ирландский",
  [keys.LNG_it]: "Итальянский",
  [keys.LNG_ja]: "Японский",
  [keys.LNG_kn]: "Каннада",
  [keys.LNG_kk]: "Казахский",
  [keys.LNG_ko]: "Корейский",
  [keys.LNG_lo]: "Лаосский",
  [keys.LNG_lv]: "Латышский",
  [keys.LNG_lt]: "Литовский",
  [keys.LNG_mk]: "Македонский",
  [keys.LNG_ms]: "Малайский",
  [keys.LNG_ml]: "Малаялам",
  [keys.LNG_mr]: "Маратхи",
  [keys.LNG_nb]: "Норвежский",
  [keys.LNG_fa]: "Персидский",
  [keys.LNG_pl]: "Польский",
  [keys.LNG_pt_BR]: "Португальский (BR)",
  [keys.LNG_pt_PT]: "Португальский (POR)",
  [keys.LNG_pa]: "Пенджаби",
  [keys.LNG_ro]: "Румынский",
  [keys.LNG_ru]: "Русский",
  [keys.LNG_sr]: "Сербский",
  [keys.LNG_sk]: "Словацкий",
  [keys.LNG_sl]: "Словенский",
  [keys.LNG_es]: "Испанский",
  [keys.LNG_es_AR]: "Испанский (ARG)",
  [keys.LNG_es_ES]: "Испанский (СПА)",
  [keys.LNG_es_MX]: "Испанский (MEX)",
  [keys.LNG_sw]: "Суахили",
  [keys.LNG_sv]: "Шведский",
  [keys.LNG_ta]: "Тамильский",
  [keys.LNG_te]: "Телугу",
  [keys.LNG_th]: "Тайский",
  [keys.LNG_tr]: "Турецкий",
  [keys.LNG_uk]: "Украинский",
  [keys.LNG_ur]: "Урду",
  [keys.LNG_uz]: "Узбекский",
  [keys.LNG_vi]: "Вьетнамский",
  [keys.LNG_zu]: "Зулу",
};

export default translate;
