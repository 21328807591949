import * as React from "react";
const Logo360_D = (props) => (
  <svg
    width={22}
    height={28}
    viewBox="0 0 31.8467 37.1369"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <desc>Created with Pixso.</desc>
    <defs />
    <path
      id="Vector"
      d="M12.12 0C16.08 0 19.53 0.76 22.49 2.29C25.48 3.79 27.78 5.96 29.38 8.81C31.02 11.63 31.84 14.92 31.84 18.7C31.84 22.47 31.02 25.75 29.38 28.53C27.78 31.31 25.48 33.44 22.49 34.94C19.53 36.4 16.08 37.13 12.12 37.13L0 37.13L0 0L12.12 0ZM12.12 32.16C16.47 32.16 19.8 30.99 22.12 28.64C24.43 26.28 25.59 22.97 25.59 18.7C25.59 14.39 24.43 11.02 22.12 8.6C19.8 6.18 16.47 4.96 12.12 4.96L6.09 4.96L6.09 32.16L12.12 32.16Z"
      fill="#fff"
      fillOpacity="1.000000"
      fillRule="nonzero"
    />
  </svg>
);
export default Logo360_D;
