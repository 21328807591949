import { UIModelChannel } from "@/services/api/channels/channels.model";

export type partnersStatus = "created" | "active" | "approved" | "pending";
export type wabaAccountStatus =
  | "created"
  | "unverified"
  | "pending"
  | "approved"
  | "obo_not_approved"
  | "pending_review"
  | "consents_pending"
  | "verification_failed"
  | "error"
  | "account_restriction"
  | "account_violation"
  | "bsp_removed"
  | "banned";
export type wabaAccountFbAccountStatus =
  | "unknown"
  | "verified"
  | "not_verified"
  | "pending_need_more_info"
  | "rejected"
  | "pending"
  | "pending_submission"
  | "failed";
export type wabaAccountOnBehalfOfBusinessStatus =
  | "submitted"
  | "approved"
  | "rejected"
  | "pending"
  | "denied";
export type channelStatus =
  | "created"
  | "unverified"
  | "verified"
  | "ready"
  | "certificate_declined"
  | "consents_signed"
  | "error"
  | "ready_for_migration"
  | "waiting_for_migration_code"
  | "new_name_requested"
  | "migration_code_requested" //or rejected
  | "unregistered";
export type integrationStatus =
  | "created"
  | "setting_up"
  | "registration_ready"
  | "registration_triggered"
  | "ready_for_finalize"
  | "finalizing"
  | "running";

export type statusIndicatorsStatuses =
  | "done"
  | "pending"
  | "rejected"
  | "draft"
  | "other"
  | "display name in review"
  | "display name rejected"
  | "unregistered"
  | "BSP permission removed";
export type templateStatuses =
  | "approved"
  | "submitted"
  | "accepted"
  | "pending"
  | "rejected"
  | "created"
  | "flagged";

export type compassStatuses = "success" | "pending" | "error" | "neutral";

export type UIAccountModes = "sandbox" | "live" | "N/A";

export const mapChannelAccountMode: {
  [k: string]: UIAccountModes;
} = {
  SANDBOX: "sandbox",
  LIVE: "live",
  na: "N/A",
  sandbox: "sandbox",
  live: "live",
};

export const getStatusFromChannel = (channel: UIModelChannel) => {
  if (!channel) {
    return "draft";
  }

  if (
    channel.integration?.hasOwnProperty("enabled") &&
    channel.integration?.enabled === false
  ) {
    return "disabled";
  } else if (channel.version === 1) {
    if (channel.integration) {
      if (channel.integration.state === "finalizing") {
        return "draft";
      } else if (channel.status === "unregistered") {
        return "unregistered";
      } else {
        return mapToCommongStatusIntegration[channel.integration.state];
      }
    } else if (channel.status === "unregistered") {
      return "unregistered";
    } else {
      return "draft";
    }
  } else {
    if (channel.status === "unregistered") {
      return "unregistered";
    } else if (channel.accountMode !== "" && channel.integration) {
      return mapChannelAccountMode[channel.accountMode];
    } else if (channel.status === "ready") {
      return "live";
    } else {
      return "draft";
    }
  }
};

export const mapToCommonStatusPartner: {
  [k in partnersStatus]: compassStatuses;
} = {
  created: "neutral",
  active: "success",
  approved: "success",
  pending: "pending",
};

export const mapToCommonStatusWabaAccount: {
  [k: string]: statusIndicatorsStatuses;
} = {
  created: "draft",
  unverified: "pending",
  pending_review: "pending",
  pending: "pending",
  approved: "done", // done
  obo_not_approved: "rejected",
  consents_pending: "pending",
  verification_failed: "rejected",
  error: "rejected",
  bsp_removed: "rejected",
  banned: "rejected",
};

export const mapToLabelStatusFbAccount: {
  [k in wabaAccountFbAccountStatus]: string;
} = {
  unknown: "unknown",
  verified: "verified",
  not_verified: "not verified",
  pending_need_more_info: "pending - need more info",
  rejected: "rejected",
  pending: "pending",
  pending_submission: "pending submission",
  failed: "failed",
};

export const mapToCommonStatusFbAccount: {
  [k: string]: statusIndicatorsStatuses;
} = {
  unknown: "draft",
  verified: "done",
  not_verified: "draft",
  pending_need_more_info: "pending",
  rejected: "rejected",
  failed: "rejected",
  pending: "pending",
  pending_submission: "pending",
};

export const mapToCommonStatusOnBehalf: {
  [k: string]: statusIndicatorsStatuses;
} = {
  submitted: "pending",
  approved: "done",
  rejected: "rejected",
  pending: "pending",
  denied: "rejected",
};

export const mapToCommonStatusWabaChannel: {
  [k: string]: statusIndicatorsStatuses;
} = {
  created: "draft",
  unverified: "display name in review",
  missing_consents: "pending",
  consents_signed: "pending",
  certificate_declined: "display name rejected",
  verified: "done",
  ready: "done",
  permission_granted: "done",
  unregistered: "unregistered",
};

export const mapToLabelStatusIntegration: {
  [k in integrationStatus]: string;
} = {
  created: "created",
  setting_up: "setting up",
  registration_ready: "registration_ready",
  registration_triggered: "registration triggered",
  ready_for_finalize: "ready for finalize",
  finalizing: "finalizing",
  running: "running",
};

export const mapToCommongStatusIntegration: {
  [k: string]: statusIndicatorsStatuses;
} = {
  created: "draft",
  setting_up: "draft",
  running: "done",
  registration_ready: "pending",
  registration_triggered: "pending",
  ready_for_finalize: "pending",
  finalizing: "pending",
  pending_review: "pending",
  ready: "done",
  unregistered: "unregistered",
};

export const mapToLabelStatusWabaAccount = (status: string) => {
  switch (status) {
    case "obo_not_approved": {
      return "failed commerce policy";
    }
    case "pending_review": {
      return "pending review";
    }
    case "consents_pending": {
      return "consents pending";
    }
    case "verification_failed": {
      return "business verification failed";
    }
    case "ready_for_migration": {
      return "ready for migration";
    }
    case "migration_code_requested": {
      return "migration code requested";
    }
    case "bsp_removed": {
      return "BSP permission removed";
    }
    default: {
      return status;
    }
  }
};

export const mapToLabelDisplayNameStatus = (status: string) => {
  switch (status) {
    case "unverified": {
      return "display name in review";
    }
    case "certificate_declined": {
      return "display name rejected";
    }
    case "consents_signed": {
      return "consents signed";
    }
    case "consents_pending": {
      return "consents pending";
    }
    case "ready_for_migration": {
      return "ready for migration";
    }
    case "waiting_for_migration_code": {
      return "ready for migration code";
    }
    case "new_name_requested": {
      return "new display name requested";
    }
    case "migration_code_requested": {
      return "migration code requested";
    }
    case "permission_granted": {
      return "done";
    }
    default: {
      return status;
    }
  }
};
