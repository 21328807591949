// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  CH_showDetailed: "CH_showDetailed",
  CH_hideDetailed: "CH_hideDetailed",
  CH_templatesButton: "CH_templatesButton",
  CH_showDetailsButton: "CH_showDetailsButton",
  CH_mobButton: "CH_mobButton",
  CH_triggerButton: "CH_triggerButton",
  CH_finishButton: "CH_finishButton",
  CH_keyButton: "CH_keyButton",
  CH_addNumber: "CH_addNumber",
  CH_exportClientData: "CH_exportClientData",
  CH_exporting: "CH_exporting",
  CH_filterPlaceholder: "CH_filterPlaceholder",
  CH_details: "CH_details",
  CH_templates: "CH_templates",
  CH_billingInfo: "CH_billingInfo",
  CH_billingStartDate: "CH_billingStartDate",
  CH_billingStartButtonLabel: "CH_billingStartButtonLabel",
  CH_numberReg: "CH_numberReg",
  CH_pin: "CH_pin",
  CH_defaultLang: "CH_defaultLang",
  CH_ivrEx: "CH_ivrEx",
  CH_ivrAct: "CH_ivrAct",
  CH_verificationMethod: "CH_verificationMethod",
  CH_voice: "CH_voice",
  CH_sms: "CH_sms",
  CH_cert: "CH_cert",
  CH_consent: "CH_consent",
  CH_consentPage: "CH_consentPage",
  CH_displayNameErrorEmoji: "CH_displayNameErrorEmoji",
  CH_displayNameErrorIntro: "CH_displayNameErrorIntro",
  CH_displayNameErrorWhatsApp: "CH_displayNameErrorWhatsApp",
  CH_displayNameErrorUrl: "CH_displayNameErrorUrl",
  CH_displayNameErrorPunct: "CH_displayNameErrorPunct",
  CH_displayNameErrorCap: "CH_displayNameErrorCap",
  CH_displayNameErrorMin: "CH_displayNameErrorMin",
  CH_whatsAppDisplayName: "CH_whatsAppDisplayName",
  CH_confirmDisplayNameChange1: "CH_confirmDisplayNameChange1",
  CH_confirmDisplayNameChange2: "CH_confirmDisplayNameChange2",
  CH_confirmDisplayNameChangeTooltip: "CH_confirmDisplayNameChangeTooltip",
  CH_confirmDisplayNameChange3: "CH_confirmDisplayNameChange3",
  CH_confirmDisplayNameChange4: "CH_confirmDisplayNameChange4",
  CH_numberReadyToRegister: "CH_numberReadyToRegister",
  CH_pending: "CH_pending",
  CH_wabaChannelExtId: "CH_wabaChannelExtId",
  CH_wabaAccountName: "CH_wabaAccountName",
  CH_mobName: "CH_mobName",
  CH_timezone: "CH_timezone",
  CH_wabaAccountId: "CH_wabaAccountId",
  CH_namespace: "CH_namespace",
  CH_dangerZone: "CH_dangerZone",
  CH_deleteNumber: "CH_deleteNumber",
  CH_deleteNumberText: "CH_deleteNumberText",
  CH_revoke: "CH_revoke",
  CH_revokeText: "CH_revokeText",
  CH_requestRevocationButton: "CH_requestRevocationButton",
  CH_deleteSuccess: "CH_deleteSuccess",
  CH_deletePopupText: "CH_deletePopupText",
  CH_confirmationFieldInputValue: "CH_confirmationFieldInputValue",
  CH_deletePopup1: "CH_deletePopup1",
  CH_detailsTab: "CH_detailsTab",
  CH_templatesTab: "CH_templatesTab",
  CH_wabaProfileTab: "CH_wabaProfileTab",
  CH_profileTab: "CH_profileTab",
  CH_integrationTab: "CH_integrationTab",
  CH_commentsTab: "CH_commentsTab",
  CH_listHeaderTitle: "CH_listHeaderTitle",
  CH_manageKeys: "CH_manageKeys",
  CH_manageKeysHelptext: "CH_manageKeysHelptext",
  CH_addNumberTitle: "CH_addNumberTitle",
  CH_addNumberHelptext: "CH_addNumberHelptext",
  CH_addNumberSelectHelptext: "CH_addNumberSelectHelptext",
  CH_addNumberSuccess: "CH_addNumberSuccess",
  CH_addNumberEmbeddedTitle: "CH_addNumberEmbeddedTitle",
  CH_addNumberEmbeddedHelptext: "CH_addNumberEmbeddedHelptext",
  CH_addNumberSelectError: "CH_addNumberSelectError",
  CH_pendingDeletion: "CH_pendingDeletion",
  CH_pendingDeletionHelptext: "CH_pendingDeletionHelptext",
  CH_reEnableButton: "CH_reEnableButton",
  CH_revokeNumberDeletionSuccess: "CH_revokeNumberDeletionSuccess",
  CH_emptyStateTitle: "CH_emptyStateTitle",
  CH_emptyStateHelptext: "CH_emptyStateHelptext",
  CH_emptyStateHelptextIO: "CH_emptyStateHelptextIO",
  CH_openSubmissionForm: "CH_openSubmissionForm",
  CH_openIODoc: "CH_openIODoc",
  CH_copyLink: "CH_copyLink",
  CH_copiedLink: "CH_copiedLink",
  CH_emptyStateTitleClient: "CH_emptyStateTitleClient",
  CH_emptyStateHelptextClient: "CH_emptyStateHelptextClient",
  CH_emptyStateButtonLabelClient: "CH_emptyStateButtonLabelClient",
  CH_noChannelsFound: "CH_noChannelsFound",
  CH_setupProgress: "CH_setupProgress",
  CH_showMore: "CH_showMore",
  CH_showLess: "CH_showLess",
  CH_percentageDone: "CH_percentageDone",
  CH_actionRequired: "CH_actionRequired",
  CH_noActionRequired: "CH_noActionRequired",
  CH_setupNumberRunning: "CH_setupNumberRunning",
  CH_setupVerificationStarted: "CH_setupVerificationStarted",
  CH_setupVerificationCompleted: "CH_setupVerificationCompleted",
  CH_setupDisplayNameApproved: "CH_setupDisplayNameApproved",
  CH_setupCommercePolicyChecked: "CH_setupCommercePolicyChecked",
  CH_ctaBusinessRejected: "CH_ctaBusinessRejected",
  CH_ctaNumberSetup: "CH_ctaNumberSetup",
  CH_ctaStartVerification: "CH_ctaStartVerification",
  CH_ctaVerificationPending: "CH_ctaVerificationPending",
  CH_ctaDisplayNameRejected: "CH_ctaDisplayNameRejected",
  CH_ctaButtonLabelOpenWaManager: "CH_ctaButtonLabelOpenWaManager",
  CH_ctaLiveMode: "CH_ctaLiveMode",
  CH_ctaButtonLabelFbm: "CH_ctaButtonLabelFbm",
  CH_ctaButtonLabelStartVerification: "CH_ctaButtonLabelStartVerification",
  CH_ctaButtonLabelOpenSettings: "CH_ctaButtonLabelOpenSettings",
  CH_ctaButtonLabelAppeal: "CH_ctaButtonLabelAppeal",
  CH_ctaCommercePolicyFailed: "CH_ctaCommercePolicyFailed",
  CH_ctaPendingDeletion: "CH_ctaPendingDeletion",
  CH_tooltipSandbox: "CH_tooltipSandbox",
  CH_wabaPhotoChanged: "CH_wabaPhotoChanged",
  CH_wabaProfile: "CH_wabaProfile",
  CH_businessProfile: "CH_businessProfile",
  CH_changeProfilePic: "CH_changeProfilePic",
  CH_profilePic: "CH_profilePic",
  CH_urlErrorMessage: "CH_urlErrorMessage",
  CH_portNumberButtonLabel: "CH_portNumberButtonLabel",
  CH_portNumberText: "CH_portNumberText",
  CH_createNewWaba: "CH_createNewWaba",
  CH_phoneNumberAlreadyExists: "CH_phoneNumberAlreadyExists",
  CH_migrateNumberNextStepText: "CH_migrateNumberNextStepText",
  CH_readyForMigrationCtaText: "CH_readyForMigrationCtaText",
  CH_waitingForMigrationCtaText: "CH_waitingForMigrationCtaText",
  CH_invalidBusinessId: "CH_invalidBusinessId",
  CH_migratedNumberLabel: "CH_migratedNumberLabel",
  CH_type: "CH_type",
  CH_retrySetupText: "CH_retrySetupText",
  CH_setTerminationDate: "CH_setTerminationDate",
  CH_successChannelAdded: "CH_successChannelAdded",
  CH_addBusinessInfo: "CH_addBusinessInfo",
  CH_businessInfoPrompt: "CH_businessInfoPrompt",
  CH_startBusinessVerification: "CH_startBusinessVerification",
  CH_deletedChannel: "CH_deletedChannel",
  CH_deletedChannelHelptextShort: "CH_deletedChannelHelptextShort",
  CH_deletedChannelHelptext: "CH_deletedChannelHelptext",
  CH_reEnableHelptext: "CH_reEnableHelptext",
  CH_partnerFallback: "CH_partnerFallback",
  CH_recentSearches: "CH_recentSearches",
  CH_applyFilter: "CH_applyFilter",
  CH_sortByDate: "CH_sortByDate",
  CH_searchBy: "CH_searchBy",
  CH_filters: "CH_filters",
  CH_filterSingular: "CH_filterSingular",
  CH_variableCountError: "CH_variableCountError",
  CH_phoneNumber: "CH_phoneNumber",
  CH_clientEmail: "CH_clientEmail",
  CH_submissionType: "CH_submissionType",
  CH_portedNumber: "CH_portedNumber",
  CH_channelStatus: "CH_channelStatus",
  CH_pendingCancellation: "CH_pendingCancellation",
  CH_accountMode: "CH_accountMode",
  CH_errorNotEverythingLoaded: "CH_errorNotEverythingLoaded",
  CH_buttonLabelGotToSupport: "CH_buttonLabelGotToSupport",
  CH_emptyStateOption: "CH_emptyStateOption",
  CH_billingStatus: "CH_billingStatus",
  CH_cancelledOn: "CH_cancelledOn",
  CH_terminatedOn: "CH_terminatedOn",
  CH_startedOn: "CH_startedOn",
  CH_notStarted: "CH_notStarted",
  CH_compressedListView: "CH_compressedListView",
  CH_externalId: "CH_externalId",
  CH_status: "CH_status",
  CH_deleteNumberTextTerms: "CH_deleteNumberTextTerms",
  CH_confirmDeleteNumberFBManagerText1: "CH_confirmDeleteNumberFBManagerText1",
  CH_confirmDeleteNumberFBManagerText2: "CH_confirmDeleteNumberFBManagerText2",
  CH_confirmDeleteNumberFBManagerText3: "CH_confirmDeleteNumberFBManagerText3",
  CH_confirmDeleteNumberFBManager: "CH_confirmDeleteNumberFBManager",
  CH_deleteNumberSuccess: "CH_deleteNumberSuccess",
  CH_messagingLimit: "CH_messagingLimit",
  CH_qualityRating: "CH_qualityRating",
  CH_learnHowDelete: "CH_learnHowDelete",
  CH_emailErrorMessage: "CH_emailErrorMessage",
  CH_partnerButtonDisabled: "CH_partnerButtonDisabled",
  CH_usageAndBalance: "CH_usageAndBalance",
  CH_prepaidSettings: "CH_prepaidSettings",
  CH_lastRenewal: "CH_lastRenewal",
  CH_usage: "CH_usage",
  CH_paymentNotEnabled: "CH_paymentNotEnabled",
  CH_hasInboxTooltip1: "CH_hasInboxTooltip1",
  CH_hasInboxTooltip2: "CH_hasInboxTooltip2",
  CH_hasInboxActionBlock1: "CH_hasInboxActionBlock1",
  CH_hasInboxActionBlock2: "CH_hasInboxActionBlock2",
  CH_disconnectInboxButtonLabel: "CH_disconnectInboxButtonLabel",
  CH_disconnectNotificationSuccess: "CH_disconnectNotificationSuccess",
  CH_disconnectNotificationError: "CH_disconnectNotificationError",
  CH_openConsentPage: "CH_openConsentPage",
  CH_close: "CH_close",
  CH_accountViolation: "CH_accountViolation",
  CH_businessManagerDetails: "CH_businessManagerDetails",
  CH_accountRestriction: "CH_accountRestriction",
  CH_noInformationAvailable: "CH_noInformationAvailable",
  CH_restrictionText: "CH_restrictionText",
  CH_restrictionTextBusiness: "CH_restrictionTextBusiness",
  CH_restrictionTextUser: "CH_restrictionTextUser",
  CH_restrictionTextNumber: "CH_restrictionTextNumber",
  CH_clientName: "CH_clientName",
  CH_premiumPlanPricing: "CH_premiumPlanPricing",
  CH_standardPlanPricing: "CH_standardPlanPricing",
  CH_upgrade: "CH_upgrade",
  CH_downgrade: "CH_downgrade",
  CH_stantard: "CH_stantard",
  CH_premium: "CH_premium",
  CH_month: "CH_month",
  CH_billedMonthly: "CH_billedMonthly",
  CH_unlimitedIncomingMsg: "CH_unlimitedIncomingMsg",
  CH_noMarkup: "CH_noMarkup",
  CH_98serviceLevel: "CH_98serviceLevel",
  CH_99serviceLevel: "CH_99serviceLevel",
  CH_premiumSupport: "CH_premiumSupport",
  CH_standardSupport: "CH_standardSupport",
  CH_premiumSLA: "CH_premiumSLA",
  CH_standardSLA: "CH_standardSLA",
  CH_costBreakdown: "CH_costBreakdown",
  CH_description: "CH_description",
  CH_qty: "CH_qty",
  CH_amount: "CH_amount",
  CH_estimatedCost: "CH_estimatedCost",
  CH_unitPrice: "CH_unitPrice",
  CH_standardPricing: "CH_standardPricing",
  CH_premiumPricing: "CH_premiumPricing",
  CH_premiumAccount: "CH_premiumAccount",
  CH_lastDowngradeTooltip1: "CH_lastDowngradeTooltip1",
  CH_lastDowngradeTooltip2: "CH_lastDowngradeTooltip2",
  CH_lastUpgradeTooltip1: "CH_lastUpgradeTooltip1",
  CH_lastUpgradeTooltip2: "CH_lastUpgradeTooltip2",
  CH_reRegisterNumber: "CH_reRegisterNumber",
  CH_reRegisterNumberText: "CH_reRegisterNumberText",
  CH_saveComment: "CH_saveComment",
  CH_activityLogs: "CH_activityLogs",
  CH_admin: "CH_admin",
  CH_dateAndTime: "CH_dateAndTime",
  CH_actor: "CH_actor",
  CH_action: "CH_action",
  CH_businessAccount: "CH_businessAccount",
  CH_profilePreview: "CH_profilePreview",
  CH_noLogsYet: "CH_noLogsYet",
  CH_noLogsYetDescription: "CH_noLogsYetDescription",
  CH_useFilteredData: "CH_useFilteredData",
  CH_selectDataset: "CH_selectDataset",
  CH_filteredExportTitle: "CH_filteredExportTitle",
  CH_filteredExport: "CH_filteredExport",
  CH_fullExportTitle: "CH_fullExportTitle",
  CH_fullExport: "CH_fullExport",
  CH_contactSupport: "CH_contactSupport",
  CH_contactSupportText: "CH_contactSupportText",
  CH_proceedWithRegistration: "CH_proceedWithRegistration",
  CH_readyToRegister: "CH_readyToRegister",
  CH_addNumberDisabledTwoNumbers: "CH_addNumberDisabledTwoNumbers",
  CH_addNumberDisabledTwoNumbers2: "CH_addNumberDisabledTwoNumbers2",
  CH_addNumberLimitExceeded: "CH_addNumberLimitExceeded",
  CH_lockedTitle: "CH_lockedTitle",
  CH_lockedText: "CH_lockedText",
  CH_ctaPendingReview: "CH_ctaPendingReview",
  CH_apiKeyDisabledMessage: "CH_apiKeyDisabledMessage",
  CH_emptyStateHelptextClientIO: "CH_emptyStateHelptextClientIO",
  CH_successfullyAddedNumber: "CH_successfullyAddedNumber",
  CH_numberNowLive: "CH_numberNowLive",
  CH_generateApiKeyNow: "CH_generateApiKeyNow",
  CH_settingUpNumber: "CH_settingUpNumber",
  CH_accountDeletionCheckbox: "CH_accountDeletionCheckbox",
  CH_deleteAccount: "CH_deleteAccount",
  CH_dataNotAccessible: "CH_dataNotAccessible",
  CH_addNumberDisabledSandbox: "CH_addNumberDisabledSandbox",
  CH_hostingPlatformType: "CH_hostingPlatformType",
  CH_aboutTextRequired: "CH_aboutTextRequired",
  CH_synced: "CH_synced",
  CH_wabaType: "CH_wabaType",
  CH_disableTracker: "CH_disableTracker",
  CH_trackerNotificationContent: "CH_trackerNotificationContent",
  CH_trackingBannerContent: "CH_trackingBannerContent",
  CH_deleteTitle: "CH_deleteTitle",
  CH_goBack: "CH_goBack",
  CH_activateInbox: "CH_activateInbox",
  CH_verifyBusiness: "CH_verifyBusiness",
  CH_contactPartner: "CH_contactPartner",
  CH_cancelPayment: "CH_cancelPayment",
  CH_cancelPaymentInfo: "CH_cancelPaymentInfo",
  CH_pleaseLimitDescription: "CH_pleaseLimitDescription",
  CH_addNumberDisabledTwoNumbersAlt: "CH_addNumberDisabledTwoNumbersAlt",
  CH_currentPlanNotSupportChangingHostingPlatform:
    "CH_currentPlanNotSupportChangingHostingPlatform",
  CH_pleaseBeAwareMigrationBack: "CH_pleaseBeAwareMigrationBack",
  CH_signalDisabledMsg: "CH_signalDisabledMsg",
  CH_inboxDisabledMsg: "CH_inboxDisabledMsg",
  CH_partnerApiKeyPermission: "CH_partnerApiKeyPermission",
  CH_subscriptionPlan: "CH_subscriptionPlan",
  CH_changePlan: "CH_changePlan",
  CH_downgradeScheduleInfo: "CH_downgradeScheduleInfo",
  CH_upgradeScheduleInfo: "CH_upgradeScheduleInfo",
  CH_subscriptionSuccessful: "CH_subscriptionSuccessful",
  CH_selectSubscription: "CH_selectSubscription",
  CH_subscriptionSelectionInfo: "CH_subscriptionSelectionInfo",
  CH_savingPlan: "CH_savingPlan",
  CH_savePlan: "CH_savePlan",
  CH_downgradeDisabledTooltip: "CH_downgradeDisabledTooltip",
  CH_pendingClientApprovalInfo: "CH_pendingClientApprovalInfo",
  CH_verifyOwnership: "CH_verifyOwnership",
  CH_pendingClientApprovalInfoNoClientInfo:
    "CH_pendingClientApprovalInfoNoClientInfo",
  CH_viewDocumentation: "CH_viewDocumentation",
  CH_pendingClientApprovalInfoWithClientInfo:
    "CH_pendingClientApprovalInfoWithClientInfo",
  CH_clientUserApproved: "CH_clientUserApproved",
  CH_channelVerified: "CH_channelVerified",
  CH_displayName: "CH_displayName",
  CH_manage: "CH_manage",
  CH_actions: "CH_actions",
  CH_wabaWebhookUrlError: "CH_wabaWebhookUrlError",
  CH_hostingType: "CH_hostingType",
  CH_partnerPermitted: "CH_partnerPermitted",
  CH_partnerNotPermitted: "CH_partnerNotPermitted",
  CH_missing: "CH_missing",
  CH_shared: "CH_shared",
  CH_managePermissions: "CH_managePermissions",
  CH_planRecommended: "CH_planRecommended",
  CH_planBasic: "CH_planBasic",
  CH_planRegular: "CH_planRegular",
  CH_planPremium: "CH_planPremium",
  CH_planMonth: "CH_planMonth",
  CH_planIncludes: "CH_planIncludes",
  CH_planPlus: "CH_planPlus",
  CH_planCloudHosting: "CH_planCloudHosting",
  CH_planNinetyServiceLevel: "CH_planNinetyServiceLevel",
  CH_planTicketBased: "CH_planTicketBased",
  CH_planOnPremiseHosting: "CH_planOnPremiseHosting",
  CH_planWabaOfficial: "CH_planWabaOfficial",
  CH_planHighServiceLevel: "CH_planHighServiceLevel",
  CH_planIncludesAdsFunnel: "CH_planIncludesAdsFunnel",
  CH_planSelect: "CH_planSelect",
  CH_planSelected: "CH_planSelected",
  CH_active: "CH_active",
  CH_manageWaba: "CH_manageWaba",
  CH_numberDetails: "CH_numberDetails",
  CH_numberBusinessAccount: "CH_numberBusinessAccount",
  CH_funds: "CH_funds",
  CH_plan: "CH_plan",
  CH_accountName: "CH_accountName",
  CH_dataRegions: "CH_dataRegions",
  CH_numberQuality: "CH_numberQuality",
  CH_numberMessagingLimit: "CH_numberMessagingLimit",
  CH_low: "CH_low",
  CH_medium: "CH_medium",
  CH_high: "CH_high",
  CH_numberPending: "CH_numberPending",
  CH_unlimited: "CH_unlimited",
  CH_newConversationsPerDay: "CH_newConversationsPerDay",
  CH_onlyAvailableCloudNumbers: "CH_onlyAvailableCloudNumbers",
  CH_qualityRatingTooltipOne: "CH_qualityRatingTooltipOne",
  CH_qualityRatingTooltipTwo: "CH_qualityRatingTooltipTwo",
  CH_qualityRatingTooltipStatus: "CH_qualityRatingTooltipStatus",
  CH_qualityRatingTooltipPending: "CH_qualityRatingTooltipPending",
  CH_qualityRatingTooltipHigh: "CH_qualityRatingTooltipHigh",
  CH_qualityRatingTooltipMedium: "CH_qualityRatingTooltipMedium",
  CH_qualityRatingTooltipLow: "CH_qualityRatingTooltipLow",
  CH_messagingLimitTooltip: "CH_messagingLimitTooltip",
  CH_messagingLimitTooltipLimits: "CH_messagingLimitTooltipLimits",
  CH_numberQualityRating: "CH_numberQualityRating",
  CH_learnMore: "CH_learnMore",
  CH_numberMessagingLimitTitle: "CH_numberMessagingLimitTitle",
  CH_seeMore: "CH_seeMore",
};

export default keys;
