// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  LNG_af: "LNG_af",
  LNG_sq: "LNG_sq",
  LNG_ar: "LNG_ar",
  LNG_az: "LNG_az",
  LNG_bn: "LNG_bn",
  LNG_bg: "LNG_bg",
  LNG_ca: "LNG_ca",
  LNG_zh_CN: "LNG_zh_CN",
  LNG_zh_HK: "LNG_zh_HK",
  LNG_zh_TW: "LNG_zh_TW",
  LNG_hr: "LNG_hr",
  LNG_cs: "LNG_cs",
  LNG_da: "LNG_da",
  LNG_nl: "LNG_nl",
  LNG_en: "LNG_en",
  LNG_en_GB: "LNG_en_GB",
  LNG_en_US: "LNG_en_US",
  LNG_et: "LNG_et",
  LNG_fil: "LNG_fil",
  LNG_fi: "LNG_fi",
  LNG_fr: "LNG_fr",
  LNG_de: "LNG_de",
  LNG_el: "LNG_el",
  LNG_gu: "LNG_gu",
  LNG_ha: "LNG_ha",
  LNG_he: "LNG_he",
  LNG_hi: "LNG_hi",
  LNG_hu: "LNG_hu",
  LNG_id: "LNG_id",
  LNG_ga: "LNG_ga",
  LNG_it: "LNG_it",
  LNG_ja: "LNG_ja",
  LNG_kn: "LNG_kn",
  LNG_kk: "LNG_kk",
  LNG_ko: "LNG_ko",
  LNG_lo: "LNG_lo",
  LNG_lv: "LNG_lv",
  LNG_lt: "LNG_lt",
  LNG_mk: "LNG_mk",
  LNG_ms: "LNG_ms",
  LNG_ml: "LNG_ml",
  LNG_mr: "LNG_mr",
  LNG_nb: "LNG_nb",
  LNG_fa: "LNG_fa",
  LNG_pl: "LNG_pl",
  LNG_pt_BR: "LNG_pt_BR",
  LNG_pt_PT: "LNG_pt_PT",
  LNG_pa: "LNG_pa",
  LNG_ro: "LNG_ro",
  LNG_ru: "LNG_ru",
  LNG_sr: "LNG_sr",
  LNG_sk: "LNG_sk",
  LNG_sl: "LNG_sl",
  LNG_es: "LNG_es",
  LNG_es_AR: "LNG_es_AR",
  LNG_es_ES: "LNG_es_ES",
  LNG_es_MX: "LNG_es_MX",
  LNG_sw: "LNG_sw",
  LNG_sv: "LNG_sv",
  LNG_ta: "LNG_ta",
  LNG_te: "LNG_te",
  LNG_th: "LNG_th",
  LNG_tr: "LNG_tr",
  LNG_uk: "LNG_uk",
  LNG_ur: "LNG_ur",
  LNG_uz: "LNG_uz",
  LNG_vi: "LNG_vi",
  LNG_zu: "LNG_zu",
};

export default keys;
