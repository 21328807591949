import { UIModelHubUsers } from "@/services/api/user/hubUser.model";
import React from "react";
import { ListState } from "./listState";

const usersState = new ListState<UIModelHubUsers>({
  withoutAppId: true,
  limit: 10,
  offset: 0,
  sortFields: ["-modifiedAt"],
  apiDataInitial: {
    list: [],
    total: 0,
  },
  filtersLoadSave: {},
});

export const UsersListState = React.createContext<ListState<UIModelHubUsers>>(
  {} as ListState<UIModelHubUsers>
);

const UsersListStateStoreProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <UsersListState.Provider value={usersState}>
      {children}
    </UsersListState.Provider>
  );
};

export default UsersListStateStoreProvider;
