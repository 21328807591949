import styled from "styled-components";

export const AdminBanner = styled.div.attrs({
  className: "AdminBanner",
})`
  display: flex;
  width: 100vw;
  height: 4.8rem;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--snow-darker);
  background: var(--cyan-light, #f1fdff);
`;
