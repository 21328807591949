// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  AUTH_login: "AUTH_login",
  AUTH_forgot: "AUTH_forgot",
  AUTH_clickHere: "AUTH_clickHere",
  AUTH_rightsReserved: "AUTH_rightsReserved",
  AUTH_privacy: "AUTH_privacy",
  AUTH_imprint: "AUTH_imprint",
  AUTH_reset: "AUTH_reset",
  AUTH_resetHelptext: "AUTH_resetHelptext",
  AUTH_resetButtonLabel: "AUTH_resetButtonLabel",
  AUTH_resetError: "AUTH_resetError",
  AUTH_resetSuccess: "AUTH_resetSuccess",
  AUTH_alreadyHaveAccount: "AUTH_alreadyHaveAccount",
  AUTH_signup: "AUTH_signup",
  AUTH_gethelp: "AUTH_gethelp",
  AUTH_getHelpLink: "AUTH_getHelpLink",
  AUTH_clickAboveButtons: "AUTH_clickAboveButtons",
  AUTH_termsAndService: "AUTH_termsAndService",
  AUTH_metaTerms: "AUTH_metaTerms",
  AUTH_forWhatsApp: "AUTH_forWhatsApp",
  AUTH_create: "AUTH_create",
  AUTH_login360: "AUTH_login360",
  AUTH_email: "AUTH_email",
  AUTH_password: "AUTH_password",
  AUTH_loginEmail: "AUTH_loginEmail",
  AUTH_continueEmail: "AUTH_continueEmail",
  AUTH_signupTerms: "AUTH_signupTerms",
  AUTH_checkEmail: "AUTH_checkEmail",
  AUTH_create360Account: "AUTH_create360Account",
  AUTH_otpTextOne: "AUTH_otpTextOne",
  AUTH_otpTextTwo: "AUTH_otpTextTwo",
  AUTH_otpTextThree: "AUTH_otpTextThree",
  AUTH_otp: "AUTH_otp",
  AUTH_enterOtp: "AUTH_enterOtp",
  AUTH_namePlaceholder: "AUTH_namePlaceholder",
  AUTH_nameLabel: "AUTH_nameLabel",
  AUTH_createAccountEmail: "AUTH_createAccountEmail",
  AUTH_otpWindowMessage: "AUTH_otpWindowMessage",
  AUTH_createAccount: "AUTH_createAccount",
  AUTH_signupPriceList: "AUTH_signupPriceList",
  AUTH_redirectMessage: "AUTH_redirectMessage",
  AUTH_visitNewHub: "AUTH_visitNewHub",
  AUTH_newHubInfoMessage: "AUTH_newHubInfoMessage",
  AUTH_governmentAgency: "AUTH_governmentAgency",
  AUTH_organizationIRepresent: "AUTH_organizationIRepresent",
  AUTH_chooseFromList: "AUTH_chooseFromList",
  AUTH_governmentalAgencyApproval: "AUTH_governmentalAgencyApproval",
  AUTH_companyEmail: "AUTH_companyEmail",
  AUTH_termsOfServiceSignUp: "AUTH_termsOfServiceSignUp",
  AUTH_newAccount: "AUTH_newAccount",
  AUTH_dontHaveAccount: "AUTH_dontHaveAccount",
  AUTH_signIn: "AUTH_signIn",
  AUTH_resetHere: "AUTH_resetHere",
  AUTH_twoFactorAuth: "AUTH_twoFactorAuth",
  AUTH_OTPSent: "AUTH_OTPSent",
  AUTH_ResetPasswordMessage: "AUTH_ResetPasswordMessage",
  AUTH_wantToCancel: "AUTH_wantToCancel",
  AUTH_backToSignIn: "AUTH_backToSignIn",
  AUTH_emailSentTo: "AUTH_emailSentTo",
  AUTH_checkInbox: "AUTH_checkInbox",
  AUTH_GoBackToSignIn: "AUTH_GoBackToSignIn",
  AUTH_logOut: "AUTH_logOut",
  AUTH_min8Characters: "AUTH_min8Characters",
  AUTH_noFacebookEmail: "AUTH_noFacebookEmail",
  AUTH_logIn: "AUTH_logIn",
  AUTH_instructions: "AUTH_instructions",
  AUTH_passwordNew: "AUTH_passwordNew",
  AUTH_emailVerification: "AUTH_emailVerification",
  AUTH_FBLogin: "AUTH_FBLogin",
  AUTH_redirectMessageCTA: "AUTH_redirectMessageCTA",
  AUTH_capsLock: "AUTH_capsLock",
  AUTH_passwordSuccess: "AUTH_passwordSuccess",
  AUTH_passwordError: "AUTH_passwordError",
  AUTH_otpTitle: "AUTH_otpTitle",
  AUTH_otpDescription: "AUTH_otpDescription",
};

export default keys;
