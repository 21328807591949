// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  INT_integrations: "INT_integrations",
  INT_syncEvents: "INT_syncEvents",
  INT_shopifyApp: "INT_shopifyApp",
  INT_connectCTADescription: "INT_connectCTADescription",
  INT_connect: "INT_connect",
  INT_supperChargingInsightDescription: "INT_supperChargingInsightDescription",
  INT_suggestIntegarationsDescription: "INT_suggestIntegarationsDescription",
  INT_suggestIntegarations: "INT_suggestIntegarations",
  INT_connectedApps: "INT_connectedApps",
  INT_upgrade: "INT_upgrade",
  INT_shopify: "INT_shopify",
  INT_shopifyDescription: "INT_shopifyDescription",
  INT_appNotAvailable: "INT_appNotAvailable",
  INT_comingSoon: "INT_comingSoon",
  INT_install: "INT_install",
  INT_connected: "INT_connected",
};

export default keys;
