// File generated automatically. Do not edit manually.
// import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.TOS_acceptAndClose]: "translation_missing",
  //  [keys.TOS_failedToLoad]: "translation_missing",
  // [keys.TOS_changeOfTos]: "translation_missing",
  // [keys.TOS_importantUpdate]: "translation_missing",
};

export default translate;
