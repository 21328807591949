// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.DASH_welcome]: "Welcome",
  [keys.DASH_welcomeBack]: "Welcome back",
  [keys.DASH_mainTemplateManagerInfo]:
    "Have you already registered some message templates for your number? Head to the template manager to create and organize your templates.",
  [keys.DASH_learnMore]: "Learn more",
  [keys.DASH_goToTemplates]: "Go to Template Manager",
  [keys.DASH_nextSteps]: "Next steps",
  [keys.DASH_firstSteps]: "First steps",
  [keys.DASH_yourWabasList]: "Your WhatsApp Accounts",
  [keys.DASH_resources]: "Resources",
  [keys.DASH_faq]: "FAQ",
  [keys.DASH_mainVerified]:
    "Your new number <NUMBER> is almost ready for you to use. Have you verified your business already?",
  [keys.DASH_mainGoToFbmButton]: "Go to Meta Business Verification",
  [keys.DASH_mainDocsLearnMore]:
    "Learn more about the functionality of the 360dialog Client Hub.",
  [keys.DASH_showDocsButton]: "Show documentation",
  [keys.DASH_popupIntro]:
    "Your new number <NUMBER> is almost ready to use. Here is what you need to do now:",
  [keys.DASH_popupBullet1]:
    "Create an API key to use within your integration partner's application.",
  [keys.DASH_popupBullet2]:
    "If your business is not yet verified by Meta, you need to start the business verification process within the Meta Business Manager.",
  [keys.DASH_generateKeyButton]: "Generate API key",
  [keys.DASH_fetchingNumberPopup]:
    "We're currently setting up your number. This may take a few moments. Please wait.",
  [keys.DASH_settingUpPopup]:
    "Starting your hosted WhatsApp Business API client. Just a few more moments.",
  [keys.DASH_numbersSectionEmpty]: "You don't have any registered numbers.",
  [keys.DASH_integrationPartnerInfo]: "Your integration partner is <PARTNER>.",
  [keys.DASH_lastStepApi]:
    "Great! You’ve just created your personal API key for your new number <NUMBER>. Now head to Meta Business Manager to start with the business verification process.",
  [keys.DASH_lastStepForwarding]:
    "Great! You’ve just enabled message forwarding for your number <NUMBER>. Now head to Meta Business Manager to start with the business verification process.",
  [keys.DASH_lastStepTitle]: "Meta Business Verification",
  [keys.DASH_lastStepButtonLabel]: "Go to Meta Business Manager",
  [keys.DASH_lastStepButtonLabelClose]: "I’ve already verified my business",
  [keys.DASH_lastStepVerifiedTitle]: "All set up...",
  [keys.DASH_lastStepVerifiedText]:
    "... and ready to go! With your personal API key for your new number <NUMBER> you can start using the WhatsApp Business API right away. As your Business is already verified by Meta there isn't anything that you need to do anymore.",
  [keys.DASH_timeoutErrorText]:
    "Unfortunately, we couldn't set up your number. It seems like you did not finish the onboarding process. Click below to finish the setup of up your WhatsApp Business account.",
  [keys.DASH_popupBulletBypassBeta]:
    "Please make sure to fill out your Business Info in the Meta Business Manager for full access to the API.",
  [keys.DASH_submissionSuccessHelptext]: "Your submission was successful! ",
  [keys.DASH_serverDelayMessage]:
    "Sorry, but it seems like our servers are getting many requests right now. Your number will be ready in a few minutes. We will notify you via email as soon as you can continue with the setup.",
  [keys.DASH_buttonLabelNewButton]: "Create new user",
  [keys.DASH_popupBullet3]:
    "Create an API key to use within your own application or set up your inbox with get.chat",
  [keys.DASH_popupBullet5]:
    "Your WhatsApp API account with 360dialog now includes a free inbox integration with our partner get.chat.\nget.chat is a software that enables you to connect your WhatsApp API account to multiple users and multiple applications (CRM, address book, ticketing system) simultaneously.\nAttention: If you choose to activate the inbox, you'll not be able to create an API key to integrate to your own application.",
  [keys.DASH_createBtnInbox]: "Create my get.chat inbox for <NUMBER>",
  [keys.DASH_generateKeyButtonInstead]: "Generate API key instead",
  [keys.DASH_chooseDifferentName]: "Choose a different display name",
  [keys.DASH_displayNamePendingMessage]:
    "WhatsApp is taking a little bit longer than usual to approve the display name you have chosen. This might take more time so we will notify you of the status of this procedure via e-mail",
  [keys.DASH_displayNameRejectedMessage]:
    "The display name you have chosen has been rejected by WhatsApp <br/> You can review the official Display Name Guidelines <LINK> <br/> Please choose a different display name",
  [keys.DASH_displayNameRejectedMessagePipe]:
    "The display name you have chosen has been rejected by WhatsApp. You can review the official Display Name Guidelines <LINK>https://www.facebook.com/business/help/338047025165344 | here<LINK>.",
  [keys.DASH_integrationSetup]: "Integration Setup",
  [keys.DASH_migrateNumberFromAnotherBSP]: "Migrate number from another BSP",
  [keys.DASH_useClassicSignup]: "Use classic signup",
  [keys.DASH_addNewPhoneNumber]: "Add new phone number",
  [keys.DASH_importFromWBA]: "Import from WhatsApp Business App",
  [keys.DASH_importFromBS]: "Import from another BSP",
  [keys.DASH_stackSetup]: "Setting up your number",
  [keys.DASH_test]: "Test",
  [keys.DASH_testWebhookSuccess]: "A test webhook event was successfully sent.",
  [keys.DASH_testWebhookError]:
    "The test webhook event could not be delivered to your webhook URL. Please check the URL and try again. If the problem persists, reach out to our customer support.",
  [keys.DASH_integrationSettings]: "Integration Settings",
  [keys.DASH_partnerWebhookUrl]: "Partner Webhook URL",
  [keys.DASH_webhookUrlTootip]:
    "Clicking the Test button will send a test webhook event to your webhook URL. Use this to test if your webhook URL is working and accepting events coming from our servers.",
  [keys.DASH_redirectUrl]: "Redirect URL (Integrated Onboarding)",
  [keys.DASH_redirectUrlToolip]:
    "Clicking the Test button will open your redirect URL in a new tab, and a sample client ID and channel IDs will be added for demo purposes",
  [keys.DASH_defaultHosting]: "Default Hosting Platform Type",
  [keys.DASH_cloudApiHosted]: "Cloud API hosted by Meta",
  [keys.DASH_onPremiseHosted]: "On-premise API hosted by 360dialog",
  [keys.DASH_infoOnHostingPlatform]:
    "All numbers newly onboarded to the WhatsApp Business API as well as all numbers migrated from another BSP will automatically be hosted via the default hosting platform type.",
  [keys.DASH_partnerUpdatedSuccess]:
    "Successfully updated partner integration data",
  [keys.DASH_redirectUrlNorValid]:
    "The provided redirect URL is not correct to the URI format. Please check it again and make sure it is spelled correctly.",
  [keys.DASH_paymentPlan]: "Payment Plan",
  [keys.DASH_vsPreviousWeek]: "vs previous week",
  [keys.DASH_conversations]: "Conversations",
  [keys.DASH_expenses]: "Expenses",
  [keys.DASH_lastWeekActivity]: "Last week's activity",
  [keys.DASH_channel]: "Channels",
};

export default translate;
