import axios, { AxiosInstance } from "axios";
import {
  beforeRequestHandler,
  requestErrorHandler,
} from "./HandleGlobalInterceptors";
import "../../../utilities/variables";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isProduction = ["production", "rc"].includes(
  process.env.NEXT_PUBLIC_APP_ENV || ""
);

const apiEnvAffix = isProduction ? "-production" : "-staging";

const tosUrlV1 = `https://terms.hub${apiEnvAffix}.360dialog.io/v1`;

const instanceV1 = axios.create({ baseURL: tosUrlV1 });

class APIInstanceTos {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    this.registerGlobalRequestInterceptors();
  }

  public get buffedInstances() {
    return this.instance;
  }

  public setAuthorizationToken(token: string) {
    try {
      this.instance.defaults.headers["Authorization"] = `Bearer ${token}`;
    } catch (error) {
      console.error(`Failed to set authorization token: ${error}`);
    }
  }

  private registerGlobalRequestInterceptors = () => {
    this.instance.interceptors.request.use(
      beforeRequestHandler,
      requestErrorHandler
    );
    this.instance.interceptors.response.use(null, requestErrorHandler);
  };
}

export const MPInstanceTos = new APIInstanceTos(instanceV1);
