import i18n, { TranslationsLanguage } from "../../services/store/i18n";
import keys from "./translate/keys";
import translateEN from "./translate/en";
import translateDE from "./translate/de";
import translateRU from "./translate/ru";
import translatePTBR from "./translate/pt-br";
import translateES from "./translate/es";

const resolutionCodes = {
  done: "ResolutionCodesDone",
  incomplete: "ResolutionCodesIncomplete",
  timed_out: "ResolutionCodesTimeOut",
  abandoned: "ResolutionCodesAbandoned",
  ignored: "ResolutionCodesIgnored",
  banned: "ResolutionCodesBanned",
  unsupported: "ResolutionCodesUnsupported",
  opted_out: "ResolutionCodesOptedOut",
  offer_created: "ResolutionCodesOfferCreated",
};

const translateDelimiterStatus: { [k: string]: string } = {
  opened: "ConversationLastMessageConversationOpenedBy",
  closed: "ConversationLastMessageConversationClosedBy",
};

export default {
  resolutionCodes,
  translateDelimiterStatus,
};

function extractTranslations(translations: { [k: string]: string }) {
  return Object.keys(translations).map((translate) => {
    return {
      key: translate,
      value: translations[translate],
    };
  });
}

const InboxConversationKeysTranslate: TranslationsLanguage[] = [
  {
    language: "en",
    phrases: extractTranslations(translateEN),
  },
  {
    language: "de",
    phrases: extractTranslations(translateDE),
  },
  {
    language: "ru",
    phrases: extractTranslations(translateRU),
  },
  {
    language: "pt",
    phrases: extractTranslations(translatePTBR),
  },
  {
    language: "es",
    phrases: extractTranslations(translateES),
  },
];

i18n.addTranslation(InboxConversationKeysTranslate);

export { i18n };
export const k = keys;
