// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.TOUR_eventFunnelTitle]: "Event funnel view!",
  [keys.TOUR_eventFunnelDesc]:
    "Create and save new funnels, switch effortlessly, and gain insights into your marketing channels' performance.",
  [keys.TOUR_addStepsTitle]: "Add steps and custom your funnel",
  [keys.TOUR_addStepsDesc]:
    "Enhance funnels by adding events like 'Added to Cart' dynamically from your triggered WhatsApp message templates or CRM events.",
  [keys.TOUR_highlightCardsTitle]: "Highlight Cards",
  [keys.TOUR_highlightCardsDesc]:
    "Track overall results across all marketing sources seamlessly!",
  [keys.TOUR_understandSourcesTitle]: "Understand Sources",
  [keys.TOUR_understandSourcesDescTitle]:
    "There are four source types on table:",
  [keys.TOUR_understandSourcesDescOne]:
    "Outgoing: Leads initiated by your business messages.",
  [keys.TOUR_understandSourcesDescTwo]:
    "Incoming: Leads originating from standard message triggers like QR Codes.",
  [keys.TOUR_understandSourcesDescThree]:
    "Meta: Leads generated from click-to-WhatsApp campaigns.",
  [keys.TOUR_understandSourcesDescFour]:
    "Organic: Leads from untracked sources",
  [keys.TOUR_dateFiltersTitle]: "Date Filters",
  [keys.TOUR_dateFiltersDesc]:
    "Customize your analysis by setting any date range to delve into your insights.",
  [keys.TOUR_audiencesAdAccountsTitle]: "Ad Accounts",
  [keys.TOUR_audiencesAdAccountsDescription]:
    "Switch between the ad accounts to see their audiences.",
  [keys.TOUR_audiencesAdAccountsTosMissingDescription]:
    "Switch between the ad accounts to see their audiences. This ad account hasn't accepted Meta's Terms of Service, yet.",
  [keys.TOUR_audiencesMetasTosTitle]: "Meta's Terms of Service",
  [keys.TOUR_audiencesMetasTosDescription]:
    "You must accept Meta's Terms of Service to see it's audiences.",
  [keys.TOUR_audiencesLeadsInteractionsTitle]: "Leads Interactions",
  [keys.TOUR_audiencesLeadsInteractionsDescription]:
    "Performance Audiences is a feature that targets users based on their interactions with your business. On the left side you may see which interaction was it.",
  [keys.TOUR_audiencesRedirectLinkTitle]: "Redirect Link",
  [keys.TOUR_audiencesRedirectLinkDescription]:
    "Click here and check on Meta's website.",
};

export default translate;
