// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.NOT_allNotifications]: "Todo",
  [keys.NOT_archivedNotifications]: "Archivado",
  [keys.NOT_closeButtonTooltip]: "Archivar esta notificación",
  [keys.NOT_archiveAllBtn]: "Archivar todo",
  [keys.NOT_noNewNotifications]:
    "Estas son todas tus notificaciones de los últimos 30 días",
  //  [keys.NOT_clientAdded]: "translation_missing",
  //  [keys.NOT_clientAddedWithMigrationDate]: "translation_missing",
  [keys.NOT_rejectionReason]: "Motivo de rechazo:",
  [keys.NOT_alreadyApproved]: "Ya has aprovado esta solicitud de cambio",
  [keys.NOT_apiKeyRecommendation]: "Recomendamos generar una nuea API Key",
  [keys.NOT_generateNewAPIKey]: "Generar una nueva API Key",
  //  [keys.NOT_partnerRequestFailed]: "translation_missing",
  //  [keys.NOT_partnerRequestCancelled]: "translation_missing",
  //  [keys.NOT_needCaptcha]: "translation_missing",
  //  [keys.NOT_errorUpdatingChatSupport]: "translation_missing",
  [keys.NOT_approved]: "Aprobado",
  [keys.NOT_pending]: "Pendiente",
  [keys.NOT_affectedNumbers]: "números afectados",
  [keys.NOT_minutesAgo]: "hace {{minutes}} minutos",
  [keys.NOT_hoursAgo]: "hace {{hours}} horas",
  [keys.NOT_emptyStateMessage]:
    "Parece que aún no has recibido ninguna notificación",
  // [keys.NOT_allNumbersAffected]: "translation_missing",
};

export default translate;
