// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.FN_intro]: "Introducing Funnels!",
  [keys.FN_createAndSaveFunnel]: "Create & Save Funnels",
  [keys.FN_organizeSource]: "Organize your Sources",
  [keys.FN_optimizeCampaigns]: "Optimize your Campaigns",
  [keys.FN_introFn]: "Introducing Funnels",
  [keys.FN_introBodyFirst]:
    "You can now create, save, and customize WhatsApp marketing funnels directly within our platform. We've also revamped our table interface for better UX, with improved grouping options and custom grouping. Currently, we support <BOLD>Off Thread<BOLD> events exclusively, so the old UI remains available.",
  [keys.FN_introBodySecond]:
    "However, as we move towards supporting both In Thread and Off Thread events, the old view will be deprecated. Thanks for joining us on this journey towards better WhatsApp marketing automation!",
  [keys.FN_introCTA]: "Let's go!",
  [keys.FN_creationNotAvailable]: "Funnel creation not available yet",
  [keys.FN_orderingOptionChanged]: "Ordering option changed to <ORDERING_NAME>",
  [keys.FN_updated]: "Funnel updated",
  [keys.FN_created]: "Funnel created successfully",
  [keys.FN_renamed]: "Funnel renamed successfully",
  [keys.FN_createFirstFunnel]: "Create first funnel",
  [keys.FN_deletedSuccessfully]: "Funnel deleted successfully",
  [keys.FN_aboutToDelete]: "You're about to delete <FUNNEL_NAME> permanently.",
  [keys.FN_delete]: "Delete funnel",
};

export default translate;
