import { MPInstanceAds } from "@/services/api/RequestAxios/RequestAxiosAds";

import * as Types from "./integrations.types";

const shopifyClientInfoURL = () => `/shopify/client-info`;

const shopifyAttachURL = () => `/shopify/attach`;

const shopifyUsageChargeURL = () => `/shopify/usage-charge`;

export const getShopifyClientInfo = async ({
  clientId,
  shop,
}: {
  clientId: string;
  shop: string;
}) => {
  const { data } =
    await MPInstanceAds.buffedInstances.get<Types.IServerResponseShopifyClientInfo>(
      shopifyClientInfoURL(),
      {
        params: {
          client_id: clientId,
          shop,
        },
      }
    );

  return data;
};

export const attachShopify = async ({
  clientId,
  shop,
}: {
  clientId: string;
  shop: string;
}) => {
  const data = await MPInstanceAds.buffedInstances.post(
    shopifyAttachURL(),
    null,
    {
      params: {
        client_id: clientId,
        shop,
      },
    }
  );

  return data;
};

export const usageCharge = async ({ clientId }: { clientId: string }) => {
  const { data } =
    await MPInstanceAds.buffedInstances.post<Types.IServerResponseUsageCharge>(
      shopifyUsageChargeURL(),
      null,
      {
        params: {
          client_id: clientId,
        },
      }
    );

  return data;
};
