// File generated automatically. Do not edit manually.
// import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.INT_integrations]: "translation_missing",
  //  [keys.INT_syncEvents]: "translation_missing",
  //  [keys.INT_shopifyApp]: "translation_missing",
  //  [keys.INT_connectCTADescription]: "translation_missing",
  //  [keys.INT_connect]: "translation_missing",
  //  [keys.INT_supperChargingInsightDescription]: "translation_missing",
  //  [keys.INT_suggestIntegarationsDescription]: "translation_missing",
  //  [keys.INT_suggestIntegarations]: "translation_missing",
  //  [keys.INT_connectedApps]: "translation_missing",
  //  [keys.INT_upgrade]: "translation_missing",
  //  [keys.INT_shopify]: "translation_missing",
  //  [keys.INT_shopifyDescription]: "translation_missing",
  //  [keys.INT_appNotAvailable]: "translation_missing",
  // [keys.INT_comingSoon]: "translation_missing",
  // [keys.INT_install]: "translation_missing",
};

export default translate;
