// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.FAQ_q_1]:
    "Что такое подтверждение компании в Meta Business Manager и зачем оно нужно?",
  //  [keys.FAQ_a_1]: "translation_missing",
  [keys.FAQ_q_2]:
    "Какие документы понадобятся для подтверждения компании в Meta Business Manager?",
  [keys.FAQ_a_2]:
    "В нашей базе <DOCS> представлен список всех документов, необходимых для подтверждения компании в Meta.",
  [keys.FAQ_q_3]:
    "Могу ли я продолжать использовать приложение WhatsApp Business?",
  [keys.FAQ_a_3]:
    "После того, как вы завершите процесс регистрации в 360dialog, мы создадим учетную запись WhatsApp Business API для выбранного номера, и вы не сможете перейти обратно на приложение WhatsApp Business.",
  [keys.FAQ_q_4]: "Я не получил SMS с проверочным кодом. Почему так произошло?",
  [keys.FAQ_a_4]:
    "Для начала проверьте, правильно ли вы ввели номер телефона. Если получить код все еще не выходит, возможно, ваш номер в настоящее время уже используется в приложении WhatsApp или WhatsApp Business.",
  [keys.FAQ_q_5]:
    "Я не знаю как создать свой API-ключ. Где искать эту возможность?",
  [keys.FAQ_a_5]:
    "Если ваша интеграция была успешно запущена, вы всегда можете создать или обновить API-ключ. Если вы не можете найти соответствующую кнопку ни на странице учетных записей WhatsApp, ни на странице детальных сведений о номере, есть вероятность, что ваша интеграция еще не полностью настроена.",
  [keys.FAQ_q_6]:
    "Что такое пересылка сообщений партнеру и зачем она должна быть активирована?",
  [keys.FAQ_a_6]:
    "Если вы видите опцию пересылки ваших сообщений вашему партнеру, вы, скорее всего, зарегистрировали продукт в компании-партнере, которая должна получать ваши сообщения для правильного функционирования приложения. Только вы можете разрешить партнеру получать эти сообщения, активировав пересылку сообщений.",
  //  [keys.FAQ_q_7]: "translation_missing",
  [keys.FAQ_a_7]:
    "Если вы зарегистрировали продукт у партнера по интеграции, API больше не требуется. Единственное, что вам нужно сделать, это включить пересылку ваших сообщений вашему партнеру. Если вы создаете приложение самостоятельно и поэтому вам необходимо создать API-ключ, обратитесь в нашу службу поддержки.",
};

export default translate;
