import * as Types from "./channels.types";
import {
  channelStatus,
  integrationStatus,
  mapToCommongStatusIntegration,
  statusIndicatorsStatuses,
  mapToCommonStatusWabaChannel,
  mapToLabelStatusIntegration,
  mapToLabelDisplayNameStatus,
} from "@/services/api/admin/statuses";
import {
  UIResponseIntegration,
  IServerResponseIntegration,
  UIResponseWabas,
} from "../waba/waba.types";

const UNSET_LABEL = "unset";

export class UIModelChannels {
  channels: UIModelChannel[];
  total: number;

  constructor(response: Types.IUIResponseChannels) {
    this.channels = response.channels.map(
      (channel) => new UIModelChannel(channel)
    );
    this.total = response.total;
  }
}

export type ChannelStatuses =
  | "main"
  | "externalWabaChannel"
  | "displayNameVerification"
  | "numberRegistration";

interface IChannelStatusObject {
  indicator: statusIndicatorsStatuses;
  label: string;
}

export type TTier = "basic" | "regular" | "premium";

export interface ISettings {
  tier: TTier;
}

export class UIModelChannel {
  id: string;
  type: string;
  partnerId: string;
  clientId: string;
  projectId: string;
  status: channelStatus;
  integrationId: null | string;
  integration?: UIModelIntegration;
  wabaAccountId: string;
  externalId: null | string;
  profileInfo: UIModelProfileInfo;
  consents: UIModelConsents;
  setupInfo: UIModelSetupInfo;
  createdAt: Date;
  redirectUrl: null | string;
  modifiedAt: Date;
  externalWabaChannelStatus: statusIndicatorsStatuses;
  displayNameVerificationStatus: statusIndicatorsStatuses;
  billingStartedAt?: Date | null;
  version: number;
  accountMode: string;
  cancelledAt?: string | null;
  terminatedAt?: string | null;
  isMigrated: boolean;
  metaStatus: string;
  currentLimit: string | null;
  currentQualityRating: string | null;
  hasInbox: boolean;
  isOba: boolean;
  settings: ISettings;
  lastUpgradeAt: Date;
  lastDowngradeAt: string | number | Date;
  currentBalance: number;

  constructor(response: Types.IUIResponseChannel) {
    this.update(response);
  }

  update = (response: Types.IUIResponseChannel) => {
    this.id = response.id;
    this.type = response.type;
    this.partnerId = response.partnerId;
    this.clientId = response.clientId;
    this.projectId = response.projectId;
    this.consents = new UIModelConsents(response.consents);
    this.status = response.status;
    this.integrationId = response.integrationId;
    this.wabaAccountId = response.wabaAccountId;
    this.externalId = response.externalId;
    this.profileInfo = new UIModelProfileInfo(response.profileInfo);
    this.setupInfo = new UIModelSetupInfo(response.setupInfo);
    this.createdAt = new Date(response.createdAt);
    this.modifiedAt = new Date(response.modifiedAt);
    this.integration = response.integration
      ? new UIModelIntegration(response.integration)
      : undefined;
    this.externalWabaChannelStatus = this.externalId ? "done" : "draft";
    this.displayNameVerificationStatus =
      mapToCommonStatusWabaChannel[this.status];
    this.redirectUrl = response.redirectUrl;
    this.billingStartedAt = response.billingStartedAt
      ? new Date(response.billingStartedAt)
      : null;
    this.version = response.version;
    this.accountMode = response.accountMode;
    this.cancelledAt = response.cancelledAt;
    this.terminatedAt = response.terminatedAt;
    this.isMigrated = response.isMigrated ? response.isMigrated : false;
    this.metaStatus = response.metaStatus;
    this.currentLimit = response.currentLimit;
    this.currentQualityRating = response.currentQualityRating;
    this.hasInbox = response.hasInbox || false;
    this.isOba = response.isOba || false;
    this.settings = response.settings || null;
    this.lastUpgradeAt = response.lastUpgradeAt
      ? new Date(response.lastUpgradeAt)
      : null;
    this.lastDowngradeAt = response.lastDowngradeAt;
    this.currentBalance = response.currentBalance;
  };

  get formValues() {
    return {
      wabaAccountId: this.wabaAccountId,
      type: this.type,
      ...this.setupInfo,
      ...this.profileInfo,
    };
  }

  get isReadyToSubmit() {
    return (
      this.setupInfo.checkIfRequiredToSubmitFieldsAreFilled &&
      this.profileInfo.checkIfRequiredToSubmitFieldsAreFilled
    );
  }

  get numberRegistrationStatus() {
    return this.integration
      ? mapToCommongStatusIntegration[this.integration.state]
      : "draft";
  }

  get isAnyStatusSetToRejected() {
    return [this.externalWabaChannelStatus, this.displayNameVerificationStatus];
  }

  get mainStatus() {
    if (
      this.integration &&
      mapToCommongStatusIntegration[this.integration.state] === "done"
    ) {
      return "done";
    } else {
      if (mapToCommonStatusWabaChannel[this.status] !== "draft") {
        return "pending";
      } else {
        return "draft";
      }
    }
  }

  get statuses(): { [k in ChannelStatuses]: IChannelStatusObject } {
    return {
      main: {
        label: "",
        indicator: this.mainStatus,
      },
      externalWabaChannel: {
        label: this.externalWabaChannelStatus,
        indicator: this.externalWabaChannelStatus,
      },
      displayNameVerification: {
        label: mapToLabelDisplayNameStatus(this.status),
        indicator: this.displayNameVerificationStatus,
      },
      numberRegistration: {
        label: this.integration
          ? mapToLabelStatusIntegration[this.integration.state]
          : UNSET_LABEL,
        indicator: this.numberRegistrationStatus,
      },
    };
  }
}

export class UIModelConsents {
  signedAt: Date | null;
  note: string;
  url: string | null;

  constructor(response: Types.IUIResponseConsents) {
    (this.note = response.note),
      (this.signedAt =
        typeof response.signedAt === "string"
          ? new Date(response.signedAt)
          : response.signedAt),
      (this.url = response.url);
  }

  get status() {
    return this.signedAt ? "Approved" : "Pending";
  }
}

export class UIModelProfileInfo {
  pictureUrl: string;
  aboutText: string;
  businessVertical: string;
  businessDescription: string;
  useCaseDescription: string;
  streetName: string;
  city: string;
  zipCode: string;
  country: string;
  contactEmail: string;
  webpageUrl: string;

  constructor(response: Types.IUIResponseProfileInfo) {
    this.pictureUrl = response.pictureUrl;
    this.aboutText = response.aboutText;
    this.businessVertical = response.businessVertical;
    this.businessDescription = response.businessDescription;
    this.useCaseDescription = response.useCaseDescription;
    this.streetName = response.streetName;
    this.city = response.city;
    this.zipCode = response.zipCode;
    this.country = response.country;
    this.contactEmail = response.contactEmail;
    this.webpageUrl = response.webpageUrl;
  }

  get checkIfRequiredToSubmitFieldsAreFilled() {
    return !!(
      this.pictureUrl &&
      this.aboutText &&
      this.businessVertical &&
      this.businessDescription &&
      this.useCaseDescription &&
      this.streetName &&
      this.city &&
      this.zipCode &&
      this.country &&
      this.contactEmail &&
      this.webpageUrl
    );
  }
}

export class UIModelSetupInfo {
  phoneNumber: string;
  phoneName: string;
  verificationMethod: string;
  defaultLanguage: string | null;
  wasInUse: boolean;
  ivr: boolean;
  certificate: string;
  hasIntegrationLayer: boolean;

  constructor(response: Types.IUIResponseSetupInfo) {
    this.phoneNumber = response.phoneNumber;
    this.phoneName = response.phoneName;
    this.verificationMethod = response.verificationMethod;
    this.defaultLanguage = response.defaultLanguage || null;
    this.wasInUse = response.wasInUse;
    this.ivr = response.ivr;
    this.certificate = response.certificate;
    this.hasIntegrationLayer = response.hasIntegrationLayer || false;
  }

  get checkIfRequiredToSubmitFieldsAreFilled() {
    return !!(
      this.phoneNumber &&
      this.phoneName &&
      this.verificationMethod &&
      this.defaultLanguage &&
      typeof this.wasInUse === "boolean" &&
      typeof this.ivr === "boolean"
    );
  }
}

export class UIModelIntegration {
  appId: string;
  createdAt: Date;
  modifiedAt: Date;
  id: string;
  parameters?: IntegrationParameters;
  stackId: string;
  state: integrationStatus;
  token: string;
  type: string;
  username: string;
  password: string;
  enabled: boolean;
  address: string;
  premium: boolean;
  hostingPlatformType: Types.THostingPlatformType;
  hostingPlatform: string;

  constructor(response: UIResponseIntegration) {
    this.update(response);
  }

  update = (response: UIResponseIntegration) => {
    this.appId = response.app_id;
    this.createdAt = new Date(response.created_at);
    this.modifiedAt = new Date(response.modified_at);
    this.id = response.id;
    this.username = response.username;
    this.password = response.password;
    this.address = response.address;
    this.parameters = response.parameters
      ? new IntegrationParameters(response.parameters)
      : undefined;
    this.stackId = response.stack_id;
    this.state = response.state;
    this.token = response.token;
    this.type = response.type;
    this.enabled = response.enabled;
    this.premium = response.premium;
    this.hostingPlatformType = response.hostingPlatformType;
  };
}

export class UIModelIntegrations {
  integrations: UIModelIntegration[];
  total: number;

  constructor(response: UIResponseWabas) {
    this.integrations = response.integrations.map(
      (integration) => new UIModelIntegration(integration)
    );
    this.total = response.total;
  }
}

export class IntegrationParameters {
  appName?: string;
  defaultLanguage: string;
  namespace: string;
  organisation?: string;
  certificate?: string;
  verificationMethod?: string;

  constructor(response: IServerResponseIntegration["parameters"]) {
    this.appName = response.app_name;
    this.defaultLanguage = response.default_language;
    this.namespace = response.namespace;
    this.organisation = response.organisation;
    this.certificate = response.certificate;
    this.verificationMethod = response.verification_method;
  }
}
