// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.BV_advertising]: "Advertising",
  [keys.BV_automotive]: "Automotive",
  [keys.BV_beauty]: "Beauty, Spa and Salon",
  [keys.BV_clothing]: "Clothing and Apparel",
  [keys.BV_consumer_goods]: "Consumer packaged goods",
  [keys.BV_ecommerce]: "e-commerce",
  [keys.BV_education]: "Education",
  [keys.BV_energy]: "Energy and utilities",
  [keys.BV_entertainment]: "Entertainment",
  [keys.BV_entertainment_media]: "Entertainment and media",
  [keys.BV_event_planning]: "Event Planning and Service",
  [keys.BV_banking]: "Finance and Banking",
  [keys.BV_food]: "Food and Grocery",
  [keys.BV_gaming]: "Gaming",
  [keys.BV_government]: "Government and politics",
  [keys.BV_hotel]: "Hotel and Lodging",
  [keys.BV_IT]: "IT-Services",
  [keys.BV_marketing]: "Marketing",
  [keys.BV_medical]: "Medical and Health",
  [keys.BV_non_profit]: "Non profit",
  [keys.BV_organizations]: "Organizations and associations",
  [keys.BV_other]: "Other",
  [keys.BV_professional]: "Professional services",
  [keys.BV_public]: "Public Service",
  [keys.BV_retail]: "Retail",
  [keys.BV_restaurant]: "Restaurant",
  [keys.BV_shopping]: "Shopping and Retail",
  [keys.BV_technology]: "Technology",
  [keys.BV_telecom]: "Telecom",
  [keys.BV_travel]: "Travel",
  [keys.BV_transportation]: "Travel and Transportation",
  [keys.BV_financial]: "Financial and Bank",
};

export default translate;
