import * as Types from "./project.types";
import nullWhenEmptyStringOrUndefinied from "@/utilities/nullWhenEmptyStringOrUndefinied";

export const unpackProject = (
  response: Types.IServerResponseProject
): Types.IUIResponseProject => ({
  appId: response.app_id,
  clientId: response.client_id,
  partnerId: response.partner_id,
  createdAt: response.created_at,
  inbox: response.inbox ?? false,
  licenseModel: response.license_model,
  id: response.id,
  modifiedAt: response.modified_at,
  name: response.name,
  status: response.status,
  apiUserEmail: response.api_user_email || "",
  webhook: {
    url: response.webhook?.url || "",
  },
});

export const unpackProjects = (
  response: Types.IServerResponseProjects
): Types.IUIResponseProjects => ({
  total: response.total,
  projects: response.projects.map((project) => unpackProject(project)),
});

export const packProject = (
  request: Types.IProjectFormValues
): Types.IServerRequestProject => ({
  name: request.name,
  inbox: request.inbox,
  license_model: request.licenseModel,
  api_user_email: nullWhenEmptyStringOrUndefinied(request.apiUserEmail),
  webhook: request.webhookUrl
    ? {
        url: request.webhookUrl,
      }
    : null,
});
