import { MPInstancesHub } from "@/services/api/RequestAxios/RequestAxiosHub";
import { IQueryParams, packQueryParams, stringifyQueryParams } from "../utils";
import {
  UIModelPartnerChannel,
  UIModelPartnerChannels,
  UIModelPartnerChannelsV1,
} from "./partnerChannels.model";
import {
  unpackPartnerChannel,
  unpackPartnerChannels,
  packPartnerChannelFromPipe,
  unpackPartnerChannelFromSubmit,
  packPartnerChannelFromSubmit,
  packPartnerChannelFromMigrate,
  unpackPartnerChannelFromMigrate,
  unpackPartnerChannelsV1,
} from "./partnerChannels.mapper";
import {
  IServerResponseChannel,
  IUIResponseChannel,
} from "../channels/channels.types";
import { unpackChannel } from "../channels/channels.mapper";
import * as Types from "./partnerChannels.types";

const partnerChannelsURL = ({ partnerId }: { partnerId: string }) =>
  `/partners/${partnerId}/channels`;
const partnerChannelsV1URL = () => `/channels`;
const partnerChannelURL = ({
  partnerId,
  channelId,
}: {
  partnerId: string;
  channelId: string;
}) => `/partners/${partnerId}/channels/${channelId}`;
const partnerChannelCreateFlow = ({ partnerId }: { partnerId: string }) =>
  `/partners/${partnerId}/channels_combined`;
const partnerChannelMigrateUrl = ({ partnerId }: { partnerId: string }) =>
  `/partners/${partnerId}/migrate_channel`;

export const getPartnerChannels = async (
  { partnerId }: { partnerId: string },
  queryParams?: IQueryParams
): Promise<UIModelPartnerChannels> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponsePartnerChannels>(
      partnerChannelsURL({ partnerId }),
      {
        params: queryParams && packQueryParams(queryParams),
        paramsSerializer: stringifyQueryParams,
      }
    );

  return new UIModelPartnerChannels(unpackPartnerChannels(data));
};

export const getPartnerChannel = async ({
  partnerId,
  channelId,
}: {
  partnerId: string;
  channelId: string;
}): Promise<UIModelPartnerChannel> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponsePartnerChannel>(
      partnerChannelURL({ partnerId, channelId })
    );
  return new UIModelPartnerChannel(unpackPartnerChannel(data));
};

export const getPartnerChannelsV1 =
  async (): Promise<UIModelPartnerChannelsV1> => {
    const { data } =
      await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponsePartnerChannelsV1>(
        partnerChannelsV1URL(),
        {
          params: {
            limit: 9999999,
          },
        }
      );

    return new UIModelPartnerChannelsV1(unpackPartnerChannelsV1(data));
  };

export const addPartnerChannelFromPipe = async (
  { partnerId }: { partnerId: string },
  payload: Types.CreatePipeFlowFullForm
): Promise<IUIResponseChannel> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<IServerResponseChannel>(
      partnerChannelCreateFlow({ partnerId }),
      packPartnerChannelFromPipe(payload)
    );

  return unpackChannel(data);
};

export const addPartnerChannelFromSubmitWaba = async (
  { partnerId }: { partnerId: string },
  payload: any
) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post<{
    client_id: string;
    token: string;
  }>(
    partnerChannelCreateFlow({ partnerId }),
    packPartnerChannelFromSubmit(payload)
  );

  return unpackPartnerChannelFromSubmit(data);
};

export const addPartnerChannelFromMigrateWaba = async (
  { partnerId }: { partnerId: string },
  payload: any
) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post<{
    client_id: string;
    token: string;
    access_token: string;
    expires_in: string;
    token_type: string;
    waba_account_id: string;
    channel_id: string;
  }>(
    partnerChannelMigrateUrl({ partnerId }),
    packPartnerChannelFromMigrate(payload)
  );

  return unpackPartnerChannelFromMigrate(data);
};
