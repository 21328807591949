// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.SET_partnerName]: "translation_missing",
  //  [keys.SET_partnerPermissions]: "translation_missing",
  //  [keys.SET_selectTitle]: "translation_missing",
  //  [keys.SET_saveButton]: "translation_missing",
  //  [keys.SET_successGrantPermissions]: "translation_missing",
  //  [keys.SET_Inboxes]: "translation_missing",
  //  [keys.SET_selectNumberInbox]: "translation_missing",
  //  [keys.SET_requestPartnerChange]: "translation_missing",
  //  [keys.SET_requestApproved]: "translation_missing",
  //  [keys.SET_requestPending]: "translation_missing",
  //  [keys.SET_selectNewPartner]: "translation_missing",
  //  [keys.SET_confirmTerms]: "translation_missing",
  //  [keys.SET_submitPartnerChangeRequest]: "translation_missing",
  //  [keys.SET_addPartnerIdMessage]: "translation_missing",
  //  [keys.SET_enterPartnerId]: "translation_missing",
  //  [keys.SET_notValidPartnerId]: "translation_missing",
  //  [keys.SET_partnerIdFound]: "translation_missing",
  //  [keys.SET_newPartnerText]: "translation_missing",
  //  [keys.SET_currentPartnerText]: "translation_missing",
  //  [keys.SET_currencyMismatchErrorText]: "translation_missing",
  //  [keys.SET_messageSupportLabel]: "translation_missing",
  //  [keys.SET_messageSupportPlaceholder]: "translation_missing",
  //  [keys.SET_billingPlanDesc1]: "translation_missing",
  //  [keys.SET_billingPlanDesc2]: "translation_missing",
  //  [keys.SET_billingPlanDesc3]: "translation_missing",
  //  [keys.SET_billingPlanDesc4]: "translation_missing",
  //  [keys.SET_partnerChangeConditionText]: "translation_missing",
  //  [keys.SET_messageToNewPartner]: "translation_missing",
  //  [keys.SET_messageToNewPartnerPlaceholder]: "translation_missing",
  //  [keys.SET_partnerChangeRequestSubmitted]: "translation_missing",
  //  [keys.SET_cancelling]: "translation_missing",
  //  [keys.SET_rejectionInputLabel]: "translation_missing",
  //  [keys.SET_rejectionInputPlaceholder]: "translation_missing",
  //  [keys.SET_rejectionInputError]: "translation_missing",
  //  [keys.SET_rejectClient]: "translation_missing",
  //  [keys.SET_acceptClient]: "translation_missing",
  //  [keys.SET_allExternalWabaMustHaveSameCurrency]: "translation_missing",
  //  [keys.SET_paymentPlanCurrencyHasToMatch]: "translation_missing",
  //  [keys.SET_partnerChangeRequestTargetPartnerPaymentPlanRequired]: "translation_missing",
  //  [keys.SET_partnerChangeRequestTargetPartnerPricingPlanRequired]: "translation_missing",
  //  [keys.SET_partnerChangeRequestClientPaymentMethodRequired]: "translation_missing",
  //  [keys.SET_wabaAndPaymentPlanCurrencyMustMatch]: "translation_missing",
  //  [keys.SET_paymentPlanHasToMatch]: "translation_missing",
  //  [keys.SET_addPaymentMethod]: "translation_missing",
  //  [keys.SET_migrationNote]: "translation_missing",
  //  [keys.SET_apiKeyRecommendation]: "translation_missing",
  //  [keys.SET_languageSelection]: "translation_missing",
  //  [keys.SET_askForRefund]: "translation_missing",
  //  [keys.SET_disabledPartnerChangeTooltip]: "translation_missing",
  //  [keys.SET_addCompanyInfo]: "translation_missing",
  //  [keys.SET_selectPlan]: "translation_missing",
  //  [keys.SET_triggerRefundMessage]: "translation_missing",
  //  [keys.SET_errorPartnerChange]: "translation_missing",
  //  [keys.SET_errorPartnerChangeInfo]: "translation_missing",
  //  [keys.SET_errorPartnerChangeHelp]: "translation_missing",
  //  [keys.SET_PCRNotEnabled]: "translation_missing",
  //  [keys.SET_standardBillNotice]: "translation_missing",
  //  [keys.SET_premiumBillNotice]: "translation_missing",
  //  [keys.SET_updateDetails]: "translation_missing",
  //  [keys.SET_generalInfo]: "translation_missing",
  //  [keys.SET_nameCompany]: "translation_missing",
  //  [keys.SET_locationCompany]: "translation_missing",
  //  [keys.SET_editDetails]: "translation_missing",
  //  [keys.SET_saveDetails]: "translation_missing",
  //  [keys.SET_checkYourInvoices]: "translation_missing",
  //  [keys.SET_invoiceId]: "translation_missing",
  //  [keys.SET_invoiceIssueAt]: "translation_missing",
  //  [keys.SET_inviteMember]: "translation_missing",
  //  [keys.SET_inviteMemberProject]: "translation_missing",
  //  [keys.SET_member]: "translation_missing",
  //  [keys.SET_companyMembers]: "translation_missing",
  //  [keys.SET_role]: "translation_missing",
  //  [keys.SET_informationPartner]: "translation_missing",
  //  [keys.SET_changePartner]: "translation_missing",
  //  [keys.SET_currentPartner]: "translation_missing",
  //  [keys.SET_currentPartnerInfo]: "translation_missing",
  //  [keys.SET_selectedPaymentMethod]: "translation_missing",
  //  [keys.SET_changePaymentMethod]: "translation_missing",
  //  [keys.SET_cardDetails]: "translation_missing",
  //  [keys.SET_cardHolder]: "translation_missing",
  //  [keys.SET_cardExpiryDate]: "translation_missing",
  //  [keys.SET_cardEndsWith]: "translation_missing",
  //  [keys.SET_card]: "translation_missing",
  //  [keys.SET_adAccountsDescription]: "translation_missing",
  //  [keys.SET_adAccountsEmptyTitle]: "translation_missing",
  //  [keys.SET_adAccountsEmptyDescription]: "translation_missing",
  //  [keys.SET_accountName]: "translation_missing",
  //  [keys.SET_accountID]: "translation_missing",
  //  [keys.SET_refreshData]: "translation_missing",
  //  [keys.SET_successfulRefreshedDataMsg]: "translation_missing",
  //  [keys.SET_refreshingDataInFlightMsg]: "translation_missing",
  // [keys.SET_noInvoices]: "translation_missing",
  // [keys.SET_noInvoicesDescription]: "translation_missing",
  // [keys.SET_name]: "translation_missing",
  // [keys.SET_email]: "translation_missing",
  // SET_paid: "translation_missing",
  // SET_unpaid: "translation_missing",
  // SET_error: "translation_missing",
  // SET_pending: "translation_missing",
  // SET_processing: "translation_missing",
  // SET_refunded: "translation_missing",
  // SET_refundTriggered: "translation_missing",
  // SET_partiallyRefunded: "translation_missing",
  // SET_partialRefundTriggered: "translation_missing",
  // SET_triggered: "translation_missing",
  // SET_unknownStatus: "translation_missing",
  // SET_status: "translation_missing",
  // [keys.SET_funds]: "translation_missing",
  // [keys.SET_aboutFunds]: "translation_missing",
  // [keys.SET_activity]: "translation_missing",
  // [keys.SET_aboutActivity]: "translation_missing",
  // [keys.SET_invoicePaymentRequired1]: "translation_missing",
  // [keys.SET_invoicePaymentRequired2]: "translation_missing",
  // [keys.SET_migrationSupport]: "translation_missing",
};

export default translate;
