// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.SUP_tickets]: "Support Tickets",
  [keys.SUP_id]: "ID",
  [keys.SUP_title]: "Titel",
  [keys.SUP_number]: "Nummer",
  [keys.SUP_status]: "Status",
  [keys.SUP_addComment]: "Kommentar hinzufügen",
  [keys.SUP_newTicket]: "Neues Ticket erstellen",
  [keys.SUP_contactSupport]: "Support kontaktieren",
  [keys.SUP_channelSupportHelptext]:
    "Bei Problemen oder Fragen bzgl. dieser Nummer kannst du unseren Support kontaktieren.",
  [keys.SUP_topic]: "Betreff",
  [keys.SUP_selectTopicError]: "Bitte wählen das Thema deiner Anfrage",
  [keys.SUP_selectWabaError]: "Bitte wähle das entsprechende Konto aus",
  [keys.SUP_selectNumber]: "Rufnummer auswählen",
  [keys.SUP_selectNumberError]: "Bitte wähle die betreffende Nummer aus",
  [keys.SUP_selectWabaFirst]: "Zuerst WhatsApp Business Account auswählen",
  [keys.SUP_messagePlaceholder]: "Wie können wir dir helfen?",
  [keys.SUP_other]: "Andere Telefonnummer",
  [keys.SUP_numberNotAvailable]: "Nummer nicht verfügbar",
  [keys.SUP_send]: "Nachricht senden",
  [keys.SUP_back]: "Zurück zur Übersicht",
  [keys.SUP_emptyStateTitle]: "Du hast noch kein Ticket erstellt.",
  [keys.SUP_emptyStateHelptext]:
    "Eröffne ein Ticket mit unserem Support-Team sofern du Fragen oder Probleme hast. Nachdem du ein Ticker erstellt hast kannst du hier die Antworten von unserem Team sowie den aktuellen Status deiner Anfrage einsehen.",
  [keys.SUP_success]:
    "Deine Nachricht wurde gesendet. Unser Support wird sich mit dir in Verbindung setzen.",
  [keys.SUP_successComment]:
    "Dein Kommentar wurde gesendet. Unser Support wird sich mit dir in Verbindung setzen.",
  [keys.SUP_commentOn]: "Kommentar erstellen",
  [keys.SUP_yourTickets]: "Deine Tickets",
  [keys.SUP_docsLink]: "360dialog Client Hub Dokumentation",
  [keys.SUP_docsLinkSubtitle]:
    "Erfahre mehr zu den Funktionen des 360dialog Client Hub",
  [keys.SUP_shortcutsLink]: "Tastatur-Kurzbefehle",
  [keys.SUP_shortcutsLinkSubtitle]:
    "Navigiere effizienter durch den 360dialog Client Hub",
  [keys.SUP_apiStatusLink]: "WhatsApp Business API Status",
  [keys.SUP_apiStatusLinkSubtitle]:
    "Offizieller API Status (bereitgestellt durch Meta)",
  [keys.SUP_supportLink]: "Support Center",
  [keys.SUP_supportLinkSubtitle]:
    "Zeige deine offenen Tickets an und erstelle neue Tickets, um Hilfe zu erhalten",
  [keys.SUP_openJiraLink]: "Ticket in Jira öffnen",
  [keys.SUP_busVerificationIssue]: "Problem mit der Business Verifizierung",
  [keys.SUP_newSubmission]: "Neueinreichung",
  [keys.SUP_unableToSubmit]: "Nummereinreichung nicht möglich",
  [keys.SUP_requestIVR]: "IVR Nummern anfragen",
  [keys.SUP_otherQuestion]: "Andere Frage",
  [keys.SUP_messagingNotWorking]:
    "Nachrichten werden nicht gesendet / empfangen",
  [keys.SUP_internalServerErr]: "Interner Server Fehler",
  [keys.SUP_sandboxSupport]: "Sandbox Support",
  [keys.SUP_hubAccess]: "Hub-Zugang",
  [keys.SUP_commercePolicyRejection]: "Berufung zur Commerce Policy",
  [keys.SUP_click2WhatsApp]: "Click2WhatsApp Anzeigen aktivieren",
  [keys.SUP_numberPorting]: "Nummerportierung",
  [keys.SUP_nameChange]: "Display-Namen ändern",
  [keys.SUP_nameRejected]: "Display-Name wurde abgelehnt",
  [keys.SUP_cancellation]: "Nummer löschen",
  [keys.SUP_onPremise]: "On-premise Support",
  [keys.SUP_paymentMethod]: "Zahlungsmethode",
  [keys.SUP_govAccount]: "Konto für Regierungsstelle beantragen",
  [keys.SUP_outsideWorkHours]:
    "Du hast ein Ticket außerhalb der Support-Team Arbeitszeiten eingereicht. Das Support-Team arbeitet von Montag bis Freitag von 2 Uhr bis 23 Uhr UTC+1. Wir werden in Kürze mit dir in Kontakt treten.",
  [keys.SUP_openStatusPage]: "Status-Seite öffnen",
  [keys.SUP_allSystemsNormal]: "Alle Systeme normal",
  [keys.SUP_expIssues]: "Vereinzelte Probleme",
  [keys.SUP_underMaintenance]: "Wartungsarbeiten",
  [keys.SUP_warningCreateTicket1]:
    "Achtung! Wir haben aktuell vereinzelte Probleme mit unserem System. Für weitere Details besuche bitte unsere <LINK>https://status.360dialog.com | Status-Seite<LINK>. Bitte sehe davon ab ein weiteres Ticket zu erstellen, sofern es sich auf die beschriebenen Probleme bezieht.",
  [keys.SUP_warningCreateTicket2]:
    "Für Updates zum Vorfall kannst du dich über die <LINK>https://status.360dialog.com | Status-Seite<LINK> anmelden.",
  [keys.SUP_statusLinkSubtitle]: "Infos zu aktuellen und vergangenen Vorfällen",
  [keys.SUP_messageLengthError]:
    "Nachricht muss mindestens 140 Zeichen lang sein",
  [keys.SUP_clickHereButtonLabel]: "Hier clicken",
  [keys.SUP_paymentMethods]: "Zahlungsmethoden",
  [keys.SUP_attachFile]: "Bilder hinzufügen",
  [keys.SUP_includeAllTickets]: "Zeige Tickets von allen Team-Mitgliedern",
  [keys.SUP_reporter]: "Ersteller",
  [keys.SUP_optionsLoading]: "Optionen werden geladen...",
  [keys.SUP_roadmap]: "Roadmap und Feature-Anfragen",
  [keys.SUP_roadmapSubtitle]:
    "Wirf einen Blick auf unsere Roadmap und erstelle Feature-Anfragen",
  [keys.SUP_inReview]: "in der Überprüfung",
  [keys.SUP_responseViaEmail]:
    "Bitte beachte, dass du deine Antwort per E-Mail erhalten wirst.",
  [keys.SUP_onboarding]: "Onboarding",
  [keys.SUP_onboardingDescription]:
    "Rufnummernregistrierung, Hinzufügen von Rufnummern, Rufnummernportierung, eingebettete Anmeldung usw...",
  [keys.SUP_problemWithMessaging]: "Probleme mit der Messaging-API",
  [keys.SUP_messagingProblemsDescription]:
    "Nachrichten kommen nicht an, Fehlermeldungen in der API, Verzögerungen bei der Nachrichtenübermittlung, Nummern werden getrennt usw...",
  [keys.SUP_accManagement]: "Kontoverwaltung",
  [keys.SUP_accManagementDescription]:
    "Meta Business Verification, Meta Geschäftsbedingungen, Handelsrichtlinien, Anzeigenamen, offizielle Geschäftskonten, Vorlagen, Nachrichtenlimits, Qualitätsstufen, Kostenübersicht, Guthaben, Partneränderungen usw...",
  [keys.SUP_offboarding]: "Offboarding",
  [keys.SUP_offboardingDescription]:
    "Stornierung, Kündigung, 2FA-Deaktivierung, Migration zu einem anderen BSP, Downgrades usw...",
  [keys.SUP_financeAndBilling]: "Finanzen & Abrechnung",
  [keys.SUP_financeAndBillingDescription]:
    "Rechnungsstellung, Abonnements, Erstattungen, Zahlungsmethoden usw...",
  [keys.SUP_otherQuestion]: "Andere Fragen",
  [keys.SUP_otherQuestionDescription]:
    "Alles andere, was nicht in den obigen Kategorien aufgeführt ist",
  [keys.SUP_visitStatusPage1]:
    "Wir haben derzeit Probleme mit unserem System. Für Details besuche bitte unsere <LINK>https://status.360dialog.com/ | Statusseite<LINK>.",
  [keys.SUP_visitStatusPage2]:
    "Bitte verzichte auf die Einreichung von Tickets zu den genannten Problemen. Über die <LINK>https://status.360dialog.com/ | Statusseite<LINK> kannst du Updates zu diesem Vorfall abonnieren.",
  //  [keys.SUP_useIntercom]: "translation_missing",
};

export default translate;
