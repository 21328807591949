// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.AUTH_login]: "Acceso",
  [keys.AUTH_forgot]: "¿Olvidó su contraseña?",
  [keys.AUTH_clickHere]: "Haga click aquí",
  [keys.AUTH_rightsReserved]: "©2021 Todos los derechos reservados.",
  [keys.AUTH_privacy]: "Privacidad",
  [keys.AUTH_imprint]: "Imprint",
  [keys.AUTH_reset]: "Rstablecer nueva contraseña",
  [keys.AUTH_resetHelptext]:
    "Introduzca la dirección de correo electrónico asociada con su cuenta y le mandaremos un email con instrucciones para establecer una nueva contraseña.",
  [keys.AUTH_resetButtonLabel]: "Enviar instrucciones",
  [keys.AUTH_resetError]:
    "Lo sentimos, hubo un error durante el proceso de cambio de contraseña. Inténtelo de nuevo en unos minutos.",
  [keys.AUTH_resetSuccess]:
    "¡Éxito! Hemos enviado las instrucciones para cambiar su contraseña a su email. Por favor, espere unos minutos si aún no ha recibido el email.",
  [keys.AUTH_alreadyHaveAccount]: "¿Ya tiene una cuenta?",
  [keys.AUTH_signup]: "Registrarse",
  [keys.AUTH_gethelp]: "¿Problemas para conectarse?",
  [keys.AUTH_getHelpLink]: "Pida ayuda",
  [keys.AUTH_clickAboveButtons]:
    "Al hacer clic en cualquiera de los botones anteriores para inscribirse, usted",
  [keys.AUTH_termsAndService]: "Términos de Servicio de 360dialog",
  [keys.AUTH_metaTerms]:
    "acepte los Términos de Servicio de <LINK>360dialog Terms of Service<LINK>, así como",
  [keys.AUTH_forWhatsApp]:
    "los términos del cliente <LINK>Meta client terms<LINK> para WhatsApp.",
  [keys.AUTH_create]: "Crea tu cuenta",
  [keys.AUTH_login360]: "Iniciar sesión en 360dialog",
  [keys.AUTH_email]: "Ingrese su email",
  [keys.AUTH_password]: "Contraseña",
  [keys.AUTH_loginEmail]: "Iniciar sesión con email",
  [keys.AUTH_continueEmail]: "Continuar con email",
  [keys.AUTH_signupTerms]:
    "Al registrarte, aceptas los <LINK>Términos de Servicio",
  [keys.AUTH_checkEmail]: "Compruebe su email",
  [keys.AUTH_create360Account]: "Crea tu cuenta en 360dialog",
  [keys.AUTH_otpTextOne]:
    "• Para continuar, primero debe verificar su correo electrónico",
  [keys.AUTH_otpTextTwo]: "• Se ha enviado un código OTP a <EMAIL>",
  [keys.AUTH_otpTextThree]:
    "• Ingrese el código OTP a continuación para verificar su dirección de correo electrónico ",
  [keys.AUTH_otp]: "One Time Passcode",
  [keys.AUTH_enterOtp]: "Ingrese su código de acceso aquí ",
  [keys.AUTH_namePlaceholder]: "Ingrese su nombre y apellido",
  [keys.AUTH_nameLabel]: "Nombre y apellido",
  [keys.AUTH_createAccountEmail]: "Crear cuenta con email",
  [keys.AUTH_otpWindowMessage]:
    "Por favor, asegúrese de no cerrar esta ventana. Sus datos no se guardarán hasta que envíe el código de verificación.",
  [keys.AUTH_createAccount]: "Crear cuenta",
  [keys.AUTH_signupPriceList]:
    "y para la <LINK>Lista de precios de 360dialog<LINK>.",
  //  [keys.AUTH_visitNewHub]: "translation_missing",
  //  [keys.AUTH_newHubInfoMessage]: "translation_missing",
  [keys.AUTH_governmentAgency]: "Agencia gubernamental",
  [keys.AUTH_organizationIRepresent]: "La organización que represento es una…",
  [keys.AUTH_chooseFromList]: "Seleccione de la lista",
  //  [keys.AUTH_governmentalAgencyApproval]: "translation_missing",
  [keys.AUTH_companyEmail]: "Correo de empresa",
  [keys.AUTH_termsOfServiceSignUp]:
    "Al crear una cuenta, aceptas nuestros <LINK>términos de servicio<LINK>",
  [keys.AUTH_newAccount]: "Nueva cuenta",
  [keys.AUTH_dontHaveAccount]: "¿No tienes cuenta?",
  //  [keys.AUTH_signIn]: "translation_missing",
  [keys.AUTH_resetHere]: "Recuperar",
  //  [keys.AUTH_twoFactorAuth]: "translation_missing",
  //  [keys.AUTH_OTPSent]: "translation_missing",
  //  [keys.AUTH_ResetPasswordMessage]: "translation_missing",
  //  [keys.AUTH_wantToCancel]: "translation_missing",
  //  [keys.AUTH_backToSignIn]: "translation_missing",
  //  [keys.AUTH_emailSentTo]: "translation_missing",
  //  [keys.AUTH_checkInbox]: "translation_missing",
  //  [keys.AUTH_GoBackToSignIn]: "translation_missing",
  //  [keys.AUTH_logOut]: "translation_missing",
  [keys.AUTH_min8Characters]: "Mínimo 8 caracteres",
  //  [keys.AUTH_noFacebookEmail]: "translation_missing",
  [keys.AUTH_logIn]: "Acceder",
  //  [keys.AUTH_instructions]: "translation_missing",
  [keys.AUTH_passwordNew]: "Contraseña",
  //  [keys.AUTH_emailVerification]: "translation_missing",
  [keys.AUTH_FBLogin]: "Acceder con Facebook",
  //  [keys.AUTH_passwordSuccess]: "translation_missing",
  //  [keys.AUTH_passwordError]: "translation_missing",
  //  [keys.AUTH_otpTitle]: "translation_missing",
  //  [keys.AUTH_otpDescription]: "translation_missing",
};

export default translate;
