import * as Types from "./partnerChannels.types";
import * as Channel from "../channels/channels.mapper";
import * as Client from "../clients/clients.mapper";
import * as Project from "../projects/project.mapper";
import * as WabaAccount from "../wabaAccount/wabaAccount.mapper";
import * as WabaPolicyViolations from "../wabaAccount/wabaPolicyViolations.mapper";
import * as Integration from "../waba/waba.mapper";
import nullWhenEmptyStringOrUndefinied from "@/utilities/nullWhenEmptyStringOrUndefinied";
import nullWhenBoolean from "@/utilities/nullWhenBoolean";
import isObject from "@/utilities/isObject";
import { unpackPartner } from "../partners/partners.mapper";

export const unpackPartnerChannels = (
  response: Types.IServerResponsePartnerChannels
): Types.IUIResponsePartnerChannels => ({
  total: response.total,
  partnerChannels: response.partner_channels.map((partnerChannel) =>
    unpackPartnerChannel(partnerChannel)
  ),
  offset: response.offset,
  limit: response.limit,
  count: response.count,
});

export const unpackPartnerChannel = (
  response: Types.IServerResponsePartnerChannel
): Types.IUIResponsePartnerChannel => ({
  wabaAccountId: response.waba_account_id,
  type: response.type,
  id: response.id,
  redirectUrl: response.redirect_url ?? null,
  partnerId: response.partner_id,
  clientId: response.client_id,
  projectId: response.project_id,
  consents: Channel.unpackConsents(response.consents),
  status: response.status,
  createdAt: response.created_at,
  modifiedAt: response.modified_at,
  integrationId: response.integration_id || "",
  externalId: response.external_id || "",
  profileInfo: Channel.unpackProfileInfo(response.profile_info),
  setupInfo: Channel.unpackSetupInfo(response.setup_info),
  client: Client.unpackSingleClient(response.client),
  project: Project.unpackProject(response.project),
  wabaAccount: WabaAccount.unpackWabaAccount(response.waba_account),
  wabaPolicyViolations: response.waba_policy_violations
    ? response.waba_policy_violations.map((violation) =>
        WabaPolicyViolations.unpackWabaPolicyViolation(violation)
      )
    : null,
  integration: response.integration
    ? Integration.unpackIntegration(response.integration)
    : undefined,
  billingStartedAt: response.billing_started_at,
  version: response.version,
  accountMode: response.account_mode || "",
  cancelledAt: response.cancelled_at ?? null,
  terminatedAt: response.terminated_at ?? null,
  isMigrated: response.is_migrated || false,
  metaStatus: response.meta_status || "",
  currentLimit: response.current_limit,
  currentQualityRating: response.current_quality_rating,
  hasInbox: response.has_inbox || false,
  isOba: response.is_oba,
  settings: response.settings,
  lastUpgradeAt: response.last_upgrade_at,
  lastDowngradeAt: response.last_downgrade_at,
  currentBalance: response.current_balance,
});

export const unpackPartnerChannelsV1 = (
  response: Types.IServerResponsePartnerChannelsV1
): Types.IUIResponsePartnerChannelsV1 => ({
  total: response.total,
  partnerChannels: response.partner_channels.map((partnerChannel) =>
    unpackPartnerChannelV1(partnerChannel)
  ),
});

export const unpackPartnerChannelV1 = (
  response: Types.IServerResponsePartnerChannelV1
): Types.IUIResponsePartnerChannelV1 => {
  const { partner, ...rest } = response;
  return {
    ...unpackPartnerChannel(rest),
    partner: unpackPartner(partner),
  };
};

export const unpackPartnerChannelFromSubmit = (response: {
  client_id: string;
  token: string;
}): {
  clientId: string;
  token: string;
} => ({
  clientId: response.client_id,
  token: response.token,
});

export const packPartnerChannelFromPipe = (
  request: Types.CreatePipeFlowFullForm
): Types.ServerRequestCreatePartnerChannel => ({
  fb_business_id: request.wabaAccount.fbBusinessId,
  phone_number: request.setupInformation.phoneNumber,
  client_name: isObject(request.client.clientName)
    ? request.client.clientName.value
    : request.client.clientName || "",
  client_contact_info: request.client.contactInfo
    ? {
        city: request.client.contactInfo?.city,
        street_name: request.client.contactInfo?.streetName,
        email: request.client.contactInfo?.email,
        zip_code: request.client.contactInfo?.zipCode,
        country: request.client.contactInfo?.country,
        full_name: request.client.contactInfo.fullName,
      }
    : null,
  default_language: request.setupInformation.defaultLanguage?.value || "",
  verification_method: request.setupSettings.verificationMethod,
  license_model: request.apiConnector.licenseModel,
  was_in_use: nullWhenBoolean(request.setupSettings.wasInUse === "true"),
  ivr: nullWhenBoolean(request.setupSettings.ivr === "true"),
  api_user: nullWhenEmptyStringOrUndefinied(request.apiConnector.apiUser),
});

export const packPartnerChannelFromSubmit = (
  request: any
): Types.ServerRequestCreatePartnerChannelFromSubmit => ({
  fb_business_id: request.fbBusinessId,
  phone_number: request.phoneNumber,
  client_name: request.clientName,
  client_contact_info: {
    email: request.email,
    full_name: request.fullName,
  },
  default_language: request?.defaultLanguage?.value || "en",
  timezone_id: request?.timezone?.value || "0",
  ivr: nullWhenBoolean(request.ivr === "true"),
  verification_method: "sms",
  license_model: "cloud",
  was_in_use: false,
  api_user: "",
  consents: request.consents,
});

export const packPartnerChannelFromMigrate = (
  request: any
): Types.ServerPartnerChannelFromMigrateWaba => ({
  fb_business_id: request.fbBusinessId,
  phone_number: request.phoneNumber,
  client_name: request.companyName,
  coupon: request.coupon || "",
  user_name: request.fullName,
  user_email: request.email,
  user_password: request.password || "",
  consents: request.consents,
});

export const unpackPartnerChannelFromMigrate = (response: {
  client_id: string;
  token: string;
  access_token: string;
  expires_in: string;
  token_type: string;
  waba_account_id: string;
  channel_id: string;
}): {
  clientId: string;
  accessToken: string;
  tokenType: string;
  expiresIn: string;
  paymentIntentToken: string;
  wabaAccountId: string;
  channelId: string;
} => ({
  accessToken: response.access_token,
  clientId: response.client_id,
  tokenType: response.token_type,
  expiresIn: response.expires_in,
  paymentIntentToken: response.token,
  wabaAccountId: response.waba_account_id,
  channelId: response.channel_id,
});
