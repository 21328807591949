import * as React from "react";
const Logo360_3 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={28}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M10.343 27.478c-5.091 0-9.239-3.43-10.083-8.343l-.26-1.5h3.863l.214 1.018c.628 2.985 3.146 4.99 6.266 4.99 3.63 0 6.369-2.776 6.369-6.46 0-3.647-2.739-6.395-6.369-6.395-.68 0-1.201.037-1.59.114l-4.023.788 2.854-2.94c.574-.592 1.955-1.983 3.369-3.405l1.465-1.476H1.963V0h19.746l-7.586 7.64c3.9 1.43 6.464 5.08 6.464 9.543 0 5.869-4.404 10.295-10.244 10.295Z"
    />
  </svg>
);
export default Logo360_3;
