// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.NUM_manageAccount]: "Manage WhatsApp Business account",
  [keys.NUM_details]: "Details",
  [keys.NUM_accountName]: "Account name",
  [keys.NUM_dataStorageRegions]: "Data Storage Regions",
  [keys.NUM_cloudApiNumbers]: "Only available for Cloud API Numbers",
  [keys.NUM_qualityRating]: "Quality Rating",
  [keys.NUM_low]: "Low",
  [keys.NUM_medium]: "Medium",
  [keys.NUM_high]: "High",
  [keys.NUM_messageLimit]: "Message Limit",
  [keys.NUM_newConversationsPerDay]: "new conversations per day",
  [keys.NUM_unlimited]: "Unlimited",
};

export default translate;
