import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const TitleWrapper = styled.div`
  position: relative;
  font-weight: var(--font-weight-medium);
  font-size: var(--font-size-m);
  color: var(--whale-light);
  margin-top: 0.8rem;
`;

export const Dots = styled.div`
  position: absolute;
  top: 0;
  left: 13.6rem;
  :after {
    display: inline-block;
    animation: dots steps(1, end) 2.5s infinite;
    content: "";
  }
  @keyframes dots {
    0% {
      content: "";
    }
    10% {
      content: ".";
    }
    20% {
      content: "..";
    }
    30%,
    70% {
      content: "...";
    }
    75% {
      content: "..";
    }
    80% {
      content: ".";
    }
    85%,
    100% {
      content: "";
    }
  }
`;

export const CircleWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 13rem;
  width: 13rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Circle = styled.div`
  background-color: var(--perf-green);
  height: 11rem;
  width: 11rem;
  border-radius: 37% 0 37% 0;
  animation-duration: 3s;
  animation-name: expand;
  animation-iteration-count: infinite;
  @keyframes expand {
    0% {
      height: 11rem;
      width: 11rem;
    }
    12% {
      height: 11.5rem;
      width: 11.5rem;
    }
    20% {
      height: 12rem;
      width: 12rem;
    }
    28% {
      height: 11rem;
      width: 11rem;
    }
  }
`;

export const Holder3 = styled.div`
  position: absolute;
  right: -30rem;
  animation-duration: 3s;
  animation-name: move3;
  animation-iteration-count: infinite;
  @keyframes move3 {
    0% {
      right: -30rem;
      opacity: 0;
    }
    5%,
    80% {
      right: 9rem;
      opacity: 1;
    }
    99% {
      right: 36rem;
      opacity: 0;
    }
    100% {
      right: -30rem;
    }
  }
`;

export const Holder6 = styled.div`
  position: absolute;
  right: -32rem;
  animation-duration: 3s;
  animation-name: move6;
  animation-iteration-count: infinite;
  @keyframes move6 {
    0% {
      right: -32rem;
      opacity: 0;
    }
    15%,
    85% {
      right: 6.9rem;
      opacity: 1;
    }
    99% {
      right: 32rem;
      opacity: 0;
    }
    100% {
      right: -32rem;
    }
  }
`;

export const Holder0 = styled.div`
  position: absolute;
  right: -34rem;
  animation-duration: 3s;
  animation-name: move0;
  animation-iteration-count: infinite;
  @keyframes move0 {
    0% {
      right: -34rem;
      opacity: 0;
    }
    25%,
    90% {
      right: 4.1rem;
      opacity: 1;
    }
    99% {
      right: 30rem;
      opacity: 0;
    }
    100% {
      right: -34rem;
    }
  }
`;

export const HolderD = styled.div`
  position: absolute;
  right: -36rem;
  animation-duration: 3s;
  animation-name: moveD;
  animation-iteration-count: infinite;
  @keyframes moveD {
    0% {
      right: -36rem;
      opacity: 0;
    }
    30%,
    95% {
      right: 1.7rem;
      opacity: 1;
    }
    99% {
      right: 28rem;
      opacity: 0;
    }
    100% {
      right: -36rem;
    }
  }
`;
