// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.BILL_header]: "translation_missing",
  //  [keys.BILL_balance]: "translation_missing",
  //  [keys.BILL_addFunds]: "translation_missing",
  //  [keys.BILL_addFundsPopup]: "translation_missing",
  //  [keys.BILL_addedFundsDescription]: "translation_missing",
  //  [keys.BILL_addFundsPopupQuestion]: "translation_missing",
  //  [keys.BILL_addFundsPopupTooltip]: "translation_missing",
  //  [keys.BILL_costBreakdown]: "translation_missing",
  //  [keys.BILL_estTemplateCost]: "translation_missing",
  //  [keys.BILL_transFees]: "translation_missing",
  //  [keys.BILL_subtotal]: "translation_missing",
  //  [keys.BILL_subAndPay]: "translation_missing",
  //  [keys.BILL_monthlyUsage]: "translation_missing",
  //  [keys.BILL_numTemplatesSent]: "translation_missing",
  //  [keys.BILL_costsByTemplates]: "translation_missing",
  //  [keys.BILL_noData]: "translation_missing",
  //  [keys.BILL_filterByNum]: "translation_missing",
  //  [keys.BILL_selectAll]: "translation_missing",
  //  [keys.BILL_numberNotLive]: "translation_missing",
  //  [keys.BILL_noBillingCountry]: "translation_missing",
  //  [keys.BILL_amountDue]: "translation_missing",
  //  [keys.BILL_notApplicable]: "translation_missing",
  //  [keys.BILL_vat]: "translation_missing",
  //  [keys.BILL_addFundsSectionMsg]: "translation_missing",
  //  [keys.BILL_autoRenewalSuccess]: "translation_missing",
  //  [keys.BILL_thresholdChangeSuccess]: "translation_missing",
  //  [keys.BILL_amountChangeSuccess]: "translation_missing",
  //  [keys.BILL_renewalHeading]: "translation_missing",
  //  [keys.BILL_renewalTooltip]: "translation_missing",
  //  [keys.BILL_thresholdSection]: "translation_missing",
  //  [keys.BILL_excludingTransactionFees]: "translation_missing",
  //  [keys.BILL_autoRenewal]: "translation_missing",
  //  [keys.BILL_autoRenewalAmount]: "translation_missing",
  //  [keys.BILL_oneOffCharge]: "translation_missing",
  //  [keys.BILL_changeSettings]: "translation_missing",
  //  [keys.BILL_renewalPopupHeading]: "translation_missing",
  //  [keys.BILL_renewalPopupSectionMsg]: "translation_missing",
  //  [keys.BILL_submitChanges]: "translation_missing",
  //  [keys.BILL_balanceBreakDown]: "translation_missing",
  //  [keys.BILL_selectNumberAction]: "translation_missing",
  //  [keys.BILL_estimateCosts]: "translation_missing",
  //  [keys.BILL_confirmCheckout]: "translation_missing",
  //  [keys.BILL_selectAction]: "translation_missing",
  //  [keys.BILL_addOneOffFunds]: "translation_missing",
  //  [keys.BILL_addAutorenewalSettings]: "translation_missing",
  //  [keys.BILL_autoRenewalSectionMsg]: "translation_missing",
  //  [keys.BILL_changeAutoRenewalThreshold]: "translation_missing",
  //  [keys.BILL_thresholdAmount]: "translation_missing",
  //  [keys.BILL_thresholdRenewalMsg]: "translation_missing",
  //  [keys.BILL_processingPayment]: "translation_missing",
  //  [keys.BILL_businessInitiatedQuestion]: "translation_missing",
  //  [keys.BILL_userInitiatedQuestion]: "translation_missing",
  //  [keys.BILL_estimatedConversationCost]: "translation_missing",
  //  [keys.BILL_previousMonth]: "translation_missing",
  //  [keys.BILL_addFundsNotAllowed]: "translation_missing",
  //  [keys.BILL_othersCombined]: "translation_missing",
  //  [keys.BILL_approxCharges]: "translation_missing",
  //  [keys.BILL_allConversations]: "translation_missing",
  //  [keys.BILL_insights]: "translation_missing",
  //  [keys.BILL_insightsAndBilling]: "translation_missing",
  //  [keys.BILL_businessInitiatedAmountTooltip]: "translation_missing",
  //  [keys.BILL_userInitiatedAmountTooltip]: "translation_missing",
  //  [keys.BILL_costBreakdownTooltip]: "translation_missing",
  //  [keys.BILL_customize]: "translation_missing",
  //  [keys.BILL_noCharges]: "translation_missing",
  //  [keys.BILL_fundsWereAdded]: "translation_missing",
  //  [keys.BILL_enabled]: "translation_missing",
  //  [keys.BILL_disabled]: "translation_missing",
  //  [keys.BILL_vat2]: "translation_missing",
  //  [keys.BILL_zeroBalanceText_one]: "translation_missing",
  //  [keys.BILL_zeroBalanceText_other]: "translation_missing",
  //  [keys.BILL_lowBalanceText_one]: "translation_missing",
  //  [keys.BILL_lowBalanceText_other]: "translation_missing",
  //  [keys.BILL_automatic]: "translation_missing",
  //  [keys.BILL_manageFunds]: "translation_missing",
  //  [keys.BILL_refund]: "translation_missing",
  //  [keys.BILL_refundAccountTitle]: "translation_missing",
  //  [keys.BILL_refundingDetails]: "translation_missing",
  //  [keys.BILL_channelFunds]: "translation_missing",
  //  [keys.BILL_noPendingBalance]: "translation_missing",
  //  [keys.BILL_totalAmountToRefund]: "translation_missing",
  //  [keys.BILL_refundInfomationText]: "translation_missing",
  //  [keys.BILL_refundInformationText2]: "translation_missing",
  //  [keys.BILL_fundsBalance]: "translation_missing",
  //  [keys.BILL_automaticAutoRenewal]: "translation_missing",
  //  [keys.BILL_descriptionText1]: "translation_missing",
  //  [keys.BILL_descriptionText2]: "translation_missing",
  //  [keys.BILL_descriptionText3]: "translation_missing",
  //  [keys.BILL_threshold]: "translation_missing",
  //  [keys.BILL_autoMaticSettingMessage]: "translation_missing",
  //  [keys.BILL_setAutomaticRenewal]: "translation_missing",
  //  [keys.BILL_automaticTooltip]: "translation_missing",
  //  [keys.BILL_enableAutoSettingsMessage]: "translation_missing",
  //  [keys.BILL_disableAutoSettingsMessage]: "translation_missing",
  //  [keys.BILL_AutoSettingsErrorMessage]: "translation_missing",
  //  [keys.BILL_refundSuccessNotification]: "translation_missing",
  //  [keys.BILL_autoRenewalDisabled]: "translation_missing",
  //  [keys.BILL_offOptionTooltip]: "translation_missing",
  //  [keys.BILL_manualOptionTooltip]: "translation_missing",
  //  [keys.BILL_smartOptionTooltip]: "translation_missing",
  //  [keys.BILL_paymentSucceeded]: "translation_missing",
  //  [keys.BILL_paymentFailed]: "translation_missing",
  //  [keys.BILL_radioDisabledTooltipText]: "translation_missing",
  //  [keys.BILL_topupDurationMessage]: "translation_missing",
  //  [keys.BILL_billingOptions]: "translation_missing",
  //  [keys.BILL_fundNegativeBalance]: "translation_missing",
  //  [keys.BILL_noRefundFunds]: "translation_missing",
  //  [keys.BILL_noAddingFunds]: "translation_missing",
  //  [keys.BILL_pendingBalanceBilling]: "translation_missing",
  //  [keys.BILL_refundAccount]: "translation_missing",
  //  [keys.BILL_negativeBalance]: "translation_missing",
  //  [keys.BILL_negativeBalanceMessage]: "translation_missing",
  //  [keys.BILL_totalPendingCharge]: "translation_missing",
  //  [keys.BILL_addFundsProceed]: "translation_missing",
  //  [keys.BILL_clickToManageFunds]: "translation_missing",
  //  [keys.BILL_billingInformation]: "translation_missing",
  //  [keys.BILL_billingInfoName]: "translation_missing",
  //  [keys.BILL_billingInfoEmail]: "translation_missing",
  //  [keys.BILL_billingInfoAddress]: "translation_missing",
  //  [keys.BILL_billingInfoVatId]: "translation_missing",
  //  [keys.BILL_billingInfoEdit]: "translation_missing",
  //  [keys.BILL_invoices]: "translation_missing",
  //  [keys.BILL_payInvoice]: "translation_missing",
  // [keys.BILL_unpaidInvoiceTooltip]: "translation_missing",
  //  [keys.BILL_invoiceViewMore]: "translation_missing",
  //  [keys.BILL_cloudApiPlan]: "translation_missing",
  //  [keys.BILL_partnerAccess]: "translation_missing",
  //  [keys.BILL_userInitiatedConversations]: "translation_missing",
  //  [keys.BILL_withRegularSupport]: "translation_missing",
  //  [keys.BILL_stripeBillingOptionsMissing]: "translation_missing",
  //  [keys.BILL_requestRefund]: "translation_missing",
  //  [keys.BILL_fundsAmount]: "translation_missing",
  //  [keys.BILL_fundsAmountDescription]: "translation_missing",
  //  [keys.BILL_estimatedConversations]: "translation_missing",
  //  [keys.BILL_selectedFundsAmount]: "translation_missing",
  //  [keys.BILL_costBreakdownDescriptionOneTime]: "translation_missing",
  //  [keys.BILL_costBreakdownDescriptionAutoRenewal]: "translation_missing",
  //  [keys.BILL_keepInMind]: "translation_missing",
  //  [keys.BILL_keepInMindList1]: "translation_missing",
  //  [keys.BILL_keepInMindList2]: "translation_missing",
  //  [keys.BILL_breakdown]: "translation_missing",
  //  [keys.BILL_provideFundsAmount]: "translation_missing",
  //  [keys.BILL_allYourNumbersWillContinue]: "translation_missing",
  //  [keys.BILL_allNumbersThatWereUpgraded]: "translation_missing",
  //  [keys.BILL_total]: "translation_missing",
  //  [keys.BILL_billingInformationDesc]: "translation_missing",
  //  [keys.BILL_checkAndDownloadInvoices]: "translation_missing",
  //  [keys.BILL_noInvoiceInfo]: "translation_missing",
  //  [keys.BILL_currentBalance]: "translation_missing",
  //  [keys.BILL_spentLastMonth]: "translation_missing",
  //  [keys.BILL_fundsManager]: "translation_missing",
  //  [keys.BILL_autoRenewalInfo]: "translation_missing",
  //  [keys.BILL_setAutomaticFundingRenewal]: "translation_missing",
  //  [keys.BILL_billingInfoUpdated]: "translation_missing",
  //  [keys.BILL_noBillingInfoAddedInfo]: "translation_missing",
  //  [keys.BILL_addBillingDetails]: "translation_missing",
  //  [keys.BILL_paymentDetails]: "translation_missing",
  //  [keys.BILL_update]: "translation_missing",
  //  [keys.BILL_expire]: "translation_missing",
  //  [keys.BILL_holder]: "translation_missing",
  //  [keys.BILL_noPaymentDetailsAddedInfo]: "translation_missing",
  //  [keys.BILL_nextBillingInfo]: "translation_missing",
  //  [keys.BILL_billingDetails]: "translation_missing",
  //  [keys.BILL_billingDetailsFormDesc]: "translation_missing",
  //  [keys.BILL_payPendingInvoices]: "translation_missing",
  //  [keys.BILL_updateAutoRechargeTitle]: "translation_missing",
  //  [keys.BILL_updateAutoRechargeDesc]: "translation_missing",
  //  [keys.BILL_thresholdAmountErrorMessage]: "translation_missing",
  //  [keys.BILL_autoRenewalAmountDesc]: "translation_missing",
  //  [keys.BILL_thresholdAmountDesc]: "translation_missing",
  //  [keys.BILL_paymentInformation]: "translation_missing",
  //  [keys.BILL_changeMethod]: "translation_missing",
  //  [keys.BILL_addNewPaymentMethod]: "translation_missing",
  //  [keys.BILL_radioDisabledTooltipText]: "translation_missing",
  //  [keys.BILL_fundNegativeBalance]: "translation_missing",
  //  [keys.BILL_unpaidInvoice1]: "translation_missing",
  //  [keys.BILL_unpaidInvoice2]: "translation_missing",
};

export default translate;
