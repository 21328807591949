// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.ERR_templateRenameExists]:
    "Can't rename template. A template with this name already exists. Please specify another name.",
  [keys.ERR_noStripeCustomer]:
    "Couldn't open billing options. Your billing account wasn't set up yet. Please contact support to do so.",
  [keys.ERR_weAreSorry]: "We're sorry",
  [keys.ERR_internalIssuesTeamNotified]:
    "It looks like we're having some internal issues. Our team has been notified. If you'd like to help, you can describe what happened below.",
  [keys.ERR_reloadPage]: "Reload page",
  [keys.ERR_letUsKnowWhatHappened]: "Let us know what happened",
  [keys.ERR_failedToGetCountry]: "Failed to get country",
  [keys.ERR_errorLoadingPaymentNotification]:
    "Error while loading PaymentNotification",
  [keys.ERR_userNotFound]:
    "We could not find a user with this email address. Please check again. If you want to sign up for a new account reach out to your integration partner or visit 360dialog.com",
  [keys.ERR_partnerNotPermitted]:
    "Sorry, given partner is not permitted to use embedded signup. Please contact us",
  [keys.ERR_inputFields]: "Error in input fields",
  [keys.ERR_fbConnectionError]:
    "Something went wrong while trying to connect your Meta account.",
  [keys.ERR_fbNoRegisteredEmail]:
    "Your account does not have an email registered, please add one to your Meta account.",
  [keys.ERR_userNoExists]:
    "This user doesn't exist. Please sign up via your partner's app or 360dialog.com",
  [keys.ERR_addFundsNumber]: "Cannot add funds to error numbers",
  [keys.ERR_autoRenewalNumber]:
    "Cannot edit auto-renewal settings to error numbers",
  [keys.ERR_stripeErrorNote]:
    "We encountered an error while loading stripe scripts. Please reload the page and try again.",
  [keys.ERR_filterOptionsErrorNote]:
    "We encountered an error while loading language and timezone filter options. Please reload the page and try again.",
  [keys.ERR_publicDataErrorText]:
    "The page could not be loaded correctly. Please try again by reloading the page. {{break}} In case this issue persists, please review our status page via {{statusPageLink}} or contact our support.",
  [keys.ERR_somethingUnexpected]:
    "Something unexpected happened, please try again later",
  [keys.ERR_phoneRegistrationError]:
    "Verification code failed to set during registration finish stage. Make sure code is valid and try again.",
  [keys.ERR_userWithNoClientPermissionError]:
    "The process could not be completed. Your user is lacking the permission to act on behalf of this client account. Please make sure you're visiting the correct URL including the correct client ID, which belongs to your user. In doubt sign out and back in again using a private browser window.",
  [keys.ERR_somethingWentWrong]:
    "Failing to load your data, please refresh the page, if the error still persists please contact support.",
};

export default translate;
