// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.WIZ_continueLabelWithHelp]: "To continue, finish the required steps",
  [keys.WIZ_billingPlanTitle]: "Select a plan",
  [keys.WIZ_billingMethodTitle]: "Add payment details",
  [keys.WIZ_billingTermsTitle]: "Accept terms & conditions",
  [keys.WIZ_paymentMethodTitleStep1]: "1. Select payment method",
  [keys.WIZ_paymentMethodTitleStep2]: "2. Checkout",
  [keys.WIZ_paymentMethodCountrySelectLabel]:
    "Select your country to reveal available payment methods.",
  [keys.WIZ_termsTitle]: "360dialog's terms and conditions:",
  [keys.WIZ_createNumberTitle]:
    "Create WhatsApp Business Account and connect number",
  [keys.WIZ_numberWasSetUp]: "Your number <NUMBER> was successfully set up.",
  [keys.WIZ_connectInboxTitle]: "Finish your onboarding with get.chat",
  [keys.WIZ_connectInboxText]:
    "To set up your get.chat inbox, please follow the instructions from the email, which you should've received by now. Your WhatsApp Business Account is set up and already connected, so that you can immediately start using the inbox.",
  [keys.WIZ_goToHubButtonLabel]: "Continue to the 360dialog Client Hub",
  [keys.WIZ_channelPageCheckboxText1]: "👤 You have a personal Meta account.",
  [keys.WIZ_channelPageCheckboxText2]:
    "📞 The number, which you will be connecting is not currently in use with another WhatsApp Account.",
  [keys.WIZ_channelPageCheckboxText3]:
    "📲 You can verify your ownership of the number by receiving SMS or international phone calls.",
  [keys.WIZ_channelPageCheckboxText4]:
    "🖋️ You already have a Meta business account, which is verified.",
  [keys.WIZ_channelPageSubtitle1]:
    "In the next step you need to set up your accounts by using your Meta credentials and following the provided steps.",
  [keys.WIZ_channelPageSubtitle2]:
    "Afterwards we will automatically set everything up for you and you will be able to use the WhatsApp Business API.",
  [keys.WIZ_waitingText1]:
    "We’re currently setting up your own server instance, so you will be ready to start using the WhatsApp Business API in a few minutes.",
  [keys.WIZ_waitingText2]: "Starting your hosted WhatsApp Business API client.",
  [keys.WIZ_addNumberText]:
    "Optionally, you can also <BUTTON>add a new number right now<BUTTON>.",
  [keys.WIZ_channelTitle]: "Make sure you fulfill the following requirements.",
  [keys.WIZ_titleSpecifiedPermission]:
    "The partner is requesting permission to access the WhatsApp Business API for the following number:",
  [keys.WIZ_titleUnspecifiedPermission]:
    "Select the numbers, for which you want to give permission to the partner to access the WhatsApp Business API.",
  [keys.WIZ_selectedCount]: "Selected <NUMBER> numbers",
  [keys.WIZ_numbers]: "Numbers",
  [keys.WIZ_selectNumbers]: "Select numbers",
  [keys.WIZ_noPendingChannels]:
    "There are no channels pending approval for this client",
  [keys.WIZ_verifyOwnership]: "Verify ownership",
  [keys.WIZ_somethingWrong]:
    "Something went wrong? Contact yout partner for clarification",
  [keys.WIZ_NumberVerificationTitle]: "Number ownership verification",
  [keys.WIZ_NumberVerificationDesc]:
    "Your partner {{partnerName}} wishes to grant you access to the phone numbers listed below. You will have the ability to manage them and gain insights through our 360dialog Client Hub. Please verify the ownership of the numbers.",
};

export default translate;
