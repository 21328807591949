// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.SET_partnerName]: "Integrations-Partner",
  [keys.SET_partnerPermissions]: "Partner-Zugriff",
  [keys.SET_selectTitle]:
    "Wähle die Nummern, für die du deinem Partner Zugriff auf die WhatsApp Business API geben möchtest.",
  [keys.SET_saveButton]: "Änderungen speichern",
  [keys.SET_successGrantPermissions]:
    "Du hast erfolgreich die Zugriffsrechte aktualisiert",
  [keys.SET_Inboxes]: "Inboxen",
  [keys.SET_selectNumberInbox]:
    "Wähle die Nummern, die du mit einer get.chat Inbox verbinden möchtest.",
  [keys.SET_requestPartnerChange]: "Antrag auf Wechsel des Partners",
  [keys.SET_requestApproved]:
    "Antrag auf Partnerwechsel genehmigt - Migration am {{Datum}}",
  [keys.SET_requestPending]: "Antrag auf Partnerwechsel ausstehend",
  [keys.SET_selectNewPartner]: "Neuen Partner auswählen",
  [keys.SET_confirmTerms]: "Bestätige die Bedingungen",
  [keys.SET_submitPartnerChangeRequest]: "Antrag auf Partnerwechsel einreichen",
  [keys.SET_addPartnerIdMessage]:
    "Bitte gib die Partner-ID des neuen Integrationspartners an, zu dem du migrieren möchtest. Bitte vergewissere dich, dass du mit deinem neuen Partner in Kontakt stehst, bevor du diesen Antrag einreichst. Er wird dir diese ID ebenfalls mitteilen können.",
  [keys.SET_enterPartnerId]: "Partner-ID eingeben",
  [keys.SET_notValidPartnerId]: "Dies ist keine gültige Partner-ID",
  [keys.SET_partnerIdFound]: "Partner-ID gefunden",
  [keys.SET_newPartnerText]: "Dein neuer Partner ist",
  [keys.SET_currentPartnerText]: "Dies ist dein aktueller Partner",
  [keys.SET_currencyMismatchErrorText]:
    "Die Rechnungswährung des neuen Partners stimmt nicht mit der Währung deines/deiner WhatsApp Business Account(s) überein. Bitte verwende das untenstehende Formular, um unserem Support eine Nachricht zur Währungsumstellung zukommen zu lassen.",
  [keys.SET_messageSupportLabel]: "Deine Nachricht an unseren Support",
  [keys.SET_messageSupportPlaceholder]: "Wie können wir dir helfen?",
  [keys.SET_billingPlanDesc1]:
    "Unbegrenzte eingehende und ausgehende WhatsApp-Session-Nachrichten",
  [keys.SET_billingPlanDesc2]:
    "Ausgehende Vorlagennachrichten gemäß der WhatsApp-Preisliste. Kein Aufschlag. ¹",
  [keys.SET_billingPlanDesc3]: ">98% Service-Level",
  [keys.SET_billingPlanDesc4]:
    "Offizieller WhatsApp Business Account (grünes Abzeichen) nach Genehmigung",
  [keys.SET_partnerChangeConditionText]:
    "Der neue Partner verlangt von dir, dass du alle Gebühren direkt an 360dialog zahlst. Bitte lies die Bedingungen weiter unten und wähle deinen Tarif, bevor du bestätigst.",
  [keys.SET_messageToNewPartner]: "Optionale Nachricht an deinen neuen Partner",
  [keys.SET_messageToNewPartnerPlaceholder]:
    "Füge alle relevanten Informationen für deinen neuen Partner hinzu, wie z.B. Benutzer-ID, E-Mail, Support-Ticket-ID, etc.",
  [keys.SET_partnerChangeRequestSubmitted]:
    "Dein Antrag auf Partnerwechsel wurde erfolgreich eingereicht. Du wirst benachrichtigt, sobald die Migration abgeschlossen ist.",
  [keys.SET_cancelling]: "Partneränderungsantrag stornieren?",
  [keys.SET_rejectionInputLabel]: "Bitte gib den Grund für die Ablehnung an",
  [keys.SET_rejectionInputPlaceholder]: "Grund für die Ablehnung eingeben",
  [keys.SET_rejectionInputError]:
    "Bitte gib eine Antwort von mindestens {{min}} Zeichen an",
  [keys.SET_rejectClient]: "Kunde ablehnen",
  [keys.SET_acceptClient]: "Kunde akzeptieren",
  [keys.SET_allExternalWabaMustHaveSameCurrency]:
    "Alle externen WhatsApp-Geschäftskonten des Kunden müssen die gleiche Währung im Meta Business Manager haben.",
  [keys.SET_paymentPlanCurrencyHasToMatch]:
    "Die Währung des Zielpartners und die des aktuellen Tarifs müssen übereinstimmen.",
  [keys.SET_partnerChangeRequestTargetPartnerPaymentPlanRequired]:
    "Der Zielpartner hat keinen gültigen Tarif (Partnerzahlung).",
  [keys.SET_partnerChangeRequestTargetPartnerPricingPlanRequired]:
    "Der Zielpartner hat keinen gültigen Tarif. Jedes Partnermodell hat ein Feld für den Tarif. Wenn es leer ist, fehlt der Tarif (Direktzahlung).",
  [keys.SET_partnerChangeRequestClientPaymentMethodRequired]:
    "Der neue Partner verlangt, dass du eine gültige Zahlungsmethode hast. Bitte füge eine Zahlungsmethode hinzu.",
  [keys.SET_wabaAndPaymentPlanCurrencyMustMatch]:
    "Alle 3 Währungen müssen übereinstimmen, falls vorhanden: Währung für Abrechnung der Template-Nachrichten, Währung des WhatsApp-Geschäftskontos und Währung des Zahlungsplans.",
  [keys.SET_paymentPlanHasToMatch]: "Der Tarif muss übereinstimmen.",
  [keys.SET_addPaymentMethod]: "Zahlungsmethode hinzufügen",
  [keys.SET_migrationNote]:
    "Bitte beachte, dass alle Nummern, die mit diesem Konto verbunden sind, auf den neuen Partner übertragen werden.",
  [keys.SET_apiKeyRecommendation]:
    "Wir empfehlen, einen neuen API KEY zu beantragen.",
  [keys.SET_languageSelection]: "Sprachauswahl",
  [keys.SET_askForRefund]:
    "Vergiss nicht, die Erstattung des nicht genutzten Guthabens zu beantragen.",
  [keys.SET_disabledPartnerChangeTooltip]:
    "Partneränderungsanträge sind vorübergehend nicht verfügbar. Diese Funktion wird am 15.03.2023 wieder verfügbar sein.",
  [keys.SET_addCompanyInfo]: "Firmeninfo hinzufügen",
  [keys.SET_selectPlan]: "Tarif auswählen",
  [keys.SET_triggerRefundMessage]:
    "Bitte veranlasse eine Erstattung für alle deine Nummern, bevor du den Antrag auf Partnerwechsel stellst. Dies erfolgt über den Bereich Fondsmanager auf der Seite Einblicke und Abrechnung.",
  [keys.SET_errorPartnerChange]: "Du kannst nicht zu diesem Partner wechseln",
  [keys.SET_errorPartnerChangeInfo]:
    "Um zu dem von dir ausgewählten Zielpartner zu wechseln, musst du die folgenden Bedingungen erfüllen:",
  [keys.SET_errorPartnerChangeHelp]:
    "Wenn du Hilfe brauchst, kontaktiere bitte den <LINK>Support<LINK>.",
  //  [keys.SET_PCRNotEnabled]: "translation_missing",
  //  [keys.SET_standardBillNotice]: "translation_missing",
  //  [keys.SET_premiumBillNotice]: "translation_missing",
  //  [keys.SET_updateDetails]: "translation_missing",
  //  [keys.SET_generalInfo]: "translation_missing",
  //  [keys.SET_nameCompany]: "translation_missing",
  //  [keys.SET_locationCompany]: "translation_missing",
  //  [keys.SET_editDetails]: "translation_missing",
  //  [keys.SET_saveDetails]: "translation_missing",
  //  [keys.SET_checkYourInvoices]: "translation_missing",
  //  [keys.SET_invoiceId]: "translation_missing",
  //  [keys.SET_invoiceIssueAt]: "translation_missing",
  //  [keys.SET_inviteMember]: "translation_missing",
  //  [keys.SET_inviteMemberProject]: "translation_missing",
  //  [keys.SET_member]: "translation_missing",
  //  [keys.SET_companyMembers]: "translation_missing",
  //  [keys.SET_role]: "translation_missing",
  //  [keys.SET_informationPartner]: "translation_missing",
  //  [keys.SET_changePartner]: "translation_missing",
  //  [keys.SET_currentPartner]: "translation_missing",
  //  [keys.SET_currentPartnerInfo]: "translation_missing",
  //  [keys.SET_selectedPaymentMethod]: "translation_missing",
  //  [keys.SET_changePaymentMethod]: "translation_missing",
  //  [keys.SET_cardDetails]: "translation_missing",
  //  [keys.SET_cardHolder]: "translation_missing",
  //  [keys.SET_cardExpiryDate]: "translation_missing",
  //  [keys.SET_cardEndsWith]: "translation_missing",
  //  [keys.SET_card]: "translation_missing",
  //  [keys.SET_adAccountsDescription]: "translation_missing",
  //  [keys.SET_adAccountsEmptyTitle]: "translation_missing",
  //  [keys.SET_adAccountsEmptyDescription]: "translation_missing",
  //  [keys.SET_accountName]: "translation_missing",
  //  [keys.SET_accountID]: "translation_missing",
  //  [keys.SET_refreshData]: "translation_missing",
  //  [keys.SET_successfulRefreshedDataMsg]: "translation_missing",
  //  [keys.SET_refreshingDataInFlightMsg]: "translation_missing",
  // [keys.SET_noInvoices]: "translation_missing",
  // [keys.SET_noInvoicesDescription]: "translation_missing",
  // [keys.SET_name]: "translation_missing",
  // [keys.SET_email]: "translation_missing",
  // SET_paid: "translation_missing",
  // SET_unpaid: "translation_missing",
  // SET_error: "translation_missing",
  // SET_pending: "translation_missing",
  // SET_processing: "translation_missing",
  // SET_refunded: "translation_missing",
  // SET_refundTriggered: "translation_missing",
  // SET_partiallyRefunded: "translation_missing",
  // SET_partialRefundTriggered: "translation_missing",
  // SET_triggered: "translation_missing",
  // SET_unknownStatus: "translation_missing",
  // SET_status: "translation_missing",
  // translation missing for [keys.SET_companyTeamMembers]
  [keys.SET_confirmPaymentMethod]: "Bestätigen Sie die Zahlungsmethode",
  [keys.SET_confirmPaymentMethodDesc]:
    "Bitte bestätigen Sie Ihre Zahlungsmethode, um den Partnerwechselvorgang fortzusetzen.",
  [keys.SET_pendingApprovalStatus]:
    "Partneränderungsantrag zur Genehmigung anstehend",
  [keys.SET_cancelPCRTitle]: "Partneränderungsantrag stornieren",
  [keys.SET_cancelPCRDesc]:
    "Möchten Sie Ihren Antrag auf Partnerwechsel stornieren?",
  [keys.SET_submitPartnerChangeRequestMessage]:
    "Ihr Antrag auf Partnerwechsel wurde gesendet und wird bearbeitet",
  [keys.SET_PCRfailed]: "Partner Change Request fehlgeschlagen",
  // [keys.SET_funds]: "translation_missing",
  // [keys.SET_aboutFunds]: "translation_missing",
  // [keys.SET_activity]: "translation_missing",
  // [keys.SET_aboutActivity]: "translation_missing",
  // [keys.SET_invoicePaymentRequired1]: "translation_missing",
  // [keys.SET_invoicePaymentRequired2]: "translation_missing",
  // [keys.SET_migrationSupport]: "translation_missing",
};

export default translate;
