// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  NAV_whatsappAccounts: "NAV_whatsappAccounts",
  NAV_partners: "NAV_partners",
  NAV_integrations: "NAV_integrations",
  NAV_support: "NAV_support",
  NAV_profile: "NAV_profile",
  NAV_billing: "NAV_billing",
  NAV_helpAndFeedback: "NAV_helpAndFeedback",
  NAV_preferences: "NAV_preferences",
  NAV_signOut: "NAV_signOut",
  NAV_dock: "NAV_dock",
  NAV_undock: "NAV_undock",
  NAV_templateBilling: "NAV_templateBilling",
  NAV_conversationBilling: "NAV_conversationBilling",
  NAV_billingText: "NAV_billingText",
  NAV_insights: "NAV_insights",
  NAV_insightsAndBilling: "NAV_insightsAndBilling",
  NAV_organizationSettings: "NAV_organizationSettings",
  NAV_connectedApps: "NAV_connectedApps",
  NAV_notifications: "NAV_notifications",
  NAV_logout: "NAV_logout",
  NAV_gettingStarted: "NAV_gettingStarted",
  NAV_roadmap: "NAV_roadmap",
  NAV_billinOptions: "NAV_billinOptions",
  NAV_company: "NAV_company",
  NAV_adAccounts: "NAV_adAccounts",
  NAV_pageUnavailable: "NAV_pageUnavailable",
  NAV_team: "NAV_team",
  NAV_settings: "NAV_settings",
  NAV_numberAccess: "NAV_numberAccess",
};

export default keys;
