// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.KEY_existingTitle]: "API key existente",
  [keys.KEY_existingHelp1]:
    "Ya hay una API key activa existente. Generar una nueva API key reescribirá la API key actual y borrará el destino actual de webhook.",
  [keys.KEY_existingHelp2]:
    "Al continuar, deberá enviar la nueva API key generada a la aplicación que va a utilizar.",
  [keys.KEY_newTitle]: "Su API key",
  [keys.KEY_newHelp]:
    "Por favor, copie su nueva API key y guárdela en un lugar seguro. Después de cerrar esta ventana, la API key no estará disponible para usted o para 360dialog nunca más.",
  [keys.KEY_key]: "API key:",
  [keys.KEY_copy]: "Copiar",
  [keys.KEY_copied]: "Copiado",
  [keys.KEY_address]: "Dirección:",
  [keys.KEY_confirmCopy]: "He copiado y guardado la API key.",
  [keys.KEY_partnerInfoText]:
    "Haga click aquí para colocar su API key en la aplicación <PARTNER>.",
  [keys.KEY_partnerInfoButtonLabel]: "Continuar a <PARTNER>",
  [keys.KEY_generateKeyIntro]:
    "Genere una API key para usar en aplicaciones de terceros para conectarse a la API de WhatsApp Business.",
};

export default translate;
