import * as React from "react";
import * as Styled from "./AdminBannerStyles";
import useAdminStore from "@/services/store/useAdminStore";
import { Body } from "@gcp-artifact/compass/lib/components";
import { observer } from "mobx-react-lite";
import { k } from "@/utilities/i18n";
import translate from "@/utilities/i18n/getTranslate";
import { replacePlaceholderStringBold } from "@/utilities/replacePlaceholder";
import api from "@/services/api/admin";
import errorHandler from "@/utilities/errorHandler";
import { useAcl } from "@/services/api/auth/useAcl";

const AdminBanner = () => {
  const {
    adminStore: { currentUser, currentClient, setCurrentClient },
    adminNotification,
  } = useAdminStore();

  const clientId = currentClient?.id;

  const { partnerId } = useAcl();

  React.useEffect(() => {
    if (!partnerId || !currentUser?.isAdmin) return;
    const fetchClientInfo = async () => {
      try {
        const clientResponse = await api.clients.get.single({
          partnerId,
          clientId,
        });

        setCurrentClient(clientResponse);
      } catch (err) {
        adminNotification.add({
          type: adminNotification.types.ERROR,
          message: errorHandler(err),
        });
      }
    };

    fetchClientInfo();
  }, [
    adminNotification,
    clientId,
    partnerId,
    setCurrentClient,
    currentUser?.isAdmin,
    currentClient?.id,
  ]);

  if (!currentUser?.isAdmin || !clientId || !currentClient) return;

  return (
    <Styled.AdminBanner>
      <Body withoutMargins size="s">
        {replacePlaceholderStringBold(
          translate(k.A_adminHeaderInfo),
          "<client_name>",
          currentClient?.name
        )}
      </Body>
    </Styled.AdminBanner>
  );
};

export default observer(AdminBanner);
