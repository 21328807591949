// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.WF_title]:
    "Подача заявки на регистрацию аккаунта WhatsApp Business API",
  [keys.WF_intro]:
    "Пожалуйста, предоставьте информацию о бизнесе и телефонный номер, который Вы хотите подключить к WhatsApp Business API. Убедитесь, что деятельность Вашего бизнеса соответствует торговой политике WhatsApp.",
  [keys.WF_compliancePolicyLinkLabel]: "торговой политике WhatsApp",
  [keys.WF_personalInformation]: "Персональная информация",
  [keys.C_email]: "Email",
  [keys.WF_emailTooltip]:
    "Этот email будет использован для информирования и оповещений о WhatsApp Business API",
  [keys.C_fullName]: "ФИО",
  [keys.WF_businessDetails]: "Сведения о бизнесе",
  [keys.WF_legalCompanyName]: "Название юридического лица",
  [keys.WF_fbBusinessManagerId]: "Meta Business Manager ID",
  [keys.WF_FbIdTip_error]: "Неверный Meta Business Manager ID",
  [keys.WF_FbIdTip_header]:
    "Meta Business Manager ID вы можете найти в своем Бизнес Менеджере Meta",
  [keys.WF_FBIDTip_text1]:
    "Ваш FBM ID находится в вашем Бизнес Менеджере Meta. Возможно, вы использовали ваш FBM ID ранее для доступа к инвойсам Meta или к Meta pixel.",
  [keys.WF_FBIDTip_text2]: "Найти ваш FBM ID можно следующим образом:",
  [keys.WF_FBIDTip_step1]: "Перейдите в настройки вашего бизнеса в Meta.",
  [keys.WF_FBIDTip_step2]: "Нажмите на Business Info.",
  [keys.WF_FBIDTip_step3]: "В разделе Business Manager Info вы увидите ваш ID.",
  [keys.WF_messaging]: "Детальная информация акканута",
  [keys.WF_whatsAppPhoneNumber]: "Номер телефона для регистрации",
  [keys.WF_whatsAppPhoneNumberTooltip1]:
    "Номер для регистрации и подключения к WhatsApp Business API.",
  [keys.WF_whatsAppPhoneNumberTooltip2]:
    "Пожалуйста, используйте номер принадлежащий вашему бизнесу",
  [keys.WF_timezone]: "Часовой пояс",
  [keys.WF_attention]: "Внимание",
  [keys.WF_govAgencyText1]:
    "Если вы представляете государственную организацию, пожалуйста, не создавайте аккаунт с помощью этой регистрационной формы. В этом случае вам потребуется предварительное одобрение Meta для создания и использования вашего аккаунта. Если вы еще не получали такого одобрения от команды Meta или не знаете, являетесь ли вы государственной организцией, ознакомьтесь с нашей <LINK>https://docs.360dialog.com/waba-for-government-agencies | документацией и заполните форму <LINK>. Мы сделаем все возможное, чтобы помочь вам.",
  [keys.PI_perMonth]: "/ Месяц",
  [keys.PI_text1]:
    "Оплата за переписку взимается в евро в соотвествии с тарифами Meta/WhatsApp, как указано в <LINK>https://developers.facebook.com/docs/whatsapp/pricing | правилах ценообразования Meta<LINK>; может взиматься предоплата.",
  [keys.PI_text2]: "и могут быть предоплачены",
  [keys.PI_textUSD]:
    "Оплата за переписку взимается в долларах США в соотвествии с тарифами Meta/WhatsApp, как указано в <LINK>https://developers.facebook.com/docs/whatsapp/pricing | правилах ценообразования Meta<LINK>; может взиматься предоплата.",
  [keys.PI_billedMonthly]: "Оплачивается ежемесячно",
  [keys.PI_completeOrderButtonLabel]: "Завершить заказ",
  //  [keys.WF_termsIntro]: "translation_missing",
  //  [keys.WF_terms1]: "translation_missing",
  //  [keys.WF_terms1p1]: "translation_missing",
  //  [keys.WF_terms1p2]: "translation_missing",
  //  [keys.WF_terms2]: "translation_missing",
  //  [keys.WF_terms2p1p1]: "translation_missing",
  //  [keys.WF_terms2p1and]: "translation_missing",
  //  [keys.WF_terms2p1p2]: "translation_missing",
  //  [keys.WF_terms2p2]: "translation_missing",
  //  [keys.WF_terms2p3]: "translation_missing",
  //  [keys.WF_terms2p4]: "translation_missing",
  //  [keys.WF_terms3]: "translation_missing",
  //  [keys.WF_terms3p1]: "translation_missing",
  //  [keys.WF_terms3p2]: "translation_missing",
  //  [keys.WF_terms4]: "translation_missing",
  //  [keys.WF_terms4p1]: "translation_missing",
  //  [keys.WF_terms4p2]: "translation_missing",
  //  [keys.WF_terms4p3]: "translation_missing",
  //  [keys.WF_terms5]: "translation_missing",
  //  [keys.WF_terms5p1]: "translation_missing",
  //  [keys.WF_terms5p2]: "translation_missing",
  //  [keys.WF_terms5p3]: "translation_missing",
  //  [keys.WF_terms5p4]: "translation_missing",
  //  [keys.WF_terms5p5]: "translation_missing",
  //  [keys.WF_terms5p6]: "translation_missing",
  //  [keys.WF_terms5p7]: "translation_missing",
  //  [keys.WF_terms5p8]: "translation_missing",
  //  [keys.WF_termsLabel]: "translation_missing",
  //  [keys.WF_termsCheckbox1]: "translation_missing",
  //  [keys.WF_termsCheckbox1Detail]: "translation_missing",
  //  [keys.WF_termsCheckbox2]: "translation_missing",
  //  [keys.WF_termsCheckbox3]: "translation_missing",
  //  [keys.WF_verifiedBsp]: "translation_missing",
  //  [keys.WF_buttonLabelAccept]: "translation_missing",
  [keys.WF_buttonLabelSave]: "Сохранить",
  [keys.WF_paymentInfo]: "Платеж",
  [keys.WF_submission]: "Отправляем...",
  [keys.WF_thanksTitle]: "Спасибо!",
  [keys.WF_thanksNext]:
    "Вам отправлено электронное письмо с инструкцией по прохождению верификации.",
  [keys.WF_thanksMob1]:
    "Сейчас вам следует открыть свой Meta Business Manager и принять запрос на работу с сообщениями от 360dialog",
  [keys.WF_thanksMobQuotes]: "undefined",
  [keys.WF_thanksMob2]: "undefined",
  [keys.WF_thanksButtonLabel]: "Открытые запросы",
  [keys.WF_0800error]:
    "Вы пытаетесь зарегистрировать номер, начинающийся на 800. Такие номера не могут принимать международные звонки как способ проверки номера. Мы рекомендуем использовать другой номер. ",
  [keys.WF_paymentCardOwnerName]: "Имя владельца карты",
  [keys.WF_paymentCreditCard]: "Банковская карта",
  [keys.WF_paymentCardOwnerNamePlaceholder]:
    "Если имя отличается от имени пользователя",
  [keys.WF_hubAccess]: "доступ к 360dialog Client Hub",
  [keys.WF_hubAccessHelp]:
    "После регистрации вам будет доступен 360dialog Client Hub, где вы сможете управлять своим аккаунтом WhatsApp Business Account",
  [keys.WF_hubAccessHelpPassword]:
    "Выберите пароль для вашего аккаунта 360dialog Client Hub",
  [keys.WF_passwordLabel]: "Введите пароль",
  [keys.WF_repeatPasswordLabel]: "Повторите пароль",
  [keys.WF_passwordError1]: "Пароль должен быть не менее 8 символов",
  [keys.WF_passwordError2]:
    "Пароль должен содержать минимум один символ в нижнем регистре (a-z)",
  [keys.WF_passwordError3]:
    "Пароль должен содержать минимум один символ в верхнем регистре (A-Z)",
  [keys.WF_passwordError4]:
    "Пароль должен содержать минимум одну цифру (0-9) или спец. символ (!#$%&?).",
  [keys.WF_passwordRepeatError]: "Пароли не совпадают",
  [keys.WF_cardError]: "Пожалуйста, проверьте данные карты",
  [keys.WF_passwordErrorZxcvbn]:
    "Добавьте еще слово или два. Используйте нестандартные слова",
  [keys.CON_title]: "Подключить Meta Business Account",
  [keys.CON_textIntro]:
    "Чтобы продолжить, у вас должен быть доступ к Meta Business Manager вашей компании. Нажав 'Продолжить с Meta' вы сможете:",
  [keys.CON_bullet1]:
    "Создать новый или использовать существующие аккаунты Meta и WhatsApp Business Account",
  [keys.CON_bullet2]:
    "Установить Display Name и описание вашего профиля WhatsApp",
  [keys.CON_bullet3]: "Подключить номер WhatsApp для работы через API",
  [keys.CON_numberHelpText]:
    "Не используйте номер, который уже подключен к WhatsApp. В случае отказа в регистрации аккаунт WhatsApp должен быть удален.",
  [keys.CON_numberHelpLink]: "Узнать больше о вашем номере WhatsApp",
  [keys.CON_buttonLabel]: "Продолжить в Meta",
  [keys.CAN_title]: "Чем я могу помочь,",
  [keys.CAN_textIntro]:
    "Вы всегда можете продолжить незавершенную регистрацию. Мы всегда готовы помочь вам с этим",
  [keys.CAN_faq]: "Часто задаваемые вопросы",
  [keys.CAN_buttonHelp]:
    "Готовы пройти регистрацию еще раз? Нажмите для запуска процесса регистрации",
  [keys.WF_partnerIntro]: "Вы подключаетесь как пользователь <PARTNER>.",
  [keys.WF_userAlreadyExists]:
    "Пользователь с таким email уже существует. Чтобы начать работу с номером или подать новый номер на регистрацию, пожалуйста, войдите в свою учетную запись 360dialog Client Hub",
  [keys.CAN_livesupportTitle]: "Обратиться в персональную поддержку",
  [keys.CAN_livesupportText]:
    "Нажмите на кнопку справа, чтобы запланировать звонок с командой поддержки, которая поможет вам начать работать с номером",
  [keys.CAN_livesupportButtonLabel]: "Запланировать звонок",
  [keys.CAN_livesupportButtonLabelSuccess]: "Звонок запланирован успешно",
  [keys.PI_selectedLabel]: "выбрано",
  [keys.SH_copied]: "Скопировано",
  [keys.SH_copyPageLink]: "Скопировать ссылку на страницу",
  [keys.SH_pageLink]: "Ссылка на страницу",
  [keys.PI_pricingPlanLinkLabel]: "Узнать больше о тарифных планах",
  [keys.WF_alreadyHaveAccount]: "Уже есть аккаунт?",
  [keys.WF_login]: "Войти",
  [keys.WF_mobileHeading]: "Приносим извинения",
  [keys.WF_notSupportedMobile]:
    "К сожалению, процесс экспресс-регистрации в настоящее время не поддерживается на мобильных устройствах. Посетите эту страницу с настольного устройства.",
  [keys.WF_saveURL]: "Сохранить текущую страницу",
  [keys.WF_continueAnyway]: "Перейти к классической форме регистрации",
  [keys.WF_sharedSuccessfully]: "URL-адрес был успешно передан",
  [keys.WF_copiedUrl]: "URL-адрес скопирован в буфер обмена",
  [keys.CC_consentText]:
    "Этот веб-сайт использует файлы cookie для улучшения пользовательского опыта.",
  [keys.CC_acceptButtonLabel]: "Принять",
  [keys.CC_declineButtonLabel]: "Отменить",
  [keys.CC_title]: "Наш веб-сайт использует файлы cookie",
  [keys.CC_text]:
    "Мы хотели бы использовать наши собственные и сторонние файлы cookie, а также аналогичные технологии для улучшения пользовательского опыта и в маркетинговых целях. Вы можете задать предпочтительные для вас настройки, выбрав параметры ниже.",
  [keys.CC_cookies1text]:
    "<cookies1label> погают в использовании основных функций нашего веб-сайта, например подтверждают факт согласия на использование файлов cookie.",
  [keys.CC_cookies1label]: "Необходимые файлы cookie",
  [keys.CC_cookies2text]:
    "<cookies2label> позволяют собирать статистику о том, как вы используете наш веб-сайт, чтобы мы могли сделать его лучше.",
  [keys.CC_cookies2label]: "Аналитические файлы cookie",
  [keys.CC_cookies3text]:
    "<cookies3label> позволяют выбирать для показа вам наиболее актуальный контент и рекламу на нашем веб-сайте и других платформах.",
  [keys.CC_cookies3label]: "Маркетинговые файлы cookie",
  [keys.CC_buttonLabelAcceptSelected]: "Подтвердить выбор",
  [keys.CC_buttonLabelAcceptNecessary]:
    "Подтвердить выбор обязательных параметров",
  [keys.CC_buttonLabelAcceptAll]:
    "Подтвердить согласие на использование полного набора",
  [keys.WF_currentBussinessSolutionsProvider]:
    "Текщий поставщик бизнес-решений (BSP)",
  [keys.MIG_fbErrorWaitNewCode]:
    "Вам необходимо подождать возможности запросить код повторно. Пожалуйста, подождите <HOUR> и <MINUTES>, прежде чем повторить попытку.",
  [keys.CH_portExistingNumber]: "Перенесите существующий номер",
  [keys.CH_portWhatsAppProfile]:
    "Перенесите существующий бизнес-профиль WhatsApp от любого другого провайдера (BSP) в 360dialog.",
  [keys.CH_addNewNumber]: "Добавьте новый номер телефона",
  [keys.CH_setUpWhatsAppProfile]:
    "Настройте свой бизнес-аккаунт и профиль WhatsApp с номером, который еще не используется.",
  [keys.CH_welcomeTo360dialog]: "Добро пожаловать в 360dialog!",
  [keys.CH_pleaseSelect]:
    "Выберите, хотите ли вы перенести существующий номер WhatsApp Business API в 360dialog или хотите создать новую учетную запись для номера, который еще не зарегистрирован в WhatsApp Business API.",
  [keys.CH_continueWithAccount]: "Продолжить создание учетной записи",
  [keys.CAN_createTicket]: "Создать заявку в службу поддержки",
  [keys.CAN_ticketCreated]: "Заявка создана",
  [keys.CON_contactSupportHelptext]:
    "Возникли проблемы с завершением регистрации?",
  [keys.WF_goToLogin]: "Перейти к странице входа",
  [keys.WF_planSelection]: "Выбрать тарифный план",
  [keys.WF_monthly]: "Ежемесячно",
  [keys.WF_yearly]: "Ежегодно",
  [keys.WF_month]: "Месяц",
  [keys.WF_year]: "Год",
  [keys.WF_billedMonthly]: "Оплачивается ежемесячно",
  [keys.WF_months]: "месяцев",
  [keys.WF_billedAt]: "Оплачивается в",
  [keys.WF_selected]: "Выбрано",
  [keys.WF_select]: "Выбрать",
  [keys.WF_basic]: "Стандартный",
  [keys.WF_professional]: "Профессиональный",
  [keys.WF_forDevelopers]: "Лучший выбор для разработчиков",
  [keys.WF_scalablePlan]: "Легко масштабируемый план",
  [keys.WF_buttonLabelSubmit]: "Отправить данные",
  [keys.WF_buttonLabelCancel]: "Вернуться назад",
  [keys.WF_reviewData]: "Проверка данных",
  [keys.WF_passwordError5]:
    "Пароль не должен содержать информацию об электронной почте",
  [keys.CC_openCookiePreferences]: "Открыть настройки файлов cookie",
  [keys.CC_acceptCardLabel]:
    "Чтобы получить доступ к нашей поддержке через чат, согласитьсесь на использование «Аналитических файлов cookie».",
  [keys.WF_freemailError]:
    "Вы не можете зарегистрировать аккаунт, используя адрес почты с доменом ”<DOMAIN>”. Пожалуйста, используйте свой рабочий адрес электронной почты, чтобы зарегистрировать ваш аккаунт.",
  [keys.WF_termsIntro]:
    "Размещая данный “Заказ”, Клиент запрашивает использование услуг WhatsApp Business Solution (как указано ниже, далее “Услуги”), предоставлямых компанией 360dialog GmbH, Торштрассе 61, 10119 Берлин, Германия, которая выступает в качестве санкционированного “Поставщика бизнес-решений” (далее “360dialog” или “BSP”). “Услуги” подразумевают под собой все услуги, предоставляемые 360dialog, связанные с настройкой, технической интеграцией и текущем управлением бизнес-аккаунта WhatsApp (“WABA(s)”). Размещая данный “Заказ”, Клиент предоставляет 360dialog право запросить доступ к WABA Клиента у WhatsApp (как указано ниже) и управлять WABA от имени клиента. Это включает в себя передачу информации между WhatsApp и Клиентов в целях исполнения данного Соглашения.",
  [keys.WF_terms1]: "WABA MSISDNs",
  [keys.WF_terms1p1]:
    "Настоящим Клиент запрашивает WABA аккаунты, связанные со следующим MSISDN (включая код страны в формате +49):",
  [keys.WF_terms1p2]:
    "Утверждение каждого WABA остается на усмотрение WhatsApp. В этом документе «WhatsApp» означает WhatsApp Inc. и/или любую из ее аффилированных сторон в группе Meta, которая предоставляет любые услуги, являющиеся частью решения WhatsApp Business.",
  [keys.WF_terms2]: "Соблюдение условий WhatsApp",
  [keys.WF_terms2p1p1]:
    "Настоящим Клиент гарантирует и заявляет, что будет использовать WABA строго в соответствии с применимыми Условиями использования WhatsApp Business Solution https://www.whatsapp.com/legal/business-solution-terms, Условиями использования Meta Client для WhatsApp, прилагаемыми в Приложении 2, а также а также все другие применимые условия, правила и политики WhatsApp, что наиболее важно",
  [keys.WF_terms2p1and]: "И",
  [keys.WF_terms2p1p2]: "«Условия WhatsApp» в целом.",
  [keys.WF_terms2p2]:
    "Клиент признает и соглашается с тем, что WhatsApp может изменить Условия WhatsApp и/или опубликовать дополнительные условия, рекомендации и политики, и после их публикации такие дополнительные условия, рекомендации и политики станут частью Условий WhatsApp.",
  [keys.WF_terms2p3]:
    "Клиент обязан возместить 360dialog любые убытки, расходы и ущерб, причиненный нарушением Условий WhatsApp.",
  [keys.WF_terms2p4]:
    "WABA предназначены только для использования Клиентом. Клиент заявляет и гарантирует, что Клиент не будет распространять, продавать, перепродавать, сдавать в аренду или иным образом предоставлять доступ к WABA любой третьей стороне для любых целей, выходящих за рамки настоящего Заказа.",
  [keys.WF_terms3]: "Использование Услуг",
  [keys.WF_terms3p1]:
    "Услуги (как определено выше) предоставляются Клиенту компанией 360dialog. Использование Услуг регулируется Условиями использования 360dialog, прилагаемыми в Приложении 1. Настоящий Заказ и Условия 360dialog именуются «Соглашение».",
  [keys.WF_terms3p2]:
    "В соответствии с Условиями WhatsApp Клиенты имеют право перенести свои WABA к другому поставщику бизнес-решений WABA. Любые действия, требуемые 360dialog в связи с таким запросом, регулируются Прейскурантом 360dialog.",
  [keys.WF_terms4]: "Прочее",
  [keys.WF_terms4p1]:
    "Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Федеративной Республики Германии, за исключением Конвенции ООН о договорах международной купли-продажи товаров и коллизионных норм.",
  [keys.WF_terms4p2]:
    "Место исполнения и место проведения – Берлин, Германия. Однако 360dialog оставляет за собой право на любое другое юридически допустимое место.",
  [keys.WF_terms4p3]:
    "Если суд компетентной юрисдикции сочтет какое-либо положение настоящего Соглашения противоречащим применимому законодательству, то остальные положения настоящего Соглашения, если они могут быть выполнены по существу, остаются в полной силе и действии. Затронутая оговорка должна быть заменена той, которая наиболее точно отражает первоначальные намерения сторон.",
  [keys.WF_terms5]: "Условия и цены 360dialog",
  [keys.WF_terms5p1]:
    "Ежемесячная лицензионная плата за хостинг WhatsApp Business API составляет 50,00 долларов США за номер в месяц.",
  [keys.WF_terms5p2]:
    "Условия Ежемесячного лицензионного сбора взимаются ежемесячно и могут быть прекращены с уведомлением за 30 дней.",
  [keys.WF_terms5p3]:
    "Ежемесячная лицензионная плата за хостинг WhatsApp Business API будет взиматься основываясь на календарном месяце.",
  [keys.WF_terms5p4]:
    "360dialog выставляет счет клиенту только за WhatsApp Business API после развертывания стека хостинга клиента.",
  [keys.WF_terms5p5]:
    "Если хостинг только начинается в любой день, кроме 1-го числа календарного месяца, он будет выставляться только пропорционально в зависимости от фактического количества дней с момента активации хостинга.",
  [keys.WF_terms5p6]:
    "Все лицензионные сборы выставляются в счет в начале соответствующего месяца.",
  [keys.WF_terms5p7]:
    "Стоимость шаблонных сообщений WhatsApp Стоимость шаблонных сообщений не включена в ежемесячную плату за лицензию и взимается в соответствии с ценами Meta/Whatsapp в евро, как указано в разделе https://developers.facebook.com/docs/whatsapp/pricing/.",
  [keys.WF_terms5p8]: "Все сборы указаны без учета НДС, если применимо.",
  [keys.WF_termsLabel]: "Условия",
  [keys.WF_termsCheckbox1]:
    "Я согласен с Приложением 1 - Условия использования 360dialog.",
  [keys.WF_termsCheckbox1Detail]: "Дата последних изменений:",
  [keys.WF_termsCheckbox2]:
    "Я принял и согласен с Приложением 2 — Условия использования клиента Meta для WhatsApp.",
  [keys.WF_termsCheckbox3]: "Я согласен с Приложением 3 - Цены 360dialog.",
  [keys.WF_verifiedBsp]:
    "360dialog является проверенным поставщиком решений WhatsApp и получил от Meta/WhatsApp право предоставлять своим клиентам доступ к WhatsApp Business API.",
  [keys.WF_buttonLabelAccept]: "Принять условия",
  [keys.CON_sessionExp]:
    'Ваша сессия с Meta истекла. Повторите процесс, нажав кнопку "Continue with Meta".',
  [keys.VER_checkEmailTitle]: "Проверьте свою электронную почту!",
  [keys.VER_submit]: "Подтвердить",
  [keys.VER_bullet1]:
    "Чтобы продолжить, вам сначала нужно подтвердить свою электронную почту.",
  [keys.VER_bullet2]: "На адрес <EMAIL> отправлен одноразовый пароль.",
  [keys.VER_bullet3]:
    "Пожалуйста, введите код доступа ниже, чтобы подтвердить свой адрес электронной почты.",
  [keys.VER_inputLabel]: "Одноразовый пароль",
  [keys.VER_inputPlaceholder]: "Введите пароль сюда",
  [keys.VER_inputError]: "Представленный код неверен.",
  [keys.VER_closeWindowWarning]:
    "Пожалуйста, не закрывайте это окно. Ваши данные не будут сохранены, пока вы не отправите код подтверждения.",
  [keys.VER_newCodeSentToEmail]:
    "Новый пароль был отправлен на вашу электронную почту",
  [keys.AI_almostDone]: "Вы почти закончили, <USERNAME>",
  [keys.AI_addInfo]: "Добавьте дополнительную информацию о компании.",
  [keys.AI_completeInfo]: "Полная информация",
  [keys.AI_companyInfo]:
    "Для продолжения нам нужна информация о вашей компании",
  [keys.AI_companyNameLabel]: "Юридическое название компании",
  [keys.AI_companyNamePlaceholder]: "Введите юридическое название компании",
  [keys.AI_companyAddressLabel]: "Юридический адрес",
  [keys.AI_companyAddressPlaceholder]: "Введите юридический адрес",
  [keys.AI_selectCountry]: "Выберите страну",
  [keys.AI_vatIdLabel]: "Код плательщика НДС",
  [keys.AI_vatIdPlaceholder]: "Введите код плательщика НДС",
  [keys.AI_companyVertical]: "Сфера деятельности компании",
  [keys.FS_accessCompanyFBM]:
    "Чтобы продолжить, вам нужно <strong>получить доступ к Meta Business Manager вашей компании</strong> и сделать следующее:",
  [keys.FS_connectNumber]:
    "Подключите и подтвердите свой номер телефона, чтобы использовать WhatsApp Business API.",
  [keys.FS_addInfoToBusinessManager]:
    "Чтобы иметь активную учетную запись, вы должны добавить свою информацию в раздел «Информация о бизнесе» в настройках Бизнес Менеджера.",
  [keys.FS_fbCommercePolicy]:
    "Эта информация используется Meta для проверки соблюдения Коммерческой политики. При неправильной настройке учетная запись будет немедленно заблокирована.",
  [keys.FS_embeddedSignupInfo]:
    "<LINK>https://docs.360dialog.com/docs/embedded-signup | Дополнительная информация<LINK>",
  [keys.WF_fullName]: "Введите свое полное имя",
  [keys.WF_noAccount]: "Еще нет учетной записи?",
  [keys.WF_signUpHere]: "Регистрация",
  [keys.WF_loginHere]: "Вход",
  [keys.WF_passwordError6]:
    "Пароль не должен содержать информацию о пользователе",
  [keys.WF_comapnyNameTooltip]:
    "Пожалуйста, введите название вашей юридической компании. Имя вашего профиля WhatsApp может быть выбрано позже и может отличаться при определенных условиях.",
  [keys.WF_vatIdTooltip]:
    "Идентификационный номер плательщика НДС требуется только для компаний из ЕС. Укажите полный идентификационный номер плательщика НДС с префиксом. Пример — ES25084476",
  [keys.WF_vatIdNotValid]:
    "Идентификационный номер плательщика НДС недействителен",
  [keys.WF_noVatId]:
    "У моей компании нет идентификационного номера плательщика НДС.",
  [keys.WF_noVATMessage]:
    "Если у вашей компании нет идентификационного номера налогоплательщика или этот номер недействителен, мы будем взимать с вас налог с продаж согласно законодательству ФРГ.",
  [keys.WF_vatInfoUpdated]: "Информация о компании и НДС обновлена",
  [keys.WF_governmentalCheck]: "Проверка для государственных учреждений",
  [keys.WF_notGovernmental]: "Я не государственное учреждение",
  [keys.WF_facebookBusinessSetup]: "Настройка страницы Meta Business",
  //  [keys.WF_ukraineExcludedAreasNote]: "translation_missing",
  //  [keys.FS_comeBackFromMeta]: "translation_missing",
  //  [keys.FS_completeMataOnboarding]: "translation_missing",
  //  [keys.FS_completeMataOnboardingInfo]: "translation_missing",
  //  [keys.CC_openCookiePreferences]: "translation_missing",
  //  [keys.CC_disabledCookiePreferences]: "translation_missing",
  // [keys.ON_paymentDebitNotice]: "translation_missing",
};

export default translate;
