// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.AUTH_login]: "Login",
  [keys.AUTH_forgot]: "Passwort vergessen?",
  [keys.AUTH_clickHere]: "Hier klicken",
  [keys.AUTH_rightsReserved]: "©2021 Alle Rechte vorbehalten.",
  [keys.AUTH_privacy]: "Datenschutz",
  [keys.AUTH_imprint]: "Impressum",
  [keys.AUTH_reset]: "Passwort zurücksetzen",
  [keys.AUTH_resetHelptext]:
    "Gib die mit deinem Konto verknüpfte E-Mail-Adresse ein, und wir senden dir eine E-Mail mit Anweisungen zum Zurücksetzen deines Passworts.",
  [keys.AUTH_resetButtonLabel]: "Anweisungen senden",
  [keys.AUTH_resetError]:
    "Es ist ein Fehler aufgetreten. Bitte versuche es in ein paar Minuten nochmals.",
  [keys.AUTH_resetSuccess]:
    "Erledigt! Die Anweisungen zum Zurücksetzen deines Passworts sind an deine E-Mail-Adresse gesendet worden. Bitte warte ein paar Minuten, falls du diese Email noch nicht erhalten hast.",
  [keys.AUTH_alreadyHaveAccount]: "Du besitzt bereits ein Konto?",
  [keys.AUTH_signup]: "Sign up",
  [keys.AUTH_gethelp]: "Probleme beim Login?",
  [keys.AUTH_getHelpLink]: "Bekomme Hilfe",
  [keys.AUTH_clickAboveButtons]:
    "Mit dem Klick auf einen der obigen Knöpfe zur Anmeldung",
  [keys.AUTH_termsAndService]: "360dialog Servicebedingungen",
  [keys.AUTH_metaTerms]:
    "akzeptierst du die <LINK>360dialog Nutzungsbedingungen<LINK>, sowie",
  [keys.AUTH_forWhatsApp]:
    "die <LINK>Meta-Client-Bedingungen<LINK> für WhatsApp.",
  [keys.AUTH_create]: "Erstelle dein Konto",
  [keys.AUTH_login360]: "Einloggen bei 360dialog",
  [keys.AUTH_email]: "Gib deine E-Mail-Adresse ein",
  [keys.AUTH_password]: "Gib dein Passwort ein",
  [keys.AUTH_loginEmail]: "Einloggen mit E-Mail-Adresse",
  [keys.AUTH_continueEmail]: "Weiter mit E-Mail-Adresse",
  [keys.AUTH_signupTerms]:
    "Mit der Anmeldung erklärst du dich mit den <LINK>Dienstleistungsbedingungen<LINK> einverstanden.",
  [keys.AUTH_checkEmail]: "Prüfe deine E-Mail!",
  [keys.AUTH_create360Account]: "Erstelle dein 360dialog-Konto",
  [keys.AUTH_otpTextOne]:
    "• Um fortzufahren, musst du zuerst deine E-Mail verifizieren",
  [keys.AUTH_otpTextTwo]: "• Einmal-Passcode wurde an <EMAIL> gesendet",
  [keys.AUTH_otpTextThree]:
    "• Bitte gib den Passcode unten ein, um deine E-Mail-Adresse zu verifizieren",
  [keys.AUTH_otp]: "Einmal-Passcode",
  [keys.AUTH_enterOtp]: "Gib den Einmal-Passcode hier ein",
  [keys.AUTH_namePlaceholder]: "Gib deinen Vor- und Nachnamen ein",
  [keys.AUTH_nameLabel]: "Vorname und Nachname",
  [keys.AUTH_createAccountEmail]: "Konto mit E-Mail-Adresse erstellen",
  [keys.AUTH_otpWindowMessage]:
    "Bitte achte darauf, dieses Fenster nicht zu schließen. Die Daten werden erst gespeichert, wenn du den Bestätigungscode eingegeben hast.",
  [keys.AUTH_createAccount]: "Konto erstellen",
  [keys.AUTH_signupPriceList]: "und zur <LINK>360dialog Preisliste<LINK>.",
  [keys.AUTH_visitNewHub]: "Besuche den neuen 360dialog Client Hub",
  [keys.AUTH_newHubInfoMessage]:
    "Wir haben eine neue Version des 360dialog Client Hubs veröffentlicht. Um darauf zuzugreifen, kannst du dich von nun an über app.360dialog.io einloggen. Alle deine Daten sind dort bereits vorhanden! Wechsle zum neuen 360dialog Client Hub für ein besseres Benutzererlebnis!",
  [keys.AUTH_governmentAgency]: "Regierungsbehörde",
  [keys.AUTH_organizationIRepresent]:
    "Die Organisation, die ich vertrete, ist eine...",
  [keys.AUTH_chooseFromList]: "wähle aus der Liste",
  [keys.AUTH_governmentalAgencyApproval]:
    "Aufgrund der Meta-Richtlinien müssen Regierungsbehörden eine Genehmigung einholen, bevor sie WhatsApp Business Accounts erstellen können.<BR><LINK>Stelle eine Anfrage<LINK>, ein Mitglied unseres Teams wird sich mit dir in Verbindung setzen.",
  [keys.AUTH_companyEmail]: "E-Mail des Unternehmens",
  [keys.AUTH_termsOfServiceSignUp]:
    "Wenn du ein Konto erstellst, stimmst du unseren <LINK>Nutzungsbedingungen<LINK> zu.",
  [keys.AUTH_newAccount]: "Neues Konto",
  [keys.AUTH_dontHaveAccount]: "Du hast noch kein Konto?",
  [keys.AUTH_signIn]: "Anmelden",
  [keys.AUTH_resetHere]: "Hier zurücksetzen",
  [keys.AUTH_twoFactorAuth]: "Zwei-Faktoren-Authentifizierung",
  [keys.AUTH_OTPSent]:
    "Wir haben eine E-Mail mit einem Code an <EMAIL> geschickt.",
  [keys.AUTH_ResetPasswordMessage]:
    "Gib deine E-Mail ein und wir schicken dir einen Link.",
  [keys.AUTH_wantToCancel]: "Willst du abbrechen?",
  [keys.AUTH_backToSignIn]: "Zurück zur Anmeldung",
  [keys.AUTH_emailSentTo]: "E-Mail gesendet an:",
  [keys.AUTH_checkInbox]:
    "Sie enthält alle Anweisungen, die du befolgen musst, um dein Passwort zurückzusetzen. Überprüfe deinen Posteingang.",
  [keys.AUTH_GoBackToSignIn]: "Zurück zur Anmeldung",
  [keys.AUTH_logOut]: "Abmelden",
  [keys.AUTH_min8Characters]: "Mindestens 8 Zeichen",
  [keys.AUTH_noFacebookEmail]:
    "Dein Versuch, dich über Meta anzumelden, war nicht erfolgreich. Das liegt daran, dass es keine gültige E-Mail-Adresse gibt, die mit deinem Meta-Konto verknüpft ist und die für die Authentifizierung in unserem System erforderlich ist.",
  [keys.AUTH_instructions]:
    "Bitte füge eine E-Mail zu deinem Meta-Konto hinzu und versuche es erneut oder melde dich mit deiner E-Mail und einem Passwort an.",
  //  [keys.AUTH_passwordNew]: "translation_missing",
  //  [keys.AUTH_emailVerification]: "translation_missing",
  //  [keys.AUTH_FBLogin]: "translation_missing",
  //  [keys.AUTH_passwordSuccess]: "translation_missing",
  //  [keys.AUTH_passwordError]: "translation_missing",
  //  [keys.AUTH_otpTitle]: "translation_missing",
  //  [keys.AUTH_otpDescription]: "translation_missing",
};

export default translate;
