import axios, { AxiosInstance } from "axios";
import {
  beforeRequestHandler,
  requestErrorHandler,
} from "./HandleGlobalInterceptors";
import "../../../utilities/variables";

const isProduction = ["production", "rc"].includes(
  process.env.NEXT_PUBLIC_APP_ENV
);

const apiEnvPrefix = isProduction ? "" : "staging-";
const apiEnvAffix = isProduction ? "" : "-staging"; // new api domain for staging env

const newCrmUrl = `https://hub${apiEnvAffix}.360dialog.io/v1`;
// const newCrmUrl = `https://${apiEnvPrefix}hub.360dialog.io/v1`;

const auth0ApiUrl = isProduction
  ? `https://360dialog.eu.auth0.com`
  : `https://tpp-360dialog.eu.auth0.com`;
const errorLogUrl = `https://${apiEnvPrefix}lp.360dialog.io`;

type instancesNames = "auth0Api" | "errorLog" | "newCrm";
type InstancesHub = {
  [k in instancesNames]: AxiosInstance;
};

export const instances: InstancesHub = {
  auth0Api: axios.create({ baseURL: auth0ApiUrl }),
  errorLog: axios.create({ baseURL: errorLogUrl }),
  newCrm: axios.create({ baseURL: newCrmUrl }),
};

class APIInstancesHub {
  private instances: InstancesHub;

  constructor(instances: InstancesHub) {
    this.instances = instances;
    this.registerGlobalRequestInterceptors();
  }

  public get buffedInstances() {
    return this.instances;
  }

  public setAuthorizationToken = (token: string) => {
    Object.keys(this.instances).forEach((name: instancesNames) => {
      if (name !== "auth0Api") {
        this.instances[name].defaults.headers[
          "Authorization"
        ] = `Bearer ${token}`;
        this.instances[name].defaults.headers["Content-Encoding"] = "gzip";
      }
    });
  };

  private registerGlobalRequestInterceptors = () => {
    const interceptors: any = {
      request: [beforeRequestHandler, requestErrorHandler],
      response: [null, requestErrorHandler],
    };
    Object.keys(this.instances).forEach((instance: instancesNames) => {
      Object.keys(interceptors).forEach((interceptor: string) => {
        const [success, error] = interceptors[interceptor];

        this.instances[instance].interceptors.request.use(success, error);
        this.instances[instance].interceptors.response.use(success, error);
      });
    });
  };
}

export const MPInstancesHub = new APIInstancesHub(instances);
