import { useRouter } from "next/router";
import * as React from "react";
import { Observer, observer } from "mobx-react-lite";
import dynamic from "next/dynamic";

import MainStoreProvider from "@/services/store/adminState/adminStateContext";
import Meta from "../Meta";
import * as Styled from "./LayoutStyles";
import NotificationStoreProvider from "@/services/store/notificationState/notificationStateContext";
import NotificationSystem from "../NotificationSystem";
import useAdminStore from "@/services/store/useAdminStore";
import AdminBanner from "../AdminBanner";
// import MPStorage from "@/services/LocalStorage";

const NavigationMenu = dynamic(() => import("../NavigationMenu"), {
  ssr: false,
});

// import NavigationMenu from "../NavigationMenu";
export type LayoutProps = {
  children: React.ReactNode;
};

const publicStaticPage = [
  "/terms-of-service",
  "/price-list",
  "/facebook-terms",
  "/404",
  "/lp/consent/openreplay",
  "/lp/consent/facebook-client-terms-for-whatsapp",
  "/lp/consent/360dialog-price-changes",
  "/lp/consent/360dialog-terms-of-service",
  "/lp/consent/openrelay",
  "/lp/consent/360dialog-payment",
];

const authPages = ["/signup", "/login", "/reset-password"];

const Layout = ({ children }: LayoutProps) => {
  const router = useRouter();
  const shouldShowNavMenu =
    !publicStaticPage.includes(router.pathname) &&
    !authPages.includes(router.pathname);
  const { adminNotification } = useAdminStore();

  return (
    <Styled.LayoutContainer>
      <Meta />
      <MainStoreProvider>
        {shouldShowNavMenu && <AdminBanner />}

        {shouldShowNavMenu && <NavigationMenu />}
        <Observer>
          {() =>
            adminNotification.notifications.length ? (
              <NotificationStoreProvider>
                <NotificationSystem
                  notificationsSystemState={adminNotification}
                />
              </NotificationStoreProvider>
            ) : (
              <></>
            )
          }
        </Observer>
        <Styled.MainContentContainer>{children}</Styled.MainContentContainer>
      </MainStoreProvider>
      {/* <Footer /> */}
    </Styled.LayoutContainer>
  );
};

export default observer(Layout);
