// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  WIZ_continueLabelWithHelp: "WIZ_continueLabelWithHelp",
  WIZ_billingPlanTitle: "WIZ_billingPlanTitle",
  WIZ_billingMethodTitle: "WIZ_billingMethodTitle",
  WIZ_billingTermsTitle: "WIZ_billingTermsTitle",
  WIZ_paymentMethodTitleStep1: "WIZ_paymentMethodTitleStep1",
  WIZ_paymentMethodTitleStep2: "WIZ_paymentMethodTitleStep2",
  WIZ_paymentMethodCountrySelectLabel: "WIZ_paymentMethodCountrySelectLabel",
  WIZ_termsTitle: "WIZ_termsTitle",
  WIZ_createNumberTitle: "WIZ_createNumberTitle",
  WIZ_numberWasSetUp: "WIZ_numberWasSetUp",
  WIZ_connectInboxTitle: "WIZ_connectInboxTitle",
  WIZ_connectInboxText: "WIZ_connectInboxText",
  WIZ_goToHubButtonLabel: "WIZ_goToHubButtonLabel",
  WIZ_channelPageCheckboxText1: "WIZ_channelPageCheckboxText1",
  WIZ_channelPageCheckboxText2: "WIZ_channelPageCheckboxText2",
  WIZ_channelPageCheckboxText3: "WIZ_channelPageCheckboxText3",
  WIZ_channelPageCheckboxText4: "WIZ_channelPageCheckboxText4",
  WIZ_channelPageSubtitle1: "WIZ_channelPageSubtitle1",
  WIZ_channelPageSubtitle2: "WIZ_channelPageSubtitle2",
  WIZ_waitingText1: "WIZ_waitingText1",
  WIZ_waitingText2: "WIZ_waitingText2",
  WIZ_addNumberText: "WIZ_addNumberText",
  WIZ_channelTitle: "WIZ_channelTitle",
  WIZ_titleSpecifiedPermission: "WIZ_titleSpecifiedPermission",
  WIZ_titleUnspecifiedPermission: "WIZ_titleUnspecifiedPermission",
  WIZ_selectedCount: "WIZ_selectedCount",
  WIZ_numbers: "WIZ_numbers",
  WIZ_selectNumbers: "WIZ_selectNumbers",
  WIZ_noPendingChannels: "WIZ_noPendingChannels",
  WIZ_verifyOwnership: "WIZ_verifyOwnership",
  WIZ_somethingWrong: "WIZ_somethingWrong",
  WIZ_NumberVerificationTitle: "WIZ_NumberVerificationTitle",
  WIZ_NumberVerificationDesc: "WIZ_NumberVerificationDesc",
};

export default keys;
