// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.TMP_name]: "Nombre",
  [keys.TMP_cat]: "Categoría",
  [keys.TMP_preview]: "Vista previa",
  [keys.TMP_lang]: "Lenguas",
  [keys.TMP_tooltipAddLang]: "Agregar lenguaje",
  [keys.TMP_tooltipCopy]: "Duplicar",
  [keys.TMP_addTemplateButtonLabel]: "Añadir template",
  [keys.TMP_emptyStateTitle]: "Aún no ha creado ninguna template de mensaje.",
  [keys.TMP_emptyStateHelptext]:
    "Los mensajes de plantilla se pueden usar para enviar notificaciones a las personas que le han permitido enviar mensajes. Puede usar plantillas para iniciar una conversación del cliente después de que se haya cerrado la ventana de la ventana de sesión de 24 horas.",
  [keys.TMP_cardHeader]: "Nueva template",
  [keys.TMP_namePlaceholder]: "Nombre de la template",
  [keys.TMP_nameLabel]: "Nombre",
  [keys.TMP_cat1]: "Auto-Reply",
  [keys.TMP_cat2]: "Actualización de cuenta",
  [keys.TMP_cat3]: "Actualización de pago",
  [keys.TMP_cat4]: "Actualización de finanzas personales",
  [keys.TMP_cat5]: "Actualización de envío",
  [keys.TMP_cat6]: "Actualización de reserva",
  [keys.TMP_cat7]: "Resolución del problema",
  [keys.TMP_cat8]: "Actualización de cita",
  [keys.TMP_cat9]: "Actualización de transporte",
  [keys.TMP_cat10]: "Actualización de ticket",
  [keys.TMP_cat11]: "Actualización de alerta",
  [keys.TMP_langLabel]: "Idioma",
  [keys.TMP_typeLabel]: "Tipo de template",
  [keys.TMP_type1]: "Predeterminado (solo texto)",
  [keys.TMP_type2]: "Media e interactivo",
  [keys.TMP_bodyPlaceholder]:
    "Inserte aquí el texto principal de su template...",
  [keys.TMP_blocksHelptext]: "Seleccione bloques de construcción de template.",
  [keys.TMP_header]: "Encabezamiento",
  [keys.TMP_body]: "Cuerpo",
  [keys.TMP_footer]: "Pie de página",
  [keys.TMP_button]: "Botón",
  [keys.TMP_buttons]: "Botones",
  [keys.TMP_chars]: "caracteres",
  [keys.TMP_vars]: "variables",
  [keys.TMP_headerMedia1]: "Texto",
  [keys.TMP_headerMedia2]: "Imagen",
  [keys.TMP_headerMedia3]: "Documento",
  [keys.TMP_headerMedia4]: "Vídeo",
  [keys.TMP_footerPlaceholder]:
    "Inserte el texto de pie de página de su template aquí...",
  [keys.TMP_headerPlaceholder]:
    "Inserte el texto del encabezamiento de su template aquí...",
  [keys.TMP_quickReply]: "Respuesta rápida",
  [keys.TMP_cta]: "Llamada a la acción",
  [keys.TMP_phoneNumber]: "Número de teléfono",
  [keys.TMP_url]: "URL",
  [keys.TMP_addButtonLabel]: "Añadir botón",
  [keys.TMP_discardButtonLabel]: "Descartar los cambios",
  [keys.TMP_previewButtonLabel]: "Vista previa y enviar",
  [keys.TMP_buttonLabelPlaceholder]:
    "Comience a ingresar el texto de su botón aquí ...",
  [keys.TMP_buttonVarHelpText]: "(debe estar al final del texto)",
  [keys.TMP_previewHeader1]: "Template",
  [keys.TMP_previewHeader2]: "Vista previa",
  [keys.TMP_exampleValues]: "Ejemplos",
  [keys.TMP_varEmptyStateHelptext1]:
    "No utilizó ningún marcador de variables en su texto.",
  [keys.TMP_varEmptyStateHelptext2]:
    "Para agregar una variable, regrese al editor e inserte {{#}} en la posición dada, donde # representa el índice de la variable, que debe comenzar en el {{1}}.",
  [keys.TMP_varEmptyStateHelptext3]:
    "Para más información, por favor consulte nuestra documentación sobre el Template Manager.",
  [keys.TMP_docsLinkLabel]: "Documentación del Template Manager",
  [keys.TMP_saveSuccess]: "¡La template fue guardada con éxito!",
  [keys.TMP_namePatternError]:
    "El nombre solo puede contener caracteres alfanuméricos en minúsculas y barra baja (_)",
  [keys.TMP_backToList]: "Vuelve a la lista",
  [keys.TMP_backToEditor]: "Volver al editor",
  [keys.TMP_submit]: "Enviar template",
  [keys.TMP_headerImgPlaceholder]: "URL de la imagen de encabezado",
  [keys.TMP_buttonUrlVarPlaceholder]: "URL de botón",
  [keys.TMP_headerDocumentPlaceholder]: "URL del documento",
  [keys.TMP_tooltipDelete]: "Eliminar template",
  [keys.TMP_deleteAllButtonLabel]: "Eliminar todos los idiomas de template",
  [keys.TMP_deleteConfirmText]:
    "¿Está seguro de que desea eliminar todos los idiomas de la template con el nombre <TEMPLATE_NAME>?",
  [keys.TMP_deleteAllConfirmButtonLabel]: "Borrar",
  [keys.TMP_deleteAllSuccess]:
    "¡Todos los idiomas de templates fueron eliminados con éxito!",
  [keys.TMP_deleteSingleSuccess]: "¡La template fue eliminada con éxito!",
  [keys.TMP_buttonsCounter]: "Botones",
  [keys.TMP_pleaseNote]: "Atención:",
  [keys.TMP_helperBulletText1]:
    "Si ese nombre de template message existe en múltiples idiomas, todos los idiomas serán eliminados.",
  [keys.TMP_helperBulletText2]:
    "Los mensajes que hayan sido mandados pero no recibidos lo reintentarán durante 30 días.",
  [keys.TMP_helperBulletText3]:
    'Si un mensaje de una message template aprobada se manda 30 días después de ser eliminado, usted recibirá un error de "Structure Unavailable"  y el cliente no recibirá el mensaje.',
  [keys.TMP_helperBulletText4]:
    "Una vez eliminado, el nombre de una template aprobada no puede ser usado durante 30 días. Use un nombre diferente para crear futuras plantillas.",
  [keys.TMP_sampleValueInfoText]:
    "Los ejemplos serán enviados a Meta como parte del proceso de aprobación de templates. Si no se dan ejemplos relevantes, las templates podrían ser rechazadas por Meta.",
  [keys.TMP_missingExamplesError]:
    "Por favor, inserte ejemplos para todas sus variables.",
  [keys.TMP_buttonPreviewHelptext]:
    "Haga click en el botón de vista previa para validar su URL.",
  [keys.TMP_syncTemplates]: "Sincroniza con Meta",
  [keys.TMP_successSyncTemplates]: "¡Éxito! ¡Las templates están actualizadas!",
  [keys.TMP_syncDone]: "Las templates están actualizadas.",
  [keys.TMP_justNow]: "ahora mismo",
  [keys.TMP_lastSync]: "Última sincronización:",
  [keys.TMP_headerVideoPlaceholder]: "URL del vídeo del encabezamiento",
  [keys.TMP_errorInvalidUrl]:
    "La URL de ejemplo proporcionada no corresponde a un archivo audiovisual válido. Por favor, corrija la URL e inténtelo de nuevo.",
  [keys.TMP_nameProposal]: "En vez de eso, use <PROPOSAL>",
  [keys.TMP_status]: "Status",
  [keys.TMP_balance]: "Saldo",
  [keys.TMP_autoRenewalStatus]: "Renovación automática Activada",
  [keys.TMP_autoRenewalThreshold]: "Límite de renovación automática",
  [keys.TMP_autoRenewalAmount]: "Valor de renovación automática",
  [keys.TMP_addFunds]: "Añadir fondos",
  [keys.TMP_editAutoRenewalAmount]: "Editar valor de renovación automática",
  [keys.TMP_editAutoRenewalThreshold]: "Editar límite de renovación automática",
  [keys.TMP_errorInvalidCharacter]: "Carácter invalido",
  [keys.TMP_errorImageExtensions]:
    "Las extensiones de archivo permitidas son JPG y PNG",
  [keys.TMP_errorVideoExtensions]:
    "Las extensiones de archivo permitidas son MP4 y 3gpp",
  [keys.TMP_buttonAddVariable]: "Añadir variable",
  [keys.TMP_newLineFooterWarning]:
    "El pie de página no debe contener un salto de línea.",
  [keys.TMP_emojiWarning]: "Emojis sólo pueden ser usados al cuerpo del texto",
  [keys.TMP_searchPlaceholder]: "Buscar por nombre",
  [keys.TMP_cat12]: "Transaccional",
  [keys.TMP_cat13]: "Marketing",
  [keys.TMP_cat14]: "Contraseñas de un solo uso",
  [keys.TMP_tooltipReplicate]: "Replicar plantilla",
  [keys.TMP_tooltipAddLangToTemplate]:
    "Agregar un idioma diferente a una plantilla",
  [keys.TMP_tooltipEditTemplate]: "Editar plantilla",
  //  [keys.TMP_tooltiMetaSync]: "translation_missing",
  [keys.TMP_ditFieldDisabled]: "Este campo no puede editarse",
  [keys.TMP_editSuccess]:
    "¡La plantilla ha sido actualizada con éxito! Según las restricciones de Meta, puede haber un retraso de hasta 24 horas antes de que los cambios se sincronicen con Meta.",
  //  [keys.TMP_editFieldDisabled]: "translation_missing",
  //  [keys.TMP_sampleRequiredValueInfoText]: "translation_missing",
  //  [keys.TMP_metaSync]: "translation_missing",
  //  [keys.TMP_tooltiMetaSync]: "translation_missing",
  //  [keys.TMP_cat15]: "translation_missing",
  //  [keys.TMP_cat16]: "translation_missing",
  //  [keys.TMP_categoryTooltip_1]: "translation_missing",
  //  [keys.TMP_categoryTooltip_2]: "translation_missing",
  //  [keys.TMP_categoryTooltip_3]: "translation_missing",
  //  [keys.TMP_categoryRejectionTooltip_1a]: "translation_missing",
  //  [keys.TMP_categoryRejectionTooltip_1b]: "translation_missing",
  //  [keys.TMP_categoryRejectionTooltip_2_embedded]: "translation_missing",
  //  [keys.TMP_categoryRejectionTooltip_2_classic]: "translation_missing",
  //  [keys.TMP_categoryRejectionTooltip_3]: "translation_missing",
  //  [keys.TMP_allowCategoryChange]: "translation_missing",
  //  [keys.TMP_headerMedia5]: "translation_missing",
  //  [keys.TMP_allowCategoryChangeTooltip_1]: "translation_missing",
  //  [keys.TMP_allowCategoryChangeTooltip_2]: "translation_missing",
  //  [keys.TMP_headerLocationPlaceholder]: "translation_missing",
  //  [keys.TMP_timeIndicatorTooltip]: "translation_missing",
  //  [keys.TMP_securityDesclaimer]: "translation_missing",
  //  [keys.TMP_expirationWarning]: "translation_missing",
  //  [keys.TMP_timeIndicator]: "translation_missing",
  //  [keys.TMP_timeIndicatorErrorMessage]: "translation_missing",
  //  [keys.TMP_textCopiedToClipboard]: "translation_missing",
  //  [keys.TMP_seeAllOptions]: "translation_missing",
};

export default translate;
