// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  SIGNALS_trackYourCtwa: "SIGNALS_trackYourCtwa",
  SIGNALS_enableTool: "SIGNALS_enableTool",
  SIGNALS_enableSignalsPage: "SIGNALS_enableSignalsPage",
  SIGNALS_learnMore: "SIGNALS_learnMore",
  SIGNALS_oneStepAway: "SIGNALS_oneStepAway",
  SIGNALS_inOrderToUseFeature: "SIGNALS_inOrderToUseFeature",
  SIGNALS_connectFacebookAccount: "SIGNALS_connectFacebookAccount",
  SIGNALS_step: "SIGNALS_step",
  SIGNALS_soundsGood: "SIGNALS_soundsGood",
};

export default keys;
