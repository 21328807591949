// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.APP_inbox_title]:
    "Einrichten und Verbinden eines WhatsApp Business-Kontos mit get.chat",
  [keys.APP_inbox_step1]: "Tarifauswahl und Checkout",
  [keys.APP_inbox_step2]: "Richte dein WhatsApp Business-Konto ein",
  [keys.APP_inbox_step3]: "Beende dein Onboarding mit get.chat",
  [keys.APP_inbox_bullet1]: "Kostenloses WhatsApp-Business-Konto",
  [keys.APP_inbox_bullet2]: "19 EUR für die shared Inbox von get.chat",
  [keys.APP_inbox_termsTitle]: "Get.chats Geschäftsbedingungen",
  [keys.APP_apps]: "Apps",
  [keys.APP_addApps]: "Apps hinzufügen",
  [keys.APP_browse]: "Marktplatz durchsuchen",
  [keys.APP_addDescription]:
    "Jede deiner Telefonnummern kann mit einer App eines Drittanbieters verbunden werden, z. B. mit Posteingängen, Chatbots und CRM-Systemen",
  [keys.APP_addSubDescription]:
    "Wir bauen unseren Marktplatz derzeit gemeinsam mit mehr als 700+ Partnern auf. Im Moment kannst du mit einem <BOLD>Team-Posteingang<BOLD> starten, der von <BOLD>get.chat<BOLD> bereitgestellt wird.",
  [keys.APP_getChat]: "get.chat",
  [keys.APP_setUp]: "get.chat-Posteingang einrichten",
  [keys.APP_connectApp]: "App verbinden",
  [keys.APP_numberRegistration]: "Weiter zur Nummernregistrierung",
  [keys.APP_addGetChat]: "get.chat hinzufügen",
  [keys.APP_activateInbox]: "Aktiviere deinen Posteingang",
  [keys.APP_faq1]: "Was ist ein WhatsApp-Team-Posteingang?",
  [keys.APP_answer1]:
    "Ein WhatsApp-Team-Posteingang ist eine grafische Benutzeroberfläche für dich und dein Team. Der Web-Posteingang ist technisch gesehen ein Frontend der Integrations-API. Sie ermöglicht dir den Zugriff mehrerer Benutzer, wenn es um das Senden und Empfangen deiner WA Business Account Nachrichten geht. Einfach ausgedrückt ist die Web Inbox ein kollaborativer Posteingang, der mehreren Teammitgliedern die Möglichkeit gibt, über ein gemeinsames Dashboard mit Kunden über WhatsApp zu kommunizieren.",
  [keys.APP_faq2]: "Welchen Posteingang aktiviere ich?",
  [keys.APP_answer2]:
    "Es wird ein get.chat-Posteingang für dich erstellt. Der Posteingang von get.chat ermöglicht es deinem Kundenserviceteam, auf Kundenanfragen über WhatsApp gleichzeitig und über verschiedene Geräte zu antworten. Es verwandelt deinen WhatsApp-Posteingang in einen angenehmen Kommunikationsraum sowohl für die Kunden als auch für das Team und macht es einfach und effizient, den Kundensupport für dein Unternehmen zu verwalten.",
  [keys.APP_faq3]: "Wie hoch ist der Preis für diesen Service?",
  [keys.APP_answer3]:
    "Da du ein Kunde von 360dialog bist, ist dieser Dienst für dich völlig kostenlos.",
  [keys.APP_activateInboxCheckbox]:
    "Ich bin mit den <LINK>https://get.chat/terms-of-service | Nutzungsbedingungen<LINK> von get.chat einverstanden.",
  [keys.APP_captchaNewApiMessage]:
    "Bitte beachte, dass durch die Aktivierung deines Posteingangs ein neuer API-Schlüssel generiert wird und dein alter nicht mehr funktioniert. Wenn du eine aktive Integration hast, wird diese auch nicht mehr funktionieren.",
  [keys.APP_captchaNewApiCheckbox]:
    "Ich verstehe, dass mein alter API-Schlüssel, falls ich einen habe, nicht mehr funktioniert",
  [keys.APP_inboxAccess]: "Zugang zum Posteingang",
  [keys.APP_inboxCredentials]:
    "Verwende die folgenden Anmeldedaten, um dich bei deinem Posteingang anzumelden",
  [keys.APP_inboxAccessClickButton]:
    "Um auf deinen Posteingang zuzugreifen, klicke auf die Schaltfläche 'Zugang'.",
  [keys.APP_accessInbox]: "Zugang zum Posteingang",
  [keys.APP_settingUpInboxTitle]: "Einrichten des Posteingangs",
  [keys.APP_settingUpInbox]:
    "Einrichten deines Posteingangs für die Nummer <NUMMER>. Dieser Vorgang kann ein paar Minuten dauern.",
  [keys.APP_pageTitle]: "360dialog - Apps mit Nummern verbinden",
  [keys.APP_pageDescription]:
    "Verbundene Apps anzeigen und Apps mit Nummern verbinden",
  //  [keys.APP_notificationEmail]: "translation_missing",
  //  [keys.APP_checkYourEmail]: "translation_missing",
  //  [keys.APP_emailSent]: "translation_missing",
  //  [keys.APP_followSteps]: "translation_missing",
};

export default translate;
