// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.MIG_progressTitle]: "Progresso da migração do número",
  [keys.MIG_progressStep1]: "Enviar dados da conta",
  [keys.MIG_progressStep2]: "Aceitar messaging on behalf",
  [keys.MIG_progressStep3]: "Verificar o número de telefone",
  [keys.MIG_numberHintTitle]:
    "Por favor, digite o número de telefone a ser migrado abaixo.",
  [keys.MIG_numberhintText1]:
    "É importante certificar que a autenticação de dois fatores para este número encontra-se desativada.",
  [keys.MIG_numberhintText2]:
    "Dica: Números entre parênteses não serão considerados.",
  [keys.MIG_mobTitle]: "Message on behalf",
  [keys.MIG_mobText]:
    "Ótimo! O próximo passo para configurar a sua conta WhatsApp Business API é logar no seu Meta Business Manager e aceitar a solicitação de Message on Behalf que a 360dialog te enviou.",
  [keys.MIG_openRequestsButtonLabel]: "Abrir Meta Business Manager",
  [keys.MIG_alreadyAcceptedMob]:
    "Assim que você aceitar a solicitação de message on behalf, por favor atualize o status da solicitação clicando no botão abaixo.",
  [keys.MIG_pleaseReturnAfterMob]:
    "Por favor, volte a esta página e continue abaixo depois de ter aceito nossa solicitação.",
  [keys.MIG_mobCheckboxLabel]:
    "Já aceitei a Mensage on Behalf em meu Gerenciador de Negócios do Meta",
  [keys.MIG_checkStatusButtonLabel]: "Atualizar o Status da solicitação",
  [keys.MIG_checkAgainIn]: "Confira o status novamente em <COUNTDOWN> segundos",
  [keys.MIG_mobSuccessText]:
    "Você aceitou a nossa solicitação de message on behalf com sucesso.",
  [keys.MIG_continueMobButtonLabel]:
    "Continuar para a verificação do número de telefone",
  [keys.MIG_verifyTitle]: "Verificar a propriedade do número de telefone",
  [keys.MIG_verifyText1]:
    "O último passo para ativar sua conta WhatsApp Business API e acessar a sua API key é verificar que você é dono desse número de telefone.",
  [keys.MIG_verifyText2]:
    "Para verificar o seu número de telefone, você precisa enviar um código por mensagem de texto SMS ou ligação de voz usando a opção abaixo. Um código PIN de 6 dígitos será enviado para o seu número de telefone.",
  [keys.MIG_verifyRadioLabel]: "Selecionar um método de verificação",
  [keys.MIG_verifyRadioOptionText]: "Mensagem de texto (SMS)",
  [keys.MIG_verifyRadioOptionVoice]: "Ligação de voz",
  [keys.MIG_verifySelectInfo]: "Selecione o idioma",
  [keys.MIG_triggerRegistrationButtonLabel]: "Acionar envio do código",
  [keys.MIG_codeWillBeSent]: "Código será enviado para <NUMBER>.",
  [keys.MIG_alreadyHaveCode]: "Já tem o código?",
  [keys.MIG_codeWasSent]: "Código foi enviado para <NUMBER>",
  [keys.MIG_codeInputLabel]: "Código de verificação",
  [keys.MIG_submitCodeButtonLabel]: "Enviar código",
  [keys.MIG_requestNewCodeIn]:
    "Você poderá solicitar um novo código em {{time}}s",
  [keys.MIG_requestNewCodeButtonLabel]: "Solicitar novo código",
  [keys.MIG_errorHint]: "Por favor tente novamente ou solicite um novo código.",
  [keys.MIG_setupTitle]: "Configurando o seu número",
  [keys.MIG_setupText1]:
    "No momento estamos configurando o seu número a sua conta WhatsApp Business API. Isso pode demorar alguns minutos. Por favor espere.",
  [keys.MIG_setupText2]:
    "Por favor NÃO feche essa janela. Você poderá gerar usa API key logo depois dessa configuração!",
  [keys.MIG_registrationFailed]: "Falha no registro",
  [keys.MIG_errorMobTitle]:
    "A solicitação de message on behalf ainda não foi aceita",
  [keys.MIG_errorMobText]:
    "Antes de verificar seu número de telefone, você precisa primeiro aceitar a nossa solicitação de message on behalf. Caso contrário, não poderemos portar seu número de telefone.",
  [keys.MIG_errorMobInfo]:
    "Mais informações sobre como aprovar a solicitação podem ser encontradas em nossa <LINK>https://docs.360dialog.com/submission-process#4-approve-the-messaging-on-behalf-request | documentação<LINK>.",
  [keys.MIG_error2faTitle]: "Autenticação de dois fatores ainda não desativada",
  [keys.MIG_error2faText]:
    "Antes de verificar a propriedade de seu número de telefone, você precisa primeiro desativar a autenticação de dois fatores (2FA) para este número. Isto pode ser feito por você ou pelo Provedor de Soluções Empresariais, que atualmente é responsável por este número.",
  [keys.MIG_error2faInfo]:
    "Visite nossa <LINK>https://docs.360dialog.com | guia para saber mais sobre como desativar 2FA para diferentes BSPs<LINK>.",
  [keys.MIG_bspDialogTitle]: "Antes de começar",
  [keys.MIG_bspDialogText]:
    "Por favor confira se a Autenticação de Dois Fatores (Two-Factor Authentication / 2FA) está desabilitada para o número que você vai migrar. O seu número está registrado em uma dessas empresas? Por favor entre em contato com eles para desabilitar o 2FA antes de começar.",
  [keys.MIG_buttonLabelRetrySetup]: "Retentar configuração",
  [keys.MIG_fbErrorNewNumber]:
    "Este número de telefone está elegível para ser adicionado diretamente, e não precisa ser migrado. Por favor vá ao dialog Client Hub e o adicione como um novo número.",
  [keys.MIG_fbErrorAlreadyPorted]:
    "O número de telefone que você está tentando migrar já foi movido para a WhatsApp Account de destino. Por favor log in no 360dialog Client Hub para continuar.",
  [keys.MIG_fbErrorDifferentBusiness]:
    "As WhatsApp Business Accounts de fonte e destino precisam representar a mesma empresa. Por favor, use a mesma Meta Business ID enviada anteriormente quando enviar o seu número para migração.",
  [keys.MIG_fbErrorUnknown]:
    "Algo deu errado quando tentamos migrar o seu número. Por favor tente novamente depois de um tempo. Se isso não funcionar, por favor entre em contato com o nosso suporte através do 360dialog Client Hub.",
  [keys.MIG_fbErrorInvalidNumber]:
    "Seu número de telefone não parece ser válido. Favor verificá-lo. Aguarde 5 minutos antes de tentar novamente.",
  [keys.MIG_fbErrorNotVerified]:
    "Sua conta comercial WhatsApp deve ser aprovada.",
  [keys.MIG_fbErrorWabaNotCorrectlySetup]:
    "A conta WhatsApp com a qual este número está registrado não está configurada corretamente.",
  [keys.MIG_fbErrorWaitNewCode]:
    "Você tem que esperar até que possa acionar novamente seu código. Por favor, espere <HOUR> e <MINUTES> antes de tentar novamente.",
  //  [keys.MIG_importNumber]: "translation_missing",
  //  [keys.MIG_enterNumber]: "translation_missing",
  //  [keys.MIG_enterNumberDescription]: "translation_missing",
  //  [keys.MIG_selectWaba]: "translation_missing",
  //  [keys.MIG_selectWabaDescription]: "translation_missing",
  //  [keys.MIG_createWabaTitle]: "translation_missing",
  //  [keys.MIG_createWabaDescription]: "translation_missing",
  //  [keys.MIG_createWabaWarning]: "translation_missing",
  //  [keys.MIG_createWabaSteps]: "translation_missing",
  //  [keys.MIG_createWabaFollowSteps]: "translation_missing",
  //  [keys.MIG_createWabaStep1]: "translation_missing",
  //  [keys.MIG_createWabaStep1Description]: "translation_missing",
  //  [keys.MIG_createWabaStep2]: "translation_missing",
  //  [keys.MIG_createWabaStep2Description]: "translation_missing",
  //  [keys.MIG_createWabaStep3]: "translation_missing",
  //  [keys.MIG_createWabaStep3Description]: "translation_missing",
  //  [keys.MIG_createWabaError]: "translation_missing",
  //  [keys.MIG_proceedInClientHub]: "translation_missing",
  //  [keys.MIG_numberMigrationTitle]: "translation_missing",
  //  [keys.MIG_proceedMigrationText1]: "translation_missing",
  //  [keys.MIG_proceedMigrationText2]: "translation_missing",
  //  [keys.MIG_continueWithMigration]: "translation_missing",
  //  [keys.MIG_migrateToDifferentWaba]: "translation_missing",
  //  [keys.MIG_migrateSuccess]: "translation_missing",
  //  [keys.MIG_validCode]: "translation_missing",
  //  [keys.MIG_invalidCode]: "translation_missing",
  //  [keys.MIG_verifyOwnership]: "translation_missing",
  //  [keys.MIG_resendCode]: "translation_missing",
  //  [keys.MIG_requestNewCode]: "translation_missing",
  //  [keys.MIG_migrateNumber]: "translation_missing",
  //  [keys.MIG_importOtherBSP]: "translation_missing",
  //  [keys.MIG_importTo360dialog]: "translation_missing",
  //  [keys.MIG_importFromOtherBSP]: "translation_missing",
  //  [keys.MIG_phoneNumberDescription]: "translation_missing",
  //  [keys.MIG_accessMetaBusinessManager]: "translation_missing",
  //  [keys.MIG_howCanIFindIt]: "translation_missing",
  //  [keys.MIG_howCanIFindItText]: "translation_missing",
  //  [keys.MIG_provideWabaId]: "translation_missing",
  //  [keys.MIG_migrateExisting]: "translation_missing",
  //  [keys.MIG_repeatProcess]: "translation_missing",
  //  [keys.MIG_settingUpNumber]: "translation_missing",
  //  [keys.MIG_channelNotReady]: "translation_missing",
  //  [keys.MIG_disabled2faCheckbox]: "translation_missing",
  //  [keys.MIG_disable2faStepOne]: "translation_missing",
  //  [keys.MIG_disable2faStepTwo]: "translation_missing",
  //  [keys.MIG_disable2fa]: "translation_missing",
  //  [keys.MIG_disable2faDescription]: "translation_missing",
  //  [keys.MIG_disable2faFollowSteps]: "translation_missing",
  //  [keys.MIG_businessVerifiedCheckbox]: "translation_missing",
  //  [keys.MIG_howDoIDisable2fa]: "translation_missing",
  //  [keys.MIG_importFromOtherProvider]: "translation_missing",
  //  [keys.MIG_ifYouCannotFindWabaId]: "translation_missing",
  //  [keys.MIG_clickToCreateWabaAccount]: "translation_missing",
  //  [keys.MIG_createWabaAccount]: "translation_missing",
  //  [keys.MIG_iHaveCreatedTheWabaACcount]: "translation_missing",
  //  [keys.MIG_youNeedToVerify]: "translation_missing",
  //  [keys.MIG_verifyOwnershipTitle]: "translation_missing",
  //  [keys.MIG_verifyNumber]: "translation_missing",
  //  [keys.MIG_continueWithEs]: "translation_missing",
  //  [keys.MIG_shareWabaWithUs1]: "translation_missing",
  //  [keys.MIG_shareWabaWithUs2]: "translation_missing",
  //  [keys.MIG_shareWabaWithUs3]: "translation_missing",
  //  [keys.MIG_shareWabaWithUs4]: "translation_missing",
  //  [keys.MIG_somethingWentWrongEs]: "translation_missing",
  //  [keys.MIG_disabledPlanInfo]: "translation_missing",
  // [keys.MIG_missingPlans]: "translation_missing",
};

export default translate;
