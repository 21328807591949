import { MPInstanceAdsV2 } from "@/services/api/RequestAxios/RequestAxiosAds";

import * as Types from "./funnels.types";

const getFunnelsURL = ({ clientId }: { clientId: string }) =>
  `/clients/${clientId}/funnels/`;

const getFunnelURL = ({
  clientId,
  funnelId,
}: {
  clientId: string;
  funnelId: string;
}) => `/clients/${clientId}/funnels/${funnelId}`;

const createFunnelURL = ({ clientId }: { clientId: string }) =>
  `/clients/${clientId}/funnels/`;

const updateFunnelURL = ({
  clientId,
  funnelId,
}: {
  clientId: string;
  funnelId: string;
}) => `/clients/${clientId}/funnels/${funnelId}`;

const deleteFunnelURL = ({
  clientId,
  funnelId,
}: {
  clientId: string;
  funnelId: string;
}) => `/clients/${clientId}/funnels/${funnelId}`;

export const getFunnels = async ({ clientId }: { clientId: string }) => {
  const { data } = await MPInstanceAdsV2.buffedInstances.get<
    Types.IServerResponseFunnel[]
  >(getFunnelsURL({ clientId }));

  return data;
};

export const createFunnel = async ({
  clientId,
  name,
  actionsSequence,
}: {
  clientId: string;
  name: string;
  actionsSequence: string[];
}) => {
  const data = await MPInstanceAdsV2.buffedInstances.post(
    createFunnelURL({ clientId }),
    {
      name,
      actions_sequence: actionsSequence,
    }
  );

  return data;
};

export const updateFunnel = async ({
  clientId,
  funnelId,
  name,
  actionsSequence,
}: {
  clientId: string;
  funnelId: string;
  name?: string;
  actionsSequence: string[];
}) => {
  const data = await MPInstanceAdsV2.buffedInstances.put(
    updateFunnelURL({ clientId, funnelId }),
    {
      actions_sequence: actionsSequence,
      ...(name ? { name } : {}),
    }
  );

  return data;
};

export const deleteFunnel = async ({
  clientId,
  funnelId,
}: {
  clientId: string;
  funnelId: string;
}) => {
  const { data } = await MPInstanceAdsV2.buffedInstances.delete(
    deleteFunnelURL({ clientId, funnelId })
  );

  return data;
};

export const getFunnel = async ({
  clientId,
  funnelId,
}: {
  clientId: string;
  funnelId: string;
}) => {
  const { data } =
    await MPInstanceAdsV2.buffedInstances.get<Types.IServerResponseFunnel>(
      getFunnelURL({ clientId, funnelId })
    );

  return data;
};
