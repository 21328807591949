import { MPInstancesHub } from "../RequestAxios/RequestAxiosHub";
import { unpackNotifications } from "./notifications.mapper";
import {
  IQueryParams,
  packQueryParams,
  stringifyQueryParams,
} from "@/api/utils";

const getAllNotificationsURL = "/user-notifications";

const markNotificationAsReadURL = "/user-notifications/mark_as_read";

export const getNotifications = async (queryParams?: IQueryParams) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get(
    getAllNotificationsURL,
    {
      params: queryParams && packQueryParams(queryParams),
      paramsSerializer: stringifyQueryParams,
    }
  );

  return unpackNotifications(data.data);
};

export const getAllNotifications = async () => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get(
    getAllNotificationsURL
  );

  return unpackNotifications(data.data);
};

export const markNotificationAsRead = async (ids: string[]) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    markNotificationAsReadURL,
    { notification_ids: ids }
  );

  return data.data;
};
