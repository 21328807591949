import { createContext } from "react";
import { NotificationState } from "./notificationState";

const store: NotificationState = new NotificationState();
export const NotificationStore = createContext<NotificationState>(store);

const NotificationStoreProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <NotificationStore.Provider value={store}>
      {children}
    </NotificationStore.Provider>
  );
};

export default NotificationStoreProvider;
