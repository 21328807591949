// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.APP_inbox_title]:
    "Set up and connect a WhatsApp Business Account with get.chat",
  [keys.APP_inbox_step1]: "Plan selection and checkout",
  [keys.APP_inbox_step2]: "Set up your WhatsApp Business Account",
  [keys.APP_inbox_step3]: "Finish your onboarding with get.chat",
  [keys.APP_inbox_bullet1]: "Free WhatsApp Business Account",
  [keys.APP_inbox_bullet2]: "19 EUR for get.chat's shared inbox",
  [keys.APP_inbox_termsTitle]: "Get.chat's terms and conditions",
  [keys.APP_apps]: "Apps",
  [keys.APP_addApps]: "Add apps",
  [keys.APP_browse]: "Browse marketplace",
  [keys.APP_addDescription]:
    "Each of your phone numbers can be connected to a third-party app, such as inboxes, chatbots and CRM systems",
  [keys.APP_addSubDescription]:
    "We're currently building our marketplace together with more than 700+ partners. Right now you can get started with a <BOLD>team inbox<BOLD> provided by <BOLD>get.chat<BOLD>",
  [keys.APP_getChat]: "get.chat",
  [keys.APP_setUp]: "Set up get.chat inbox",
  [keys.APP_connectApp]: "Connect app",
  [keys.APP_numberRegistration]: "Continue to number registration",
  [keys.APP_addGetChat]: "Add get.chat",
  [keys.APP_activateInbox]: "Activate your inbox",
  [keys.APP_faq1]: "What is a WhatsApp team inbox?",
  [keys.APP_answer1]:
    "A WhatsApp team inbox is a graphical user interface for you and your team. The Web Inbox is technically a frontend of the Integration API. It allows you to have multiple user access when it comes to sending and receiving your WA Business Account Messages. In simple terms, the Web Inbox is a collaborative inbox that gives multiple team members the ability to respond to clients over WhatsApp through a shared dashboard.",
  [keys.APP_faq2]: "What inbox am I activating?",
  [keys.APP_answer2]:
    "A Get.chat inbox is going to be generated for you. Get.chat's inbox allows your customer support team to respond to customer queries over WhatsApp simultaneously and through different devices. It turns your WhatsApp inbox into a pleasant communication space for both the clients and the team, making it easy and efficient to manage customer support for your business.",
  [keys.APP_faq3]: "What is the price of this service?",
  [keys.APP_answer3]:
    "As you are a 360dialog client, this service is totally free for you.",
  [keys.APP_activateInboxCheckbox]:
    "I agree with Get.chat's <LINK>https://get.chat/terms-of-service | Terms of service<LINK>",
  [keys.APP_captchaNewApiMessage]:
    "Please be aware that by activating your inbox, a new API Key will be generated and your old one won't be functional anymore. If you have any active integration, it will also stop functioning.",
  [keys.APP_captchaNewApiCheckbox]:
    "I understand that my old API Key, if I have one, won't be functional anymore",
  [keys.APP_inboxAccess]: "Inbox access",
  [keys.APP_inboxCredentials]:
    "Use the following credentials to log in into your inbox",
  [keys.APP_inboxAccessClickButton]:
    "To access your inbox click on the 'access button below'",
  [keys.APP_accessInbox]: "Access inbox",
  [keys.APP_settingUpInboxTitle]: "Setting up your inbox",
  [keys.APP_settingUpInbox]:
    "Setting up your inbox for the number <NUMBER>. This process might take a couple of minutes.",
  [keys.APP_pageTitle]: "360dialog - Connect Apps to Number",
  [keys.APP_pageDescription]: "View connected apps and connect apps to numbers",
  [keys.APP_notificationEmail]:
    "This process might take a couple of minutes. You will soon receive an email with further steps to activate the inbox.",
  [keys.APP_checkYourEmail]: "Please check your email",
  [keys.APP_emailSent]: "An email has been sent to you.",
  [keys.APP_followSteps]:
    "Please follow the provided steps to activate the inbox.",
};

export default translate;
