// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.KEY_existingTitle]: "Existierender API Schlüssel",
  [keys.KEY_existingHelp1]:
    "Es existiert bereits ein aktiver API Schlüssel. Sobald du einen neuen Schlüssel erstellst, wird der alte Schlüssel überschrieben und nicht mehr verwendbar sein.",
  [keys.KEY_existingHelp2]:
    "Durch das Fortfahren wirst du deinen neu erstellten API Schlüssel in deiner Anwendung aktualisieren müssen.",
  [keys.KEY_newTitle]: "Dein API Schlüssel",
  [keys.KEY_newHelp]:
    "Bitte kopiere deinen neuen API Schlüssel und speichere ihn an einem sicheren Ort. Nachdem du dieses Fenster schließt, wird der Schlüssel weder für dich noch für 360dialog zugänglich sein.",
  [keys.KEY_key]: "API Schlüssel:",
  [keys.KEY_copy]: "Kopieren",
  [keys.KEY_copied]: "Kopiert",
  [keys.KEY_address]: "Adresse:",
  [keys.KEY_confirmCopy]: "Ich habe den API Schlüssel kopiert und gespeichert",
  [keys.KEY_partnerInfoText]:
    "Klicke hier um deinen API Schlüssel bei <PARTNER> zu platzieren.",
  [keys.KEY_partnerInfoButtonLabel]: "Weiter zu <PARTNER>",
  //  [keys.KEY_generateKeyIntro]: "translation_missing",
};

export default translate;
