// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  SUP_tickets: "SUP_tickets",
  SUP_id: "SUP_id",
  SUP_title: "SUP_title",
  SUP_number: "SUP_number",
  SUP_status: "SUP_status",
  SUP_addComment: "SUP_addComment",
  SUP_newTicket: "SUP_newTicket",
  SUP_contactSupport: "SUP_contactSupport",
  SUP_channelSupportHelptext: "SUP_channelSupportHelptext",
  SUP_topic: "SUP_topic",
  SUP_selectTopicError: "SUP_selectTopicError",
  SUP_selectWabaError: "SUP_selectWabaError",
  SUP_selectNumber: "SUP_selectNumber",
  SUP_selectNumberError: "SUP_selectNumberError",
  SUP_selectWabaFirst: "SUP_selectWabaFirst",
  SUP_messagePlaceholder: "SUP_messagePlaceholder",
  SUP_other: "SUP_other",
  SUP_numberNotAvailable: "SUP_numberNotAvailable",
  SUP_send: "SUP_send",
  SUP_back: "SUP_back",
  SUP_emptyStateTitle: "SUP_emptyStateTitle",
  SUP_emptyStateHelptext: "SUP_emptyStateHelptext",
  SUP_success: "SUP_success",
  SUP_successComment: "SUP_successComment",
  SUP_commentOn: "SUP_commentOn",
  SUP_yourTickets: "SUP_yourTickets",
  SUP_docsLink: "SUP_docsLink",
  SUP_docsLinkSubtitle: "SUP_docsLinkSubtitle",
  SUP_shortcutsLink: "SUP_shortcutsLink",
  SUP_shortcutsLinkSubtitle: "SUP_shortcutsLinkSubtitle",
  SUP_apiStatusLink: "SUP_apiStatusLink",
  SUP_apiStatusLinkSubtitle: "SUP_apiStatusLinkSubtitle",
  SUP_supportLink: "SUP_supportLink",
  SUP_supportLinkSubtitle: "SUP_supportLinkSubtitle",
  SUP_openJiraLink: "SUP_openJiraLink",
  SUP_busVerificationIssue: "SUP_busVerificationIssue",
  SUP_newSubmission: "SUP_newSubmission",
  SUP_unableToSubmit: "SUP_unableToSubmit",
  SUP_requestIVR: "SUP_requestIVR",
  SUP_otherQuestion: "SUP_otherQuestion",
  SUP_messagingNotWorking: "SUP_messagingNotWorking",
  SUP_internalServerErr: "SUP_internalServerErr",
  SUP_sandboxSupport: "SUP_sandboxSupport",
  SUP_hubAccess: "SUP_hubAccess",
  SUP_commercePolicyRejection: "SUP_commercePolicyRejection",
  SUP_click2WhatsApp: "SUP_click2WhatsApp",
  SUP_numberPorting: "SUP_numberPorting",
  SUP_nameChange: "SUP_nameChange",
  SUP_nameRejected: "SUP_nameRejected",
  SUP_cancellation: "SUP_cancellation",
  SUP_onPremise: "SUP_onPremise",
  SUP_paymentMethod: "SUP_paymentMethod",
  SUP_govAccount: "SUP_govAccount",
  SUP_outsideWorkHours: "SUP_outsideWorkHours",
  SUP_openStatusPage: "SUP_openStatusPage",
  SUP_allSystemsNormal: "SUP_allSystemsNormal",
  SUP_expIssues: "SUP_expIssues",
  SUP_underMaintenance: "SUP_underMaintenance",
  SUP_warningCreateTicket1: "SUP_warningCreateTicket1",
  SUP_warningCreateTicket2: "SUP_warningCreateTicket2",
  SUP_statusLinkSubtitle: "SUP_statusLinkSubtitle",
  SUP_messageLengthError: "SUP_messageLengthError",
  SUP_clickHereButtonLabel: "SUP_clickHereButtonLabel",
  SUP_paymentMethods: "SUP_paymentMethods",
  SUP_attachFile: "SUP_attachFile",
  SUP_includeAllTickets: "SUP_includeAllTickets",
  SUP_reporter: "SUP_reporter",
  SUP_optionsLoading: "SUP_optionsLoading",
  SUP_roadmap: "SUP_roadmap",
  SUP_roadmapSubtitle: "SUP_roadmapSubtitle",
  SUP_inReview: "SUP_inReview",
  SUP_responseViaEmail: "SUP_responseViaEmail",
  SUP_onboarding: "SUP_onboarding",
  SUP_onboardingDescription: "SUP_onboardingDescription",
  SUP_problemWithMessaging: "SUP_problemWithMessaging",
  SUP_messagingProblemsDescription: "SUP_messagingProblemsDescription",
  SUP_accManagement: "SUP_accManagement",
  SUP_accManagementDescription: "SUP_accManagementDescription",
  SUP_offboarding: "SUP_offboarding",
  SUP_offboardingDescription: "SUP_offboardingDescription",
  SUP_financeAndBilling: "SUP_financeAndBilling",
  SUP_financeAndBillingDescription: "SUP_financeAndBillingDescription",
  SUP_otherQuestionDescription: "SUP_otherQuestionDescription",
  SUP_visitStatusPage1: "SUP_visitStatusPage1",
  SUP_visitStatusPage2: "SUP_visitStatusPage2",
  SUP_useIntercom: "SUP_useIntercom",
};

export default keys;
