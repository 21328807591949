// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.CompanyCountry]: "País de la empresa",
  [keys.ChannelsSubmit]: "Enviar",
  [keys.Step]: "Paso",
};

export default translate;
