// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.APP_inbox_title]:
    "Configurar e conectar uma conta de WhatsApp Business com a get.chat",
  [keys.APP_inbox_step1]: "Seleção de plano e checkout",
  [keys.APP_inbox_step2]: "Configure sua conta de WhatsApp Business",
  [keys.APP_inbox_step3]: "Termine seu onboarding com a get.chat",
  [keys.APP_inbox_bullet1]: "Conta gratuita de WhatsApp Business",
  [keys.APP_inbox_bullet2]:
    "19 EUR para a caixa de entrada compartilhada do get.chat",
  [keys.APP_inbox_termsTitle]: "Termos e condições da Get.chat",
  [keys.APP_apps]: "Aplicativos",
  [keys.APP_addApps]: "Adicionar apps",
  [keys.APP_browse]: "Procurar no marketplace",
  [keys.APP_addDescription]:
    "Cada um de seus números de telefone pode ser conectado a um aplicativo de terceiros, como caixas de entrada, chatbots e sistemas de CRM",
  [keys.APP_addSubDescription]:
    "Estamos atualmente construindo nosso mercado junto com mais de 700+ parceiros. Agora mesmo você pode começar com uma <BOLD>team inbox<BOLD> fornecida por <BOLD>get.chat<BOLD>",
  [keys.APP_getChat]: "get.chat",
  [keys.APP_setUp]: "Configurar a caixa de entrada get.chat",
  [keys.APP_connectApp]: "Conectar aplicativo",
  [keys.APP_numberRegistration]: "Continuar para registro de números",
  [keys.APP_addGetChat]: "Adicionar get.chat",
  [keys.APP_activateInbox]: "Ative sua caixa de entrada",
  [keys.APP_faq1]: "O que é uma caixa de entrada da equipe WhatsApp?",
  [keys.APP_answer1]:
    "Uma caixa de entrada da equipe WhatsApp é uma interface gráfica do usuário para você e sua equipe. A Caixa de Entrada Web é tecnicamente um frontend da API de Integração. Ela permite que você tenha acesso a múltiplos usuários quando se trata de enviar e receber suas mensagens WA Business Account Messages. Em termos simples, a Web Inbox é uma caixa de entrada colaborativa que dá a múltiplos membros da equipe a capacidade de responder aos clientes sobre o WhatsApp através de um painel de controle compartilhado.",
  [keys.APP_faq2]: "Qual caixa de entrada eu estou ativando?",
  [keys.APP_answer2]:
    "Uma caixa de entrada Get.chat vai ser gerada para você. A caixa de entrada do Get.chat permite que sua equipe de suporte ao cliente responda às consultas dos clientes sobre o WhatsApp simultaneamente e através de diferentes dispositivos. Ela transforma sua caixa de entrada do WhatsApp em um agradável espaço de comunicação tanto para os clientes quanto para a equipe, tornando fácil e eficiente a gestão do suporte ao cliente para o seu negócio",
  [keys.APP_faq3]: "Qual é o preço deste serviço?",
  [keys.APP_answer3]:
    "Como você é um cliente 360dialog, este serviço é totalmente gratuito para você.",
  [keys.APP_activateInboxCheckbox]:
    "Eu concordo com o Get.chat's <LINK>https://get.chat/terms-of-service | Termos do serviço<LINK>",
  [keys.APP_captchaNewApiMessage]:
    "Por favor, esteja ciente de que ativando sua caixa de entrada, uma nova chave API será gerada e sua antiga não estará mais funcional. Se você tiver alguma integração ativa, ela também deixará de funcionar.",
  [keys.APP_captchaNewApiCheckbox]:
    "Eu entendo que minha antiga chave API, se eu tiver uma, não será mais funcional",
  [keys.APP_inboxAccess]: "Acesso à caixa de entrada",
  [keys.APP_inboxCredentials]:
    "Use as seguintes credenciais para entrar em sua caixa de entrada",
  [keys.APP_inboxAccessClickButton]:
    "Para acessar sua caixa de entrada, clique no 'botão de acesso abaixo'.",
  [keys.APP_accessInbox]: "Acesso à caixa de entrada",
  [keys.APP_settingUpInboxTitle]: "Configurando sua caixa de entrada",
  [keys.APP_settingUpInbox]:
    "Configurando sua caixa de entrada para o número <NUMBER>. Este processo pode demorar alguns minutos.",
  [keys.APP_pageTitle]: "360dialog - Conecte os aplicativos aos números",
  [keys.APP_pageDescription]:
    "Veja os aplicativos conectados e conecte aplicativos aos números",
  //  [keys.APP_notificationEmail]: "translation_missing",
  //  [keys.APP_checkYourEmail]: "translation_missing",
  //  [keys.APP_emailSent]: "translation_missing",
  //  [keys.APP_followSteps]: "translation_missing",
};

export default translate;
