import { createContext } from "react";
import AdminState from "./adminState";

const store: AdminState = new AdminState();
export const MainStore = createContext<AdminState>({} as AdminState);

const MainStoreProvider = ({ children }: { children: React.ReactNode }) => {
  return <MainStore.Provider value={store}>{children}</MainStore.Provider>;
};

export default MainStoreProvider;
