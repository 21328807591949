import { observable, action, computed, makeObservable } from "mobx";
import translate from "../../../utilities/i18n/getTranslate";
import { k } from "../../../utilities/i18n";
import * as types from "./notificationsState.types";

export type TEMPLATE_NAME =
  | ""
  | "link-copied"
  | "connection-error"
  | "conversation-error-id"
  | "conversation-list-error";

export enum conversationMessagesTags {
  NO_ERRORS = "",
  UNEXPECTED = "unexpected",
  LOADING_PROBLEM = "loadingProblem",
}

export enum notificationsTypes {
  ERROR = "error",
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
}

const errorMessages: types.errorMessagesTyping = {
  [conversationMessagesTags.NO_ERRORS]: "",
  [conversationMessagesTags.UNEXPECTED]: translate(k.InboxConversationNotFound),
  [conversationMessagesTags.LOADING_PROBLEM]: translate(
    k.InboxConversationMessagesLoadingProblem
  ),
};

export class NotificationState {
  notifications: Notification[] = [];
  templateName: TEMPLATE_NAME = "";
  conversationList = "";
  conversationMessages: conversationMessagesTags =
    conversationMessagesTags.NO_ERRORS;
  id = 0;
  types = notificationsTypes;

  constructor() {
    makeObservable(this, {
      notifications: observable,
      templateName: observable,
      conversationList: observable,
      conversationMessages: observable,
      id: observable,
      errorMessageForMessages: computed,
      add: action,
      remove: action,
      clearPopup: action,
    });
  }

  private defaultNotificationValues = {
    id: 0,
    message: "",
    type: notificationsTypes.INFO,
    showCloseButton: true,
    timeoutToClose: 10,
    showCount: false,
    counter: 1,
    restartTimeCounter: false,
    shouldCloseAutomatically: true,
  };

  add = (notification: types.NewNotification) => {
    this.id++;
    notification.id = this.id;
    if (notification.id) {
      const notificationWithDefaults: Notification = {
        ...this.defaultNotificationValues,
        ...notification,
      };
      const indexIfAlreadyExist = this.notifications.findIndex(
        (notification) =>
          notification.message === notificationWithDefaults.message &&
          notification.type === notificationWithDefaults.type
      );
      if (indexIfAlreadyExist !== -1) {
        this.notifications[indexIfAlreadyExist].counter++;
        this.notifications[indexIfAlreadyExist].timeoutToClose =
          notificationWithDefaults.timeoutToClose;
        this.notifications[indexIfAlreadyExist].restartTimeCounter = true;
      } else {
        this.notifications.push(new Notification(notificationWithDefaults));
      }
    }
  };

  remove = (id: number) => {
    const notificationToRemovePosition = this.notifications.findIndex(
      (notification) => id === notification.id
    );
    this.notifications.splice(notificationToRemovePosition, 1);
  };

  get errorMessageForMessages() {
    return errorMessages[this.conversationMessages];
  }

  clearPopup() {
    this.templateName = "";
  }
}

class Notification {
  id: number;
  message: React.ReactNode;
  type: notificationsTypes;
  showCloseButton: boolean;
  timeoutToClose: number;
  showCount: boolean;
  onClickAction?: () => void;
  counter: number;
  restartTimeCounter: boolean;
  action?: types.ToastActionType;
  shouldCloseAutomatically?: boolean;

  constructor(notification: types.Notification) {
    this.id = notification.id;
    this.message = notification.message;
    this.type = notification.type;
    this.showCloseButton = notification.showCloseButton;
    this.timeoutToClose = notification.timeoutToClose;
    this.showCount = notification.showCount;
    this.counter = notification.counter;
    this.onClickAction = notification.onClickAction;
    this.restartTimeCounter = notification.restartTimeCounter;
    this.action = notification.action;
    this.shouldCloseAutomatically = notification.shouldCloseAutomatically;
    makeObservable(this, {
      counter: observable,
      restartTimeCounter: observable,
    });
  }
}

const store = new NotificationState();

export default store;
