import translate from "@/utilities/i18n/getTranslate";
import { k } from "@/utilities/i18n";
import { NotificationState } from "../store/notificationState/notificationState";
import config from "@/config";
import store from "store";
import { format } from "date-fns";

// Define constant keys for cookies
const COOKIE_KEYS = {
  CONSENT: "cookieConsent",
  ANALYTICS: "cookieAnalytics",
  MARKETING: "cookieMarketing",
  PREFERENCES: "cookiePreferences",
};

declare global {
  interface Window {
    Cookiebot: {
      show: () => void;
      onaccept?: () => void;
      onconsentchanged?: () => void;
      consent: {
        necessary: boolean;
        preferences: boolean;
        statistics: boolean;
        marketing: boolean;
      };
      consented: boolean;
      regulations: {
        gdprApplies: boolean;
      };
      setCookies?: boolean;
    };
    CookiebotCallback_OnDecline?: () => void;
  }
}

type SetCookie = (
  name: string,
  value: string,
  options?: {
    path?: string;
    expires?: Date;
    maxAge?: number;
    domain?: string;
    secure?: boolean;
    httpOnly?: boolean;
    sameSite?: boolean | "none" | "lax" | "strict";
    encode?: (value: string) => string;
  }
) => void;

const commonCookieProps = {
  path: "/",
  domain: config.domain,
  sameSite: false,
  secure: false,
};

const initCookiebotScript = (
  setCookie: SetCookie,
  adminNotification: NotificationState
) => {
  const script = document.createElement("script");
  script.src = "https://consent.cookiebot.com/uc.js";
  script.id = "Cookiebot";
  script.setAttribute("data-cbid", config.COOKIEBOT_APP_ID);
  // script.setAttribute("data-blockingmode", "auto");
  script.async = true;
  script.onclose;
  script.onload = () => {
    if (
      !window.Cookiebot?.regulations?.gdprApplies &&
      !window.Cookiebot?.setCookies
    ) {
      window.Cookiebot.setCookies = true;
      setCookie(COOKIE_KEYS.CONSENT, "true", commonCookieProps);
      setCookie(COOKIE_KEYS.ANALYTICS, "true", commonCookieProps);
      setCookie(COOKIE_KEYS.MARKETING, "true", commonCookieProps);
      setCookie(COOKIE_KEYS.PREFERENCES, "true", commonCookieProps);
      return;
    }

    window.addEventListener("CookiebotOnDialogDisplay", () => {
      window.Cookiebot.onaccept = () => {
        onAccept(setCookie, adminNotification);
      };
      window.CookiebotCallback_OnDecline = function () {
        // User declined cookies or set all 3 selectable to false
        setCookie(COOKIE_KEYS.CONSENT, "true", commonCookieProps);
        setCookie(COOKIE_KEYS.ANALYTICS, "false", commonCookieProps);
        setCookie(COOKIE_KEYS.MARKETING, "false", commonCookieProps);
        setCookie(COOKIE_KEYS.PREFERENCES, "false", commonCookieProps);
        adminNotification.add({
          message: translate(k.PRE_cookiesChanged),
          timeoutToClose: 6,
          type: adminNotification.types.SUCCESS,
        });
      };
    });
  };
  script.onerror = () => {
    console.error("Failed to load Cookiebot script");
  };
  document.body.appendChild(script);
};

const triggerCookiebot = (
  setCookie: SetCookie,
  adminNotification: NotificationState
) => {
  if (window.Cookiebot) {
    window.Cookiebot.show();
    // Set the onaccept function if cookies have been accepted
    if (window.Cookiebot.consent?.necessary) {
      window.Cookiebot.onaccept = () => {
        onAccept(setCookie, adminNotification);
      };
    }
  }
};

const onAccept = (
  setCookie: SetCookie,
  adminNotification: NotificationState
) => {
  // Check if Cookiebot has the new consent data
  if (window.Cookiebot?.consent) {
    const { necessary, preferences, statistics, marketing } =
      window.Cookiebot.consent;
    // Update the local cookies
    setCookie(COOKIE_KEYS.CONSENT, necessary.toString(), commonCookieProps);
    setCookie(COOKIE_KEYS.ANALYTICS, statistics.toString(), commonCookieProps);
    setCookie(COOKIE_KEYS.MARKETING, marketing.toString(), commonCookieProps);
    setCookie(
      COOKIE_KEYS.PREFERENCES,
      preferences.toString(),
      commonCookieProps
    );
    store.set(
      `${config.localStoragePrefixMP}.cookieConsentSigned`,
      format(new Date(), "dd.MM.yyyy")
    );
    adminNotification.add({
      message: translate(k.PRE_cookiesChanged),
      timeoutToClose: 6,
      type: adminNotification.types.SUCCESS,
    });
  }
};

export { initCookiebotScript, triggerCookiebot };
