import * as Types from "./wabaTemplates.types";
import { MPInstancesHub } from "@/services/api/RequestAxios/RequestAxiosHub";
import {
  unpackWabaTemplates,
  unpackWabaTemplate,
} from "./wabaTemplates.mapper";
import { UIModelWabaTemplates } from "./wabaTemplates.model";
import { IQueryParams, packQueryParams } from "../utils";
import { toJS } from "mobx";
import qs from "qs";

const wabaTemplatesURL = ({
  partnerId,
  wabaAccountId,
}: {
  partnerId: string;
  wabaAccountId: string;
}) => `/partners/${partnerId}/waba_accounts/${wabaAccountId}/waba_templates`;

const wabaTemplatesImportURL = ({
  partnerId,
  wabaAccountId,
}: {
  partnerId: string;
  wabaAccountId: string;
}) =>
  `/partners/${partnerId}/waba_accounts/${wabaAccountId}/waba_templates/import`;

const wabaTemplateURL = ({
  partnerId,
  wabaAccountId,
  templateId,
}: {
  partnerId: string;
  wabaAccountId: string;
  templateId: string;
}) =>
  `/partners/${partnerId}/waba_accounts/${wabaAccountId}/waba_templates/${templateId}`;

const wabaTemplateControlDeleteURL = ({
  partnerId,
  wabaAccountId,
}: {
  partnerId: string;
  wabaAccountId: string;
}) =>
  `/partners/${partnerId}/waba_accounts/${wabaAccountId}/control/delete_template`;

export const getWabaTemplates = async (
  { partnerId, wabaAccountId }: { partnerId: string; wabaAccountId: string },
  queryParams?: IQueryParams
): Promise<UIModelWabaTemplates> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseWabaTemplates>(
      wabaTemplatesURL({
        partnerId,
        wabaAccountId,
      }),
      {
        params: queryParams && packQueryParams(queryParams),
        paramsSerializer: (params) => {
          const { filters, ...otherParams } = params;
          const serializedQueryString = qs.stringify(otherParams, {
            arrayFormat: "repeat",
          });

          return `${serializedQueryString}&filters=${JSON.stringify(
            toJS(filters)
          )}`;
        },
      }
    );

  return new UIModelWabaTemplates(unpackWabaTemplates(data));
};

export const addWabaTemplate = async (
  { partnerId, wabaAccountId }: { partnerId: string; wabaAccountId: string },
  payload: Types.WabaTemplatePayload
): Promise<Types.IUIResponseWabaTemplate> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseWabaTemplate>(
      wabaTemplatesURL({
        partnerId,
        wabaAccountId,
      }),
      payload
    );

  return unpackWabaTemplate(data);
};

export const syncWabaTemplates = async ({
  partnerId,
  wabaAccountId,
}: {
  partnerId: string;
  wabaAccountId: string;
}): Promise<{}> => {
  const data = await MPInstancesHub.buffedInstances.newCrm.post<{}>(
    wabaTemplatesImportURL({
      partnerId,
      wabaAccountId,
    }),
    {}
  );

  return data;
};

export const deleteWabaTemplate = async ({
  partnerId,
  wabaAccountId,
  templateId,
}: {
  partnerId: string;
  wabaAccountId: string;
  templateId: string;
}): Promise<void> => {
  await MPInstancesHub.buffedInstances.newCrm.delete<Types.IServerResponseWabaTemplate>(
    wabaTemplateURL({
      partnerId,
      wabaAccountId,
      templateId,
    })
  );
};

export const deleteAllWabaTemplates = async (
  {
    partnerId,
    wabaAccountId,
  }: {
    partnerId: string;
    wabaAccountId: string;
  },
  payload: { name: string }
): Promise<void> => {
  await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseWabaTemplate>(
    wabaTemplateControlDeleteURL({
      partnerId,
      wabaAccountId,
    }),
    payload
  );
};
