import { MPInstanceAds } from "@/services/api/RequestAxios/RequestAxiosAds";

import * as Types from "./copilot.types";

const getCopilotReportURL = ({
  clientId,
  businessPhoneNumber,
}: {
  clientId: string;
  businessPhoneNumber: string;
}) => `/clients/${clientId}/phones/${businessPhoneNumber}/copilot/report`;

export const getCopilotReport = async ({
  clientId,
  businessPhoneNumber,
  dateFrom,
  dateTo,
}: {
  clientId: string;
  businessPhoneNumber: string;
  dateFrom: string;
  dateTo: string;
}) => {
  const { data } =
    await MPInstanceAds.buffedInstances.get<Types.IServerResponseCopilotReport>(
      getCopilotReportURL({ clientId, businessPhoneNumber }),
      {
        params: {
          since: dateFrom,
          until: dateTo,
        },
      }
    );

  return data;
};
