// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.MIG_progressTitle]: "Number Porting Progress",
  [keys.MIG_progressStep1]: "Submit account details",
  [keys.MIG_progressStep2]: "Accept messaging on behalf",
  [keys.MIG_progressStep3]: "Verify phone number",
  [keys.MIG_numberHintTitle]:
    "Please enter the phone number to be ported below.",
  [keys.MIG_numberhintText1]:
    "It is important that you ensure two-factor authentication for this number is disabled.",
  [keys.MIG_numberhintText2]:
    "Hint: Numbers within parentheses will not be part of the submitted number.",
  [keys.MIG_mobTitle]: "Message on behalf",
  [keys.MIG_mobText]:
    "Great! The next step to set up your WhatsApp Business API Account is to log into your Meta Business Manager and accept the Message on Behalf request that 360dialog has sent you.",
  [keys.MIG_openRequestsButtonLabel]: "Open Meta Business Manager",
  [keys.MIG_alreadyAcceptedMob]:
    "After you have accepted the message on behalf request, please refresh the status of the request by clicking on the button below.",
  [keys.MIG_pleaseReturnAfterMob]:
    "Please return to this page and continue below after you've accepted our request.",
  [keys.MIG_mobCheckboxLabel]:
    "I have accepted the Message on Behalf request in my Meta Business Manager",
  [keys.MIG_checkStatusButtonLabel]: "Refresh Request Status",
  [keys.MIG_checkAgainIn]: "Check status again in <COUNTDOWN> s",
  [keys.MIG_mobSuccessText]:
    "You have succesfully accepted our message of behalf request. ",
  [keys.MIG_continueMobButtonLabel]: "Continue to phone number verification",
  [keys.MIG_verifyTitle]: "Verify phone number ownership",
  [keys.MIG_verifyText1]:
    "The last step to activate your WhatsApp Business API account and access your API key, is to verify your phone number ownership. ",
  [keys.MIG_verifyText2]:
    "To verify your phone number, you need to request a registration code either via SMS text message or voice call below. A 6 Digit PIN Code will be sent to your phone number.",
  [keys.MIG_verifyRadioLabel]: "Select a verification method",
  [keys.MIG_verifyRadioOptionText]: "Text message (SMS)",
  [keys.MIG_verifyRadioOptionVoice]: "Voice call",
  [keys.MIG_verifySelectInfo]:
    "Select the language in which you want to receive the registration code",
  [keys.MIG_triggerRegistrationButtonLabel]: "Trigger registration",
  [keys.MIG_codeWillBeSent]: "Code will be sent to <NUMBER>.",
  [keys.MIG_alreadyHaveCode]:
    "Already have a code? <BUTTON>Continue to next step<BUTTON>.",
  [keys.MIG_codeWasSent]: "Code was sent to <NUMBER>",
  [keys.MIG_codeInputLabel]: "Registration code",
  [keys.MIG_submitCodeButtonLabel]: "Submit code",
  [keys.MIG_requestNewCodeIn]: "Request new code in {{time}}s",
  [keys.MIG_requestNewCodeButtonLabel]: "Request new code",
  [keys.MIG_errorHint]:
    "The code could not be verified. Please try again or request a new code.",
  [keys.MIG_setupTitle]: "Setting up your number",
  [keys.MIG_setupText1]:
    "We're currently setting up your number and starting the hosted WhatsApp Business API client. This may take a few minutes. Please wait.",
  [keys.MIG_setupText2]:
    "Please do NOT close this window. You will be able to create your API key right afterwards!",
  [keys.MIG_registrationFailed]: "Registration failed",
  [keys.MIG_errorMobTitle]: "Message on behalf request not yet accepted",
  [keys.MIG_errorMobText]:
    "Before you can verify your phone number ownership you first need to accept our message on behalf request. Otherwise we will not be able to port your number.",
  [keys.MIG_errorMobInfo]:
    "More information on how to approve the request can be found in our <LINK>https://docs.360dialog.com/submission-process#4-approve-the-messaging-on-behalf-request | documentation<LINK>.",
  [keys.MIG_error2faTitle]: "Two-factor authentication not yet disabled",
  [keys.MIG_error2faText]:
    "Before you can verify your phone number ownership you first need to disable two-factor authentication (2FA) for this number. This can be done either by you or by the Business Solution Provider, that is currently in charge of this number.",
  [keys.MIG_error2faInfo]:
    "Visit our <LINK>https://docs.360dialog.com | guide on how to disable 2FA for different BSPs<LINK>.",
  [keys.MIG_bspDialogTitle]: "Before you start",
  [keys.MIG_bspDialogText]:
    "Please make sure that Two-Factor Authentication (2FA) is disabled for the number you're about to port. Is your number registered with one of these companies? Please reach out to them to disable 2FA.",
  [keys.MIG_buttonLabelRetrySetup]: "Re-try setup",
  [keys.MIG_fbErrorNewNumber]:
    "This phone number is eligible to be added directly, and does not need to be ported. Please go to the 360dialog Client Hub and add it as a new number.",
  [keys.MIG_fbErrorAlreadyPorted]:
    "The phone number you are trying to port has already been moved to your destination WhatsApp Account. Please log in to the 360dialog Client Hub to continue.",
  [keys.MIG_fbErrorDifferentBusiness]:
    "The source and destination WhatsApp Business Accounts need to represent the same business. Please use the same Meta Business ID as before when submitting the number for migration.",
  [keys.MIG_fbErrorUnknown]:
    "Something went wrong when trying to migrate your phone number. Please try again after some time. If that does not work, please contact our support via the 360dialog Client Hub.",
  [keys.MIG_fbErrorInvalidNumber]:
    "Your phone number doesn't appear to be valid. Please double check it. Please wait 5 minutes before trying again.",
  [keys.MIG_fbErrorNotVerified]:
    "Your WhatsApp Business Account must be approved.",
  [keys.MIG_fbErrorWabaNotCorrectlySetup]:
    "The WhatsApp account that this number is registered with isn't set up correctly.",
  [keys.MIG_fbErrorWaitNewCode]:
    "You have to wait until you can re-trigger your code. Please wait <HOUR> and <MINUTES> before you try again.",
  [keys.MIG_importNumber]:
    "These steps enable you to import one of your phone numbers from a WhatsApp Business Account managed by another Business Solution Provider to one managed by 360dialog.",
  [keys.MIG_enterNumber]: "Enter the phone number",
  [keys.MIG_selectWaba]: "Select a WhatsApp Business Account",
  [keys.MIG_selectWabaDescription]:
    "Select the WhatsApp Business Account you want to migrate the entered number above to. You can select one that already exists within the 360dialog Hub or you can import one directly from Meta.",
  [keys.MIG_createWabaTitle]: "Create a new Whatsapp Business Account",
  [keys.MIG_createWabaDescription]:
    "The following steps will allow you create a WhatsApp Business Account (WABA) without a number. This can then be used as a target account for your existing number to be moved to. Please read carefully the provided instructions below and follow the steps.",
  [keys.MIG_createWabaWarning]:
    "It is very important to follow the steps with the provided details below. Otherwise you will not create a WhatsApp Business Account without a number and then migrating a number into it can cause issues with messaging.",
  [keys.MIG_createWabaSteps]:
    "In order to create an empty Whatsapp Business Account, you will have to go through the Embedded Signup process. This process has several steps, but you will only have to go through some of them and drop off at the indicated moment.",
  [keys.MIG_createWabaFollowSteps]:
    "The simple steps labled below will help you understand how to complete the process successfully. Please go over them carefully.",
  [keys.MIG_createWabaStep1]: "Step 1",
  [keys.MIG_createWabaStep1Description]: "Fill in your business information",
  [keys.MIG_createWabaStep2]: "Step 2",
  [keys.MIG_createWabaStep2Description]:
    "Create your WhatsApp Business Account",
  [keys.MIG_createWabaStep3]: "Step 3",
  [keys.MIG_createWabaStep3Description]: "Exit the Embedded Signup process",
  [keys.MIG_createWabaError]:
    "We couldn't find any new WhatsApp Business Account. To proceed, you will have to repeat the process again. Please make sure you read the provided instructions carefully before triggering the Embedded Signup.",
  [keys.MIG_proceedInClientHub]:
    "Proceed with migration in 360dialog Client Hub",
  [keys.MIG_numberMigrationTitle]: "Number Migration Progress",
  [keys.MIG_proceedMigrationText1]:
    "You can now log into the new 360dialog Client Hub and proceed with the number migration. All further steps will be explained during the process.",
  [keys.MIG_proceedMigrationText2]:
    "You will be prompted to authenticate again. You can do so with the email and password, that you've just submitted",
  [keys.MIG_continueWithMigration]: "Continue with migration process",
  [keys.MIG_migrateToDifferentWaba]: "Migrate number to a different WABA",
  [keys.MIG_migrateSuccess]:
    "You have successfully migrated your channel to another WABA!",
  [keys.MIG_validCode]: "Valid code. You can proceed with the migration",
  [keys.MIG_invalidCode]:
    "Invalid code. Make sure you are providing the correct code.",
  [keys.MIG_verifyOwnership]:
    "Plese enter below the 6 digit PIN Code we sent to <NUMBER>",
  [keys.MIG_resendCode]: "Re-send code",
  [keys.MIG_requestNewCode]: "Request new code in <SECONDS>s",
  [keys.MIG_migrateNumber]: "Migrate number",
  [keys.MIG_importOtherBSP]: "Import number from another BSP",
  [keys.MIG_importTo360dialog]: "Port existing number to 360dialog",
  [keys.MIG_importFromOtherBSP]: "Import from another BSP",
  [keys.MIG_phoneNumberDescription]:
    "This phone number should match the one currently in use by the other Business Solution Provider.",
  [keys.MIG_accessMetaBusinessManager]:
    "Please access your <LINK>https://business.facebook.com | Meta Business Manager<LINK> and get the ID of the WhatsApp Business Account below",
  [keys.MIG_howCanIFindIt]: "How can I find it?",
  [keys.MIG_howCanIFindItText]:
    "You can find your WhatsApp Business Account ID in the Accounts section, on the top center side of the WhatsApp Accounts page, as shown below.",
  [keys.MIG_provideWabaId]: "Provide the WhatsApp Business Account ID",
  [keys.MIG_migrateExisting]: "Migrate existing number",
  [keys.MIG_repeatProcess]: "Repeat the process",
  [keys.MIG_settingUpNumber]:
    "We're currently setting up your number. This may take a few moments.",
  [keys.MIG_channelNotReady]: "Channel status not ready for migration",
  [keys.MIG_disabled2faCheckbox]:
    "I have disabled 2FA for the number <NUMBER>.",
  [keys.MIG_disable2faStepOne]:
    "1. Go to <LINK>https://business.facebook.com | Business settings<LINK> → WhatsApp Manager → Phone Numbers → Settings",
  [keys.MIG_disable2faStepTwo]:
    '2. Select Two-Step Verification in the left menu and click on "Turn off two-step verification" button',
  [keys.MIG_disable2fa]:
    "Disable 2 Factor Authentication and check if your Business Verification is approved",
  [keys.MIG_disable2faDescription]:
    "In order for the phone numbers to be migrated in or out of 360dialog, 2FA has to be disabled and Business Verification must be completed and approved.",
  [keys.MIG_disable2faFollowSteps]:
    "To disable 2FA, please follow the steps below:",
  [keys.MIG_businessVerifiedCheckbox]:
    "I have verified my business in Meta Business Manager",
  [keys.MIG_howDoIDisable2fa]: "How do I disable 2FA?",
  [keys.MIG_importFromOtherProvider]: "Import number from another provider",
  [keys.MIG_ifYouCannotFindWabaId]:
    "If you cannot find any new WhatsApp Business Account, you will have to repeat the process again. Please make sure you read the provided instructions carefully.",
  [keys.MIG_clickToCreateWabaAccount]:
    "Click on the button below to create the WhatsApp Business Account. Once created you can click on the provided checkbox and continue the process.",
  [keys.MIG_createWabaAccount]: "Create WhatsApp Business Account",
  [keys.MIG_iHaveCreatedTheWabaACcount]:
    "I have just created the WhatsApp Business Account as the previous steps indicate.",
  [keys.MIG_youNeedToVerify]:
    "You have 24h to verify ownership for this number. Otherwise it will be deleted, and you'll need to start over.",
  [keys.MIG_verifyOwnershipTitle]: "Verify ownership",
  [keys.MIG_verifyNumber]: "Verify number",
  [keys.MIG_continueWithEs]: "Continue with the Embedded Signup",
  [keys.MIG_shareWabaWithUs1]:
    "Share destination WhatsApp Business Account with us",
  [keys.MIG_shareWabaWithUs2]:
    "In order to import your WhatsApp Business Account, you will have to go through the Embedded Signup process.",
  [keys.MIG_shareWabaWithUs3]:
    "In the process, you will be asked to select a Meta Business Account and the WhatsApp Business Account you want to share. Note:",
  [keys.MIG_shareWabaWithUs4]:
    "It is very important that you share a new WhatsApp Business Account, or select one that has never been in use. Otherwise, the messaging API will not work.",
  [keys.MIG_somethingWentWrongEs]:
    "Something went wrong during the Embedded Signup process. Please try again.",
  [keys.MIG_disabledPlanInfo]:
    "If any of the plans are disabled and you would like to make a change related to given number, please contact our support for assistance with plan change",
  [keys.MIG_missingPlans]:
    "The target partner does not have any plan attached to it, please contact support",
};

export default translate;
