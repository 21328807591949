import config from "@/config";

enum SessionStorageKeys {
  REDIRECT_TO_HUB = "REDIRECT_TO_HUB",
}

type SessionStorageItemKey =
  | SessionStorageKeys
  | keyof typeof SessionStorageKeys;

const save = (key: SessionStorageItemKey, value: unknown) => {
  try {
    const stringValue =
      typeof value !== "string" ? JSON.stringify(value) : value;

    globalThis.sessionStorage?.setItem(
      `${config.localStoragePrefixMP}.${key}`,
      stringValue
    );
  } catch {}
};

const remove = (key: SessionStorageItemKey) =>
  globalThis.sessionStorage?.removeItem(
    `${config.localStoragePrefixMP}.${key}`
  );

const get = (key: SessionStorageItemKey) => {
  const value = globalThis.sessionStorage?.getItem(
    `${config.localStoragePrefixMP}.${key}`
  );

  return value ?? undefined;
};

const pop = (key: SessionStorageItemKey) => {
  const value = globalThis.sessionStorage?.getItem(
    `${config.localStoragePrefixMP}.${key}`
  );
  if (value) {
    remove(key);
  }
  return value;
};

const clearAll = () => {
  globalThis.sessionStorage?.clear();
};

const MPSessionStorage = {
  remove,
  save,
  get,
  keys: SessionStorageKeys,
  pop,
  clearAll,
};

export default MPSessionStorage;
