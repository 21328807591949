// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  BV_advertising: "BV_advertising",
  BV_automotive: "BV_automotive",
  BV_beauty: "BV_beauty",
  BV_clothing: "BV_clothing",
  BV_consumer_goods: "BV_consumer_goods",
  BV_ecommerce: "BV_ecommerce",
  BV_education: "BV_education",
  BV_energy: "BV_energy",
  BV_entertainment: "BV_entertainment",
  BV_entertainment_media: "BV_entertainment_media",
  BV_event_planning: "BV_event_planning",
  BV_banking: "BV_banking",
  BV_food: "BV_food",
  BV_gaming: "BV_gaming",
  BV_government: "BV_government",
  BV_hotel: "BV_hotel",
  BV_IT: "BV_IT",
  BV_marketing: "BV_marketing",
  BV_medical: "BV_medical",
  BV_non_profit: "BV_non_profit",
  BV_organizations: "BV_organizations",
  BV_other: "BV_other",
  BV_professional: "BV_professional",
  BV_public: "BV_public",
  BV_retail: "BV_retail",
  BV_restaurant: "BV_restaurant",
  BV_shopping: "BV_shopping",
  BV_technology: "BV_technology",
  BV_telecom: "BV_telecom",
  BV_travel: "BV_travel",
  BV_transportation: "BV_transportation",
  BV_financial: "BV_financial",
};

export default keys;
