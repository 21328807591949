// File generated automatically. Do not edit manually.
import onboarding from "./onboarding/keys";
import preferences from "./preferences/keys";
import dashboard from "./dashboard/keys";
import inbox from "./inbox/keys";
import support from "./support/keys";
import templates from "./templates/keys";
import insights from "./insights/keys";
import auth from "./auth/keys";
import archive from "./archive/keys";
import faq from "./faq/keys";
import navigation from "./navigation/keys";
import wizard from "./wizard/keys";
import notifications from "./notifications/keys";
import channels from "./channels/keys";
import billing from "./billing/keys";
import general from "./general/keys";
import languages from "./languages/keys";
import appconnection from "./appconnection/keys";
import key from "./key/keys";
import signals from "./signals/keys";
import settings from "./settings/keys";
import facebook from "./facebook/keys";
import migrate from "./migrate/keys";
import app from "./app/keys";
import errors from "./errors/keys";
import funnels from "./funnels/keys";
import integrations from "./integrations/keys";
import numbers from "./numbers/keys";
import audiences from "./audiences/keys";
import tour from "./tour/keys";
import tos from "./tos/keys";

const allKeys: { [k: string]: string } = {
  ...app,
  ...archive,
  ...auth,
  ...billing,
  ...channels,
  ...dashboard,
  ...errors,
  ...facebook,
  ...faq,
  ...general,
  ...inbox,
  ...key,
  ...languages,
  ...migrate,
  ...navigation,
  ...onboarding,
  ...preferences,
  ...settings,
  ...support,
  ...templates,
  ...wizard,
  ...notifications,
  ...appconnection,
  ...signals,
  ...insights,
  ...funnels,
  ...integrations,
  ...numbers,
  ...audiences,
  ...tour,
  ...tos,
};

export default allKeys;
