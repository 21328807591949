import * as Types from "./accounting.types";
import {
  unpackStripePortal,
  unpackClientBalance,
  unpackCost,
  unpackAutoRecharge,
  unpackPbsAutoRecharge,
  unpackFundsResponse,
  packAutoRechargeData,
  unpackCostPbs,
} from "./accounting.mapper";
import { IUIResponseAutoRecharge, TBillingSystem } from "./accounting.types";
import { MPInstancesHub } from "@/api/RequestAxios/RequestAxiosHub";

const triggerInvoicesCreationURL = (date: string) =>
  `/invoicing/partners/invoices/${date}/control/trigger_invoice_generation`;

const triggerTemplateInvoicesCreationURL = (date: string) =>
  `/invoicing/partners/invoices/${date}/control/trigger_template_invoice_generation`;

const triggerTemplateReportCreationURL = (date: string) =>
  `/invoicing/partners/invoices/${date}/control/trigger_template_report_generation`;

const triggerCbpInvoicesCreationURL = (date: string) =>
  `/invoicing/partners/invoices/${date}/control/trigger_zoho_conversation_invoice_generation`;

const triggerCbpReportCreationURL = (date: string) =>
  `/invoicing/partners/invoices/${date}/control/trigger_conversation_monthly_report_generation`;

const triggerCbpReportDailyCreationURL = (date: string) =>
  `/invoicing/partners/invoices/${date}/control/trigger_conversation_daily_report_generation`;

const stripeCreatePortalURL = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/control/stripe_portal_session`;

const clientBalanceURL = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/info/balance`;

const partenerBalanceURL = ({ partnerId }: { partnerId: string }) =>
  `/partners/${partnerId}/info/conversation_usage`;

const channelBalanceURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/info/balance`;

const channelBalanceListURL = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/info/channels_balance`;

const addFundsURL = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/add_funds`;

const addFundsFinishURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId?: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/add_funds_finish`;

const requestRefundURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId?: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/issue_refund`;

const channelBalanceFilterURL = ({
  partnerId,
  clientId,
  projectId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/all/info/balance`;

const addChannelFundsURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/add_funds`;

const calculateCostURL = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/info/calculate_cost`;

const setAutoRechargeURL = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/control/update_auto_recharge_settings`;

const setChannelAutoRechargeURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/update_auto_recharge_settings`;

const getAutoRechargeURL = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/auto_recharge_settings`;

export const triggerInvoicesCreation = async (
  payload: Types.IUIRequestTriggerInvoicesCreation
) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    triggerInvoicesCreationURL(payload.date),
    {}
  );

  return data;
};

const getChannelAutoRechargeURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/auto_recharge_settings`;

const getChannelAutoRechargeListURL = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/channels_auto_recharge_settings`;

export const triggerTemplateInvoicesCreation = async (
  payload: Types.IUIRequestTriggerInvoicesCreation
) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    triggerTemplateInvoicesCreationURL(payload.date),
    {}
  );

  return data;
};

export const triggerTemplateReportCreation = async (
  payload: Types.IUIRequestTriggerInvoicesCreation
) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    triggerTemplateReportCreationURL(payload.date),
    {}
  );

  return data;
};

export const triggerCbpInvoicesCreation = async (
  payload: Types.IUIRequestTriggerInvoicesCreation
) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    triggerCbpInvoicesCreationURL(payload.date),
    {}
  );

  return data;
};

export const triggerCbpReportCreation = async (
  payload: Types.IUIRequestTriggerInvoicesCreation
) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    triggerCbpReportCreationURL(payload.date),
    {}
  );

  return data;
};

export const triggerCbpReportDailyCreation = async (
  payload: Types.IUIRequestTriggerInvoicesCreation
) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    triggerCbpReportDailyCreationURL(payload.date),
    {}
  );

  return data;
};

export const stripeCreatePortal = async (
  { partnerId, clientId }: { partnerId: string; clientId: string },
  redirectUrl: string
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseStripePortal>(
      stripeCreatePortalURL({
        partnerId,
        clientId,
      }),
      { redirect_url: redirectUrl }
    );

  return unpackStripePortal(data);
};

export const getClientBalance = async ({
  partnerId,
  clientId,
  appIds,
}: {
  partnerId: string;
  clientId: string;
  appIds: Array<string>;
}) => {
  const queryParams = appIds.length ? { app_ids: appIds.join(",") } : {};
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get(
    clientBalanceURL({
      partnerId,
      clientId,
    }),
    {
      params: {
        ...queryParams,
      },
    }
  );

  return unpackClientBalance(data);
};

export const getPartnerClientsBalance = async ({
  partnerId,
  appIds,
}: {
  partnerId: string;
  appIds: Array<string>;
}) => {
  const queryParams = appIds.length ? { app_ids: appIds.join(",") } : {};
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get(
    partenerBalanceURL({
      partnerId,
    }),
    {
      params: {
        ...queryParams,
      },
    }
  );

  return unpackClientBalance(data);
};

export const getChannelBalance = async ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get(
    channelBalanceURL({
      partnerId,
      clientId,
      projectId,
      channelId,
    })
  );

  return unpackClientBalance(data);
};

export const getChannelBalanceList = async ({
  partnerId,
  clientId,
  appIds,
  filters,
}: {
  partnerId: string;
  clientId: string;
  appIds?: Array<string>;
  filters: {
    start_date?: number;
    end_date?: number;
    granularity?: "day" | "month";
  };
}) => {
  const queryParams = appIds?.length ? { app_ids: appIds.join(",") } : {};
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get(
    channelBalanceListURL({
      partnerId,
      clientId,
    }),
    {
      params: {
        ...queryParams,
        ...filters,
      },
    }
  );

  return data.map(unpackClientBalance);
};

export const getFilteredChannelsBalance = async ({
  partnerId,
  clientId,
  projectId,
  channelIds,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelIds?: Array<string>;
}) => {
  const queryParams = channelIds?.length
    ? { channel_ids: channelIds.join(",") }
    : {};
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get(
    channelBalanceFilterURL({
      partnerId,
      clientId,
      projectId,
    }),
    {
      params: {
        ...queryParams,
      },
    }
  );

  return data.map(unpackClientBalance);
};

export const addFunds = async ({
  partnerId,
  clientId,
  amount,
  currency = "EUR",
}: {
  partnerId: string;
  clientId: string;
  amount: number;
  currency: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    addFundsURL({
      partnerId,
      clientId,
    }),
    { amount, currency }
  );

  return data;
};

export const addFundsFinish = async ({
  partnerId,
  clientId,
  projectId,
  channelId,
  paymentReference,
  details,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
  paymentReference: string;
  details: Record<string, unknown>; // type with the right data structure
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    addFundsFinishURL({
      partnerId,
      clientId,
      projectId,
      channelId,
    }),
    { paymentReference, details }
  );

  return data;
};

export const addChannelFunds = async ({
  partnerId,
  clientId,
  projectId,
  channelId,
  amount,
  currency = "EUR",
  billingSystem,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
  amount: number;
  currency?: string;
  billingSystem?: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    addChannelFundsURL({
      partnerId,
      clientId,
      projectId,
      channelId,
    }),
    { amount, currency }
  );

  return unpackFundsResponse(data, billingSystem);
};

export const requestRefund = async ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  channelId?: string;
  projectId: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    requestRefundURL({
      partnerId,
      clientId,
      projectId,
      channelId,
    })
  );

  return data;
};

export const calculateCost = async ({
  partnerId,
  clientId,
  amount,
  user_initiated_amount,
  business_initiated_amount,
  isPbsClient,
}: {
  partnerId: string;
  clientId: string;
  amount?: number;
  user_initiated_amount?: number;
  business_initiated_amount?: number;
  isPbsClient: boolean;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    calculateCostURL({
      partnerId,
      clientId,
    }),
    { amount, user_initiated_amount, business_initiated_amount }
  );

  if (isPbsClient) {
    return unpackCostPbs(data);
  }

  return unpackCost(data);
};

export const getAutoRecharge = async ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get(
    getAutoRechargeURL({
      partnerId,
      clientId,
    })
  );

  return unpackAutoRecharge(data);
};

export const getChannelAutoRecharge = async ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get(
    getChannelAutoRechargeURL({
      partnerId,
      clientId,
      projectId,
      channelId,
    })
  );

  return unpackAutoRecharge(data);
};

export const getChannelAutoRechargeList = async ({
  partnerId,
  clientId,
  billingSystem,
}: {
  partnerId: string;
  clientId: string;
  billingSystem: TBillingSystem;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get(
    getChannelAutoRechargeListURL({
      partnerId,
      clientId,
    })
  );

  if (billingSystem === "stripe") {
    return data.map(unpackAutoRecharge);
  } else if (billingSystem === "pbs") {
    return data.map(unpackPbsAutoRecharge);
  }
};

export const setAutoRecharge = async ({
  partnerId,
  clientId,
  renewalData,
}: {
  partnerId: string;
  clientId: string;
  renewalData: IUIResponseAutoRecharge;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    setAutoRechargeURL({
      partnerId,
      clientId,
    }),
    {
      auto_recharge_enabled: renewalData.autoRechargeEnabled,
      recharge_balance_threshold: renewalData.rechargeThreshold || 0,
      recharge_top_up_amount: renewalData.rechargeAmount || 0,
    }
  );

  return unpackAutoRecharge(data);
};

export const setChannelAutoRecharge = async ({
  partnerId,
  clientId,
  projectId,
  channelId,
  renewalData,
  billingSystem,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
  renewalData: IUIResponseAutoRecharge;
  billingSystem: TBillingSystem;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    setChannelAutoRechargeURL({
      partnerId,
      clientId,
      projectId,
      channelId,
    }),
    packAutoRechargeData(renewalData)
  );

  if (billingSystem === "stripe") {
    return unpackAutoRecharge(data);
  } else if (billingSystem === "pbs") {
    return unpackPbsAutoRecharge(data);
  }
};
