import { MPInstanceAds } from "@/services/api/RequestAxios/RequestAxiosAds";

import * as Types from "./tour.types";

const getTourByTopicURL = ({
  clientId,
  topic,
}: {
  clientId: string;
  topic: string;
}) => `/clients/${clientId}/tours/${topic}`;

const saveTourURL = ({ clientId }: { clientId: string }) =>
  `/clients/${clientId}/tours/`;

export const getTourByTopic = async ({
  clientId,
  topic,
}: {
  clientId: string;
  topic: string;
}) => {
  const { data } =
    await MPInstanceAds.buffedInstances.get<Types.IServerResponseTourByTopic>(
      getTourByTopicURL({ clientId, topic })
    );

  return data;
};

export const saveTour = async ({
  clientId,
  topic,
  status,
}: {
  clientId: string;
  topic: string;
  status: string;
}) => {
  const { data: responseData } = await MPInstanceAds.buffedInstances.post(
    saveTourURL({ clientId }),
    { topic, status }
  );

  return responseData;
};
