import axios, { AxiosInstance } from "axios";
import {
  beforeRequestHandler,
  requestErrorHandler,
} from "./HandleGlobalInterceptors";
import "../../../utilities/variables";
import config from "@/config";

const instanceV1 = axios.create({ baseURL: config.authX360UrlV1 });

class APIInstanceAuthX360 {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    this.registerGlobalRequestInterceptors();
  }

  public get buffedInstances() {
    return this.instance;
  }

  public setAuthorizationToken(token: string) {
    try {
      this.instance.defaults.headers["Authorization"] = `Bearer ${token}`;
    } catch (error) {
      console.error(`Failed to set authorization token: ${error}`);
    }
  }

  private registerGlobalRequestInterceptors = () => {
    this.instance.interceptors.request.use(
      beforeRequestHandler,
      requestErrorHandler
    );
    this.instance.interceptors.response.use(null, requestErrorHandler);
  };
}

export const MPInstanceAuthX360 = new APIInstanceAuthX360(instanceV1);
