// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.BOX_fetchingChannels]: "Buscando números...",
  [keys.BOX_setUpInbox]: "Configurar su inbox get.chat",
  [keys.BOX_loadingText]: "Reenviando para su inbox get.chat",
  [keys.BOX_selectTheNumbers]:
    "Su cuenta incluye bandeja de entrada (inbox) gratuita, impulsada por nuestro partner get.chat. Para empezar, seleccione los números que desea conectar a su su inbox.",
  [keys.BOX_apiKeyWarning]:
    "Tenga en cuenta que no se podrá generar una clave API para estos números y, por tanto, no se podrá utilizar la API de WhatsApp de la 360dialog. Se puede utilizar las funcionalidades de la API a través de la API de WhatsApp de la get.chat.",
  [keys.BOX_createBtn]: "Crear mi inbox get.chat",
  [keys.BOX_selectInboxLabel]: "Selecionar el inbox, el cual desea abrir",
  [keys.BOX_buttonLabelConnectNew]: "Conectar nuevo número",
  [keys.BOX_disclaimerOneNumber]:
    "En este momento sólo se puede añadir un número a la vez. Seleccione un número para conectar y siga guardando sus cambios. Para añadir números adicionales, repita el proceso.",
};

export default translate;
