// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.AUD_audiences]: "Audiences",
  [keys.AUD_generalAudience]: "General Audience",
  [keys.AUD_details]: "Details",
  [keys.AUD_undelivered]: "Undelivered",
  [keys.AUD_undeliveredTipTitle]: "Check some tips about this audience!",
  [keys.AUD_undeliveredTipOne]:
    "Save this Custom Audience on Meta or download it as a CSV file",
  [keys.AUD_undeliveredTipTwo]:
    "Use the Meta Ads Manager to retarget this audience!",
  [keys.AUD_seeOnMeta]: "See on Meta",
  [keys.AUD_downloadAudience]: "Download Audience",
  [keys.AUD_noAudience]: "No Audiences registered yet",
  [keys.AUD_noAudienceDescription]:
    "There are no previous data for Custom Audiences registered yet",
  [keys.AUD_noAudienceDescriptionToS]:
    "In order to unlock all the custom audiences features you need to accept \n the Meta's Terms of Service regarding Custom Audiences for your Ad Accounts",
  [keys.AUD_createAudience]: "Create an audience of this event",
  [keys.AUD_createAudienceCTAText]: "Create Audience",
  [keys.AUD_chooseAdAccount]: "Choose Ad Account",
  [keys.AUD_createdMsg]: "Audience created successfully",
  [keys.AUD_acceptMetaTerms]: "Accept Meta's ToS",
  [keys.AUD_newText]: "New",
  [keys.AUD_introducingAudiences]: "Introducing Audiences",
  [keys.AUD_introducingAudiencesDescription]:
    "We've generated custom audiences for you, always up-to-date. Explore how to \n boost your campaign's performance with these tailored insights.",
  [keys.AUD_checkDocs]: "Check docs",
  [keys.AUD_changeAdAccounts]: "Change between your ad accounts",
};

export default translate;
