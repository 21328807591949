// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.BV_advertising]: "Werbung",
  [keys.BV_automotive]: "Automobilindustrie",
  [keys.BV_beauty]: "Schönheit, Spa und Salon",
  [keys.BV_clothing]: "Kleidung und Bekleidungsindustrie",
  [keys.BV_consumer_goods]: "Konsumgüter",
  [keys.BV_ecommerce]: "E-commerce",
  [keys.BV_education]: "Bildung",
  [keys.BV_energy]: "Energie und Versorgungsunternehmen",
  [keys.BV_entertainment]: "Unterhaltung",
  [keys.BV_entertainment_media]: "Unterhaltung und Medien",
  [keys.BV_event_planning]: "Veranstaltungsplanung und -service",
  [keys.BV_banking]: "Finanzen und Bankwesen",
  [keys.BV_food]: "Lebensmittel und Lebensmittelhandel",
  [keys.BV_gaming]: "Glücksspiel",
  [keys.BV_government]: "Regierung und Politik",
  [keys.BV_hotel]: "Hotel und Beherbergung",
  [keys.BV_IT]: "IT-Dienstleistungen",
  [keys.BV_marketing]: "Vermarktung",
  [keys.BV_medical]: "Medizin und Gesundheit",
  [keys.BV_non_profit]: "Gemeinnützig",
  [keys.BV_organizations]: "Organisationen und Verbände",
  [keys.BV_other]: "Andere",
  [keys.BV_professional]: "Professionelle Dienstleistungen",
  [keys.BV_public]: "Öffentlicher Dienst",
  [keys.BV_retail]: "Einzelhandel",
  [keys.BV_restaurant]: "Restaurant",
  [keys.BV_shopping]: "Einkaufen und Einzelhandel",
  [keys.BV_technology]: "Technologie",
  [keys.BV_telecom]: "Telekommunikation",
  [keys.BV_travel]: "Reisen",
  [keys.BV_transportation]: "Reisen und Transport",
  [keys.BV_financial]: "Finanzen und Bankwesen",
};

export default translate;
