import * as Types from "./wabaAccount.types";
import {
  wabaAccountFbAccountStatus,
  wabaAccountOnBehalfOfBusinessStatus,
  wabaAccountStatus,
  statusIndicatorsStatuses,
  mapToCommonStatusFbAccount,
  mapToCommonStatusWabaAccount,
  mapToCommonStatusOnBehalf,
  mapToLabelStatusFbAccount,
  mapToLabelStatusWabaAccount,
} from "../admin/statuses";

const UNSET_LABEL = "unset";

export type WabaAccountStatuses =
  | "main"
  | "externalWabaAccount"
  | "messageOnBehalf"
  | "fbBusinessVerification";

export class UIModelWabaAccounts {
  total: number;
  wabaAccounts: UIModelWabaAccount[];

  constructor(response: Types.IUIResponseWabaAccounts) {
    (this.total = response.total),
      (this.wabaAccounts = response.wabaAccounts.map(
        (wabaAccount) => new UIModelWabaAccount(wabaAccount)
      ));
  }
}

interface IWabaAccountStatusObject {
  indicator: statusIndicatorsStatuses;
  label: string;
}

export class UIModelWabaAccount {
  id: string;
  partnerId: string;
  clientId: string;
  externalId: string;
  fbBusinessId: string;
  debugError: any;
  name: string;
  namespace: string;
  consents: any;
  hasSignedConsents: boolean;
  fbAccountStatus: wabaAccountFbAccountStatus;
  onBehalfOfBusinessInfo: {
    name: string;
    id: string;
    status: wabaAccountOnBehalfOfBusinessStatus;
    type: string;
  } | null;
  primaryFundingId: string;
  status: wabaAccountStatus;
  timezoneId: string;
  modifiedAt: Date;
  createdAt: Date;
  // wabaAccountSetupStatus : statusIndicatorsStatuses
  wabaAccountSetupStatus: string;

  constructor(response: Types.IUIResponseWabaAccount) {
    this.update(response);
  }

  get formValues() {
    return {
      name: this.name,
      externalId: this.externalId,
      fbBusinessId: this.fbBusinessId,
      namespace: this.namespace,
      timezoneId: this.timezoneId,
      primaryFundingId: this.primaryFundingId,
    };
  }

  get externalWabaAccountMappedStatus() {
    return mapToCommonStatusWabaAccount[this.wabaAccountSetupStatus];
  }

  get messageOnBehalfMappedStatus() {
    return this.onBehalfOfBusinessInfo
      ? mapToCommonStatusOnBehalf[this.onBehalfOfBusinessInfo?.status]
      : "draft";
  }

  get fbBusinessVerificationMappedStatus() {
    return mapToCommonStatusFbAccount[this.fbAccountStatus];
  }

  get mainMappedStatus() {
    const isAnySubssatusSetToFailed = [
      this.externalWabaAccountMappedStatus,
      this.messageOnBehalfMappedStatus,
      this.fbBusinessVerificationMappedStatus,
    ].some((status) => status === "rejected");
    return isAnySubssatusSetToFailed
      ? "rejected"
      : mapToCommonStatusWabaAccount[this.status];
  }

  get statuses(): { [k in WabaAccountStatuses]: IWabaAccountStatusObject } {
    return {
      main: {
        indicator: this.mainMappedStatus,
        label: this.name,
      },
      externalWabaAccount: {
        indicator: this.externalWabaAccountMappedStatus,
        label: mapToLabelStatusWabaAccount(this.wabaAccountSetupStatus),
      },
      messageOnBehalf: {
        indicator: this.messageOnBehalfMappedStatus,
        label: this.onBehalfOfBusinessInfo?.status || UNSET_LABEL,
      },
      fbBusinessVerification: {
        indicator: this.fbBusinessVerificationMappedStatus,
        label: mapToLabelStatusFbAccount[this.fbAccountStatus] || UNSET_LABEL,
      },
    };
  }

  get isReadyToSubmit() {
    return (
      this.fbBusinessId && this.timezoneId && this.mainMappedStatus !== "done"
    );
  }

  update = (response: Types.IUIResponseWabaAccount) => {
    this.id = response.id;
    this.partnerId = response.partnerId;
    this.clientId = response.clientId;
    this.externalId = response.externalId;
    this.fbBusinessId = response.fbBusinessId;
    this.debugError = response.debugError;
    this.name = response.name;
    this.namespace = response.namespace;
    this.fbAccountStatus = response.fbAccountStatus;
    this.onBehalfOfBusinessInfo = response.onBehalfOfBusinessInfo
      ? {
          name: response.onBehalfOfBusinessInfo.name,
          id: response.onBehalfOfBusinessInfo.id,
          status: response.onBehalfOfBusinessInfo.status,
          type: response.onBehalfOfBusinessInfo.type,
        }
      : null;
    this.primaryFundingId = response.primaryFundingId;
    this.status = response.status;
    this.timezoneId = response.timezoneId;
    this.modifiedAt = new Date(response.modifiedAt);
    this.createdAt = new Date(response.createdAt);
    // this.wabaAccountSetupStatus = this.externalId ? "done" : "draft"
    this.wabaAccountSetupStatus = this.status;
    this.consents = response.consents;
    this.hasSignedConsents = response.hasSignedConsents;
  };
}
