// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  ERR_templateRenameExists: "ERR_templateRenameExists",
  ERR_noStripeCustomer: "ERR_noStripeCustomer",
  ERR_weAreSorry: "ERR_weAreSorry",
  ERR_internalIssuesTeamNotified: "ERR_internalIssuesTeamNotified",
  ERR_reloadPage: "ERR_reloadPage",
  ERR_letUsKnowWhatHappened: "ERR_letUsKnowWhatHappened",
  ERR_failedToGetCountry: "ERR_failedToGetCountry",
  ERR_errorLoadingPaymentNotification: "ERR_errorLoadingPaymentNotification",
  ERR_userNotFound: "ERR_userNotFound",
  ERR_partnerNotPermitted: "ERR_partnerNotPermitted",
  ERR_inputFields: "ERR_inputFields",
  ERR_fbConnectionError: "ERR_fbConnectionError",
  ERR_fbNoRegisteredEmail: "ERR_fbNoRegisteredEmail",
  ERR_userNoExists: "ERR_userNoExists",
  ERR_addFundsNumber: "ERR_addFundsNumber",
  ERR_autoRenewalNumber: "ERR_autoRenewalNumber",
  ERR_stripeErrorNote: "ERR_stripeErrorNote",
  ERR_filterOptionsErrorNote: "ERR_filterOptionsErrorNote",
  ERR_publicDataErrorText: "ERR_publicDataErrorText",
  ERR_somethingUnexpected: "ERR_somethingUnexpected",
  ERR_phoneRegistrationError: "ERR_phoneRegistrationError",
  ERR_userWithNoClientPermissionError: "ERR_userWithNoClientPermissionError",
  ERR_somethingWentWrong: "ERR_somethingWentWrong",
};

export default keys;
