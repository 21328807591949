// File generated automatically. Do not edit manually.
import app from "./app/pt-br";
import archive from "./archive/pt-br";
import auth from "./auth/pt-br";
import billing from "./billing/pt-br";
import channels from "./channels/pt-br";
import dashboard from "./dashboard/pt-br";
import errors from "./errors/pt-br";
import facebook from "./facebook/pt-br";
import faq from "./faq/pt-br";
import general from "./general/pt-br";
import inbox from "./inbox/pt-br";
import key from "./key/pt-br";
import languages from "./languages/pt-br";
import migrate from "./migrate/pt-br";
import navigation from "./navigation/pt-br";
import onboarding from "./onboarding/pt-br";
import preferences from "./preferences/pt-br";
import settings from "./settings/pt-br";
import support from "./support/pt-br";
import templates from "./templates/pt-br";
import wizard from "./wizard/pt-br";
import notifications from "./notifications/pt-br";
import appconnection from "./appconnection/pt-br";
import signals from "./signals/pt-br";
import insights from "./insights/pt-br";
import funnels from "./funnels/pt-br";
import integrations from "./integrations/pt-br";
import numbers from "./numbers/pt-br";
import audiences from "./audiences/pt-br";
import tour from "./tour/pt-br";
import tos from "./tos/pt-br";

const allTranslations: { [k: string]: string } = {
  ...app,
  ...archive,
  ...auth,
  ...billing,
  ...channels,
  ...dashboard,
  ...errors,
  ...facebook,
  ...faq,
  ...general,
  ...inbox,
  ...key,
  ...languages,
  ...migrate,
  ...navigation,
  ...onboarding,
  ...preferences,
  ...settings,
  ...support,
  ...templates,
  ...wizard,
  ...notifications,
  ...appconnection,
  ...signals,
  ...insights,
  ...funnels,
  ...integrations,
  ...numbers,
  ...audiences,
  ...tour,
  ...tos,
};

export default allTranslations;
