// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.BOX_fetchingChannels]: "Fetching numbers...",
  [keys.BOX_setUpInbox]: "Set up your get.chat inbox",
  [keys.BOX_loadingText]: "Forwarding to your get.chat inbox",
  [keys.BOX_selectTheNumbers]:
    "Your account includes a free inbox, powered by our partner get.chat. To get started, select the numbers that you want to connect to your inbox below. You will be asked to create a new account in the get.chat inbox application.",
  [keys.BOX_apiKeyWarning]:
    "Please be aware that you won't be able to generate an API key for these numbers and therefore won't be able to use the 360dialog WhatsApp API. Existing API keys will become unusable. You can use API functionalities through get.chat's WhatsApp API.",
  [keys.BOX_createBtn]: "Create my get.chat inbox",
  [keys.BOX_selectInboxLabel]: "Select the inbox, which you want to open.",
  [keys.BOX_buttonLabelConnectNew]: "Connect new number",
  [keys.BOX_disclaimerOneNumber]:
    "Right now you can only add one number at a time. Please select a number to connect and continue by saving your changes. To add additional numbers repeat the process.",
};

export default translate;
