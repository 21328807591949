// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  NUM_manageAccount: "NUM_manageAccount",
  NUM_details: "NUM_details",
  NUM_accountName: "NUM_accountName",
  NUM_dataStorageRegions: "NUM_dataStorageRegions",
  NUM_cloudApiNumbers: "NUM_cloudApiNumbers",
  NUM_qualityRating: "NUM_qualityRating",
  NUM_low: "NUM_low",
  NUM_medium: "NUM_medium",
  NUM_high: "NUM_high",
  NUM_messageLimit: "NUM_messageLimit",
  NUM_newConversationsPerDay: "NUM_newConversationsPerDay",
  NUM_unlimited: "NUM_unlimited",
};

export default keys;
