// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.MIG_progressTitle]: "Fortschritt der Nummerportierung",
  [keys.MIG_progressStep1]: "Kontodetails einreichen",
  [keys.MIG_progressStep2]: "Nachrichtenanfrage annehmen",
  [keys.MIG_progressStep3]: "Telefonnnummer verifizieren",
  [keys.MIG_numberHintTitle]:
    "Bitte gib die zu portierende Nummer im Feld unten ein.",
  [keys.MIG_numberhintText1]:
    "Es ist wichtig, dass du sicherstellst, dass die Zwei-Faktor Authentifizierung für diese Nummer deaktiviert ist.",
  [keys.MIG_numberhintText2]:
    "Hinweis: Zahlen in runden Klammern sind nicht Teil der eingereichten Nummer.",
  [keys.MIG_mobTitle]: "Nachrichtenanfrage",
  [keys.MIG_mobText]:
    "Großartig! Der nächste Schritt um dein WhatsApp Business API Konto einzurichten besteht darin in deinem Meta Business Manager die Anfrage zum Senden von Nachrichten in deinem Namen anzunehmen, welche 360dialog dir gesendet hat.",
  [keys.MIG_openRequestsButtonLabel]: "Meta Business Manager öffnen",
  [keys.MIG_alreadyAcceptedMob]:
    "Nachdem du die Anfrage akzeptiert hast, klicke den Button unten um den Status zu aktualisieren.",
  [keys.MIG_pleaseReturnAfterMob]:
    "Bitte kehre zu dieser Seite zurück und folge den nächsten Schritten nachdem du die Nachrichtenanfrage akzeptiert hast.",
  [keys.MIG_mobCheckboxLabel]:
    "Ich habe die Nachrichtenanfrage im Meta Business Manager akzeptiert.",
  [keys.MIG_checkStatusButtonLabel]: "Anfragestatus aktualisieren",
  [keys.MIG_checkAgainIn]:
    "Status kann in <COUNTDOWN> s erneut abgefragt werden",
  [keys.MIG_mobSuccessText]:
    "Du hast unsere Nachrichtenanfrage erfolgreich angenommen.",
  [keys.MIG_continueMobButtonLabel]: "Weiter zur Telefonnummernverifizierung",
  [keys.MIG_verifyTitle]: "Verifiziere deine Telefonnummer",
  [keys.MIG_verifyText1]:
    "Der letzte Schritt zur Aktivierung deines WhatsApp Business API Kontos ist die Verifizierung deiner Telefonnummer.",
  [keys.MIG_verifyText2]:
    "Um deine Nummer zu verifizieren musst du nächst den Registrierungscode entweder per SMS Textnachricht oder Telefonanruf anfragen. Du erhältst dann einen sechsstelligen PIN Code an die angegebene Telefonnummer",
  [keys.MIG_verifyRadioLabel]: "Wähle eine Verifizierungsmethode",
  [keys.MIG_verifyRadioOptionText]: "Textnachricht (SMS)",
  [keys.MIG_verifyRadioOptionVoice]: "Telefonanruf",
  [keys.MIG_verifySelectInfo]:
    "Wähle die Sprache, in der du den Code erhalten möchtest",
  [keys.MIG_triggerRegistrationButtonLabel]: "Registrierungscode anfragen",
  [keys.MIG_codeWillBeSent]: "Code wird an <NUMBER> gesendet.",
  [keys.MIG_alreadyHaveCode]:
    "Du hast bereits einen Code? <BUTTON>Gehe zum nächsten Schritt<BUTTON>.",
  [keys.MIG_codeWasSent]: "Code wurde an <NUMBER> gesendet",
  [keys.MIG_codeInputLabel]: "Registrierungscode",
  [keys.MIG_submitCodeButtonLabel]: "Code bestätigen",
  [keys.MIG_requestNewCodeIn]: "Neuer Code kann in {{time}}s angefragt werden",
  [keys.MIG_requestNewCodeButtonLabel]: "Neuen Code anfragen",
  [keys.MIG_errorHint]:
    "Der Registrierungscode konnte nicht verifiziert werden. Versuche es erneut oder beantrage einen neuen Code.",
  [keys.MIG_setupTitle]: "Nummer wird eingerichtet",
  [keys.MIG_setupText1]:
    "Wir richten aktuell deine Nummer ein und starten den Service. Das kann ein paar Minuten dauern. Wir bitten dich um einen Moment Geduld.",
  [keys.MIG_setupText2]:
    "Bitte schließe dieses Fenster NICHT. Nachdem die Einrichtung abgeschlossen ist, kannst du direkt im Anschluss deinen API Schlüssel erstellen.",
  [keys.MIG_registrationFailed]: "Registrierung fehlgeschlagen",
  [keys.MIG_errorMobTitle]:
    "Anfrage zum Senden von Nachrichten nicht akzeptiert",
  [keys.MIG_errorMobText]:
    "Bevor du deine Nummer registrieren kannst, musst du zunächst unsere Anfrage zum Senden von Nachrichten in deinem Namen annehmen. Diese findest du im Meta Business Manager. Ohne diese Anfrage zu akzeptieren kannst du deine Nummer nicht portieren.",
  [keys.MIG_errorMobInfo]:
    "Weitere Informationen zur Nachrichtenanfrage findest du in unserer <LINK>https://docs.360dialog.com/submission-process#4-approve-the-messaging-on-behalf-request | Dokumentation<LINK>.",
  [keys.MIG_error2faTitle]:
    "Zwei-Faktor Authentifizierung noch nicht deaktiviert",
  [keys.MIG_error2faText]:
    "Bevor du deine Nummer registrieren kannst, musst du zunächst die Zwei-Faktor Authentifizierung für diese Nummer deaktivieren. Das kannst du entweder selber über den Meta Business Manager oder über den Business Solution Provider, der aktuell deine Nummer verwaltet.",
  [keys.MIG_error2faInfo]:
    "Besuche unseren <LINK>https://docs.360dialog.com | Guide zum Deaktivieren von 2FA für unterschiedliche BSPs<LINK>.",
  [keys.MIG_bspDialogTitle]: "Bevor du loslegst",
  [keys.MIG_bspDialogText]:
    "Bitte stelle sicher, dass die Zwei-Faktor Authentifizierung (2FA) für die zu portierende Nummer deaktiviert ist. Ist deine Nummer bei einer der folgenden Firmen registriert? Bitte frage dort an 2FA zu deaktivieren.",
  [keys.MIG_buttonLabelRetrySetup]: "Setup erneut versuchen",
  [keys.MIG_fbErrorNewNumber]:
    "Diese Telefonnummer kann ohne Portierung zu deinen Nummern hinzugefügt werden. Bitte besuche den 360dialog Client Hub und füge sie dort als neue Nummer hinzu.",
  [keys.MIG_fbErrorAlreadyPorted]:
    "Die Telefonnumer wurde bereits zum Ziel-WhatsApp Business Konto hinzugefügt. Bitte besuche den 360dialog Client Hub um fortzufahren.",
  [keys.MIG_fbErrorDifferentBusiness]:
    "Das ursprüngliche und das neue WhatsApp Business Konto müssen das gleiche Unternehmen repräsentieren. Bitte nutze die gleiche Meta Business ID wie zuvor, wenn du die Nummer einreichst.",
  [keys.MIG_fbErrorUnknown]:
    "Fehler bei der Nummerportierung. Bitte warte einige Minuten und versuche er erneut. Wenn das nicht hilft, kontaktiere unseren Support über den 360dialog Client Hub.",
  [keys.MIG_fbErrorInvalidNumber]:
    "Deine Telefonnummer scheint nicht korrekt zu sein. Bitte kontrolliere diese nochmals und warte 5 Minuten bevor du es erneut versuchst.",
  [keys.MIG_fbErrorNotVerified]:
    "Dein WhatsApp Business-Konto muss genehmigt sein",
  [keys.MIG_fbErrorWabaNotCorrectlySetup]:
    "Das WhatsApp Konto, mit dem diese Nummer verknüpft ist, ist nicht korrekt aufgesetzt",
  [keys.MIG_fbErrorWaitNewCode]:
    "Du musst warten, bis du deinen Code erneut anfordern kannst. Bitte warte <HOUR> und <MINUTES>, bevor du es erneut versuchst.",
  [keys.MIG_importNumber]:
    "Mit diesen Schritten kannst du eine deiner Telefonnummern aus einem WhatsApp Business Account, der von einem anderen Business Solution Provider verwaltet wird, in einen von 360dialog verwalteten Account importieren.",
  [keys.MIG_enterNumber]: "Gib die Rufnummer ein",
  [keys.MIG_enterNumberDescription]:
    "Diese Telefonnummer sollte mit der übereinstimmen, die der andere Business Solution Provider derzeit verwendet.",
  [keys.MIG_selectWaba]: "Wähle einen WhatsApp Business Account",
  [keys.MIG_selectWabaDescription]:
    "Wähle den WhatsApp Business Account aus, zu dem du die oben eingegebene Nummer migrieren möchtest. Du kannst einen Account auswählen, der bereits im 360dialog Hub existiert, oder du kannst einen direkt von Meta importieren.",
  [keys.MIG_createWabaTitle]: "Einen neuen Whatsapp Business Account erstellen",
  [keys.MIG_createWabaDescription]:
    "Mit den folgenden Schritten kannst du einen WhatsApp Business Account (WABA) ohne Nummer erstellen. Dieser kann dann als Zielkonto verwendet werden, auf das deine bestehende Nummer übertragen wird. Bitte lies dir die folgenden Anweisungen sorgfältig durch und befolge die Schritte.",
  [keys.MIG_createWabaWarning]:
    "Es ist sehr wichtig, dass du die Schritte mit den unten angegebenen Details befolgst. Andernfalls kannst du keinen WhatsApp Business Account ohne Nummer erstellen und die Übertragung einer Nummer kann zu Problemen beim Messaging führen.",
  [keys.MIG_createWabaSteps]:
    "Um einen leeren Whatsapp Business Account zu erstellen, musst du den eingebetteten Anmeldeprozess durchlaufen. Dieser Prozess besteht aus mehreren Schritten, aber du musst nur einige davon durchlaufen und an der angegebenen Stelle aufhören.",
  [keys.MIG_createWabaFollowSteps]:
    "Die unten aufgeführten einfachen Schritte werden dir helfen, den Prozess erfolgreich abzuschließen. Bitte gehe sie sorgfältig durch.",
  [keys.MIG_createWabaStep1]: "Schritt 1",
  [keys.MIG_createWabaStep1Description]: "Fülle deine Unternehmensdaten aus",
  [keys.MIG_createWabaStep2]: "Schritt 2",
  [keys.MIG_createWabaStep2Description]:
    "Erstelle deinen WhatsApp Business Account",
  [keys.MIG_createWabaStep3]: "Schritt 3",
  [keys.MIG_createWabaStep3Description]:
    "Beende den eingebetteten Anmeldeprozess",
  [keys.MIG_createWabaError]:
    "Wir konnten keinen neuen WhatsApp Business Account finden. Um fortzufahren, musst du den Prozess noch einmal wiederholen. Bitte lies dir die Anweisungen sorgfältig durch, bevor du die eingebettete Anmeldung auslöst.",
  [keys.MIG_proceedInClientHub]:
    "Fortfahren mit der Migration im 360dialog Client Hub",
  [keys.MIG_numberMigrationTitle]: "Fortschritt der Nummernmigration",
  //  [keys.MIG_proceedMigrationText1]: "translation_missing",
  //  [keys.MIG_proceedMigrationText2]: "translation_missing",
  //  [keys.MIG_continueWithMigration]: "translation_missing",
  //  [keys.MIG_migrateToDifferentWaba]: "translation_missing",
  //  [keys.MIG_migrateSuccess]: "translation_missing",
  //  [keys.MIG_validCode]: "translation_missing",
  //  [keys.MIG_invalidCode]: "translation_missing",
  //  [keys.MIG_verifyOwnership]: "translation_missing",
  //  [keys.MIG_resendCode]: "translation_missing",
  //  [keys.MIG_requestNewCode]: "translation_missing",
  //  [keys.MIG_migrateNumber]: "translation_missing",
  //  [keys.MIG_importOtherBSP]: "translation_missing",
  //  [keys.MIG_importTo360dialog]: "translation_missing",
  //  [keys.MIG_importFromOtherBSP]: "translation_missing",
  //  [keys.MIG_phoneNumberDescription]: "translation_missing",
  //  [keys.MIG_accessMetaBusinessManager]: "translation_missing",
  //  [keys.MIG_howCanIFindIt]: "translation_missing",
  //  [keys.MIG_howCanIFindItText]: "translation_missing",
  //  [keys.MIG_provideWabaId]: "translation_missing",
  //  [keys.MIG_migrateExisting]: "translation_missing",
  //  [keys.MIG_repeatProcess]: "translation_missing",
  //  [keys.MIG_settingUpNumber]: "translation_missing",
  //  [keys.MIG_channelNotReady]: "translation_missing",
  //  [keys.MIG_disabled2faCheckbox]: "translation_missing",
  //  [keys.MIG_disable2faStepOne]: "translation_missing",
  //  [keys.MIG_disable2faStepTwo]: "translation_missing",
  //  [keys.MIG_disable2fa]: "translation_missing",
  //  [keys.MIG_disable2faDescription]: "translation_missing",
  //  [keys.MIG_disable2faFollowSteps]: "translation_missing",
  //  [keys.MIG_businessVerifiedCheckbox]: "translation_missing",
  //  [keys.MIG_howDoIDisable2fa]: "translation_missing",
  //  [keys.MIG_importFromOtherProvider]: "translation_missing",
  //  [keys.MIG_ifYouCannotFindWabaId]: "translation_missing",
  //  [keys.MIG_clickToCreateWabaAccount]: "translation_missing",
  //  [keys.MIG_createWabaAccount]: "translation_missing",
  //  [keys.MIG_iHaveCreatedTheWabaACcount]: "translation_missing",
  //  [keys.MIG_youNeedToVerify]: "translation_missing",
  //  [keys.MIG_verifyOwnershipTitle]: "translation_missing",
  //  [keys.MIG_verifyNumber]: "translation_missing",
  //  [keys.MIG_continueWithEs]: "translation_missing",
  //  [keys.MIG_shareWabaWithUs1]: "translation_missing",
  //  [keys.MIG_shareWabaWithUs2]: "translation_missing",
  //  [keys.MIG_shareWabaWithUs3]: "translation_missing",
  //  [keys.MIG_shareWabaWithUs4]: "translation_missing",
  //  [keys.MIG_somethingWentWrongEs]: "translation_missing",
  //  [keys.MIG_disabledPlanInfo]: "translation_missing",
  //  [keys.MIG_missingPlans]: "translation_missing",
};

export default translate;
