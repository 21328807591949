// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.INT_integrations]: "Integrations",
  [keys.INT_syncEvents]: "Sync events with your favourite apps",
  [keys.INT_shopifyApp]: "Shopify App",
  [keys.INT_connectCTADescription]:
    "Connect Insights with Shopify and automatically sync your sales with WhatsApp conversations!",
  [keys.INT_connect]: "Connect your conversions",
  [keys.INT_supperChargingInsightDescription]:
    "We're supercharging Insights with integrations that sync any app you use to attribute conversion events originating from conversations.",
  [keys.INT_suggestIntegarationsDescription]:
    "Are there any other integrations you'd love to see? Let us know by filling out the form below!",
  [keys.INT_suggestIntegarations]: "Suggest new Integration",
  [keys.INT_connectedApps]: "Connected Apps",
  [keys.INT_upgrade]: "Upgrade",
  [keys.INT_shopify]: "Shopify",
  [keys.INT_shopifyDescription]:
    "Track Shopify Store purchase event, and attribute this event to the proper WhatsApp conversation!",
  [keys.INT_appNotAvailable]: "App not available yet",
  [keys.INT_comingSoon]: "Coming soon",
  [keys.INT_install]: "Install our app",
  [keys.INT_connected]: "Connected",
};

export default translate;
