import { IServerResponseApiKeys, IServerResponseApiKey } from "./apiKeys.types";
import { DefaultServerResponseStatus } from "../settings/settings.types";
import { MPInstancesHub } from "@/api/RequestAxios/RequestAxiosHub";

const getApiKeyUrl = (appId: string, partnerId: string) =>
  `partners/${partnerId}/apps/${appId}/api_keys`;

export const getApiKeys = async ({
  appId,
  partnerId,
}: {
  appId: string;
  partnerId: string;
}): Promise<IServerResponseApiKeys> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<IServerResponseApiKeys>(
      getApiKeyUrl(appId, partnerId)
    );
  return data;
};

export const createApiKey = async ({
  appId,
  partnerId,
}: {
  appId: string;
  partnerId: string;
}): Promise<IServerResponseApiKey> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<IServerResponseApiKey>(
      getApiKeyUrl(appId, partnerId),
      {}
    );
  return data;
};

export const deleteApiKeys = async ({
  appId,
  partnerId,
}: {
  appId: string;
  partnerId: string;
}): Promise<DefaultServerResponseStatus> => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.delete(
    getApiKeyUrl(appId, partnerId)
  );
  return data as DefaultServerResponseStatus;
};
