// File generated automatically. Do not edit manually.
import app from "./app/de";
import archive from "./archive/de";
import auth from "./auth/de";
import billing from "./billing/de";
import channels from "./channels/de";
import dashboard from "./dashboard/de";
import errors from "./errors/de";
import facebook from "./facebook/de";
import faq from "./faq/de";
import general from "./general/de";
import inbox from "./inbox/de";
import key from "./key/de";
import languages from "./languages/de";
import migrate from "./migrate/de";
import navigation from "./navigation/de";
import onboarding from "./onboarding/de";
import preferences from "./preferences/de";
import settings from "./settings/de";
import support from "./support/de";
import templates from "./templates/de";
import wizard from "./wizard/de";
import notifications from "./notifications/de";
import appconnection from "./appconnection/de";
import signals from "./signals/de";
import insights from "./insights/de";
import funnels from "./funnels/de";
import integrations from "./integrations/de";
import numbers from "./numbers/de";
import audiences from "./audiences/de";
import tour from "./tour/de";
import tos from "./tos/de";

const allTranslations: { [k: string]: string } = {
  ...app,
  ...archive,
  ...auth,
  ...billing,
  ...channels,
  ...dashboard,
  ...errors,
  ...facebook,
  ...faq,
  ...general,
  ...inbox,
  ...key,
  ...languages,
  ...migrate,
  ...navigation,
  ...onboarding,
  ...preferences,
  ...settings,
  ...support,
  ...templates,
  ...wizard,
  ...notifications,
  ...appconnection,
  ...signals,
  ...insights,
  ...funnels,
  ...integrations,
  ...numbers,
  ...audiences,
  ...tour,
  ...tos,
};

export default allTranslations;
