// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.NOT_allNotifications]: "All",
  [keys.NOT_archivedNotifications]: "Archived",
  [keys.NOT_closeButtonTooltip]: "Archive this notification",
  [keys.NOT_archiveAllBtn]: "Archive all",
  [keys.NOT_noNewNotifications]: "That's all your notifications.",
  [keys.NOT_clientAdded]:
    "You successfully accepted the client. The client and all corresponding phone numbers will be moved to your partner hub on by the end of the current month.",
  [keys.NOT_clientAddedWithMigrationDate]:
    "You successfully accepted the client. The client and all corresponding phone numbers will be moved to your partner hub on {{migrationDate}}.",
  [keys.NOT_rejectionReason]: "Rejection Reason: ",
  [keys.NOT_alreadyApproved]: "You already approved this change request",
  [keys.NOT_apiKeyRecommendation]: "We recommend retrieving a new API KEY",
  [keys.NOT_generateNewAPIKey]: "Generate New API Key",
  [keys.NOT_partnerRequestFailed]: "Failed: Process could not be completed",
  [keys.NOT_partnerRequestCancelled]: "Client has cancelled the request",
  [keys.NOT_needCaptcha]: "Please verify that you are not a robot",
  [keys.NOT_errorUpdatingChatSupport]:
    "There is a problem with updating chat support status. Please try later.",
  [keys.NOT_approved]: "Approved",
  [keys.NOT_pending]: "Pending",
  [keys.NOT_affectedNumbers]: "affected numbers",
  [keys.NOT_minutesAgo]: "{{minutes}} minutes ago",
  [keys.NOT_hoursAgo]: "{{hours}} hours ago",
  [keys.NOT_emptyStateMessage]:
    "Seems like you haven't received any notification yet",
  [keys.NOT_allNumbersAffected]: "All numbers affected",
};

export default translate;
