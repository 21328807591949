// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.CH_showDetailed]: "Show detailed status",
  [keys.CH_hideDetailed]: "Hide detailed status",
  [keys.CH_templatesButton]: "Manage templates",
  [keys.CH_showDetailsButton]: "Show details",
  [keys.CH_mobButton]: "Message on behalf request",
  [keys.CH_triggerButton]: "Trigger registration",
  [keys.CH_finishButton]: "Finish registration",
  [keys.CH_keyButton]: "Generate API key",
  [keys.CH_addNumber]: "Add a new phone number",
  [keys.CH_exportClientData]: "Export client data",
  [keys.CH_exporting]: "Exporting ...",
  [keys.CH_filterPlaceholder]: "all statuses",
  [keys.CH_details]: "Details",
  [keys.CH_templates]: "Templates",
  [keys.CH_billingInfo]: "Billing information",
  [keys.CH_billingStartDate]: "Billing start at (local time)",
  [keys.CH_billingStartButtonLabel]: "Start billing",
  [keys.CH_numberReg]: "Number Registration",
  [keys.CH_pin]: "Pin Verification",
  [keys.CH_defaultLang]: "Default language for templates",
  [keys.CH_ivrEx]: "IVR existing",
  [keys.CH_ivrAct]: "IVR active",
  [keys.CH_verificationMethod]: "Verification method",
  [keys.CH_voice]: "Voice",
  [keys.CH_sms]: "SMS",
  [keys.CH_cert]: "Certificate",
  [keys.CH_consent]: "Consent status",
  [keys.CH_consentPage]: "Consents signing page",
  [keys.CH_displayNameErrorEmoji]: "emoji",
  [keys.CH_displayNameErrorIntro]: "Display name cannot contain:",
  [keys.CH_displayNameErrorWhatsApp]: "'WhatsApp'",
  [keys.CH_displayNameErrorUrl]: "an URL address",
  [keys.CH_displayNameErrorPunct]: "punctuation",
  [keys.CH_displayNameErrorCap]:
    "Display name only can only be capitalized when the company already uses branding entirely in capital letters",
  [keys.CH_displayNameErrorMin]:
    "Display name must contain a minimum of 3 characters",
  [keys.CH_whatsAppDisplayName]: "WhatsApp Display Name",
  [keys.CH_confirmDisplayNameChange1]:
    "Your new display name will first be reviewed by Meta before it is finally changed, and may be rejected if it doesn't follow Meta's display name guidelines. Are you sure you want to submit this display name?",
  [keys.CH_confirmDisplayNameChange2]:
    "Please allow up to 48 hours for your display name to be reviewed by Meta's Trust & Safety team.",
  [keys.CH_confirmDisplayNameChangeTooltip]:
    "You can change the display name up to three times in the first 30 days after the profile has been created and the number activated. Once you have reached that limit, you must wait 30 days before submitting a name change again.",
  [keys.CH_confirmDisplayNameChange3]:
    "FAQ: How often can I change my display name?",
  [keys.CH_confirmDisplayNameChange4]: "Please confirm this action",
  [keys.CH_numberReadyToRegister]:
    "Your number has not yet been registered to the WhatsApp API, kindly proceed with the registration to use our services.",
  [keys.CH_pending]: "Pending",
  [keys.CH_wabaChannelExtId]: "WABA Channel External ID",
  [keys.CH_wabaAccountName]: "WhatsApp Business Account Name",
  [keys.CH_mobName]: "Message on Behalf Name",
  [keys.CH_timezone]: "Timezone ID",
  [keys.CH_wabaAccountId]: "WhatsApp Business Account ID",
  [keys.CH_namespace]: "Namespace",
  [keys.CH_dangerZone]: "Danger Zone",
  [keys.CH_deleteNumber]: "Delete number",
  [keys.CH_revoke]: "Revoke payment on behalf",
  [keys.CH_revokeText]:
    "This action will revoke your payments and will prompt the client to add his own payment information in order to continue using our product.",
  [keys.CH_requestRevocationButton]: "Request revocation",
  [keys.CH_deleteSuccess]:
    "We have received your request. Your number will be terminated shortly.",
  [keys.CH_detailsTab]: "Details",
  [keys.CH_templatesTab]: "Templates",
  [keys.CH_wabaProfileTab]: "Waba profile",
  [keys.CH_profileTab]: "Profile",
  [keys.CH_integrationTab]: "Integration",
  [keys.CH_commentsTab]: "Comments",
  [keys.CH_listHeaderTitle]: "WhatsApp Account",
  [keys.CH_manageKeys]: "Manage API keys",
  [keys.CH_manageKeysHelptext]:
    "Your integration is running and can send and receive messages. If you haven't created an API key already, you can create it now via the button below.",
  [keys.CH_addNumberTitle]: "Connect a new phone number to WhatsApp Business",
  [keys.CH_addNumberHelptext]:
    "This form can be used to connect a new phone number to the WhatsApp Business API. By clicking 'Save' you will be officially submitting a new Phone Number to 360dialog, and may be charged accordingly.",
  [keys.CH_addNumberSelectHelptext]:
    "Please select the WhatsApp Business account you want to use:",
  [keys.CH_addNumberSuccess]:
    "Success! Your number was just added and will be available in a few moments.",
  [keys.CH_addNumberEmbeddedTitle]: "Connect a new phone number",
  [keys.CH_addNumberEmbeddedHelptext]:
    "This form can be used to connect a new phone number to the WhatsApp Business API. By continuing with the Meta Embedded Form you will be officially submitting a new Phone Number to 360dialog, and may be charged accordingly.",
  [keys.CH_addNumberSelectError]: "Please select the relevant account",
  [keys.CH_pendingDeletion]: "Pending Deletion",
  [keys.CH_pendingDeletionHelptext]:
    "Your integration has been submitted for deletion and is currently pending. This number can still receive or send messages, until it will be finally deleted on <date>. Until then you have the possibility to re-enable your number below.",
  [keys.CH_reEnableButton]: "Re-enable number",
  [keys.CH_revokeNumberDeletionSuccess]:
    "Your number was successfully re-enabled!",
  [keys.CH_emptyStateTitle]:
    "You haven’t registered any WhatsApp Accounts yet.",
  [keys.CH_emptyStateHelptext]:
    "To start submitting accounts ask your clients to fill out your individual submission form. All account submissions through that form will show up here.",
  [keys.CH_emptyStateHelptextIO]:
    "To start submitting accounts make sure to implement the Integrated Onboarding experience and ask your clients to trigger the setup process from within your application.",
  [keys.CH_openSubmissionForm]: "Open submission form",
  [keys.CH_openIODoc]: "Read the Integrated Onboarding documentation",
  [keys.CH_copyLink]: "Copy link",
  [keys.CH_copiedLink]: "Link copied",
  [keys.CH_emptyStateTitleClient]:
    "It seems like you haven't finished your initial onboarding.",
  [keys.CH_emptyStateHelptextClient]:
    "To access the WhatsApp Business API you need to create a WhatsApp Business account first, which is done through our onboarding process. Please make sure to follow the provided steps.",
  [keys.CH_emptyStateButtonLabelClient]: "Finish onboarding",
  [keys.CH_noChannelsFound]: "There are no results for this search query.",
  [keys.CH_setupProgress]: "Setup progress",
  [keys.CH_showMore]: "Show more",
  [keys.CH_showLess]: "Show less",
  [keys.CH_percentageDone]: "done",
  [keys.CH_actionRequired]: "Action required",
  [keys.CH_noActionRequired]: "No action required",
  [keys.CH_setupNumberRunning]: "Number running",
  [keys.CH_setupVerificationStarted]: "Business verification started",
  [keys.CH_setupVerificationCompleted]: "Business verification completed",
  [keys.CH_setupDisplayNameApproved]: "Display name approved",
  [keys.CH_setupCommercePolicyChecked]: "Commerce policy check done",
  [keys.CH_ctaBusinessRejected]: "Your business verification was rejected.",
  [keys.CH_ctaNumberSetup]:
    "Your number is currently being set up. This will take a few moments. Please reload this page in ca. 5 minutes.",
  [keys.CH_ctaStartVerification]:
    "Head to the Meta Business manager and start your business verification.",
  [keys.CH_ctaVerificationPending]:
    "Your business verification is currently pending.",
  [keys.CH_ctaDisplayNameRejected]:
    "Your <LINK>https://developers.facebook.com/docs/whatsapp/guides/display-name/ | display name<LINK> was rejected. Please use the Meta WhatsApp Manager or contact our support to change it and re-submit.",
  [keys.CH_ctaButtonLabelOpenWaManager]: "Open WhatsApp Manager",
  [keys.CH_ctaLiveMode]:
    "Congratulations! Your account is live and you have full access to the WhatsApp Business API.",
  [keys.CH_ctaButtonLabelFbm]: "Open business manager",
  [keys.CH_ctaButtonLabelStartVerification]: "Start business verification",
  [keys.CH_ctaButtonLabelOpenSettings]: "Open settings",
  [keys.CH_ctaButtonLabelAppeal]: "Open appeal form",
  [keys.CH_ctaCommercePolicyFailed]:
    "Your company has not passed the <LINK>https://www.whatsapp.com/legal/commerce-policy/?lang=en | WhatsApp Commerce Policy Checks<LINK>. If you feel that your company does not violate these policies, you may appeal.",
  [keys.CH_ctaPendingDeletion]:
    "Your number is pending for deletion. There is no further action required.",
  [keys.CH_tooltipSandbox]: "Learn more about the unverified trial experience.",
  [keys.CH_wabaPhotoChanged]:
    "Whatsapp business account photo changed successfully.",
  [keys.CH_wabaProfile]: "WhatsApp Business Profile",
  [keys.CH_businessProfile]: "Business Profile",
  [keys.CH_changeProfilePic]: "Change Profile Picture",
  [keys.CH_profilePic]: "Profile Picture",
  [keys.CH_urlErrorMessage]: "Please enter a valid URL with https://",
  [keys.CH_portNumberButtonLabel]: "Port existing number to 360dialog",
  [keys.CH_portNumberText]:
    "This form can be used to initiate the migration of an existing phone number to your 360dialog account. After providing the number and the destination WhatsApp Business Account you will be guided through the migration process. By continuing you also acknowledge that you will be officially submitting a new Phone Number to 360dialog, and may be charged accordingly.",
  [keys.CH_createNewWaba]: "Create new WhatsApp Business Account",
  [keys.CH_phoneNumberAlreadyExists]:
    "This phone number has already been added.",
  [keys.CH_migrateNumberNextStepText]:
    "Please continue the number porting process in the new tab, which should've opened. Otherwise please check your browser for a blocked pop-up and <LINK>try again<LINK>.",
  [keys.CH_readyForMigrationCtaText]:
    "Please open the verification page and follow the steps to finalize the porting of your number.",
  [keys.CH_waitingForMigrationCtaText]:
    "Number verification was not completed yet. Please follow the steps on the verification page to re-trigger and submit a verification code. This step is needed to finalize the porting of your number.",
  [keys.CH_invalidBusinessId]: "This Meta Business Manager ID is not valid",
  [keys.CH_migratedNumberLabel]: "Migrated Number",
  [keys.CH_type]: "Type",
  [keys.CH_retrySetupText]:
    "Your integration was not yet started. Most likely due to 2FA still being enabled. Please re-try the setup.",
  [keys.CH_setTerminationDate]: "Set termination date",
  [keys.CH_successChannelAdded]: "Channel successfully added",
  [keys.CH_addBusinessInfo]: "Add Business Info",
  [keys.CH_businessInfoPrompt]:
    "Please make sure to fill out your Business Info in the Meta Business Manager for full access to the API. You already passed all checks, but are looking to increase your messaging limits?",
  [keys.CH_startBusinessVerification]: "Start Business Verification",
  [keys.CH_deletedChannel]: "Channel inactive",
  [keys.CH_deletedChannelHelptextShort]:
    "The integration was deleted and this number is not active anymore. It will be deleted irrevocably by <date>.",
  [keys.CH_deletedChannelHelptext]:
    "The integration was deleted and this number is not active anymore. It will be deleted irrevocably by <date>. After this date it will also no longer be visible in the 360dialog Client Hub.",
  [keys.CH_reEnableHelptext]:
    "If you want to restore your number, please contact <PARTNER>.",
  [keys.CH_partnerFallback]: "your 360dialog partner",
  [keys.CH_recentSearches]: "Recent searches",
  [keys.CH_applyFilter]: "Apply filter",
  [keys.CH_sortByDate]: "Sorted by date modified",
  [keys.CH_searchBy]: "Search by phone number, display name or company name",
  [keys.CH_filters]: "Filters",
  [keys.CH_filterSingular]: "Filter",
  [keys.CH_variableCountError]: "Number of permitted variables exceeded.",
  [keys.CH_phoneNumber]: "Phone number",
  [keys.CH_clientEmail]: "Client email",
  [keys.CH_submissionType]: "Submission type",
  [keys.CH_portedNumber]: "Ported number",
  [keys.CH_channelStatus]: "Channel Status",
  [keys.CH_pendingCancellation]: "Pending Cancellation",
  [keys.CH_accountMode]: "Account Mode",
  [keys.CH_errorNotEverythingLoaded]:
    "Not all components of the page could be loaded. Please try again. If this issue persists, please contact support.",
  [keys.CH_buttonLabelGotToSupport]: "Go to support hub",
  [keys.CH_emptyStateOption]: "No result for this search",
  [keys.CH_billingStatus]: "Billing status",
  [keys.CH_cancelledOn]: "Cancelled on",
  [keys.CH_terminatedOn]: "Terminated on",
  [keys.CH_startedOn]: "Started on",
  [keys.CH_notStarted]: "Not started yet",
  [keys.CH_compressedListView]: "Compressed list view",
  [keys.CH_externalId]: "External ID",
  [keys.CH_status]: "Status",
  [keys.CH_deleteNumberText]:
    "This will queue the hosted WhatsApp Business API client for deletion. After the termination date which is set according to the cancellation terms, the number will no longer be able to send or receive messages using the WhatsApp Business API. Note: The number will remain visible in the numbers list but you will not be billed after the termination date.",
  [keys.CH_deleteNumberTextTerms]:
    "Terms: The subscription can be terminated with thirty (30) days' notice to the end of a month.",
  [keys.CH_deletePopupText]:
    "If you wish to proceed, please type CANCEL into the field below.",
  [keys.CH_confirmationFieldInputValue]: "CANCEL",
  [keys.CH_deletePopup1]:
    "Are you sure you want to cancel your subscription to the WhatsApp Business API client for the phone number <NUMBER>?",
  [keys.CH_confirmDeleteNumberFBManagerText1]:
    "Cancelling your subscription will queue the number for deletion. Please note, it is not an instant deletion. The status of the number will be changed to “Pending deletion”, while the number is in the stage, it can still send and receive messages as normal.",
  [keys.CH_confirmDeleteNumberFBManagerText2]:
    "The number will be permanently deleted at the end of the current month. At this point billing will stop.",
  [keys.CH_confirmDeleteNumberFBManagerText3]:
    "If you wish this number to be deleted before this period, please check the “Immediately delete my number on the Meta Business Manager” box before confirming.",
  [keys.CH_confirmDeleteNumberFBManager]:
    "Immediately delete my number on the Meta Business Manager. Please be aware that this action will automatically create a Support ticket and leads to deletion of the number within approximately 48 hours. It will no longer be available for use with the WhatsApp Business API. This will not cancel the obligation to pay license fees before the date of its complete deletion according to 360dialog Terms.",
  [keys.CH_deleteNumberSuccess]:
    "Your number will be removed from Meta Business Manager.",
  [keys.CH_messagingLimit]: "Messaging Limit",
  [keys.CH_learnHowDelete]: "Learn more on how to delete your number",
  [keys.CH_emailErrorMessage]: "Please enter a valid email address",
  [keys.CH_partnerButtonDisabled]:
    "This action is currently not available to users with partner permissions. Please ask your client to log in their 360dialog Client Hub to generate their API key.",
  [keys.CH_usageAndBalance]: "Usage & Balance",
  [keys.CH_prepaidSettings]: "Prepaid settings",
  [keys.CH_lastRenewal]: "Last renewal",
  [keys.CH_usage]: "Usage",
  [keys.CH_paymentNotEnabled]: "Client is not enabled for prepayment",
  [keys.CH_hasInboxTooltip1]:
    "This number is currently being used in combination with the get.chat inbox. You can therefore not generate an API key to use with the 360dialog WhatsApp API. You can however use API functionalities through get.chat's WhatsApp API.",
  [keys.CH_hasInboxTooltip2]:
    "To disconnect your inbox and create a 360dialog API key, visit the details page via the 'Show details' button.",
  [keys.CH_hasInboxActionBlock1]:
    "This number is currently being used in combination with the get.chat inbox. You can therefore not generate an API key to use with the 360dialog WhatsApp API. You can however use API functionalities through get.chat's WhatsApp API.",
  [keys.CH_hasInboxActionBlock2]:
    "You can disconnect your get.chat inbox below and create a 360dialog API key afterwards.",
  [keys.CH_disconnectInboxButtonLabel]: "Disconnect inbox now",
  [keys.CH_disconnectNotificationSuccess]:
    "Your get.chat inbox was successfully disconnected from this number",
  [keys.CH_disconnectNotificationError]:
    "We couldn't disconnect your inbox from get.chat. Please try again later. If this problem persists, please contact support.",
  [keys.CH_openConsentPage]: "Open consent signing page",
  [keys.CH_close]: "Close",
  [keys.CH_accountViolation]: "Account violation",
  [keys.CH_businessManagerDetails]: "View details in Meta Business Manager",
  [keys.CH_accountRestriction]: "Account restriction",
  [keys.CH_noInformationAvailable]: "No information available.",
  [keys.CH_restrictionText]: "Your account has been restricted by Meta.",
  [keys.CH_restrictionTextBusiness]:
    "You are no longer allowed to send business-initiated messages.",
  [keys.CH_restrictionTextUser]:
    "You are no longer allowed to respond to user-initiated messages.",
  [keys.CH_restrictionTextNumber]:
    "You are no longer allowed to add new phone numbers.",
  [keys.CH_clientName]: "Client name",
  [keys.CH_premiumPlanPricing]: "Premium plan pricing",
  [keys.CH_standardPlanPricing]: "Standard plan pricing",
  [keys.CH_upgrade]:
    "By continuing through the next steps, you will upgrade the plan for the number <NUMBER>.",
  [keys.CH_downgrade]:
    "By continuing through the next steps, you will downgrade the plan for the number <NUMBER>.",
  [keys.CH_stantard]: "Standard",
  [keys.CH_premium]: "Premium",
  [keys.CH_month]: "Month",
  [keys.CH_billedMonthly]: "Billed monthly",
  [keys.CH_unlimitedIncomingMsg]:
    "Unlimited incoming and outgoing WhatsApp session messages",
  [keys.CH_noMarkup]:
    "User-initiated & business-initiated conversations according to <LINK>https://developers.facebook.com/docs/whatsapp/pricing | WhatsApp pricing<LINK>. No markup.",
  [keys.CH_98serviceLevel]: "98% Hosting Service Level",
  [keys.CH_99serviceLevel]: "99% Hosting Service Level",
  [keys.CH_premiumSupport]:
    "All week support (24 hours Monday - Friday, 9am - 5pm CET Saturday & Sunday)",
  [keys.CH_standardSupport]: "Weekday support (2am – 10pm CET Monday - Friday)",
  [keys.CH_premiumSLA]:
    "Down to < 30min Support SLA (for eligible ticket types)",
  [keys.CH_standardSLA]: "Down to < 4h Support SLA (for eligible ticket types)",
  [keys.CH_costBreakdown]: "Continue to cost breakdown",
  [keys.CH_description]: "Description",
  [keys.CH_qty]: "Qty",
  [keys.CH_amount]: "Amount",
  [keys.CH_estimatedCost]:
    "Your estimated monthly costs for your subscription excluding any conversation costs and potential proration payments for the running month, will be as follows.",
  [keys.CH_unitPrice]: "Unit Price",
  [keys.CH_standardPricing]: "Standard pricing",
  [keys.CH_premiumPricing]: "Premium pricing",
  [keys.CH_premiumAccount]: "Premium account",
  [keys.CH_lastDowngradeTooltip1]:
    "Accounts can be upgraded 30 days after the last downgrade. Your last downgrade was on <DATE>.",
  [keys.CH_lastDowngradeTooltip2]:
    "The next possible upgrade can be triggered on <DATE>.",
  [keys.CH_lastUpgradeTooltip1]:
    "Accounts can be downgraded 30 days after the last upgrade. Your last upgrade was on <DATE>.",
  [keys.CH_lastUpgradeTooltip2]:
    "The next possible downgrade can be triggered on <DATE>.",
  [keys.CH_reRegisterNumber]: "Re-register number",
  [keys.CH_reRegisterNumberText]:
    "Your number has been unregistered by Meta. In order to continue with our messaging services, you have to re-register it. Click the “Re-register number” button and follow the steps.",
  [keys.CH_saveComment]: "Save comment",
  [keys.CH_activityLogs]: "Activity logs",
  [keys.CH_admin]: "Admin",
  [keys.CH_dateAndTime]: "Date and time",
  [keys.CH_actor]: "Actor",
  [keys.CH_action]: "Action",
  [keys.CH_businessAccount]: "Business account",
  [keys.CH_profilePreview]: "Profile Preview",
  [keys.CH_noLogsYet]: "No logs yet",
  [keys.CH_noLogsYetDescription]:
    "There are currently no activity logs to be displayed.",
  [keys.CH_useFilteredData]: "Use filtered data?",
  [keys.CH_selectDataset]:
    "Please select if you want to download the full dataset or if the selected filters should be applied to the exported dataset.",
  [keys.CH_filteredExportTitle]: "Filtered export",
  [keys.CH_filteredExport]: "Export filtered dataset",
  [keys.CH_fullExportTitle]: "Full export",
  [keys.CH_fullExport]: "Export full dataset",
  [keys.CH_contactSupport]: "Contact support",
  [keys.CH_contactSupportText]:
    "Your number setup is unexpectedly slow. Please contact our support team for assistance.",
  [keys.CH_proceedWithRegistration]:
    "Your number has not yet been registered to the WhatsApp API, kindly proceed with the registration to use our services.",
  [keys.CH_readyToRegister]: "Ready to register",
  [keys.CH_addNumberDisabledTwoNumbers]:
    "You can currently not add more numbers to your account. If you require more numbers, please contact our support.",
  [keys.CH_addNumberDisabledTwoNumbers2]:
    "You can currently not add more numbers to your account. If you require more numbers, please contact your integration partner or our support via hub.360dialog.com",
  [keys.CH_addNumberLimitExceeded]:
    "You currently can not add more numbers to your account because of the account limit. If you require more numbers, please contact your integration partner or our support.",
  [keys.CH_lockedTitle]: "Your account is temporarily locked",
  [keys.CH_lockedText]:
    "We detected suspicious activity on one of your WhatsApp phone numbers and therefore locked your account temporarily. Please make sure you've paid all your outstanding invoices for subscription fees as well as conversation costs in order to continue messaging. If you feel like this is wrong and want your account get activated again, please contact our support.",
  [keys.CH_ctaPendingReview]:
    "Your account is currently being reviewed by our team. This should not take more than 2 hours. Until the review process is completed, you are restricted from sending any template messages. You can still receive messages and respond to user-initiated conversations.",
  [keys.CH_apiKeyDisabledMessage]:
    "API keys are disabled for this number. The number was shared with your integration partner. With your permission the partner has access to the WhatsApp Business API on your behalf. To manage permissions go to “Organization Settings” or contact your partner.",
  [keys.CH_emptyStateHelptextClientIO]:
    "To access the WhatsApp Business API you need to create a WhatsApp Business account first. Please continue the setup process via your partner's application.",
  [keys.CH_successfullyAddedNumber]:
    "Your number was successfully added. We're setting it up and it will be visible on this page in a few minutes. You will get notified here and via email.",
  [keys.CH_numberNowLive]: "Your number is now live",
  [keys.CH_generateApiKeyNow]: "Generate API key now",
  [keys.CH_settingUpNumber]:
    "We're currently setting up your number and this might take a few minutes. You will be notified via email when your number is completely set up. After that, please continue the registration of the number.",
  [keys.CH_accountDeletionCheckbox]:
    "I understand that my number will be deactivated by the end of current subscription period.",
  [keys.CH_deleteAccount]: "Delete account",
  [keys.CH_dataNotAccessible]:
    "Data on this page are not accessible to your user",
  [keys.CH_addNumberDisabledSandbox]:
    "You have reached your maximum amount of 2 sandbox numbers. In order to add new numbers, make sure to proceed into live mode with your existing numbers.",
  [keys.CH_hostingPlatformType]: "Hosting Platform Type",
  [keys.CH_aboutTextRequired]: "About text is a required field",
  [keys.CH_synced]: "Synced",
  [keys.CH_wabaType]: "WhatsApp Business Account Type",
  [keys.CH_disableTracker]: "Disable CTWA tracker",
  [keys.CH_trackerNotificationContent]:
    "If you disable the Click To WhatsApp tracker, all the gathered data will still be visible, nevertheless the tracked events will be automatically archived.",
  [keys.CH_trackingBannerContent]:
    "You have Click To WhatsApp tracker disabled for this number. Enable the tool to continue gathering insights from your conversations!",
  [keys.CH_deleteTitle]:
    "If you wish to cancel your subscription for the phone number <NUMBER>, please type CANCEL in this field below.",
  [keys.CH_goBack]: "Go back",
  [keys.CH_activateInbox]: "Activate Inbox",
  [keys.CH_verifyBusiness]: "Verify Meta Business",
  [keys.CH_contactPartner]:
    "Please contact your Partner to Add or Port Phone Number",
  [keys.CH_cancelPayment]: "Cancel payment on behalf of the Client",
  [keys.CH_cancelPaymentInfo]:
    "This option removes your responsibility (as a 360dialog Partner) to pay on behalf of the Client. Please note that you, as the Partner, are still contractually responsible for all charges associated with the Client until the end of the month.",
  [keys.CH_pleaseLimitDescription]:
    "Please limit the Description field to 256 characters",
  [keys.CH_addNumberDisabledTwoNumbersAlt]:
    "You can currently not add more numbers to your account. If you require more numbers, please contact your integration partner.",
  [keys.CH_currentPlanNotSupportChangingHostingPlatform]:
    "Your current plan does not support changing the Hosting Platform Type.",
  [keys.CH_pleaseBeAwareMigrationBack]:
    "Please be aware that a migration back to the on-premise hosting solution is not possible once a number is hosted via Cloud API.",
  [keys.CH_signalDisabledMsg]:
    "Your current plan does not include access to the Signals app.",
  [keys.CH_inboxDisabledMsg]:
    "Your current plan does not include access to the get.chat inbox.",
  [keys.CH_partnerApiKeyPermission]: "Partner API Key Permission",
  [keys.CH_subscriptionPlan]: "Subscription plan",
  [keys.CH_changePlan]: "Change plan",
  [keys.CH_downgradeScheduleInfo]: "Downgrade scheduled for {{date}}",
  [keys.CH_upgradeScheduleInfo]: "Upgrade scheduled for {{date}}",
  [keys.CH_subscriptionSuccessful]:
    "Your subscription plan was successfully changed.",
  [keys.CH_selectSubscription]: "Select new subscription plan",
  [keys.CH_subscriptionSelectionInfo]:
    "By continuing through the next steps, you will change the subscription plan for the number {{number}}",
  [keys.CH_savingPlan]: "Saving plan change",
  [keys.CH_savePlan]: "Save plan change",
  [keys.CH_downgradeDisabledTooltip]:
    "Accounts can be downgraded 30 days after the last plan change. Your last plan change was on {{updatedDate}}. The next possible downgrade can be triggered on {{nextPossibleDowngradeDate}}",
  [keys.CH_pendingClientApprovalInfo]:
    "Your partner {{partnerName}} wishes to grant you access to this phone number. Please verify the ownership of the number, so that you can start using it for messaging on the WhatsApp Business Platform. After the verification your number will be set up and {{partnerName}} will be informed to complete the setup:",
  [keys.CH_verifyOwnership]: "Verify ownership",
  [keys.CH_pendingClientApprovalInfoNoClientInfo]:
    "In order to start using this number for messaging on the WhatsApp Business Platform, a client email needs to be attached and the client needs to confirm ownership of this number. Afterwards the number will be automatically set up. To learn more on how to attach a client email {{documentationLink}}.",
  [keys.CH_viewDocumentation]: "view our documentation",
  [keys.CH_pendingClientApprovalInfoWithClientInfo]:
    "In order to start using this number for messaging on the WhatsApp Business Platform, the attached client needs to confirm ownership of this number. Afterwards the number will be automatically set up. Please ask the client to log in to the 360dialog Client Hub and follow the instructions to confirm the number ownership for getting this number activated",
  [keys.CH_clientUserApproved]:
    "The number was successfully activated and is currently being set up. This will take a few moments. Please check back later.",
  [keys.CH_channelVerified]: "Channel verification successful",
  [keys.CH_displayName]: "Display name",
  [keys.CH_qualityRating]: "Quality rating",
  [keys.CH_manage]: "Manage",
  [keys.CH_actions]: "Actions",
  [keys.CH_wabaWebhookUrlError]:
    "In order to proceed with the hosting type change, you need to set a WABA-based webhook URL for this WABA. Learn more about setting webhook URLs for Cloud API numbers in our {{documentation}}.",
  [keys.CH_hostingType]: "Hosting Type",
  [keys.CH_partnerPermitted]: "Partner permitted",
  [keys.CH_partnerNotPermitted]: "Partner not permitted",
  [keys.CH_missing]: "Missing",
  [keys.CH_shared]: "Shared",
  [keys.CH_managePermissions]: "Manage Permission Settings",
  [keys.CH_planRecommended]: "Recommended",
  [keys.CH_planBasic]: "Basic",
  [keys.CH_planRegular]: "Regular",
  [keys.CH_planPremium]: "Premium",
  [keys.CH_planMonth]: "month",
  [keys.CH_planIncludes]: "Includes",
  [keys.CH_planPlus]: "plus",
  [keys.CH_planCloudHosting]: "Cloud API Hosting",
  [keys.CH_planNinetyServiceLevel]: "90% Hosting Service Level",
  [keys.CH_planTicketBased]: "Ticket-based support with 24 hours response time",
  [keys.CH_planOnPremiseHosting]: "On-premise hosting",
  [keys.CH_planWabaOfficial]: "Whatsapp Official Business Account request",
  [keys.CH_planHighServiceLevel]: ">99% Hosting Service Level",
  [keys.CH_planIncludesAdsFunnel]: "Includes Ads & measurement Funnel tracking",
  [keys.CH_planSelect]: "Select",
  [keys.CH_planSelected]: "Selected",
  [keys.CH_active]: "Active",
  [keys.CH_manageWaba]: "Manage Whatsapp Business Account",
  [keys.CH_numberDetails]: "Details",
  [keys.CH_numberBusinessAccount]: "Business Account",
  [keys.CH_funds]: "Funds",
  [keys.CH_plan]: "Plan",
  [keys.CH_accountName]: "Account name",
  [keys.CH_dataRegions]: "Data storage regions",
  [keys.CH_numberQuality]: "Quality rating",
  [keys.CH_numberMessagingLimit]: "Messaging limit",
  [keys.CH_low]: "Low",
  [keys.CH_medium]: "Medium",
  [keys.CH_high]: "High",
  [keys.CH_numberPending]: "Pending",
  [keys.CH_unlimited]: "Unlimited",
  [keys.CH_newConversationsPerDay]: "New conversations per day",
  [keys.CH_onlyAvailableCloudNumbers]: "Only available for Cloud API numbers",
  [keys.CH_qualityRatingTooltipOne]:
    "Your quality rating is based on how messages have been received by recipients over the past seven days and is weighted by recency.",
  [keys.CH_qualityRatingTooltipTwo]:
    "It is determined by a combination of quality signals from conversations between businesses and users. Examples include user feedback signals like blocks, reports, and the reasons users provide when they block a business.",
  [keys.CH_qualityRatingTooltipStatus]:
    "Quality Rating can have the following status:",
  [keys.CH_qualityRatingTooltipPending]:
    "<b>Active - Quality pending</b> The message template has yet to receive quality feedback from customers. Message templates with this status can be sent to customers and will be monitored for rating.",
  [keys.CH_qualityRatingTooltipHigh]:
    "<b>Active - High Quality</b> The template has received little or no negative customer feedback. Message templates with this status can be sent to customers.",
  [keys.CH_qualityRatingTooltipMedium]:
    "<b>Active - Medium Quality</b> The template has received negative feedback from multiple customers but may soon become paused or disabled. Message templates with this status can be sent to customers.",
  [keys.CH_qualityRatingTooltipLow]:
    "<b>Active - Low Quality</b> The template has received negative feedback from multiple customers. Message templates with this status can be sent to customers but are in danger of being paused or disabled soon, so it is recommended that you address the issues that customers are reporting.",
  [keys.CH_messagingLimitTooltip]:
    "Messaging limit determines the maximum number of business-initiated conversations each phone number can start in a rolling 24-hour period.",
  [keys.CH_messagingLimitTooltipLimits]:
    "Business-initiated conversation limits are: <b>250</b> · <b>1K</b> · <b>10K</b> · <b>100K</b>",
  [keys.CH_numberQualityRating]: "Number quality rating",
  [keys.CH_learnMore]: "Learn more",
  [keys.CH_numberMessagingLimitTitle]: "Number messaging limit",
  [keys.CH_seeMore]: "See more",
};

export default translate;
