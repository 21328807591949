import { useContext } from "react";

import { MainStore } from "./adminState/adminStateContext";
import { NotificationStore } from "./notificationState/notificationStateContext";
import { UsersListState } from "./listState/userListStateContext";
import { WabasListState } from "./listState/wabaListStateContext";
import { ProjectsAndWabaAccountState } from "./listState/projectsWabaAccountStateContext";
import { WabaTemplatesState } from "./listState/wabaTemplateStateContext";
import { ChannelListState } from "./listState/channelListStateContext";
import { PartnerChannelAndClientsState } from "./listState/clientStateContext";
import { WabaSupportState } from "./listState/wabaSupportStateContext";

const useAdminStore = () => {
  const adminStore = useContext(MainStore);
  const adminNotification = useContext(NotificationStore);
  const wabasState = useContext(WabasListState);
  const usersState = useContext(UsersListState);
  const projectsAndWabaAccountState = useContext(ProjectsAndWabaAccountState);
  const channelsState = useContext(ChannelListState);
  const partnerChannelAndClientsState = useContext(
    PartnerChannelAndClientsState
  );
  const wabaTemplatesState = useContext(WabaTemplatesState);
  const wabaSupportState = useContext(WabaSupportState);

  return {
    adminStore,
    adminNotification,
    wabasState,
    usersState,
    projectsAndWabaAccountState,
    channelsState,
    partnerChannelAndClientsState,
    wabaTemplatesState,
    wabaSupportState,
  };
};

export default useAdminStore;
