import * as Types from "./jiraTicket.types";
import { MPInstancesHub } from "@/services/api/RequestAxios/RequestAxiosHub";
import {
  packUpdateBilling,
  packJiraTicket,
  packJiraTickets,
  unpackTickets,
  packTicketComment,
  unpackJiraCreateTicket,
} from "./jiraTicket.mapper";
import { UIModelTickets } from "./jiraTicket.model";
import { IQueryParams } from "@/services/api/utils";
import qs from "qs";

const jiraTicketURL = (key?: string) => (key ? `/jira/` + key : `/jira`);
const partnerUsersJiraTicketURL = () => "/jira_partner_tickets";
const jiraAttachmentURL = (id: string) => `/jira/attachment/${id}`;

const jiraAttachUploadFileURL = (key: string) => `/jira/${key}/attach`;

const jiraUploadFileURL = `/jira/upload`;

export const jiraTicketUpdateBilling = async (
  payload: Types.IUpdateBillingPayload
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerRequestCreateTicket>(
      jiraTicketURL(),
      packUpdateBilling(payload)
    );

  return data;
};

export const jiraTicketCreate = async (
  payload: Types.IUIRequestCreateTicket
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IUIServerResponseCreateTicket>(
      jiraTicketURL(),
      packJiraTicket(payload)
    );

  return unpackJiraCreateTicket(data);
};

export const getTickets = async (
  queryParams?: IQueryParams,
  allTickets = false
) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get(
    allTickets ? partnerUsersJiraTicketURL() : jiraTicketURL(),
    {
      params: queryParams && packJiraTickets(queryParams),
      paramsSerializer: (params) => {
        const { ...otherParams } = params;
        const serializedQueryString = qs.stringify(otherParams, {
          arrayFormat: "repeat",
        });

        return `${serializedQueryString}`;
      },
    }
  );

  return new UIModelTickets(unpackTickets(data));
};

export const addComment = async (payload: Types.IUIRequestAddComment) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerRequestAddComment>(
      jiraTicketURL() + "/comment",
      packTicketComment(payload)
    );

  return data;
};

export const getTicket = async (key: string) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerRequestGetTicket>(
      jiraTicketURL(key)
    );
  return data;
};

export const getAttachment = async (id: string) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get(
    jiraAttachmentURL(id)
  );
  return data;
};

export const uploadFileTicket = async ({
  file,
  onUploadProgress,
}: {
  file: FormData;
  onUploadProgress: (ev: any) => void;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    jiraUploadFileURL,
    file,
    {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress,
    }
  );
  return data;
};

export const attachUploadFileTicket = async (
  key: string,
  fileIds: { fileIds: string[] }
) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    jiraAttachUploadFileURL(key),
    fileIds
  );
  return data;
};
