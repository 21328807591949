import { MPInstancesHub } from "@/api/RequestAxios/RequestAxiosHub";
import * as Types from "./statusPage.types";
import { unpackAllPagesToOverallStatus } from "./statusPage.mapper";

const getOverallStatusURL = `/status/pages`;
const addSubscriberMainStatusPageURL = `/status/subscribers`;

export const getOverallStatus = async () => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get<
    Array<Types.StatusPageSingle>
  >(getOverallStatusURL);
  return unpackAllPagesToOverallStatus(data);
};

export const addSubscriberMainStatusPage = async ({
  email,
  all,
}: Types.addSubscriberMainStatusPagePayload) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post<
    Array<Types.addSubscriberMainStatusPageResponse>
  >(addSubscriberMainStatusPageURL, { email, all });
  return data;
};
