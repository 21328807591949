import styled, { css } from "styled-components";

interface ITextButtonStyles {
  variant: string | undefined;
  backgroundColor?: string;
  inline?: boolean;
  size?: string;
  disabled?: boolean;
  showUnderline?: boolean;
}

const primaryStyles = css<ITextButtonStyles>`
  color: var(--blue);
  text-decoration: none;
  cursor: pointer;
  position: relative;
  font-size: ${(props) =>
    (props.size && "var(--font-size-" + props.size + ")") || "inherit"};
  padding: 0px 1px;
  box-shadow: ${(props) =>
    props.showUnderline && "inset 0 -0.07em 0 var(--blue)"};
  ${(props) =>
    props.inline &&
    css`
      display: inline-block;
    `}
  &:hover {
    color: var(--snow);
    background: var(--blue);
    border-radius: 2px;
  }
  &:focus {
    color: var(--snow);
    background: var(--blue);
    border-radius: 2px;
  }
  transition: background 0.2s, color 0.1s linear 0.15s;
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      color: var(--smoke-darker);
    `}
`;

const secondaryStyles = css<ITextButtonStyles>`
  color: var(--steel);
  text-decoration: none;
  cursor: pointer;
  position: relative;
  font-size: ${(props) =>
    (props.size && "var(--font-size-" + props.size + ")") || "inherit"};
  padding: 0px 1px;
  box-shadow: inset 0 -0.07em 0 var(--steel);
  ${(props) =>
    props.inline &&
    css`
      display: inline-block;
    `}
  &:hover {
    color: var(--snow);
    background: var(--steel);
    border-radius: 2px;
  }
  &:focus {
    color: var(--snow);
    background: var(--steel);
    border-radius: 2px;
  }
  transition: background 0.2s, color 0.1s linear 0.15s;
  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: not-allowed;
      color: var(--smoke-darker);
      box-shadow: none;
    `}
`;

export const TextButton = styled.span.attrs({
  className: "TextButton",
})<ITextButtonStyles>`
  ${(props) => props.variant === "primary" && primaryStyles}
  ${(props) => props.variant === "secondary" && secondaryStyles}
`;
