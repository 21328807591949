// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.WF_title]: "WhatsApp Business API Account Antrag",
  [keys.WF_intro]:
    "Bitte gib uns im Folgenden einige Informationen bezüglich des Unternehmens sowie der Telefonnummer, welches du mit der WhatsApp Business API verbinden möchtest, an. Bitte stelle sicher, dass dein Unternehmen den Compliance-Richtlinien von Meta entspricht.",
  [keys.WF_compliancePolicyLinkLabel]: "Compliance-Richtlinien",
  [keys.WF_personalInformation]: "Persönliche Informationen",
  [keys.C_email]: "Email",
  [keys.WF_emailTooltip]:
    "Die Email wird für Kommunikationszwecke und Benachrichtigungen hinsichtlich der WhatsApp Business API genutzt.",
  [keys.C_fullName]: "Vollständiger Name",
  [keys.WF_businessDetails]: "Unternehmensdetails",
  [keys.WF_legalCompanyName]: "Firmenname",
  [keys.WF_fbBusinessManagerId]: "Meta Business Manager ID",
  [keys.WF_FbIdTip_error]: "Falsche Meta Business Manager ID",
  [keys.WF_FbIdTip_header]: "Finden Sie Ihre Business-ID im Business Manager",
  [keys.WF_FBIDTip_text1]:
    "Deine Business Manager ID ist deinem Meta Business Konto zugeordnet.",
  [keys.WF_FBIDTip_text2]: "So findest du deine Business Manager ID:",
  [keys.WF_FBIDTip_step1]:
    "Gehe im Meta Business Manager auf Unternehmenseinstellungen.",
  [keys.WF_FBIDTip_step2]: "Navigiere zu den Unternehmensinformationen.",
  [keys.WF_FBIDTip_step3]:
    "Zu Beginn der Seite findest du unterhalb deines Unternehmensnamens die Business Manager ID.",
  [keys.WF_messaging]: "Messaging",
  [keys.WF_whatsAppPhoneNumber]: "WhatsApp Telefonnummer",
  [keys.WF_whatsAppPhoneNumberTooltip1]:
    "Entspricht der Telefonnummer, welche für den WhatsApp Business Account genutzt werden soll.",
  [keys.WF_whatsAppPhoneNumberTooltip2]:
    "Bitte nutze hierfür nicht deine persönliche Telefonnummer.",
  [keys.WF_timezone]: "Zeitzone",
  [keys.WF_attention]: "Achtung",
  [keys.WF_govAgencyText1]:
    "Beantrage kein Konto über dieses Formular, falls du eine Regierungsstelle vertrittst. Bevor du ein WhatsApp Business Konto beantragst, benötigst du zunächst die Genehmigung durch Meta. Falls du diese Genehmigung noch nicht erhalten hast order dir nicht sicher bist, ob diese Regelung auf dein Unternehmen zutrifft <LINK>https://docs.360dialog.com/waba-for-government-agencies | lies bitte unsere Dokumentation und fülle das verlinkte Formular aus<LINK>. Wir werden unser Bestes tun um dich zu unterstützen. ",
  [keys.PI_perMonth]: "/ Monat",
  [keys.PI_text1]:
    "Nachrichtenvorlagen werden entsprechend der <LINK>https://developers.facebook.com/docs/whatsapp/pricing | Meta/WhatsApp EUR Preisliste<LINK> berechnet. Eine Vorauszahlung dieser bleibt vorbehalten.",
  [keys.PI_text2]: "-",
  [keys.PI_textUSD]:
    "Nachrichtenvorlagen werden entsprechend der <LINK>https://developers.facebook.com/docs/whatsapp/pricing | Meta/WhatsApp USD Preisliste<LINK> berechnet. Eine Vorauszahlung dieser bleibt vorbehalten.",
  [keys.PI_billedMonthly]: "Monatliche Abrechnung",
  [keys.PI_completeOrderButtonLabel]: "Auftrag abschließen",
  //  [keys.WF_termsIntro]: "translation_missing",
  //  [keys.WF_terms1]: "translation_missing",
  //  [keys.WF_terms1p1]: "translation_missing",
  //  [keys.WF_terms1p2]: "translation_missing",
  //  [keys.WF_terms2]: "translation_missing",
  //  [keys.WF_terms2p1p1]: "translation_missing",
  //  [keys.WF_terms2p1and]: "translation_missing",
  //  [keys.WF_terms2p1p2]: "translation_missing",
  //  [keys.WF_terms2p2]: "translation_missing",
  //  [keys.WF_terms2p3]: "translation_missing",
  //  [keys.WF_terms2p4]: "translation_missing",
  //  [keys.WF_terms3]: "translation_missing",
  //  [keys.WF_terms3p1]: "translation_missing",
  //  [keys.WF_terms3p2]: "translation_missing",
  //  [keys.WF_terms4]: "translation_missing",
  //  [keys.WF_terms4p1]: "translation_missing",
  //  [keys.WF_terms4p2]: "translation_missing",
  //  [keys.WF_terms4p3]: "translation_missing",
  //  [keys.WF_terms5]: "translation_missing",
  //  [keys.WF_terms5p1]: "translation_missing",
  //  [keys.WF_terms5p2]: "translation_missing",
  //  [keys.WF_terms5p3]: "translation_missing",
  //  [keys.WF_terms5p4]: "translation_missing",
  //  [keys.WF_terms5p5]: "translation_missing",
  //  [keys.WF_terms5p6]: "translation_missing",
  //  [keys.WF_terms5p7]: "translation_missing",
  //  [keys.WF_terms5p8]: "translation_missing",
  //  [keys.WF_termsLabel]: "translation_missing",
  //  [keys.WF_termsCheckbox1]: "translation_missing",
  //  [keys.WF_termsCheckbox1Detail]: "translation_missing",
  //  [keys.WF_termsCheckbox2]: "translation_missing",
  //  [keys.WF_termsCheckbox3]: "translation_missing",
  //  [keys.WF_verifiedBsp]: "translation_missing",
  //  [keys.WF_buttonLabelAccept]: "translation_missing",
  [keys.WF_buttonLabelSave]: "Speichern",
  [keys.WF_paymentInfo]: "Zahlung",
  [keys.WF_submission]: "Antrag wird bearbeitet...",
  [keys.WF_thanksTitle]: "Vielen Dank!",
  [keys.WF_thanksNext]:
    "Wir senden dir eine Email mit weiteren Informationen zum weiteren Verifizierungsprozess.",
  [keys.WF_thanksMob1]:
    "In der Zwischenzeit kannst du bereits jetzt in den Meta Business Manager wechseln um unsere",
  [keys.WF_thanksMobQuotes]: "messaging on behalf",
  [keys.WF_thanksMob2]: "Anfrage zu genehmigen.",
  [keys.WF_thanksButtonLabel]: "Zu den Anfragen",
  [keys.WF_0800error]:
    "Du versuchst eine 0800-Nummer zu registrieren. Solche Nummern können üblicherweise keine internationalen Telefonate empfangen, welches eine notwendige Bedingung zu Verifizierung ist. Sollte dies zutreffen, stelle bitte eine andere Nummer zur Verfügung.",
  [keys.WF_paymentCardOwnerName]: "Name des Karteninhabers",
  [keys.WF_paymentCreditCard]: "Kreditkarte",
  [keys.WF_paymentCardOwnerNamePlaceholder]:
    "Falls abweichend vom Antragsteller",
  [keys.WF_hubAccess]: "360dialog Client Hub Zugang",
  [keys.WF_hubAccessHelp]:
    "Nach dem Antrag bekommst du Zugang zu unserem 360dialog Client Hub, wo du deine WhatsApp Business Konten managen kannst.",
  [keys.WF_hubAccessHelpPassword]:
    "Bitte vergib ein Passwort, welches du zum Einloggen in den 360dialog Client Hub verwenden möchtest.",
  [keys.WF_passwordLabel]: "Passwort wählen",
  [keys.WF_repeatPasswordLabel]: "Passwort wiederholen",
  [keys.WF_passwordError1]: "Passwort muss mindestens 8 Zeichen land sein.",
  [keys.WF_passwordError2]:
    "Passwort muss mindestens einen Kleinbuchstaben enthalten (a-z).",
  [keys.WF_passwordError3]:
    "Passwort muss mindestens einen Großbuchstaben enthalten (A-Z).",
  [keys.WF_passwordError4]:
    "Passwort muss mindestens eine Zahl (z.B. 0-9) oder ein Sonderzeichen (z.B. !#$%&?) enthalten.",
  [keys.WF_passwordRepeatError]: "Passwörter stimmen nicht überein",
  [keys.WF_cardError]: "Bitte gib eine gültige Kartennummer an",
  [keys.WF_passwordErrorZxcvbn]:
    "Das Passwort ähnelt gängigen Mustern und ist einfach zu erraten. Füge das ein oder andere Wort hinzu. Ungewöhnliche Wörter sind besser.",
  [keys.CON_title]: "Verbinde deinen Meta Business Account",
  [keys.CON_textIntro]:
    "Um fortzufahren, musst du auf das Meta Konto deines Unternehmens zugreifen. Durch das Anklicken des 'Weiter mit Meta' unten kannst du: ",
  [keys.CON_bullet1]:
    "Sowohl bestehende Meta und WhatsApp Konten auswählen, als auch neue erstellen.",
  [keys.CON_bullet2]:
    "Einen Anzeigename sowie eine Beschreibung für dein WhatsApp Business Profil angeben.",
  [keys.CON_bullet3]:
    "Die Telefonnummer, welche du für den Zugang zur WhatsApp Business API verwendest, verbinden und verifizieren.",
  [keys.CON_numberHelpText]:
    "Benutze bitte keine Telefonnummer, die aktuell bereits mit einem WhatsApp Konto verbunden ist. Das beinhaltet auch Telefonnummern, welche mit der WhatsApp Business App verbunden sind. Du wirst andernfalls den Registrierungsprozess nicht abschließen können.",
  [keys.CON_numberHelpLink]: "Lerne mehr über deine WhatsApp Telefonnummer",
  [keys.CON_buttonLabel]: "Weiter mit Meta",
  [keys.CAN_title]: "Wie können wir helfen,",
  [keys.CAN_textIntro]:
    "Wie es scheint hast du den Meta Anmeldeprozess nicht vollständig abgeschlossen. Aber keine Sorge: Du kannst jederzeit neu starten. Gerne unterstützen wir dich dabei.",
  [keys.CAN_faq]: "Oft gefragt",
  [keys.CAN_buttonHelp]:
    "Bereit für einen neuen Versuch? Klicke hier um das Widget neu zu starten.",
  [keys.WF_partnerIntro]:
    "Du bewirbst dich als Kunde deines Partners <PARTNER>.",
  [keys.WF_userAlreadyExists]:
    "Es besteht bereits ein Nutzer mit dieser E-Mail Adresse. Bitte logge dich ein um mit dem Onboarding fortzufahren oder um eine weitere Nummer hinzuzufügen.",
  [keys.CAN_livesupportTitle]: "Bekomme individuellen Support",
  [keys.CAN_livesupportText]:
    "Klicke auf den Button rechts um ein persönliches Meeting mit einem unserer Support-Mitarbeiter zu buchen. Wir werden dich dann beim Onboarding-Prozess begleiten.",
  [keys.CAN_livesupportButtonLabel]: "Meeting buchen",
  [keys.CAN_livesupportButtonLabelSuccess]: "Meeting gebucht",
  [keys.PI_selectedLabel]: "ausgewählt",
  [keys.SH_copied]: "Kopiert",
  [keys.SH_copyPageLink]: "Link zur Seite kopieren",
  [keys.SH_pageLink]: "Link zur Seite",
  [keys.PI_pricingPlanLinkLabel]:
    "Klicke hier, um mehr über unsere Preise zu erfahren.",
  [keys.WF_alreadyHaveAccount]: "Du hast bereits ein Konto?",
  [keys.WF_login]: "Login",
  [keys.WF_mobileHeading]: "Sorry!",
  [keys.WF_notSupportedMobile]:
    "Leider wird der Express-Anmeldeprozess aktuell nicht für mobile Geräte unterstützt. Wechsle auf ein Desktop-Gerät und besuche diese Seite erneut.",
  [keys.WF_saveURL]: "Aktuelle Seite teilen",
  [keys.WF_continueAnyway]: "Weiter zum 'Classic' Anmeldeformular",
  [keys.WF_sharedSuccessfully]: "Seiten-URL erfolgreich geteilt",
  [keys.WF_copiedUrl]: "URL in Zwischenablage kopiert",
  [keys.CC_consentText]:
    "Diese Website nutzt Cookies um die Nutzererfahrung zu optimieren.",
  [keys.CC_acceptButtonLabel]: "Akzeptieren",
  [keys.CC_declineButtonLabel]: "Ablehnen",
  [keys.CC_title]: "Diese Website nutzt Cookies",
  [keys.CC_text]:
    "Wir möchten sowohl unsere als auch Cookies von Drittanbietern, sowie ähnliche Technologien nutzen um unsere Nutzererfahrung zu verbessern. Mit den Checkboxen kannst du deine Einstellungen ändern.",
  [keys.CC_cookies1text]:
    "<cookies1label> helfen die Grundfunktionen der Website zu gewährleisten, z.B. um zu speichern ob du der Cookienutzung eingewilligt hast oder nicht.",
  [keys.CC_cookies1label]: "Essentielle Cookies",
  [keys.CC_cookies2text]:
    "<cookies2label> machen es möglich Informationen über das Nutzerverhalten auf unserer Website zu sammeln, so dass wir diese besser machen können.",
  [keys.CC_cookies2label]: "Analyse-Cookies",
  [keys.CC_cookies3text]:
    "<cookies3label> ermöglichen es uns dir sowohl hier als auch auf anderen Plattformen relevante Inhalte und Anzeigen zu zeigen. ",
  [keys.CC_cookies3label]: "Marketing-Cookies",
  [keys.CC_buttonLabelAcceptSelected]: "Ausgewählte akzeptieren",
  [keys.CC_buttonLabelAcceptNecessary]: "Notwendige akzeptieren",
  [keys.CC_buttonLabelAcceptAll]: "Alle akzeptieren",
  [keys.WF_currentBussinessSolutionsProvider]:
    "Aktueller Business Solution Provider",
  [keys.MIG_fbErrorWaitNewCode]:
    "Leider musst du etwas warten bis du den Code erneut anfragen kannst. Bitte warte <HOUR> und <MINUTES> bevor du es erneut versuchst.",
  [keys.CH_portExistingNumber]: "Existierende Nummer portieren",
  [keys.CH_portWhatsAppProfile]:
    "Portiere ein bestehendes WhatsApp Business Profil von einem beliebigen Anbieter zu 360dialog.",
  [keys.CH_addNewNumber]: "Eine neue Telefonnummer hinzufügen",
  [keys.CH_setUpWhatsAppProfile]:
    "Erstelle dein WhatsApp Business Konto und Profil mit einer Telefonnummer, welche bisher nicht in Benutzung ist.",
  [keys.CH_welcomeTo360dialog]: "Willkommen bei 360dialog!",
  [keys.CH_pleaseSelect]:
    "Bitte wähle aus, ob du eine bestehende WhatsApp Business API Nummer zu 360dialog portieren möchtest, oder ob du ein neues Konto für eine bisher mit der WhatsApp Business API nicht genutzte Telefonnnummer erstellen möchtest. ",
  [keys.CH_continueWithAccount]: "Mit Kontoerstellung fortfahren",
  [keys.CAN_createTicket]: "Erstelle ein Support-Ticket",
  [keys.CAN_ticketCreated]: "Ticket erstellt",
  [keys.CON_contactSupportHelptext]: "Schwierigkeiten beim Onboarding?",
  [keys.WF_goToLogin]: "Gehe zum Login",
  [keys.WF_planSelection]: "Tarif-Auswahl",
  [keys.WF_monthly]: "Monatlich",
  [keys.WF_yearly]: "Jährlich",
  [keys.WF_month]: "Monat",
  [keys.WF_year]: "Jahr",
  [keys.WF_billedMonthly]: "Monatlich abgerechnet",
  [keys.WF_months]: "Monate",
  [keys.WF_billedAt]: "Abgerechnet am",
  [keys.WF_selected]: "Ausgewählt",
  [keys.WF_select]: "Auswählen",
  [keys.WF_basic]: "Basic",
  [keys.WF_professional]: "Professional",
  [keys.WF_forDevelopers]: "Favorit für Entwickler",
  [keys.WF_scalablePlan]: "Unser skalierbarster Tarif",
  [keys.WF_buttonLabelSubmit]: "Daten absenden",
  [keys.WF_buttonLabelCancel]: "Zurück",
  [keys.WF_reviewData]: "Daten überprüfen",
  [keys.WF_passwordError5]:
    "Das Passwort darf keine E-Mail-Informationen enthalten",
  [keys.CC_openCookiePreferences]: "Cookie Präferenzen öffnen",
  [keys.CC_acceptCardLabel]:
    'Um unseren Live Chat nutzen zu können, müssen Sie "analytische Cookies" akzeptieren.',
  [keys.WF_freemailError]:
    'Sie können sich nicht mit der Domain "<DOMAIN>" registrieren. Bitte nutzen Sie ihre geschäftliche Mailadresse, um einen Account anzulegen.',
  [keys.WF_termsIntro]:
    'Mit der Erteilung dieses "Auftrages" beantragt der Kunde die Nutzung der WhatsApp Business Solution Services (wie nachstehend näher spezifiziert, nachfolgend die "Services"), die von der 360dialog GmbH, Torstr. 61, 10119 Berlin, Deutschland, als autorisierter "Solution Provider" (nachfolgend "360dialog" oder "BSP"). Die "Services" sind alle Dienstleistungen, die 360dialog im Zusammenhang mit der Einrichtung, der technischen Integration und der laufenden Verwaltung von WhatsApp Business Accounts ("WABA(s)") erbringt. Mit der Erteilung dieses Auftrags ermächtigt der Kunde 360dialog, den/die WABA(s) des Kunden bei WhatsApp (wie unten definiert) anzufordern und die WABA(s) im Namen des Kunden zu verwalten. Dies beinhaltet die Weitergabe von Informationen zwischen WhatsApp und dem Kunden für die Zwecke dieser Vereinbarung.',
  [keys.WF_terms1]: "WABA MSISDNs",
  [keys.WF_terms1p1]:
    "Der Kunde beantragt hiermit WABAs für folgende MSISDN (inklusive Ländervorwahl im Format +49):",
  [keys.WF_terms1p2]:
    'Die Genehmigung der einzelnen WABAs liegt im Ermessen von WhatsApp. In diesem Dokument bezeichnet der Begriff "WhatsApp" WhatsApp Inc. und/oder ein mit ihr verbundenes Unternehmen innerhalb der Meta-Gruppe, das Dienstleistungen im Rahmen der WhatsApp Business Solution anbietet.',
  [keys.WF_terms2]: "Einhaltung der WhatsApp-Bedingungen",
  [keys.WF_terms2p1p1]:
    "Der Kunde garantiert und sichert hiermit zu, die WABAs strikt in Übereinstimmung mit den geltenden WhatsApp Business Solution Terms https://www.whatsapp.com/legal/business-solution-terms, den Meta Client Terms for WhatsApp, die als Anlage 2 beigefügt sind, sowie allen anderen geltenden WhatsApp-Bedingungen, -Richtlinien und -Vorschriften zu nutzen, vor allem",
  [keys.WF_terms2p1and]: "und",
  [keys.WF_terms2p1p2]: 'insgesamt die "WhatsApp-Bedingungen".',
  [keys.WF_terms2p2]:
    "Der Kunde nimmt zur Kenntnis und erklärt sich damit einverstanden, dass WhatsApp die WhatsApp-Bedingungen ändern und/oder zusätzliche Bedingungen, Richtlinien und Vorschriften herausgeben kann, und dass diese zusätzlichen Bedingungen, Richtlinien und Vorschriften bei ihrer Veröffentlichung Teil der WhatsApp-Bedingungen sind.",
  [keys.WF_terms2p3]:
    "Der Kunde stellt 360dialog von allen Schäden, Kosten und Nachteilen frei, die durch einen Verstoß gegen die WhatsApp-Bedingungen entstehen.",
  [keys.WF_terms2p4]:
    "Die WABA(s) sind nur für die Nutzung durch den Kunden bestimmt. Der Kunde sichert zu und gewährleistet, dass er die WABA(s) nicht an Dritte für Zwecke außerhalb dieses Auftrags verteilt, verkauft, weiterverkauft, vermietet oder ihnen anderweitig Zugang dazu gewährt.",
  [keys.WF_terms3]: "Nutzung der Dienste",
  [keys.WF_terms3p1]:
    'Die Dienste (wie oben definiert) werden von 360dialog für den Kunden bereitgestellt. Die Nutzung der Dienste unterliegt den 360dialog-Nutzungsbedingungen, die als Anlage 1 beigefügt sind. Dieser Auftrag und die 360dialog-Nutzungsbedingungen werden im Folgenden als "Vereinbarung" bezeichnet.',
  [keys.WF_terms3p2]:
    "Gemäss den WhatsApp-Bedingungen sind die Kunden berechtigt, ihre WABA(s) zu einem anderen WABA Business Solution Provider zu migrieren. Alle Bemühungen, die 360dialog im Zusammenhang mit einem solchen Antrag unternimmt, unterliegen der 360dialog-Preisliste.",
  [keys.WF_terms4]: "Sonstiges",
  [keys.WF_terms4p1]:
    "Diese Vereinbarung unterliegt dem Recht der Bundesrepublik Deutschland unter Ausschluss des UN-Übereinkommens über Verträge über den internationalen Warenkauf und der Kollisionsnormen.",
  [keys.WF_terms4p2]:
    "Erfüllungsort und Gerichtsstand ist Berlin, Deutschland. 360dialog behält sich jedoch das Recht vor, jeden anderen gesetzlich zulässigen Gerichtsstand zu wählen.",
  [keys.WF_terms4p3]:
    "Sollte eine Bestimmung dieser Vereinbarung von einem zuständigen Gericht für rechtswidrig befunden werden, so bleiben die übrigen Bestimmungen dieser Vereinbarung, sofern sie im Wesentlichen erfüllt werden können, in vollem Umfang in Kraft und wirksam. Die betroffene Klausel wird durch eine solche ersetzt, die der ursprünglichen Absicht der Parteien am nächsten kommt.",
  [keys.WF_terms5]: "360dialog Geschäftsbedingungen und Preisgestaltung",
  [keys.WF_terms5p1]:
    "Die monatliche Lizenzgebühr für das Hosting der WhatsApp Business API liegt bei 50,00 USD/Nummer/Monat.",
  [keys.WF_terms5p2]:
    "Terms of the Monthly Licensee fee is month-by-month and can be terminate with 30 days notice.",
  [keys.WF_terms5p3]:
    "Die monatlichen Gebühren für das Hosting der WhatsApp Business API werden auf Basis des Kalendermonats in Rechnung gestellt.",
  [keys.WF_terms5p4]:
    "Die monatliche Lizenzgebühr wird von Monat zu Monat berechnet und kann mit einer Frist von 30 Tagen gekündigt werden.",
  [keys.WF_terms5p5]:
    "Beginnt ein Hosting nicht am 1. eines Kalendermonats, wird es nur anteilig nach den Tagen berechnet, an denen das Hosting aktiviert wurde.",
  [keys.WF_terms5p6]:
    "Alle Lizenzgebühren werden zu Beginn des jeweiligen Monats in Rechnung gestellt.",
  [keys.WF_terms5p7]:
    "Die Kosten für WhatsApp Template Messages sind nicht in der monatlichen Lizenzgebühr enthalten und werden gemäß den Meta/Whatsapp-Preisen in EUR berechnet, wie unter https://developers.facebook.com/docs/whatsapp/pricing/ angegeben.",
  [keys.WF_terms5p8]:
    "Alle Gebühren verstehen sich zuzüglich Mehrwertsteuer, falls zutreffend.",
  [keys.WF_termsLabel]: "Geschäftsbedingungen",
  [keys.WF_termsCheckbox1]:
    "Ich erkläre mich mit der Anlage 1 - 360dialog Nutzungsbedingungen einverstanden.",
  [keys.WF_termsCheckbox1Detail]: "Letzte Änderung:",
  [keys.WF_termsCheckbox2]:
    "Ich habe die Anlage 2 - Meta-Kundenbedingungen für WhatsApp zur Kenntnis genommen und erkläre mich damit einverstanden.",
  [keys.WF_termsCheckbox3]:
    "Ich erkläre mich mit der Anlage 3 - 360dialog Preisgestaltung einverstanden.",
  [keys.WF_verifiedBsp]:
    "360dialog ist ein verifizierter WhatsApp Solution Provider und hat von Meta/WhatsApp das Recht erhalten, seinen Kunden Zugang zur WhatsApp Business API zu gewähren.",
  [keys.WF_buttonLabelAccept]: "Bedingungen akzeptieren",
  [keys.CON_sessionExp]:
    'Deine Sitzung mit Meta ist abgelaufen. Bitte wiederhole den Vorgang, indem du auf die Schaltfläche "Weiter mit Meta" klickst.',
  [keys.VER_checkEmailTitle]: "Prüfe deine E-Mail!",
  [keys.VER_submit]: "Absenden",
  [keys.VER_bullet1]:
    "Um fortzufahren, musst du zuerst deine E-Mail verifizieren.",
  [keys.VER_bullet2]: "Es wurde ein einmaliger Passcode an <EMAIL> gesendet.",
  [keys.VER_bullet3]:
    "Bitte gib den unten stehenden Code ein, um deine E-Mail-Adresse zu verifizieren.",
  [keys.VER_inputLabel]: "Einmaliger Passcode",
  [keys.VER_inputPlaceholder]: "Gib den Passcode hier ein",
  [keys.VER_inputError]: "Der eingegebene Code ist nicht korrekt.",
  [keys.VER_closeWindowWarning]:
    "Bitte stelle sicher, dass du dieses Fenster nicht schließt. Deine Daten werden erst gespeichert, wenn du den Bestätigungscode eingibst",
  [keys.VER_newCodeSentToEmail]:
    "Ein neuer Code wurde an deine E-Mail geschickt",
  [keys.AI_almostDone]: "Du bist fast fertig, <USERNAME>",
  [keys.AI_addInfo]: "Füge zusätzliche Unternehmensinformationen hinzu.",
  [keys.AI_completeInfo]: "Informationen vervollständigen",
  [keys.AI_companyInfo]:
    "Um fortzufahren, benötigen wir einige Informationen über dein Unternehmen",
  [keys.AI_companyNameLabel]: "Juristischer Firmenname",
  [keys.AI_companyNamePlaceholder]: "Gib den Namen des Unternehmens ein",
  [keys.AI_companyAddressLabel]: "Adresse des Unternehmens",
  [keys.AI_companyAddressPlaceholder]: "Gib die Adresse des Unternehmens ein",
  [keys.AI_selectCountry]: "Wähle das Land",
  [keys.AI_vatIdLabel]: "UMSATZSTEUER-ID",
  [keys.AI_vatIdPlaceholder]: "Gib die Umsatzsteuer-Identifikationsnummer ein",
  [keys.AI_companyVertical]: "Branche des Unternehmens",
  [keys.FS_accessCompanyFBM]:
    "Um fortzufahren, musst du <strong>auf den Meta Business Manager deines Unternehmens zugreifen</strong> und die folgenden Schritte ausführen:",
  [keys.FS_connectNumber]:
    "Verbinde und verifiziere deine Telefonnummer, um die WhatsApp Business API zu nutzen.",
  [keys.FS_addInfoToBusinessManager]:
    'Um ein aktives Konto zu haben, musst du deine Informationen im Abschnitt "Business Info" der Business Manager-Einstellungen hinzufügen.',
  [keys.FS_fbCommercePolicy]:
    "Diese Informationen werden von Meta verwendet, um die Einhaltung der Handelsrichtlinien zu überprüfen. Wenn sie nicht korrekt eingerichtet sind, wird das Konto sofort gesperrt.",
  [keys.FS_embeddedSignupInfo]:
    "<LINK>https://docs.360dialog.com/docs/embedded-signup | Weitere Informationen<LINK>",
  [keys.WF_fullName]: "Gib deinen vollständigen Namen ein",
  [keys.WF_noAccount]: "Noch kein Konto?",
  [keys.WF_signUpHere]: "Hier registrieren",
  [keys.WF_loginHere]: "Hier anmelden",
  [keys.WF_passwordError6]:
    "Das Passwort darf keine Benutzerinformationen enthalten",
  [keys.WF_comapnyNameTooltip]:
    "Bitte gib deinen juristischen Firmennamen ein. Der Name deines WhatsApp-Profils kann später gewählt werden und kann abweichen, wenn bestimmte Bedingungen erfüllt sind.",
  [keys.WF_vatIdTooltip]:
    "Umsatzsteuer-Identifikationsnummer nur für Unternehmen mit Sitz in der EU erforderlich. Bitte geben Sie die vollständige Umsatzsteuer-ID mit Präfix an. Beispiel - ES25084476",
  [keys.WF_vatIdNotValid]: "Die Umsatzsteuer-ID ist ungültig",
  [keys.WF_noVatId]:
    "Mein Unternehmen hat keine Umsatzsteuer-Identifikationsnummer",
  [keys.WF_noVATMessage]:
    "Wenn dein Unternehmen keine Umsatzsteuer-Identifikationsnummer hat oder diese nicht gültig ist, berechnen wir dir die deutsche Umsatzsteuer.",
  [keys.WF_vatInfoUpdated]:
    "Firmen- und Umsatzsteuerinformationen aktualisiert",
  [keys.WF_governmentalCheck]: "Staatliche Prüfung",
  [keys.WF_notGovernmental]: "Ich bin keine Regierungsbehörde",
  [keys.WF_facebookBusinessSetup]: "Meta Business-Einrichtung",
  [keys.WF_ukraineExcludedAreasNote]:
    "Das Unternehmen ist nicht Teil der Regionen Krim, Donezk und Luhansk.",
  //  [keys.FS_comeBackFromMeta]: "translation_missing",
  //  [keys.FS_completeMataOnboarding]: "translation_missing",
  //  [keys.FS_completeMataOnboardingInfo]: "translation_missing",
  //  [keys.CC_openCookiePreferences]: "translation_missing",
  //  [keys.CC_disabledCookiePreferences]: "translation_missing",
  // [keys.ON_paymentDebitNotice]: "translation_missing",
};

export default translate;
