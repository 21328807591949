import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { observable, action, runInAction, makeObservable } from "mobx";

export interface language {
  short: string;
  fullName: string;
  display: string;
}

interface Phrase {
  [k: string]: string;
  key: string;
  value: string;
}
export interface TranslationsLanguage {
  language: "en" | "ru" | "pt" | "de" | "es";
  phrases: Phrase[];
}
export interface FullTranslationArray {
  [k: string]: string;
}

export const NS = "translation";

export const languages: language[] = [
  {
    short: "en",
    fullName: "English",
    display: "English (EN)",
  },
  {
    short: "de",
    fullName: "German",
    display: "Deutsch (DE)",
  },
  {
    short: "ru",
    fullName: "Russian",
    display: "Pусский (RU)",
  },
  {
    short: "pt",
    fullName: "Portuguese",
    display: "Português (Brasil) (PT-BR)",
  },
  {
    short: "es",
    fullName: "Spanish",
    display: "Español (ES)",
  },
];

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    debug: false, // TODO: process.ENV to detect environment
    resources: {},
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["cookie", "localStorage", "querystring", "navigator"],
    },
  });

class i18next {
  t: any;
  addResourceBundle = i18n.addResourceBundle;
  language = i18n.language;

  constructor() {
    runInAction(() => {
      this.t = i18n.t.bind(i18n);
    });
    makeObservable(this, {
      t: observable,
      addResourceBundle: observable,
      language: observable,
      changeLanguage: action,
    });
  }

  addTranslation = (translationsArray: TranslationsLanguage[]) => {
    translationsArray.forEach((translation) => {
      i18n.addResourceBundle(
        translation.language,
        NS,
        translation.phrases.reduce<FullTranslationArray>((fullObj, phrase) => {
          fullObj[phrase.key] = phrase.value;
          return fullObj;
        }, {})
      );
    });
  };

  changeLanguage = async (lg: string) => {
    await i18n.changeLanguage(lg);
    // moment.locale(lg);
    this.t = i18n.getFixedT(lg);
  };
}

export default new i18next();
