// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.WIZ_continueLabelWithHelp]:
    "Para continuar, terminar as etapas necessárias",
  [keys.WIZ_billingPlanTitle]: "Selecione um plano",
  [keys.WIZ_billingMethodTitle]: "Adicione detalhes de pagamento",
  [keys.WIZ_billingTermsTitle]: "Aceite termos e condições",
  [keys.WIZ_paymentMethodTitleStep1]: "1. Selecione método de pagamento",
  [keys.WIZ_paymentMethodTitleStep2]: "2. Checkout",
  [keys.WIZ_paymentMethodCountrySelectLabel]:
    "Selecione seu país para ver métodos de pagamento disponíveis.",
  [keys.WIZ_termsTitle]: "Termos e condições da 360dialog:",
  [keys.WIZ_createNumberTitle]:
    "Criar Conta de WhatsApp Business e conectar número",
  [keys.WIZ_numberWasSetUp]: "Seu número <NUMBER> foi configurado com sucesso.",
  [keys.WIZ_connectInboxTitle]: "Terminar seu onboarding com get.chat",
  [keys.WIZ_connectInboxText]:
    "Para configurar sua caixa de entrada get.chat, siga as instruções do e-mail que você já deveria ter recebido. Sua conta WhatsApp Business está configurada e já conectada para que você possa começar a usar a caixa de entrada imediatamente.",
  [keys.WIZ_goToHubButtonLabel]: "Siga para o 360dialog Client Hub",
  [keys.WIZ_channelPageCheckboxText1]: "👤 Você tem uma conta pessoal de Meta",
  [keys.WIZ_channelPageCheckboxText2]:
    "📞 O número que será conectado não está em uso atual com outra Conta de WhatsApp.",
  [keys.WIZ_channelPageCheckboxText3]:
    "📲 Você pode verificar a propriedade sobre o número mediante SMS ou telefonemas internacionais.",
  [keys.WIZ_channelPageCheckboxText4]:
    "🖋️ Você já tem uma conta business no Meta, a qual está verificada.",
  [keys.WIZ_channelPageSubtitle1]:
    "Na etapa seguinte, você precisa configurar suas contas usando suas credenciais do Meta e seguindo as etapas fornecidas.",
  [keys.WIZ_channelPageSubtitle2]:
    "Depois disso, configuraremos automaticamente tudo para você e você poderá utilizar a API comercial WhatsApp.",
  [keys.WIZ_waitingText1]:
    "Estamos atualmente configurando sua própria instância de servidor, portanto, você estará pronto para começar a usar a API comercial WhatsApp em alguns minutos.",
  [keys.WIZ_waitingText2]:
    "Iniciando seu cliente WhatsApp Business API hospedado.",
  [keys.WIZ_addNumberText]:
    "Opcionalmente, você também pode <BUTTON>adicionar um novo número agora mesmo<BUTTON>.",
  [keys.WIZ_channelTitle]: "Certifique-se de cumprir os seguintes requisitos.",
  [keys.WIZ_titleSpecifiedPermission]:
    "O parceiro está solicitando permissão para acessar o WhatsApp Business API para o seguinte número:",
  [keys.WIZ_titleUnspecifiedPermission]:
    "Selecione os números para os quais você deseja dar permissão ao parceiro para acessar a WhatsApp Business API.",
  [keys.WIZ_selectedCount]: "Números selecionados <NUMBER>",
  //  [keys.WIZ_numbers]: "translation_missing",
  //  [keys.WIZ_selectNumbers]: "translation_missing",
  //  [keys.WIZ_noPendingChannels]: "translation_missing",
  //  [keys.WIZ_verifyOwnership]: "translation_missing",
  //  [keys.WIZ_somethingWrong]: "translation_missing",
  //  [keys.WIZ_NumberVerificationTitle]: "translation_missing",
  //  [keys.WIZ_NumberVerificationDesc]: "translation_missing",
};

export default translate;
