// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.WF_title]: "Cadastro de conta de WhatsApp Business API",
  [keys.WF_intro]:
    "Favor fornecer informações sobre a empresa e o número de telefone que você gostaria de conectar à WhatsApp Business API. Certifique-se de que sua empresa segue a política comercial do Meta.",
  [keys.WF_compliancePolicyLinkLabel]: "política comercial",
  [keys.WF_personalInformation]: "Informações pessoais",
  [keys.C_email]: "Email",
  [keys.WF_emailTooltip]:
    "O e-mail será utilizado para fins de comunicação e alertas sobre a WhatsApp Business API",
  [keys.C_fullName]: "Nome completo",
  [keys.WF_businessDetails]: "Detalhes da empresa",
  [keys.WF_legalCompanyName]: "Nome da empresa",
  [keys.WF_fbBusinessManagerId]: "Meta ID do Gerenciador de Negócios",
  [keys.WF_FbIdTip_error]: "Meta ID errado",
  [keys.WF_FbIdTip_header]: "Encontre seu Meta ID",
  [keys.WF_FBIDTip_text1]:
    "Seu Meta ID corresponde ao seu Gerenciador de Negócios. Você pode precisar de sua Meta ID ao compartilhar o acesso a informações como faturas mensais ou um pixel do Meta.",
  [keys.WF_FBIDTip_text2]: "Passos para encontrar sua identificação do Meta:",
  [keys.WF_FBIDTip_step1]: "Acesse as configurações do negócio.",
  [keys.WF_FBIDTip_step2]: "Clique em Informações da Empresa",
  [keys.WF_FBIDTip_step3]:
    "Sob Informações do Gerenciador de Negócios, você verá o número de identificação",
  [keys.WF_messaging]: "Mensagens",
  [keys.WF_whatsAppPhoneNumber]: "Número de telefone do WhatsApp",
  [keys.WF_whatsAppPhoneNumberTooltip1]:
    "Número a ser usado para a conta WhatsApp Business API.",
  [keys.WF_whatsAppPhoneNumberTooltip2]:
    "Por favor, não inclua seu número de telefone pessoal.",
  [keys.WF_timezone]: "Fuso horário",
  [keys.WF_attention]: "Atenção",
  [keys.WF_govAgencyText1]:
    "Se você for uma Agência Governamental, não deve enviar uma conta usando este formulário. Primeiro, você solicita a pré-aprovação do Meta para seu caso de uso. Se você não tiver aprovação da equipe do Meta para criar esta conta ou não souber se você é um caso governamental, leia nossa <LINK>https://docs.360dialog.com/waba-for-government-agencies | documentação e preencha o formulário<LINK>. Faremos o nosso melhor para ajudá-lo.",
  [keys.PI_perMonth]: "/ Mês",
  [keys.PI_text1]:
    "Os custos de conversação são cobrados de acordo com o preço do Meta/WhatsApp EUR conforme indicado em <LINK>https://developers.facebook.com/docs/whatsapp/pricing | Regras de preços do Meta<LINK> e podem estar sujeitas a pré-pagamento.",
  [keys.PI_text2]: "e pode estar sujeito ao pagamento antecipado.",
  [keys.PI_textUSD]:
    "Os custos de conversação são cobrados de acordo com o preço do Meta/WhatsApp USD conforme indicado em <LINK>https://developers.facebook.com/docs/whatsapp/pricing | Regras de preços do Meta<LINK> e podem estar sujeitas a pré-pagamento.",
  [keys.PI_billedMonthly]: "Faturado mensalmente",
  [keys.PI_completeOrderButtonLabel]: "Completar pedido",
  //  [keys.WF_termsIntro]: "translation_missing",
  //  [keys.WF_terms1]: "translation_missing",
  //  [keys.WF_terms1p1]: "translation_missing",
  //  [keys.WF_terms1p2]: "translation_missing",
  //  [keys.WF_terms2]: "translation_missing",
  //  [keys.WF_terms2p1p1]: "translation_missing",
  //  [keys.WF_terms2p1and]: "translation_missing",
  //  [keys.WF_terms2p1p2]: "translation_missing",
  //  [keys.WF_terms2p2]: "translation_missing",
  //  [keys.WF_terms2p3]: "translation_missing",
  //  [keys.WF_terms2p4]: "translation_missing",
  //  [keys.WF_terms3]: "translation_missing",
  //  [keys.WF_terms3p1]: "translation_missing",
  //  [keys.WF_terms3p2]: "translation_missing",
  //  [keys.WF_terms4]: "translation_missing",
  //  [keys.WF_terms4p1]: "translation_missing",
  //  [keys.WF_terms4p2]: "translation_missing",
  //  [keys.WF_terms4p3]: "translation_missing",
  //  [keys.WF_terms5]: "translation_missing",
  //  [keys.WF_terms5p1]: "translation_missing",
  //  [keys.WF_terms5p2]: "translation_missing",
  //  [keys.WF_terms5p3]: "translation_missing",
  //  [keys.WF_terms5p4]: "translation_missing",
  //  [keys.WF_terms5p5]: "translation_missing",
  //  [keys.WF_terms5p6]: "translation_missing",
  //  [keys.WF_terms5p7]: "translation_missing",
  //  [keys.WF_terms5p8]: "translation_missing",
  //  [keys.WF_termsLabel]: "translation_missing",
  //  [keys.WF_termsCheckbox1]: "translation_missing",
  //  [keys.WF_termsCheckbox1Detail]: "translation_missing",
  //  [keys.WF_termsCheckbox2]: "translation_missing",
  //  [keys.WF_termsCheckbox3]: "translation_missing",
  //  [keys.WF_verifiedBsp]: "translation_missing",
  //  [keys.WF_buttonLabelAccept]: "translation_missing",
  [keys.WF_buttonLabelSave]: "Salvar",
  [keys.WF_paymentInfo]: "Pagamento",
  [keys.WF_submission]: "Submissão em progresso...",
  [keys.WF_thanksTitle]: "Obrigado!",
  [keys.WF_thanksNext]:
    "Você receberá um e-mail com instruções sobre como proceder com o processo de verificação.",
  [keys.WF_thanksMob1]:
    "Enquanto isso, você pode ir ao seu Gerenciador de Negócios do Meta para aceitar nossa",
  [keys.WF_thanksMobQuotes]: "messaging on behalf",
  [keys.WF_thanksMob2]: "solicitação",
  [keys.WF_thanksButtonLabel]: "solicitações em aberto",
  [keys.WF_0800error]:
    "Você está tentando registrar um número que começa com 0800. Normalmente, estes números não podem receber ligações internacionais, o que é necessário para o processo de registro. Se este for o caso, por favor, forneça um número diferente.",
  [keys.WF_paymentCardOwnerName]: "Nome no cartão de crédito",
  [keys.WF_paymentCreditCard]: "cartão de crédito",
  [keys.WF_paymentCardOwnerNamePlaceholder]:
    "Se o nome for diferente do nome do requerente",
  [keys.WF_hubAccess]: "Acesso ao 360dialog Client Hub",
  [keys.WF_hubAccessHelp]:
    "Após o envio, você poderá acessar o 360dialog Client Hub, onde você poderá gerenciar sua conta de WhatsApp Business",
  [keys.WF_hubAccessHelpPassword]:
    "Por favor, selecione uma senha, que você usará para entrar no 360dialog Client Hub.",
  [keys.WF_passwordLabel]: "Selecione uma senha",
  [keys.WF_repeatPasswordLabel]: "Repita a senha",
  [keys.WF_passwordError1]: "A senha deve ter pelo menos 8 caracteres.",
  [keys.WF_passwordError2]:
    "A senha deve conter pelo menos uma letra minúscula (a-z).",
  [keys.WF_passwordError3]:
    "A senha deve conter pelo menos uma letra maiúscula (A-Z).",
  [keys.WF_passwordError4]:
    "A senha deve conter pelo menos um dígito (por exemplo, 0-9) ou um caracter especial (por exemplo, !#$%&?).",
  [keys.WF_passwordRepeatError]: "As senhas não correspondem.",
  [keys.WF_cardError]: "Favor fornecer um cartão de crédito válido",
  [keys.WF_passwordErrorZxcvbn]:
    "Adicione outra palavra ou duas. Palavras incomuns são melhores.",
  [keys.CON_title]: "Conecte sua conta do Meta Business",
  [keys.CON_textIntro]:
    "Para continuar, você precisará acessar a conta comercial de sua empresa no Meta. Ao clicar no botão 'Continuar com Meta' abaixo, você poderá:",
  [keys.CON_bullet1]:
    "Criar contas comerciais novas ou selecionar as já existentes no Meta e WhatsApp Business.",
  [keys.CON_bullet2]:
    "Forneça um display name e descrição para seu perfil de WhatsApp Business",
  [keys.CON_bullet3]:
    "Conecte e verifique seu número de telefone a ser utilizado para o acesso à API WhatsApp.",
  [keys.CON_numberHelpText]:
    "Não utilize um número que esteja atualmente conectado a uma conta WhatsApp existente. Isto inclui números que estão conectados à aplicação WhatsApp Business App. Você não conseguirá concluir o processo de registro.",
  [keys.CON_numberHelpLink]:
    "Saiba mais sobre o seu número de telefone do WhatsApp",
  [keys.CON_buttonLabel]: "Continuar com o Meta",
  [keys.CAN_title]: "Como podemos ajudar,",
  [keys.CAN_textIntro]:
    "Parece que você não terminou o widget de onboarding do Meta. Mas não se preocupe: você pode reiniciar a qualquer momento e tentar novamente. Estamos aqui para te ajudar durante o processo.",
  [keys.CAN_faq]: "Perguntas mais frequentes",
  [keys.CAN_buttonHelp]:
    "Pronto para tentar novamente? Clique aqui para reiniciar o widget de onboarding do Meta.",
  [keys.WF_partnerIntro]: "Você está se cadastrando como cliente da <PARTNER>",
  [keys.WF_userAlreadyExists]:
    "Já existe um usuário com esse e-mail. Para continuar seu onboarding ou para adicionar um novo número à sua conta de WhatsApp Business API, faça o login no 360dialog Client Hub.",
  [keys.CAN_livesupportTitle]: "Iniciar suporte individual",
  [keys.CAN_livesupportText]:
    "Clique no botão da direita para agendar um horário com um de nossos agentes, que vão te acompanhar durante o processo de onboarding.",
  [keys.CAN_livesupportButtonLabel]: "Agende um horário",
  [keys.CAN_livesupportButtonLabelSuccess]: "Horário agendado",
  [keys.PI_selectedLabel]: "selecionado",
  [keys.SH_copied]: "Copiado",
  [keys.SH_copyPageLink]: "Copiar o link da página",
  [keys.SH_pageLink]: "Link da página",
  [keys.PI_pricingPlanLinkLabel]:
    "Clique aqui para saber mais sobre nossos pacotes de preços.",
  [keys.WF_alreadyHaveAccount]: "Já tem uma conta?",
  [keys.WF_login]: "Log in",
  [keys.WF_mobileHeading]: "Desculpe",
  [keys.WF_notSupportedMobile]:
    "Infelizmente, o processo de Embedded Signup não está disponível para dispositivos móveis no momento. Por favor, tente novamente utilizando um computador.",
  [keys.WF_saveURL]: "Salvar URL atual",
  [keys.WF_continueAnyway]: "Continuar para signup clássico",
  [keys.WF_sharedSuccessfully]: "URL foi compartilhada com sucesso",
  [keys.WF_copiedUrl]: "URL copiada para a área de transferência",
  [keys.CC_consentText]:
    "Este website usa cookies para melhorar a experiência do usuário.",
  [keys.CC_acceptButtonLabel]: "Aceitar",
  [keys.CC_declineButtonLabel]: "Recusar",
  [keys.CC_title]: "Nosso website utiliza cookies",
  [keys.CC_text]:
    "Gostaríamos de usar cookies próprios e de terceiros e tecnologias similares para melhorar a experiência do usuário e objetivos de marketing. Você pode definir suas preferências selecionando as opções abaixo.",
  [keys.CC_cookies1text]:
    "<cookies1label> ajuda com a funcionalidade básica do nosso website, p. ex. lembra se você aceitou os cookies.",
  [keys.CC_cookies1label]: "Cookies necessários",
  [keys.CC_cookies2text]:
    "<cookies2label> torna possível recolher estatísticas sobre como usar o nosso website, para podemos melhorá-lo.",
  [keys.CC_cookies2label]: "Cookies analíticos",
  [keys.CC_cookies3text]:
    "<cookies3label> torna possível mostrar a você conteúdos e anúncios mais relevantes no nosso website e em outras plataformas.",
  [keys.CC_cookies3label]: "Cookies de marketing",
  [keys.CC_buttonLabelAcceptSelected]: "Aceitar os selecionados",
  [keys.CC_buttonLabelAcceptNecessary]: "Aceitar os necessários",
  [keys.CC_buttonLabelAcceptAll]: "Aceitar todos",
  [keys.WF_currentBussinessSolutionsProvider]:
    "Business Solution Provider atual",
  [keys.MIG_fbErrorWaitNewCode]:
    "Você precisa esperar para reenviar o código. Por favor aguarde <HOUR> e <MINUTES> antes de tentar novamente.",
  [keys.CH_portExistingNumber]:
    "Migrar número existente em outro BSP para a 360dialog",
  [keys.CH_portWhatsAppProfile]:
    "Migrar um perfil WhatsApp Business já existente de qualquer provedor para a 360dialog.",
  [keys.CH_addNewNumber]: "Adicionar um novo número de telefone",
  [keys.CH_setUpWhatsAppProfile]:
    "Configurar sua conta e perfil WhatsApp Business com um número que ainda não está em uso.",
  [keys.CH_welcomeTo360dialog]: "Bem-vindo à 360dialog!",
  [keys.CH_pleaseSelect]:
    "Por favor, selecione se você quer migrar um número existente para a 360dialog ou se você quer enviar um novo número para a sua conta WhatsApp Business.",
  [keys.CH_continueWithAccount]: "Continuar com a criação da conta",
  [keys.CAN_createTicket]: "Criar um ticket de suporte",
  [keys.CAN_ticketCreated]: "Ticket criado",
  [keys.CON_contactSupportHelptext]:
    "Com problemas para completar a criação da conta?",
  [keys.WF_goToLogin]: "Ir para login",
  [keys.WF_planSelection]: "Seleção de plano",
  [keys.WF_monthly]: "Mensal",
  [keys.WF_yearly]: "Anual",
  [keys.WF_month]: "Mês",
  [keys.WF_year]: "Ano",
  [keys.WF_billedMonthly]: "Cobrança mensal",
  [keys.WF_months]: "meses",
  [keys.WF_billedAt]: "Faturado em",
  [keys.WF_selected]: "Selecionado",
  [keys.WF_select]: "Selecionar",
  [keys.WF_basic]: "Básico",
  [keys.WF_professional]: "Profissional",
  [keys.WF_forDevelopers]: "Favorito para desenvolvedores",
  [keys.WF_scalablePlan]: "Nosso plano mais escalável",
  [keys.WF_buttonLabelSubmit]: "Enviar dados",
  [keys.WF_buttonLabelCancel]: "Voltar",
  [keys.WF_reviewData]: "Revisar seus dados",
  [keys.WF_passwordError5]: "A senha não deve conter informações do e-mail",
  [keys.CC_openCookiePreferences]: "Abrir preferências de Cookies",
  [keys.CC_acceptCardLabel]:
    'Para aceder ao chat de suporte, aceite os "Cookies Analíticos"',
  [keys.WF_freemailError]:
    'Não é possível criar uma conta com o email "<DOMAIN>".  Por favor, utilize uma conta de email empresarial.',
  [keys.WF_termsIntro]:
    'Ao colocar esta "encomenda", o Cliente solicita a utilização dos serviços WhatsApp Business Solution (como especificado mais adiante, a seguir os "Serviços") fornecidos pela 360dialog GmbH, Torstr. 61, 10119 Berlin, Alemanha agindo como "Solution Provider" autorizado (doravante "360dialog" ou "BSP"). Os "Serviços" significam todos os serviços prestados pela 360dialog em ligação com a criação, a integração técnica e a administração contínua das contas empresariais WhatsApp ("WABA(s)". Ao fazer este pedido, o Cliente autoriza a 360dialog a solicitar ao(s) Cliente(s) WABA(s) da WhatsApp (conforme definido abaixo) e a administrar o(s) WABA(s) em nome do Cliente. Isto inclui a transmissão de informação entre a WhatsApp e o Cliente para efeitos do presente Acordo.',
  [keys.WF_terms1]: "WABA MSISDNs",
  [keys.WF_terms1p1]:
    "O cliente solicita WABAs para o seguinte MSISDN (com o indicativo do país no seguinte formato +49)",
  [keys.WF_terms1p2]:
    'A aprovação de cada WABA fica ao critério da WhatsApp. Neste documento, "WhatsApp" significa a WhatsApp Inc. e/ou qualquer uma das suas partes afiliadas dentro do grupo Meta que fornece quaisquer serviços que façam parte da WhatsApp Business Solution.',
  [keys.WF_terms2]: "Conformidade com os termos da WhatsApp",
  [keys.WF_terms2p1p1]:
    "O cliente garante e representa a utilização das WABAs estritamente de acordo com os termos aplicáveis da WhatsApp Business Solution Terms https://www.whatsapp.com/legal/business-solution-terms, os Termos de Cliente do Meta para a WhatsApp, anexados como Anexo 2, bem como todos os outros termos, directrizes e políticas aplicáveis da WhatsApp, o mais importante",
  [keys.WF_terms2p1and]: "e",
  [keys.WF_terms2p1p2]: 'no seu conjunto, os "Termos da WhatsApp"',
  [keys.WF_terms2p2]:
    "O cliente reconhece e concorda que a WhatsApp poderá modificar os Termos WhatsApp e/ou divulgar termos, directrizes e políticas adicionais, e, após a sua divulgação, tais termos, directrizes e políticas adicionais deverão fazer parte dos Termos WhatsApp.",
  [keys.WF_terms2p3]:
    "O cliente indemniza o 360dialog de quaisquer danos, custos e causas de danos por uma violação dos termos da WhatsApp.",
  [keys.WF_terms2p4]:
    "WABA(s) são apenas para utilização pelo Cliente. O Cliente representa e garante que o Cliente não distribuirá, venderá, revenderá, alugará ou de outra forma concederá acesso à(s) WABA(s) a terceiros para quaisquer fins fora do âmbito da presente Ordem.",
  [keys.WF_terms3]: "Utilização dos Serviços",
  [keys.WF_terms3p1]:
    'Os Serviços (tal como definidos acima) são fornecidos pelo 360dialog ao Cliente. A utilização dos Serviços está sujeita aos Termos de Utilização do 360diálogo anexados como Anexo 1. Esta Ordem e os Termos do 360diálogo serão referidos como o "Acordo".',
  [keys.WF_terms3p2]:
    "De acordo com os termos da WhatsApp, os clientes têm o direito de migrar a(s) sua(s) WABA(s) para um fornecedor de soluções empresariais WABA diferente. Quaisquer esforços exigidos pelo 360dialog em relação a tal pedido estão sujeitos à lista de preços do 360dialog.",
  [keys.WF_terms4]: "Diversas",
  [keys.WF_terms4p1]:
    "O presente Acordo será regido e interpretado em conformidade com as leis da República Federal da Alemanha, estando excluídas as regras da Convenção das Nações Unidas sobre Contratos de Venda Internacional de Mercadorias e de Conflitos de Leis.",
  [keys.WF_terms4p2]:
    "O lugar de atuação e local é Berlim, Alemanha. No entanto, o 360dialog reserva-se o direito a qualquer outro local legalmente admissível.",
  [keys.WF_terms4p3]:
    "Se qualquer disposição do presente Acordo for considerada por um tribunal de jurisdição competente como sendo contrária às leis aplicáveis, então as restantes disposições do presente Acordo, se capazes de um desempenho substancial, permanecerão em pleno vigor e efeito. A cláusula afectada será substituída por uma que reflicta quase exclusivamente a intenção original das partes.",
  [keys.WF_terms5]: "Termos e Preços de 360dialog",
  [keys.WF_terms5p1]:
    "A taxa de licença mensal para hospedagem do WhatsApp Business API é de 50,00USD/Número/Mês.",
  [keys.WF_terms5p2]:
    "Os termos da taxa mensal do Licenciado são mensais e podem ser rescindidos com 30 dias de aviso prévio.",
  [keys.WF_terms5p3]:
    "As taxas mensais de licença para a hospedagem do WhatsApp Business API serão facturadas com base no mês civil.",
  [keys.WF_terms5p4]:
    "360dialog fatura o cliente apenas o WhatsApp Business API após a ativação da stack de hospedagem do cliente.",
  [keys.WF_terms5p5]:
    "Se uma hospedagem se iniciar num dia diferente do dia 1 de um mês civil, será cobrada proporcionalmente, dependendo dos dias em que a hospedagem foi ativada.",
  [keys.WF_terms5p6]:
    "Todas las tarifas de licencia se facturan al comienzo del mes correspondiente.",
  [keys.WF_terms5p7]:
    "O preço das mensagens modelo WhatsApp Os custos das mensagens modelo não estão incluídos na taxa de licença mensal e são cobrados a partir do preço do Meta/ Whatsapp em EUR, tal como indicado em https://developers.facebook.com/docs/whatsapp/pricing/",
  [keys.WF_terms5p8]: "Todas as taxas são exclusivas de IVA, se aplicável.",
  [keys.WF_termsLabel]: "Termos",
  [keys.WF_termsCheckbox1]:
    "Concordo com os Termos de Utilização da 360dialog - Anexo 1.",
  [keys.WF_termsCheckbox1Detail]: "Modificado pela última vez em:",
  [keys.WF_termsCheckbox2]:
    "Reconheci e concordo com o Anexo 2 - Termos de Cliente do Meta para WhatsApp.",
  [keys.WF_termsCheckbox3]: "Concordo do o Anexo 3 - preçário 360dialog ",
  [keys.WF_verifiedBsp]:
    "O 360dialog é um Provedor de Soluções WhatsApp verificado e foi concedido pelo Meta/WhatsApp o direito de conceder aos seus clientes acesso ao WhatsApp Business API.",
  [keys.WF_buttonLabelAccept]: "Aceitar termos",
  [keys.CON_sessionExp]:
    'A sua sessão com o Meta expirou. Por favor, repita o processo clicando no botão "Continuar com o Meta".',
  [keys.VER_checkEmailTitle]: "Verifique seu email",
  [keys.VER_submit]: "Enviar",
  [keys.VER_bullet1]:
    "Para continuar, primeiro você precisa verificar seu email.",
  [keys.VER_bullet2]: "Uma senha de uso único foi enviada para <EMAIL>",
  [keys.VER_bullet3]:
    "Por favor digite a senha abaixo para verificar seu email",
  [keys.VER_inputLabel]: "Senha de uso único",
  [keys.VER_inputPlaceholder]: "Insira a senha aqui",
  [keys.VER_inputError]: "O código enviado não está correto.",
  [keys.VER_closeWindowWarning]:
    "Por favor certifique-se de não fechar essa janela. Seus dados não serão salvos até que você envie o código de verificação.",
  [keys.VER_newCodeSentToEmail]: "Um novo código foi enviado para o seu e-mail",
  [keys.AI_almostDone]: "Você está quase acabando, <USERNAME>",
  [keys.AI_addInfo]: "Adicione informações adicionais da empresa.",
  [keys.AI_completeInfo]: "Informações completas",
  [keys.AI_companyInfo]:
    "Para prosseguir, precisamos de algumas informações sobre a sua empresa",
  [keys.AI_companyNameLabel]: "Nome legal da empresa",
  [keys.AI_companyNamePlaceholder]: "Digite o nome da empresa legal",
  [keys.AI_companyAddressLabel]: "Endereço da empresa",
  [keys.AI_companyAddressPlaceholder]: "Digite o endereço da empresa",
  [keys.AI_selectCountry]: "Selecione o país",
  [keys.AI_vatIdLabel]: "VAT ID",
  [keys.AI_vatIdPlaceholder]: "Digite o ID do VAT",
  [keys.AI_companyVertical]: "vertical da empresa",
  [keys.FS_accessCompanyFBM]:
    "Para continuar, você precisará <strong>acessar o Gerente de Negócios do Meta de sua empresa </strong> e fazer o seguinte:",
  [keys.FS_connectNumber]:
    "Conecte e verifique seu número de telefone para usar o WhatsApp Business API.",
  [keys.FS_addInfoToBusinessManager]:
    "Para ter uma conta ativa, você deve adicionar suas informações na seção de Informações Comerciais das Configurações do Gerente de Negócios.",
  [keys.FS_fbCommercePolicy]:
    "Esta informação é usada pelo Meta para verificar a conformidade com a Política Comercial. Se não estiver corretamente configurada, a conta será imediatamente bloqueada.",
  [keys.FS_embeddedSignupInfo]:
    "<LINK>https://docs.360dialog.com/docs/embedded-signup | Mais informações<LINK>",
  [keys.WF_fullName]: "Digite seu nome completo",
  [keys.WF_noAccount]: "Sem conta ainda?",
  [keys.WF_signUpHere]: "Cadastre-se aqui",
  [keys.WF_loginHere]: "Faça aqui seu login",
  [keys.WF_passwordError6]: "A senha não deve conter informações do usuario",
  [keys.WF_comapnyNameTooltip]:
    "Por favor, digite o nome legal da sua empresa. Seu nome de perfil WhatsApp pode ser escolhido mais tarde e pode ser diferente se as condições se aplicarem.",
  [keys.WF_vatIdTooltip]:
    "É necessária a identificação VAT apenas para empresas sediadas na UE. Por favor, forneça o número completo do VAT com o prefixo. Exemplo - ES25084476",
  [keys.WF_vatIdNotValid]: "O ID do IVA não é válido",
  [keys.WF_noVatId]: "Minha empresa não tem uma identificação de IVA",
  [keys.WF_noVATMessage]:
    "Se sua empresa não tiver um número de identificação fiscal ou este não for válido, nós cobraremos o imposto alemão sobre vendas.",
  [keys.WF_vatInfoUpdated]: "Informações atualizadas sobre a empresa e o IVA",
  [keys.WF_governmentalCheck]: "Verificação governamental",
  [keys.WF_notGovernmental]: "Não sou uma agencia governamental",
  [keys.WF_facebookBusinessSetup]: "Configuração de negocios do Meta",
  //  [keys.WF_ukraineExcludedAreasNote]: "translation_missing",
  //  [keys.FS_comeBackFromMeta]: "translation_missing",
  //  [keys.FS_completeMataOnboarding]: "translation_missing",
  //  [keys.FS_completeMataOnboardingInfo]: "translation_missing",
  //  [keys.CC_openCookiePreferences]: "translation_missing",
  //  [keys.CC_disabledCookiePreferences]: "translation_missing",
  // [keys.ON_paymentDebitNotice]: "translation_missing",
};

export default translate;
