import { MPInstancesHub } from "@/services/api/RequestAxios/RequestAxiosHub";
import { IQueryParams, packQueryParams } from "../utils";
import { toJS } from "mobx";
import * as Types from "./waba.types";
import { unpackWabas } from "./waba.mapper";
import {
  UIModelIntegration,
  UIModelIntegrations,
} from "../channels/channels.model";
import qs from "qs";

const wabaUrl = () => `/partners/waba_setup`;
const wabaTriggerRegistrationUrl = (appId: string) =>
  `/partners/waba_trigger_registration/${appId}`;
const wabaFinishRegistrationUrl = (appId: string) =>
  `/partners/waba_finish_registration/${appId}`;

export const getWabas = async (
  queryParams?: IQueryParams
): Promise<UIModelIntegrations> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseWabas>(
      wabaUrl(),
      {
        params: queryParams && packQueryParams(queryParams),
        paramsSerializer: (params) => {
          const { filters, ...otherParams } = params;
          const serializedQueryString = qs.stringify(otherParams, {
            arrayFormat: "repeat",
          });

          return `${serializedQueryString}&filters=${JSON.stringify(
            toJS(filters)
          )}`;
        },
      }
    );

  return new UIModelIntegrations(unpackWabas(data));
};

export const setupWaba = async (payload: Types.IServerResponseSingleWaba) => {
  await MPInstancesHub.buffedInstances.newCrm.post(wabaUrl(), payload);
};

export interface IwabaTriggerRegistrationParams {
  verification_method?: string;
  certificate?: string;
  setup_flow?: string;
}
export const wabaTriggerRegistration = async (
  waba: UIModelIntegration,
  params: IwabaTriggerRegistrationParams
) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    wabaTriggerRegistrationUrl(waba.appId),
    {
      ...(params.verification_method
        ? { verification_method: params.verification_method }
        : {}),
      ...(params.certificate ? { certificate: params.certificate } : {}),
    }
  );
  return data;
};

export const wabaTriggerRegistrationWithAppId = async (
  appId: string,
  params: IwabaTriggerRegistrationParams
) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    wabaTriggerRegistrationUrl(appId),
    {
      ...(params.verification_method
        ? { verification_method: params.verification_method }
        : {}),
      ...(params.certificate ? { certificate: params.certificate } : {}),
      ...(params.setup_flow ? { setup_flow: params.setup_flow } : {}),
    }
  );
  return data;
};

export interface IwabaFinishRegistrationParams {
  verification_code: string;
}
export const wabaFinishRegistration = async (
  waba: UIModelIntegration,
  params: IwabaFinishRegistrationParams
) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    wabaFinishRegistrationUrl(waba.appId),
    params
  );
  return data;
};
