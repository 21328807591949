// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.NOT_allNotifications]: "Все",
  [keys.NOT_archivedNotifications]: "Архив",
  [keys.NOT_closeButtonTooltip]: "Поместить это уведомление в архив",
  [keys.NOT_archiveAllBtn]: "Поместить все в архив",
  [keys.NOT_noNewNotifications]:
    "Это все ваши уведомления за последние 30 дней.",
  //  [keys.NOT_clientAdded]: "translation_missing",
  //  [keys.NOT_clientAddedWithMigrationDate]: "translation_missing",
  [keys.NOT_rejectionReason]: "Причина отказа:",
  [keys.NOT_alreadyApproved]: "Вы уже утвердили этот запрос на изменение",
  //  [keys.NOT_apiKeyRecommendation]: "translation_missing",
  [keys.NOT_generateNewAPIKey]: "Сгенерировать новый API ключ",
  [keys.NOT_partnerRequestFailed]: "Ошибка: Процесс не может быть завершен",
  [keys.NOT_partnerRequestCancelled]: "Клиент отменил запрос",
  [keys.NOT_needCaptcha]: "Пожалуйста, подтвердите, что вы не робот",
  [keys.NOT_errorUpdatingChatSupport]:
    "Возникла проблема с обновлением статуса поддержки чата. Пожалуйста, попробуйте позднее.",
  // [keys.NOT_allNumbersAffected]: "translation_missing",
};

export default translate;
