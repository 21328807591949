// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.NUM_manageAccount]: "Administrar cuenta empresarial de WhatsApp",
  [keys.NUM_details]: "Detalles",
  [keys.NUM_accountName]: "Nombre de la cuenta",
  [keys.NUM_dataStorageRegions]: "Regiones de almacenamiento de datos",
  [keys.NUM_cloudApiNumbers]: "Disponible solo para números de API en la nube",
  [keys.NUM_qualityRating]: "Calificación de calidad",
  [keys.NUM_low]: "Bajo",
  [keys.NUM_medium]: "Medio",
  [keys.NUM_high]: "Alto",
  [keys.NUM_messageLimit]: "Límite de mensajes",
  [keys.NUM_newConversationsPerDay]: "nuevas conversaciones por día",
  [keys.NUM_unlimited]: "Ilimitado",
};

export default translate;
