// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.DASH_welcome]: "Bem-vindo",
  [keys.DASH_welcomeBack]: "Bem-vindo de volta",
  [keys.DASH_mainTemplateManagerInfo]:
    "Você já registrou alguma mensagem template para seu número? Dirija-se ao gerenciador de templates para criar e organizar seus templates.",
  [keys.DASH_learnMore]: "Mais informações",
  [keys.DASH_goToTemplates]: "Ir para o Gerenciador de Templates",
  [keys.DASH_nextSteps]: "Próximos passos",
  [keys.DASH_firstSteps]: "Primeiros passos",
  [keys.DASH_yourWabasList]: "Suas contas de WhatsApp",
  [keys.DASH_resources]: "Recursos",
  [keys.DASH_faq]: "FAQ",
  [keys.DASH_mainVerified]:
    "Seu novo número <NUMBER> está quase pronto para uso. Você já verificou seu negócio?",
  [keys.DASH_mainGoToFbmButton]: "Ir para Verificação de Negócios do Meta",
  [keys.DASH_mainDocsLearnMore]:
    "Saiba mais sobre as funcionalidades do 360dialog Client Hub.",
  [keys.DASH_showDocsButton]: "Exibir documentação",
  [keys.DASH_popupIntro]:
    "Seu novo número <NUMBER> está quase pronto para ser usado. Aqui estão os passos que você precisa realizar agora:",
  [keys.DASH_popupBullet1]:
    "Crie uma API key para usar dentro da aplicação do seu parceiro de integração.",
  [keys.DASH_popupBullet2]:
    "Se seu negócio ainda não for verificado pelo Meta, você precisa iniciar o processo de verificação de negócios dentro do Gerenciador de Negócios do Meta.",
  [keys.DASH_generateKeyButton]: "Criar API key",
  [keys.DASH_fetchingNumberPopup]:
    "Estamos atualmente preparando o seu número. Isto pode levar alguns momentos. Por favor, aguarde.",
  [keys.DASH_settingUpPopup]:
    "Iniciando a hospedagem do seu WhatsApp Business API Client. Por favor, aguarde.",
  [keys.DASH_numbersSectionEmpty]: "Você não tem nenhum número registrado.",
  [keys.DASH_integrationPartnerInfo]: "Seu parceiro de integração é <PARTNER>.",
  [keys.DASH_lastStepApi]:
    "Muito bem! Você acabou de criar sua API key para seu novo número <NUMBER>. Agora vá até o Gerenciador de Negócios do Meta para começar com o processo de verificação de negócio.",
  [keys.DASH_lastStepForwarding]:
    "Muito bem! Você acabou de habilitar o encaminhamento de mensagens para seu número <NUMBER>. Agora vá até o Gerenciador de Negócios do Meta para começar com o processo de verificação de negócio.",
  [keys.DASH_lastStepTitle]: "Verificação de Negócios do Meta",
  [keys.DASH_lastStepButtonLabel]: "Ir para o Gerenciador de Negócios do Meta",
  [keys.DASH_lastStepButtonLabelClose]: "Eu já verifiquei meu negócio",
  [keys.DASH_lastStepVerifiedTitle]: "Tudo configurado...",
  [keys.DASH_lastStepVerifiedText]:
    "... e pronto para começar! Com a sua API key para o número <NUMBER> você pode começar a usar a WhatsApp Business API agora mesmo. Como o seu negócio já é verificado pelo Meta, você não tem mais nenhuma ação pendente.",
  [keys.DASH_timeoutErrorText]:
    "Infelizmente, não conseguimos configurar o seu número. Parece que você não finalizou o processo de onboarding. Clique abaixo para finalizar a configuração da sua conta WhatsApp Business.",
  [keys.DASH_popupBulletBypassBeta]:
    "Certifique-se de preencher suas Informações Comerciais no Meta Business Manager para acesso total ao API.",
  [keys.DASH_submissionSuccessHelptext]: "Enviado com sucesso!",
  [keys.DASH_serverDelayMessage]:
    "Desculpe, mas parece que nossos servidores estão recebendo muitos pedidos neste momento. Seu número estará pronto dentro de alguns minutos. Iremos notificá-lo via e-mail assim que você puder continuar com a configuração.",
  [keys.DASH_buttonLabelNewButton]: "Criar um novo usuário",
  [keys.DASH_popupBullet3]:
    "Crie uma chave API para usar dentro de sua própria aplicação ou configure sua caixa de entrada com get.chat",
  [keys.DASH_popupBullet5]:
    "Sua conta WhatsApp API com 360dialog agora inclui uma integração gratuita da caixa de entrada com nosso parceiro get.chat.inget.chat é um software que lhe permite conectar sua conta WhatsApp API a vários usuários e vários aplicativos (CRM, catálogo de endereços, sistema de emissão de bilhetes) simultaneamente.\nAttenção Se você optar por ativar a caixa de entrada, você não poderá criar uma chave API para integrar a sua própria aplicação.",
  [keys.DASH_createBtnInbox]:
    "Criar minha caixa de entrada get.chat para <NUMBER>",
  [keys.DASH_generateKeyButtonInstead]: "Gerar chave API ao invés de",
  [keys.DASH_chooseDifferentName]: "Escolha um Display Name diferente",
  [keys.DASH_displayNamePendingMessage]:
    "O Whatsapp está demorando um pouco mais do que o habitual para aprovar o display name escolhido. Isto pode demorar um tempo, por isso iremos notificar do estado deste procedimento via e-mail.",
  [keys.DASH_displayNameRejectedMessage]:
    "O display name que você escolheu foi rejeitado pelo WhatsApp <br/> Você pode rever as diretrizes oficiais de display name <LINK> <br/> Por favor, escolha um display name diferente",
  [keys.DASH_displayNameRejectedMessagePipe]:
    "O display name que você escolheu foi rejeitado pelo WhatsApp. Você pode rever as diretrizes oficiais de display name  <LINK>https://www.facebook.com/business/help/338047025165344 | aqui <LINK>.",
  [keys.DASH_integrationSetup]: "Configuração de integração",
  [keys.DASH_migrateNumberFromAnotherBSP]:
    "Migrar número de outro provedor oficial do WhatsApp (BSP)",
  [keys.DASH_useClassicSignup]: "Usar signup clássico",
  [keys.DASH_addNewPhoneNumber]: "Adicionar novo número de telefone",
  [keys.DASH_importFromWBA]: "Importar da WhatsApp Business App",
  [keys.DASH_importFromBS]: "Importar de outros BSP",
  [keys.DASH_stackSetup]: "Configurando seu número",
  //  [keys.DASH_test]: "translation_missing",
  //  [keys.DASH_testWebhookSuccess]: "translation_missing",
  //  [keys.DASH_testWebhookError]: "translation_missing",
  //  [keys.DASH_integrationSettings]: "translation_missing",
  //  [keys.DASH_partnerWebhookUrl]: "translation_missing",
  //  [keys.DASH_webhookUrlTootip]: "translation_missing",
  //  [keys.DASH_redirectUrl]: "translation_missing",
  //  [keys.DASH_redirectUrlToolip]: "translation_missing",
  //  [keys.DASH_defaultHosting]: "translation_missing",
  //  [keys.DASH_cloudApiHosted]: "translation_missing",
  //  [keys.DASH_onPremiseHosted]: "translation_missing",
  //  [keys.DASH_infoOnHostingPlatform]: "translation_missing",
  //  [keys.DASH_partnerUpdatedSuccess]: "translation_missing",
  //  [keys.DASH_redirectUrlNorValid]: "translation_missing",
  //  [keys.DASH_paymentPlan]: "translation_missing",
  //  [keys.DASH_vsPreviousWeek]: "translation_missing",
  //  [keys.DASH_conversations]: "translation_missing",
  //  [keys.DASH_expenses]: "translation_missing",
  //  [keys.DASH_lastWeekActivity]: "translation_missing",
  //  [keys.DASH_channel]: "translation_missing",
};

export default translate;
