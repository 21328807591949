// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  WF_title: "WF_title",
  WF_intro: "WF_intro",
  WF_compliancePolicyLinkLabel: "WF_compliancePolicyLinkLabel",
  WF_personalInformation: "WF_personalInformation",
  C_email: "C_email",
  WF_emailTooltip: "WF_emailTooltip",
  C_fullName: "C_fullName",
  WF_businessDetails: "WF_businessDetails",
  WF_legalCompanyName: "WF_legalCompanyName",
  WF_fbBusinessManagerId: "WF_fbBusinessManagerId",
  WF_FbIdTip_error: "WF_FbIdTip_error",
  WF_FbIdTip_header: "WF_FbIdTip_header",
  WF_FBIDTip_text1: "WF_FBIDTip_text1",
  WF_FBIDTip_text2: "WF_FBIDTip_text2",
  WF_FBIDTip_step1: "WF_FBIDTip_step1",
  WF_FBIDTip_step2: "WF_FBIDTip_step2",
  WF_FBIDTip_step3: "WF_FBIDTip_step3",
  WF_messaging: "WF_messaging",
  WF_whatsAppPhoneNumber: "WF_whatsAppPhoneNumber",
  WF_whatsAppPhoneNumberTooltip1: "WF_whatsAppPhoneNumberTooltip1",
  WF_whatsAppPhoneNumberTooltip2: "WF_whatsAppPhoneNumberTooltip2",
  WF_timezone: "WF_timezone",
  WF_attention: "WF_attention",
  WF_govAgencyText1: "WF_govAgencyText1",
  PI_perMonth: "PI_perMonth",
  PI_text1: "PI_text1",
  PI_text2: "PI_text2",
  PI_textUSD: "PI_textUSD",
  PI_billedMonthly: "PI_billedMonthly",
  PI_completeOrderButtonLabel: "PI_completeOrderButtonLabel",
  WF_termsIntro: "WF_termsIntro",
  WF_terms1: "WF_terms1",
  WF_terms1p1: "WF_terms1p1",
  WF_terms1p2: "WF_terms1p2",
  WF_terms2: "WF_terms2",
  WF_terms2p1p1: "WF_terms2p1p1",
  WF_terms2p1and: "WF_terms2p1and",
  WF_terms2p1p2: "WF_terms2p1p2",
  WF_terms2p2: "WF_terms2p2",
  WF_terms2p3: "WF_terms2p3",
  WF_terms2p4: "WF_terms2p4",
  WF_terms3: "WF_terms3",
  WF_terms3p1: "WF_terms3p1",
  WF_terms3p2: "WF_terms3p2",
  WF_terms4: "WF_terms4",
  WF_terms4p1: "WF_terms4p1",
  WF_terms4p2: "WF_terms4p2",
  WF_terms4p3: "WF_terms4p3",
  WF_terms5: "WF_terms5",
  WF_terms5p1: "WF_terms5p1",
  WF_terms5p2: "WF_terms5p2",
  WF_terms5p3: "WF_terms5p3",
  WF_terms5p4: "WF_terms5p4",
  WF_terms5p5: "WF_terms5p5",
  WF_terms5p6: "WF_terms5p6",
  WF_terms5p7: "WF_terms5p7",
  WF_terms5p8: "WF_terms5p8",
  WF_termsLabel: "WF_termsLabel",
  WF_termsCheckbox1: "WF_termsCheckbox1",
  WF_termsCheckbox1Detail: "WF_termsCheckbox1Detail",
  WF_termsCheckbox2: "WF_termsCheckbox2",
  WF_termsCheckbox3: "WF_termsCheckbox3",
  WF_verifiedBsp: "WF_verifiedBsp",
  WF_buttonLabelAccept: "WF_buttonLabelAccept",
  WF_buttonLabelSave: "WF_buttonLabelSave",
  WF_paymentInfo: "WF_paymentInfo",
  WF_submission: "WF_submission",
  WF_thanksTitle: "WF_thanksTitle",
  WF_thanksNext: "WF_thanksNext",
  WF_thanksMob1: "WF_thanksMob1",
  WF_thanksMobQuotes: "WF_thanksMobQuotes",
  WF_thanksMob2: "WF_thanksMob2",
  WF_thanksButtonLabel: "WF_thanksButtonLabel",
  WF_0800error: "WF_0800error",
  WF_paymentCardOwnerName: "WF_paymentCardOwnerName",
  WF_paymentCreditCard: "WF_paymentCreditCard",
  WF_paymentCardOwnerNamePlaceholder: "WF_paymentCardOwnerNamePlaceholder",
  WF_hubAccess: "WF_hubAccess",
  WF_hubAccessHelp: "WF_hubAccessHelp",
  WF_hubAccessHelpPassword: "WF_hubAccessHelpPassword",
  WF_passwordLabel: "WF_passwordLabel",
  WF_repeatPasswordLabel: "WF_repeatPasswordLabel",
  WF_passwordError1: "WF_passwordError1",
  WF_passwordError2: "WF_passwordError2",
  WF_passwordError3: "WF_passwordError3",
  WF_passwordError4: "WF_passwordError4",
  WF_passwordRepeatError: "WF_passwordRepeatError",
  WF_cardError: "WF_cardError",
  WF_passwordErrorZxcvbn: "WF_passwordErrorZxcvbn",
  CON_title: "CON_title",
  CON_textIntro: "CON_textIntro",
  CON_bullet1: "CON_bullet1",
  CON_bullet2: "CON_bullet2",
  CON_bullet3: "CON_bullet3",
  CON_numberHelpText: "CON_numberHelpText",
  CON_numberHelpLink: "CON_numberHelpLink",
  CON_buttonLabel: "CON_buttonLabel",
  CAN_title: "CAN_title",
  CAN_textIntro: "CAN_textIntro",
  CAN_faq: "CAN_faq",
  CAN_buttonHelp: "CAN_buttonHelp",
  WF_partnerIntro: "WF_partnerIntro",
  WF_userAlreadyExists: "WF_userAlreadyExists",
  CAN_livesupportTitle: "CAN_livesupportTitle",
  CAN_livesupportText: "CAN_livesupportText",
  CAN_livesupportButtonLabel: "CAN_livesupportButtonLabel",
  CAN_livesupportButtonLabelSuccess: "CAN_livesupportButtonLabelSuccess",
  PI_selectedLabel: "PI_selectedLabel",
  SH_copied: "SH_copied",
  SH_copyPageLink: "SH_copyPageLink",
  SH_pageLink: "SH_pageLink",
  PI_pricingPlanLinkLabel: "PI_pricingPlanLinkLabel",
  WF_alreadyHaveAccount: "WF_alreadyHaveAccount",
  WF_login: "WF_login",
  WF_mobileHeading: "WF_mobileHeading",
  WF_notSupportedMobile: "WF_notSupportedMobile",
  WF_saveURL: "WF_saveURL",
  WF_continueAnyway: "WF_continueAnyway",
  WF_sharedSuccessfully: "WF_sharedSuccessfully",
  WF_copiedUrl: "WF_copiedUrl",
  CC_consentText: "CC_consentText",
  CC_acceptButtonLabel: "CC_acceptButtonLabel",
  CC_declineButtonLabel: "CC_declineButtonLabel",
  CC_title: "CC_title",
  CC_text: "CC_text",
  CC_cookies1text: "CC_cookies1text",
  CC_cookies1label: "CC_cookies1label",
  CC_cookies2text: "CC_cookies2text",
  CC_cookies2label: "CC_cookies2label",
  CC_cookies3text: "CC_cookies3text",
  CC_cookies3label: "CC_cookies3label",
  CC_buttonLabelAcceptSelected: "CC_buttonLabelAcceptSelected",
  CC_buttonLabelAcceptNecessary: "CC_buttonLabelAcceptNecessary",
  CC_buttonLabelAcceptAll: "CC_buttonLabelAcceptAll",
  WF_currentBussinessSolutionsProvider: "WF_currentBussinessSolutionsProvider",
  MIG_fbErrorWaitNewCode: "MIG_fbErrorWaitNewCode",
  CH_portExistingNumber: "CH_portExistingNumber",
  CH_portWhatsAppProfile: "CH_portWhatsAppProfile",
  CH_addNewNumber: "CH_addNewNumber",
  CH_setUpWhatsAppProfile: "CH_setUpWhatsAppProfile",
  CH_welcomeTo360dialog: "CH_welcomeTo360dialog",
  CH_pleaseSelect: "CH_pleaseSelect",
  CH_continueWithAccount: "CH_continueWithAccount",
  CAN_createTicket: "CAN_createTicket",
  CAN_ticketCreated: "CAN_ticketCreated",
  CON_contactSupportHelptext: "CON_contactSupportHelptext",
  WF_goToLogin: "WF_goToLogin",
  WF_planSelection: "WF_planSelection",
  WF_monthly: "WF_monthly",
  WF_yearly: "WF_yearly",
  WF_month: "WF_month",
  WF_year: "WF_year",
  WF_billedMonthly: "WF_billedMonthly",
  WF_months: "WF_months",
  WF_billedAt: "WF_billedAt",
  WF_selected: "WF_selected",
  WF_select: "WF_select",
  WF_basic: "WF_basic",
  WF_professional: "WF_professional",
  WF_forDevelopers: "WF_forDevelopers",
  WF_scalablePlan: "WF_scalablePlan",
  WF_buttonLabelSubmit: "WF_buttonLabelSubmit",
  WF_buttonLabelCancel: "WF_buttonLabelCancel",
  WF_reviewData: "WF_reviewData",
  WF_passwordError5: "WF_passwordError5",
  CC_openCookiePreferences: "CC_openCookiePreferences",
  CC_acceptCardLabel: "CC_acceptCardLabel",
  WF_freemailError: "WF_freemailError",
  CON_sessionExp: "CON_sessionExp",
  VER_checkEmailTitle: "VER_checkEmailTitle",
  VER_submit: "VER_submit",
  VER_bullet1: "VER_bullet1",
  VER_bullet2: "VER_bullet2",
  VER_bullet3: "VER_bullet3",
  VER_inputLabel: "VER_inputLabel",
  VER_inputPlaceholder: "VER_inputPlaceholder",
  VER_inputError: "VER_inputError",
  VER_closeWindowWarning: "VER_closeWindowWarning",
  VER_newCodeSentToEmail: "VER_newCodeSentToEmail",
  AI_almostDone: "AI_almostDone",
  AI_addInfo: "AI_addInfo",
  AI_completeInfo: "AI_completeInfo",
  AI_companyInfo: "AI_companyInfo",
  AI_companyNameLabel: "AI_companyNameLabel",
  AI_companyNamePlaceholder: "AI_companyNamePlaceholder",
  AI_companyAddressLabel: "AI_companyAddressLabel",
  AI_companyAddressPlaceholder: "AI_companyAddressPlaceholder",
  AI_selectCountry: "AI_selectCountry",
  AI_vatIdLabel: "AI_vatIdLabel",
  AI_vatIdPlaceholder: "AI_vatIdPlaceholder",
  AI_companyVertical: "AI_companyVertical",
  FS_accessCompanyFBM: "FS_accessCompanyFBM",
  FS_connectNumber: "FS_connectNumber",
  FS_addInfoToBusinessManager: "FS_addInfoToBusinessManager",
  FS_fbCommercePolicy: "FS_fbCommercePolicy",
  FS_embeddedSignupInfo: "FS_embeddedSignupInfo",
  WF_fullName: "WF_fullName",
  WF_noAccount: "WF_noAccount",
  WF_signUpHere: "WF_signUpHere",
  WF_loginHere: "WF_loginHere",
  WF_passwordError6: "WF_passwordError6",
  WF_comapnyNameTooltip: "WF_comapnyNameTooltip",
  WF_vatIdTooltip: "WF_vatIdTooltip",
  WF_vatIdNotValid: "WF_vatIdNotValid",
  WF_noVatId: "WF_noVatId",
  WF_noVATMessage: "WF_noVATMessage",
  WF_vatInfoUpdated: "WF_vatInfoUpdated",
  WF_governmentalCheck: "WF_governmentalCheck",
  WF_notGovernmental: "WF_notGovernmental",
  WF_facebookBusinessSetup: "WF_facebookBusinessSetup",
  WF_ukraineExcludedAreasNote: "WF_ukraineExcludedAreasNote",
  FS_comeBackFromMeta: "FS_comeBackFromMeta",
  FS_completeMataOnboarding: "FS_completeMataOnboarding",
  FS_completeMataOnboardingInfo: "FS_completeMataOnboardingInfo",
  CC_disabledCookiePreferences: "CC_disabledCookiePreferences",
  CON_bullet2a: "CON_bullet2a",
  CON_bullet2b: "CON_bullet2b",
  CON_bullet4: "CON_bullet4",
  CON_bullet4a: "CON_bullet4a",
  ON_paymentDebitNotice: "ON_paymentDebitNotice",
};

export default keys;
