import { MPInstancesHub } from "@/services/api/RequestAxios/RequestAxiosHub";
import PublicRequester from "@/services/api/RequestAxios/RequestAxiosHubPublic";
import { IQueryParams, packQueryParams, stringifyQueryParams } from "../utils";
import { UIModelChannel, UIModelChannels } from "./channels.model";
import {
  packChannel,
  unpackChannels,
  unpackChannel,
  packChannelConsents,
  packUpdateBillingFinish,
  packUpdateBillingStart,
  unpackWaba,
  unpackMigrateChannel,
  unpackPermissions,
  unpackPermissionsList,
  unpackProfile,
  packAdminChannel,
} from "./channels.mapper";
import * as Types from "./channels.types";

import { IServerResponseIntegration } from "../waba/waba.types";
import { TBundleTier } from "../clients/clients.types";

const channelsURL = ({
  partnerId,
  clientId,
  projectId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels`;

const channelURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}`;

const partnerChannelURL = ({
  partnerId,
  channelId,
}: {
  partnerId: string;
  channelId: string;
}) => `/partners/${partnerId}/channels/${channelId}`;

const channelsSubmitURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/submit`;

const submitMigrationUrl = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/submit_migration`;

const migrateChannelUrl = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/migrate_channel`;

const requestVerificationUrl = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/request_migration_code`;

const verifyMigrationUrl = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/verify_migration`;

const channelsUpdateStatusURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/update_status`;

const channelsUpdatePhoneNameURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/request_new_name`;

const channelsUpdateDeclinedPhoneNameURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/update_name`;

const channelsUpdateConsents = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/update_consents`;

const channelsDeleteConsents = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/delete_consents`;

const channelsWabaRegistrationURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/send_register_reminder`;

const channelsAddNumberUrl = ({
  partnerId,
  clientId,
  wabaAccountId,
}: {
  partnerId: string;
  clientId: string;
  wabaAccountId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/waba_accounts/${wabaAccountId}/control/add_number`;

const integrationUrl = ({ integrationId }: { integrationId: string }) =>
  `/partners/waba_setup/${integrationId}`;

const channelConsentsGetDataUrl = ({
  partnerId,
  channelId,
}: {
  partnerId: string;
  channelId: string;
}) => `/partners/${partnerId}/channels/${channelId}/info/client`;

const channelConsentsUpdateDataUrl = ({
  partnerId,
  channelId,
  token,
}: {
  partnerId: string;
  channelId: string;
  token: string;
}) =>
  `/partners/${partnerId}/channels/${channelId}/control/update_info?token=${token}`;

const channelConsentsUpdateConsentsUrl = ({
  partnerId,
  channelId,
  token,
}: {
  partnerId: string;
  channelId: string;
  token: string;
}) =>
  `/partners/${partnerId}/channels/${channelId}/control/sign_consents?token=${token}`;

const channelAvailabilityURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  channelId: string;
  projectId: string;
  clientId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/set_availability`;

const channelSetVerifiedURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  channelId: string;
  projectId: string;
  clientId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/set_verified`;

const channelUpdateBillingFinishURL = ({
  partnerId,
  channelId,
}: {
  partnerId: string;
  channelId: string;
}) =>
  `/partners/${partnerId}/channels/${channelId}/control/update_billing_finish_date`;

const channelUpdateBillingStartURL = ({
  partnerId,
  channelId,
}: {
  partnerId: string;
  channelId: string;
}) =>
  `/partners/${partnerId}/channels/${channelId}/control/update_billing_start_date`;

const channelDeleteNumberURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  channelId: string;
  projectId: string;
  clientId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/cancellation_request`;

const revokeNumberDeletionURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  channelId: string;
  projectId: string;
  clientId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/revoke_cancellation`;

const getProfileURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  channelId: string;
  projectId: string;
  clientId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/profile_settings`;

const uploadProfilePhotoURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  channelId: string;
  projectId: string;
  clientId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/upload_profile_photo`;

const financialReportURL = () => `/channels/info/financial_report`;
const financialReportEmailURL = () =>
  `/channels/info/financial_report_to_email`;

const channelsPermissionsURL = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/api-key-permission`;

const getPermissionsListURL = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/clients-shared-numbers`;

const changeInboxSettingURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  channelId: string;
  projectId: string;
  clientId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/inbox_settings`;

const enableLocalStorageURL = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  channelId: string;
  projectId: string;
  clientId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/enable_local_storage`;

export const getChannelsList = async (
  {
    partnerId,
    clientId,
    projectId,
  }: {
    partnerId: string;
    clientId: string;
    projectId: string;
  },
  queryParams?: IQueryParams
): Promise<UIModelChannels> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseChannels>(
      channelsURL({ partnerId, clientId, projectId }),
      {
        params: queryParams && packQueryParams(queryParams),
        paramsSerializer: stringifyQueryParams,
      }
    );

  return new UIModelChannels(unpackChannels(data));
};

export const getChannel = async ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}): Promise<UIModelChannel> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseChannel>(
      channelURL({ partnerId, clientId, projectId, channelId })
    );
  const fullResponse = await channelGetOrNotIntegration(data);
  return new UIModelChannel(unpackChannel(fullResponse));
};

export const addChannel = async (
  {
    partnerId,
    clientId,
    projectId,
  }: { partnerId: string; clientId: string; projectId: string },
  payload: Types.ChannelFormValues
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseChannel>(
      channelsURL({ partnerId, clientId, projectId }),
      packChannel(payload)
    );

  return new UIModelChannel(unpackChannel(data));
};

export const editChannel = async (
  {
    partnerId,
    clientId,
    projectId,
    channelId,
  }: {
    partnerId: string;
    clientId: string;
    projectId: string;
    channelId: string;
  },
  payload: Partial<Types.ChannelFormValues>
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.patch<Types.IServerResponseChannel>(
      channelURL({ partnerId, clientId, projectId, channelId }),
      packChannel(payload)
    );
  const fullResponse = await channelGetOrNotIntegration(data);
  return unpackChannel(fullResponse);
};

export const adminEditChannel = async (
  {
    partnerId,
    channelId,
  }: {
    partnerId: string;
    channelId: string;
  },
  payload: Partial<Types.ChannelFormValues>
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.patch<Types.IServerResponseChannel>(
      partnerChannelURL({ partnerId, channelId }),
      packAdminChannel(payload)
    );
  const fullResponse = await channelGetOrNotIntegration(data);
  return unpackChannel(fullResponse);
};

export const deleteChannel = async ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.delete(
    channelURL({ partnerId, clientId, projectId, channelId })
  );
  return data;
};

export const submitChannel = async ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseChannel>(
      channelsSubmitURL({
        partnerId,
        clientId,
        projectId,
        channelId,
      })
    );
  const fullResponse = await channelGetOrNotIntegration(data);
  return unpackChannel(fullResponse);
};

export const addNumberChannel = async (
  {
    partnerId,
    clientId,
    wabaAccountId,
  }: { partnerId: string; clientId: string; wabaAccountId: string },
  payload: Types.IServerRequestAddNumberToChannel
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseChannel>(
      channelsAddNumberUrl({
        partnerId,
        clientId,
        wabaAccountId,
      }),
      payload
    );
  return data;
};

export const updateStatusChannel = async ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseChannel>(
      channelsUpdateStatusURL({
        partnerId,
        clientId,
        projectId,
        channelId,
      })
    );
  const fullResponse = await channelGetOrNotIntegration(data);
  return unpackChannel(fullResponse);
};

export const channelsWabaRegistration = async ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    channelsWabaRegistrationURL({
      partnerId,
      clientId,
      projectId,
      channelId,
    })
  );
  return data;
};

export const updatePhoneName = async (
  {
    partnerId,
    clientId,
    projectId,
    channelId,
  }: {
    partnerId: string;
    clientId: string;
    projectId: string;
    channelId: string;
  },
  phoneName: string
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseChannel>(
      channelsUpdatePhoneNameURL({
        partnerId,
        clientId,
        projectId,
        channelId,
      }),
      { phone_name: phoneName }
    );
  const fullResponse = await channelGetOrNotIntegration(data);
  return unpackChannel(fullResponse);
};

export const updateDeclinedPhoneName = async (
  {
    partnerId,
    clientId,
    projectId,
    channelId,
  }: {
    partnerId: string;
    clientId: string;
    projectId: string;
    channelId: string;
  },
  phoneName: string
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseChannel>(
      channelsUpdateDeclinedPhoneNameURL({
        partnerId,
        clientId,
        projectId,
        channelId,
      }),
      { phone_name: phoneName }
    );
  const fullResponse = await channelGetOrNotIntegration(data);
  return unpackChannel(fullResponse);
};

export const updateConsents = async (
  {
    partnerId,
    clientId,
    projectId,
    channelId,
  }: {
    partnerId: string;
    clientId: string;
    projectId: string;
    channelId: string;
  },
  payload: Types.IUIRequestChannelConsents
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseChannel>(
      channelsUpdateConsents({
        partnerId,
        clientId,
        projectId,
        channelId,
      }),
      packChannelConsents(payload)
    );
  const fullResponse = await channelGetOrNotIntegration(data);
  return unpackChannel(fullResponse);
};

export const deleteConsents = async ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseChannel>(
      channelsDeleteConsents({
        partnerId,
        clientId,
        projectId,
        channelId,
      }),
      {}
    );
  const fullResponse = await channelGetOrNotIntegration(data);
  return unpackChannel(fullResponse);
};

const channelGetOrNotIntegration = async (
  data: Types.IServerResponseChannel
): Promise<Types.IServerResponseChannel> => {
  let response = data;
  const integrationId = data.integration_id;
  if (integrationId && !data.integration) {
    try {
      const integration = await getIntegration({ integrationId });
      response = {
        ...data,
        integration: integration,
      };
    } catch (error) {
      // Mixpanel?.track({
      //   name: "Loading integration error",
      //   props: { type: "event" },
      // });
    }
  }
  return response;
};

export const getIntegration = async ({
  integrationId,
}: {
  integrationId: string;
}): Promise<IServerResponseIntegration> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<IServerResponseIntegration>(
      integrationUrl({ integrationId })
    );
  return data;
};

export const getChannelConsentsClientData = async ({
  partnerId,
  channelId,
  token,
}: {
  partnerId: string;
  channelId: string;
  token: string;
}): Promise<Types.IServerResponseConsentsChannelData> => {
  const { data } =
    await PublicRequester.requester.get<Types.IServerResponseConsentsChannelData>(
      channelConsentsGetDataUrl({ partnerId, channelId }),
      {
        params: { token },
      }
    );
  return data;
};

export const updateChannelConsentsClientData = async (
  {
    partnerId,
    channelId,
    token,
  }: { partnerId: string; channelId: string; token: string },
  body: Types.IServerRequestConsentsChannelData
): Promise<Types.IServerResponseConsentsChannelDataOnUpdate> => {
  const { data } =
    await PublicRequester.requester.post<Types.IServerResponseConsentsChannelDataOnUpdate>(
      channelConsentsUpdateDataUrl({ partnerId, channelId, token }),
      body
    );
  return data;
};

export const updateChannelConsents = async (
  {
    partnerId,
    channelId,
    token,
  }: { partnerId: string; channelId: string; token: string },
  body: Types.IServerRequestConsents
) => {
  const { data } = await PublicRequester.requester.post(
    channelConsentsUpdateConsentsUrl({ partnerId, channelId, token }),
    body
  );
  return data;
};

export const getFinancialReport = async () => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get(
    financialReportURL(),
    {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
      responseType: "arraybuffer",
    }
  );
  return data;
};

export const triggerFinancialReport = async () => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    financialReportEmailURL(),
    {}
  );
  return data;
};

export const setChannelAvailability = async (
  {
    partnerId,
    clientId,
    projectId,
    channelId,
  }: {
    partnerId: string;
    clientId: string;
    projectId: string;
    channelId: string;
  },
  payload: {
    enabled: boolean;
  }
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseChannel>(
      channelAvailabilityURL({
        partnerId,
        clientId,
        projectId,
        channelId,
      }),
      payload
    );
  const fullResponse = await channelGetOrNotIntegration(data);
  return unpackChannel(fullResponse);
};

export const setChannelVerified = async (
  {
    partnerId,
    clientId,
    projectId,
    channelId,
  }: {
    partnerId: string;
    clientId: string;
    projectId: string;
    channelId: string;
  },
  payload: {
    certificate: string;
  }
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseChannel>(
      channelSetVerifiedURL({
        partnerId,
        clientId,
        projectId,
        channelId,
      }),
      payload
    );
  const fullResponse = await channelGetOrNotIntegration(data);
  return unpackChannel(fullResponse);
};

export const updateBillingFinish = async (
  { partnerId, channelId }: { partnerId: string; channelId: string },
  payload: Types.IUpdateBillingFinishPayload
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseChannel>(
      channelUpdateBillingFinishURL({
        partnerId,
        channelId,
      }),
      packUpdateBillingFinish(payload)
    );
  const fullResponse = await channelGetOrNotIntegration(data);
  return unpackChannel(fullResponse);
};

export const updateBillingStart = async (
  { partnerId, channelId }: { partnerId: string; channelId: string },
  payload: Types.IUpdateBillingStartPayload
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseChannel>(
      channelUpdateBillingStartURL({
        partnerId,
        channelId,
      }),
      packUpdateBillingStart(payload)
    );
  const fullResponse = await channelGetOrNotIntegration(data);
  return unpackChannel(fullResponse);
};

export const deleteNumber = async ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseChannel>(
      channelDeleteNumberURL({
        partnerId,
        clientId,
        projectId,
        channelId,
      })
    );
  return unpackChannel(data);
};

export const revokeNumberDeletion = async ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseChannel>(
      revokeNumberDeletionURL({
        partnerId,
        clientId,
        projectId,
        channelId,
      })
    );

  return data;
};

export const getProfile = async ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseWhatsappProfile>(
      getProfileURL({ partnerId, clientId, projectId, channelId })
    );

  return unpackProfile(data);
};

export const editWaba = async (
  {
    partnerId,
    clientId,
    projectId,
    channelId,
  }: {
    partnerId: string;
    clientId: string;
    projectId: string;
    channelId: string;
  },
  payload: Partial<Types.IWabaFormValues>
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.patch<Types.IServerResponseChannel>(
      getProfileURL({ partnerId, clientId, projectId, channelId }),
      payload
    );
  const fullResponse = await channelGetOrNotIntegration(data);
  return unpackWaba(fullResponse);
};

export const uploadProfilePhoto = async (
  {
    partnerId,
    clientId,
    projectId,
    channelId,
  }: {
    partnerId: string;
    clientId: string;
    projectId: string;
    channelId: string;
  },
  payload: { file: FormData; onUploadProgress: (ev: any) => void }
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseChannel>(
      uploadProfilePhotoURL({ partnerId, clientId, projectId, channelId }),
      payload.file,
      {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: payload.onUploadProgress,
      }
    );

  return data.profile_info.picture_url;
};

export const submitMigration = async ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseChannel>(
      submitMigrationUrl({
        partnerId,
        clientId,
        projectId,
        channelId,
      }),
      {}
    );
  const fullResponse = await channelGetOrNotIntegration(data);
  return unpackChannel(fullResponse);
};

export const verifyMigration = async (
  {
    partnerId,
    clientId,
    projectId,
    channelId,
  }: {
    partnerId: string;
    clientId: string;
    projectId: string;
    channelId: string;
  },
  payload: { code: string; tier: TBundleTier; bundleId: number }
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseChannel>(
      verifyMigrationUrl({
        partnerId,
        clientId,
        projectId,
        channelId,
      }),
      payload
    );
  const fullResponse = await channelGetOrNotIntegration(data);
  return unpackChannel(fullResponse);
};

export const requestVerification = async (
  {
    partnerId,
    clientId,
    projectId,
    channelId,
  }: {
    partnerId: string;
    clientId: string;
    projectId: string;
    channelId: string;
  },
  payload: { code_method: string; language: string }
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseChannel>(
      requestVerificationUrl({
        partnerId,
        clientId,
        projectId,
        channelId,
      }),
      payload
    );
  const fullResponse = await channelGetOrNotIntegration(data);
  return unpackChannel(fullResponse);
};

export const migrateChannel = async (
  { partnerId, clientId }: { partnerId: string; clientId: string },
  payload: {
    phone_number: string;
    coupon: string;
    waba_account_id?: string;
    fb_business_id?: string;
  }
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseMigrateChannel>(
      migrateChannelUrl({
        partnerId,
        clientId,
      }),
      payload
    );
  return unpackMigrateChannel(data);
};

export const grantPermissions = async (
  { partnerId, clientId }: { partnerId: string; clientId: string },
  payload: { grants: Types.IGrantPermissionsPayload[] }
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IResponseChannelsPermissions>(
      channelsPermissionsURL({
        partnerId,
        clientId,
      }),
      payload
    );
  return unpackPermissions(data);
};

export const getPermissionsList = async ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get<
    Types.IListGrantedChannels[]
  >(
    getPermissionsListURL({
      partnerId,
      clientId,
    })
  );

  return Array.isArray(data)
    ? data?.map((item) => unpackPermissionsList(item))
    : [];
};

export const changeInboxSettings = async (
  {
    partnerId,
    clientId,
    projectId,
    channelId,
  }: {
    partnerId: string;
    clientId: string;
    projectId: string;
    channelId: string;
  },
  payload: { has_inbox: boolean }
) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post<{
    has_inbox: boolean;
  }>(
    changeInboxSettingURL({
      partnerId,
      clientId,
      projectId,
      channelId,
    }),
    payload
  );
  return data;
};

export const enableLocalStorage = async (
  {
    partnerId,
    clientId,
    projectId,
    channelId,
  }: {
    partnerId: string;
    clientId: string;
    projectId: string;
    channelId: string;
  },
  payload: { data_localization_region: string }
) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post<{
    data_localization_region: string;
  }>(
    enableLocalStorageURL({
      partnerId,
      clientId,
      projectId,
      channelId,
    }),
    payload
  );
  return data;
};

export const getPaymentPlans = async (partnerId: string) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get<
    {
      bundleId: number;
      bundleName: string;
      price: number;
      currency: string;
      billingPeriod: string;
      hubPartnerId: string;
      plans: {
        id: string;
        details: string;
        status: string;
        currency: string;
        priceType: string;
        priceAmount: {
          price: number;
          start: number;
          end: number;
        }[];
      }[];
    }[]
  >(`/partners/${partnerId}/payment_plan`);
  return data;
};
