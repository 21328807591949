import * as Types from "./wabaTemplates.types";
import { templateStatuses } from "@/services/api/admin/statuses";
import { makeAutoObservable } from "mobx";

export class UIModelWabaTemplate {
  id: string;
  wabaAccountId: string;
  partnerId: string;
  category: Types.templateCategories;
  name: string;
  status: templateStatuses;
  language: string;
  components: Types.ServerResponseComponentsTypes[];
  modifiedAt: string;
  createdAt: string;

  constructor(response: Types.IUIResponseWabaTemplate) {
    this.id = response.id;
    this.wabaAccountId = response.wabaAccountId;
    this.partnerId = response.partnerId;
    this.category = response.category;
    this.name = response.name;
    this.status = response.status;
    this.language = response.language;
    this.components = response.components;
    this.modifiedAt = response.modifiedAt;
    this.createdAt = response.createdAt;
  }

  // get formData() {
  //   const bodyComponent = this.components.find(component => component.type === 'BODY')
  //   return ({
  //     name: this.name,
  //     category: this.category,
  //     language: this.language,
  //     body: bodyComponent?.text || '',
  //     type: 'standard'
  //   })
  // }
}

export class UIModelWabaTemplates {
  total: number;
  wabaTemplates: UIModelWabaListTemplate[];

  constructor(response: Types.IUIResponseWabaTemplates) {
    this.total = response.total;
    this.wabaTemplates = response.wabaTemplates.reduce<
      UIModelWabaListTemplate[]
    >((prev, curr) => {
      const templateToFind = prev.find(
        (existedTemplate) => existedTemplate.name === curr.name
      );

      if (templateToFind) {
        templateToFind.setData({
          id: curr.id,
          language: curr.language,
          components: curr.components,
          status: curr.status,
        });
        return prev;
      }

      return [...prev, new UIModelWabaListTemplate(curr)];
    }, []);
    makeAutoObservable(this);
  }
}

export interface IData {
  id: string;
  status: templateStatuses;
  rejectedReason?: string;
  language: string;
  components: Types.ServerResponseComponentsTypes[];
}

export class UIModelWabaListTemplate {
  id: string;
  wabaAccountId: string;
  partnerId: string;
  category: Types.templateCategories;
  name: string;
  modifiedAt: string;
  createdAt: string;
  currentPickedLanguage: string;
  data: { [k: string]: IData } = {};

  constructor(response: Types.IUIResponseWabaTemplate) {
    this.id = response.id;
    this.wabaAccountId = response.wabaAccountId;
    this.partnerId = response.partnerId;
    this.category = response.category;
    this.name = response.name;
    this.modifiedAt = response.modifiedAt;
    this.createdAt = response.createdAt;
    this.currentPickedLanguage = response.language;
    this.data[response.language] = {
      id: response.id,
      status: response.status,
      language: response.language,
      components: response.components,
      rejectedReason: response.rejectedReason,
    };
    makeAutoObservable(this);
  }

  get allTemplateData() {
    return Object.values(this.data);
  }

  get currentData() {
    return this.data[this.currentPickedLanguage];
  }

  get formData() {
    return {
      name: this.name,
      category: this.category,
      language: this.currentData.language,
      status: this.currentData.status,
      type: "standard",
    };
  }

  deleteTemplate = (language: string) => {
    if (this.allTemplateData.length >= 2) {
      delete this.data[language];
      const [first] = this.allTemplateData;
      this.setCurrentLanguage(first.language);
    }
  };

  setData(data: IData) {
    this.data[data.language] = data;
  }

  setCurrentLanguage = (language: string) => {
    this.currentPickedLanguage = language;
  };
}
