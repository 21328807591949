// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.BV_advertising]: "Publicidad",
  [keys.BV_automotive]: "Automoción",
  [keys.BV_beauty]: "Salón de belleza y spa",
  [keys.BV_clothing]: "Ropa y vestimenta",
  [keys.BV_consumer_goods]: "Bienes de consumo envasados",
  [keys.BV_ecommerce]: "E-commerce",
  [keys.BV_education]: "Educación",
  [keys.BV_energy]: "Enegería y servicios públicos",
  [keys.BV_entertainment]: "Entretenimiento",
  [keys.BV_entertainment_media]: "Entretenimiento y medios de comunicación",
  [keys.BV_event_planning]: "Planificación y servicio de eventos",
  [keys.BV_banking]: "Finanzas y banca",
  [keys.BV_food]: "Comida y colmado",
  [keys.BV_gaming]: "Videojuegos",
  [keys.BV_government]: "Gobierno y políticas",
  [keys.BV_hotel]: "Hotel y alojamiento",
  [keys.BV_IT]: "Servicios TIC",
  [keys.BV_marketing]: "Marketing",
  [keys.BV_medical]: "Medicina y salud",
  [keys.BV_non_profit]: "Sin ánimo de lucro",
  [keys.BV_organizations]: "Organizaciones y asociaciones",
  [keys.BV_other]: "Otros",
  [keys.BV_professional]: "Servicios profesionales",
  [keys.BV_public]: "Servicio público",
  [keys.BV_retail]: "Venta minorista",
  [keys.BV_restaurant]: "Restaurantes",
  [keys.BV_shopping]: "Compras y venta al por menor",
  [keys.BV_technology]: "Tecnología",
  [keys.BV_telecom]: "Telecomunicaciones",
  [keys.BV_travel]: "Viajes",
  [keys.BV_transportation]: "Viajes y transportes",
  [keys.BV_financial]: "Finanzas y banca",
};

export default translate;
