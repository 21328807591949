// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.BV_advertising]: "translation_missing",
  //  [keys.BV_automotive]: "translation_missing",
  //  [keys.BV_beauty]: "translation_missing",
  //  [keys.BV_clothing]: "translation_missing",
  //  [keys.BV_consumer_goods]: "translation_missing",
  //  [keys.BV_ecommerce]: "translation_missing",
  //  [keys.BV_education]: "translation_missing",
  //  [keys.BV_energy]: "translation_missing",
  //  [keys.BV_entertainment]: "translation_missing",
  //  [keys.BV_entertainment_media]: "translation_missing",
  //  [keys.BV_event_planning]: "translation_missing",
  //  [keys.BV_banking]: "translation_missing",
  //  [keys.BV_food]: "translation_missing",
  //  [keys.BV_gaming]: "translation_missing",
  //  [keys.BV_government]: "translation_missing",
  //  [keys.BV_hotel]: "translation_missing",
  //  [keys.BV_IT]: "translation_missing",
  //  [keys.BV_marketing]: "translation_missing",
  //  [keys.BV_medical]: "translation_missing",
  //  [keys.BV_non_profit]: "translation_missing",
  //  [keys.BV_organizations]: "translation_missing",
  //  [keys.BV_other]: "translation_missing",
  //  [keys.BV_professional]: "translation_missing",
  //  [keys.BV_public]: "translation_missing",
  //  [keys.BV_retail]: "translation_missing",
  //  [keys.BV_restaurant]: "translation_missing",
  //  [keys.BV_shopping]: "translation_missing",
  //  [keys.BV_technology]: "translation_missing",
  //  [keys.BV_telecom]: "translation_missing",
  //  [keys.BV_travel]: "translation_missing",
  //  [keys.BV_transportation]: "translation_missing",
  //  [keys.BV_financial]: "translation_missing",
};

export default translate;
