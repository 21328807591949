import { createContext } from "react";
import { UIModelIntegration } from "@/services/api/channels/channels.model";
import { ListState } from "@/store/listState";

export interface IWabasListWithAppInfoIntegrations extends UIModelIntegration {
  name: string;
  organisation: string;
}

export interface IWabasListWithAppInfo {
  integrations: IWabasListWithAppInfoIntegrations[];
  total: number;
}

const wabasState = new ListState<IWabasListWithAppInfo>({
  withoutAppId: true,
  limit: 10,
  offset: 0,
  sortFields: ["-modified_at"],
  searchFields: ["stack_id"],
  apiDataInitial: {
    integrations: [],
    total: 0,
  },
  filtersLoadSave: {},
});

export const WabasListState = createContext<ListState<IWabasListWithAppInfo>>(
  {} as ListState<IWabasListWithAppInfo>
);

const WabaListStateStoreProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <WabasListState.Provider value={wabasState}>
      {children}
    </WabasListState.Provider>
  );
};

export default WabaListStateStoreProvider;
