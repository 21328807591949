import * as React from "react";
const Logo360_6 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={28}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M11.152 11.341c-3.63 0-6.366 2.755-6.366 6.406 0 3.652 2.736 6.407 6.366 6.407 3.613 0 6.336-2.755 6.336-6.407 0-3.651-2.723-6.406-6.336-6.406Zm0 16.647C5.314 27.988.91 23.584.91 17.747c0-2.325.715-4.495 2.067-6.275L11.22.557h4.813l-5.268 6.957c.129-.007.258-.009.387-.009 5.82 0 10.212 4.404 10.212 10.242 0 5.837-4.391 10.24-10.212 10.24Z"
    />
  </svg>
);
export default Logo360_6;
