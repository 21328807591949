import * as React from "react";
const Logo360_0 = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={29}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M13.42 3.877c-5.247 0-9.516 4.551-9.516 10.142 0 5.61 4.269 10.176 9.516 10.176 5.244 0 9.508-4.565 9.508-10.176 0-5.686-4.177-10.142-9.508-10.142Zm0 24.202C6.021 28.079 0 21.771 0 14.019 0 6.289 6.021 0 13.42 0c7.506 0 13.383 6.158 13.383 14.019 0 7.752-6.004 14.06-13.383 14.06Z"
    />
  </svg>
);
export default Logo360_0;
