import { unpackUserSkeleton } from "./user.mapper";
import {
  IServerResponseHubUser,
  UIResponseHubUser,
  IServerResponseHubUsers,
  UIResponseHubUsers,
  IFormValuesPartnerUser,
  IServerRequestPartnerUser,
} from "./user.types";

export const unpackHubUser = (
  response: IServerResponseHubUser
): UIResponseHubUser => ({
  ...unpackUserSkeleton(response),
  login: response.login || "",
  permissions: response.permissions,
});

export const unpackHubUsers = (
  response: IServerResponseHubUsers
): UIResponseHubUsers => {
  return {
    users: response.users.map((user: IServerResponseHubUser) => {
      return unpackHubUser(user);
    }),
    total: response.total,
  };
};

export const packHubUser = (
  response: IFormValuesPartnerUser
): IServerRequestPartnerUser => ({
  name: response.name,
  email: response.email,
});
