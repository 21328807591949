import {
  ServerResponseConversationUsersBlob,
  ServerResponseProfile,
  ServerRequestProfile,
  FormUpdatePassword,
  ServerRequestInviteUser,
  InviteUserParams,
  ServerFormUpdatePassword,
  UIResponseProfile,
  UIResponseConversationUsers,
  ServerResponseUser,
  ServerResponseUserSkeleton,
  UIResponseUserSkeleton,
  UIResponseUser,
  CreateUserServerRequest,
  UpdateUserServerRequest,
  ServerResponseProfileAppDetails,
  UIResponseProfileAppDetails,
  ServerResponseProfileAppDetailsTeam,
  ServerResponseProfileAppDetailsTeamsAgent,
  UIResponseProfileAppDetailsTeam,
  UIResponseProfileAppDetailsTeamsAgent,
} from "./user.types";
import { UIModelUser, UIModelProfile } from "./user.model";
import { unpackPartnerShort } from "../partners/partners.mapper";

export const unpackServerResponseConversationUsers = (
  response: ServerResponseConversationUsersBlob
): UIResponseConversationUsers => {
  return {
    users: response.users.map((user: ServerResponseUser) => {
      return unpackUser(user);
    }),
    total: response.total,
  };
};

export const packProfile = (
  profile: Partial<UIModelProfile>
): ServerRequestProfile => {
  return {
    id: profile.id,
    login: profile.login,
    name: profile.name,
    email: profile.email,
    email_verified: profile.emailVerified,
    picture: profile.picture,
    admin: profile.admin,
    blocked: profile.blocked,
    apps: profile.apps,
  };
};

export const packUpdateUserPasswordPayload = (
  form: FormUpdatePassword
): ServerFormUpdatePassword => {
  return {
    password: form.password,
  };
};

export const packInviteUser = (
  payload: InviteUserParams
): ServerRequestInviteUser => ({
  permissions: payload.permissions,
  email: payload.email,
});

export const unpackUserSkeleton = (
  response: Omit<ServerResponseUserSkeleton, "login">
): Omit<UIResponseUserSkeleton, "login"> => ({
  id: response.id,
  name: response.name,
  email: response.email,
  emailVerified: response.email_verified,
  picture: response.picture,
  blocked: response.blocked,
  createdAt: response.created_at,
  createdBy: {
    userId: response.created_by.user_id,
    userName: response.created_by.user_name,
  },
  modifiedAt: response.modified_at,
  modifiedBy: {
    userId: response.modified_by.user_id,
    userName: response.modified_by.user_name,
  },
  otpStatus: response.otp_status,
});

export const unpackProfile = (
  response: ServerResponseProfile
): UIResponseProfile => ({
  ...unpackUserSkeleton(response),
  login: response.login,
  admin: response.admin,
  apps: response.apps,
  lastManaged: {
    channelId: response.last_managed.channel_id,
  },
  ...(response.partners
    ? {
        partners:
          response.partners.map((partner) => unpackPartnerShort(partner)) || [],
        clients: response.clients?.map((client) => ({
          client_id: client.client_id,
          partner_id: client.partner_id,
          permissions: client.permissions,
          client_name: client.client_name,
        })),
      }
    : {}),
});

export const unpackUser = (response: ServerResponseUser): UIResponseUser => ({
  ...unpackUserSkeleton(response),
  login: response.login || "",
  permissions: response.permissions,
});

export const packUser = (response: UIModelUser): CreateUserServerRequest => ({
  email: response.email,
  permissions: response.permissions,
  picture: !!response.picture ? response.picture : null,
  name: response.name,
});

export const packUpdateUser = (
  response: UIModelUser
): UpdateUserServerRequest => ({
  email: response.email,
  name: response.name,
  picture:
    typeof response.picture === "undefined"
      ? undefined
      : response.picture === ""
      ? null
      : response.picture,
  permissions: response.permissions,
});

export const unpackAppDetails = (
  response: ServerResponseProfileAppDetails
): UIResponseProfileAppDetails => ({
  app: {
    id: response.app.id,
    name: response.app.name,
    organisation: response.app.organisation,
    permissions: response.app.permissions,
  },
  teams: response.teams.map((team) => unpackAppDetailsTeam(team)),
});

export const unpackAppDetailsTeam = (
  team: ServerResponseProfileAppDetailsTeam
): UIResponseProfileAppDetailsTeam => ({
  id: team.id,
  name: team.name,
  role: team.role,
  avatarUrl: team.avatar_url,
  agents: team.agents.map((agent) => unpackAppDetailsAgent(agent)),
});

export const unpackAppDetailsAgent = (
  agent: ServerResponseProfileAppDetailsTeamsAgent
): UIResponseProfileAppDetailsTeamsAgent => ({
  id: agent.id,
  userId: agent.user_id,
  userName: agent.user_name,
});
