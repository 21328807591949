const keys: { [k: string]: string } = {
  InboxConversationNotFound: "InboxConversationNotFound",
  InboxConversationMessagesLoadingProblem:
    "InboxConversationMessagesLoadingProblem",
  InboxConversationMessagesSendingProblem:
    "InboxConversationMessagesSendingProblem",
  InboxConversationEmptyConversationList:
    "InboxConversationEmptyConversationList",
  InboxConversationNotFoundButton: "InboxConversationNotFoundButton",
  InboxPrefilterBarFilterAll: "InboxPrefilterBarFilterAll",
  InboxPrefilterBarFilterMy: "InboxPrefilterBarFilterMy",
  InboxPrefilterBarFilterNew: "InboxPrefilterBarFilterNew",
  InboxPrefilterBarFilterUnread: "InboxPrefilterBarFilterUnread",
  InboxPrefilterBarFilterClosed: "InboxPrefilterBarFilterClosed",
  InboxWorkspaceMenuMyInbox: "InboxWorkspaceMenuMyInbox",
  InboxWorkspaceMenuTeamInbox: "InboxWorkspaceMenuTeamInbox",
  InboxWorkspaceMenuTeamInboxSelected: "InboxWorkspaceMenuTeamInboxSelected",
  InboxAlertLinkCopiedLabel: "InboxAlertLinkCopiedLabel",
  InboxAlertConnectionProblemLabel: "InboxAlertConnectionProblemLabel",
  InboxSearchPlaceholder: "InboxSearchPlaceholder",
  InboxFilterConversationPopupFilterNoneFilterConversation:
    "InboxFilterConversationPopupFilterNoneFilterConversation",
  InboxFilterConversationPopupFilterAllConversations:
    "InboxFilterConversationPopupFilterAllConversations",
  InboxFilterConversationPopupFilterMyConversations:
    "InboxFilterConversationPopupFilterMyConversations",
  InboxFilterConversationPopupFilterNewConversations:
    "InboxFilterConversationPopupFilterNewConversations",
  InboxFilterConversationPopupFilterUnfinishedConversations:
    "InboxFilterConversationPopupFilterUnfinishedConversations",
  InboxFilterConversationPopupFilterRecentlyClosedConversations:
    "InboxFilterConversationPopupFilterRecentlyClosedConversations",
  InboxFilterConversationPopupFilterCustom:
    "InboxFilterConversationPopupFilterCustom",
  InboxFilterConversationPopupFilterTitleDefaultFilters:
    "InboxFilterConversationPopupFilterTitleDefaultFilters",
  InboxFilterConversationPopupCustomFilterTitle:
    "InboxFilterConversationPopupCustomFilterTitle",
  InboxFilterConversationOrderDateTitle:
    "InboxFilterConversationOrderDateTitle",
  InboxFilterConversationOrderDateNewest:
    "InboxFilterConversationOrderDateNewest",
  InboxFilterConversationOrderDateOldest:
    "InboxFilterConversationOrderDateOldest",
  ConversationSearchPlaceholder: "ConversationSearchPlaceholder",
  ConversationSearchHeader: "ConversationSearchHeader",
  ConversationSearchLabel: "ConversationSearchLabel",
  InboxConversationListNone: "InboxConversationListNone",
  InboxConversationListErrorTitle: "InboxConversationListErrorTitle",
  InboxConversationListErrorButton: "InboxConversationListErrorButton",
  InboxConversationIndicatorAssignedAvatarTakeOver:
    "InboxConversationIndicatorAssignedAvatarTakeOver",
  TooltipAssignToMeLabel: "TooltipAssignToMeLabel",
  SelectDefaultPlaceholder: "SelectDefaultPlaceholder",
  ManageUsers: "ManageUsers",
  TeamsAndUsersFirstSubHeader: "TeamsAndUsersFirstSubHeader",
  TeamsAndUsersSecondSubHeader: "TeamsAndUsersSecondSubHeader",
  UsersPopupEmailSendHeader: "UsersPopupEmailSendHeader",
  UsersPopupAddUserDescription: "UsersPopupAddUserDescription",
  InboxConversationHelperProfile: "InboxConversationHelperProfile",
  InboxConversationHelperLabels: "InboxConversationHelperLabels",
  InboxConversationHelperAttachments: "InboxConversationHelperAttachments",
  InboxConversationHelperLinks: "InboxConversationHelperLinks",
  InboxConversationHelperProfileMainInfoPhone:
    "InboxConversationHelperProfileMainInfoPhone",
  InboxConversationHelperProfileMainInfoDouble:
    "InboxConversationHelperProfileMainInfoDouble",
  InboxConversationHelperProfileMainInfoMo:
    "InboxConversationHelperProfileMainInfoMo",
  InboxConversationHelperProfileMainInfoLastSeen:
    "InboxConversationHelperProfileMainInfoLastSeen",
  InboxConversationHelperProfileMainInfoCreated:
    "InboxConversationHelperProfileMainInfoCreated",
  InboxConversationHelperProfileMainInfoSession:
    "InboxConversationHelperProfileMainInfoSession",
  InboxConversationHelperProfileMainInfoPushNotification:
    "InboxConversationHelperProfileMainInfoPushNotification",
  InboxConversationHelperProfileMainInfoEnabled:
    "InboxConversationHelperProfileMainInfoEnabled",
  RichEditorPickerSearch: "RichEditorPickerSearch",
  RichEditorPickerEmojiNotFound: "RichEditorPickerEmojiNotFound",
  RichEditorPickerSearchResults: "RichEditorPickerSearchResults",
  RichEditorPickerFrequentlyUsed: "RichEditorPickerFrequentlyUsed",
  RichEditorPickerSmileysAndPeople: "RichEditorPickerSmileysAndPeople",
  RichEditorPickerAnimalsAndNature: "RichEditorPickerAnimalsAndNature",
  RichEditorPickerFoodAndDrink: "RichEditorPickerFoodAndDrink",
  RichEditorPickerActivity: "RichEditorPickerActivity",
  RichEditorPickerTravelAndPlaces: "RichEditorPickerTravelAndPlaces",
  RichEditorPickerObjects: "RichEditorPickerObjects",
  RichEditorPickerSymbols: "RichEditorPickerSymbols",
  RichEditorPickerFlags: "RichEditorPickerFlags",
  RichEditorPickerCustom: "RichEditorPickerCustom",
  HighlightSearchListRecentSearches: "HighlightSearchListRecentSearches",
  NavigationShortcutsPanelToNavigate: "NavigationShortcutsPanelToNavigate",
  NavigationShortcutsPanelToSelect: "NavigationShortcutsPanelToSelect",
  NavigationShortcutsPanelESC: "NavigationShortcutsPanelESC",
  NavigationShortcutsPanelToDismiss: "NavigationShortcutsPanelToDismiss",
  TouchpointWizardTitleNewTouchpoints: "TouchpointWizardTitleNewTouchpoints",
  TouchpointWizardTitleDescription: "TouchpointWizardTitleDescription",
  TouchpointWizardTitleConsentAndDisclaimer:
    "TouchpointWizardTitleConsentAndDisclaimer",
  TouchpointWizardTitleTheming: "TouchpointWizardTitleTheming",
  TouchpointWizardTitleJourney: "TouchpointWizardTitleJourney",
  TouchpointWizardTitlePreviewAndDeployment:
    "TouchpointWizardTitlePreviewAndDeployment",
  SettingsCategoryTitleTouchpointWizard:
    "SettingsCategoryTitleTouchpointWizard",
  SettingsCategorySupportedCountriesSupportedCountries:
    "SettingsCategorySupportedCountriesSupportedCountries",
  SettingsCategorySupportedCountries: "SettingsCategorySupportedCountries",
  SettingsCategoryUnsupportedMedia: "SettingsCategoryUnsupportedMedia",
  SettingsCategoryUnsupportedMediaDescription:
    "SettingsCategoryUnsupportedMediaDescription",
  SettingsCategoryUnsupportedMediaEnabledDescription:
    "SettingsCategoryUnsupportedMediaEnabledDescription",
  SettingsCategoryUnsupportedMediaEnabled:
    "SettingsCategoryUnsupportedMediaEnabled",
  SettingsCategoryUnsupportedMediaVideo:
    "SettingsCategoryUnsupportedMediaVideo",
  SettingsCategoryUnsupportedMediaAudio:
    "SettingsCategoryUnsupportedMediaAudio",
  SettingsCategoryUnsupportedMediaDocument:
    "SettingsCategoryUnsupportedMediaDocument",
  SettingsCategoryUnsupportedMediaImage:
    "SettingsCategoryUnsupportedMediaImage",
  SettingsCategoryUnsupportedMediaVoice:
    "SettingsCategoryUnsupportedMediaVoice",
  SettingsCategoryUnsupportedMediaLocation:
    "SettingsCategoryUnsupportedMediaLocation",
  SettingsCategoryUnsupportedMediaContact:
    "SettingsCategoryUnsupportedMediaContact",
  SettingsCategorySupportedCountriesDescription:
    "SettingsCategorySupportedCountriesDescription",
  SettingsCategorySupportedCountriesDescriptionAutoResponder:
    "SettingsCategorySupportedCountriesDescriptionAutoResponder",
  SettingsCategoryAutoResponder: "SettingsCategoryAutoResponder",
  SettingsCategoryWebhook: "SettingsCategoryWebhook",
  NotificationSheduleFooterSave: "NotificationSheduleFooterSave",
  NotificationSheduleFooterCancel: "NotificationSheduleFooterCancel",
  NotificationSheduleHeaderReminders: "NotificationSheduleHeaderReminders",
  NotificationSheduleHeaderPinned: "NotificationSheduleHeaderPinned",
  NotificationSheduleRemindersContentMessageFrom:
    "NotificationSheduleRemindersContentMessageFrom",
  NotificationSheduleRemindersContentAt:
    "NotificationSheduleRemindersContentAt",
  CustomFiltersDialogFooterSaveFilter: "CustomFiltersDialogFooterSaveFilter",
  CustomFiltersDialogFooterCancel: "CustomFiltersDialogFooterCancel",
  CustomFiltersDialogFooterApplyAndClose:
    "CustomFiltersDialogFooterApplyAndClose",
  CustomFiltersDialogFiltersSavedTitle: "CustomFiltersDialogFiltersSavedTitle",
  CustomFiltersDialogFiltersLabelMatching:
    "CustomFiltersDialogFiltersLabelMatching",
  CustomFiltersDialogFiltersLabelPhoneNumber:
    "CustomFiltersDialogFiltersLabelPhoneNumber",
  CustomFiltersDialogFiltersLabelPofileName:
    "CustomFiltersDialogFiltersLabelPofileName",
  CustomFiltersDialogFiltersLabelResolutionCode:
    "CustomFiltersDialogFiltersLabelResolutionCode",
  CustomFiltersDialogFiltersLabelCurrentlyAssignedTo:
    "CustomFiltersDialogFiltersLabelCurrentlyAssignedTo",
  CustomFiltersDialogFiltersLabelConversationStatus:
    "CustomFiltersDialogFiltersLabelConversationStatus",
  CustomFiltersDialogFiltersLabelMessageInOut:
    "CustomFiltersDialogFiltersLabelMessageInOut",
  CustomFiltersDialogFiltersLabelMessageStatus:
    "CustomFiltersDialogFiltersLabelMessageStatus",
  CustomFiltersDialogFiltersLabelMessageType:
    "CustomFiltersDialogFiltersLabelMessageType",
  CustomFiltersDialogFiltersLabelConversationCreated:
    "CustomFiltersDialogFiltersLabelConversationCreated",
  CustomFiltersDialogFiltersLabelLastMessage:
    "CustomFiltersDialogFiltersLabelLastMessage",
  CustomFiltersDialogFiltersLabelLastIncomingMessage:
    "CustomFiltersDialogFiltersLabelLastIncomingMessage",
  CustomFiltersDialogFiltersLabelMessageCreated:
    "CustomFiltersDialogFiltersLabelMessageCreated",
  CustomFiltersDialogFiltersDropdownMore:
    "CustomFiltersDialogFiltersDropdownMore",
  ProfileTooltipToogleStatus: "ProfileTooltipToogleStatus",
  ProfileTooltipToogleProfileSettings: "ProfileTooltipToogleProfileSettings",
  ProfileTooltipToogleSignout: "ProfileTooltipToogleSignout",
  ProfileTooltipToogleLanguageLabel: "ProfileTooltipToogleLanguageLabel",
  ConversationInputModeReply: "ConversationInputModeReply",
  ConversationInputModeInternalNote: "ConversationInputModeInternalNote",
  ConversationInputTakeOverConversationNotFound:
    "ConversationInputTakeOverConversationNotFound",
  InboxConversationSessionInfoTranslateSessionExpires:
    "InboxConversationSessionInfoTranslateSessionExpires",
  ConversationTemplatesToolTipMessage: "ConversationTemplatesToolTipMessage",
  InboxConversationAssignToLabel: "InboxConversationAssignToLabel",
  InboxConversationAssignToAgentListLabel:
    "InboxConversationAssignToAgentListLabel",
  InboxConversationAssignToAgentUnassign:
    "InboxConversationAssignToAgentUnassign",
  InboxConversationAssignToTeamTitle: "InboxConversationAssignToTeamTitle",
  InboxConversationResolutionCodesReason:
    "InboxConversationResolutionCodesReason",
  InboxConversationResolutionCodesReopen:
    "InboxConversationResolutionCodesReopen",
  InboxConversationResolutionCodesClose:
    "InboxConversationResolutionCodesClose",
  InboxConversationTranslateAppTitle: "InboxConversationTranslateAppTitle",
  InboxConversationTranslateAppLangEnglish:
    "InboxConversationTranslateAppLangEnglish",
  InboxConversationTranslateAppLangGerman:
    "InboxConversationTranslateAppLangGerman",
  InboxConversationTranslateAppLandPolish:
    "InboxConversationTranslateAppLandPolish",
  InboxConversationRemindMeTitle: "InboxConversationRemindMeTitle",
  InboxConversationRemindMeTwenty: "InboxConversationRemindMeTwenty",
  InboxConversationRemindMeOneHour: "InboxConversationRemindMeOneHour",
  InboxConversationRemindMeThreeHour: "InboxConversationRemindMeThreeHour",
  InboxConversationRemindMeTomorrow: "InboxConversationRemindMeTomorrow",
  InboxConversationRemindMeNextWeek: "InboxConversationRemindMeNextWeek",
  AppReports: "AppReports",
  D360WhatsappMessageIn: "D360WhatsappMessageIn",
  D360Register: "D360Register",
  D360RelocationClicked: "D360RelocationClicked",
  D360WhatsappMessageSent: "D360WhatsappMessageSent",
  D360WhatsappSuccess: "D360WhatsappSuccess",
  D360WhatsappFail: "D360WhatsappFail",
  D360ReportActionDeleted: "D360ReportActionDeleted",
  SettingsActionGroupCancel: "SettingsActionGroupCancel",
  SettingsActionGroupSave: "SettingsActionGroupSave",
  CompanySettingsBasicInfoAddressAddresses:
    "CompanySettingsBasicInfoAddressAddresses",
  CompanySettingsBasicInfoAddressCity: "CompanySettingsBasicInfoAddressCity",
  CompanySettingsBasicInfoAddressPostalCode:
    "CompanySettingsBasicInfoAddressPostalCode",
  CompanySettingsBasicInfoAddressCountry:
    "CompanySettingsBasicInfoAddressCountry",
  CompanySettingsBasicInfoAddressPhone: "CompanySettingsBasicInfoAddressPhone",
  CompanySettingsBasicInfoAdministrativeContactEmailAddress:
    "CompanySettingsBasicInfoAdministrativeContactEmailAddress",
  CompanySettingsBasicInfoAdministrativeContactPhone:
    "CompanySettingsBasicInfoAdministrativeContactPhone",
  CompanySettingsBasicInfoHeaderLogotype:
    "CompanySettingsBasicInfoHeaderLogotype",
  CompanySettingsBasicInfoHeaderCompanyName:
    "CompanySettingsBasicInfoHeaderCompanyName",
  CompanySettingsBasicInfoHeaderCategory:
    "CompanySettingsBasicInfoHeaderCategory",
  CompanySettingsBasicInfoHeaderBusinessDescription:
    "CompanySettingsBasicInfoHeaderBusinessDescription",
  CompanySettingsBasicInfoHeaderBriefDescription:
    "CompanySettingsBasicInfoHeaderBriefDescription",
  KpiDashboardFiltersSelect: "KpiDashboardFiltersSelect",
  KpiDashboardFiltersFilter: "KpiDashboardFiltersFilter",
  KpiDashboardFiltersTotal: "KpiDashboardFiltersTotal",
  KpiDashboardFiltersLast30d: "KpiDashboardFiltersLast30d",
  KpiDashboardFiltersLast7d: "KpiDashboardFiltersLast7d",
  KpiDashboardFiltersLastDay: "KpiDashboardFiltersLastDay",
  KpiDashboardFiltersLast90d: "KpiDashboardFiltersLast90d",
  CompanySettingsBasicInfoCompanyInfo: "CompanySettingsBasicInfoCompanyInfo",
  CompanySettingsBasicInfoCompanyInfoDescription:
    "CompanySettingsBasicInfoCompanyInfoDescription",
  CompanySettingsBasicInfoAdministrativeContact:
    "CompanySettingsBasicInfoAdministrativeContact",
  CompanySettingsBasicInfoAdministrativeContactDescription:
    "CompanySettingsBasicInfoAdministrativeContactDescription",
  CompanySettingsBasicInfoKeysHint: "CompanySettingsBasicInfoKeysHint",
  CompanySettingsBasicInfoSocialFacebookBusiness:
    "CompanySettingsBasicInfoSocialFacebookBusiness",
  CompanySettingsBasicInfoSocialFacebook:
    "CompanySettingsBasicInfoSocialFacebook",
  CompanySettingsBasicInfoSocialLinkedin:
    "CompanySettingsBasicInfoSocialLinkedin",
  CompanySettingsBasicInfoSocialIstagram:
    "CompanySettingsBasicInfoSocialIstagram",
  CompanySettingsBasicInfoSocialTwitter:
    "CompanySettingsBasicInfoSocialTwitter",
  CompanySettingsBasicInfoWebsiteEmailAddress:
    "CompanySettingsBasicInfoWebsiteEmailAddress",
  CompanySettingsBasicInfoWebsiteWebsite:
    "CompanySettingsBasicInfoWebsiteWebsite",
  CompanySettingsOptOutFlowOptOut: "CompanySettingsOptOutFlowOptOut",
  CompanySettingsOptOutFlowOptOutDescription:
    "CompanySettingsOptOutFlowOptOutDescription",
  CompanySettingsOptOutFlowConfirmationText:
    "CompanySettingsOptOutFlowConfirmationText",
  CompanySettingsOptOutFlowOptOutMessage:
    "CompanySettingsOptOutFlowOptOutMessage",
  CompanySettingsOptOutFlowOptOutInMessage:
    "CompanySettingsOptOutFlowOptOutInMessage",
  CompanySettingsOptOutFlowOptOutTriggerWords:
    "CompanySettingsOptOutFlowOptOutTriggerWords",
  CompanySettingsOptOutFlowConfirmationWords:
    "CompanySettingsOptOutFlowConfirmationWords",
  CompanySettingsTooglesAutoAssignAgents:
    "CompanySettingsTooglesAutoAssignAgents",
  CompanySettingsTooglesAutoAssignAgentsDescription:
    "CompanySettingsTooglesAutoAssignAgentsDescription",
  CompanySettingsTooglesRrequencyCapping:
    "CompanySettingsTooglesRrequencyCapping",
  CompanySettingsTooglesRrequencyCappingDescription:
    "CompanySettingsTooglesRrequencyCappingDescription",
  CompanySettingsTooglesEnabled: "CompanySettingsTooglesEnabled",
  CompanySettingsWorkingHoursTitle: "CompanySettingsWorkingHoursTitle",
  CompanySettingsWorkingHoursTitleDescription:
    "CompanySettingsWorkingHoursTitleDescription",
  CompanySettingsWorkingHoursOfficeHour:
    "CompanySettingsWorkingHoursOfficeHour",
  CompanySettingsWorkingHoursExceptions:
    "CompanySettingsWorkingHoursExceptions",
  CompanySettingsWorkingHoursAutoResponder:
    "CompanySettingsWorkingHoursAutoResponder",
  CompanySettingsWorkingHoursExceptionsAddException:
    "CompanySettingsWorkingHoursExceptionsAddException",
  CompanySettingsWorkingHoursExceptionsOptionWholeDayOff:
    "CompanySettingsWorkingHoursExceptionsOptionWholeDayOff",
  CompanySettingsWorkingHoursExceptionsOptionFrom:
    "CompanySettingsWorkingHoursExceptionsOptionFrom",
  CompanySettingsWorkingHoursExceptionsOptionTo:
    "CompanySettingsWorkingHoursExceptionsOptionTo",
  CompanySettingsWorkingHoursOfficeHoursFrom:
    "CompanySettingsWorkingHoursOfficeHoursFrom",
  CompanySettingsWorkingHoursOfficeHoursTo:
    "CompanySettingsWorkingHoursOfficeHoursTo",
  CompanySettingsWorkingHoursOfficeHoursDeployTouchPointButtonText:
    "CompanySettingsWorkingHoursOfficeHoursDeployTouchPointButtonText",
  CompanySettingsWorkingHoursOfficeHoursDeployTouchPointButtonLabel:
    "CompanySettingsWorkingHoursOfficeHoursDeployTouchPointButtonLabel",
  MySettingsAccountMyInformation: "MySettingsAccountMyInformation",
  MySettingsAccountTitleDescription: "MySettingsAccountTitleDescription",
  MySettingsAccountChangePassword: "MySettingsAccountChangePassword",
  MySettingsAccountChangePasswordDescription:
    "MySettingsAccountChangePasswordDescription",
  MySettingsAccountHint: "MySettingsAccountHint",
  MySettingsAccountHintTwoFactorAuthentication:
    "MySettingsAccountHintTwoFactorAuthentication",
  MySettingsAccountHintTwoFactorAuthenticationDescription:
    "MySettingsAccountHintTwoFactorAuthenticationDescription",
  SettingsBasicInformationFirstName: "SettingsBasicInformationFirstName",
  SettingsBasicInformationLastName: "SettingsBasicInformationLastName",
  SettingsBasicInformationNickname: "SettingsBasicInformationNickname",
  SettingsBasicInformationEmail: "SettingsBasicInformationEmail",
  SettingsBasicInformationSignature: "SettingsBasicInformationSignature",
  SettingsUpdateAvatarTitleUpload1: "SettingsUpdateAvatarTitleUpload1",
  SettingsUpdateAvatarTitleUpload2: "SettingsUpdateAvatarTitleUpload2",
  SettingsUpdateAvatarTitleUpload3: "SettingsUpdateAvatarTitleUpload3",
  SettingsUpdateAvatarMaxFileSize: "SettingsUpdateAvatarMaxFileSize",
  SettingsUpdatePasswordFormPassword: "SettingsUpdatePasswordFormPassword",
  SettingsUpdatePasswordFormConfirmPassword:
    "SettingsUpdatePasswordFormConfirmPassword",
  SettingsUpdatePasswordFormChangeButton:
    "SettingsUpdatePasswordFormChangeButton",
  SettingsUpdatePasswordFormToWeakError:
    "SettingsUpdatePasswordFormToWealError",
  SettingsUpdatePasswordFormBeenUsedError:
    "SettingsUpdatePasswordFormBeenUsedError",
  SettingsUpdatePasswordFormIsTheSameError:
    "SettingsUpdatePasswordFormIsTheSameError",
  SettingsUpdatePasswordFormIsToShort: "SettingsUpdatePasswordFormIsToShort",
  SettingsUpdatePasswordFormPasswordChanged:
    "SettingsUpdatePasswordFormPasswordChanged",
  TwoFactorAuthenticationEnable: "TwoFactorAuthenticationEnable",
  MySettingsNotificationsBasicNotifications:
    "MySettingsNotificationsBasicNotifications",
  MySettingsNotificationsBasicNotificationsDescription:
    "MySettingsNotificationsBasicNotificationsDescription",
  MySettingsNotificationsAdvancedNotifications:
    "MySettingsNotificationsAdvancedNotifications",
  MySettingsNotificationsAdvancedNotificationsDescription:
    "MySettingsNotificationsAdvancedNotificationsDescription",
  MySettingsNotificationsCreateARule: "MySettingsNotificationsCreateARule",
  MySettingsNotificationsTips: "MySettingsNotificationsTips",
  MySettingsNotificationsRadioGroupAllNotifications:
    "MySettingsNotificationsRadioGroupAllNotifications",
  MySettingsNotificationsRadioGroupSmartNotifications:
    "MySettingsNotificationsRadioGroupSmartNotifications",
  MySettingsNotificationsRadioGroupOff: "MySettingsNotificationsRadioGroupOff",
  SettingsTitleMySettings: "My Settings",
  SettingsTitleTeamSettings: "Team Settings",
  SettingsTitleCompanySettings: "Company settings",
  SettingsTitleHelp: "Help",
  SettingsCategoryTitleAccount: "SettingsCategoryTitleAccount",
  SettingsCategoryTitleNotifications: "SettingsCategoryTitleNotifications",
  SettingsCategoryDelegationRules: "SettingsCategoryDelegationRules",
  SettingsCategoryTitleCompany: "SettingsCategoryTitleCompany",
  SettingsCategoryTitleManageTeammates: "SettingsCategoryTitleManageTeammates",
  SettingsCategoryTitleBasicInfo: "SettingsCategoryTitleBasicInfo",
  SettingsCategoryTitleWorkingHours: "SettingsCategoryTitleWorkingHours",
  SettingsCategoryTitleToogles: "SettingsCategoryTitleToogles",
  SettingsCategoryTitleOptOutFlow: "SettingsCategoryTitleOptOutFlow",
  SettingsCategoryTitleRulesAndAutomation:
    "SettingsCategoryTitleRulesAndAutomation",
  SettingsCategoryTitleSettings: "SettingsCategoryTitleSettings",
  SettingsCategoryTitleTouchpoints: "SettingsCategoryTitleTouchpoints",
  InboxAssignToPanelActions: "InboxAssignToPanelActions",
  AuthForgetPasswordRequestPasswordDescription:
    "AuthForgetPasswordRequestPasswordDescription",
  AuthForgetPasswordRequestPasswordSemdLink:
    "AuthForgetPasswordRequestPasswordSemdLink",
  AuthForgetPasswordRequestPasswordGoBack:
    "AuthForgetPasswordRequestPasswordGoBack",
  AuthForgetPasswordPlaceholderAnimateEmail:
    "AuthForgetPasswordPlaceholderAnimateEmail",
  AuthHeaderTitle: "AuthHeaderTitle",
  AuthHeaderTitleDescription: "AuthHeaderTitleDescription",
  AuthLoginFormWrongLoginPage: "AuthLoginFormWrongLoginPage",
  AuthLoginFormMarketingAutomation: "AuthLoginFormMarketingAutomation",
  AuthLoginFormHidePassword: "AuthLoginFormHidePassword",
  AuthLoginFormShowPassword: "AuthLoginFormShowPassword",
  AuthLoginFormPlaceholderEmail: "AuthLoginFormPlaceholderEmail",
  AuthLoginFormPlaceholderPassword: "AuthLoginFormPlaceholderPassword",
  AuthLoginFormSubmit: "AuthLoginFormSubmit",
  AuthLoginFormPassworResetText: "AuthLoginFormPassworResetText",
  AuthLoginFormPassworResetLink: "AuthLoginFormPassworResetLink",
  AuthLoginFormAllRightsReserved: "AuthLoginFormAllRightsReserved",
  AuthLoginFormPrivacy: "AuthLoginFormPrivacy",
  AuthLoginFormAnd: "AuthLoginFormAnd",
  AuthLoginFormTerms: "AuthLoginFormTerms",
  AuthSignupHeaderDescription: "AuthSignupHeaderDescription",
  AuthLoginFooterDescription: "AuthLoginFooterDescription",
  AuthLoginFooterLink: "AuthLoginFooterLink",
  CompanySettingsWorkingHoursExceptionsOptionHeaderDate:
    "CompanySettingsWorkingHoursExceptionsOptionHeaderDate",
  AuthSignupFormPlaceholderEmail: "AuthSignupFormPlaceholderEmail",
  AuthSignupFormPlaceholderPassword: "AuthSignupFormPlaceholderPassword",
  AuthSignupFormPlaceholderRepassword: "AuthSignupFormPlaceholderRepasswordd",
  AuthSignupFormCheckboxTerms: "AuthSignupFormCheckboxTerms",
  AuthSignupFormSubmit: "AuthSignupFormSubmit",
  SettingTouchpointErrorMessage: "SettingTouchpointErrorMessage",
  SettingIntegrationsErrorMessage: "SettingIntegrationsErrorMessage",
  TouchpointWizardFooterSkip: "TouchpointWizardFooterSkip",
  TouchpointWizardFooterNext: "TouchpointWizardFooterNext",
  TouchpointWizardFooterBack: "TouchpointWizardFooterBack",
  TouchpointWizardNewTouchpointTitleTheming:
    "TouchpointWizardNewTouchpointTitleTheming",
  TouchpointWizardNewTouchpointTitleThemingDescription:
    "TouchpointWizardNewTouchpointTitleThemingDescription",
  NewTouchpointTypeLabelTouchpointType: "NewTouchpointTypeLabelTouchpointType",
  NewTouchpointTypeMobileOnsiteFlow: "NewTouchpointTypeMobileOnsiteFlow",
  NewTouchpointTypeMobileOnsiteFlowDescription:
    "NewTouchpointTypeMobileOnsiteFlowDescription",
  NewTouchpointTypeMobileOnsiteWrap: "NewTouchpointTypeMobileOnsiteWrap",
  NewTouchpointTypeMobileOnsiteWrapDescription:
    "NewTouchpointTypeMobileOnsiteWrapDescription",
  NewTouchpointTypeFacebookAd: "NewTouchpointTypeFacebookAd",
  NewTouchpointTypeFacebookAdDescription:
    "NewTouchpointTypeFacebookAdDescription",
  NewTouchpointTypeInApp: "NewTouchpointTypeInApp",
  NewTouchpointTypeInAppDescription: "NewTouchpointTypeInAppDescription",
  NewTouchpointTypeQRCode: "NewTouchpointTypeQRCode",
  NewTouchpointTypeQRCodeDescription: "NewTouchpointTypeQRCodeDescription",
  NewTouchpointTypeBanner: "NewTouchpointTypeBanner",
  NewTouchpointTypeBannerDescription: "NewTouchpointTypeBannerDescription",
  NewTouchpointTypeCommingSoon: "NewTouchpointTypeCommingSoon",
  TouchpointWizardNewTouchpointTitleNewTouchpoint:
    "TouchpointWizardNewTouchpointTitleNewTouchpoint",
  TouchpointWizardNewTouchpointTitleNewTouchpointDescription:
    "TouchpointWizardNewTouchpointTitleNewTouchpointDescription",
  //Touchpoint labels
  TouchpointWizardNewTouchpointLabelNewTouchpoint:
    "TouchpointWizardNewTouchpointLabelNewTouchpoint",
  TouchpointWizardNewTouchpointLabelNewTouchpointDescription:
    "TouchpointWizardNewTouchpointLabelNewTouchpointDescription",
  TouchpointWizardNewTouchpointLabelNessanger:
    "TouchpointWizardNewTouchpointLabelNessanger",
  TouchpointWizardNewTouchpointLabelTouchpointType:
    "TouchpointWizardNewTouchpointLabelTouchpointType",
  TouchpointWizardJourneySettingsLabelTreshold:
    "TouchpointWizardJourneySettingsLabelTreshold",
  TouchpointWizardJourneySettingsLabelSendButtonLabel:
    "TouchpointWizardJourneySettingsLabelSendButtonLabel",
  TouchpointWizardJourneySettingsLabelName:
    "TouchpointWizardJourneySettingsLabelName",
  TouchpointWizardJourneySettingsLabelInputPlaceholder:
    "TouchpointWizardJourneySettingsLabelInputPlaceholder",
  TouchpointWizardJourneySettingsLabelDisclaimerText:
    "TouchpointWizardJourneySettingsLabelDisclaimerText",
  TouchpointWizardJourneySettingsLabelDescription:
    "TouchpointWizardJourneySettingsLabelDescription",
  TouchpointWizardJourneySettingsLabelContainerId:
    "TouchpointWizardJourneySettingsLabelContainerId",
  TouchpointWizardJourneySettingsLabelAgreement:
    "TouchpointWizardJourneySettingsLabelAgreement",
  TouchpointWizardJourneySettingsLabelTouchpointMinimizedImage:
    "TouchpointWizardJourneySettingsLabelTouchpointMinimizedImage",
  TouchpointWizardJourneySettingsLabelTouchpointShadow:
    "TouchpointWizardJourneySettingsLabelTouchpointShadow",
  TouchpointWizardJourneySettingsLabelTouchpointMsisdn:
    "TouchpointWizardJourneySettingsLabelTouchpointMsisdn",
  TouchpointWizardJourneySettingsLabelQrCode:
    "TouchpointWizardJourneySettingsLabelQrCode",
  TouchpointWizardJourneySettingsLabelPhoneNumber:
    "TouchpointWizardJourneySettingsLabelPhoneNumber",
  TouchpointWizardJourneySettingsLabelInterests:
    "TouchpointWizardJourneySettingsLabelInterests",
  TouchpointWizardJourneySettingsLabelInterestsKey:
    "TouchpointWizardJourneySettingsLabelInterestsKey",
  TouchpointWizardJourneySettingsLabelInterestsValue:
    "TouchpointWizardJourneySettingsLabelInterestsValue",

  TouchpointWizardJourneySettingsLabelShowOnMobile:
    "TouchpointWizardJourneySettingsLabelShowOnMobile",
  TouchpointWizardJourneySettingsLabelShowOnDesktop:
    "TouchpointWizardJourneySettingsLabelShowOnDesktop",
  TouchpointWizardJourneySettingsLabelMobile:
    "TouchpointWizardJourneySettingsLabelMobile",
  TouchpointWizardJourneySettingsLabelDesktop:
    "TouchpointWizardJourneySettingsLabelDesktop",
  TouchpointWizardJourneySettingsLabelActivation:
    "TouchpointWizardJourneySettingsLabelActivation",
  TouchpointWizardJourneySettingsLabelActivationMoDescription:
    "TouchpointWizardJourneySettingsLabelActivationMoDescription",
  TouchpointWizardJourneySettingsLabelActivationMtDescription:
    "TouchpointWizardJourneySettingsLabelActivationMtDescription",
  TouchpointWizardJourneySettingsLabelMoFlow:
    "TouchpointWizardJourneySettingsLabelMoFlow",
  TouchpointWizardJourneySettingsLabelThanksMtMessage:
    "TouchpointWizardJourneySettingsLabelThanksMtMessage",
  TouchpointWizardJourneySettingsLabelMtFlow:
    "TouchpointWizardJourneySettingsLabelMtFlow",
  TouchpointWizardJourneySettingsLabelMoFlowDescription:
    "TouchpointWizardJourneySettingsLabelMoFlowDescription",
  TouchpointWizardJourneySettingsDescriptionFlow:
    "TouchpointWizardJourneySettingsDescriptionFlow",
  TouchpointWizardJourneySettingsTooltipMo:
    "TouchpointWizardJourneySettingsTooltipMo",
  TouchpointWizardJourneySettingsTooltipMt:
    "TouchpointWizardJourneySettingsTooltipMt",
  TouchpointWizardJourneySettingsTooltipMoWithLlLabel:
    "TouchpointWizardJourneySettingsTooltipMoWithLlLabel",
  TouchpointWizardJourneyDescription: "TouchpointWizardJourneyDescription",
  TouchpointTitle: "TouchpointTitle",
  TouchpointDescription: "TouchpointDescription",
  //Touchpoint Default Values
  TouchpointWizardJourneySettingsLabelNameDefaultValue:
    "TouchpointWizardJourneySettingsLabelNameDefaultValue",
  TouchpointWizardJourneySettingsLabelSendButtonLabelDefaultValue:
    "TouchpointWizardJourneySettingsLabelSendButtonLabelDefaultValue",
  TouchpointWizardJourneySettingsLabelInputPlaceholderDefaultValue:
    "TouchpointWizardJourneySettingsLabelInputPlaceholderDefaultValue",
  UploadInputTitlePart1: "UploadInputTitlePart1",
  UploadInputTitlePart2: "UploadInputTitlePart2",
  UploadInputTitlePart3: "UploadInputTitlePart3",
  UploadInputInfo: "UploadInputInfo",
  AnalogClockModeAm: "AnalogClockModeAm",
  AnalogClockModePm: "AnalogClockModePm",
  DatePickerPlaceholderFrom: "DatePickerPlaceholderFrom",
  DatePickerPlaceholderTo: "DatePickerPlaceholderTo",
  ConversationLastMessageConversationIs:
    "ConversationLastMessageConversationIs",
  ConversationLastMessageConversationCloseAs:
    "ConversationLastMessageConversationCloseAs",
  ConversationLastMessageConversationOpenedBy:
    "ConversationLastMessageConversationOpenedBy",
  ConversationLastMessageConversationClosedBy:
    "ConversationLastMessageConversationClosedBy",
  ConversationLastMessageConversationUnsupportedMessage:
    "ConversationLastMessageConversationUnsupportedMessage",
  InboxAssignToPanelSelectAll: "InboxAssignToPanelSelectAll",
  InboxMediaMessageDropdownDownload: "InboxMediaMessageDropdownDownload",
  FileUploaderTitle: "FileUploaderTitle",
  TemplatesContent: "TemplatesContent",
  TemplatesHeader: "TemplatesHeader",
  TemplatesMenageTemplates: "TemplatesMenageTemplates",
  TemplatesFirstSubHeader: "TemplatesFirstSubHeader",
  TemplatesFirstSecondHeader: "TemplatesFirstSecondHeader",
  TemplatesIconDeleteTooltipText: "TemplatesIconDeleteTooltipText",
  TemplatesIconCantTooltipText: "TemplatesIconCantTooltipText",
  TemplatesPopupCloseButton: "TemplatesPopupCloseButton",
  TemplatesType: "TemplatesType",
  TemplatesGeneral: "TemplatesGeneral",
  TemplatesCreated: "TemplatesCreated",
  TemplatesLastModified: "TemplatesLastModified",
  TemplatesNotificationFailedToSave: "TemplatesNotificationFailedToSave",
  TemplatesNotificationSaved: "TemplatesNotificationSaved",
  TemplatesFailedToLoadTemplate: "TemplatesFailedToLoadTemplate",
  TemplatesModalHeaderEditTemplate: "TemplatesModalHeaderEditTemplate",
  TemplatesModalHeaderPreviewTemplate: "TemplatesModalHeaderPreviewTemplate",
  TemplatesModalHeaderCreateTemplate: "TemplatesModalHeaderCreateTemplate",
  TemplatesModalFooterButtonClose: "TemplatesModalFooterButtonClose",
  TemplatesModalFooterButtonCancel: "TemplatesModalFooterButtonCancel",
  TemplatesModalFooterButtonSave: "TemplatesModalFooterButtonSave",
  TemplatesModalFooterButtonAdd: "TemplatesModalFooterButtonAdd",
  TemplatesListPanelKeysButtonEdit: "TemplatesListPanelKeysButtonEdit",
  TemplatesListPanelKeysButtonPreview: "TemplatesListPanelKeysButtonPreview",
  TemplatesListPanelKeysButtonDelete: "TemplatesListPanelKeysButtonDelete",
  TemplateType: "TemplateType",
  TemplateContent: "TemplateContent",
  DeleteTemplateErrorMessage: "DeleteTemplateErrorMessage",
  DeleteTemplateSuccessMessage: "DeleteTemplateSuccessMessage",
  NotifyNewMessage: "NotifyNewMessage",
  NotifyConversationMessagesError: "NotifyConversationMessagesError",
  NotifyConversationError: "NotifyConversationError",
  CopyConversationLink: "CopyConversationLink",
  CopiedConversationLink: "CopiedConversationLink",
  NotificationsFileIsTooBig: "NotificationsFileIsTooBig",
  NotificationErrorOnUpload: "NotificationErrorOnUpload",
  NotificationIncorrectFileType: "NotificationIncorrectFileType",
  LastMessageVideo: "LastMessageVideo",
  LastMessageAudio: "LastMessageAudio",
  LastMessageVoice: "LastMessageVoice",
  LastMessageImage: "LastMessageImage",
  LastMessageLocation: "LastMessageLocation",
  LastMessageContact: "LastMessageContact",
  LastMessageDocument: "LastMessageDocument",
  MessageForDeletedMessage: "MessageForDeletedMessage",
  NotifyConversationFileUploadError: "NotifyConversationFileUploadError",
  TouchpointWizardJourneySettingsLabelTouchpointIgnoreOfficeHours:
    "TouchpointWizardJourneySettingsLabelTouchpointIgnoreOfficeHours",
  TouchpointWizardJourneySettingsLabelActivateGaTracking:
    "TouchpointWizardJourneySettingsLabelActivateGaTracking",
  NotificationErrorOnSave: "NotificationErrorOnSave",
  NotificationErrorOnSuccessfullSaveWorkingHours:
    "NotificationErrorOnSuccessfullSaveWorkingHours",
  NotificationMessageTooLong: "NotificationMessageTooLong",
  LoginErrorNoAppConnectedWithAccount: "LoginErrorNoAppConnectedWithAccount",
  DeleteRuleTooltipText: "DeleteRuleTooltipText",
  FailedToLoadRule: "FailedToLoadRule",
  DeleteRuleSuccessMessage: "DeleteRuleSuccessMessage",
  DeleteRuleFailedMessage: "DeleteRuleFailedMessage",
  SaveRuleSuccessMessage: "SaveRuleSuccessMessage",
  SaveRuleFailedMessage: "SaveRuleFailedMessage",
  RulesFirstSubHeader: "RulesFirstSubHeader",
  RulesSecondSubHeader: "RulesSecondSubHeader",
  RulesHeader: "RulesHeader",
  RulesHeaderTitle: "RulesHeaderTitle",
  RulesNameLabel: "RulesNameLabel",
  RuleColumnName: "RulesColumnName",
  RulesCreated: "RulesCreated",
  RulesDeleteRules: "RulesDeleteRule",
  RulesName: "RulesName",
  RulesLastModifiedAt: "RulesLastModifiedAt",
  RulesNewRule: "RulesNewRule",
  RulesPopupLabelAllowReenter: "RulesPopupLabelAllowReenter",
  RulesPopupLabelIgnoreFrequencyCaps: "RulesPopupLabelIgnoreFrequencyCaps",
  RulesPopupLabelReenterAfterTime: "RulesPopupLabelReenterAfterTime",
  RulesPopupLabelReenterAfterUnitValue: "RulesPopupLabelReenterAfterUnitValue",
  RulesPopupLabelReenterAfterUnitYears: "RulesPopupLabelReenterAfterUnitYears",
  RulesPopupLabelReenterAfterUnitMonths:
    "RulesPopupLabelReenterAfterUnitMonths",
  RulesPopupLabelReenterAfterUnitDays: "RulesPopupLabelReenterAfterUnitDays",
  RulesPopupLabelReenterAfterUnitHours: "RulesPopupLabelReenterAfterUnitHours",
  RulesPopupLabelReenterAfterUnitMinutes:
    "RulesPopupLabelReenterAfterUnitMinutes",
  RulesPopupLabelReenterAfterUnitSeconds:
    "RulesPopupLabelReenterAfterUnitSeconds",
  RulesPopupActionAddAction: "RulesPopupActionAddAction",
  RulesPopupActionAskForAnswerViaMessage:
    "RulesPopupActionAskForAnswerViaMessage",
  RulesPopupActionAskForAnswerViaTemplate:
    "RulesPopupActionAskForAnswerViaTemplate",
  RulesPopupActionSendEmailToAgent: "RulesPopupActionSendEmailToAgent",
  RulesPopupActionAssignAgentByCustomParam:
    "RulesPopupActionAssignAgentByCustomParam",
  RulesPopupActionSendWhatsappMessage: "RulesPopupActionSendWhatsappMessage",
  RulesPopupActionLabelMessage: "RulesPopupActionLabelMessage",
  RulesPopupActionLabelPreviewUrl: "RulesPopupActionLabelPreviewUrl",
  RulesPopupActionLabelSubject: "RulesPopupActionLabelSubject",
  RulesPopupActionLabelAssignBy: "RulesPopupActionLabelAssignBy",
  RulesPopupActionLabelDefault: "RulesPopupActionLabelDefault",
  RulesPopupActionSelectDefault: "RulesPopupActionSelectDefault",
  RulesPopupActionLabelChoices: "RulesPopupActionLabelChoices",
  RulesPopupActionLabelCustomProperty: "RulesPopupActionLabelCustomProperty",
  RulesPopupActionLabelRetryMessage: "RulesPopupActionLabelRetryMessage",
  RulesPopupActionLabelValidator: "RulesPopupActionLabelValidator",
  RulesPopupActionLabelTemplateId: "RulesPopupActionLabelTemplateId",
  RulesPopupActionLabelUnsupportedActionType:
    "RulesPopupActionLabelUnsupportedActionType",
  RulesPopupActionUnknownAction: "RulesPopupActionUnknownAction",
  RulesPopupActionName: "RulesPopupActionName",
  RulesPopupActionYourActionPayload: "RulesPopupActionYourActionPayload",
  RulesPopupLastModified: "RulesPopupLastModified",
  RulesPopupGeneral: "RulesPopupGeneral",
  RulesPopupTrigger: "RulesPopupTrigger",
  RulesPopupCondictions: "RulesPopupCondictions",
  RulesPopupActions: "RulesPopupActions",
  RulesPopupAddTrigger: "RulesPopupAddTrigger",
  RulesPopupAddCondition: "RulesPopupAddCondition",
  RulesPopupConditionEqualTo: "RulesPopupConditionEqualTo",
  RulesPopupConditionNotEqualTo: "RulesPopupConditionNotEqualTo",
  RulesPopupConditionGreaterThan: "RulesPopupConditionGreaterThan",
  RulesPopupConditionLessThan: "RulesPopupConditionLessThan",
  RulesPopupConditionExactlyOrLessThan: "RulesPopupConditionExactlyOrLessThan",
  RulesPopupConditionExactlyOrMoreThan: "RulesPopupConditionExactlyOrMoreThan",
  RulesPopupConditionIsOneOf: "RulesPopupConditionIsOneOf",
  RulesPopupConditionIsNoneOf: "RulesPopupConditionIsNoneOf",
  RulesPopupConditionContains: "RulesPopupConditionContains",
  RulesPopupConditionNotContains: "RulesPopupConditionNotContains",
  UserPopupUserNotFound: "UserPopupUserNotFound",
  UserPopupUserSaved: "UserPopupUserSaved",
  UserPopupUserNotSaved: "UserPopupUserNotSaved",
  UserPopupUserPasswordChangeOk: "UserPopupUserPasswordChangeOk",
  UserPopupUserPasswordChangeFailed: "UserPopupUserPasswordChangeFailed",
  PopupLastModifiedTimeLabel: "PopupLastModifiedTimeLabel",
  PopupPersonalDataLabel: "PopupPersonalDataLabel",
  PopupPasswordSavePasswordButton: "PopupPasswordSavePasswordLabel",
  PopupContactDataLabel: "PopupContactDataLabel",
  PopupPasswordDataLabel: "PopupPasswordDataLabel",
  PopupPasswordRepeatDataLabel: "PopupPasswordRepeatDataLabel",
  UserPopupTeamNotFound: "UserPopupTeamNotFound",
  UserPopupCreateTeamText: "UserPopupCreateTeamText",
  UserPopupTeamSaved: "UserPopupTeamSaved",
  TeamPopupConfirmationTextHeader: "TeamPopupConfirmationTextHeader",
  TeamPopupConfirmationTextDescription: "TeamPopupConfirmationTextDescription",
  UserPopupTeamAddNewTeam: "UserPopupTeamAddNewTeam",
  UserPopupTeamAddTeam: "UserPopupTeamAddTeam",
  UserPopupTeamNewTeam: "UserPopupTeamNewTeam",
  UserPopupTeamSaveError: "UserPopupTeamSaveError",
  UserPopupUnassignUserFromTeam: "UserPopupUnassignUserFromTeam",
  UserPopupTeamUserListError: "UserPopupTeamUserListError",
  UserPopupTeamUserAssignToTeamError: "UserPopupTeamUserAssignToTeamError",
  UserPopupTeamUserUnAssignToTeamError: "UserPopupTeamUserUnAssignToTeamError",
  NameLabel: "NameLabel",
  CreatedAtLabel: "CreatedAtLabel",
  ModifiedAtLabel: "ModifiedAtLabel",
  TeamNameLabel: "TeamNameLabel",
  DescriptionLabel: "DescriptionLabel",
  UsersCountLabel: "UsersCountLabel",
  UsersHeader: "UsersHeader",
  PermissionsLabel: "PermissionsLabel",
  UserPopupLoginLabel: "UserPopupLoginLabel",
  GenericPopupEnabled: "GenericPopupEnabled",
  GenericPopupDisabled: "GenericPopupDisabled",
  NewTeam: "NewTeam",
  UsersTabLabel: "UsersTabLabel",
  TeamMembers: "TeamMembers",
  TeamsTabLabel: "TeamsTabLabel",
  GeneralLabel: "GeneralLabel",
  TeamDecriptionLabel: "TeamDecriptionLabel",
  AvatarUrlLabel: "AvatarUrlLabel",
  UserPopupSendInvitationButton: "UserPopupSendInvitationButton",
  UserPopupSendNextInvitationButton: "UserPopupSendNextInvitationButton",
  UserPopupEmailSentStatus: "UserPopupEmailSentStatus",
  OK: "OK",
  PasswordValidationLenght: "PasswordValidationLenght",
  PasswordValidationLettersNumbers: "PasswordValidationLettersNumbers",
  PasswordValidationPasswordsEquality: "PasswordValidationPasswordsEquality",
  PasswordValidationSpecialChars: "PasswordValidationSpecialChars",
  UserAlreadyExists: "UserAlreadyExists",
  UsersListResetPasswordTooltip: "UsersListResetPasswordTooltip",
  UsersListDeleteUserTooltip: "UsersListDeleteUserTooltip",
  UsersListResetPasswordSuccessNotification:
    "UsersListResetPasswordSuccessNotification",
  UsersListResetPasswordErrotNotification:
    "UsersListResetPasswordErrotNotification",
  UsersListDeleteUserErrorNotification: "UsersListDeleteUserErrorNotification",
  UsersListDeleteTeamErrorNotification: "UsersListDeleteTeamErrorNotification",
  UsersListDeleteUserSuccessNotification:
    "UsersListDeleteUserSuccessNotification",
  UsersListDeleteTeamSuccessNotification:
    "UsersListDeleteTeamSuccessNotification",
  DeleteTeamTooltipText: "DeleteTeamTooltipText",
  DeleteTeamDefaultTeamTooltipText: "DeleteTeamDefaultTeamTooltipText",
  WebhookTestSuccessfull: "WebhookTestSuccessfull",
  WebhookDescription: "WebhookDescription",
  WebhookTestFailed: "WebhookTestFailed",
  WebhookUrlLabel: "WebhookUrlLabel",
  WebhookTestButtonLabel: "WebhookTestButtonLabel",
  WebhookTestCheckboxLabel: "WebhookTestCheckboxLabel",
  WebhookTestEventsSentLabel: "WebhookTestEventsSentLabel",
  WebhookTestFailureLabel: "WebhookTestFailureLabel",
  WebhookTestResultsTitleLabel: "WebhookTestResultsTitleLabel",
  ConfirmButtonLabel: "ConfirmButtonLabel",
  ListLoadError: "ListLoadError",
  OfficeHoursWrongTimeRangeNotification:
    "OfficeHoursWrongTimeRangeNotification",
  GenericListHeaderHide: "GenericListHeaderHide",
  GenericListHeaderShow: "GenericListHeaderShow",
  GenericListPagingNext: "GenericListPagingNext",
  GenericListPagingPrev: "GenericListPagingPrev",
  GenericListConfirmationPopupDefaultText:
    "GenericListConfirmationPopupDefaultText",
  SearchInputComponentPlaceholder: "SearchInputComponentPlaceholder",
  WabaListAppId: "WabaListAppId",
  WabaListModifiedAt: "WabaListModifiedAt",
  WabaListLabelStatus: "WabaListLabelStatus",
  WabaListLabelStackId: "WabaListLabelStackId",
  WabaListActionTriggerRegistrationTooltip:
    "WabaListActionTriggerRegistrationTooltip",
  WabaListActionFinishRegistrationTooltip:
    "WabaListActionFinishRegistrationTooltip",
  WabaSetupOrganisation: "WabaSetupOrganisation",
  WabaSetupAppName: "WabaSetupAppName",
  WabaSetupNamespace: "WabaSetupNamespace",
  WabaSetupStackID: "WabaSetupStackID",
  WabaSetupVerificationMethod: "WabaSetupVerificationMethod",
  WabaSetupVerificationCode: "WabaSetupVerificationCode",
  WabaSetupDefaultLanguageLabel: "WabaSetupDefaultLanguageLabel",
  WabaSetupCertificate: "WabaSetupCertificate",
  WabaTriggerRegistrationPlaceholderNotChangedValues:
    "WabaTriggerRegistrationPlaceholderNotChangedValues",
  WabaSetupHeader: "WabaSetupHeader",
  WabaSetupSelectVerification: "WabaSetupSelectVerification",
  WabaSetupSelectLanguageLabel: "WabaSetupSelectLanguageLabel",
  WabaSetupFailedToSetupWaba: "WabaSetupFailedToSetupWaba",
  WabaSetupWabaSuccesfullyCreated: "WabaSetupWabaSuccesfullyCreated",
  WabaTriggerRegistrationSaveButtonLabel: "WabaTriggerRequestSaveButtonLabel",
  WabaFinishRegistrationSaveButtonLabel:
    "WabaFinishRegistrationSaveButtonLabel",
  ContactLabelName: "ContactLabelName",
  ContactLabelPhone: "ContactLabelPhone",
  ContactLabelEmail: "ContactLabelEmail",
  ContactLabelClickToDownload: "ContactLabelClickToDownload",
  MessageInputSendUrlImageUrl: "MessageInputSendUrlImageUrl",
  MessageInputSendUrlImageCaption: "MessageInputSendUrlImageCaption",
  MessageInputSendUrlImageAlt: "MessageInputSendUrlImageAlt",
  MessageInputSendUrlImageError: "MessageInputSendUrlImageError",
  MessageLocalizationNotSupportedErrorMessage:
    "MessageLocalizationNotSupportedErrorMessage",
  MessageLocalizationDeterminationErrorMessage:
    "MessageLocalizationDeterminationErrorMessage",
  InputMessageSendContactSuccess: "InputMessageSendContactSuccess",
  InputMessageSendContactFailed: "InputMessageSendContactFailed",
  InputMessageImportVcardFailed: "InputMessageImportVcardFailed",
  ClientsTabGeneral: "ClientsTabGeneral",
  ClientsCreateNewClient: "ClientsCreateNewClient",
  ClientsTabContactInfo: "ClientsTabContactInfo",
  ClientsTabContactUser: "ClientsTabContactUser",
  ClientsTabMetaInfo: "ClientsTabMetaInfo",
  ClientsName: "ClientsName",
  ClientsOrganisation: "ClientsOrganisation",
  ClientsWebpageUrl: "ClientsWebpageUrl",
  ClientsPhone: "ClientsPhone",
  ClientsLanguage: "ClientsLanguage",
  ClientsCountry: "ClientsCountry",
  CompanyCountry: "CompanyCountry",
  ClientsStreetName: "ClientsStreetName",
  ClientsCity: "ClientsCity",
  ClientsZipCode: "ClientsZipCode",
  ClientsBusinessVertical: "ClientsBusinessVertical",
  ClientsTimezone: "ClientsTimezone",
  ClientsAbout: "ClientsAbout",
  ClientsBusinessDescription: "ClientsBusinessDescription",
  ClientsUseCase: "ClientsUseCase",
  ClientsUserPhone: "ClientsUserPhone",
  ClientsEmail: "ClientsEmail",
  ClientsUserName: "ClientsUserName",
  ClientsModifiedAt: "ClientsModifiedAt",
  ClientsStatus: "ClientsStatus",
  ClientsNotificationFailedToLoadClient:
    "ClientsNotificationFailedToLoadClient",
  ClientsNotificationFailedToSaveClientData:
    "ClientsNotificationFailedToSaveClientData",
  ClientsNotificationSuccesfullySavedClientData:
    "ClientsNotificationSuccesfullySavedClientData",
  ClientsEditClientData: "ClientsEditClientData",
  PartnerLegalName: "PartnerLegalName",
  PartnersName: "PartnersName",
  PartnersDefaultOrganisationLabel: "PartnersDefaultOrganisationLabel",
  PartnersLicense: "PartnersLicense",
  PartnersDefaultOrganisation: "PartnersDefaultOrganisation",
  PartnersStatus: "PartnersStatus",
  PartnersOrderDocument: "PartnersOrderDocument",
  PartnersSalesUserId: "PartnersSalesUserId",
  PartnersFinanceUserId: "PartnersFinanceUserId",
  PartnersOperationUserId: "PartnersOperationUserId",
  PartnersDialogSalesUserId: "PartnersDialogSalesUserId",
  PartnersBusinessName: "PartnersBusinessName",
  PartnersOrganisation: "PartnersOrganisation",
  PartnersNotes: "PartnersNotes",
  PartnersMarkets: "PartnersMarkets",
  PartnersTimezone: "PartnersTimezone",
  PartnersDescription: "PartnersDescription",
  PartnersWebsite: "PartnersWebsite",
  PartnersPhoneNumber: "PartnersPhoneNumber",
  PartnersEmail: "PartnersEmail",
  PartnersLanguage: "PartnersLanguage",
  PartnersCountry: "PartnersCountry",
  PartnersStreetName: "PartnersStreetName",
  PartnersCity: "PartnersCity",
  PartnersZipCode: "PartnersZipCode",
  PartnersState: "PartnersState",
  PartnersModifiedAt: "PartnersModifiedAt",
  PartnersFailedToSaveData: "PartnersFailedToSaveData",
  PartnersSuccesfullySavedData: "PartnersSuccesfullySavedData",
  PartnersFailedToLoadData: "PartnersFailedToLoadData",
  PartnersTabBusinessInfo: "PartnersTabBusinessInfo",
  PartnersTabContactInfo: "PartnersTabContactInfo",
  PartnersTabGeneral: "PartnersTabGeneral",
  PartnersEditPartner: "PartnersEditPartner",
  PartnersCreateNewPartner: "PartnersCreateNewPartner",
  PartnerUserFinance: "PartnerUserFinance",
  PartnerUserSales: "PartnerUserSales",
  PartnerUserDialogSales: "PartnerUserDialogSales",
  PartnerUserOperation: "PartnerUserOperation",
  PartnerUserPassword: "PartnerUserPassword",
  PartnerUserPicture: "PartnerUserPicture",
  PartnerUserType: "PartnerUserType",
  PartnerUserCreate: "PartnerUserCreate",
  PartnerUserFailedToCreate: "PartnerUserFailedToCreate",
  PartnerTriggerActivationTooltip: "PartnerTriggerActivationTooltip",
  PartnerApproveTooltip: "PartnerApproveTooltip",
  PartnerTriggerActivationErrorTooltip: "PartnerTriggerActivationErrorTooltip",
  PartnerApproveErrorTooltip: "PartnerApproveErrorTooltip",
  PartnerApproveSuccessMessage: "PartnerApproveSuccessMessage",
  PartnerApproveErrorMessage: "PartnerApproveErrorMessage",
  PartnerTriggerActivationSuccessMessage:
    "PartnerTriggerActivationSuccessMessage",
  PartnerTriggerActivationErrorMessage: "PartnerTriggerActivationErrorMessage",
  SmartPanelEmojiPicker: "SmartPanelEmojiPicker",
  SmartPanelUploadFile: "SmartPanelUploadFile",
  SmartPanelShareLocation: "SmartPanelShareLocation",
  SmartPanelSendImage: "SmartPanelSendImage",
  SmartPanelSendTemplate: "SmartPanelSendTemplate",
  SmartPanelSendButton: "SmartPanelSendButton",
  ProjectName: "ProjectName",
  ProjectLicenseModel: "ProjectLicenseModel",
  ProjectStatus: "ProjectStatus",
  ProjectFailedToSaveData: "ProjectFailedToSaveData",
  ProjectSuccesfullySavedData: "ProjectSuccesfullySavedData",
  ProjectFailedToLoadData: "ProjectFailedToLoadData",
  ProjectLabelProject: "ProjectLabelProject",
  ProjectEditData: "ProjectEditData",
  ProjectsCreateNewProject: "ProjectsCreateNewProject",
  WabaAccountName: "WabaAccountName",
  WabaAccountCreateNewWaba: "WabaAccountCreateNewWaba",
  WabaAccountExternalId: "WabaAccountExternalId",
  WabaAccountFbBusinessId: "WabaAccountFbBusinessId",
  WabaAccountFbBusinessIdStatus: "WabaAccountFbBusinessIdStatus",
  WabaAccountOnBehalfStatus: "WabaAccountOnBehalfStatus",
  WabaAccountNamespace: "WabaAccountNamespace",
  WabaAccountTimezoneId: "WabaAccountTimezoneId",
  WabaAccountPrimaryFundingId: "WabaAccountPrimaryFundingId",
  WabaAccountFailedToSaveData: "WabaAccountToSaveData",
  WabaAccountSuccesfullySavedData: "WabaAccountSuccesfullySavedData",
  WabaAccountNewNameRequested: "WabaAccountNewNameRequested",
  WabaAccountFailedToLoadData: "WabaAccountFailedToLoadData",
  WabaAccountSubmitWabaAccount: "WabaAccountSubmitWabaAccount",
  WabaAccountSubmitWabaAccountTooltipText:
    "WabaAccountSubmitWabaAccountTooltipText",
  WabaAccountSubmitWabaAccountTooltipTextError:
    "WabaAccountSubmitWabaAccountTooltipTextError",
  WabaAccountSubmitWabaAccountTooltipTextSaveFirst:
    "WabaAccountSubmitWabaAccountTooltipTextSaveFirst",
  WabaAccountSubmitWabaAccountFailed: "WabaAccountSubmitWabaAccountFailed",
  WabaAccountSubmitWabaAccountSucces: "WabaAccountSubmitWabaAccountSuccess",
  WabaAccountUpdateTemplateNamespace: "WabaAccountUpdateTemplateNamespace",
  WabaAccountUpdateTemplateNamespaceTooltipText:
    "WabaAccountUpdateTemplateNamespaceTooltipText",
  WabaAccountUpdateTemplateNamespaceFailed:
    "WabaAccountUpdateTemplateNamespaceFailed",
  WabaAccountUpdateTemplateNamespaceSucces:
    "WabaAccountUpdateTemplateNamespaceSucces",
  WabaAccountSynchronizeStatus: "WabaAccountSynchronizeStatus",
  WabaAccountSynchronizeStatusTooltipText:
    "WabaAccountSynchronizeStatusTooltipText",
  WabaAccountSynchronizeStatusSucces: "WabaAccountSynchronizeStatusSucces",
  WabaAccountSynchronizeStatusFailed: "WabaAccountSynchronizeStatusFailed",
  WabaAccountTimezoneListFailedToLoad: "WabaAccountTimezoneListFailedToLoad",
  EditWabaAccount: "EditWabaAccount",
  WabaDeleteWabaAccount: "WabaDeleteWabaAccount",
  WabaSuccesfullyDeletedWabaAccount: "WabaSuccesfullyDeletedWabaAccount",
  WabaFailedToDeleteWabaAccount: "WabaFailedToDeleteWabaAccount",
  CopyToClipboard: "CopyToClipboard",
  MessageInputSendUrlLocationUrl: "MessageInputSendUrlLocationUrl",
  MessageInputSendUrlLocationName: "MessageInputSendUrlLocationName",
  MessageInputSendUrlLocationAlt: "MessageInputSendUrlLocationAlt",
  MessageInputSendUrlLocationError: "MessageInputSendUrlLocationError",
  ChannelsSubmit: "ChannelsSubmit",
  ChannelsSubmitTooltipText: "ChannelsSubmitTooltipText",
  ChannelsSubmitTooltipTextError: "ChannelsSubmitTooltipTextError",
  ChannelsSubmitTooltipTextSaveFirst: "ChannelsSubmitTooltipTextSaveFirst",
  ChannelsSubmitFailed: "ChannelsSubmitFailed",
  ChannelsSubmitSucces: "ChannelsSubmitSucces",
  ChannelsUpdateStatus: "ChannelsUpdateStatus",
  ChannelsUpdateStatusTooltipText: "ChannelsUpdateStatusTooltipText",
  ChannelsUpdateStatusFailed: "ChannelsUpdateStatusFailed",
  ChannelsUpdateStatusSucces: "ChannelsUpdateStatusSucces",
  ChannelsWabaAccountId: "ChannelsWabaAccountId",
  ChannelsPictureUrl: "ChannelsPictureUrl",
  ChannelsAboutText: "ChannelsAboutText",
  ChannelsBusinessVertical: "ChannelsBusinessVertical",
  ChannelsTimezone: "ChannelsTimezone",
  ChannelsBusinessDescription: "ChannelsBusinessDescription",
  ChannelsUseCaseDescription: "ChannelsUseCaseDescription",
  ChannelsPhoneNumber: "ChannelsPhoneNumber",
  ChannelsPhoneName: "ChannelsPhoneName",
  ChannelsWasInUse: "ChannelsWasInUse",
  ChannelsVerificationMethod: "ChannelsVerificationMethod",
  ChannelsCertificate: "ChannelsCertificate",
  ChannelsDefaultLanguage: "ChannelsDefaultLanguage",
  ChannelsFailedToSaveData: "ChannelsFailedToSaveData",
  ChannelsFailedToLoadDefaultLanguageList:
    "ChannelsFailedToLoadDefaultLanguageList",
  ChannelsSuccesfullySavedData: "ChannelsSuccesfullySavedData",
  ChannelsFailedToLoadData: "ChannelsFailedToLoadData",
  ChannelsEditData: "ChannelsEditData",
  ChannelsSetupInfo: "ChannelsSetupInfo",
  ChannelsProfileInfo: "ChannelsProfileInfo",
  ChannelsNewChannel: "ChannelsNewChannel",
  FailedToLoadPartnerChannel: "FailedToLoadPartnerChannel",
  PartnerChannelClientLegalName: "PartnerChannelClientLegalName",
  PartnerChannelFbIdName: "PartnerChannelFbIdName",
  PartnerChannelFbPrimaryPageName: "PartnerChannelFbPrimaryPageName",
  PartnerChannelChannelId: "PartnerChannelChannelId",
  PartnerChannelWabaChannelStatus: "PartnerChannelWabaChannelStatus",
  PartnerChannelWabaAccountStatus: "PartnerChannelWabaAccountStatus",
  PartnerChannelMessageOnBehalfStatus: "PartnerChannelMessageOnBehalfStatus",
  PartnerChannelProjectName: "PartnerChannelProjectName",
  PartnerChannelClientName: "PartnerChannelClientName",
  PartnerChannelWrongFbID: "PartnerChannelWrongFbID",
  Clients: "Clients",
  Projects: "Projects",
  WabaAccount: "WabaAccount",
  OnBehalfBusinessInfo: "OnBehalfBusinessInfo",
  Integration: "Integration",
  Parameters: "Parameters",
  ShowFbBusinessAccountInfo: "ShowFbBusinessAccountInfo",
  FacebookBusinessInfoPreview: "FacebookBusinessInfoPreview",
  FacebookBusinessInfoPreviewFailedToLoad:
    "FacebookBusinessInfoPreviewFailedToLoad",
  FastWabaCreatePopupHeader: "FastWabaCreatePopupHeader",
  FastWabaCreatePopupFailedToLoadData: "FastWabaCreatePopupFailedToLoadData",
  FastWabaCreatePopupSelectExisitingClient:
    "FastWabaCreatePopupSelectExisitingClient",
  FastWabaCreatePopupAddNewClient: "FastWabaCreatePopupAddNewClient",
  FastWabaCreatePopupSelectExistingWabaAccount:
    "FastWabaCreatePopupSelectExistingWabaAccount",
  FastWabaCreatePopupAddNewWabaAccount: "FastWabaCreatePopupAddNewWabaAccount",
  FastWabaCreatePopupAddNewProject: "FastWabaCreatePopupAddNewProject",
  ChannelsApiUserEmail: "ChannelsApiUserEmail",
  ChannelsWebhookUrl: "ChannelsWebhookUrl",
  ContactNameFieldLabel: "ContactNameFieldLabel",
  ContactPhonesFieldLabel: "ContactPhonesFieldLabel",
  ContactEmailsFieldLabel: "ContactEmailsFieldLabel",
  ContactUrlsFieldLabel: "ContactUrlsFieldLabel",
  ContactAddressesFieldLabel: "ContactAddressesFieldLabel",
  Contact_name_first_name: "Contact_name_first_name",
  Contact_name_last_name: "Contact_name_last_name",
  Contact_name_formatted_name: "Contact_name_formatted_name",
  Contact_emails_email: "Contact_emails_email",
  Contact_emails_type: "Contact_emails_type",
  Contact_phones_phone: "Contact_phones_phone",
  Contact_phones_type: "Contact_phones_type",
  Contact_urls_url: "Contact_urls_url",
  Contact_urls_type: "Contact_urls_type",
  Contact_addresses_street: "Contact_addresses_street",
  Contact_addresses_city: "Contact_addresses_city",
  Contact_addresses_state: "Contact_addresses_state",
  Contact_addresses_zip: "Contact_addresses_zip",
  Contact_addresses_country: "Contact_addresses_country",
  Contact_addresses_country_code: "Contact_addresses_country_code",
  Contact_addresses_type: "Contact_addresses_type",
  ContactUploadVCardButtonLabel: "ContactUploadVCardButtonLabel",
  SmartPanelContactIconTooltip: "SmartPanelContactIconTooltip",
  ContactFormErrorMessageFormattedNameNotFound:
    "ContactFormErrorMessageFormattedNameNotFound",
  ClientContactUserEmailIsTooLong: "ClientContactUserEmailIsTooLong",
  ClientMetaInfoDecriptionIsTooLong: "ClientMetaInfoDecriptionIsTooLong",
  ChannelProfileInfoAboutTextIsTooLong: "ChannelProfileInfoAboutTextIsTooLong",
  ChannelSubimissionInfoNameWarningSpecialChars:
    "ChannelSubimissionInfoNameWarningSpecialChars",
  ChannelSubimissionInfoNameWarningCapitalized:
    "ChannelSubimissionInfoNameWarningCapitalized",
  ChannelSubimissionInfoNameWarningSpaces:
    "ChannelSubimissionInfoNameWarningSpaces",
  ChannelSubimissionInfoNameWarningUrlFormat:
    "ChannelSubimissionInfoNameWarningUrlFormat",
  Unknown: "Unknown",
  ParterUsersPasswordMinLengthErrorInformation:
    "ParterUsersPasswordMinLengthErrorInformation",
  SomethingWentWrongError: "SomethingWentWrongError",
  PipeyRenemberToSave: "PipeyRenemberToSave",
  PipeyFillRequiredData: "PipeyFillRequiredData",
  Step: "Step",
};

export default keys;
