import {
  MPInstanceAds,
  MPInstanceAdsV2,
} from "@/services/api/RequestAxios/RequestAxiosAds";

import * as Types from "./insights.types";

const insightsStatsURL = ({ clientId }: { clientId: string }) =>
  `/clients/${clientId}/insights`;
const sourcesURL = ({ clientId }: { clientId: string }) =>
  `/clients/${clientId}/sources`;
const setSourceNameURL = ({
  sourceId,
  clientId,
}: {
  sourceId: string;
  clientId: string;
}) => `/clients/${clientId}/sources/${sourceId}`;
const insightAllowedURL = ({ clientId }: { clientId: string }) =>
  `/insights-allowed/${clientId}`;

const externalEventURL = ({ clientId }: { clientId: string }) =>
  `/clients/${clientId}/external-events`;
const messagingEventsUrl = ({ clientId }: { clientId: string }) =>
  `/clients/${clientId}/actions/`;
const actionSourceDetailsUrl = ({ clientId }: { clientId: string }) =>
  `/clients/${clientId}/actions/source_details`;
const exportContactsUrl = ({ clientId }: { clientId: string }) =>
  `/clients/${clientId}/export_contacts`;
const exportContactsBackgroundUrl = ({ clientId }: { clientId: string }) =>
  `/clients/${clientId}/contacts/export`;
const downloadContactsLinkUrl = ({
  clientId,
  jobId,
}: {
  clientId: string;
  jobId: string;
}) => `/clients/${clientId}/contacts/export/${jobId}`;
const resultsUrl = ({ clientId }: { clientId: string }) =>
  `clients/${clientId}/results/`;
const apiKeyUrl = ({ clientId }: { clientId: string }) =>
  `/clients/${clientId}/api-key/`;

const facebookAccountsUrl = ({
  appId,
  clientId,
}: {
  appId: string;
  clientId: string;
}) => `/clients/${clientId}/apps/${appId}/facebook-accounts`;
const externalEventsSourceDetails = ({ clientId }: { clientId: string }) =>
  `/clients/${clientId}/external-events/source_details`;

const refreshADsURL = ({
  adAccID,
  appId,
  clientId,
}: {
  adAccID: string;
  appId: string;
  clientId: string;
}) => `/clients/${clientId}/apps/${appId}/ads/account/${adAccID}/refresh`;

export const getInsightsStats = async ({
  dateFrom,
  dateTo,
  appIds,
  clientId,
}: {
  dateFrom: string;
  dateTo: string;
  appIds: string;
  clientId: string;
}) => {
  const { data } =
    await MPInstanceAds.buffedInstances.get<Types.IServerResponseInsightsStats>(
      insightsStatsURL({ clientId }),
      {
        params: {
          date_from: dateFrom,
          date_to: dateTo,
          app_ids: appIds,
        },
      }
    );

  return data;
};

export const getListOfSources = async ({
  dateFrom,
  dateTo,
  appIds,
  limit,
  offset,
  clientId,
}: {
  dateFrom: string;
  dateTo: string;
  appIds?: string;
  limit?: number;
  offset?: number;
  clientId: string;
}) => {
  const { data } =
    await MPInstanceAds.buffedInstances.get<Types.IServerResponseSources>(
      sourcesURL({ clientId }),
      {
        params: {
          date_from: dateFrom,
          date_to: dateTo,
          app_ids: appIds,
          limit,
          offset,
        },
      }
    );

  return data;
};

export const setSourceName = async ({
  sourceId,
  name,
  clientId,
}: {
  sourceId: string;
  name: string;
  clientId: string;
}) => {
  const { data } = await MPInstanceAds.buffedInstances.patch(
    setSourceNameURL({ sourceId, clientId }),
    {
      name,
    }
  );

  return data;
};

export const getInsightAllowed = async ({ clientId }: { clientId: string }) => {
  const { data } =
    await MPInstanceAds.buffedInstances.get<Types.IInsightAllowedResponse>(
      insightAllowedURL({ clientId })
    );

  return data;
};

export const getActions = async ({
  dateFrom,
  dateTo,
  sourceIds,
  appIds,
  limit,
  offset,
  clientId,
  type,
}: {
  dateFrom: string;
  dateTo: string;
  sourceIds: string;
  appIds?: string;
  limit?: number;
  offset?: number;
  clientId: string;
  type?: Types.TActionType;
}) => {
  const actionsUrlWithSourceIds = `${messagingEventsUrl({ clientId })}?${
    sourceIds ? sourceIds : ""
  }`;

  const { data } =
    await MPInstanceAds.buffedInstances.get<Types.IServerResponseActions>(
      actionsUrlWithSourceIds,
      {
        params: {
          date_from: dateFrom,
          date_to: dateTo,
          app_ids: appIds,
          limit,
          offset,
          type,
        },
      }
    );

  return data;
};

export const getActionsSourceDetails = async ({
  dateFrom,
  dateTo,
  actionIds,
  sourceIds,
  limit,
  offset,
  clientId,
  appIds,
  campaignIds,
  returnGroupedSources = true,
}: {
  dateFrom: string;
  dateTo: string;
  actionIds: string;
  sourceIds: string;
  limit?: number;
  offset?: number;
  appIds?: string;
  clientId: string;
  campaignIds?: string;
  returnGroupedSources?: boolean;
}) => {
  const actionSourceDetailsUrlWithActionsIds = `${actionSourceDetailsUrl({
    clientId,
  })}?${actionIds ? actionIds + "&" : ""}${sourceIds ? sourceIds : ""}`;

  const { data } =
    await MPInstanceAds.buffedInstances.get<Types.IServerResponseActionsSourceDetails>(
      actionSourceDetailsUrlWithActionsIds,
      {
        params: {
          date_from: dateFrom,
          date_to: dateTo,
          app_ids: appIds,
          limit,
          offset,
          campaign_ids: campaignIds,
          return_grouped_sources: returnGroupedSources,
        },
      }
    );

  return data;
};

export const exportContacts = async ({
  dateFrom,
  dateTo,
  sourceIds,
  actionIds,
  appIds,
  clientId,
  eventIds,
}: {
  dateFrom: string;
  dateTo: string;
  sourceIds?: string[];
  actionIds?: string[];
  appIds?: string[];
  clientId: string;
  eventIds?: string[];
}) => {
  const { data } = await MPInstanceAds.buffedInstances.post(
    exportContactsUrl({ clientId }),
    {
      date_from: dateFrom,
      date_to: dateTo,
      app_ids: appIds,
      ...(sourceIds ? { source_ids: sourceIds } : {}),
      ...(actionIds ? { action_ids: actionIds } : {}),
      ...(eventIds ? { event_ids: eventIds } : {}),
    }
  );

  return data;
};

export const exportContactsBackground = async ({
  dateFrom,
  dateTo,
  sourceIds,
  actionIds,
  appIds,
  clientId,
  eventIds,
  type = "link",
}: {
  dateFrom: string;
  dateTo: string;
  type?: string;
  sourceIds?: string[];
  actionIds?: string[];
  appIds?: string[];
  clientId: string;
  eventIds?: string[];
}) => {
  const { data } = await MPInstanceAdsV2.buffedInstances.post(
    exportContactsBackgroundUrl({ clientId }),
    {
      type,
      date_from: dateFrom,
      date_to: dateTo,
      app_ids: appIds,
      ...(sourceIds ? { source_ids: sourceIds } : {}),
      ...(actionIds ? { action_ids: actionIds } : {}),
      ...(eventIds ? { event_ids: eventIds } : {}),
    }
  );

  return data;
};

export const downloadContactsLink = async ({
  jobId,
  clientId,
}: {
  jobId: string;
  clientId: string;
}) => {
  const { data } = await MPInstanceAdsV2.buffedInstances.get(
    downloadContactsLinkUrl({ clientId, jobId })
  );

  return data;
};

export const createFbAccount = async ({
  appId,
  token,
  clientId,
}: {
  appId: string;
  token: string;
  clientId: string;
}) => {
  const { data } = await MPInstanceAds.buffedInstances.post(
    facebookAccountsUrl({ appId, clientId }),
    {
      temporary_token: token,
    }
  );

  return data;
};

export const getExternalEvents = async ({
  clientId,
  sourceIds,
  dateFrom,
  dateTo,
  appIds,
  offset,
  limit,
}: {
  clientId: string;
  sourceIds: string;
  dateFrom: string;
  dateTo: string;
  appIds?: string;
  offset?: number;
  limit?: number;
}) => {
  const externalEventURLWithSourceIds = `${externalEventURL({ clientId })}?${
    sourceIds ? sourceIds : ""
  }`;
  const { data } =
    await MPInstanceAds.buffedInstances.get<Types.IServerResponseExternalEvents>(
      externalEventURLWithSourceIds,
      {
        params: {
          app_ids: appIds,
          offset,
          limit,
          date_from: dateFrom,
          date_to: dateTo,
        },
      }
    );
  return data;
};

export const getExternalEventsSourceDetails = async ({
  dateFrom,
  dateTo,
  limit,
  offset,
  clientId,
  eventIds,
  appIds,
  sourceIds,
  campaignIds,
  returnGroupedSources = true,
}: {
  dateFrom: string;
  dateTo: string;
  appIds: string;
  sourceIds?: string;
  eventIds?: string;
  limit?: number;
  offset?: number;
  clientId: string;
  campaignIds?: string;
  returnGroupedSources?: boolean;
}) => {
  const externalEventsSourceDetailsURLWithSourceIds = `${externalEventsSourceDetails(
    {
      clientId,
    }
  )}?${sourceIds ? sourceIds + "&" : ""}${eventIds ? eventIds : ""}`;

  const { data } =
    await MPInstanceAds.buffedInstances.get<Types.IServerResponseExternalEventsSourceDetails>(
      externalEventsSourceDetailsURLWithSourceIds,
      {
        params: {
          app_ids: appIds,
          offset,
          limit,
          date_from: dateFrom,
          date_to: dateTo,
          campaign_ids: campaignIds,
          return_grouped_sources: returnGroupedSources,
        },
      }
    );
  return data;
};

export const getFBAccounts = async ({
  appId,
  clientId,
}: {
  appId: string;
  clientId: string;
}) => {
  const { data } = await MPInstanceAds.buffedInstances.get<
    Types.IServerResponseFBAccount[]
  >(facebookAccountsUrl({ appId, clientId }));

  return data;
};

export const refreshADs = async ({
  adAccID,
  appId,
  clientId,
}: {
  adAccID: string;
  appId: string;
  clientId: string;
}) => {
  const { data } = await MPInstanceAds.buffedInstances.post(
    refreshADsURL({ adAccID, appId, clientId })
  );

  return data;
};

export const results = async ({
  clientId,
  dateFrom,
  dateTo,
  appIds,
  eventsSequence,
  offset,
  limit,
  returnGroupedSources = true,
  returnPerSourceType = true,
  sources,
}: {
  clientId: string;
  dateFrom: string;
  dateTo: string;
  appIds: string;
  eventsSequence?: string[];
  offset?: number;
  limit?: number;
  returnGroupedSources?: boolean;
  returnPerSourceType?: boolean;
  sources?: {
    ids?: string[];
    groups?: string[];
    types?: string[];
  };
}) => {
  const { data } = await MPInstanceAds.buffedInstances.post(
    resultsUrl({ clientId }),
    {
      date_from: dateFrom,
      date_to: dateTo,
      app_id: appIds,
      events_sequence: eventsSequence,
      offset,
      limit,
      return_grouped_sources: returnGroupedSources,
      return_per_source_type: returnPerSourceType,
      sources,
    }
  );

  return data;
};

export const getApiKey = async ({ clientId }: { clientId: string }) => {
  const { data } =
    await MPInstanceAds.buffedInstances.get<Types.IApiKeyResponse>(
      apiKeyUrl({ clientId })
    );

  return data;
};
