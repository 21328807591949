const varsArray = [
  {
    fromHtmlAttr: "data-ui-env",
    toGlobalVar: "UI_ENV",
  },
];

if (globalThis.window && typeof globalThis.window === "object") {
  const reactRoot = document.getElementById("app") as HTMLElement;
  varsArray.forEach((varObj) => {
    const variable = reactRoot?.getAttribute(varObj.fromHtmlAttr);
    if (variable) {
      globalThis.window[varObj.toGlobalVar] = variable;
    }
  });
}

export {};
