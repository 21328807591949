// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.WIZ_continueLabelWithHelp]:
    "Para continuar, termine los pasos requeridos.",
  [keys.WIZ_billingPlanTitle]: "Seleccionar un plan",
  [keys.WIZ_billingMethodTitle]: "Añadir detalles de pago",
  [keys.WIZ_billingTermsTitle]: "Aceptar términos y condiciones",
  [keys.WIZ_paymentMethodTitleStep1]: "1. Seleccionar método de pago",
  [keys.WIZ_paymentMethodTitleStep2]: "2. Checkout",
  [keys.WIZ_paymentMethodCountrySelectLabel]:
    "Seleccione su país para ver los métodos de pago disponibles.",
  [keys.WIZ_termsTitle]: "Términos y condiciones de 360dialog:",
  [keys.WIZ_createNumberTitle]:
    "Crear cuenta de WhatsApp Business y conectar número",
  [keys.WIZ_numberWasSetUp]: "Su número <NUMBER> fue configurado con éxito.",
  [keys.WIZ_connectInboxTitle]: "Terminar su onboarding con get.chat",
  [keys.WIZ_connectInboxText]:
    "Para configurar su bandeja de entrada de get.chat, siga las instrucciones del correo electrónico, que ya debería haber recibido. Su cuenta de WhatsApp Business ya está configurada y conectada, por lo que puede empezar a utilizar inmediatamente la bandeja de entrada.",
  [keys.WIZ_goToHubButtonLabel]: "Siga para el 360dialog Client Hub",
  [keys.WIZ_channelPageCheckboxText1]:
    "👤 Usted tiene una cuenta personal de Meta",
  [keys.WIZ_channelPageCheckboxText2]:
    "📞 El número que será conectado no está en uso corriente con otra Cuenta de WhatsApp.",
  [keys.WIZ_channelPageCheckboxText3]:
    "📲 Puede verificar la propiedad del número mediante la recepción de SMS o llamadas telefónicas internacionales.",
  [keys.WIZ_channelPageCheckboxText4]:
    "🖋️ Usted ya tiene una cuenta de empresa (business) en Meta, que está verificada.",
  [keys.WIZ_channelPageSubtitle1]:
    "En el siguiente paso hay que configurar sus cuentas utilizando sus credenciales de Meta y siguiendo los pasos proporcionados.",
  [keys.WIZ_channelPageSubtitle2]:
    "Después, lo configuraremos todo automáticamente y podrá utilizar la API de WhatsApp Business.",
  [keys.WIZ_waitingText1]:
    "Actualmente estamos configurando su propia instancia de servidor, por lo que estará listo para empezar a utilizar la API de WhatsApp Business en unos minutos.",
  [keys.WIZ_waitingText2]:
    "Iniciar su cliente alojado de la API de WhatsApp Business.",
  [keys.WIZ_addNumberText]:
    "Opcionalmente, también se puede <BUTTON>añadir un nuevo número ahora mismo<BUTTON>.",
  [keys.WIZ_channelTitle]: "Asegúrese de que cumple los siguientes requisitos.",
  [keys.WIZ_titleSpecifiedPermission]:
    "El partner solicita permiso para acceder a la API de WhatsApp Business para el siguiente número:",
  [keys.WIZ_titleUnspecifiedPermission]:
    "Seleccione los números a los que desea dar permiso al partner para acceder a la API de WhatsApp Business.",
  [keys.WIZ_selectedCount]: "Números seleccionados <NUMBER>",
  //  [keys.WIZ_numbers]: "translation_missing",
  //  [keys.WIZ_selectNumbers]: "translation_missing",
  //  [keys.WIZ_noPendingChannels]: "translation_missing",
  //  [keys.WIZ_verifyOwnership]: "translation_missing",
  //  [keys.WIZ_somethingWrong]: "translation_missing",
  //  [keys.WIZ_NumberVerificationTitle]: "translation_missing",
  //  [keys.WIZ_NumberVerificationDesc]: "translation_missing",
};

export default translate;
