// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  // [keys.NUM_manageAccount]: "translation_missing",
  // [keys.NUM_details]: "translation_missing",
  // [keys.NUM_accountName]: "translation_missing",
  // [keys.NUM_dataStorageRegions]: "translation_missing",
  // [keys.NUM_cloudApiNumbers]: "translation_missing",
  // [keys.NUM_qualityRating]: "translation_missing",
  // [keys.NUM_low]: "translation_missing",
  // [keys.NUM_medium]: "translation_missing",
  // [keys.NUM_high]: "translation_missing",
  // [keys.NUM_messageLimit]: "translation_missing",
  // [keys.NUM_newConversationsPerDay]: "translation_missing",
  // [keys.NUM_unlimited]: "translation_missing",
};

export default translate;
