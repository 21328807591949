import { MPInstancesHub } from "@/api/RequestAxios/RequestAxiosHub";

const approveMessageForwardingUrl = ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/channels/${channelId}/control/approve_payment_connection`;

export const approveMessageForwarding = async ({
  partnerId,
  clientId,
  projectId,
  channelId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
  channelId: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    approveMessageForwardingUrl({ partnerId, clientId, projectId, channelId }),
    {}
  );
  return data;
};
