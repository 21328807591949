// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.DASH_welcome]: "Willkommen",
  [keys.DASH_welcomeBack]: "Willkommen zurück",
  [keys.DASH_mainTemplateManagerInfo]:
    "Hast du bereits Nachrichtenvorlagen für deine Nummer erstellt? Gehe zum Vorlagen-Manager um deine Vorlagen zu organisieren und um neue zu erstellen.",
  [keys.DASH_learnMore]: "Mehr erfahren",
  [keys.DASH_goToTemplates]: "Zum Vorlagen-Manager",
  [keys.DASH_nextSteps]: "Nächste Schritte",
  [keys.DASH_firstSteps]: "Erste Schritte",
  [keys.DASH_yourWabasList]: "Deine WhatsApp Konten",
  [keys.DASH_resources]: "Ressourcen",
  [keys.DASH_faq]: "Oft gestellte Fragen",
  [keys.DASH_mainVerified]:
    "Deine neue Nummer <NUMBER> ist fast bereit um los zu legen. Hast du dein Unternehmen bereits verifiziert?",
  [keys.DASH_mainGoToFbmButton]: "Zur Meta Business Verifikation",
  [keys.DASH_mainDocsLearnMore]:
    "Lerne mehr über die Funktionen des 360dialog Client Hub.",
  [keys.DASH_showDocsButton]: "Dokumentation anzeigen",
  [keys.DASH_popupIntro]:
    "Deine neue Nummer <NUMBER> ist fast bereit um los zu legen. Folgendes gilt es noch zu tun:",
  [keys.DASH_popupBullet1]:
    "Erstelle einen API Schlüssel, um diesen in der Anwendung deines Partners zu nutzen.",
  [keys.DASH_popupBullet2]:
    "Wenn dein Unternehmen noch nicht von Meta verifiziert wurde, solltest du den Unternehmensverifizierungs-Prozess im Meta Business Manager starten.",
  [keys.DASH_generateKeyButton]: "API Schlüssel erstellen",
  [keys.DASH_fetchingNumberPopup]:
    "Deine Nummer wird gerade von uns eingerichtet. Das kann ein paar Augenblicke dauern. Bitte warte kurz.",
  [keys.DASH_settingUpPopup]:
    "Dein gehosteter WhatsApp Business API Client wird jetzt gestartet. Gleich geht es los!",
  [keys.DASH_numbersSectionEmpty]: "Du hast keine registrierte Nummer.",
  [keys.DASH_integrationPartnerInfo]: "Dein Integrationspartner ist <PARTNER>.",
  [keys.DASH_lastStepApi]:
    "Hervorragend! Du hast soeben deinen persönlichen API Schlüssel für die Nummer <NUMBER> erstellt. Gehe jetzt zum Meta Business Manager um den Meta Unternehmensverifizierungsprozess zu beginnen.",
  [keys.DASH_lastStepForwarding]:
    "Hervorragend! Du hast soeben die Nachrichten-Weiterleitung für die Nummer <NUMBER> aktiviert. Gehe jetzt zum Meta Business Manager um den Meta Unternehmensverifizierungsprozess zu beginnen.",
  [keys.DASH_lastStepTitle]: "Meta Unternehmensverifizierung",
  [keys.DASH_lastStepButtonLabel]: "Zur Meta Unternehmensverifizierung",
  [keys.DASH_lastStepButtonLabelClose]:
    "Ich habe mein Unternehmen bereits verifiziert",
  [keys.DASH_lastStepVerifiedTitle]: "Alles erledigt...",
  [keys.DASH_lastStepVerifiedText]:
    "... und bereit, um los zu legen! Mit deinem persönlichen API Schlüssel für die Nummer <NUMBER> kannst du direkt starten die WhatsApp Business API zu nutzen. Da dein Unternehmen bereits von Meta verifiziert ist, musst du an dieser Stelle nichts mehr tun.",
  [keys.DASH_timeoutErrorText]:
    "Leider konnten wir deine Nummer nicht einrichten. Scheinbar hast du das Onboarding nicht vollständig abgeschlossen.  Klicke den Button unterhalb um das Setup deines WhatsApp Business Kontos abzuschließen.",
  [keys.DASH_popupBulletBypassBeta]:
    "Leider konnten wir deine Nummer nicht einrichten. Scheinbar hast du das Onboarding nicht vollständig abgeschlossen.  Klicke den Button unterhalb um das Setup deines WhatsApp Business Kontos abzuschließen.",
  [keys.DASH_submissionSuccessHelptext]: "Deine Anmeldung war erfolgreich!",
  [keys.DASH_serverDelayMessage]:
    "Es tut uns leid, aber scheinbar erreichen unsere Server gerade sehr viele Anfragen. Deine Nummer wird in wenigen Minuten fertig sein. Wir werden dich dann umgehend per E-Mail benachrichtigen, sobald du mit dem Setup fortfahren kannst.",
  [keys.DASH_buttonLabelNewButton]: "Neuen Nutzer anlegen",
  [keys.DASH_popupBullet3]:
    "Erstelle einen API Schlüssel für deine eigene Anwendung oder erstelle deine Inbox mit get.chat.",
  [keys.DASH_popupBullet5]:
    "Dein WhatsApp API Konto beinhaltet jetzt eine gratis Inbox, bereitgestellt durch unseren Partner get.chat.\nget.chat ist eine Software, die es dir ermöglicht dein WhatsApp API Konto mit mehreren Nutzern und mehreren Anwendungen gleichzeitig zu verbinden.\nAufgepasst: Wenn du die Inbox verwendest, kannst du für diese Nummer keinen 360dialog API Schlüssel erstellen.",
  [keys.DASH_createBtnInbox]: "Erstelle meine get.chat Inbox für <NUMBER>",
  [keys.DASH_generateKeyButtonInstead]: "Stattdessen API Schlüssel erstellen",
  [keys.DASH_chooseDifferentName]: "Wähle einen anderen Anzeigenamen",
  [keys.DASH_displayNamePendingMessage]:
    "WhatsApp braucht etwas länger als üblich, um den von dir gewählten Anzeigenamen zu genehmigen. Dies kann mehr Zeit in Anspruch nehmen. Wir werden dich per E-Mail über den Status dieses Verfahrens informieren",
  [keys.DASH_displayNameRejectedMessage]:
    "Der von dir gewählte Anzeigename wurde von WhatsApp abgelehnt <br/> Du kannst die offiziellen Richtlinien für Anzeigenamen einsehen <LINK> <br/> Bitte wähle einen anderen Anzeigenamen",
  [keys.DASH_displayNameRejectedMessagePipe]:
    "Der von dir gewählte Anzeigename wurde von WhatsApp abgelehnt. Du kannst die offiziellen Richtlinien für Anzeigenamen <LINK>https://www.facebook.com/business/help/338047025165344 | hier<LINK> einsehen.",
  [keys.DASH_integrationSetup]: "Integration einrichten",
  [keys.DASH_migrateNumberFromAnotherBSP]:
    "Nummer von einem anderem BSP migrieren",
  [keys.DASH_useClassicSignup]: "Klassische Anmeldung verwenden",
  [keys.DASH_addNewPhoneNumber]: "Neue Rufnummer hinzufügen",
  [keys.DASH_importFromWBA]: "Importieren aus WhatsApp Business App",
  [keys.DASH_importFromBS]: "Importieren von einem anderen BSP",
  [keys.DASH_stackSetup]: "Einrichten deiner Nummer",
  [keys.DASH_test]: "Test",
  [keys.DASH_testWebhookSuccess]:
    "Ein Test-Webhook-Ereignis wurde erfolgreich gesendet.",
  [keys.DASH_testWebhookError]:
    "Das Test-Webhook-Ereignis konnte nicht an deine Webhook-URL übermittelt werden. Bitte überprüfe die URL und versuche es erneut. Wenn das Problem weiterhin besteht, wende dich an unseren Kundensupport.",
  [keys.DASH_integrationSettings]: "Integrationseinstellungen",
  [keys.DASH_partnerWebhookUrl]: "Partner-Webhook-URL",
  [keys.DASH_webhookUrlTootip]:
    "Wenn du auf die Schaltfläche Test klickst, wird ein Test-Webhook-Ereignis an deine Webhook-URL gesendet. Damit kannst du testen, ob deine Webhook-URL funktioniert und Ereignisse von unseren Servern annimmt.",
  [keys.DASH_redirectUrl]: "Umleitungs-URL (Integriertes Onboarding)",
  [keys.DASH_redirectUrlToolip]:
    "Wenn du auf die Schaltfläche Testen klickst, wird deine Umleitungs-URL in einem neuen Tab geöffnet und eine Beispiel-Kunden-ID und Channel-IDs werden zu Demozwecken hinzugefügt",
  [keys.DASH_defaultHosting]: "Standard-Hosting-Plattformtyp",
  [keys.DASH_cloudApiHosted]: "Cloud-API, gehostet von Meta",
  [keys.DASH_onPremiseHosted]: "On-premise-API, gehostet von 360dialog",
  [keys.DASH_infoOnHostingPlatform]:
    "Alle Nummern, die neu in die WhatsApp Business API aufgenommen werden, sowie alle Nummern, die von einem anderen BSP migriert werden, werden automatisch über den Standard-Hosting-Plattformtyp gehostet.",
  [keys.DASH_partnerUpdatedSuccess]:
    "Partnerintegrationsdaten erfolgreich aktualisiert",
  [keys.DASH_redirectUrlNorValid]:
    "Die angegebene Redirect-URL stimmt nicht mit dem URI-Format überein. Bitte überprüfe sie noch einmal und stelle sicher, dass sie korrekt ist.",
  [keys.DASH_paymentPlan]: "Zahlungsplan",
  //  [keys.DASH_vsPreviousWeek]: "translation_missing",
  //  [keys.DASH_conversations]: "translation_missing",
  //  [keys.DASH_expenses]: "translation_missing",
  //  [keys.DASH_lastWeekActivity]: "translation_missing",
  //  [keys.DASH_channel]: "translation_missing",
};

export default translate;
