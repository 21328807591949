import { MPInstancesHub } from "@/services/api/RequestAxios/RequestAxiosHub";
import { IQueryParams, packQueryParams, stringifyQueryParams } from "../utils";
import { UIModelClientsList, UIModelSingleClient } from "./clients.model";
import {
  unpackClientsList,
  unpackSingleClient,
  unpackClientVatId,
  packClient,
  packVatId,
  packFbCallback,
  packCreatePartnerChangeInfo,
  unpackPartnerChangePreview,
  unpackPaymentCheck,
} from "./clients.mapper";
import * as Types from "./clients.types";
import removeNullValues from "@/utilities/removeNullValues";
import {
  IServerResponsePartnerChangeRequest,
  IServerResponsePartnerChangeRequestsInfo,
  IUIResponsePartnerChangeRequest,
  IUIResponsePartnerChangeRequestsInfo,
} from "../partners/partners.types";
import { UIModelPartnerChangeRequestsInfo } from "../partners/partners.model";
import {
  unpackPartnerChangeRequest,
  unpackPartnerChangeInfo,
} from "../partners/partners.mapper";
import {
  IServerResponsePartnerChangePreview,
  IUIResponsePartnerChangePreview,
  IClientPaymentMethods,
  IServerResponsePaymentCheck,
} from "./clients.types";
import { MPInstanceAdsV2 } from "@/services/api/RequestAxios/RequestAxiosAds";

const clientsUrl = ({ partnerId }: { partnerId: string }) =>
  `/partners/${partnerId}/clients`;
const clientUrl = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}`;

const clientsUserURL = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/users`;

const saveInThreadMessageURL = ({
  businessPhoneNumber,
  clientId,
}: {
  businessPhoneNumber: string;
  clientId: string;
}) =>
  `/clients/${clientId}/phones/${businessPhoneNumber}/messages/frequency/rules`;

const paymentTokenURL = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/info/payment`;

const addPaymentMethodUrl = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/add_payment_method`;

const clientDataCsvURL = ({ partnerId }: { partnerId: string }) =>
  `/partners/${partnerId}/clients/info/export`;
const clientFbCallbackURL = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/control/fb_login_callback`;
const generateClientDataToEmailUrl = (partnerId: string) =>
  `/partners/${partnerId}/clients/info/export_to_email`;
const partnerChangeRequestURL = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/partner_change_requests`;
const partnerChangeRequestPreviewURL = ({
  partnerId,
  clientId,
  targetPartnerId,
}: {
  partnerId?: string;
  clientId?: string;
  targetPartnerId?: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/partner_change_requests/preview/${targetPartnerId}`;
const cancelPartnerChangeURL = ({
  clientId,
  partnerId,
  partnerChangeRequestId,
}: {
  clientId: string;
  partnerId: string;
  partnerChangeRequestId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/partner_change_requests/${partnerChangeRequestId}/cancel`;

const getClientBillingDataUrl = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `partners/${partnerId}/clients/${clientId}/info/billing_data`;

const setClientVatIdUrl = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/set_vat_info`;

const getClientInvoicesUrl = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/invoices`;

const getClientInvoiceAsPDFUrl = ({
  partnerId,
  clientId,
  invoiceId,
}: {
  partnerId: string;
  clientId: string;
  invoiceId: string;
}) => `/partners/${partnerId}/clients/${clientId}/invoice/${invoiceId}/pdf`;

const getClientPaymentMethodsUrl = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/payment_methods`;

const getClientUnpaidInvoiceLinkUrl = ({
  partnerId,
  clientId,
  invoiceId,
}: {
  partnerId: string;
  clientId: string;
  invoiceId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/invoices/${invoiceId}/payment-link`;

const checkPaymentUrl = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/control/payment_check`;

const aditionalPaymentDetailsUrl = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/payment/details`;

const finalizePartnerChangeUrl = ({
  clientId,
  partnerId,
  partnerChangeRequestId,
}: {
  clientId: string;
  partnerId: string;
  partnerChangeRequestId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/partner_change_requests/${partnerChangeRequestId}/finalize`;

const premigratePbsUrl = ({
  clientId,
  oldPartnerId,
  newPartnerId,
}: {
  clientId: string;
  oldPartnerId: string;
  newPartnerId: string;
}) =>
  `/partners/${oldPartnerId}/clients/${clientId}/premigrate_to_pbs/${newPartnerId}`;

export const getClientsList = async (
  { partnerId }: { partnerId: string },
  queryParams?: IQueryParams
): Promise<UIModelClientsList> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseClientList>(
      clientsUrl({ partnerId }),
      {
        params: queryParams && packQueryParams(queryParams),
        paramsSerializer: stringifyQueryParams,
      }
    );

  return new UIModelClientsList(unpackClientsList(data));
};

export const getClient = async ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}): Promise<UIModelSingleClient> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseSingleClient>(
      clientUrl({ partnerId, clientId })
    );

  return new UIModelSingleClient(unpackSingleClient(data));
};

export const getClientInvoices = async ({
  partnerId,
  clientId,
  page,
  limit,
}: {
  partnerId: string;
  clientId: string;
  page?: number;
  limit?: number;
}) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseClientInvoice>(
      getClientInvoicesUrl({ partnerId, clientId }),
      {
        params: {
          page,
          limit,
        },
      }
    );

  return data;
};

export const getClientInvoiceAsPDF = async ({
  partnerId,
  clientId,
  invoiceId,
}: {
  partnerId: string;
  clientId: string;
  invoiceId: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get(
    getClientInvoiceAsPDFUrl({ partnerId, clientId, invoiceId }),
    {
      responseType: "blob",
    }
  );

  return data;
};

export const getClientBillingData = async ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseClientBillingData>(
      getClientBillingDataUrl({ partnerId, clientId })
    );

  return data;
};

export const setClientVatId = async (
  { partnerId, clientId }: { partnerId: string; clientId: string },
  payload: Types.IUIVATFormValues
): Promise<Types.IUIResponseClientVatId> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseClientVatId>(
      setClientVatIdUrl({ partnerId, clientId }),
      removeNullValues(packVatId(payload))
    );

  return unpackClientVatId(data);
};

export const createClient = async (
  { partnerId }: { partnerId: string },
  payload: Types.IClientFormValues
): Promise<UIModelSingleClient> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseSingleClient>(
      clientsUrl({ partnerId }),
      packClient(payload)
    );

  return new UIModelSingleClient(unpackSingleClient(data));
};

export const updateClient = async (
  { partnerId, clientId }: { partnerId: string; clientId: string },
  payload: Types.IClientFormValues
): Promise<UIModelSingleClient> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.patch<Types.IServerResponseSingleClient>(
      clientUrl({ partnerId, clientId }),
      removeNullValues(packClient(payload))
    );

  return new UIModelSingleClient(unpackSingleClient(data));
};

export const getClientPaymentToken = async ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IClientPaymentInfo>(
      paymentTokenURL({ partnerId, clientId })
    );

  return data;
};

export const addPaymentMethod = async ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IClientAddPaymentMethod>(
      addPaymentMethodUrl({ partnerId, clientId })
    );

  return data;
};

export const getClientData = async ({ partnerId }: { partnerId: string }) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get(
    clientDataCsvURL({ partnerId }),
    {
      headers: {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0",
      },
    }
  );
  return data;
};

export const postFbCallbackToken = async (
  { partnerId, clientId }: { partnerId: string; clientId: string },
  payload: Types.IFbCallbackVlaues
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseSingleClient>(
      clientFbCallbackURL({ partnerId, clientId }),
      packFbCallback(payload)
    );
  return data;
};

export const generateClientDataToEmail = async (partnerId: string) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    generateClientDataToEmailUrl(partnerId),
    {}
  );
  return data;
};

export const createPartnerChangeRequest = async ({
  partnerId,
  clientId,
  payload,
}: {
  partnerId: string;
  clientId: string;
  payload: Types.IPartnerChangeRequestFormValues;
}): Promise<IUIResponsePartnerChangeRequest> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<IServerResponsePartnerChangeRequest>(
      partnerChangeRequestURL({ partnerId, clientId }),
      packCreatePartnerChangeInfo(payload)
    );

  return unpackPartnerChangeRequest(data);
};

export const getPartnerChangeRequests = async ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}): Promise<IUIResponsePartnerChangeRequestsInfo> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<IServerResponsePartnerChangeRequestsInfo>(
      partnerChangeRequestURL({ partnerId, clientId })
    );

  return new UIModelPartnerChangeRequestsInfo(unpackPartnerChangeInfo(data));
};

export const getPartnerChangeRequestPreview = async ({
  partnerId,
  clientId,
  targetPartnerId,
}: {
  partnerId?: string;
  clientId?: string;
  targetPartnerId?: string;
}): Promise<IUIResponsePartnerChangePreview> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<IServerResponsePartnerChangePreview>(
      partnerChangeRequestPreviewURL({ partnerId, clientId, targetPartnerId })
    );

  return unpackPartnerChangePreview(data);
};

export const cancelPartnerChangeRequest = async ({
  clientId,
  partnerId,
  partnerChangeRequestId,
  payload,
}: {
  clientId: string;
  partnerId: string;
  partnerChangeRequestId: string;
  payload: any;
}): Promise<IUIResponsePartnerChangeRequest> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<IServerResponsePartnerChangeRequest>(
      cancelPartnerChangeURL({ clientId, partnerId, partnerChangeRequestId }),
      payload
    );

  return unpackPartnerChangeRequest(data);
};

export const finalizePartnerChangeRequest = async ({
  clientId,
  partnerId,
  partnerChangeRequestId,
  payload,
}: {
  clientId: string;
  partnerId: string;
  partnerChangeRequestId: string;
  payload: any;
}): Promise<IUIResponsePartnerChangeRequest> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<IServerResponsePartnerChangeRequest>(
      finalizePartnerChangeUrl({ clientId, partnerId, partnerChangeRequestId }),
      payload
    );

  return unpackPartnerChangeRequest(data);
};

export const getClientPaymentMethods = async ({
  clientId,
  partnerId,
}: {
  clientId: string;
  partnerId: string;
}) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<IClientPaymentMethods>(
      getClientPaymentMethodsUrl({ clientId, partnerId })
    );

  return data.data;
};

export const getClientUnpaidInvoiceLink = async ({
  clientId,
  partnerId,
  invoiceId,
}: {
  clientId: string;
  partnerId: string;
  invoiceId: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get<{
    url: string;
  }>(getClientUnpaidInvoiceLinkUrl({ clientId, partnerId, invoiceId }));

  return data;
};

export const checkPayment = async (
  {
    partnerId,
    clientId,
  }: {
    partnerId: string;
    clientId: string;
  },
  payload
): Promise<Types.IUIResponsePaymentCheck> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<IServerResponsePaymentCheck>(
      checkPaymentUrl({ partnerId, clientId }),
      payload
    );

  return unpackPaymentCheck(data);
};

export const submitAdditionalDetails = async ({
  payload,
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
  payload: Types.PaymentDetailsValuesT;
}): Promise<Types.IUIResponsePaymentCheck> => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    aditionalPaymentDetailsUrl({ partnerId, clientId }),
    payload
  );
  return unpackPaymentCheck(data);
};

export const addNewUserToExistingClient = async ({
  partnerId,
  clientId,
  email,
  name,
}: {
  partnerId: string;
  clientId: string;
  email: string;
  name: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    clientsUserURL({ partnerId, clientId }),
    {
      name: name,
      email: email,
    }
  );

  return data;
};

export const saveInThreadMessage = async ({
  businessPhoneNumber,
  clientId,
  messageValue,
  messageType = "text",
}: {
  businessPhoneNumber: string;
  clientId: string;
  messageValue: string;
  messageType?: "template" | "text" | "interactive";
}) => {
  const { data } = await MPInstanceAdsV2.buffedInstances.post(
    saveInThreadMessageURL({ businessPhoneNumber, clientId }),
    {
      value: messageValue,
      type: messageType,
    }
  );

  return data;
};

export const getMembers = async ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get(
    clientsUserURL({ partnerId, clientId })
  );

  return data;
};

export const premigratePbs = async ({
  clientId,
  oldPartnerId,
  newPartnerId,
}: {
  clientId: string;
  oldPartnerId: string;
  newPartnerId: string;
}) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IClientAddPaymentMethod>(
      premigratePbsUrl({ clientId, oldPartnerId, newPartnerId })
    );

  return data;
};
