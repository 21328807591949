// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.APP_inbox_title]:
    "Настройте и подключите WhatsApp Business Account к get.chat",
  [keys.APP_inbox_step1]: "Выбор тарифного плана и оплата",
  [keys.APP_inbox_step2]: "Настройте свой WhatsApp Business Account",
  [keys.APP_inbox_step3]: "Завершите вашу регистрацию с get.chat",
  [keys.APP_inbox_bullet1]: "Бесплатная учетная запись WhatsApp Business",
  [keys.APP_inbox_bullet2]: "19EUR на Shared inbox от get.chat",
  [keys.APP_inbox_termsTitle]: "Условия обслуживания get.chat",
  [keys.APP_apps]: "Приложения",
  [keys.APP_addApps]: "Добавить приложения",
  [keys.APP_browse]: "Обзор маркетплейса",
  [keys.APP_addDescription]:
    "Каждый из ваших телефонных номеров может быть подключен к стороннему приложению, например, к inbox, чат-ботам и CRM-системам.",
  [keys.APP_addSubDescription]:
    "В настоящее время мы создаем наш маркетплейс вместе с более чем 700 партнерами. Прямо сейчас вы можете начать работу с <BOLD>team inbox<BOLD>, предоставляемым <BOLD>get.chat<BOLD>.",
  [keys.APP_getChat]: "get.chat",
  [keys.APP_setUp]: "Настроить get.chat inbox",
  [keys.APP_connectApp]: "Подключить приложение",
  [keys.APP_numberRegistration]: "Продолжить к регистрации номера",
  [keys.APP_addGetChat]: "Добавить get.chat",
  [keys.APP_activateInbox]: "Активировать ваш inbox",
  [keys.APP_faq1]: "Что такое WhatsApp team inbox?",
  [keys.APP_answer1]:
    "WhatsApp team inbox — это графический пользовательский интерфейс для вас и вашей команды. Web Inbox технически является интерфейсом API интеграции. Это позволяет вам иметь многопользовательский доступ к отправке и получению сообщений вашей учетной записи WhatsApp Business. Проще говоря, Web Inbox — это совместный почтовый ящик, который дает нескольким членам команды возможность отвечать клиентам в WhatsApp через общую панель управления.",
  [keys.APP_faq2]: "Какой inbox я подключаю?",
  [keys.APP_answer2]:
    "Для вас будет создан Get.chat inbox. Get.chat inbox позволяет вашей команде поддержки клиентов отвечать на запросы клиентов через WhatsApp одновременно и с разных устройств. Он превращает ваш WhatsApp inbox в удобное пространство для общения как для клиентов, так и для команды, позволяя легко и эффективно управлять поддержкой клиентов для вашего бизнеса.",
  [keys.APP_faq3]: "Какова цена этой услуги?",
  [keys.APP_answer3]:
    "Поскольку вы являетесь клиентом 360dialog, эта услуга для вас абсолютно бесплатна.",
  [keys.APP_activateInboxCheckbox]:
    "Я согласен с <LINK>https://get.chat/terms-of-service | Условия использования<LINK>",
  [keys.APP_captchaNewApiMessage]:
    "Имейте в виду, что при активации вашего inbox будет сгенерирован новый ключ API, а ваш старый ключ больше не будет работать. Если у вас есть активная интеграция, она также перестанет работать.",
  [keys.APP_captchaNewApiCheckbox]:
    "Я понимаю, что мой старый ключ API, если он у меня есть, больше не будет работать",
  [keys.APP_inboxAccess]: "Доступы к inbox",
  [keys.APP_inboxCredentials]:
    "Используйте следующие учетные данные для входа в свой inbox",
  [keys.APP_inboxAccessClickButton]:
    "Чтобы получить доступ к inbox, нажмите кнопку доступа ниже.",
  [keys.APP_accessInbox]: "Получить доступ к inbox",
  [keys.APP_settingUpInboxTitle]: "Настройка вашего inbox",
  [keys.APP_settingUpInbox]: "Настройка вашего inbox для номера <NUMBER>",
  [keys.APP_pageTitle]: "360dialog - Подключить приложения к номерам",
  [keys.APP_pageDescription]:
    "Просмотр подключенных приложений и подключение приложений к номерам",
  //  [keys.APP_notificationEmail]: "translation_missing",
  //  [keys.APP_checkYourEmail]: "translation_missing",
  //  [keys.APP_emailSent]: "translation_missing",
  //  [keys.APP_followSteps]: "translation_missing",
};

export default translate;
