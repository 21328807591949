import { createContext } from "react";
import { UIModelWabaTemplates } from "@/services/api/wabaTemplates/wabaTemplates.model";
import { ListState } from "./listState";

const wabaTemplatesState = new ListState<UIModelWabaTemplates>({
  withoutAppId: true,
  limit: 20,
  offset: 0,
  sortFields: ["business_templates.name"],
  searchFields: [],
  apiDataInitial: {
    wabaTemplates: [],
    total: 0,
  },
  filtersLoadSave: {},
});

export const WabaTemplatesState = createContext<
  ListState<UIModelWabaTemplates>
>({} as ListState<UIModelWabaTemplates>);

const WabaTemplateStateStoreProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <WabaTemplatesState.Provider value={wabaTemplatesState}>
      {children}
    </WabaTemplatesState.Provider>
  );
};

export default WabaTemplateStateStoreProvider;
