// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.AUTH_login]: "translation_missing",
  //  [keys.AUTH_forgot]: "translation_missing",
  //  [keys.AUTH_clickHere]: "translation_missing",
  //  [keys.AUTH_rightsReserved]: "translation_missing",
  //  [keys.AUTH_privacy]: "translation_missing",
  //  [keys.AUTH_imprint]: "translation_missing",
  //  [keys.AUTH_reset]: "translation_missing",
  //  [keys.AUTH_resetHelptext]: "translation_missing",
  //  [keys.AUTH_resetButtonLabel]: "translation_missing",
  //  [keys.AUTH_resetError]: "translation_missing",
  //  [keys.AUTH_resetSuccess]: "translation_missing",
  //  [keys.AUTH_alreadyHaveAccount]: "translation_missing",
  //  [keys.AUTH_signup]: "translation_missing",
  //  [keys.AUTH_gethelp]: "translation_missing",
  //  [keys.AUTH_getHelpLink]: "translation_missing",
  //  [keys.AUTH_clickAboveButtons]: "translation_missing",
  //  [keys.AUTH_termsAndService]: "translation_missing",
  //  [keys.AUTH_metaTerms]: "translation_missing",
  //  [keys.AUTH_forWhatsApp]: "translation_missing",
  //  [keys.AUTH_create]: "translation_missing",
  //  [keys.AUTH_login360]: "translation_missing",
  //  [keys.AUTH_email]: "translation_missing",
  //  [keys.AUTH_password]: "translation_missing",
  //  [keys.AUTH_loginEmail]: "translation_missing",
  //  [keys.AUTH_continueEmail]: "translation_missing",
  //  [keys.AUTH_signupTerms]: "translation_missing",
  //  [keys.AUTH_checkEmail]: "translation_missing",
  //  [keys.AUTH_create360Account]: "translation_missing",
  //  [keys.AUTH_otpTextOne]: "translation_missing",
  //  [keys.AUTH_otpTextTwo]: "translation_missing",
  //  [keys.AUTH_otpTextThree]: "translation_missing",
  //  [keys.AUTH_otp]: "translation_missing",
  //  [keys.AUTH_enterOtp]: "translation_missing",
  //  [keys.AUTH_namePlaceholder]: "translation_missing",
  //  [keys.AUTH_nameLabel]: "translation_missing",
  //  [keys.AUTH_createAccountEmail]: "translation_missing",
  //  [keys.AUTH_otpWindowMessage]: "translation_missing",
  //  [keys.AUTH_createAccount]: "translation_missing",
  //  [keys.AUTH_signupPriceList]: "translation_missing",
  //  [keys.AUTH_redirectMessage]: "translation_missing",
  //  [keys.AUTH_visitNewHub]: "translation_missing",
  //  [keys.AUTH_newHubInfoMessage]: "translation_missing",
  //  [keys.AUTH_governmentAgency]: "translation_missing",
  //  [keys.AUTH_organizationIRepresent]: "translation_missing",
  //  [keys.AUTH_chooseFromList]: "translation_missing",
  //  [keys.AUTH_governmentalAgencyApproval]: "translation_missing",
  //  [keys.AUTH_companyEmail]: "translation_missing",
  //  [keys.AUTH_termsOfServiceSignUp]: "translation_missing",
  //  [keys.AUTH_newAccount]: "translation_missing",
  //  [keys.AUTH_dontHaveAccount]: "translation_missing",
  //  [keys.AUTH_signIn]: "translation_missing",
  //  [keys.AUTH_resetHere]: "translation_missing",
  //  [keys.AUTH_twoFactorAuth]: "translation_missing",
  //  [keys.AUTH_OTPSent]: "translation_missing",
  //  [keys.AUTH_ResetPasswordMessage]: "translation_missing",
  //  [keys.AUTH_wantToCancel]: "translation_missing",
  //  [keys.AUTH_backToSignIn]: "translation_missing",
  //  [keys.AUTH_emailSentTo]: "translation_missing",
  //  [keys.AUTH_checkInbox]: "translation_missing",
  //  [keys.AUTH_GoBackToSignIn]: "translation_missing",
  //  [keys.AUTH_logOut]: "translation_missing",
  //  [keys.AUTH_min8Characters]: "translation_missing",
  //  [keys.AUTH_noFacebookEmail]: "translation_missing",
  //  [keys.AUTH_logIn]: "translation_missing",
  //  [keys.AUTH_instructions]: "translation_missing",
  //  [keys.AUTH_passwordNew]: "translation_missing",
  //  [keys.AUTH_emailVerification]: "translation_missing",
  //  [keys.AUTH_FBLogin]: "translation_missing",
  //  [keys.AUTH_passwordSuccess]: "translation_missing",
  //  [keys.AUTH_passwordError]: "translation_missing",
  //  [keys.AUTH_otpTitle]: "translation_missing",
  //  [keys.AUTH_otpDescription]: "translation_missing",
};

export default translate;
