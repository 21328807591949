// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.TMP_name]: "Nome",
  [keys.TMP_cat]: "Categoria",
  [keys.TMP_preview]: "Prévia",
  [keys.TMP_lang]: "Idiomas",
  [keys.TMP_tooltipAddLang]: "Adicionar idioma",
  [keys.TMP_tooltipCopy]: "Duplicar",
  [keys.TMP_addTemplateButtonLabel]: "Adicionar template",
  [keys.TMP_emptyStateTitle]:
    "Você ainda não criou nenhum template de mensagem.",
  [keys.TMP_emptyStateHelptext]:
    "As mensagens template podem ser usadas para enviar notificações às pessoas que lhe deram permissão para enviar mensagens (opt-in). Você pode usar os templates para iniciar uma conversa com um cliente depois que a janela de 24 horas para mensagens de sessão tiver fechado.",
  [keys.TMP_cardHeader]: "Novo template",
  [keys.TMP_namePlaceholder]: "Nome do template",
  [keys.TMP_nameLabel]: "Nome",
  [keys.TMP_cat1]: "Auto-Reply",
  [keys.TMP_cat2]: "Atualização da conta",
  [keys.TMP_cat3]: "Atualização de pagamento",
  [keys.TMP_cat4]: "Atualização das finanças pessoais",
  [keys.TMP_cat5]: "Atualização de envio",
  [keys.TMP_cat6]: "Atualização de reserva",
  [keys.TMP_cat7]: "Resolução do problema",
  [keys.TMP_cat8]: "Atualização da hora marcada",
  [keys.TMP_cat9]: "Atualização do transporte",
  [keys.TMP_cat10]: "Atualização do ingresso",
  [keys.TMP_cat11]: "Atualização do alerta",
  [keys.TMP_langLabel]: "Idioma",
  [keys.TMP_typeLabel]: "Tipo de template",
  [keys.TMP_type1]: "Padrão (somente texto)",
  [keys.TMP_type2]: "Mídia & Interativo",
  [keys.TMP_bodyPlaceholder]:
    "Digite aqui o texto principal de seu template...",
  [keys.TMP_blocksHelptext]: "Selecione blocos de construção do template",
  [keys.TMP_header]: "Header",
  [keys.TMP_body]: "Body",
  [keys.TMP_footer]: "Footer",
  [keys.TMP_button]: "Botão",
  [keys.TMP_buttons]: "Botões",
  [keys.TMP_chars]: "caracteres",
  [keys.TMP_vars]: "variáveis",
  [keys.TMP_headerMedia1]: "Texto",
  [keys.TMP_headerMedia2]: "Imagem",
  [keys.TMP_headerMedia3]: "Documento",
  [keys.TMP_headerMedia4]: "Vídeo",
  [keys.TMP_footerPlaceholder]:
    "Digite o texto do footer do seu template aqui...",
  [keys.TMP_headerPlaceholder]:
    "Digite o texto do header do seu template aqui...",
  [keys.TMP_quickReply]: "Quick reply",
  [keys.TMP_cta]: "Call to action",
  [keys.TMP_phoneNumber]: "Número de telefone",
  [keys.TMP_url]: "URL",
  [keys.TMP_addButtonLabel]: "Adicionar botão",
  [keys.TMP_discardButtonLabel]: "Descartar alterações",
  [keys.TMP_previewButtonLabel]: "Visualizar e enviar",
  [keys.TMP_buttonLabelPlaceholder]:
    "Comece a digitar o texto do seu botão aqui...",
  [keys.TMP_buttonVarHelpText]: "(precisa estar no final do texto)",
  [keys.TMP_previewHeader1]: "Template",
  [keys.TMP_previewHeader2]: "Preview",
  [keys.TMP_exampleValues]: "Exemplos",
  [keys.TMP_varEmptyStateHelptext1]:
    "Você não usou nenhum marcador de variável no seu texto.",
  [keys.TMP_varEmptyStateHelptext2]:
    "Para adicionar uma variável, volte no editor e insire {{#}} na posição desejada. O # representa o index da variável, que precisa começar em {{1}}.",
  [keys.TMP_varEmptyStateHelptext3]:
    "Para mais informações, por favor confira nosssa documentação sobre o Template Manager.",
  [keys.TMP_docsLinkLabel]: "Documentação de Template Manager",
  [keys.TMP_saveSuccess]: "O template foi salvo com sucesso!",
  [keys.TMP_namePatternError]:
    "O nome só pode conter caracteres alfanuméricos em minúsculo e underscores ( _ )",
  [keys.TMP_backToList]: "Voltar para a lista",
  [keys.TMP_backToEditor]: "Voltar para o editor",
  [keys.TMP_submit]: "Enviar template",
  [keys.TMP_headerImgPlaceholder]: "URL da imagem de header",
  [keys.TMP_buttonUrlVarPlaceholder]: "Button URL",
  [keys.TMP_headerDocumentPlaceholder]: "URL do Documento",
  [keys.TMP_tooltipDelete]: "Deletar template",
  [keys.TMP_deleteAllButtonLabel]: "Deletar todos os idiomas de template",
  [keys.TMP_deleteConfirmText]:
    "Tem certeza que deseja deletar todos os idiomas de templates com o nome <TEMPLATE_NAME>?",
  [keys.TMP_deleteAllConfirmButtonLabel]: "Deletar todos",
  [keys.TMP_deleteAllSuccess]:
    "Todos os idiomas de templates foram deletados com sucesso!",
  [keys.TMP_deleteSingleSuccess]: "O template foi deletado com sucesso!",
  [keys.TMP_buttonsCounter]: "Buttons",
  [keys.TMP_pleaseNote]: "Atenção: ",
  [keys.TMP_helperBulletText1]:
    "Se esse nome de mensagem de template existe em múltiplos idiomas, todos os idiomas serão deletados.",
  [keys.TMP_helperBulletText2]:
    "Messagens que foram enviadas mas não recebidas tentarão ser recebidas por 30 dias.",
  [keys.TMP_helperBulletText3]:
    'Se a mensagem de um template aprovado é enviado 30 dias após ser deletado, você receberá o erro "Structure Unavailable" e o cliente não receberá a mensagem.',
  [keys.TMP_helperBulletText4]:
    "Uma vez deletado, o nome de um template aprovado não pode ser utilizado novamente por 30 dias. Use um nome diferente para criar templates futuros.",
  [keys.TMP_sampleValueInfoText]:
    "Os exemplos serão enviados para o Meta como parte do processo de aprovação de templates. Se nenhum exemplo for informado, os templates poderão ser rejeitados pelo Meta.",
  [keys.TMP_missingExamplesError]:
    "Por favor, insira exemplos para todas as suas variáveis.",
  [keys.TMP_buttonPreviewHelptext]:
    "Clique no botão da prévia para validar sua URL.",
  [keys.TMP_syncTemplates]: "Sincronizar com o Meta",
  [keys.TMP_successSyncTemplates]: "Sucesso! Os templates foram atualizados!",
  [keys.TMP_syncDone]: "Os templates estão atualizados.",
  [keys.TMP_justNow]: "Agora mesmo",
  [keys.TMP_lastSync]: "Última sincronização:",
  [keys.TMP_headerVideoPlaceholder]: "URL do vídeo do cabeçalho",
  [keys.TMP_errorInvalidUrl]:
    "A URL de exemplo enviada não leva a um arquivo de mídia válido. Por favor corrija a URL e tente novamente.",
  [keys.TMP_nameProposal]: "Ao invés, use <PROPOSAL>",
  [keys.TMP_status]: "Status",
  [keys.TMP_balance]: "Saldo",
  [keys.TMP_autoRenewalStatus]: "Renovação automática Ativada",
  [keys.TMP_autoRenewalThreshold]: "Limite de renovação automática",
  [keys.TMP_autoRenewalAmount]: "Valor de renovação automática",
  [keys.TMP_addFunds]: "Adicionar fundos",
  [keys.TMP_editAutoRenewalAmount]: "Editar valor de renovação automática",
  [keys.TMP_editAutoRenewalThreshold]: "Editar limite de renovação automática",
  [keys.TMP_errorInvalidCharacter]: "Caractere inválido",
  [keys.TMP_errorImageExtensions]:
    "Extensões de arquivo aceitas são JPG and PNG",
  [keys.TMP_errorVideoExtensions]:
    "Extensões de arquivo aceitas são MP4 and 3gpp",
  [keys.TMP_buttonAddVariable]: "Adicionar variável",
  [keys.TMP_newLineFooterWarning]:
    "O rodapé não deve conter uma quebra de linha.",
  [keys.TMP_emojiWarning]: "Emojis só podem ser colocador no corpo do texto",
  [keys.TMP_searchPlaceholder]: "Procurar por nome",
  [keys.TMP_cat12]: "Transacional",
  [keys.TMP_cat13]: "Marketing",
  [keys.TMP_cat14]: "Senha de uso único",
  [keys.TMP_tooltipReplicate]: "Replicar modelo",
  [keys.TMP_tooltipAddLangToTemplate]: "Adicionar idioma diferente a um modelo",
  [keys.TMP_tooltipEditTemplate]: "Editar template",
  [keys.TMP_tooltiMetaSync]:
    "Este status mostra se um template já foi submetido à Meta ou se alterações recentes a um template ainda estão esperando para serem submetidas.",
  [keys.TMP_ditFieldDisabled]: "O campo não pode ser editado",
  [keys.TMP_editSuccess]:
    "O template foi atualizado com sucesso! Baseado nas restriçōes do Meta, pode haver um atraso de até 24 horas antes que as mudanças sejam sincronizadas com o Meta.",
  //  [keys.TMP_editFieldDisabled]: "translation_missing",
  [keys.TMP_sampleRequiredValueInfoText]:
    "Os valores de exemplo fornecidos serão submetidos à Meta como parte do processo de submissão de modelos. É necessário adicionar valores de exemplo para todas as variáveis a fim de submeter o template.",
  //  [keys.TMP_metaSync]: "translation_missing",
  //  [keys.TMP_tooltiMetaSync]: "translation_missing",
  //  [keys.TMP_cat15]: "translation_missing",
  //  [keys.TMP_cat16]: "translation_missing",
  //  [keys.TMP_categoryTooltip_1]: "translation_missing",
  //  [keys.TMP_categoryTooltip_2]: "translation_missing",
  //  [keys.TMP_categoryTooltip_3]: "translation_missing",
  //  [keys.TMP_categoryRejectionTooltip_1a]: "translation_missing",
  //  [keys.TMP_categoryRejectionTooltip_1b]: "translation_missing",
  //  [keys.TMP_categoryRejectionTooltip_2_embedded]: "translation_missing",
  //  [keys.TMP_categoryRejectionTooltip_2_classic]: "translation_missing",
  //  [keys.TMP_categoryRejectionTooltip_3]: "translation_missing",
  //  [keys.TMP_allowCategoryChange]: "translation_missing",
  //  [keys.TMP_headerMedia5]: "translation_missing",
  //  [keys.TMP_allowCategoryChangeTooltip_1]: "translation_missing",
  //  [keys.TMP_allowCategoryChangeTooltip_2]: "translation_missing",
  //  [keys.TMP_headerLocationPlaceholder]: "translation_missing",
  //  [keys.TMP_timeIndicatorTooltip]: "translation_missing",
  //  [keys.TMP_securityDesclaimer]: "translation_missing",
  //  [keys.TMP_expirationWarning]: "translation_missing",
  //  [keys.TMP_timeIndicator]: "translation_missing",
  //  [keys.TMP_timeIndicatorErrorMessage]: "translation_missing",
  //  [keys.TMP_textCopiedToClipboard]: "translation_missing",
  //  [keys.TMP_seeAllOptions]: "translation_missing",
};

export default translate;
