import * as Types from "./notifications.types";

export const unpackNotifications = (
  response: Types.IServerResponseNotifications
): Types.IUIResponseNotifications => ({
  total: response.total,
  notifications: response.notifications.map((notification) =>
    unpackNotification(notification)
  ),
  offset: response.offset,
  limit: response.limit,
  count: response.count,
});

export const unpackNotification = (
  notification: Types.IServerResponseNotification
): Types.IUINotification => ({
  id: notification.id,
  notificationType: {
    name: notification.notification_type?.name,
  },
  severity: notification.severity,
  context: {
    affectedChannels: notification.context?.affected_channels?.map(
      (channel) => ({
        displayName: channel.display_name,
        number: channel.number,
      })
    ),
    wabaAccountId: notification.context?.waba_account_id,
    messageForNewPartner: notification.context?.message_for_new_partner,
    phoneNumber: notification.context?.phone_number,
    clientId: notification.context?.client_id,
    partnerChangeRequestId: notification.context?.partner_change_request_id,
    partnerChangeRequestStatus:
      notification.context?.partner_change_request_status,
    newPartnerId: notification.context?.new_partner_id,
    oldPartnerId: notification.context?.old_partner_id,
    hostedInvoiceUrl: notification.context?.hosted_invoice_url,
  },
  content: notification.content,
  status: notification.status,
  createdAt: notification.created_at,
});
