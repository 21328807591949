// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.WIZ_continueLabelWithHelp]:
    "Para continuar, finalice los pasos requeridos",
  [keys.WIZ_billingPlanTitle]: "Seleccione um plan",
  [keys.WIZ_billingMethodTitle]: "Añadir detalles de pago",
  [keys.WIZ_billingTermsTitle]: "Acepte los términos y condiciones",
  [keys.WIZ_paymentMethodTitleStep1]: "1. Seleccione método de pago",
  [keys.WIZ_paymentMethodCountrySelectLabel]: "2. Checkout",
  [keys.WIZ_paymentMethodCountrySelectLabel]:
    "Seleccione su país para ver métodos de pago disponibles.",
  [keys.WIZ_termsTitle]: "Términos y condiciones de la 360dialog",
  [keys.WIZ_numberWasSetUp]:
    "Su número <NUMBER> ha sido configurado con suceso",
  [keys.WIZ_connectInboxTitle]: "Terminar su onboarding con la get.chat",
  [keys.WIZ_connectInboxText]:
    "Para configurar su bandeja de entrada de get.chat, sigue las instrucciones del correo electrónico que ya debería haber recibido. Su cuenta de WhatsApp Business ya está configurada y conectada, por lo que puede empezar a utilizar inmediatamente la bandeja de entrada.",
  [keys.WIZ_goToHubButtonLabel]: "Siga para el 360dialog Client Hub",
  [keys.WIZ_channelPageCheckboxText1]:
    "👤 Usted tiene una cuenta Meta personal ",
  [keys.WIZ_channelPageCheckboxText1]:
    "📞 El número que se va a conectar no está actualmente en uso con otra cuenta de WhatsApp.",
  [keys.WIZ_channelPageCheckboxText3]:
    "📲 Puede verificar la propiedad del número mediante la recepción de SMS o llamadas telefónicas internacionales.",
  [keys.WIZ_channelPageCheckboxText3]:
    "🖋️ Usted ya tiene una cuenta de empresa en Meta que está verificada.",
  [keys.WIZ_channelPageSubtitle1]:
    "En el siguiente paso hay que configurar sus cuentas utilizando sus credenciales de Meta y siguiendo los pasos proporcionados.",
  [keys.WIZ_channelPageSubtitle2]:
    "Después, lo configuraremos todo automáticamente y podrá utilizar la API de WhatsApp Business.",
  [keys.WIZ_waitingText1]:
    "Actualmente estamos configurando su propia instancia de servidor, por lo que estará listo para empezar a utilizar la API de WhatsApp Business en unos minutos.",
  [keys.WIZ_waitingText2]:
    "Iniciar su cliente alojado de la API de WhatsApp Business.",
  [keys.WIZ_channelTitle]: "Asegúrese de que cumple los siguientes requisitos.",
  [keys.WIZ_titleSpecifiedPermission]:
    "El partner solicita permiso para acceder a la API de WhatsApp Business para el siguiente número:",
  [keys.WIZ_titleUnspecifiedPermission]:
    "Seleccione los números a los que desea dar permiso al partner para acceder a la API de WhatsApp Business.",
  [keys.WIZ_saveSelection]: "Guardar selección",
  [keys.WIZ_grantPermission]: "Dar permiso",
  [keys.WIZ_optional]: "Opcional",
  [keys.WIZ_successTitle]:
    "Se ha conectado con éxito su cuenta de 360dialog con",
  [keys.WIZ_successSubtitle1]:
    "Retornará para <PARTNER> automáticamente en 5 segundos",
  [keys.WIZ_successSubtitle2]: "Hasta entonces, no cierre esta ventana.",
  [keys.WIZ_successMainTitle]: "Aplicación de partner autorizada!",
  [keys.WIZ_successTitleWithoutBrandName]:
    "Acaba de conectar con éxito su cuenta de 360dialog.",
  [keys.WIZ_successTitle1WithoutBrandName]:
    "Retornará automáticamente en 5 segundos",
  [keys.WIZ_permissionsPageWarningMessage]:
    "Se ha solicitado permiso para el número <NUMBER>, que no existe en su cuenta de 360dialog. ¿Desea añadirlo ahora? De lo contrario, seleccione un número diferente a continuación.",
  [keys.WIZ_addThisNumber]: "Añadir este número",
  [keys.WIZ_addSelected]: "Añadir seleccionado",
  [keys.WIZ_selectPlanTitle]: "Seleccione su plan",
  [keys.WIZ_selectPlanSubtitle]:
    "Sólo unos pocos pasos más hasta que pueda empezar a chatear con sus clientes. Empecemos por lo básico.",
  [keys.WIZ_addNumberText]:
    "Opcionalmente, también se puede <BUTTON>añadir un nuevo número ahora mismo<BUTTON>.",
  [keys.WIZ_selectedCount]: "<NUMBER> números seleccionados",
  [keys.WIZ_selectedCountNewNumbers]: "<NUMBER> números nuevos seleccionados",
  [keys.WIZ_welcomeToWABA]: "Bienvenido a la WhatsApp Business API!",
  [keys.WIZ_facebookAndWhatsAppBusAccount]:
    "Business Accounts de Meta y WhatsApp",
  [keys.WIZ_accountsSuccessfullyCreated]: "Cuenta creada con éxito!",
  [keys.WIZ_authorizePartnerApp]: "Autorizar la Aplicación del Partner",
  [keys.WIZ_numbers]: "Números de telefono",
  [keys.WIZ_selectNumbers]: "Seleccionar números",
  [keys.WIZ_setupFbAndWba]: "Configurar cuentas de Meta y WhatsApp Business",
  [keys.WIZ_nextSteps]:
    "En los próximos pasos, debe configurar sus cuentas utilizando sus credenciales de Meta y siguiendo los pasos proporcionados. También deberá proporcionar información de pago.",
  [keys.WIZ_companyInfoText]:
    "Primero necesitamos información sobre tu empresa",
  [keys.WIZ_cardDetailsError]:
    "Error en el pago. Comprueba los datos de tu tarjeta e inténtalo de nuevo.",
  [keys.WIZ_insufficientFundsError]:
    "Error en el pago. Comprueba que tienes fondos suficientes en la tarjeta e inténtalo de nuevo.",
  [keys.WIZ_tryAgain]: "Intentar de nuevo",
  [keys.WIZ_transactionCancelled]:
    "El pago ha sido rechazado. Inténtalo de nuevo.",
  [keys.WIZ_transactionFailed]: "El pago no se realizó. Inténtalo de nuevo.",
  [keys.WIZ_cardBlocked]:
    "La tarjeta utilizada para la transacción está bloqueada, introduce una tarjeta activa y vuelve a intentarlo.",
  [keys.WIZ_cardExpired]:
    "La tarjeta utilizada para la transacción está caducada, introduce una tarjeta activa e inténtalo de nuevo.",
  //  [keys.WIZ_amountMismatch]: "translation_missing",
  //  [keys.WIZ_cardInvalid]: "translation_missing",
  //  [keys.WIZ_paymentNotAllowed]: "translation_missing",
  //  [keys.WIZ_cardLowBalance]: "translation_missing",
  //  [keys.WIZ_noPaymentMethod]: "translation_missing",
  //  [keys.WIZ_cloudHostingIOInfo]: "translation_missing",
  //  [keys.WIZ_accountSetUpIssuesMessage]: "translation_missing",
  //  [keys.WIZ_accountCreationFailed]: "translation_missing",
  //  [keys.WIZ_updatePaymentDetails]: "translation_missing",
  //  [keys.WIZ_noPendingChannels]: "translation_missing",
  //  [keys.WIZ_verifyOwnership]: "translation_missing",
  //  [keys.WIZ_somethingWrong]: "translation_missing",
  //  [keys.WIZ_NumberVerificationTitle]: "translation_missing",
  //  [keys.WIZ_NumberVerificationDesc]: "translation_missing",
  //  [keys.WIZ_success]: "translation_missing",
  //  [keys.WIZ_successMessage]: "translation_missing",
  //  [keys.WIZ_paymentMethodError]: "translation_missing",
  // [keys.WIZ_isNumberConnectedToBusinessApp]: "translation_missing",
  // [keys.WIZ_businessAppTooltip]: "translation_missing",
  // [keys.WIZ_isNumberConnectedToWABA]: "translation_missing",
  // [keys.WIZ_businessAPITooltip]: "translation_missing",
  // [keys.WIZ_numberConnectedToBusinessApp]: "translation_missing",
  // [keys.WIZ_numberNotConnectedToBusinessApp]: "translation_missing",
  // [keys.WIZ_numberConnectedToWABA]: "translation_missing",
  // [keys.WIZ_numberNotConnectedToWABA]: "translation_missing",
  // [keys.WIZ_aboutYourNumber]: "translation_missing",
  // [keys.WIZ_confirmStatusOfNumber]: "translation_missing",
};

export default translate;
