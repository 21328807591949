import { MPInstancesHub } from "@/services/api/RequestAxios/RequestAxiosHub";
import { IQueryParams, packQueryParams } from "../utils";
import { UIModelHubUser, UIModelHubUsers } from "./hubUser.model";
import { unpackHubUsers, unpackHubUser, packHubUser } from "./hubUser.mapper";
import * as Types from "./user.types";
import { toJS } from "mobx";
import qs from "qs";

const partnerUsersURL = ({ partnerId }: { partnerId: string }) =>
  `/partners/${partnerId}/users`;
const partnerUserUrl = ({
  partnerId,
  userId,
}: {
  partnerId: string;
  userId: string;
}) => `/partners/${partnerId}/users/${userId}`;

const checkUserExistenceURL = () => "/public/check_email";

export const getUsers = async (
  partnerId: string,
  queryParams?: IQueryParams
): Promise<UIModelHubUsers> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseHubUsers>(
      partnerUsersURL({ partnerId }),
      {
        params: queryParams && packQueryParams(queryParams),
        paramsSerializer: (params) => {
          const { filters, ...otherParams } = params;
          const serializedQueryString = qs.stringify(otherParams, {
            arrayFormat: "repeat",
          });

          return `${serializedQueryString}&filters=${JSON.stringify(
            toJS(filters)
          )}`;
        },
      }
    );

  return new UIModelHubUsers(unpackHubUsers(data));
};

export const getUser = async ({
  partnerId,
  userId,
}: {
  partnerId: string;
  userId: string;
}): Promise<UIModelHubUser> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseHubUser>(
      partnerUserUrl({ partnerId, userId })
    );

  return new UIModelHubUser(unpackHubUser(data));
};

export const addUser = async (
  { partnerId }: { partnerId: string },
  payload: Types.IFormValuesPartnerUser
): Promise<UIModelHubUser> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseHubUser>(
      partnerUsersURL({ partnerId }),
      packHubUser(payload)
    );

  return new UIModelHubUser(unpackHubUser(data));
};

export const deleteUser = async ({
  partnerId,
  userId,
}: {
  partnerId: string;
  userId: string;
}): Promise<void> => {
  await MPInstancesHub.buffedInstances.newCrm.delete(
    partnerUserUrl({ partnerId, userId })
  );
};

export const checkUser = async (payload: Types.IUICheckUserExistence) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    checkUserExistenceURL(),
    payload
  );

  return data;
};
