import axios, { AxiosInstance } from "axios";

const isProduction = ["production", "rc"].includes(
  process.env.NEXT_PUBLIC_APP_ENV
);
const apiEnvPrefix = isProduction ? "" : "-staging";

class AxiosInstanceHubPublic {
  requester: AxiosInstance;

  constructor() {
    this.requester = axios.create({
      baseURL: `https://hub${apiEnvPrefix}.360dialog.io/v1`,
    });
  }
}

export default new AxiosInstanceHubPublic();
