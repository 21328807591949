// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.SIGNALS_trackYourCtwa]: "translation_missing",
  //  [keys.SIGNALS_enableTool]: "translation_missing",
  //  [keys.SIGNALS_enableSignalsPage]: "translation_missing",
  //  [keys.SIGNALS_learnMore]: "translation_missing",
  //  [keys.SIGNALS_oneStepAway]: "translation_missing",
  //  [keys.SIGNALS_inOrderToUseFeature]: "translation_missing",
  //  [keys.SIGNALS_connectFacebookAccount]: "translation_missing",
  //  [keys.SIGNALS_step]: "translation_missing",
  //  [keys.SIGNALS_soundsGood]: "translation_missing",
};

export default translate;
