// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.NAV_whatsappAccounts]: "Contas de WhatsApp",
  [keys.NAV_partners]: "Parceiros",
  [keys.NAV_integrations]: "Integrações",
  [keys.NAV_support]: "Suporte",
  [keys.NAV_profile]: "Perfil",
  [keys.NAV_billing]: "Opções de Faturamento",
  [keys.NAV_helpAndFeedback]: "Ajuda e Feedback",
  [keys.NAV_preferences]: "Configurações",
  [keys.NAV_signOut]: "Sign out",
  [keys.NAV_dock]: "Fixar Menu",
  [keys.NAV_undock]: "Ocultar Menu",
  [keys.NAV_templateBilling]: "Faturamento de Templates",
  [keys.NAV_conversationBilling]: "Faturamento de Conversas",
  [keys.NAV_billingText]: "Faturamento",
  [keys.NAV_insights]: "Insights",
  [keys.NAV_insightsAndBilling]: "Insights & Billing",
  [keys.NAV_organizationSettings]: "Configurações da organização",
  [keys.NAV_connectedApps]: "Apps conectados",
  [keys.NAV_notifications]: "Notificações",
  [keys.NAV_logout]: "Sair",
  [keys.NAV_gettingStarted]: "Começando",
  [keys.NAV_roadmap]: "Roadmap",
  //  [keys.NAV_billinOptions]: "translation_missing",
  [keys.NAV_company]: "Empresa",
  //  [keys.NAV_adAccounts]: "translation_missing",
  //  [keys.NAV_pageUnavailable]: "translation_missing",
  [keys.NAV_team]: "Time",
  // [keys.NAV_numberAccess]: "translation_missing",
};

export default translate;
