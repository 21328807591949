// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.SUP_tickets]: "Support Tickets",
  [keys.SUP_id]: "Id",
  [keys.SUP_title]: "Title",
  [keys.SUP_number]: "Number",
  [keys.SUP_status]: "Status",
  [keys.SUP_addComment]: "Add comment",
  [keys.SUP_newTicket]: "Create new ticket",
  [keys.SUP_contactSupport]: "Contact support",
  [keys.SUP_channelSupportHelptext]:
    "If you have issues with this number you can contact our support.",
  [keys.SUP_topic]: "Topic",
  [keys.SUP_selectTopicError]: "Please select the topic of your inquiry",
  [keys.SUP_selectWabaError]: "Please select the relevant account",
  [keys.SUP_selectNumber]: "Select phone number",
  [keys.SUP_selectNumberError]: "Please select the number in question",
  [keys.SUP_selectWabaFirst]: "Select WhatsApp Business Account first",
  [keys.SUP_messagePlaceholder]: "How can we help you?",
  [keys.SUP_other]: "Other",
  [keys.SUP_numberNotAvailable]: "Number not available",
  [keys.SUP_send]: "Send message",
  [keys.SUP_back]: "Back to list",
  [keys.SUP_emptyStateTitle]: "You haven't created any ticket yet",
  [keys.SUP_emptyStateHelptext]:
    "Open a ticket with our support team in case you have any questions or issues. Once you opened a ticket you will be able to see responses from our team as well as the current status of your request.",
  [keys.SUP_success]:
    "Your message was sent. Our support will be in contact with you.",
  [keys.SUP_successComment]:
    "Your comment was sent. Our support will be in contact with you.",
  [keys.SUP_commentOn]: "Comment on ticket",
  [keys.SUP_yourTickets]: "Your Tickets",
  [keys.SUP_docsLink]: "360dialog Client Hub documentation",
  [keys.SUP_docsLinkSubtitle]:
    "Read more about features and how to use the 360dialog Client Hub",
  [keys.SUP_shortcutsLink]: "Keyboard shortcuts",
  [keys.SUP_shortcutsLinkSubtitle]:
    "Navigate the 360dialog Client Hub more efficiently",
  [keys.SUP_apiStatusLink]: "WhatsApp Business API status",
  [keys.SUP_apiStatusLinkSubtitle]:
    "Official API status updates provided by Meta",
  [keys.SUP_supportLink]: "Support center",
  [keys.SUP_supportLinkSubtitle]:
    "View your open tickets and create new tickets to ask for help or send feedback",
  [keys.SUP_openJiraLink]: "Open ticket in Jira",
  [keys.SUP_busVerificationIssue]: "Business verification issue",
  [keys.SUP_newSubmission]: "New submission",
  [keys.SUP_unableToSubmit]: "Unable to submit number",
  [keys.SUP_requestIVR]: "Request IVR numbers",
  [keys.SUP_otherQuestion]: "Other question",
  [keys.SUP_messagingNotWorking]: "Messaging not working",
  [keys.SUP_internalServerErr]: "Internal server error",
  [keys.SUP_sandboxSupport]: "Sandbox support",
  [keys.SUP_hubAccess]: "Hub access",
  [keys.SUP_commercePolicyRejection]: "Appeal commerce policy rejection",
  [keys.SUP_click2WhatsApp]: "Enable Click2WhatsApp Ads",
  [keys.SUP_numberPorting]: "Number porting",
  [keys.SUP_nameChange]: "Display Name change",
  [keys.SUP_nameRejected]: "Display Name rejected",
  [keys.SUP_cancellation]: "Cancellation (delete number)",
  [keys.SUP_onPremise]: "On-premise support",
  [keys.SUP_paymentMethod]: "Payment method",
  [keys.SUP_govAccount]: "Governmental account application",
  [keys.SUP_outsideWorkHours]:
    "You’ve submitted a new support ticket outside of our office hours. Our customer support team works from Monday to Friday 2 am to 11 pm UTC+1. We will get in touch with you shortly.",
  [keys.SUP_openStatusPage]: "Open status page",
  [keys.SUP_allSystemsNormal]: "All systems normal",
  [keys.SUP_expIssues]: "Experiencing issues",
  [keys.SUP_underMaintenance]: "Under maintenance",
  [keys.SUP_warningCreateTicket1]:
    "Attention! We’re currently experiencing issues with our system. For details please visit our <LINK>https://status.360dialog.com/ | Status Page<LINK>. Please refrain from submitting tickets regarding the mentioned issues.",
  [keys.SUP_warningCreateTicket2]:
    "You can subscribe to updates regarding the incident via the <LINK>https://status.360dialog.com/ | Status Page<LINK>.",
  [keys.SUP_statusLinkSubtitle]:
    "View and subscribe to any current or past incidents",
  [keys.SUP_messageLengthError]: "Message must at least be 140 characters",
  [keys.SUP_clickHereButtonLabel]: "Click here",
  [keys.SUP_paymentMethods]: "Payment methods",
  [keys.SUP_attachFile]: "Attach images",
  [keys.SUP_includeAllTickets]: "Include tickets from my team",
  [keys.SUP_reporter]: "Reporter",
  [keys.SUP_optionsLoading]: "Options are loading…",
  [keys.SUP_roadmap]: "Roadmap and feature requests",
  [keys.SUP_roadmapSubtitle]:
    "View our roadmap and submit or vote on feature requests",
  [keys.SUP_inReview]: "in review",
  [keys.SUP_responseViaEmail]:
    "Please note that you will receive your response via email.",
  [keys.SUP_onboarding]: "Onboarding",
  [keys.SUP_onboardingDescription]:
    "Number registrations, adding numbers, number porting, embedded signup etc...",
  [keys.SUP_problemWithMessaging]: "Problem with messaging API",
  [keys.SUP_messagingProblemsDescription]:
    "Messaging not arriving, error messages in the API, messaging delays, numbers disconnected etc...",
  [keys.SUP_accManagement]: "Account management",
  [keys.SUP_accManagementDescription]:
    "Meta business verification, Meta business terms, commerce policy, display names, offifical business accounts, templates, message limits, quality tiers, insights, balance, partner changes etc...",
  [keys.SUP_offboarding]: "Offboarding",
  [keys.SUP_offboardingDescription]:
    "Cancellation, termination, 2FA disable, migrate to another BSP, downgrades etc...",
  [keys.SUP_financeAndBilling]: "Finance & billing",
  [keys.SUP_financeAndBillingDescription]:
    "Invoicing, subscriptions, refunds, payment methods etc...",
  [keys.SUP_otherQuestionDescription]:
    "Anything else that is not listed in the categories above",
  [keys.SUP_visitStatusPage1]:
    "We’re currently experiencing issues with our system. For details please visit our <LINK>https://status.360dialog.com/ | Status Page<LINK>.",
  [keys.SUP_visitStatusPage2]:
    " Please refrain from submitting tickets regarding the mentioned issues. You can subscribe to updates regarding the incident via the <LINK>https://status.360dialog.com/ | Status Page<LINK>.",
  [keys.SUP_useIntercom]:
    "Open a support chat using the Intercom bubble at the bottom-right",
};

export default translate;
