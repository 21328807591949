// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  AUD_audiences: "AUD_audiences",
  AUD_generalAudience: "AUD_generalAudience",
  AUD_details: "AUD_details",
  AUD_undelivered: "AUD_undelivered",
  AUD_undeliveredTipTitle: "AUD_undeliveredTipTitle",
  AUD_undeliveredTipOne: "AUD_undeliveredTipOne",
  AUD_undeliveredTipTwo: "AUD_undeliveredTipTwo",
  AUD_seeOnMeta: "AUD_seeOnMeta",
  AUD_downloadAudience: "AUD_downloadAudience",
  AUD_noAudience: "AUD_noAudience",
  AUD_noAudienceDescription: "AUD_noAudienceDescription",
  AUD_noAudienceDescriptionToS: "AUD_noAudienceDescriptionToS",
  AUD_createAudience: "AUD_createAudience",
  AUD_createAudienceCTAText: "AUD_createAudienceCTAText",
  AUD_chooseAdAccount: "AUD_chooseAdAccount",
  AUD_createdMsg: "AUD_createdMsg",
  AUD_acceptMetaTerms: "AUD_acceptMetaTerms",
  AUD_newText: "AUD_newText",
  AUD_introducingAudiences: "AUD_introducingAudiences",
  AUD_introducingAudiencesDescription: "AUD_introducingAudiencesDescription",
  AUD_checkDocs: "AUD_checkDocs",
  AUD_changeAdAccounts: "AUD_changeAdAccounts",
};

export default keys;
