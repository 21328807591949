// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.SUP_tickets]: "Tickets de suporte",
  [keys.SUP_id]: "ID",
  [keys.SUP_title]: "Título",
  [keys.SUP_number]: "Número",
  [keys.SUP_status]: "Status",
  [keys.SUP_addComment]: "Adicionar comentário",
  [keys.SUP_newTicket]: "Criar novo ticket",
  [keys.SUP_contactSupport]: "Entre em contato com a equipe de suporte",
  [keys.SUP_channelSupportHelptext]:
    "Se você tiver problemas com este número, você pode entrar em contato com nosso suporte.",
  [keys.SUP_topic]: "Tema",
  [keys.SUP_selectTopicError]: "Favor selecionar o tema de sua pergunta",
  [keys.SUP_selectWabaError]:
    "Favor selecionar a conta relacionada à sua pergunta",
  [keys.SUP_selectNumber]: "Selecione o número de telefone",
  [keys.SUP_selectNumberError]: "Favor selecionar o número em questão",
  [keys.SUP_selectWabaFirst]: "Selecione primeiro a conta de WhatsApp Business",
  [keys.SUP_messagePlaceholder]: "Como podemos ajudá-lo?",
  [keys.SUP_other]: "Outro",
  [keys.SUP_numberNotAvailable]: "Número não disponível",
  [keys.SUP_send]: "Enviar mensagem",
  [keys.SUP_back]: "Voltar à lista",
  [keys.SUP_emptyStateTitle]: ".Você ainda não criou nenhum ticket",
  [keys.SUP_emptyStateHelptext]:
    "Abra um ticket com o nosso time de suporte se você tiver qualquer pergunta ou problema. Quando você abre um ticket você conseguirá ver as respostas do nosso time assim como os status atuais da sua solicitação.",
  [keys.SUP_success]:
    "Sua mensagem foi enviada. Nosso suporte entrará em contato com você.",
  [keys.SUP_successComment]:
    "Seu comentário foi enviado. Nosso suporte entrará em contato com você.",
  [keys.SUP_commentOn]: "Adicionar comentário ao ticket",
  [keys.SUP_yourTickets]: "Seus tickets",
  [keys.SUP_docsLink]: "Documentação do 360dialog Client Hub",
  [keys.SUP_docsLinkSubtitle]:
    "Leia mais sobre recursos e como usar o 360dialog Client Hub",
  [keys.SUP_shortcutsLink]: "Atalhos do teclado",
  [keys.SUP_shortcutsLinkSubtitle]:
    "Navegar pelo 360dialog Client Hub com mais eficiência",
  [keys.SUP_apiStatusLink]: "Status da WhatsApp Business API",
  [keys.SUP_apiStatusLinkSubtitle]:
    "Atualizações oficiais do status da API fornecidas pelo Meta",
  [keys.SUP_supportLink]: "Central de suporte",
  [keys.SUP_supportLinkSubtitle]:
    "Veja seus tickets abertos e crie novos tickets para pedir ajuda ou enviar feedback",
  [keys.SUP_openJiraLink]: "Abrir ticket no Jira",
  [keys.SUP_busVerificationIssue]: "Problema na verificação da empresa",
  [keys.SUP_newSubmission]: "Nova solicitação",
  [keys.SUP_unableToSubmit]: "Não é possível enviar número",
  [keys.SUP_requestIVR]: "Solicitar números de IVR",
  [keys.SUP_otherQuestion]: "Outra pergunta",
  [keys.SUP_messagingNotWorking]: "Mensagens não estão funcionando",
  [keys.SUP_internalServerErr]: "Erro interno do servidor",
  [keys.SUP_sandboxSupport]: "Suporte de Sandbox",
  [keys.SUP_hubAccess]: "Acesso ao Hub",
  [keys.SUP_commercePolicyRejection]: "Apelar rejeição da política de comércio",
  [keys.SUP_click2WhatsApp]: "Habilitar anúncios de Click2WhatsApp",
  [keys.SUP_numberPorting]: "Portabilidade de números",
  [keys.SUP_nameChange]: "Alteração de Display Name",
  [keys.SUP_nameRejected]: "Rejeição de Display Name",
  [keys.SUP_cancellation]: "Cancelamento (deletar número)",
  [keys.SUP_onPremise]: "Suporte on-premise",
  [keys.SUP_paymentMethod]: "Método de pagamento",
  [keys.SUP_govAccount]: "Solicitação de conta governamental",
  [keys.SUP_outsideWorkHours]:
    "Você enviou um novo ticket de suporte fora do nosso horário de funcionamento. Nosso time de suporte trabalha de Segunda a Sexta de 2 da manhã a 11 da noite UTC +1. Entraremos em contato assim que possível.",
  [keys.SUP_openStatusPage]: "Abrir página de status",
  [keys.SUP_allSystemsNormal]: "Funcionamento normal",
  [keys.SUP_expIssues]: "Problemas identificados",
  [keys.SUP_underMaintenance]: "Em manutenção",
  [keys.SUP_warningCreateTicket1]:
    "Atenção! Estamos com alguns problemas no sistema. Para detalhes, por favor visite nossa <LINK>https://status.360dialog.com/ | Status Page<LINK>. Por favor, evite enviar tickets sobre os problemas mencionados.",
  [keys.SUP_warningCreateTicket2]:
    "Você pode se inscrever para updates sobre o incidente na <LINK>https://status.360dialog.com/ | Status Page<LINK>.",
  [keys.SUP_statusLinkSubtitle]:
    "Visualizar e inscrever para qualquer incidente atual ou antigo",
  [keys.SUP_messageLengthError]: "A mensagem deve ter no mínimo 140 caracteres",
  [keys.SUP_clickHereButtonLabel]: "Clique aqui",
  [keys.SUP_paymentMethods]: "Métodos de pagamento",
  [keys.SUP_attachFile]: "Anexar imagens",
  [keys.SUP_includeAllTickets]: "Incluir tickets da minha equipe",
  [keys.SUP_reporter]: "Solicitante",
  [keys.SUP_optionsLoading]: "Carregando opções",
  [keys.SUP_roadmap]: "Roadmap e solicitações de features",
  [keys.SUP_roadmapSubtitle]:
    "Veja nosso roadmap e envie ou vote em solicitações de features",
  [keys.SUP_inReview]: "Em revisão",
  [keys.SUP_responseViaEmail]:
    "Por gentileza notar que você receberá sua resposta por e-mail.",
  [keys.SUP_onboarding]: "Onboarding",
  [keys.SUP_onboardingDescription]:
    "Registros de números, adição de números, portabilidade de números, Embedded signup, etc...",
  [keys.SUP_problemWithMessaging]: "Problema com o API de mensagens",
  [keys.SUP_messagingProblemsDescription]:
    "Mensagens não chegando, mensagens de erro na API, atrasos nas mensagens, números desconectados, etc...",
  [keys.SUP_accManagement]: "Gerenciamento de contas",
  [keys.SUP_accManagementDescription]:
    "Verificação de Meta negócios, termos de Meta negócios, política comercial, nomes de exibição, contas comerciais oficiais, templates, limites de mensagens, níveis de qualidade, insights, balanço, mudanças de parceiros, etc...",
  [keys.SUP_offboarding]: "Offboarding",
  [keys.SUP_offboardingDescription]:
    "Cancelamento, finalização, desativação do 2FA, migração para outro BSP, downgrades, etc...",
  [keys.SUP_financeAndBilling]: "Finanças e faturamento",
  [keys.SUP_financeAndBillingDescription]:
    "Faturamento, assinaturas, reembolsos, formas de pagamento, etc...",
  [keys.SUP_otherQuestion]: "Outras perguntas",
  [keys.SUP_otherQuestionDescription]:
    "Qualquer outra coisa que não esteja listada nas categorias acima",
  //  [keys.SUP_visitStatusPage1]: "translation_missing",
  //  [keys.SUP_visitStatusPage2]: "translation_missing",
  //  [keys.SUP_useIntercom]: "translation_missing",
};

export default translate;
