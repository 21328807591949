// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.NOT_allNotifications]: "Alle",
  [keys.NOT_archivedNotifications]: "Archiviert",
  [keys.NOT_closeButtonTooltip]: "Diese Benachrichtigung archivieren",
  [keys.NOT_archiveAllBtn]: "Alle archivieren",
  [keys.NOT_noNewNotifications]:
    "Das sind alle deine Benachrichtigungen der letzten 30 Tage.",
  //  [keys.NOT_clientAdded]: "translation_missing",
  //  [keys.NOT_clientAddedWithMigrationDate]: "translation_missing",
  [keys.NOT_rejectionReason]: "Grund der Ablehnung:",
  [keys.NOT_alreadyApproved]:
    "Du hast diese Änderungsanfrage bereits genehmigt",
  [keys.NOT_apiKeyRecommendation]:
    "Wir empfehlen, einen neuen API-Schlüssel abzurufen",
  [keys.NOT_generateNewAPIKey]: "Neuen API-Schlüssel generieren",
  [keys.NOT_partnerRequestFailed]:
    "Fehlgeschlagen: Vorgang konnte nicht abgeschlossen werden",
  [keys.NOT_partnerRequestCancelled]: "Der Kunde hat die Anfrage abgebrochen",
  [keys.NOT_needCaptcha]: "Bitte bestätige, dass du kein Roboter bist",
  [keys.NOT_errorUpdatingChatSupport]:
    "Es gibt ein Problem mit der Aktualisierung des Chat-Supportstatus. Bitte versuche es später noch einmal.",
  // [keys.NOT_allNumbersAffected]: "translation_missing",
};

export default translate;
