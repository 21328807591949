import * as Types from "./partners.types";
import { partnersStatus } from "../admin/statuses";
import { UIModelSingleClient } from "../clients/clients.model";
import { TBillingSystem } from "../accounting/accounting.types";

export class UIModelPartners {
  total: number;
  partners: UIModelPartner[];

  constructor(response: Types.IUIResponsePartners) {
    this.total = response.total;
    this.partners = response.partners.map(
      (partner) => new UIModelPartner(partner)
    );
  }
}

export class UIModelPartner {
  id: string;
  name: string;
  defaultOrganisation: string;
  status: partnersStatus;
  orderDocument: string;
  license: string;
  salesUserId: string;
  financeUserId: string;
  operationUserId: string;
  dialogSalesUserId: string;
  paymentRequired: boolean;
  allowClientToAddPhoneNumber: boolean;
  businessInfo: UIModelPartnerBusinessInfo;
  contactInfo: UIModelPartnerContactInfo;
  createdAt: Date;
  modifiedAt: Date;
  logoUrl: string;
  brandName: string;
  deeplinkUrl: string;
  enabledForChatSupport: boolean;
  partnerRedirectUrl: string;
  billing_system: TBillingSystem;
  settings: {
    default_data_localization_region: string | null;
    standard_tiered_pricing_enabled?: boolean;
    account_sharing?: {
      business_manager_id?: string;
      solution_id?: string;
    };
  };
  paymentPlan: {
    currency: string;
    price: number;
    stripe_price_id: string;
  };
  cloudApiPermitted?: boolean;

  constructor(response: Types.IUIResponsePartner) {
    this.update(response);
  }

  update = (response: Types.IUIResponsePartner) => {
    Object.assign(this, response);
    this.id = response.id;
    this.name = response.name;
    this.defaultOrganisation = response.defaultOrganisation;
    this.status = response.status;
    this.orderDocument = response.orderDocument;
    this.salesUserId = response.salesUserId;
    this.financeUserId = response.financeUserId;
    this.operationUserId = response.operationUserId;
    this.dialogSalesUserId = response.dialogSalesUserId;
    this.license = response.license;
    this.paymentRequired = response.paymentRequired;
    this.allowClientToAddPhoneNumber = response.allowClientToAddPhoneNumber;
    this.businessInfo = new UIModelPartnerBusinessInfo(response.businessInfo);
    this.contactInfo = new UIModelPartnerContactInfo(response.contactInfo);
    this.createdAt = new Date(response.createdAt);
    this.modifiedAt = new Date(response.modifiedAt);
    this.logoUrl = response.logoUrl;
    this.brandName = response.brandName;
    this.deeplinkUrl = response.deeplinkUrl;
    this.enabledForChatSupport = response.enabledForChatSupport;
    this.partnerRedirectUrl = response.partnerRedirectUrl;
    this.paymentPlan = response.paymentPlan;
  };

  get formValues() {
    const { businessInfo, contactInfo, createdAt, modifiedAt, id, ...rest } =
      this;
    const { ...restBusinessInfo } = businessInfo;
    const { address, ...restContactInfo } = contactInfo;

    return {
      ...rest,
      ...restBusinessInfo,
      ...restContactInfo,
      ...address,
      createdAt,
      modifiedAt,
      id,
      name: this.name,
    };
  }

  get hasRequiredDataForActivation() {
    return !!(
      this.license &&
      this.operationUserId &&
      this.contactInfo.language
    );
  }
}

export class UIModelPartnerBusinessInfo {
  name: string;
  organisation: string;
  notes: string;
  markets: string;
  timezone: string;
  description: string;

  constructor(response: Types.IUIResponseBusinessInfo) {
    this.name = response.name;
    this.organisation = response.organisation;
    this.notes = response.notes;
    this.markets = response.markets;
    this.timezone = response.timezone;
    this.description = response.description;
  }
}

export class UIModelPartnerContactInfo {
  website: string;
  phoneNumber: string;
  email: string;
  language: string;
  address: UIModelPartnerContactInfoAdress;

  constructor(response: Types.IUIResponseContactInfo) {
    this.website = response.website;
    this.phoneNumber = response.phoneNumber;
    this.email = response.email;
    this.language = response.language;
    this.address = new UIModelPartnerContactInfoAdress(response.address);
  }
}

export class UIModelPartnerContactInfoAdress {
  country: string;
  streetName: string;
  city: string;
  zipCode: string;
  state: string;

  constructor(response: Types.IUIResponseContactInfoAdress) {
    this.country = response.country;
    this.streetName = response.streetName;
    this.city = response.city;
    this.zipCode = response.zipCode;
    this.state = response.state;
  }
}

export class UIModelFacebookBusinessAccountInfo {
  id: string;
  name: string;
  primaryPage: {
    name: string;
    id: string;
  } | null;
  vertical: string;
  timezoneId: string;
  verificationStatus: string;
  link: string;
  client: UIModelSingleClient | null;
  verticalId: string;
  picture: {
    data: {
      url: string;
    };
  } | null;
  profilePictureUrl: string;

  constructor(response: Types.IUIResponseFacebookBusinessAccountInfo) {
    this.id = response.id;
    this.name = response.name;
    if (response.primaryPage) {
      this.primaryPage = {
        name: response.primaryPage.name,
        id: response.primaryPage.id,
      };
    } else {
      this.primaryPage = response.primaryPage;
    }
    this.vertical = response.vertical;
    this.timezoneId = response.timezoneId;
    this.verificationStatus = response.verificationStatus;
    this.link = response.link;
    this.verticalId = response.verticalId;
    if (response.picture) {
      this.picture = {
        data: {
          url: response.picture.data.url,
        },
      };
    } else {
      response.picture = response.picture;
    }
    if (response.client) {
      this.client = new UIModelSingleClient(response.client);
    } else {
      this.client = null;
    }
    this.profilePictureUrl = response.profilePictureUrl;
  }
}

export class UIModelFilterOptions {
  partnerId: string;
  type: string;
  enabled: boolean;
  content: {
    timezones: Types.IServerResponseTimezones[];
    namespaceLanguages: Types.IServerResponseNamespaceLanguages[];
  };

  constructor(response: Types.IUIResponseFilterOptions) {
    this.partnerId = response.partnerId;
    this.type = response.type;
    this.enabled = response.enabled;
    this.content = response.content;
  }
}

export class UIModelPartnerUser {
  createdAt: Date;
  modifiedAt: Date;
  blocked: boolean;
  emailVerified: string;
  id: string;
  login: string;
  name: string;
  email: string;
  picture: string;
  password: string;
  permissions: string;

  constructor(response: Types.IUIResponsePartnerUser) {
    this.createdAt = new Date(response.createdAt);
    this.modifiedAt = new Date(response.modifiedAt);
    this.blocked = response.blocked;
    this.emailVerified = response.emailVerified;
    this.id = response.id;
    this.login = response.login;
    this.name = response.name;
    this.email = response.email;
    this.picture = response.picture;
    this.password = response.password;
    this.permissions = response.permissions;
  }
}

export class UIModelPartnerPaymentInfo {
  paymentRequired: boolean;
  paymentPlan: {
    currency: string;
    price: string;
  };
  publishableKey: string;
  logoUrl: string;
  brandName: string;
  campaign?: Types.IServerResponseCampaign;
  id: string;
  pbsEnabled: boolean;
  settings: {
    standard_tiered_pricing_enabled: boolean;
  };
  billingSystem: string;

  constructor(response: Types.IUIResponsePartnerPaymentInfo) {
    this.paymentPlan = {
      currency: response.paymentPlan.currency,
      price: response.paymentPlan.price,
    };
    this.paymentRequired = response.paymentRequired;
    this.publishableKey = response.publishableKey || "";
    this.logoUrl = response.logoUrl;
    this.brandName = response.brandName;
    this.id = response.id;
    this.pbsEnabled = response.pbsEnabled;
    this.campaign = response.campaign || undefined;
    this.settings = response.settings;
    this.billingSystem = response.billingSystem;
  }
}

export class UIModelPartnerShort {
  id: string;

  constructor(response: Types.UIResponsePartnerShort) {
    this.id = response.id;
  }
}

export class ExtendedUIModelPartnerPaymentInfo extends UIModelPartnerPaymentInfo {
  monthlyPlan: {
    currency: string;
    price: string;
    description?: string[];
    discountPercentage?: number;
  };

  yearlyPlan: {
    currency: string;
    price: string;
    description?: string[];
    discountPercentage?: number;
  };

  constructor(options: Types.IPartnerPaymentInfoOptions) {
    super(options);
    this.monthlyPlan = options.monthlyPlan;
    this.yearlyPlan = options.yearlyPlan;
  }
}

export class ExtendedUIModelPartnerPaymentInfoVariant extends UIModelPartnerPaymentInfo {
  basicPlan: {
    currency: string;
    price: string;
    description?: Array<{ text: string; upgrade?: boolean }>;
  };

  professionalPlan: {
    currency: string;
    price: string;
    description?: Array<{ text: string; upgrade?: boolean }>;
  };

  constructor(options: Types.IPartnerPaymentPlanVariantOptions) {
    super(options);
    this.basicPlan = options.basicPlan;
    this.professionalPlan = options.professionalPlan;
  }
}

export class ExtendedTypeUIModelPartnerPaymentInfo extends UIModelPartnerPaymentInfo {
  paymentPlan: {
    currency: string;
    price: string;
    type: "basic" | "premium";
    billingPlan: "monthly" | "yearly" | "two-yearly";
    description?: Array<{ text: string; upgrade?: boolean }>;
  };
  id: string;
  settings: {
    standard_tiered_pricing_enabled: boolean;
  };

  constructor(response: Types.ExtendedIUIResponsePartnerPaymentInfo) {
    super(response);
    this.paymentPlan = {
      currency: response.paymentPlan.currency,
      price: response.paymentPlan.price,
      type: response.paymentPlan.type,
      billingPlan: response.paymentPlan.billingPlan,
      description: response.paymentPlan.description,
    };
    this.settings = response.settings;
  }
}
export class UIModelPartnerChangeRequestsInfo {
  filters: {
    newPartnerId: string;
  };
  count: number;
  limit: number;
  offset: number;
  sort: Array<string>;
  total: number;
  partnerChangeRequests: Array<{
    clientId: string;
    createdAt: string;
    createdBy: any;
    id: string;
    messageForNewPartner: string;
    migrationDate: Date;
    modifiedAt: Date;
    modifiedBy: any;
    newPartnerId: string;
    oldPartnerId: string;
    rejectionReason: string;
    status: string;
  }>;

  constructor(response: Types.IUIResponsePartnerChangeRequestsInfo) {
    this.filters = response.filters;
    this.count = response.limit;
    this.limit = response.limit;
    this.offset = response.offset;
    this.sort = response.sort;
    this.partnerChangeRequests = response.partnerChangeRequests;
  }
}
