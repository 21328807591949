import * as Types from "./project.types";

export enum licenseMethods {
  CLOUD = "cloud",
  ON_PREMISE = "on_premise",
}

export class UIModelProjects {
  projects: UIModelProject[];
  total: number;

  constructor(response: Types.IUIResponseProjects) {
    this.projects = response.projects.map(
      (project) => new UIModelProject(project)
    );
    this.total = response.total;
  }
}

export class UIModelProject {
  appId: null | string;
  clientId: string;
  partnerId: string;
  createdAt: Date;
  modifiedAt: Date;
  inbox?: boolean;
  licenseModel?: string;
  id: string;
  name: string;
  status: string;
  apiUserEmail?: string;
  webhook: {
    url: string;
  };

  constructor(response: Types.IUIResponseProject) {
    this.update(response);
  }

  update = (response: Types.IUIResponseProject) => {
    this.appId = response.appId;
    this.clientId = response.clientId;
    this.partnerId = response.partnerId;
    this.createdAt = new Date(response.createdAt);
    this.modifiedAt = new Date(response.modifiedAt);
    this.inbox = response.inbox;
    this.licenseModel = response.licenseModel;
    this.id = response.id;
    this.name = response.name;
    this.status = response.status;
    this.apiUserEmail = response.apiUserEmail;
    this.webhook = {
      url: response.webhook.url,
    };
  };

  get formValues() {
    return {
      name: this.name,
      inbox: this.inbox,
      licenseModel: this.licenseModel,
      webhookUrl: this.webhook.url,
      apiUserEmail: this.apiUserEmail,
    };
  }
}
