import * as Types from "./comments.types";

export class UIModelComment {
  id: string;
  partnerId: string;
  clientId: string;
  wabaAccountId: string;
  channelId: string;
  content: string;
  createdAt: string;
  createdBy: {
    userName: string;
    userId: string;
  };

  constructor(response: Types.IUIResponseComment) {
    this.id = response.id;
    this.partnerId = response.partnerId;
    this.clientId = response.clientId;
    this.wabaAccountId = response.wabaAccountId;
    this.channelId = response.channelId;
    this.content = response.content;
    this.createdAt = response.createdAt;
    this.createdBy = {
      userName: response.createdBy.userName,
      userId: response.createdBy.userId,
    };
  }
}

export class UIModelComments {
  total: number;
  comments: UIModelComment[];

  constructor(response: UIModelComments) {
    this.comments = response.comments.map(
      (comment) => new UIModelComment(comment)
    );
    this.total = response.total;
  }
}
