import { MPInstancesHub } from "@/api/RequestAxios/RequestAxiosHub";
import * as Types from "./public.types";
import { unpackOutageEvent } from "./public.mapper";

const outageEventURL = `/public/outage_feed`;
const captchaURL = "/verify_grecaptcha";
const triggerEmailVerificationURL = `/public/generate_otp`;
const verifyEmailURL = ({ otp }: { otp: string }) =>
  `/public/verify_otp/${otp}`;

export const outageEvent = async () => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get<
    Array<Types.IServerResponseOutage>
  >(outageEventURL);

  return unpackOutageEvent(data);
};

export const verifyCaptcha = async (token: string) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    captchaURL,
    { token }
  );

  return data;
};

export const triggerEmailVerification = async ({
  email,
}: {
  email?: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    triggerEmailVerificationURL,
    email ? { email: email } : null
  );
  return data;
};

export const verifyEmail = async ({
  email,
  otp,
}: {
  email?: string;
  otp: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    verifyEmailURL({ otp }),
    email ? { email: email } : null
  );
  return data;
};
