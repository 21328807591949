import styled from "styled-components";

export const NotificationSystem = styled.div.attrs({
  classNames: "NotificationSystem",
})`
  height: auto;
  min-width: 10rem;
  max-width: 80rem;
  min-height: 5rem;
  position: absolute;
  z-index: 2147483999; // increasing this to this value because intercom zIndex is 2147483003

  max-height: 30vh;

  padding: 2.4rem;
  right: 0px;
  bottom: 0px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  align-items: flex-end;
  justify-content: center;
  @media only screen and (min-width: 740px) {
    position: fixed;
    bottom: 24px;
    right: 24px;
    padding: 0px;
    width: auto;
  }
`;
