// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.FAQ_q_1]:
    "Was ist die Meta Unternehmensverifizierung und warum muss ich diese durchführen?",
  //  [keys.FAQ_a_1]: "translation_missing",
  [keys.FAQ_q_2]:
    "Welche Dokumente benötige ich für die Meta Unternehmensverifizierung?",
  [keys.FAQ_a_2]:
    "In unserer <DOCS> findest du eine Liste aller von Meta benötigten Dokumente.",
  [keys.FAQ_q_3]: "Kann ich weiterhin die WhatsApp Business App nutzen?",
  [keys.FAQ_a_3]:
    "Sobald du den Registrierungsprozess bei 360dialog abgeschlossen hast, erstellen wir einen WhatsApp Business Account für die von dir angegebene Nummer. Es besteht daraufhin leider keine Möglichkeit mehr zurück auf die WhatsApp Business App zu wechseln.",
  [keys.FAQ_q_4]:
    "Ich habe die SMS mit dem Verifizierungs-Code nicht erhalten. Woran liegt das?",
  [keys.FAQ_a_4]:
    "Zunächst überprüfe bitte nochmals, dass du die korrekte Nummer im Feld angegeben hast. Empfängst du immer noch keinen Code, so kann es sein, dass deine Nummer bereits mit einem bestehenden WhatsApp Business App oder auch einem privaten WhatsApp Konto verbunden ist.",
  [keys.FAQ_q_5]:
    "Ich kann die Option zur Erstellung meines API Schlüssels nicht sehen. Wo finde ich diese?",
  [keys.FAQ_a_5]:
    "Sobald deine Nummer von uns erstellt und gestartet wurde, solltest du immer die Möglichkeit haben einen neuen API Schlüssel zu erstellen. Wenn du dafür weder in der WhatsApp Accounts Seite, noch in der Detailseite der Nummer einen Button findest, dann ist das Setup deiner Nummer womöglich noch nicht vollständig abgeschlossen.",
  [keys.FAQ_q_6]:
    "Was ist die Nachrichtenweiterleitung an den Partner und warum muss ich sie aktivieren?",
  [keys.FAQ_a_6]:
    "Wenn du die Option siehst, deine Nachrichten an deinen Partner weiterzuleiten, hast du höchstwahrscheinlich ein Produkt bei einem Partnerunternehmen registriert, das deine Nachrichten erhalten muss, um wie vorgesehen zu funktionieren. Nur du kannst dem Partner die Erlaubnis erteilen, diese Nachrichten zu erhalten, indem du die Nachrichtenweiterleitung aktivierst.",
  //  [keys.FAQ_q_7]: "translation_missing",
  [keys.FAQ_a_7]:
    "Wenn du ein Produkt bei einem Integrationspartner registriert hast, ist eine API nicht mehr erforderlich. Das Einzige, was du tun musst, ist, die Weiterleitung deiner Nachrichten an deinen Partner zu aktivieren. Wenn du eine eigene Anwendung entwickelst und deshalb einen API-Schlüssel erstellen musst, wende dich bitte an unser Support-Team.",
};

export default translate;
