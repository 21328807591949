import { k } from "@/utilities/i18n/index";
import translate from "@/utilities/i18n/getTranslate";

// Because of inconsistencies with the error response returned from the backend
// we try our best here to handle all scenario we are aware of

function errorHandler(error: any) {
  let error_message: string = translate(k.ERR_somethingWentWrong);

  if (error?.response?.data?.error_description) {
    error_message = error.response?.data?.error_description;
  } else if (error?.response?.data?.meta?.details) {
    // details can be an array or a string
    const details = error.response?.data?.meta?.details;
    if (Array.isArray(details)) {
      // its an array
      error_message = details.join(" ");
    } else if (typeof details === "string") {
      error_message = details;
    }
  } else if (
    typeof error?.response?.data?.meta?.developer_message === "string"
  ) {
    error_message = error.response?.data?.meta?.developer_message;
  } else if (typeof error?.response?.data === "string") {
    error_message = error.response?.data;
  } else if (error?.response?.data?.error) {
    error_message = error.response.data.error;
  } else if (error?.response?.data?.detail) {
    error_message = error.response.data.detail;
  }

  return error_message;
}

export default errorHandler;
