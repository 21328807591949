import store from "store";
import config from "@/config";
import { UIModelLogin } from "@/services/api/auth/auth.model";
import addSeconds from "date-fns/addSeconds";

export interface RequestCacheAuthenticate {
  token: string;
  tokenExpiresAt: string;
  [k: string]: string;
}

export enum customKeys {
  LAST_LOCALIZTIONS = "lastLocalization",
  NEW_LOCALIZTIONS = "newLocalization",
  ERROR_EVENT_ID = "errorEventId",
  CLIENT_ID = "clientId",
  PARTNER_ID = "partnerId",
  SHOULD_SHOW_FUNNEL_INTRO = "shouldShowFunnelIntro",
}

const requestCacheAuthenticate = (): RequestCacheAuthenticate => {
  const token = store.get(`${config.localStoragePrefixMP}.token`);
  const tokenExpiresAt = store.get(
    `${config.localStoragePrefixMP}.tokenExpiresAt`
  );

  return {
    token,
    tokenExpiresAt,
  };
};

const saveCacheAuthenticate = ({ accessToken, expiresIn }: UIModelLogin) => {
  store.set(`${config.localStoragePrefixMP}.token`, accessToken);
  store.set(
    `${config.localStoragePrefixMP}.tokenExpiresAt`,
    addSeconds(new Date(), parseInt(expiresIn))
  );
};

const clearCacheAuthenticate = () => {
  store.remove(`${config.localStoragePrefixMP}.token`);
  store.remove(`${config.localStoragePrefixMP}.tokenExpiresAt`);
};

const saveToLocalStorage = (key: string, value: string) => {
  if (!value.includes("/auth/")) {
    store.set(`${config.localStoragePrefixMP}.${key}`, value);
  }
};

const clearItemLocalStorage = (key: string) => {
  return store.remove(`${config.localStoragePrefixMP}.${key}`);
};

const clearLocalStorage = () => {
  return store.clearAll();
};

const getCurrentLanguage = () => store.get("i18nextLng");

const getFromLocalStorage = (key: string) => {
  return store.get(`${config.localStoragePrefixMP}.${key}`);
};

const removeNewLocalizationFromLocalStorage = () => {
  store.remove(`${config.localStoragePrefixMP}.${customKeys.NEW_LOCALIZTIONS}`);
};

const removeLastLocalizationFromLocalStorage = () => {
  store.remove(
    `${config.localStoragePrefixMP}.${customKeys.LAST_LOCALIZTIONS}`
  );
};

const localStorageOptions = {
  requestCacheAuthenticate,
  saveCacheAuthenticate,
  clearCacheAuthenticate,
  saveToLocalStorage,
  getFromLocalStorage,
  customKeys,
  removeNewLocalizationFromLocalStorage,
  getCurrentLanguage,
  removeLastLocalizationFromLocalStorage,
  clearItemLocalStorage,
  clearLocalStorage,
};

export default localStorageOptions;
