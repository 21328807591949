// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.A_SomethingWentWrongError]: "Algo salió mal",
  [keys.A_SelectDefaultPlaceholder]: "Seleccione...",
  [keys.A_phoneNumber]: "Número de teléfono",
  [keys.A_waba]: "Cuenta de WhatsApp Business",
  [keys.A_client]: "Cliente",
  [keys.A_partner]: "Partner",
  [keys.A_documentation]: "Documentación",
  [keys.A_email]: "Email",
  [keys.A_password]: "Contraseña",
  [keys.A_back]: "Volver",
  [keys.A_sortBy]: "Organizar",
  [keys.A_date]: "fecha",
  [keys.A_status]: "estado",
  [keys.A_displayName]: "Display name",
  [keys.A_filterByStatus]: "filtrar por estado",
  [keys.A_searchHere]: "Buscar aquí",
  [keys.A_account]: "Cuenta",
  [keys.A_accountName]: "Nombre de la cuenta",
  [keys.A_search]: "Buscar",
  [keys.A_cancel]: "Cancelar",
  [keys.A_continue]: "Continuar",
  [keys.A_close]: "Cerrar",
  [keys.A_delete]: "Eliminar",
  [keys.A_edit]: "Modificar",
  [keys.A_and]: "Es",
  [keys.A_name]: "Nombre",
  [keys.A_deletePlaceholder]: "Escriba <DELETE> para confirmar",
  [keys.A_deleteHelptextDefault]:
    "Esta acción no se puede deshacer. ¿Está seguro de que desea continuar?",
  [keys.A_viewDocs]: "Ver la documentación",
  [keys.A_defaultLanguage]: "Idioma predeterminado",
  [keys.A_save]: "Guardar",
  [keys.A_learnMore]: "Más información",
  [keys.A_audiencesLearnMore]: "Más Información",
  [keys.A_address]: "Dirección",
  [keys.A_description]: "Descripción",
  [keys.A_website]: "Sitio web",
  [keys.A_deleteImage]: "Eliminar imagen",
  [keys.A_dragYourImage]: "Arrastre su imagen aquí o",
  [keys.A_browse]: "buscar",
  [keys.A_supportsFormats]: "Soporta los formatos jpg, png y svg",
  [keys.A_upload]: "Subir",
  [keys.A_imageMaxSize]: "La imagen debe ser menor de 3MB",
  [keys.A_paymentMethodCountrySelectLabel]:
    "Seleccione su país para ver los métodos de pago disponibles",
  [keys.A_intCreditCard]: "Tarjeta de crédito internacional",
  [keys.A_paymentMethodSelectLabel]: "Seleccione su modo de pago preferido",
  [keys.A_savePaymentMethod]: "Guardar modo de pago",
  [keys.A_country]: "País",
  [keys.A_cardExistingText]: "Ya ha introducido una tarjeta de crédito.",
  [keys.A_addAnotherCardButton]: "Añadir otra tarjeta",
  [keys.A_creditCardFormHelptext]:
    "Por favor, introduzca los datos de su tarjeta de crédito más abajo.",
  [keys.A_submitCardButton]: "Introducir tarjeta",
  [keys.A_termsStep1]: "Estoy de acuerdo con",
  [keys.A_termsOfService]: "Términos de Servicio",
  [keys.A_termsConnect]: "y con",
  [keys.A_terms360exhibit1]: "exhibit 1 - Términos de Uso de 360dialog",
  [keys.A_terms360exhibit3]: "exhibit 3 - Tarifas de 360dialog",
  [keys.A_waTermsStep1]: "He leído y estoy de acuerdo con",
  [keys.A_terms360exhibit2]:
    "exhibit 2 - Términos de Cliente para WhatsApp de Meta",
  [keys.A_partnerTermsConnect]: "y con",
  [keys.A_privacyPolicy]: "Política de Privacidad",
  [keys.A_partnersTermsAndConditions]: "Términos y Condiciones",
  [keys.A_selectNumberTitle]: "Seleccionar número",
  [keys.A_addNewNumber]: "Añadir nuevo número",
  [keys.A_or]: "o",
  [keys.A_tooltipEmbeddedSignup]:
    "Los registros que utilicen este formulario seguirán el flujo de activación del Embedded Signup. Active cuentas en menos de 10 minutos y finalice el proceso de verificación más tarde. <LINK>https://docs.360dialog.com/embedded-signup | Learn more.<LINK>",
  [keys.A_tooltipClassicSignup]:
    "Los registros que utilicen este formulario seguirán el flujo de activación Clásico. Las empresas deben estar completamente verificadas antes de acceder a la API. <LINK>https://docs.360dialog.com/submission-process | Learn more.<LINK>",
  [keys.A_loading]: "Cargando",
  [keys.A_notImage]: "El archivo subido debería ser jpg, png o svg",
  [keys.A_tooltipNumberPortingSignup]:
    "Los envíos mediante este formulario permitirán a los clientes crear una cuenta por medio de la portabilidad de un número desde otro BSP. <LINK>https://docs.360dialog.com/360-client-hub/number-porting | Más información.<LINK>",
  [keys.A_FbmId]: "Business Manager ID",
  [keys.A_buttonLabelReturnToHub]: "Volver para el 360dialog Client Hub",
  [keys.A_notificationNoPermission]:
    "No tienes permiso para acceder a esta página porque esta cuenta está conectada a otro partner. Si deseas migrar tus números a este partner, solicita un cambio de partner en tu Perfil -> 'Configuración de la organización' a través de hub.360dialog.com.",
  [keys.A_textNoPermission]:
    "No tienes permiso para acceder a esta página porque esta cuenta está conectada a otro partner.",
  [keys.A_continueNext]: "Seguir para el próximo paso",
  [keys.A_pleaseTryAgain]:
    "En este momento estamos experimentando problemas con el inicio de sesión. Por favor inténtelo de nuevo en 30 minutos.",
  [keys.A_clientsSearchPlaceholder]: "Buscar nombre",
  [keys.A_terms]: "Términos",
  [keys.A_userNotExists]:
    "Este usuario no existe. Regístrese a través de la plataforma de su partner o en 360dialog.com",
  [keys.A_verificationError]: "Algo salió mal. Por favor, vuelva a intentarlo",
  [keys.A_partnerIsBlocked]:
    "El partner está bloqueado para nuevas solicitudes",
  [keys.A_urlCopiedToClipboard]: "URL copiada al Portapapeles",
  [keys.A_latestSearch]: "Última búsqueda",
  [keys.A_loadingBillingPortal]: "Cargando portal de facturación",
  [keys.NOT_allNotifications]: "Todo",
  [keys.NOT_archivedNotifications]: "Archivado",
  [keys.NOT_closeButtonTooltip]: "Archivar esta notificación",
  [keys.NOT_archiveAllBtn]: "Archivar todo",
  [keys.NOT_clientAdded]: "Se agregará un nuevo cliente a su partner hub",
  [keys.A_errorDownloadingCSV]:
    "Se ha producido un error al intentar descargar el archivo CSV. Por favor, inténtelo de nuevo más tarde.",
  [keys.A_financialReportSentToEmail]:
    "El informe financiero se enviará a su dirección de correo electrónico en breve.",
  [keys.A_errorGeneratingFinancialReport]:
    "Ha ocurrido un error al generar el informe financiero. Por favor, inténtelo de nuevo más tarde.",
  [keys.A_exportAllContactData]: "Exportar todos los datos de contacto",
  [keys.A_generateFinancialReport]: "Generar informe financiero",
  [keys.A_generateInvoices]: "Generar facturas",
  [keys.A_templateMessages]: "Mensajes de plantilla",
  [keys.A_generateTemplateInvoices]: "Generar pantillas de factura",
  [keys.A_generateTemplateReport]: "Generar plantilla de informe",
  [keys.A_conversationBasedPricing]: "Precios por conversación",
  [keys.A_generateCBPInvoices]: "Generar facturas de CBP",
  //  [keys.A_generateCBPDailyReport]: "translation_missing",
  [keys.A_generateCBPMonthlyReport]: "Generar informe mensual de CBP",
  [keys.A_invoiceCreationTriggered]:
    "Se activó la creación de la factura. Las facturas se enviarán por correo electrónico.",
  [keys.A_invoiceDateNeedsToBeFirstOrLast]:
    "La fecha de facturación debe ser el primer o el último día de un mes.",
  [keys.A_dateOfInvoice]: "Fecha de facturación",
  [keys.A_selectInvoicingDate]: "Selecciona fecha de facturación",
  [keys.A_reportCreationTriggered]:
    "Se activó la creación de informes. El informe se enviará por correo electrónico.",
  [keys.A_triggerInvoiceCreation]: "Activar la creación de facturas",
  [keys.A_selectReportDate]: "Selecciona la fecha del informe",
  [keys.A_dateOfReport]: "Fecha del informe",
  [keys.A_monthlyReportCreationTriggered]:
    "Se ha activado la creación de informes mensuales. El informe se enviará por correo electrónico.",
  [keys.A_dailyReportCreationTriggered]:
    "Se ha activado la creación de informes diarios. El informe se enviará por correo electrónico.",
  [keys.A_selectMonthlyReportDate]: "Selecciona fecha del informe mensual",
  //  [keys.A_selectDailyReportDate]: "translation_missing",
  [keys.A_somethingWentWrongnTryAgain]:
    "Algo salió mal. Por favor, inténtalo más tarde",
  [keys.A_linkURL]: "Link URL",
  [keys.A_parameters]: "Parámetros",
  [keys.A_imageRecommendation]:
    "Recomendación: tamaño máximo de 5 MB y tamaño de imagen de 640x640.",
  [keys.A_displayLogoAndDeepLinkNotice]:
    "Así es como los clientes van a ver tu logotipo y nombre de marca, y cómo interactuarán con tu deeplink URL en varios lugares dentro del 360dialog Client Hub.",
  [keys.A_attentionToShowLogoAndBrandSet]:
    "el siguiente elemento solo se mostrará si se establecen tanto el logotipo de la empresa como el nombre de la marca.",
  [keys.A_attentionToShowIfURLProvided]:
    "Así es como los clientes van a ver tu logotipo y nombre de marca, en varios lugares dentro del 360dialog Client Hub.",
  [keys.A_companyLogo]: "Logotipo de la empresa",
  [keys.A_uploadLogo]: "Subir Logotipo",
  [keys.A_profilePreview]: "Previsualización del perfil",
  [keys.A_deeplinkURL]: "Deeplink URL",
  [keys.A_logoChangeSuccess]: "El logotipo ha sido cambiado",
  [keys.A_deeplinkURLToolTipContent]:
    "Si se estableces este valor, los usuarios tendrán la opción de hacer clic en un botón después de crear su clave de API,  que los llevará de regreso a tu aplicación, donde pueden colocar la clave de API.",
  //  [keys.A_logoAndBrandNameVariantOne]: "translation_missing",
  [keys.A_logoAndBrandNameVariantTwo]: "Variante 2: Logotipo y marca",
  [keys.A_featured]: "Destacado",
  [keys.A_verifyHuman]: "Verifica que eres un humano",
  [keys.A_insightsAndFunds]: "Información & fondos",
  [keys.A_letsDoIt]: "Vamos a hacerlo!",
  [keys.A_paymentPlan]: "Seleccione su plan",
  [keys.A_newNumberPlan]: "Seleccione su plan para el nuevo número ",
  [keys.A_betterSla]: "Mejor SLA",
  [keys.A_higherScalability]: "Mayor escalabilidad",
  [keys.A_verifyBusiness]: "Verifique su empresa!",
  [keys.A_verifyFBM]:
    "Dirígete a Meta Business Manager para comenzar con el proceso de verificación comercial.",
  [keys.A_accessCompanyFb]:
    "Para continuar, deberá <BOLD>acceder a la cuenta comercial de Meta de su empresa.<BOLD>",
  [keys.A_continueWithFbButton]:
    "Al hacer clic en el botón Continuar con Meta a continuación, puede:",
  [keys.A_addNumbers]: "Agregar números",
  [keys.A_addNumber]: "Agregar número",
  [keys.A_addFirstNumber]:
    "Aún no has añadido ningún número de teléfono. Agrega tu primer número para comenzar la plataforma de WhatsApp Business.",
  [keys.A_termsAndConditions]: "Términos & Condiciones",
  [keys.A_paymentDetails]: "Detalles del pago",
  [keys.A_facebookSetup]: "Configuración de Meta",
  [keys.A_termsAndPriceList1]:
    "Acepto los <LINK>https://www.360dialog.com/contact#privacy-policy | Términos de Servicio de 360dialog<LINK>, y al",
  [keys.A_termsAndPriceList2]:
    "<LINK>https://www.360dialog.com/whatsapp-business-api?origin=new_hub#pricing | Listado de precios de 360dialog<LINK>.",
  [keys.A_user]: "Usuario",
  [keys.A_insightsPageTitle]: "360dialog - Copilot",
  [keys.A_insightsPageDescription]: "Ver información y fondos",
  [keys.A_supportPageTitle]: "360dialog - Soporte",
  [keys.A_supportPageDescription]: "Soporte y contacto",
  [keys.A_numberPageTitle]: "360dialog - Su Número",
  [keys.A_numberPageDescription]: "Ver su número",
  [keys.A_detailsPageTitle]: "360dialog - Detalles del Canal",
  [keys.A_detailsPageDescription]: "Ver detalles sobre un canal",
  [keys.A_changeDisplayName]: "Cambiar display name",
  [keys.A_pleaseContinueRegistration]:
    "Por favor, continúa el registro de tu número",
  [keys.A_continueRegistration]: "Continuar registro",
  [keys.A_continueVerification]: "Continuar verificación",
  [keys.A_numbers]: "Números",
  [keys.A_inappropriatePassword]:
    "No se puede crear el usuario. Razón: la contraseña contiene información del usuario",
  [keys.A_businessVerifiedButton]: "Ya he verificado mi negocio",
  [keys.A_businessNotVerifiedButton]: "Continuar sin verificación",
  [keys.A_chatSupportEnabled]: "Soporte por chat habilitado",
  [keys.A_acceptAndClose]: "Aceptar y cerrar",
  [keys.A_fullInfoDetails]:
    "Puedes acceder a la información completa {{hereLink}}",
  [keys.A_here]: "aquí",
  //  [keys.A_subProcessorPopupTitle]: "translation_missing",
  //  [keys.A_thatsAllNotifications]: "translation_missing",
  //  [keys.A_errorPaymentToken]: "translation_missing",
  //  [keys.A_maintenance]: "translation_missing",
  //  [keys.A_userMaintenance]: "translation_missing",
  //  [keys.A_actions]: "translation_missing",
  //  [keys.A_signals]: "translation_missing",
  //  [keys.A_manage]: "translation_missing",
  //  [keys.A_qualityRating]: "translation_missing",
  //  [keys.A_variantIOTitle]: "translation_missing",
  //  [keys.A_allowClientsAddNumber]: "translation_missing",
  //  [keys.A_allowClientsAddNumberTooltip]: "translation_missing",
  //  [keys.A_allow]: "translation_missing",
  //  [keys.A_restrict]: "translation_missing",
  //  [keys.A_restrictClientMessage]: "translation_missing",
  //  [keys.A_allowClientMessage]: "translation_missing",
  //  [keys.A_updatedAllowedMessage]: "translation_missing",
  //  [keys.A_updatedRestrictMessage]: "translation_missing",
  //  [keys.A_infoDetailsPriceChange]: "translation_missing",
  //  [keys.A_priceChangePopupTitle]: "translation_missing",
  //  [keys.A_selectEventType]: "translation_missing",
  //  [keys.A_whereWasEvent]: "translation_missing",
  //  [keys.A_eventWithinConversation]: "translation_missing",
  //  [keys.A_eventOutsideConversation]: "translation_missing",
  //  [keys.A_messagingEvent]: "translation_missing",
  //  [keys.A_externalEvent]: "translation_missing",
  //  [keys.A_enterNumber]: "translation_missing",
  //  [keys.A_yourNumber]: "translation_missing",
  //  [keys.A_iHaveNumber]: "translation_missing",
  //  [keys.A_iDontHaveNumber]: "translation_missing",
  //  [keys.A_selectDoYouHaveNumber]: "translation_missing",
  //  [keys.A_doYouHaveNumber]: "translation_missing",
  //  [keys.A_youNeedNumberToFinalizeJourney]: "translation_missing",
  //  [keys.A_createNumber]: "translation_missing",
  //  [keys.A_basicSubPlan]: "translation_missing",
  //  [keys.A_regularSubPlan]: "translation_missing",
  //  [keys.A_premiumSubPlan]: "translation_missing",
  //  [keys.A_cloudAPIHosting]: "translation_missing",
  //  [keys.A_HostingService]: "translation_missing",
  //  [keys.A_TicketBasedSupport]: "translation_missing",
  //  [keys.A_OnPremiseHosting]: "translation_missing",
  //  [keys.A_RTChat]: "translation_missing",
  //  [keys.A_WABAReq]: "translation_missing",
  //  [keys.A_AdsFunnelTracking]: "translation_missing",
  //  [keys.A_recommended]: "translation_missing",
  //  [keys.A_choosePlan]: "translation_missing",
  //  [keys.A_SelectPlan]: "translation_missing",
  //  [keys.A_includes]: "translation_missing",
  //  [keys.A_prevTierPlus]: "translation_missing",
  //  [keys.A_continueWithSelectedPlan]: "translation_missing",
  //  [keys.A_activityPageDescription]: "translation_missing",
  //  [keys.A_activityPageTitle]: "translation_missing",
  [keys.A_activity]: "Actividad",
  //  [keys.A_noActivityFound]: "translation_missing",
  //  [keys.A_noActivitySeen]: "translation_missing",
  //  [keys.A_allChannels]: "translation_missing",
  //  [keys.A_addNumberToSeeActivity]: "translation_missing",
  //  [keys.A_addNumberOverview]: "translation_missing",
  //  [keys.A_insights]: "translation_missing",
  //  [keys.A_expenseTooltip]: "translation_missing",
  //  [keys.A_downloadContacts]: "translation_missing",
  //  [keys.A_downloadContactsList1]: "translation_missing",
  //  [keys.A_downloadContactsList2]: "translation_missing",
  //  [keys.A_couldYouTellUsMore]: "translation_missing",
  //  [keys.A_downloadCsv]: "translation_missing",
  //  [keys.A_nameTheSource]: "translation_missing",
  //  [keys.A_nameTheSourceDescription]: "translation_missing",
  //  [keys.A_sourceName]: "translation_missing",
  //  [keys.A_message]: "translation_missing",
  //  [keys.A_saveSource]: "translation_missing",
  //  [keys.A_inviteMarketer]: "translation_missing",
  //  [keys.A_inviteMarketer1]: "translation_missing",
  //  [keys.A_coworkerEmail]: "translation_missing",
  //  [keys.A_invite]: "translation_missing",
  //  [keys.A_anInviteWasSent]: "translation_missing",
  //  [keys.A_messageDetails]: "translation_missing",
  //  [keys.A_checkSelectedMessages]: "translation_missing",
  //  [keys.A_sourceInvestment]: "translation_missing",
  //  [keys.A_investmentMade]: "translation_missing",
  //  [keys.A_amountCurrency]: "translation_missing",
  //  [keys.A_investedAmount]: "translation_missing",
  //  [keys.A_saveInvestment]: "translation_missing",
  //  [keys.A_requestPartnerAccess]: "translation_missing",
  //  [keys.A_trackEvent]: "translation_missing",
  //  [keys.A_selectPoolConversations]: "translation_missing",
  //  [keys.A_billingPageTitle]: "translation_missing",
  //  [keys.A_billingPageDescription]: "translation_missing",
  //  [keys.A_coworkerName]: "translation_missing",
  //  [keys.A_sentTo]: "translation_missing",
  //  [keys.A_viewConversationsInsights]: "translation_missing",
  //  [keys.A_insightsPageTitle]: "translation_missing",
  //  [keys.A_insightsPageDescription]: "translation_missing",
  //  [keys.A_measurePerformance]: "translation_missing",
  //  [keys.A_selectEventType]: "translation_missing",
  //  [keys.A_whereWasEvent]: "translation_missing",
  //  [keys.A_eventWithinConversation]: "translation_missing",
  //  [keys.A_eventOutsideConversation]: "translation_missing",
  //  [keys.A_messagingEvent]: "translation_missing",
  //  [keys.A_externalEvent]: "translation_missing",
  //  [keys.A_changeEventType]: "translation_missing",
  //  [keys.A_adAccountsPageTitle]: "translation_missing",
  //  [keys.A_adAccountsPAgeDescription]: "translation_missing",
  //  [keys.A_permissions]: "translation_missing",
  //  [keys.A_preferences]: "translation_missing",
  [keys.A_fundsPageTitle]: "360dialog - Fondos",
  [keys.A_funds]: "Fondos",
  [keys.A_archived]: "Archivado",
  [keys.A_rename]: "Renombrar",
  [keys.A_options]: "Opciones",
  //  [keys.A_adminHeaderInfo]: "translation_missing",
  //  [keys.A_funnels]: "translation_missing",
  [keys.A_Integrations]: "Integraciones",
  [keys.A_integrationsPageTitle]: "Integraciones",
};

export default translate;
