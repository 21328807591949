import * as Types from "./comments.types";

export const unpackComment = (
  response: Types.IServerResponseComment
): Types.IUIResponseComment => ({
  id: response.id,
  partnerId: response.partner_id,
  clientId: response.client_id,
  wabaAccountId: response.waba_account_id,
  channelId: response.channel_id,
  content: response.content,
  createdAt: response.created_at,
  createdBy: {
    userId: response.created_by.user_id,
    userName: response.created_by.user_name,
  },
});

export const unpackComments = (
  response: Types.IServerResponseComments
): Types.IUIResponseComments => ({
  comments: response.comments.map((comment) => unpackComment(comment)),
  total: response.total,
});
