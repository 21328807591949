// File generated automatically. Do not edit manually.
import app from "./app/es";
import archive from "./archive/es";
import auth from "./auth/es";
import billing from "./billing/es";
import channels from "./channels/es";
import dashboard from "./dashboard/es";
import errors from "./errors/es";
import facebook from "./facebook/es";
import faq from "./faq/es";
import general from "./general/es";
import inbox from "./inbox/es";
import key from "./key/es";
import languages from "./languages/es";
import migrate from "./migrate/es";
import navigation from "./navigation/es";
import onboarding from "./onboarding/es";
import preferences from "./preferences/es";
import settings from "./settings/es";
import support from "./support/es";
import templates from "./templates/es";
import wizard from "./wizard/es";
import notifications from "./notifications/es";
import appconnection from "./appconnection/es";
import signals from "./signals/es";
import insights from "./insights/es";
import funnels from "./funnels/es";
import integrations from "./integrations/es";
import numbers from "./numbers/es";
import audiences from "./audiences/es";
import tour from "./tour/es";
import tos from "./tos/es";

const allTranslations: { [k: string]: string } = {
  ...app,
  ...archive,
  ...auth,
  ...billing,
  ...channels,
  ...dashboard,
  ...errors,
  ...facebook,
  ...faq,
  ...general,
  ...inbox,
  ...key,
  ...languages,
  ...migrate,
  ...navigation,
  ...onboarding,
  ...preferences,
  ...settings,
  ...support,
  ...templates,
  ...wizard,
  ...notifications,
  ...appconnection,
  ...signals,
  ...insights,
  ...funnels,
  ...integrations,
  ...numbers,
  ...audiences,
  ...tour,
  ...tos,
};

export default allTranslations;
