// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.LNG_af]: "Afrikaans",
  [keys.LNG_sq]: "Albanisch",
  [keys.LNG_ar]: "Arabisch",
  [keys.LNG_az]: "Aserbaidschanisch",
  [keys.LNG_bn]: "Bengali",
  [keys.LNG_bg]: "Bulgarisch",
  [keys.LNG_ca]: "Katalanisch",
  [keys.LNG_zh_CN]: "Chinesisch (CHN)",
  [keys.LNG_zh_HK]: "Chinesisch (HKG)",
  [keys.LNG_zh_TW]: "Chinesisch (TAI)",
  [keys.LNG_hr]: "Kroatisch",
  [keys.LNG_cs]: "Tschechisch",
  [keys.LNG_da]: "Dänisch",
  [keys.LNG_nl]: "Niederländisch",
  [keys.LNG_en]: "Englisch",
  [keys.LNG_en_GB]: "Englisch (UK)",
  [keys.LNG_en_US]: "Englisch (US)",
  [keys.LNG_et]: "Estnisch",
  [keys.LNG_fil]: "Philippinisch",
  [keys.LNG_fi]: "Finnisch",
  [keys.LNG_fr]: "Französisch",
  [keys.LNG_de]: "Deutsch",
  [keys.LNG_el]: "Griechisch",
  [keys.LNG_gu]: "Gujarati",
  [keys.LNG_ha]: "Hausa",
  [keys.LNG_he]: "Hebräisch",
  [keys.LNG_hi]: "Hindi",
  [keys.LNG_hu]: "Ungarisch",
  [keys.LNG_id]: "Indonesisch",
  [keys.LNG_ga]: "Irisch",
  [keys.LNG_it]: "Italienisch",
  [keys.LNG_ja]: "Japanisch",
  [keys.LNG_kn]: "Kannada",
  [keys.LNG_kk]: "Kasachisch",
  [keys.LNG_ko]: "Koreanisch",
  [keys.LNG_lo]: "Lao",
  [keys.LNG_lv]: "Lettisch",
  [keys.LNG_lt]: "Litauisch",
  [keys.LNG_mk]: "Mazedonisch",
  [keys.LNG_ms]: "Mailaiisch",
  [keys.LNG_ml]: "Malayalam",
  [keys.LNG_mr]: "Marathi",
  [keys.LNG_nb]: "Norwegisch",
  [keys.LNG_fa]: "Persisch",
  [keys.LNG_pl]: "Polnisch",
  [keys.LNG_pt_BR]: "Portugiesisch (BR)",
  [keys.LNG_pt_PT]: "Portugiesisch (POR)",
  [keys.LNG_pa]: "Punjabi",
  [keys.LNG_ro]: "Rumänisch",
  [keys.LNG_ru]: "Russisch",
  [keys.LNG_sr]: "Serbisch",
  [keys.LNG_sk]: "Slowakisch",
  [keys.LNG_sl]: "Slowenisch",
  [keys.LNG_es]: "Spanisch",
  [keys.LNG_es_AR]: "Spanisch (ARG)",
  [keys.LNG_es_ES]: "Spanisch (SPA)",
  [keys.LNG_es_MX]: "Spanisch (MEX)",
  [keys.LNG_sw]: "Suaheli",
  [keys.LNG_sv]: "Schwedisch",
  [keys.LNG_ta]: "Tamil",
  [keys.LNG_te]: "Telugu",
  [keys.LNG_th]: "Thailändisch",
  [keys.LNG_tr]: "Türkisch",
  [keys.LNG_uk]: "Ukrainisch",
  [keys.LNG_ur]: "Urdu",
  [keys.LNG_uz]: "Usbekisch",
  [keys.LNG_vi]: "Vietnamesisch",
  [keys.LNG_zu]: "Zulu",
};

export default translate;
