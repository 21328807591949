import {
  observable,
  action,
  IReactionDisposer,
  computed,
  makeObservable,
} from "mobx";

import { QueryParamsFilters, ParamsFilters } from "@/services/api/utils";
import {
  UIModelPartnerChannels,
  UIModelPartnerChannel,
} from "@/services/api/partnerChannels/partnerChannels.model";

// const getObjectValueAlt = (
//   obj: UIModelPartnerChannel,
//   path: string | string[],
//   def: undefined = null
// ) =>
//   (() =>
//     typeof path === "string"
//       ? path.replace(/\[(\d+)]/g, ".$1")
//       : path.join("."))()
//     .split(".")
//     .filter(Boolean)
//     .every((step) => (obj = obj[step]) !== undefined)
//     ? obj
//     : def;

/**
 * @param value
 * @param path
 * @param defaultValue
 * @returns
 */
/**
 * TODO: confirm that this replaces lodash.get
 */
const getObjectValue = (
  value: any,
  path: string,
  defaultValue: undefined = null
) => {
  return String(path)
    .split(".")
    .reduce((acc, v) => {
      try {
        acc = acc[v];
      } catch (e) {
        return defaultValue;
      }
      return acc;
    }, value);
};

interface Total {
  total: number;
}

type APIData<T> = T & Total;

interface ListStateConctructorParams<T> {
  offset: number;
  limit: number;
  searchFields?: string[];
  withoutAppId?: boolean;
  processingData?: () => unknown;
  sortFields?: string[];
  apiDataInitial?: APIData<T>;
  filtersLoadSave: Record<string, unknown>;
}

export class ListState<T> {
  limit: number;
  readonly processingData: () => unknown | undefined;
  readonly withoutAppId: boolean | undefined;
  readonly apiDataInitial: any;
  readonly initialQueryParams: {
    sort: string[];
    offset: number;
    filters: Record<string, unknown>;
    limit: number;
  };
  filters: QueryParamsFilters;
  sortFields: string[];
  pending = false;
  searchInputValue = "";
  isListPopupShowed = false;
  currentPickedRow: string;
  APIData: APIData<T>;
  currentPage: number;
  searchFields: string[];
  pickedListElements: string[] = [];
  listFetchDisposer: IReactionDisposer;
  filtersLoadSave: Record<string, unknown> | null;
  constructor({
    limit,
    processingData,
    apiDataInitial,
    searchFields,
    sortFields,
    withoutAppId,
    filtersLoadSave,
  }: ListStateConctructorParams<T>) {
    this.apiDataInitial = apiDataInitial;
    this.limit = limit;
    this.filters = {};
    this.filtersLoadSave = filtersLoadSave || null;
    this.processingData = processingData || undefined;
    this.currentPage = 1;
    this.searchFields = searchFields || ["name"];
    this.sortFields = sortFields || [];
    this.withoutAppId = withoutAppId;
    this.initialQueryParams = {
      sort: sortFields || [],
      offset: 0,
      limit: limit,
      filters: {},
    };

    if (apiDataInitial) {
      this.APIData = apiDataInitial;
    }
    makeObservable(this, {
      filters: observable,
      sortFields: observable,
      pending: observable,
      searchInputValue: observable,
      isListPopupShowed: observable,
      APIData: observable,
      currentPage: observable,
      searchFields: observable,
      pickedListElements: observable,
      offset: computed,
      listPages: computed,
      allQueryParams: computed,
      onPageClick: action,
      onRowClick: action,
      setCurrentPickedRow: action,
      showListPopup: action,
      hideListPopup: action,
      handlePrevButtonClick: action,
      handleNextButtonClick: action,
      removeDecreeseSign: action,
      sortColumn: action,
      onCheckboxClick: action,
      removeAllSorting: action,
      addSortingType: action,
      handleClearSearchInput: action,
      handleDefaultSearchInput: action,
      insertListData: action,
    });
  }

  // public debounce(callback: () => unknown, wait: number) {
  //   let timeout: NodeJS.Timeout;
  //   return (...args: any[]) => {
  //     const context = this;
  //     clearTimeout(timeout);
  //     timeout = setTimeout(() => callback.apply(context, args), wait);
  //   };
  // }

  public get offset() {
    return (this.currentPage - 1) * this.limit;
  }

  public get listPages() {
    const total = this.APIData.total;
    const limit = this.limit;

    const totalNumbers =
      Math.floor(total / limit) + (total % limit !== 0 ? 1 : 0);
    return totalNumbers;
  }

  public insertListData(listData: APIData<T>) {
    this.APIData = listData;
  }

  public get allQueryParams() {
    return {
      sort: this.sortFields,
      offset: this.offset,
      filters: this.filters,
      limit: this.limit,
    };
  }

  public onPageClick = (page: number) => {
    this.currentPage = page;
  };

  public onRowClick = (id: string) => {
    this.showListPopup();
    this.setCurrentPickedRow(id);
  };

  setCurrentPickedRow = (id: string) => {
    this.currentPickedRow = id;
  };

  showListPopup = () => {
    this.isListPopupShowed = true;
  };

  hideListPopup = () => {
    this.isListPopupShowed = false;
  };

  public handlePrevButtonClick = () => {
    this.currentPage = this.currentPage - 1;
  };

  public handleNextButtonClick = () => {
    this.currentPage = this.currentPage + 1;
  };

  public removeDecreeseSign = (value: string) => {
    return value.startsWith("-") ? value.slice(1) : value;
  };

  public sortColumn = (columnName: string) => {
    const sortFromLowest = `-${columnName}`;
    const sortFromBiggest = columnName;

    if (this.sortFields.includes(sortFromLowest)) {
      this.removeSortingType(sortFromLowest);
      this.addSortingType(sortFromBiggest);
    } else if (this.sortFields.includes(sortFromBiggest)) {
      this.removeSortingType(sortFromBiggest);
      this.addSortingType(sortFromLowest);
    } else {
      this.sortFields = [];
      this.addSortingType(sortFromLowest);
    }

    this.resetPaging();
  };

  public onCheckboxClick = (pickedId: string) => {
    if (this.pickedListElements.includes(pickedId)) {
      this.pickedListElements = this.pickedListElements.filter(
        (id) => id !== pickedId
      );
    } else {
      this.pickedListElements.push(pickedId);
    }
  };

  public removeAllSorting = () => {
    this.sortFields.length = 0;
  };

  public removeFilters = () => {
    this.filters = {};
  };

  private removeSortingType = (sortingType: string) => {
    this.sortFields = this.sortFields.filter((sort) => sort !== sortingType);
  };

  addSortingType = (sortingType: string) => {
    this.sortFields.push(sortingType);
  };

  resetPaging = () => {
    this.currentPage = 1;
  };

  public handleClearSearchInput = () => {
    this.searchInputValue = "";
    // NOTE: Causing a filtering error
    // this.filters.name = {
    //   like: "",
    // };
    this.filters = {};
  };

  changeAllSearchableFields = (inputValue: string) => {
    for (const searchableField of this.searchFields) {
      this.filters[searchableField] = {
        like: inputValue,
      };
    }
  };

  public handleDefaultSearchInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    this.searchInputValue = value;
    this.changeAllSearchableFields(value);
    this.resetPaging();
  };
}

const mapPipeSortingFieldsToActuallTabFields: { [k: string]: string } = {
  modifiedAt: "dateOfModify",
  "client.name": "client.name",
  status: "status",
};

export class PipesListState {
  listState: ListState<UIModelPartnerChannels>;

  constructor(params: ListStateConctructorParams<UIModelPartnerChannels>) {
    this.listState = new ListState<UIModelPartnerChannels>(params);

    makeObservable(this, {
      listState: observable,
      pipesDataWithSorting: computed,
      APIData: computed,
      toggleSorting: action,
      handleClearSearchInput: action,
      handleDefaultSearchInput: action,
      changeSearchInputValue: action,
      changeFilterByStatusValue: action,
    });
  }

  get APIData() {
    return this.listState.APIData;
  }

  get pipesDataWithSorting(): { [k: string]: UIModelPartnerChannel[] } {
    return this.listState.APIData.partnerChannels.reduce(
      (prev: { [k: string]: UIModelPartnerChannel[] }, curr) => {
        const searchedModelValue = getObjectValue(
          curr,
          mapPipeSortingFieldsToActuallTabFields[
            this.listState.removeDecreeseSign(this.listState.sortFields[0])
          ]
        );
        return {
          ...prev,
          [searchedModelValue]: prev[searchedModelValue]
            ? [...prev[searchedModelValue], curr]
            : [curr],
        };
      },
      {}
    );
  }

  public toggleSorting = () => {
    this.listState.sortColumn(
      this.listState.removeDecreeseSign(this.listState.sortFields[0])
    );
  };

  public handleClearSearchInput = () => {
    this.listState.searchInputValue = "";
    this.listState.filters.q = "";
    this.listState.pending = true;
  };

  public handleDefaultSearchInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    this.listState.searchInputValue = value;
    this.listState.filters.q = value;
    this.listState.resetPaging();
    this.listState.pending = true;
  };

  public changeSearchInputValue = (value: string) => {
    this.listState.searchInputValue = value;
    this.listState.filters.q = value;
    this.listState.resetPaging();
    this.listState.pending = true;
  };

  public changeFilterByStatusValue = (object: ParamsFilters) => {
    if (object.status.eq === "all statuses") {
      delete this.listState.filters.status;
    } else {
      const [key, value] = Object.entries(object)[0];
      this.listState.filters[key] = value;
    }
    this.listState.resetPaging();
  };
}
