// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.BILL_header]: "Bem-vindo",
  [keys.BILL_balance]: "Saldo",
  [keys.BILL_addFunds]: "Adicionar fundos",
  [keys.BILL_addFundsPopup]: "Adicionar fundos para template messages",
  [keys.BILL_addedFundsDescription]:
    "Os fundos adicionados serão válidos apenas para o uso de template messages. Planos mensais ou anuais para as contas WhatsApp Business API serão faturados separadamente.",
  [keys.BILL_addFundsPopupQuestion]:
    "Quantos templates você planeja enviar por mês?",
  [keys.BILL_addFundsPopupTooltip]:
    "Baseado no seu consumo planejado de templates messages podemos estimar o custo total. De toda forma, o preço das template messages varia de acordo com o país de uso. Por isso, os custos por mensagem só podem ser estimados.",
  [keys.BILL_costBreakdown]: "Detalhes dos custos",
  [keys.BILL_estTemplateCost]: "Custo estimado de template messages",
  [keys.BILL_transFees]: "Taxas de transação",
  [keys.BILL_subtotal]: "Subtotal",
  [keys.BILL_subAndPay]: "Enviar e pagar agora",
  [keys.BILL_monthlyUsage]: "Uso mensal",
  [keys.BILL_numTemplatesSent]: "Número de templates enviados",
  [keys.BILL_costsByTemplates]: "Custos causados por templates",
  [keys.BILL_noData]: "Sem dados para esse relatório",
  [keys.BILL_filterByNum]: "Filtrar por número",
  [keys.BILL_selectAll]: "Selecionar todos",
  [keys.BILL_numberNotLive]: "Este número ainda não está ativo",
  [keys.BILL_noBillingCountry]: "Você precisa configurar o país de cobrança!",
  [keys.BILL_amountDue]: "Total",
  [keys.BILL_notApplicable]: "N/A",
  [keys.BILL_vat]: "VAT",
  [keys.BILL_addFundsSectionMsg]:
    "O valor selecionado acima será cobrado e contado no seu saldo atual para os custos do WhatsApp. Será cobrado uma única vez e não afetará suas configurações de renovação automática. Esteja ciente também que as taxas mensais de assinatura são cobradas separadamente e não utilizam o saldo pré-pago.",
  [keys.BILL_autoRenewalSuccess]:
    "A auto-renovação foi habilitada com sucesso. O limite e a quantia podem ser fixados manualmente.",
  [keys.BILL_thresholdChangeSuccess]:
    "Limite de renovação automática alterado com sucesso",
  [keys.BILL_amountChangeSuccess]:
    "Valor de renovação automática alterado com sucesso",
  [keys.BILL_renewalHeading]: "Gerenciar números",
  [keys.BILL_renewalTooltip]:
    "Se o saldo ficar abaixo desse valor, ele será renovado com o valor definido na renovação automática.",
  [keys.BILL_thresholdSection]: "Limite para renovação automática",
  [keys.BILL_excludingTransactionFees]: "excluindo taxas de transação e VAT",
  [keys.BILL_autoRenewal]: "Renovação automática",
  [keys.BILL_autoRenewalAmount]: "Valor da renovação automática",
  [keys.BILL_oneOffCharge]: "Taxa única",
  [keys.BILL_changeSettings]: "Mudar configurações",
  [keys.BILL_renewalPopupHeading]: "Mudar valor da renovação automática",
  [keys.BILL_renewalPopupSectionMsg]:
    "Isso altera as configurações para sua renovação automática. O valor selecionado será cobrado na próxima vez que o seu saldo for abaixo do valor de limite. Você não será cobrado agora se o seu saldo ainda está acima desse limite.",
  [keys.BILL_submitChanges]: "Enviar mudanças",
  [keys.BILL_balanceBreakDown]: "Detalhes do saldo",
  [keys.BILL_selectNumberAction]: "Selecionar número e ação",
  [keys.BILL_estimateCosts]: "Estimar custos",
  [keys.BILL_confirmCheckout]: "Confirmar checkout",
  [keys.BILL_selectAction]: "Selecione qual ação você quer realizar",
  [keys.BILL_addOneOffFunds]:
    "Adicionar fundos a este número com uma cobrança única",
  [keys.BILL_addAutorenewalSettings]:
    "Alterar configurações de renovação automática para esse número",
  [keys.BILL_autoRenewalSectionMsg]:
    "Ao submeter as mudanças você continua a alterar as configurações para a sua renovação automática. O valor selecionado será cobrado na próxima vez que o seu saldo cair abaixo do valor limite. Você não será cobrado no momento se o seu saldo ainda estiver acima do valor limite. Esteja ciente também que as taxas mensais de assinatura são cobradas separadamente e não utilizam o saldo pré-pago.",
  [keys.BILL_changeAutoRenewalThreshold]:
    "Alterar limite de renovação automática",
  [keys.BILL_thresholdAmount]: "Valor do limite de renovação automática",
  [keys.BILL_thresholdRenewalMsg]:
    "Se o saldo para o número selecionado for abaixo do valor de limite de renovação automática, o saldo é renovado com o valor de renovação automática, que pode ser especificado separadamente.",
  [keys.BILL_processingPayment]:
    "O pagamento está sendo processado. O saldo será atualizado em breve.",
  [keys.BILL_businessInitiatedQuestion]:
    "Quantas conversas iniciadas pela empresa você planeja ter? ",
  [keys.BILL_userInitiatedQuestion]:
    "Quantas conversas inciadas por usuários você provavelmente terá?",
  [keys.BILL_estimatedConversationCost]: "Custos estimados de conversas",
  [keys.BILL_previousMonth]: "mês anterior",
  [keys.BILL_addFundsNotAllowed]:
    "Não é possível adicionar fundos para números em rasunho",
  [keys.BILL_othersCombined]: "Outros (combinados)",
  [keys.BILL_approxCharges]: "Estimativa de cobranças",
  [keys.BILL_allConversations]: "Todas as conversas",
  [keys.BILL_insights]: "Insights",
  [keys.BILL_insightsAndBilling]: "Insights & Billing",
  [keys.BILL_businessInitiatedAmountTooltip]:
    "Baseado no número planejado de conversas iniciadas pela empresa, podemos estimar os custos que provavelmente serão gerados. Porém, o preço por conversa varia por país e, assim, os custos podem ser apenas estimados. Estas estimativas são baseadas na suposição de que você apenas iniciará conversas no país de origem do seu número de telefone registrado.",
  [keys.BILL_userInitiatedAmountTooltip]:
    "Baseado no número planejado de conversas iniciadas pelos usuários, podemos estimar os custos que provavelmente serão gerados. Porém, o preço por conversa varia por país e, assim, os custos podem ser apenas estimados. Estas estimativas são baseadas na suposição de que você apenas receberá conversas no país de origem do seu número de telefone registrado.",
  [keys.BILL_costBreakdownTooltip]:
    "Baseado no número planejado de conversas iniciadas pelos usuários e pela empresa, podemos estimar os custos que provavelmente serão gerados. Porém, o preço por conversa varia por país e, assim, os custos podem ser apenas estimados. Estas estimativas são baseadas na suposição de que você apenas terá conversas no país de origem do seu número de telefone registrado.",
  [keys.BILL_customize]: "Customizar",
  [keys.BILL_noCharges]:
    "Não serão cobradas taxas, as conversas estimadas estão dentro de sua conversa gratuita mensal disponível (1000)",
  [keys.BILL_fundsWereAdded]: "Os fundos foram adicionados com sucesso",
  [keys.BILL_enabled]: "habilitado",
  [keys.BILL_disabled]: "desativado",
  [keys.BILL_vat2]: "Adicionar fundos",
  [keys.BILL_zeroBalanceText_one]:
    "O saldo para o número {{numbers}} é 0. Você não consegue mais enviar mensagens em uma conversa paga. Por favor, adicione fundos se você quiser continuar usando este número com uma conversa paga.",
  [keys.BILL_zeroBalanceText_other]:
    "O saldo para esses números {{numbers}} é 0. Você não consegue mais enviar mensagens em uma conversa paga. Por favor, adicione fundos se você quiser continuar usando este número com uma conversa paga.",
  [keys.BILL_lowBalanceText_one]:
    "O saldo para o número {{numbers}} está baixo. Para continuar respondendo a conversas pagas, por favor adicione fundos manualmente ou habilite a renovação automática. Se o saldo do número chegar a 0, você não poderá enviar mensagens em uma conversa paga.",
  [keys.BILL_lowBalanceText_other]:
    "O saldo para os números {{numbers}} está baixo. Para continuar respondendo a conversas pagas, por favor adicione fundos manualmente ou habilite a renovação automática. Se o saldo do número chegar a 0, você não poderá enviar mensagens em uma conversa paga.",
  [keys.BILL_automatic]: "Automático",
  [keys.BILL_manageFunds]: "Gerenciar fundos",
  [keys.BILL_refund]: "Reembolso",
  [keys.BILL_refundAccountTitle]: "Reembolsar valores para esse número",
  [keys.BILL_refundingDetails]: "Detalhes do reembolso",
  [keys.BILL_channelFunds]: "Fundos do canal",
  [keys.BILL_noPendingBalance]: "Nenhum saldo pendente a ser reembolsado",
  [keys.BILL_totalAmountToRefund]: "Montante total a ser reembolsado",
  [keys.BILL_refundInfomationText]:
    "Seus fundos serão reembolsados através de seu método de pagamento padrão.",
  [keys.BILL_refundInformationText2]:
    "Se você não se envolveu em nenhuma conversa paga após sua última recarga de saldo e, portanto, não gastou nada do seu saldo, o reembolso também incluirá a taxa de processamento de pagamento paga. Caso contrário, você receberá um reembolso do saldo restante atual sem a taxa de processamento.",
  [keys.BILL_fundsBalance]: "Saldo de Fundos",
  [keys.BILL_automaticAutoRenewal]: "Renovação Automática Inteligente",
  [keys.BILL_descriptionText1]:
    "Estamos felizes que esteja usando nossa nova função.",
  [keys.BILL_descriptionText2]:
    "Nós criamos a renovação automática para ajudá-lo a gerenciar seus fundos de uma maneira mais eficiente.",
  [keys.BILL_descriptionText3]:
    "Com base em suas conversas do(s) mês(es) anterior(es) e nas tendências de custos, estamos estabelecendo o limite do seu número e o valor a ser alcançado:",
  [keys.BILL_threshold]: "Limite",
  [keys.BILL_autoMaticSettingMessage]:
    "Por favor, note que estas configurações podem variar para sempre corresponder aos fundos necessários para o seu número.",
  [keys.BILL_setAutomaticRenewal]: "Estabelecer a renovação automatica.",
  [keys.BILL_automaticTooltip]:
    "A auto-renovação automática define seu limite e montante automaticamente com base em suas conversas do(s) mês(es) anterior(es) e tendências de custos. Nós recomendamos que você o tenha ativo para nunca ficar sem fundos e também para ter um número preciso de fundos.",
  [keys.BILL_enableAutoSettingsMessage]:
    "A auto-renovação inteligente foi habilitada com sucesso. O limite e montante serão calculados automaticamente.",
  [keys.BILL_disableAutoSettingsMessage]:
    "Configurações de renovação automática desativadas com sucesso para recarga automática",
  [keys.BILL_AutoSettingsErrorMessage]:
    "Não foi possível alterar as configurações de renovação automática. Por favor, tente novamente.",
  [keys.BILL_refundSuccessNotification]:
    "Seu reembolso foi iniciado com sucesso! Seu saldo será atualizado de acordo em breve.",
  [keys.BILL_autoRenewalDisabled]: "Auto-renovação desligada com sucesso",
  [keys.BILL_offOptionTooltip]:
    "Seus fundos não serão renovados automaticamente. Você tem que adicionar fundos manualmente sempre que seu saldo estiver baixo, a fim de continuar a se envolver em conversas pagas. Nós recomendamos permitir a renovação automática para nunca ficar sem fundos.",
  [keys.BILL_manualOptionTooltip]:
    "A renovação automática está ativada e renovará automaticamente seus fundos com base em um limite e uma quantia de renovação que você define manualmente através das opções à direita.",
  [keys.BILL_smartOptionTooltip]:
    "A auto-renovação está ativada. Seu limite e valor são automaticamente calculados e ajustados com base em suas conversas do(s) mês(es) anterior(es) e tendências de custos.",
  [keys.BILL_paymentSucceeded]: "O pagamento foi bem sucedido",
  [keys.BILL_paymentFailed]:
    "O método de pagamento fornecido falhou a autenticação. Por favor, forneça um novo método de pagamento para tentar cumprir a intenção de pagamento novamente.",
  [keys.BILL_radioDisabledTooltipText]:
    'Para ajustar suas configurações de renovação automática manualmente, você precisa mudar a renovação automática para o modo "manual".',
  [keys.BILL_topupDurationMessage]:
    "Pode levar até 30 minutos até que o saldo seja atualizado após uma cobrança bem sucedida. Para reduzir o risco de ser bloqueado do envio de mensagens, nós recomendamos habilitar a renovação automática.",
  [keys.BILL_billingOptions]: "Opções de pagamento",
  [keys.BILL_fundNegativeBalance]: "Equilibrar o saldo negativo desse canal",
  [keys.BILL_noRefundFunds]: "Não há fundos disponíveis para reembolso",
  [keys.BILL_noAddingFunds]:
    "No momento este número não está disponível para adição de fundos",
  [keys.BILL_pendingBalanceBilling]:
    "O saldo negativo pendente será cobrado em sua conta.",
  [keys.BILL_refundAccount]: "Fundos da conta de reembolso",
  [keys.BILL_negativeBalance]: "Conta com saldo negativo!",
  [keys.BILL_negativeBalanceMessage]:
    "Alguns canais têm saldo negativo, você pode enviar sua solicitação de mudança de parceiro assim que o saldo negativo for pago. Prossiga para adicionar fundos à sua conta.",
  [keys.BILL_totalPendingCharge]: "Total de cobranças pendentes",
  [keys.BILL_addFundsProceed]: "Continuar para adicionar fundos",
  [keys.BILL_clickToManageFunds]:
    "Clique aqui para gerenciar os fundos desse número",
  [keys.BILL_billingInformation]: "Informações de pagamentos",
  [keys.BILL_billingInfoName]: "Nome",
  [keys.BILL_billingInfoEmail]: "Email",
  [keys.BILL_billingInfoAddress]: "Endereço",
  [keys.BILL_billingInfoVatId]: "IVA ID",
  [keys.BILL_billingInfoEdit]: "Editar informação",
  [keys.BILL_invoices]: "Faturas",
  [keys.BILL_payInvoice]: "Pagar faturas",
  [keys.BILL_unpaidInvoiceTooltip]:
    "Para realizar o pagamento da fatura, primeiro adicione um método de pagamento.",
  [keys.BILL_invoiceViewMore]: "Ver mais",
  [keys.BILL_cloudApiPlan]: "Plano de API na nuvem, com suporte dedicado",
  [keys.BILL_partnerAccess]: "Plano de acesso de parceiros",
  [keys.BILL_userInitiatedConversations]:
    "Conversas iniciadas pelo usuário e iniciadas pela empresa. Sem marcação.",
  [keys.BILL_withRegularSupport]: "com suporte regular",
  [keys.BILL_stripeBillingOptionsMissing]:
    "As opções de cobrança do Stripe estão ausentes. Entre em contato com o suporte para obter assistência",
  [keys.BILL_requestRefund]: "Solicitar reembolso",
  //  [keys.BILL_fundsAmount]: "translation_missing",
  //  [keys.BILL_fundsAmountDescription]: "translation_missing",
  //  [keys.BILL_estimatedConversations]: "translation_missing",
  //  [keys.BILL_selectedFundsAmount]: "translation_missing",
  //  [keys.BILL_costBreakdownDescriptionOneTime]: "translation_missing",
  //  [keys.BILL_costBreakdownDescriptionAutoRenewal]: "translation_missing",
  //  [keys.BILL_keepInMind]: "translation_missing",
  //  [keys.BILL_keepInMindList1]: "translation_missing",
  //  [keys.BILL_keepInMindList2]: "translation_missing",
  //  [keys.BILL_breakdown]: "translation_missing",
  //  [keys.BILL_provideFundsAmount]: "translation_missing",
  [keys.BILL_allYourNumbersWillContinue]:
    "Todos os seus números continuarão à ser cobrados de acordo com nosso plano Standard. Você pode atualizar ou rebaixar seu plano para cada número individualmente à qualquer momento.",
  [keys.BILL_allNumbersThatWereUpgraded]:
    "Todos os números que foram anteriormente atualizados para o plano Premium irão continuar à serem cobrados com o valor do mesmo.",
  //  [keys.BILL_total]: "translation_missing",
  //  [keys.BILL_billingInformationDesc]: "translation_missing",
  //  [keys.BILL_checkAndDownloadInvoices]: "translation_missing",
  //  [keys.BILL_noInvoiceInfo]: "translation_missing",
  //  [keys.BILL_currentBalance]: "translation_missing",
  //  [keys.BILL_spentLastMonth]: "translation_missing",
  //  [keys.BILL_fundsManager]: "translation_missing",
  //  [keys.BILL_autoRenewalInfo]: "translation_missing",
  //  [keys.BILL_setAutomaticFundingRenewal]: "translation_missing",
  //  [keys.BILL_billingInfoUpdated]: "translation_missing",
  //  [keys.BILL_noBillingInfoAddedInfo]: "translation_missing",
  //  [keys.BILL_addBillingDetails]: "translation_missing",
  //  [keys.BILL_paymentDetails]: "translation_missing",
  //  [keys.BILL_update]: "translation_missing",
  //  [keys.BILL_expire]: "translation_missing",
  //  [keys.BILL_holder]: "translation_missing",
  //  [keys.BILL_noPaymentDetailsAddedInfo]: "translation_missing",
  //  [keys.BILL_nextBillingInfo]: "translation_missing",
  //  [keys.BILL_billingDetails]: "translation_missing",
  //  [keys.BILL_billingDetailsFormDesc]: "translation_missing",
  //  [keys.BILL_payPendingInvoices]: "translation_missing",
  //  [keys.BILL_updateAutoRechargeTitle]: "translation_missing",
  //  [keys.BILL_updateAutoRechargeDesc]: "translation_missing",
  //  [keys.BILL_thresholdAmountErrorMessage]: "translation_missing",
  //  [keys.BILL_autoRenewalAmountDesc]: "translation_missing",
  //  [keys.BILL_thresholdAmountDesc]: "translation_missing",
  //  [keys.BILL_paymentInformation]: "translation_missing",
  //  [keys.BILL_changeMethod]: "translation_missing",
  //  [keys.BILL_addNewPaymentMethod]: "translation_missing",
  //  [keys.BILL_radioDisabledTooltipText]: "translation_missing",
  //  [keys.BILL_fundNegativeBalance]: "translation_missing",
  //  [keys.BILL_unpaidInvoice1]: "translation_missing",
  //  [keys.BILL_unpaidInvoice2]: "translation_missing",
};

export default translate;
