import { UIModelChannels } from "@/services/api/channels/channels.model";
import React from "react";
import { ListState } from "./listState";

const channelsState = new ListState<UIModelChannels>({
  withoutAppId: true,
  limit: 20,
  offset: 0,
  sortFields: ["-modifiedAt"],
  apiDataInitial: {
    channels: [],
    total: 0,
  },
  filtersLoadSave: {},
});

export const ChannelListState = React.createContext<ListState<UIModelChannels>>(
  {} as ListState<UIModelChannels>
);

const ChannelListStateStoreProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ChannelListState.Provider value={channelsState}>
      {children}
    </ChannelListState.Provider>
  );
};

export default ChannelListStateStoreProvider;
