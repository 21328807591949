import { MPInstancesHub } from "@/api/RequestAxios/RequestAxiosHub";
import { MPInstanceAuthX360 } from "@/api/RequestAxios/RequestAxiosAuthX360";
import * as Types from "./profile.types";

const userProfileUrl = () => `/user_profile`;

const updateLastManagedUrl = () => `/profile/last_managed`;

export const updateLastManaged = async (channelId: string) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.patch(
    updateLastManagedUrl(),
    {
      channel_id: channelId,
    }
  );

  return data;
};

export const getUserProfile = async () => {
  const { data } =
    await MPInstanceAuthX360.buffedInstances.get<Types.IServerResponseClientInfo>(
      userProfileUrl()
    );

  return data;
};
