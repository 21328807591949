import { MPInstanceAdsV2 } from "@/services/api/RequestAxios/RequestAxiosAds";

import * as Types from "./audiences.types";

const customAudiencesURL = ({ clientId }: { clientId: string }) =>
  `/clients/${clientId}/custom-audiences/all`;

const createFromContactsURL = ({ clientId }: { clientId: string }) =>
  `/clients/${clientId}/custom-audiences/create_from_contacts`;

const generateCandidatesFromContactsURL = ({
  clientId,
}: {
  clientId: string;
}) => `/clients/${clientId}/custom-audiences/generate_candidates_from_contacts`;

const groupedByAdAccountsURL = ({ clientId }: { clientId: string }) =>
  `/clients/${clientId}/custom-audiences/grouped-by-ad-accounts`;

export const getCustomAudiences = async ({
  clientId,
  queryParams,
}: {
  clientId: string;
  queryParams?: Types.AllCustomAudiencesQueryParams;
}) => {
  const { data } =
    await MPInstanceAdsV2.buffedInstances.get<Types.IServerResponseAudiences>(
      customAudiencesURL({ clientId }),
      {
        params: {
          ...queryParams,
        },
      }
    );

  return data;
};

export const createFromContacts = async ({
  clientId,
  filters,
  data,
}: {
  clientId: string;
  filters: Types.CustomAudienceFilters;
  data: Types.CustomAudienceData;
}) => {
  const { data: responseData } = await MPInstanceAdsV2.buffedInstances.post(
    createFromContactsURL({ clientId }),
    {
      custom_audience_filters: filters,
      custom_audience_data: data,
    }
  );

  return responseData;
};

export const generateCandidatesFromContacts = async ({
  clientId,
  filters,
}: {
  clientId: string;
  filters: Types.CustomAudienceFilters;
}) => {
  const { data } = await MPInstanceAdsV2.buffedInstances.post(
    generateCandidatesFromContactsURL({ clientId }),
    {
      ...filters,
    }
  );

  return data;
};

export const getAudiencesGroupedByAdAccounts = async ({
  clientId,
  queryParams,
}: {
  clientId: string;
  queryParams: Types.CustomAudiencesGroupedByAdAccountsParams;
}) => {
  const { data } =
    await MPInstanceAdsV2.buffedInstances.get<Types.IServerResponseGroupedAudience>(
      groupedByAdAccountsURL({ clientId }),
      {
        params: {
          ...queryParams,
        },
      }
    );

  return data;
};
