import { AxiosError, AxiosRequestConfig } from "axios";
import ErrorSender from "@/services/api/RequestAxios/SendErrorToGraylog";

const REQUEST_TIMEOUT = 8000;

declare let global: {
  UI_ENV: string;
  [key: string]: any;
};

export const beforeRequestHandler = (config: AxiosRequestConfig) => {
  return config;
};

export const requestErrorHandler = (error: AxiosError | any) => {
  if (
    error.request &&
    !(
      error.code === "ECONNABORTED" &&
      error.message.indexOf(REQUEST_TIMEOUT.toString()) > -1
    )
  ) {
    ErrorSender.send({
      message: "Request Error",
      context: {
        url: error.config.url,
        code: error.code,
        stage: global.UI_ENV,
        error: `${error.stack}`,
        request: `${JSON.stringify(error.request)}`,
        response: `${JSON.stringify(error.response?.data?.meta)}`,
        message: error.message,
      },
    });
  }
  if (error.response) {
    ErrorSender.send({
      message: `${error.response.statusText}`,
      context: {
        url: error.config.url,
        code: error.code,
        stage: global.UI_ENV,
        error: `${error.stack}`,
        response: `${JSON.stringify(error.response)}`,
        request: `${JSON.stringify(error.request)}`,
        message: error.message,
      },
    });
  }

  return Promise.reject(error);
};
