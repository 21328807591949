import { MPInstancesHub } from "@/services/api/RequestAxios/RequestAxiosHub";
import { IQueryParams, packQueryParams, stringifyQueryParams } from "../utils";
import { UIModelWabaAccounts, UIModelWabaAccount } from "./wabaAccount.model";
import {
  packWabaAccount,
  unpackWabaAccount,
  unpackWabaAccounts,
  unpackWabaAccountPayment,
} from "./wabaAccount.mapper";
import * as Types from "./wabaAccount.types";

const wabaAccountsURL = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/waba_accounts`;

const wabaAccountURL = ({
  partnerId,
  clientId,
  wabaAccountId,
}: {
  partnerId: string;
  clientId: string;
  wabaAccountId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/waba_accounts/${wabaAccountId}`;

const wabaAccountSubmitURL = ({
  partnerId,
  clientId,
  wabaAccountId,
}: {
  partnerId: string;
  clientId: string;
  wabaAccountId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/waba_accounts/${wabaAccountId}/control/submit`;

const wabaAccountUpdateTemplateNamespaceURL = ({
  partnerId,
  clientId,
  wabaAccountId,
}: {
  partnerId: string;
  clientId: string;
  wabaAccountId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/waba_accounts/${wabaAccountId}/control/update_template_namespace`;

const wabaAccountSynchronizeStatusURL = ({
  partnerId,
  clientId,
  wabaAccountId,
}: {
  partnerId: string;
  clientId: string;
  wabaAccountId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/waba_accounts/${wabaAccountId}/control/synchronize_status`;

const synchronizeSharedWabaURL = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/waba_accounts/control/synchronize_shared_waba`;

const messageOnBehalfReminderURL = ({
  partnerId,
  clientId,
  wabaAccountId,
}: {
  partnerId: string;
  clientId: string;
  wabaAccountId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/waba_accounts/${wabaAccountId}/control/send_mob_reminder`;

const businessVerificationReminderURL = ({
  partnerId,
  clientId,
  wabaAccountId,
}: {
  partnerId: string;
  clientId: string;
  wabaAccountId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/waba_accounts/${wabaAccountId}/control/send_business_verification_reminder`;

const wabaAccountPayment = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/control/payment_intent`;

export const getWabaAccountsList = async (
  { partnerId, clientId }: { partnerId: string; clientId: string },
  queryParams?: IQueryParams
): Promise<UIModelWabaAccounts> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseWabaAccounts>(
      wabaAccountsURL({ partnerId, clientId }),
      {
        params: queryParams && packQueryParams(queryParams),
        paramsSerializer: stringifyQueryParams,
      }
    );

  return new UIModelWabaAccounts(unpackWabaAccounts(data));
};

export const getWabaAccount = async ({
  partnerId,
  clientId,
  wabaAccountId,
}: {
  partnerId: string;
  clientId: string;
  wabaAccountId: string;
}) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseWabaAccount>(
      wabaAccountURL({ partnerId, clientId, wabaAccountId })
    );

  return new UIModelWabaAccount(unpackWabaAccount(data));
};

export const addWabaAccount = async (
  { partnerId, clientId }: { partnerId: string; clientId: string },
  payload: Types.IWabaAccountFormValues
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseWabaAccount>(
      wabaAccountsURL({ partnerId, clientId }),
      packWabaAccount(payload)
    );

  return new UIModelWabaAccount(unpackWabaAccount(data));
};

export const editWabaAccount = async (
  {
    partnerId,
    clientId,
    wabaAccountId,
  }: { partnerId: string; clientId: string; wabaAccountId: string },
  payload: Types.IWabaAccountFormValues
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.patch<Types.IServerResponseWabaAccount>(
      wabaAccountURL({ partnerId, clientId, wabaAccountId }),
      packWabaAccount(payload)
    );

  return unpackWabaAccount(data);
};

export const deleteWabaAccount = async ({
  partnerId,
  clientId,
  wabaAccountId,
}: {
  partnerId: string;
  clientId: string;
  wabaAccountId: string;
}) => {
  await MPInstancesHub.buffedInstances.newCrm.delete(
    wabaAccountURL({ partnerId, clientId, wabaAccountId })
  );
};

export const submitWaba = async ({
  partnerId,
  clientId,
  wabaAccountId,
}: {
  partnerId: string;
  clientId: string;
  wabaAccountId: string;
}) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseWabaAccount>(
      wabaAccountSubmitURL({ partnerId, clientId, wabaAccountId })
    );
  return unpackWabaAccount(data);
};

export const updateTemplateNamespace = async ({
  partnerId,
  clientId,
  wabaAccountId,
}: {
  partnerId: string;
  clientId: string;
  wabaAccountId: string;
}) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseWabaAccount>(
      wabaAccountUpdateTemplateNamespaceURL({
        partnerId,
        clientId,
        wabaAccountId,
      })
    );
  return unpackWabaAccount(data);
};

export const synchronizeStatus = async ({
  partnerId,
  clientId,
  wabaAccountId,
}: {
  partnerId: string;
  clientId: string;
  wabaAccountId: string;
}) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseWabaAccount>(
      wabaAccountSynchronizeStatusURL({ partnerId, clientId, wabaAccountId })
    );
  return unpackWabaAccount(data);
};

export const synchronizeSharedWaba = async (
  {
    partnerId,
    clientId,
  }: {
    partnerId: string;
    clientId: string;
  },
  payload: { external_id: string }
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseWabaAccount>(
      synchronizeSharedWabaURL({ partnerId, clientId }),
      payload
    );
  return unpackWabaAccount(data);
};

export const messageOnBehalfReminder = async ({
  partnerId,
  clientId,
  wabaAccountId,
}: {
  partnerId: string;
  clientId: string;
  wabaAccountId: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    messageOnBehalfReminderURL({ partnerId, clientId, wabaAccountId })
  );
  return data;
};

export const businessVerificationReminder = async ({
  partnerId,
  clientId,
  wabaAccountId,
}: {
  partnerId: string;
  clientId: string;
  wabaAccountId: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.post(
    businessVerificationReminderURL({ partnerId, clientId, wabaAccountId })
  );
  return data;
};

export const paymentWaba = async (
  { partnerId, clientId }: { partnerId: string; clientId: string },
  token: string
): Promise<Types.IUIResponseWabaPaymentIntent> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseWabaPaymentIntent>(
      wabaAccountPayment({ partnerId, clientId }),
      {},
      {
        params: {
          token,
        },
      }
    );
  return unpackWabaAccountPayment(data);
};
