import {
  ServerResponseLoginBlob,
  ServerResponseCurrentBlob,
  UIResponseLoginBlob,
  UIResponseCurrentBlob,
  ServerResponseHubProfileClient,
  UIResponseHubProfileClient,
  ServerResponseSignUp,
  UIResponseSignUp,
} from "./auth.types";

export const unpackServerLogin = (
  response: ServerResponseLoginBlob
): UIResponseLoginBlob => {
  return {
    accessToken: response.access_token,
    expiresIn: response.expires_in,
  };
};

export const unpackServerCurrent = (
  response: ServerResponseCurrentBlob
): UIResponseCurrentBlob => {
  return {
    applicationRights: response.application_rights,
    fullName: response.full_name,
    userName: response.user_name,
    organizations: response.organizations,
  };
};

export const unpackProfileClients = (
  response: ServerResponseHubProfileClient
): UIResponseHubProfileClient => {
  return {
    client_id: response.client_id,
    partner_id: response.partner_id,
    permissions: response.permissions,
    client_name: response.client_name,
  };
};

export const unpackSignUp = (
  response: ServerResponseSignUp
): UIResponseSignUp => ({
  accessToken: response.access_token,
  clientId: response.client_id,
  paymentIntentToken: response.token,
});
