import * as React from "react";
import { observer } from "mobx-react";
import {
  observable,
  IReactionDisposer,
  reaction,
  runInAction,
  makeObservable,
} from "mobx";

import * as types from "./NotificationSystem.types";
import * as Styled from "./NotificationSystem.style";
import Toast from "@gcp-artifact/compass/lib/components/toast";

class Notification extends React.Component<types.NotificationProps> {
  timeout = 0;
  timeRestartNeededDisposer: IReactionDisposer;

  constructor(props: types.NotificationProps) {
    super(props);
    makeObservable(this, {
      timeout: observable,
    });
  }

  componentDidMount() {
    const { notification, remove } = this.props;
    if (notification.timeoutToClose && notification.shouldCloseAutomatically) {
      // let message = translate(k.SomethingWentWrongError);
      if (typeof notification.message === "string") {
        // message = notification.message;
      }
      this.timeout = setTimeout(
        remove(notification.id),
        notification.timeoutToClose * 1000
      );
      this.timeRestartNeededDisposer = reaction(
        () => notification.restartTimeCounter,
        () => {
          runInAction(() => {
            if (notification.restartTimeCounter) {
              clearTimeout(this.timeout);
              this.timeout = setTimeout(
                remove(notification.id),
                notification.timeoutToClose * 1000
              );
              notification.restartTimeCounter = false;
            }
          });
        }
      );
    }
  }

  componentWillUnmount() {
    this.timeRestartNeededDisposer && this.timeRestartNeededDisposer();
    clearTimeout(this.timeout);
  }

  handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { notification, remove } = this.props;

    notification?.onClickAction?.();
    const removeHandler = remove(notification.id);
    removeHandler(e);
  };

  render() {
    const { notification } = this.props;

    return (
      <Toast
        message={notification.message}
        description={notification.description}
        type={notification.type}
        action={notification.action}
        handleClose={this.handleClose}
        showCloseButton={notification.showCloseButton}
      />
    );
  }
}

const NotificationWithObserve = observer(Notification);

class NotificationSystem extends React.Component<types.NotificationSystemProps> {
  removeElement = (id: number) => () => {
    this.props.notificationsSystemState.remove(id);
  };

  render() {
    return (
      <Styled.NotificationSystem>
        {this.props.notificationsSystemState.notifications.map(
          (notification) => (
            <NotificationWithObserve
              notification={notification}
              remove={this.removeElement}
              key={notification.id}
            />
          )
        )}
      </Styled.NotificationSystem>
    );
  }
}

export default observer(NotificationSystem);
