// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.TOS_acceptAndClose]: "Accept and close",
  [keys.TOS_failedToLoad]:
    "Failed to load the Terms of Service. Please contact support through info@360dialog.com or support@360dialog.com",
  [keys.TOS_changeOfTos]:
    "360dialog has added a new feature in the Client Hub to help us track your acceptance of the Terms of Service and receive future updates here. For this purpose, the current Terms of Service now requires re-acceptance. \n \n After clicking continue, you will be redirected to re-accept the Terms of Service.",
  [keys.TOS_importantUpdate]: "Important update: ",
};

export default translate;
