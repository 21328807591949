// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.MIG_progressTitle]: "Progreso de migración del número",
  [keys.MIG_progressStep1]: "Enviar datos de la cuenta",
  [keys.MIG_progressStep2]: "Aceptar messaging on behalf",
  [keys.MIG_progressStep3]: "Verificar el número de teléfono",
  [keys.MIG_numberHintTitle]:
    "Por favor, introduzca el número de teléfono a portar a continuación.",
  [keys.MIG_numberhintText1]:
    "Es importante que se asegure de que la autenticación de dos factores de este número se encuentra desactivada.",
  [keys.MIG_numberhintText2]:
    "Consejo: las cifras entre paréntesis no formarán parte del número enviado.",
  [keys.MIG_mobTitle]: "Message on behalf",
  [keys.MIG_mobText]:
    "¡Estupendo! El siguiente paso para configurar su cuenta de WhatsApp Business API es iniciar sesión en su Meta Business Manager y aceptar la solicitud de messaging on behalf que 360dialog le ha enviado.",
  [keys.MIG_openRequestsButtonLabel]: "Abrir Meta Business Manager",
  [keys.MIG_alreadyAcceptedMob]:
    "Una vez que haya aceptado la solicitud de messaging on behalf, actualice el estado de la solicitud haciendo clic en el botón a continuación.",
  [keys.MIG_pleaseReturnAfterMob]:
    "Por favor, vuelva a esta página y continúe más abajo después de aceptar nuestra solicitud.",
  [keys.MIG_mobCheckboxLabel]:
    "Ya acepté la petición de Message on Behalf en mi Meta Business Manager.",
  [keys.MIG_checkStatusButtonLabel]: "Actualizar Estado de la Solicitud",
  [keys.MIG_checkAgainIn]: "Consulte el estado otra vez en <COUNTDOWN> s",
  [keys.MIG_mobSuccessText]:
    "Ha aceptado con éxito nuestra solicitud de message on behalf.",
  [keys.MIG_continueMobButtonLabel]:
    "Continuar para verificar el número de teléfono",
  [keys.MIG_verifyTitle]: "Verificar propietario del número de teléfono",
  [keys.MIG_verifyText1]:
    "El último paso para activar su cuenta de WhatsApp Business API  y acceder a su API key es verificar el propietario de su número de teléfono.",
  [keys.MIG_verifyText2]:
    "Para verificar su número de teléfono, debe solicitar un código de registro a continuación a través de un mensaje de texto SMS o una llamada de voz. Se enviará un código PIN de 6 dígitos a su número de teléfono.",
  [keys.MIG_verifyRadioLabel]: "Seleccione un modo de verificación",
  [keys.MIG_verifyRadioOptionText]: "Mensaje de texto (SMS)",
  [keys.MIG_verifyRadioOptionVoice]: "Llamada de voz",
  [keys.MIG_verifySelectInfo]:
    "Seleccione el idioma en el que desea recibir el código de registro",
  [keys.MIG_triggerRegistrationButtonLabel]: "Activar envío del código",
  [keys.MIG_codeWillBeSent]: "El código será enviado al <NUMBER>.",
  [keys.MIG_alreadyHaveCode]: "¿Ya tiene el código?",
  [keys.MIG_codeWasSent]: "El código fue enviado al <NUMBER>",
  [keys.MIG_codeInputLabel]: "Código de registro",
  [keys.MIG_submitCodeButtonLabel]: "Enviar código",
  [keys.MIG_requestNewCodeIn]: "Puede solicitar un nuevo código en {{time}}s",
  [keys.MIG_requestNewCodeButtonLabel]: "Solicitar nuevo código",
  [keys.MIG_errorHint]:
    "El código no se pudo verificar. Por favor, inténtelo de nuevo o solicite un nuevo código.",
  [keys.MIG_setupTitle]: "Configurando su número",
  [keys.MIG_setupText1]:
    "Estamos configurando su número en la WhatsApp Business API. Esto puede tardar algunos minutos. Por favor, espere.",
  [keys.MIG_setupText2]:
    "Por favor NO cierre esta ventana. Podrá generar una API key inmediatamente después de este proceso.",
  [keys.MIG_registrationFailed]: "Registro fallido",
  [keys.MIG_errorMobTitle]:
    "La petición de Message on Behalf aún no fue aceptada.",
  [keys.MIG_errorMobText]:
    "Antes de que pueda verificar la propiedad de su número de teléfono, primero debe aceptar nuestra solicitud de message on behalf. De lo contrario, no podremos portar su número.",
  [keys.MIG_errorMobInfo]:
    "Puede encontrar más información sobre cómo aceptar la solicitud en nuestra <LINK>https://docs.360dialog.com/submission-process#4-approve-the-messaging-on-behalf-request | documentación<LINK>.",
  [keys.MIG_error2faTitle]:
    "La autenticación de dos factores todavía no ha sido desactivada.",
  [keys.MIG_error2faText]:
    "Antes de poder verificar la propiedad de su número de teléfono, primero debe desactivar la autenticación de dos factores (2FA) para este número. Puede hacerlo usted o el Business Solution Provider que actualmente está a cargo de este número.",
  [keys.MIG_error2faInfo]:
    "Visite nuestra <LINK>https://docs.360dialog.com | guía sobre cómo desactivar la 2FA para diferentes BSPs<LINK>.",
  [keys.MIG_bspDialogTitle]: "Antes de empezar",
  [keys.MIG_bspDialogText]:
    "Asegúrese de que la autenticación de dos factores (2FA) esté deshabilitada para el número que está a punto de portar. ¿Su número está registrado con una de estas empresas? Comuníquese con ellos para deshabilitar la 2FA.",
  [keys.MIG_buttonLabelRetrySetup]: "Reintentar configuración",
  [keys.MIG_fbErrorNewNumber]:
    "Este número de teléfono es elegible para agregarse directamente y no necesita ser portado. Vaya al 360dialog Client Hub y agréguelo como un nuevo número. ",
  [keys.MIG_fbErrorAlreadyPorted]:
    "El número de teléfono que está intentando portar ya ha sido movido a su cuenta de WhatsApp de destino. Inicie sesión en el 360dialog Client Hub para continuar.",
  [keys.MIG_fbErrorDifferentBusiness]:
    "Las cuentas de WhatsApp Business de origen y destino deben representar la misma empresa. Utilice el mismo Meta Business ID que antes cuando envíe el número para la migración.",
  [keys.MIG_fbErrorUnknown]:
    "Se produjo un error al intentar migrar su número de teléfono. Inténtelo de nuevo después de un tiempo. Si eso no funciona, comuníquese con nuestro soporte a través del 360dialog Client Hub.",
  [keys.MIG_fbErrorInvalidNumber]:
    "Su número de teléfono no parece ser válido. Por favor, verifíquelo. Espere 5 minutos antes de volver a intentarlo.",
  [keys.MIG_fbErrorNotVerified]:
    "Su cuenta de WhatsApp Business debe ser aprobada.",
  [keys.MIG_fbErrorWabaNotCorrectlySetup]:
    "La cuenta de WhatsApp con la que está registrado este número no está configurada correctamente.",
  [keys.MIG_fbErrorWaitNewCode]:
    "Hay que esperar hasta que se pueda volver a activar su código. Por favor, espere <HORA> y <MINUTOS> antes de volver a intentarlo.",
  //  [keys.MIG_importNumber]: "translation_missing",
  //  [keys.MIG_enterNumber]: "translation_missing",
  //  [keys.MIG_enterNumberDescription]: "translation_missing",
  //  [keys.MIG_selectWaba]: "translation_missing",
  //  [keys.MIG_selectWabaDescription]: "translation_missing",
  //  [keys.MIG_createWabaTitle]: "translation_missing",
  //  [keys.MIG_createWabaDescription]: "translation_missing",
  //  [keys.MIG_createWabaWarning]: "translation_missing",
  //  [keys.MIG_createWabaSteps]: "translation_missing",
  //  [keys.MIG_createWabaFollowSteps]: "translation_missing",
  //  [keys.MIG_createWabaStep1]: "translation_missing",
  //  [keys.MIG_createWabaStep1Description]: "translation_missing",
  //  [keys.MIG_createWabaStep2]: "translation_missing",
  //  [keys.MIG_createWabaStep2Description]: "translation_missing",
  //  [keys.MIG_createWabaStep3]: "translation_missing",
  //  [keys.MIG_createWabaStep3Description]: "translation_missing",
  //  [keys.MIG_createWabaError]: "translation_missing",
  //  [keys.MIG_proceedInClientHub]: "translation_missing",
  //  [keys.MIG_numberMigrationTitle]: "translation_missing",
  //  [keys.MIG_proceedMigrationText1]: "translation_missing",
  //  [keys.MIG_proceedMigrationText2]: "translation_missing",
  //  [keys.MIG_continueWithMigration]: "translation_missing",
  //  [keys.MIG_migrateToDifferentWaba]: "translation_missing",
  //  [keys.MIG_migrateSuccess]: "translation_missing",
  //  [keys.MIG_validCode]: "translation_missing",
  //  [keys.MIG_invalidCode]: "translation_missing",
  //  [keys.MIG_verifyOwnership]: "translation_missing",
  //  [keys.MIG_resendCode]: "translation_missing",
  //  [keys.MIG_requestNewCode]: "translation_missing",
  //  [keys.MIG_migrateNumber]: "translation_missing",
  //  [keys.MIG_importOtherBSP]: "translation_missing",
  //  [keys.MIG_importTo360dialog]: "translation_missing",
  //  [keys.MIG_importFromOtherBSP]: "translation_missing",
  //  [keys.MIG_phoneNumberDescription]: "translation_missing",
  //  [keys.MIG_accessMetaBusinessManager]: "translation_missing",
  //  [keys.MIG_howCanIFindIt]: "translation_missing",
  //  [keys.MIG_howCanIFindItText]: "translation_missing",
  //  [keys.MIG_provideWabaId]: "translation_missing",
  //  [keys.MIG_migrateExisting]: "translation_missing",
  //  [keys.MIG_repeatProcess]: "translation_missing",
  //  [keys.MIG_settingUpNumber]: "translation_missing",
  //  [keys.MIG_channelNotReady]: "translation_missing",
  //  [keys.MIG_disabled2faCheckbox]: "translation_missing",
  //  [keys.MIG_disable2faStepOne]: "translation_missing",
  //  [keys.MIG_disable2faStepTwo]: "translation_missing",
  //  [keys.MIG_disable2fa]: "translation_missing",
  //  [keys.MIG_disable2faDescription]: "translation_missing",
  //  [keys.MIG_disable2faFollowSteps]: "translation_missing",
  //  [keys.MIG_businessVerifiedCheckbox]: "translation_missing",
  //  [keys.MIG_howDoIDisable2fa]: "translation_missing",
  //  [keys.MIG_importFromOtherProvider]: "translation_missing",
  //  [keys.MIG_ifYouCannotFindWabaId]: "translation_missing",
  //  [keys.MIG_clickToCreateWabaAccount]: "translation_missing",
  //  [keys.MIG_createWabaAccount]: "translation_missing",
  //  [keys.MIG_iHaveCreatedTheWabaACcount]: "translation_missing",
  //  [keys.MIG_youNeedToVerify]: "translation_missing",
  //  [keys.MIG_verifyOwnershipTitle]: "translation_missing",
  //  [keys.MIG_verifyNumber]: "translation_missing",
  //  [keys.MIG_continueWithEs]: "translation_missing",
  //  [keys.MIG_shareWabaWithUs1]: "translation_missing",
  //  [keys.MIG_shareWabaWithUs2]: "translation_missing",
  //  [keys.MIG_shareWabaWithUs3]: "translation_missing",
  //  [keys.MIG_shareWabaWithUs4]: "translation_missing",
  //  [keys.MIG_somethingWentWrongEs]: "translation_missing",
  // [keys.MIG_disabledPlanInfo]: "translation_missing",
  // [keys.MIG_missingPlans]: "translation_missing",
};

export default translate;
