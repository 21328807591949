// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.SET_partnerName]: "Integración de partner",
  [keys.SET_partnerPermissions]: "Permisos de partner",
  [keys.SET_selectTitle]:
    "Seleccione los números a los que desea dar permiso al partner para acceder a la API de WhatsApp Business.",
  [keys.SET_saveButton]: "Guardar modificaciones",
  [keys.SET_successGrantPermissions]: "Permisos alterados con éxito",
  [keys.SET_Inboxes]: "Inboxes",
  [keys.SET_selectNumberInbox]:
    "Seleccione los números que desea conectar a su bandeja de entrada de get.chat",
  [keys.SET_requestPartnerChange]: "Solicitar cambio de socio",
  [keys.SET_requestApproved]:
    "Solicitud de cambio de partner aprobada: migración el {{date}}",
  [keys.SET_requestPending]: "Solicitud de cambio de partner pendiente",
  [keys.SET_selectNewPartner]: "Selecciona nuevo partner",
  [keys.SET_confirmTerms]: "Confirmar términos",
  [keys.SET_submitPartnerChangeRequest]: "Enviar solicitud de cambio de socio",
  [keys.SET_addPartnerIdMessage]:
    "Proporcione el ID de partner del nuevo partner de integración al que desea migrar. Asegúrese de estar en contacto con su nuevo partner antes de enviar esta solicitud. También podrán proporcionarle este ID.",
  [keys.SET_enterPartnerId]: "Introduce el ID del partner",
  [keys.SET_notValidPartnerId]: "Este no es un ID de partner válido",
  [keys.SET_partnerIdFound]: "ID de partner encontrado",
  [keys.SET_newPartnerText]: "Tu nuevo partner será",
  [keys.SET_currentPartnerText]: "Este es tu partner actual",
  [keys.SET_currencyMismatchErrorText]:
    "La moneda de facturación admitida por el nuevo partner no coincide con la moneda de su(s) cuenta(s) comercial(es) de WhatsApp. Utilice el siguiente formulario para enviar un mensaje a nuestro soporte para la conversión de moneda.",
  [keys.SET_messageSupportLabel]: "Su mensaje a nuestro soporte",
  [keys.SET_messageSupportPlaceholder]: "¿Cómo te podemos ayudar?",
  [keys.SET_billingPlanDesc1]:
    "Mensajes de sesión de WhatsApp entrantes y salientes ilimitados",
  [keys.SET_billingPlanDesc2]:
    "Plantilla de mensajes salientes según lista de precios de WhatsApp. Sin marcado.¹",
  [keys.SET_billingPlanDesc3]: ">98 % Nivel de servicio",
  [keys.SET_billingPlanDesc4]:
    "Cuenta comercial oficial de WhatsApp (insignia verde) previa aprobación",
  //  [keys.SET_partnerChangeConditionText]: "translation_missing",
  [keys.SET_messageToNewPartner]: "Mensaje opcional a tu nuevo partner",
  [keys.SET_messageToNewPartnerPlaceholder]:
    "Agregue cualquier información relevante para su nuevo partner, como ID de usuario, correo electrónico, ID de ticket de soporte, etc.",
  [keys.SET_partnerChangeRequestSubmitted]:
    "Su solicitud de cambio de partner se envió correctamente. Se le notificará tan pronto como se complete la migración.",
  [keys.SET_cancelling]: "¿Cancelar solicitud de cambio de partner?",
  [keys.SET_rejectionInputLabel]: "Por favor, indique el motivo del rechazo",
  [keys.SET_rejectionInputPlaceholder]: "Introduzca el motivo del rechazo",
  [keys.SET_rejectionInputError]:
    "Proporcione una respuesta de al menos {{min}} caracteres",
  [keys.SET_rejectClient]: "Rechazar cliente",
  [keys.SET_acceptClient]: "Aceptar cliente",
  [keys.SET_allExternalWabaMustHaveSameCurrency]:
    "Todas las cuentas de WhatsApp Business de un cliente deben tener la misma moneda en Meta Business Manager",
  //  [keys.SET_paymentPlanCurrencyHasToMatch]: "translation_missing",
  //  [keys.SET_partnerChangeRequestTargetPartnerPaymentPlanRequired]: "translation_missing",
  //  [keys.SET_partnerChangeRequestTargetPartnerPricingPlanRequired]: "translation_missing",
  //  [keys.SET_partnerChangeRequestClientPaymentMethodRequired]: "translation_missing",
  //  [keys.SET_wabaAndPaymentPlanCurrencyMustMatch]: "translation_missing",
  //  [keys.SET_paymentPlanHasToMatch]: "translation_missing",
  //  [keys.SET_addPaymentMethod]: "translation_missing",
  //  [keys.SET_migrationNote]: "translation_missing",
  //  [keys.SET_apiKeyRecommendation]: "translation_missing",
  //  [keys.SET_languageSelection]: "translation_missing",
  //  [keys.SET_askForRefund]: "translation_missing",
  //  [keys.SET_disabledPartnerChangeTooltip]: "translation_missing",
  //  [keys.SET_addCompanyInfo]: "translation_missing",
  //  [keys.SET_selectPlan]: "translation_missing",
  //  [keys.SET_triggerRefundMessage]: "translation_missing",
  //  [keys.SET_errorPartnerChange]: "translation_missing",
  //  [keys.SET_errorPartnerChangeInfo]: "translation_missing",
  //  [keys.SET_errorPartnerChangeHelp]: "translation_missing",
  //  [keys.SET_PCRNotEnabled]: "translation_missing",
  //  [keys.SET_standardBillNotice]: "translation_missing",
  //  [keys.SET_premiumBillNotice]: "translation_missing",
  //  [keys.SET_updateDetails]: "translation_missing",
  //  [keys.SET_generalInfo]: "translation_missing",
  [keys.SET_nameCompany]: "Detalles generales de su empresa",
  [keys.SET_locationCompany]: "Detalles de facturación de su empresa",
  [keys.SET_editDetails]: "Editar detalles",
  //  [keys.SET_saveDetails]: "translation_missing",
  //  [keys.SET_checkYourInvoices]: "translation_missing",
  //  [keys.SET_invoiceId]: "translation_missing",
  //  [keys.SET_invoiceIssueAt]: "translation_missing",
  [keys.SET_inviteMember]: "Invitar a un miembro",
  //  [keys.SET_inviteMemberProject]: "translation_missing",
  //  [keys.SET_member]: "translation_missing",
  [keys.SET_companyMembers]: "Los miembros de su empresa",
  //  [keys.SET_role]: "translation_missing",
  [keys.SET_informationPartner]: "Información sobre su partner",
  [keys.SET_changePartner]: "Cambiar de partner",
  //  [keys.SET_currentPartner]: "translation_missing",
  [keys.SET_currentPartnerInfo]: "Información sobre su partner actual",
  //  [keys.SET_selectedPaymentMethod]: "translation_missing",
  //  [keys.SET_changePaymentMethod]: "translation_missing",
  //  [keys.SET_cardDetails]: "translation_missing",
  //  [keys.SET_cardHolder]: "translation_missing",
  //  [keys.SET_cardExpiryDate]: "translation_missing",
  //  [keys.SET_cardEndsWith]: "translation_missing",
  //  [keys.SET_card]: "translation_missing",
  //  [keys.SET_changeEmail]: "translation_missing",
  //  [keys.SET_verifyEmailText]: "translation_missing",
  //  [keys.SET_emailChangeSuccessful]: "translation_missing",
  //  [keys.SET_passcodeText]: "translation_missing",
  //  [keys.SET_emailToChangeToText]: "translation_missing",
  //  [keys.SET_changeName]: "translation_missing",
  //  [keys.SET_nameToChangeToText]: "translation_missing",
  //  [keys.SET_nameChangeSuccessful]: "translation_missing",
  //  [keys.SET_messagingAndPaymentPlanCurrencyMustMatchForINR]: "translation_missing",
  [keys.SET_general]: "General",
  [keys.SET_payment]: "Pagos",
  [keys.SET_support]: "Soporte",
  [keys.SET_partner]: "Partner",
  [keys.SET_adAccounts]: "Cuentas publicitarias",
  [keys.SET_invoices]: "Facturas",
  [keys.SET_documentation]: "Documentación",
  [keys.SET_roadmap]: "Hoja de ruta",
  [keys.SET_logout]: "Cerrar sesión",
  [keys.SET_companyAndBilling]: "Detalles de empresa y facturación",
  [keys.SET_teamManagement]: "Gestión del equipo",
  [keys.SET_syncedAdAccounts]: "Cuentas publicitarias sincronizadas",
  [keys.SET_getAssistance]: "Solicita asistencia",
  [keys.SET_selectedPayment]: "Método de pago seleccionado",
  [keys.SET_issuedInvoices]: "Facturas emitidas",
  [keys.SET_aboutPartner]: "Sobre tu partner",
  [keys.SET_companyGeneral]:
    "Detalles generales y de facturación de su empresa",
  [keys.SET_connectAdAccount]: "Conectar cuenta publicitaria",
  [keys.SET_adAccountsDescription]:
    "Conecta tu cuenta de Facebook para desbloquear funciones poderosas: rastrea campañas publicitarias, vincúlalas a conversaciones, mejora la creación de audiencias personalizadas e intégralas sin problemas con conversaciones de WhatsApp.",
  //  [keys.SET_adAccountsEmptyTitle]: "translation_missing",
  //  [keys.SET_adAccountsEmptyDescription]: "translation_missing",
  [keys.SET_accountName]: "Nombre de la cuenta",
  [keys.SET_accountID]: "ID de la cuenta",
  //  [keys.SET_refreshData]: "translation_missing",
  //  [keys.SET_successfulRefreshedDataMsg]: "translation_missing",
  //  [keys.SET_refreshingDataInFlightMsg]: "translation_missing",
  // [keys.SET_noInvoices]: "translation_missing",
  // [keys.SET_noInvoicesDescription]: "translation_missing",
  // [keys.SET_name]: "translation_missing",
  // [keys.SET_email]: "translation_missing",
  // SET_paid: "translation_missing",
  // SET_unpaid: "translation_missing",
  // SET_error: "translation_missing",
  // SET_pending: "translation_missing",
  // SET_processing: "translation_missing",
  // SET_refunded: "translation_missing",
  // SET_refundTriggered: "translation_missing",
  // SET_partiallyRefunded: "translation_missing",
  // SET_partialRefundTriggered: "translation_missing",
  // SET_triggered: "translation_missing",
  // SET_unknownStatus: "translation_missing",
  // SET_status: "translation_missing",
  [keys.SET_companyTeamMembers]: "Los miembros de su empresa",
  [keys.SET_confirmPaymentMethod]: "Confirmar método de pago",
  [keys.SET_confirmPaymentMethodDesc]:
    "Confirme su método de pago para continuar con el proceso de cambio de socio.",
  [keys.SET_pendingApprovalStatus]:
    "Solicitud de cambio de socio pendiente de aprobación",
  [keys.SET_cancelPCRTitle]: "Cancelar solicitud de cambio de socio",
  [keys.SET_cancelPCRDesc]: "¿Desea anular su solicitud de cambio de socio?",
  [keys.SET_submitPartnerChangeRequestMessage]:
    "Su solicitud de cambio de socio ha sido enviada y está siendo procesada",
  [keys.SET_PCRfailed]: "Solicitud de cambio de socio fallida",
  [keys.SET_funds]: "Fondos",
  [keys.SET_aboutFunds]: "Cargos por el WABA",
  [keys.SET_activity]: "Actividad",
  [keys.SET_aboutActivity]: "Conversaciones y gastos",
  // [keys.SET_invoicePaymentRequired1]: "translation_missing",
  // [keys.SET_invoicePaymentRequired2]: "translation_missing",
  // [keys.SET_migrationSupport]: "translation_missing",
};

export default translate;
