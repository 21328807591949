// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.MIG_progressTitle]: "Ход портирования номера",
  [keys.MIG_progressStep1]: "Отправка данных аккаунта",
  [keys.MIG_progressStep2]: "Запрос на отправку сообщений от имени аккаунта",
  [keys.MIG_progressStep3]: "Подтверждение номера телефона",
  [keys.MIG_numberHintTitle]:
    "Пожалуйста, введите ниже номер телефона, который вы хотите перенести.",
  [keys.MIG_numberhintText1]:
    "Убедитесь, что двухфакторная аутентификация (2FA) для этого номера отключена, - это необходимо для завершения портирования. ",
  [keys.MIG_numberhintText2]:
    "Примечание: цифры в скобках не учитываются как часть введенного номера.",
  [keys.MIG_mobTitle]: "Отправка сообщений от имени аккаунта",
  [keys.MIG_mobText]:
    "Отлично! Чтобы перейти к следующему этапу настройки аккаунта WhatsApp Business API, войдите в Meta Business Manager и примите запрос 360dialog на отправку сообщений от вашего имени.",
  [keys.MIG_openRequestsButtonLabel]: "Открыть Meta Business Manager",
  [keys.MIG_alreadyAcceptedMob]:
    "После того, как вы приняли запрос 360dialog на отправку сообщений от вашего имени, пожалуйста, обновите статус, нажав кнопку ниже.",
  [keys.MIG_pleaseReturnAfterMob]:
    "Пожалуйста, вернитесь на эту страницу для продолжения после того, как примете наш запрос.",
  [keys.MIG_mobCheckboxLabel]:
    "Я принял запрос 360dialog на отправку сообщений от имени аккаунта в моем бизнес-менеджере Meta",
  [keys.MIG_checkStatusButtonLabel]: "Обновить статус запроса",
  [keys.MIG_checkAgainIn]: "Обновить статус повторно через <COUNTDOWN> с",
  [keys.MIG_mobSuccessText]:
    "Вы успешно приняли запрос 360dialog на отправку сообщений от вашего имени.",
  [keys.MIG_continueMobButtonLabel]: "Перейти к подтверждению номера",
  [keys.MIG_verifyTitle]: "Подтвердить номер телефона",
  [keys.MIG_verifyText1]:
    "Последний шаг для активации аккаунта WhatsApp Business API и доступа к API-ключу - это подтверждение номера телефона.",
  [keys.MIG_verifyText2]:
    "Чтобы подтвердить свой номер телефона, вам необходимо запросить регистрационный код. Получить его можно либо в SMS-сообщении, либо с помощью голосового вызова. 6-значный пин-код будет оптравлен на ваш номер телефона.",
  [keys.MIG_verifyRadioLabel]: "Выбрать способ подтверждения",
  [keys.MIG_verifyRadioOptionText]: "Сообщение (SMS)",
  [keys.MIG_verifyRadioOptionVoice]: "Голосовой вызов",
  [keys.MIG_verifySelectInfo]:
    "Выберите язык для отправки регистрационного кода",
  [keys.MIG_triggerRegistrationButtonLabel]: "Начать регистрацию",
  [keys.MIG_codeWillBeSent]: "Код будет отправлен на номер <NUMBER>.",
  [keys.MIG_alreadyHaveCode]:
    "У вас уже есть код? <BUTTON>Перейти к следующему шагу <BUTTON>.",
  [keys.MIG_codeWasSent]: "Код был отправлен на номер <NUMBER>",
  [keys.MIG_codeInputLabel]: "Регистрационный код",
  [keys.MIG_submitCodeButtonLabel]: "Проверить код",
  [keys.MIG_requestNewCodeIn]: "Запросить новый код через {{time}}с",
  [keys.MIG_requestNewCodeButtonLabel]: "Запросить новый код",
  [keys.MIG_errorHint]:
    "Веденный код не прошел проверку. Попробуйте еще раз или запросите новый код.",
  [keys.MIG_setupTitle]: "Завершение портирования и настройка номера",
  [keys.MIG_setupText1]:
    "В настоящее время мы завершаем настройки и запускаем ваш WhatsApp Business API клиент. Это может занять несколько минут. Пожалуйста, подождите.",
  [keys.MIG_setupText2]:
    "Пожалуйста, не закрывайте это окно. После завершения настройки вы сразу сможете создать API-ключ!",
  [keys.MIG_registrationFailed]: "Ошибка регистрации",
  [keys.MIG_errorMobTitle]:
    "Запрос 360dialog на отправку сообщений от вашего имени еще не был принят",
  [keys.MIG_errorMobText]:
    "Прежде чем вы сможете перейти к подтверждению номера телефона, вам необходимо принять запрос 360dialog на отправку сообщений от имени вашего аккаунта. В противном случае мы не сможем портировать ваш номер.",
  [keys.MIG_errorMobInfo]:
    "Более подробную информацию о том, как принять этот запрос, можно найти в нашей <LINK>https://docs.360dialog.com/submission-process#4-approve-the-messaging-on-behalf-request | документации<LINK>.",
  [keys.MIG_error2faTitle]:
    "Двухфакторная аутентификация (2FA) еще не отключена",
  [keys.MIG_error2faText]:
    "Прежде чем вы сможете перейти к подтверждению номера телефона, вам необходимо отключить двухфакторную аутентификацию (2FA) для этого номера. Это можете сделать вы или ваш текущий провайдер WABA (Business Solution Provider), отвечающий за этот номер.",
  [keys.MIG_error2faInfo]:
    "Ознакомьтесь с нашим <LINK>https://docs.360dialog.com | руководством по отключению двухфакторной аутентификации (2FA) для различных провадеров WABA (Business Solution Provider)<LINK>.",
  [keys.MIG_bspDialogTitle]: "Прежде чем вы приступите к портированию",
  [keys.MIG_bspDialogText]:
    "Убедитесь, что двухфакторная аутентификация (2FA) отключена для номера, который вы собираетесь переносить. Ваш номер зарегистрирован в одной из этих компаний? Свяжитесь с ними, чтобы отключить двухфакторную аутентификацию.",
  [keys.MIG_buttonLabelRetrySetup]: "Повторить настройку",
  [keys.MIG_fbErrorNewNumber]:
    "Этот номер телефона может быть добавлен напрямую, и его не требуется переносить. Перейдите в 360dialog Client Hub и добавьте его как новый номер.",
  [keys.MIG_fbErrorAlreadyPorted]:
    "Номер телефона, для которого вы пытаетесь выполнить миграцию, уже был перемещен в ваш целевой аккаунт WhatsApp Business API. Пожалуйста, войдите в 360dialog Client Hub чтобы продолжить.",
  [keys.MIG_fbErrorDifferentBusiness]:
    "Исходная и конечная учетные записи WhatsApp Business API должны представлять одну и ту же компанию (бизнес). Пожалуйста, используйте тот же Meta Business ID, что и раннее, при отправке номера на миграцию.",
  [keys.MIG_fbErrorUnknown]:
    "Что-то пошло не так при попытке перенести ваш номер телефона. Пожалуйста, попробуйте еще раз через некоторое время. Если это не поможет, обратитесь в нашу службу поддержки через 360dialog Client Hub.",
  [keys.MIG_fbErrorInvalidNumber]:
    "Ваш номер телефона недействителен. Пожалуйста, проверьте его правильность еще раз. Перед повторением попытки необходимо выдержать 5-минутный интервал.",
  [keys.MIG_fbErrorNotVerified]:
    "Ваш аккаунт WhatsApp Business API должен быть одобрен Meta.",
  [keys.MIG_fbErrorWabaNotCorrectlySetup]:
    "Учетная запись WhatsApp Business API, в которой зарегистрирован этот номер, настроена некорректно.",
  [keys.MIG_fbErrorWaitNewCode]:
    "Вам необходимо подождать прежде чем вы сможете снова запросить ваш код. Пожалуйста, подождите <HOUR> и <MINUTES> перед тем как попытаться снова.",
  //  [keys.MIG_importNumber]: "translation_missing",
  //  [keys.MIG_enterNumber]: "translation_missing",
  //  [keys.MIG_enterNumberDescription]: "translation_missing",
  //  [keys.MIG_selectWaba]: "translation_missing",
  //  [keys.MIG_selectWabaDescription]: "translation_missing",
  //  [keys.MIG_createWabaTitle]: "translation_missing",
  //  [keys.MIG_createWabaDescription]: "translation_missing",
  //  [keys.MIG_createWabaWarning]: "translation_missing",
  //  [keys.MIG_createWabaSteps]: "translation_missing",
  //  [keys.MIG_createWabaFollowSteps]: "translation_missing",
  //  [keys.MIG_createWabaStep1]: "translation_missing",
  //  [keys.MIG_createWabaStep1Description]: "translation_missing",
  //  [keys.MIG_createWabaStep2]: "translation_missing",
  //  [keys.MIG_createWabaStep2Description]: "translation_missing",
  //  [keys.MIG_createWabaStep3]: "translation_missing",
  //  [keys.MIG_createWabaStep3Description]: "translation_missing",
  //  [keys.MIG_createWabaError]: "translation_missing",
  //  [keys.MIG_proceedInClientHub]: "translation_missing",
  //  [keys.MIG_numberMigrationTitle]: "translation_missing",
  //  [keys.MIG_proceedMigrationText1]: "translation_missing",
  //  [keys.MIG_proceedMigrationText2]: "translation_missing",
  //  [keys.MIG_continueWithMigration]: "translation_missing",
  //  [keys.MIG_migrateToDifferentWaba]: "translation_missing",
  //  [keys.MIG_migrateSuccess]: "translation_missing",
  //  [keys.MIG_validCode]: "translation_missing",
  //  [keys.MIG_invalidCode]: "translation_missing",
  //  [keys.MIG_verifyOwnership]: "translation_missing",
  //  [keys.MIG_resendCode]: "translation_missing",
  //  [keys.MIG_requestNewCode]: "translation_missing",
  //  [keys.MIG_migrateNumber]: "translation_missing",
  //  [keys.MIG_importOtherBSP]: "translation_missing",
  //  [keys.MIG_importTo360dialog]: "translation_missing",
  //  [keys.MIG_importFromOtherBSP]: "translation_missing",
  //  [keys.MIG_phoneNumberDescription]: "translation_missing",
  //  [keys.MIG_accessMetaBusinessManager]: "translation_missing",
  //  [keys.MIG_howCanIFindIt]: "translation_missing",
  //  [keys.MIG_howCanIFindItText]: "translation_missing",
  //  [keys.MIG_provideWabaId]: "translation_missing",
  //  [keys.MIG_migrateExisting]: "translation_missing",
  //  [keys.MIG_repeatProcess]: "translation_missing",
  //  [keys.MIG_settingUpNumber]: "translation_missing",
  //  [keys.MIG_channelNotReady]: "translation_missing",
  //  [keys.MIG_disabled2faCheckbox]: "translation_missing",
  //  [keys.MIG_disable2faStepOne]: "translation_missing",
  //  [keys.MIG_disable2faStepTwo]: "translation_missing",
  //  [keys.MIG_disable2fa]: "translation_missing",
  //  [keys.MIG_disable2faDescription]: "translation_missing",
  //  [keys.MIG_disable2faFollowSteps]: "translation_missing",
  //  [keys.MIG_businessVerifiedCheckbox]: "translation_missing",
  //  [keys.MIG_howDoIDisable2fa]: "translation_missing",
  //  [keys.MIG_importFromOtherProvider]: "translation_missing",
  //  [keys.MIG_ifYouCannotFindWabaId]: "translation_missing",
  //  [keys.MIG_clickToCreateWabaAccount]: "translation_missing",
  //  [keys.MIG_createWabaAccount]: "translation_missing",
  //  [keys.MIG_iHaveCreatedTheWabaACcount]: "translation_missing",
  //  [keys.MIG_youNeedToVerify]: "translation_missing",
  //  [keys.MIG_verifyOwnershipTitle]: "translation_missing",
  //  [keys.MIG_verifyNumber]: "translation_missing",
  //  [keys.MIG_continueWithEs]: "translation_missing",
  //  [keys.MIG_shareWabaWithUs1]: "translation_missing",
  //  [keys.MIG_shareWabaWithUs2]: "translation_missing",
  //  [keys.MIG_shareWabaWithUs3]: "translation_missing",
  //  [keys.MIG_shareWabaWithUs4]: "translation_missing",
  //  [keys.MIG_somethingWentWrongEs]: "translation_missing",
  //  [keys.MIG_disabledPlanInfo]: "translation_missing",
  // [keys.MIG_missingPlans]: "translation_missing",
};

export default translate;
