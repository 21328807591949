// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.AUTH_login]: "Log in",
  [keys.AUTH_forgot]: "Forgot your password?",
  [keys.AUTH_clickHere]: "Click here",
  [keys.AUTH_rightsReserved]: "©2021 All Rights Reserved.",
  [keys.AUTH_privacy]: "Privacy",
  [keys.AUTH_imprint]: "Imprint",
  [keys.AUTH_reset]: "Reset password",
  [keys.AUTH_resetHelptext]:
    "Enter the email address associated with your account and we will send an email with instructions to reset your password.",
  [keys.AUTH_resetButtonLabel]: "Send instructions",
  [keys.AUTH_resetError]:
    "Sorry, an error occurred during reset password proccess. Please try again in a few minutes.",
  [keys.AUTH_resetSuccess]:
    "Success! Instructions to reset your password were sent to your email address. Please wait few minutes if you haven't received an email yet.",
  [keys.AUTH_alreadyHaveAccount]: "Already have an account?",
  [keys.AUTH_signup]: "Sign up",
  [keys.AUTH_gethelp]: "Problems logging in?",
  [keys.AUTH_getHelpLink]: "Get Help",
  [keys.AUTH_clickAboveButtons]:
    "By clicking any of the above buttons to sign up you",
  [keys.AUTH_termsAndService]: "360dialog Terms of Service",
  [keys.AUTH_metaTerms]:
    "accept the <LINK>360dialog Terms of Service<LINK>, as well as",
  [keys.AUTH_forWhatsApp]: "the <LINK>Meta client terms<LINK> for WhatsApp.",
  [keys.AUTH_create]: "Create your account",
  [keys.AUTH_login360]: "Login to 360dialog",
  [keys.AUTH_email]: "Enter your email",
  [keys.AUTH_password]: "Enter your password",
  [keys.AUTH_loginEmail]: "Login with email",
  [keys.AUTH_continueEmail]: "Continue with email",
  [keys.AUTH_signupTerms]:
    "By signing up, you agree to the <LINK>Terms of Service<LINK>",
  [keys.AUTH_checkEmail]: "Check your email!",
  [keys.AUTH_create360Account]: "Create your 360dialog account",
  [keys.AUTH_otpTextOne]: "• To proceed, you first need to verify your email",
  [keys.AUTH_otpTextTwo]: "• A One Time Passcode has been sent to <EMAIL>",
  [keys.AUTH_otpTextThree]:
    "• Please enter the passcode below to verify your email address",
  [keys.AUTH_otp]: "One Time Passcode",
  [keys.AUTH_enterOtp]: "Enter the passcode here",
  [keys.AUTH_namePlaceholder]: "Enter your first and last name",
  [keys.AUTH_nameLabel]: "First and last name",
  [keys.AUTH_createAccountEmail]: "Create account with email",
  [keys.AUTH_otpWindowMessage]:
    "Please make sure to not close this window. Your data will not be saved until you submit the verification code.",
  [keys.AUTH_createAccount]: "Create account",
  [keys.AUTH_signupPriceList]: "and to the <LINK>360dialog's Price List<LINK>.",
  [keys.AUTH_redirectMessage]:
    "You have been redirected to the correct dashboard. Please login again to access your WhatsApp Account Management.",
  [keys.AUTH_visitNewHub]: "Visit new 360dialog Client Hub",
  [keys.AUTH_newHubInfoMessage]:
    "We have launched a new version of the 360dialog Client Hub. To access it you can from now on log in via app.360dialog.io. All your data is already there! So proceed to the new 360dialog Client Hub for a better user experience!",
  [keys.AUTH_governmentAgency]: "Government agency",
  [keys.AUTH_organizationIRepresent]: "The organization I represent is a...",
  [keys.AUTH_chooseFromList]: "choose from the list",
  [keys.AUTH_governmentalAgencyApproval]:
    "Due to Meta’s policies, Governmental Agency must get approval before creating WhatsApp Business accounts.<BR><LINK>Make a request<LINK>, a member of our team will get in touch.",
  [keys.AUTH_companyEmail]: "Company email",
  [keys.AUTH_termsOfServiceSignUp]:
    "By creating an account, you agree to our <LINK>terms of service<LINK>",
  [keys.AUTH_newAccount]: "New account",
  [keys.AUTH_dontHaveAccount]: "Don't have an account?",
  [keys.AUTH_signIn]: "Sign in",
  [keys.AUTH_resetHere]: "Reset here",
  [keys.AUTH_twoFactorAuth]: "Two-Factor Authentication",
  [keys.AUTH_OTPSent]: "We’ve sent an email with a code to <EMAIL>",
  [keys.AUTH_ResetPasswordMessage]:
    "Enter your email and we will send you a link.",
  [keys.AUTH_wantToCancel]: "Want to cancel?",
  [keys.AUTH_backToSignIn]: "Back to sign in",
  [keys.AUTH_emailSentTo]: "Email sent to:",
  [keys.AUTH_checkInbox]:
    "It includes all the instructions you need to follow to reset your password. Check your inbox.",
  [keys.AUTH_GoBackToSignIn]: "Go back to Sign in",
  [keys.AUTH_logOut]: "Log out",
  [keys.AUTH_min8Characters]: "Minimum 8 characters",
  [keys.AUTH_noFacebookEmail]:
    "Your sign in attempt via Meta was not successful. This is because there is no valid email address attached to your Meta account, which is required to authenticate with our system. <br/> Please add an email to your Meta account and try again or sign in with your email and a password below.",
  [keys.AUTH_logIn]: "Log in",
  [keys.AUTH_instructions]: "Instructions sent to your email.",
  [keys.AUTH_passwordNew]: "Password",
  [keys.AUTH_emailVerification]: "Email Verification",
  [keys.AUTH_FBLogin]: "Log in with Meta",
  [keys.AUTH_redirectMessageCTA]: "Click here to see what's new",
  [keys.AUTH_capsLock]: "Caps Lock is on",
  [keys.AUTH_passwordSuccess]: "Strong password",
  [keys.AUTH_passwordError]:
    "Must contain upper and smaller case characters, a number and a symbol",
  [keys.AUTH_otpTitle]: "We sent you an OTP code",
  [keys.AUTH_otpDescription]:
    "This is a protected action and we need to verify that you are you. We sent you an OTP code to your email.",
};

export default translate;
