// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.WF_title]: "Solicitud de cuenta de la WhatsApp Business API",
  [keys.WF_intro]:
    "Proporcione información sobre la empresa y el número de teléfono que le gustaría conectar a la WhatsApp Business API. Asegúrese de que su empresa siga la política de conformidad de Meta.",
  [keys.WF_compliancePolicyLinkLabel]: "política de conformidad",
  [keys.WF_personalInformation]: "Información personal",
  [keys.C_email]: "Email",
  [keys.WF_emailTooltip]:
    "El email será utilizado para comunicaciones y alertas sobre la WhatsApp Business API",
  [keys.C_fullName]: "Nombre completo",
  [keys.WF_businessDetails]: "Detalles de la empresa",
  [keys.WF_legalCompanyName]: "Nombre de la empresa",
  [keys.WF_fbBusinessManagerId]: "ID de Meta Business Manager",
  [keys.WF_FbIdTip_error]: "ID de Meta Business Manager erróneo",
  [keys.WF_FbIdTip_header]: "Encuentre su ID de Meta",
  [keys.WF_FBIDTip_text1]:
    "Su business ID corresponde con su Business Manager. Puede que necesite su business ID cuando comparta acceso a información como facturas mensuales o un Píxel de Meta.",
  [keys.WF_FBIDTip_text2]: "Pasos para encontrar su business ID:",
  [keys.WF_FBIDTip_step1]: "Vaya a Ajustes de su empresa",
  [keys.WF_FBIDTip_step2]: "Haga click en Informaciones de la empresa",
  [keys.WF_FBIDTip_step3]:
    "Bajo la información de Business Manager, verá su número de identificación.",
  [keys.WF_messaging]: "Mensajes",
  [keys.WF_whatsAppPhoneNumber]: "Número de teléfono de WhatsApp",
  [keys.WF_whatsAppPhoneNumberTooltip1]:
    "Número que será usado para la cuenta de WhatsApp Business API.",
  [keys.WF_whatsAppPhoneNumberTooltip2]:
    "Por favor, no incluya su número de teléfono personal.",
  [keys.WF_timezone]: "Franja horaria",
  [keys.WF_attention]: "Atención",
  [keys.WF_govAgencyText1]:
    "Si es una Agencia Gubernamental, no debe enviar una cuenta utilizando este formulario de envío. Primero, solicita la aprobación previa de Meta para su caso de uso. Si no tiene la aprobación del equipo de Meta para crear esta cuenta, o si no está seguro si es un caso del gobierno, lea <LINK>https://docs.360dialog.com/waba-for-government-agencies | documentación y rellene el formulario<LINK>. Haremos todo lo posible para ayudar",
  [keys.PI_perMonth]: "/ Mes",
  [keys.PI_text1]:
    "Los costes de conversación se cobran a partir del precio en EUR de Meta/WhatsApp tal como se indica en el <LINK>https://developers.facebook.com/docs/whatsapp/pricing | Reglas de precios de Meta<LINK> y pueden estar sujetos a prepago.",
  [keys.PI_text2]: "y puede estar sujeto a pre-pago.",
  [keys.PI_textUSD]:
    "Los costes de conversación se cobran a partir del precio en USD de Meta/WhatsApp tal como se indica en el <LINK>https://developers.facebook.com/docs/whatsapp/pricing | Reglas de precios de Meta<LINK> y pueden estar sujetos a prepago.",
  [keys.PI_billedMonthly]: "Facturado mensualmente",
  [keys.PI_completeOrderButtonLabel]: "Completar pedido",
  //  [keys.WF_termsIntro]: "translation_missing",
  //  [keys.WF_terms1]: "translation_missing",
  //  [keys.WF_terms1p1]: "translation_missing",
  //  [keys.WF_terms1p2]: "translation_missing",
  //  [keys.WF_terms2]: "translation_missing",
  //  [keys.WF_terms2p1p1]: "translation_missing",
  //  [keys.WF_terms2p1and]: "translation_missing",
  //  [keys.WF_terms2p1p2]: "translation_missing",
  //  [keys.WF_terms2p2]: "translation_missing",
  //  [keys.WF_terms2p3]: "translation_missing",
  //  [keys.WF_terms2p4]: "translation_missing",
  //  [keys.WF_terms3]: "translation_missing",
  //  [keys.WF_terms3p1]: "translation_missing",
  //  [keys.WF_terms3p2]: "translation_missing",
  //  [keys.WF_terms4]: "translation_missing",
  //  [keys.WF_terms4p1]: "translation_missing",
  //  [keys.WF_terms4p2]: "translation_missing",
  //  [keys.WF_terms4p3]: "translation_missing",
  //  [keys.WF_terms5]: "translation_missing",
  //  [keys.WF_terms5p1]: "translation_missing",
  //  [keys.WF_terms5p2]: "translation_missing",
  //  [keys.WF_terms5p3]: "translation_missing",
  //  [keys.WF_terms5p4]: "translation_missing",
  //  [keys.WF_terms5p5]: "translation_missing",
  //  [keys.WF_terms5p6]: "translation_missing",
  //  [keys.WF_terms5p7]: "translation_missing",
  //  [keys.WF_terms5p8]: "translation_missing",
  //  [keys.WF_termsLabel]: "translation_missing",
  //  [keys.WF_termsCheckbox1]: "translation_missing",
  //  [keys.WF_termsCheckbox1Detail]: "translation_missing",
  //  [keys.WF_termsCheckbox2]: "translation_missing",
  //  [keys.WF_termsCheckbox3]: "translation_missing",
  //  [keys.WF_verifiedBsp]: "translation_missing",
  //  [keys.WF_buttonLabelAccept]: "translation_missing",
  [keys.WF_buttonLabelSave]: "Guardar",
  [keys.WF_paymentInfo]: "Pago",
  [keys.WF_submission]: "Solicitud en progreso...",
  [keys.WF_thanksTitle]: "¡Gracias!",
  [keys.WF_thanksNext]:
    "Recibirá un email con instrucciones sobre cómo proceder con el proceso de verificación.",
  [keys.WF_thanksMob1]:
    "Mientras tanto, puede dirigirse al Meta Business Manager para aceptar nuestra",
  [keys.WF_thanksMobQuotes]: "messaging on behalf",
  [keys.WF_thanksMob2]: "solicitud",
  [keys.WF_thanksButtonLabel]: "Solicitudes abiertas",
  [keys.WF_0800error]:
    "Está intentando registrar un número que comienza con 0800. Por lo general, estos números no pueden recibir llamadas internacionales, lo cual es necesario para el proceso de registro. Si este es el caso, proporcione un número diferente.",
  [keys.WF_paymentCardOwnerName]: "Nombre en la tarjeta de crédito",
  [keys.WF_paymentCreditCard]: "Tarjeta de crédito",
  [keys.WF_paymentCardOwnerNamePlaceholder]:
    "Si el nombre es distinto al del solicitante",
  [keys.WF_hubAccess]: "Acceso al 360dialog Client Hub",
  [keys.WF_hubAccessHelp]:
    "Después de completar la solicitud, podrá acceder a nuestro 360dialog Client Hub, donde puede administrar su WhatsApp Business Account.",
  [keys.WF_hubAccessHelpPassword]:
    "Por favor, elija una contraseña, que será la que utilizará para acceder al 360dialog Client Hub.",
  [keys.WF_passwordLabel]: "Elija una contraseña",
  [keys.WF_repeatPasswordLabel]: "Repita la contraseña",
  [keys.WF_passwordError1]:
    "La contraseña debe contener al menos 8 caracteres.",
  [keys.WF_passwordError2]:
    "La contraseña debe contener al menos una letra minúscula (a-z).",
  [keys.WF_passwordError3]:
    "La contraseña debe contener al menos una letra mayúscula (A-Z).",
  [keys.WF_passwordError4]:
    "La contraseñra debe contener al menos un número (0-9) o un carácter especial (como !#$%&?).",
  [keys.WF_passwordRepeatError]: "Las contraseñas no corresponden.",
  [keys.WF_cardError]: "Por favor, proporcione una tarjeta de crédito válida.",
  [keys.WF_passwordErrorZxcvbn]:
    "Añada una o dos palabras más. Las palabras inusuales son mejores.",
  [keys.CON_title]: "Conecte su cuenta de Meta Business",
  [keys.CON_textIntro]:
    "Para continuar, deberá acceder a la cuenta de Meta Business de su empresa. Al hacer clic en el botón 'Continuar con Meta' a continuación, puede:",
  [keys.CON_bullet1]:
    "Cree cuentas de Meta y WhatsApp Business o seleccione cuentas existentes.",
  [keys.CON_bullet2]:
    "Proporcione un display name y una descripción para su perfil de WhatsApp Business.",
  [keys.CON_bullet3]:
    "Conecte y verifique el número de teléfono que utilizará para el acceso a la WhatsApp Business API.",
  [keys.CON_numberHelpText]:
    "No utilice un número que esté actualmente conectado a una cuenta de WhatsApp existente. Esto incluye los números que están conectados a la aplicación de WhatsApp Business. No podrá finalizar el proceso de registro.",
  [keys.CON_numberHelpLink]:
    "Saber más sobre su número de teléfono de WhatsApp",
  [keys.CON_buttonLabel]: "Continuar con Meta",
  [keys.CAN_title]: "Cómo podemos ayudar,",
  [keys.CAN_textIntro]:
    "Parece que no terminó el widget de registro de Meta. Pero no se preocupe: puede volver a empezar en cualquier momento e intentarlo de nuevo. Estamos aquí para ayudar durante el proceso.",
  [keys.CAN_faq]: "Preguntas frecuentes",
  [keys.CAN_buttonHelp]:
    "¿Listo para intentarlo de nuevo? Haga click aquí para reiniciar el widget de registro.",
  [keys.WF_partnerIntro]:
    "Usted se está registrando como cliente de <PARTNER>.",
  [keys.WF_userAlreadyExists]:
    "Ya existe un usuario con ese correo electrónico. Para continuar con su registro o agregar un nuevo número a su cuenta de WhatsApp Business API, inicie sesión en el 360dialog Client Hub. ",
  [keys.CAN_livesupportTitle]: "Obtener soporte individual",
  [keys.CAN_livesupportText]:
    "Haga click en el botón de la derecha para programar una llamada con uno de nuestros agentes de soporte, que le ayudará a navegar el proceso de registro.",
  [keys.CAN_livesupportButtonLabel]: "Programe una llamada",
  [keys.CAN_livesupportButtonLabelSuccess]: "Llamada programada",
  [keys.PI_selectedLabel]: "seleccionado",
  [keys.SH_copied]: "Copiado",
  [keys.SH_copyPageLink]: "Copiar link de la página",
  [keys.SH_pageLink]: "Link de la página",
  [keys.PI_pricingPlanLinkLabel]:
    "Haga click aquí para saber más sobre nuestros planes de precios.",
  [keys.WF_alreadyHaveAccount]: "¿Ya tiene una cuenta?",
  [keys.WF_login]: "Acceder",
  [keys.WF_mobileHeading]: "Lo sentimos",
  [keys.WF_notSupportedMobile]:
    "Desgraciadamente, el proceso de Embedded Signup no está disponible para dispositivos móviles. Intente de nuevo con una computadora de escritorio.",
  [keys.WF_saveURL]: "Guardar URL actual",
  [keys.WF_continueAnyway]: "Continúe al signup clásico",
  [keys.WF_sharedSuccessfully]: "La URL fue compartida con éxito",
  [keys.WF_copiedUrl]: "URL copiada al portapapeles",
  [keys.CC_consentText]:
    "Esta página web usa cookies para mejorar la experiencia del usuario.",
  [keys.CC_acceptButtonLabel]: "Aceptar",
  [keys.CC_declineButtonLabel]: "Rechazar",
  [keys.CC_title]: "Nuestra página web utiliza cookies",
  [keys.CC_text]:
    "Nos gustaría utilizar cookies propias y de terceros y tecnologías similares para mejorar la experiencia de usuario y para fines de marketing. Puede establecer sus preferencias seleccionando las opciones siguientes.",
  [keys.CC_cookies1text]:
    "<cookies1label> ayudan con la funcionalidad básica de nuestra página web, p. ej. recordar si dio su consentimiento a las cookies.",
  [keys.CC_cookies1label]: "Cookies necesarias",
  [keys.CC_cookies2text]:
    "<cookies2label> permiten recopilar estadísticas sobre cómo utiliza nuestra página web, para que podamos mejorarla.",
  [keys.CC_cookies2label]: "Cookies analíticas",
  [keys.CC_cookies3text]:
    "<cookies3label> permiten mostrarle contenido y anuncios más relevantes en nuestra página web y otras plataformas.",
  [keys.CC_cookies3label]: "Cookies de marketing",
  [keys.CC_buttonLabelAcceptSelected]: "Aceptar las seleccionadas",
  [keys.CC_buttonLabelAcceptNecessary]: "Aceptar las necesarias",
  [keys.CC_buttonLabelAcceptAll]: "Aceptar todas",
  [keys.WF_currentBussinessSolutionsProvider]:
    "Business Solution Provider corriente",
  [keys.MIG_fbErrorWaitNewCode]:
    "Es necesario esperar para reenviar el código. Por favor, aguarde <HOUR> e <MINUTES> antes de intentar nuevamente.",
  [keys.CH_portExistingNumber]:
    "Migrar número existente en otro BSP para 360dialog",
  [keys.CH_portWhatsAppProfile]:
    "Migrar un perfil WhatsApp Business existente desde cualquier proveedor para la 360dialog.",
  [keys.CH_addNewNumber]: "Adicionar un número nuevo de teléfono",
  [keys.CH_setUpWhatsAppProfile]:
    "Configurar su cuenta y perfil WhatsApp Business con un número que todavía no esté en uso.",
  [keys.CH_welcomeTo360dialog]: "¡Bienvenido a la 360dialog!",
  [keys.CH_pleaseSelect]:
    "Por favor, seleccione si quiere migrar un número existente para la 360dialog o si quiere enviar un número nuevo para su cuenta WhatsApp Business.",
  [keys.CH_continueWithAccount]: "Seguir con la creación de la cuenta",
  [keys.CAN_createTicket]: "Crear un ticket para soporte",
  [keys.CAN_ticketCreated]: "Ticket creado",
  [keys.CON_contactSupportHelptext]:
    "¿Problemas para completar la creación de la cuenta?",
  [keys.WF_goToLogin]: "Ir para login",
  [keys.WF_planSelection]: "Selección de plan",
  [keys.WF_monthly]: "Mensual",
  [keys.WF_yearly]: "Anual",
  [keys.WF_month]: "Mes",
  [keys.WF_year]: "Año",
  [keys.WF_billedMonthly]: "Facturación mensual",
  [keys.WF_months]: "meses",
  [keys.WF_billedAt]: "Facturado el",
  [keys.WF_selected]: "Seleccionado",
  [keys.WF_select]: "Seleccionar",
  [keys.WF_basic]: "Básico",
  [keys.WF_professional]: "Profesional",
  [keys.WF_forDevelopers]: "Favorito para desarrolladores",
  [keys.WF_scalablePlan]: "Nuestro plan más escalable",
  [keys.WF_buttonLabelSubmit]: "Enviar datos",
  [keys.WF_buttonLabelCancel]: "Atrás",
  [keys.WF_reviewData]: "Revisar sus datos",
  [keys.WF_passwordError5]:
    "La contraseña no debe contener información sobre el correo electrónico",
  [keys.CC_openCookiePreferences]: "Abrir ajustes de cookies",
  [keys.CC_acceptCardLabel]:
    'Para acceder al live chat de soporte, acepte las "Cookies analíticas".',
  [keys.WF_freemailError]:
    'No es posible registrar una cuenta utilizando el dominio de correo electrónico "<DOMINIO>". Por favor, utilice la dirección de correo electrónico de su empresa para registrar su cuenta.',
  [keys.WF_termsIntro]:
    'Al realizar este "Pedido", el Cliente solicita utilizar los servicios de WhatsApp Business Solution (en adelante, los "Servicios") proporcionados por 360dialog GmbH, Torstr. 61, 10119 Berlín, Alemania, que actúa como "Proveedor de soluciones" autorizado (en adelante, "360dialog" o "BSP"). Los "Servicios" se refieren a todos los servicios proporcionados por 360dialog en relación con la configuración, integración y administración continua de las WhatsApp Business Accounts "WABA(s)". Al realizar este Pedido, el Cliente autoriza a 360dialog a solicitar WABA(s) a WhatsApp (como se define a continuación) y a administrar los WABA(s) en nombre del Cliente. Esto incluirá la transmisión de información entre WhatsApp y el Cliente a los efectos de este Acuerdo.',
  [keys.WF_terms1]: "WABA MSISDNs",
  [keys.WF_terms1p1]:
    "Por la presente, el cliente solicita WABAs para el siguiente MSISDN (incluido el código de país en el formato +49):",
  [keys.WF_terms1p2]:
    'La aprobación de cada WABA es responsabilidad de WhatsApp. En este documento, "WhatsApp" se refiere a WhatsApp Inc. y/o cualquiera de sus partes afiliadas dentro del grupo de Meta que proporcione servicios que formen parte de WhatsApp Business Soltion.',
  [keys.WF_terms2]: "Cumplimiento de los términos de WhatsApp",
  [keys.WF_terms2p1p1]:
    "Por la presente, el Cliente garantiza y declara que utilizará los WABAs estrictamente de acuerdo con los Términos de WhatsApp Business Solution https://www.whatsapp.com/legal/business-solution-terms, los Términos del cliente de Meta para WhatsApp que se adjuntan como Anexo 2, como así como todos los demás términos, pautas y políticas aplicables de WhatsApp, lo más importante",
  [keys.WF_terms2p1and]: "y",
  [keys.WF_terms2p1p2]: 'en conjunto los "Términos de WhatsApp".',
  [keys.WF_terms2p2]:
    "El Cliente reconoce y acepta que WhatsApp puede modificar los Términos de WhatsApp y/o publicar términos, pautas y políticas adicionales y, una vez publicados, dichos términos, pautas y políticas adicionales formarán parte de los Términos de WhatsApp.",
  [keys.WF_terms2p3]:
    "El cliente indemnizará a 360dialog por cualquier daño o costo causado por el incumplimiento de los Términos de WhatsApp.",
  [keys.WF_terms2p4]:
    "Las WABAs son para uso exclusivo del Cliente. El Cliente declara y garantiza que no distribuirá, venderá, revenderá, alquilará ni otorgará acceso a los WABA a ningún tercero para ningún propósito fuera del alcance de esta Pedido.",
  [keys.WF_terms3]: "Uso de los Servicios",
  [keys.WF_terms3p1]:
    '360dialog proporciona los Servicios (según se define anteriormente) al Cliente. El uso de los Servicios está sujeto a los Términos de uso de 360dialog adjuntos como Anexo 1. Este Pedido y los Términos de 360dialog se denominarán el "Acuerdo".',
  [keys.WF_terms3p2]:
    "De acuerdo con los Términos de WhatsApp, los Clientes tienen derecho a migrar sus WABA a un Proveedor de soluciones comerciales de WABA diferente. Cualquier esfuerzo requerido por 360dialog en relación con dicha solicitud está sujeto a la Lista de precios de 360dialog.",
  [keys.WF_terms4]: "Diversas",
  [keys.WF_terms4p1]:
    "Este Acuerdo se regirá e interpretará de conformidad con las leyes de la República Federal de Alemania, con exclusión de la Convención de las Naciones Unidas sobre Contratos para la Venta Internacional de Mercaderías y las normas sobre conflictos de leyes.",
  [keys.WF_terms4p2]:
    "El lugar de actuación y sede es Berlín, Alemania. No obstante, 360dialog se reserva el derecho a cualquier otra vía legalmente admisible.",
  [keys.WF_terms4p3]:
    "Si un tribunal de jurisdicción competente considera que alguna disposición de este Acuerdo es contraria a las leyes aplicables, entonces las disposiciones restantes de este Acuerdo, si pueden cumplirse sustancialmente, permanecerán en pleno vigor y efecto. La cláusula afectada será reemplazada por una que refleje lo más posible la intención original de las partes.",
  [keys.WF_terms5]: "Términos y precios de 360dialog",
  [keys.WF_terms5p1]:
    "La tarifa de licencia mensual para alojar la API de WhatsApp Business es de 50,00 USD/Número/Mes.",
  [keys.WF_terms5p2]:
    "Los términos de la tarifa mensual del Licenciatario son de mes a mes y pueden rescindirse con un aviso de 30 días.",
  [keys.WF_terms5p3]:
    "Las tarifas de licencia mensuales para el alojamiento de API de WhatsApp Business se facturarán según el mes calendario.",
  [keys.WF_terms5p4]:
    "360dialog factura al cliente solo por la API de WhatsApp Business después de la implementación de la pila de alojamiento del cliente.",
  [keys.WF_terms5p5]:
    "Si un alojamiento solo comienza en una fecha que no sea el día 1 de un mes calendario, se facturará solo a prorrata según los días en que se activó el portador.",
  [keys.WF_terms5p6]:
    "Todas las tarifas de licencia se facturan al comienzo del mes correspondiente.",
  [keys.WF_terms5p7]:
    "Precios de los mensajes de plantilla de WhatsApp Los costes de los mensajes de plantilla no están incluidos en la tarifa de licencia mensual y se cobran a partir de los precios de Meta/Whatsapp en EUR, tal como se indica en https://developers.facebook.com/docs/whatsapp/pricing/",
  [keys.WF_terms5p8]: "Todas las tarifas son sin IVA, si corresponde.",
  [keys.WF_termsLabel]: "Términos",
  [keys.WF_termsCheckbox1]: "Acepto el Anexo 1 - Términos de uso de 360dialog.",
  [keys.WF_termsCheckbox1Detail]: "Última modificación:",
  [keys.WF_termsCheckbox2]:
    "Reconozco y acepto el Anexo 2: Términos de Cliente de Meta para WhatsApp.",
  [keys.WF_termsCheckbox3]: "Acepto el Anexo 3 - Precios de 360dialog.",
  [keys.WF_verifiedBsp]:
    "360dialog es un proveedor de soluciones de WhatsApp verificado y Meta/WhatsApp le ha otorgado el derecho de facilitar a sus clientes el acceso a la WhatsApp Business API.",
  [keys.WF_buttonLabelAccept]: "Aceptar términos",
  [keys.CON_sessionExp]:
    'Su sesión con Meta ha expirado. Haz click en "Continuar con Meta" para repetir el proceso.',
  [keys.VER_checkEmailTitle]: "Comprueba tu email",
  [keys.VER_submit]: "Enviar",
  [keys.VER_bullet1]: "Para continuar, debes verificar tu email.",
  [keys.VER_bullet2]: "Se ha enviado el código de un solo uso a <EMAIL>.",
  [keys.VER_bullet3]:
    "Por favor, introduce el código a continuación para verificar tu cuenta de email.",
  [keys.VER_inputLabel]: "Código de un solo uso",
  [keys.VER_inputPlaceholder]: "Introduce aquí el código",
  [keys.VER_inputError]: "El código facilitado no es correcto.",
  [keys.VER_closeWindowWarning]:
    "Por favor, asegúrese de no cerrar esta ventana. Sus datos no se guardarán hasta que envíe el código de verificación.",
  [keys.VER_newCodeSentToEmail]: "Se le ha enviado un código nuevo por email",
  [keys.AI_almostDone]: "Ya casi has terminado, <USERNAME>",
  [keys.AI_addInfo]: "Agregar información aidcional de la empresa.",
  [keys.AI_completeInfo]: "Completar información ",
  [keys.AI_companyInfo]:
    "Para continuar, necesitamos algunos datos sobre su empresa.",
  [keys.AI_companyNameLabel]: "Nombre legal de la empresa",
  [keys.AI_companyNamePlaceholder]: "Ingrese el nombre de la empresa legal",
  [keys.AI_companyAddressLabel]: "Dirección de la empresa",
  [keys.AI_companyAddressPlaceholder]: "Ingrese la dirección de la empresa",
  [keys.AI_selectCountry]: "Seleccione un país",
  [keys.AI_vatIdLabel]: "VAT ID",
  [keys.AI_vatIdPlaceholder]: "Introduzca el VAT ID",
  [keys.AI_companyVertical]: "Vertical de la empresa",
  [keys.FS_accessCompanyFBM]:
    "Para continuar, deberá <strong>acceder al Meta Business Manager de su empresa</strong> y hacer lo siguiente:",
  [keys.FS_connectNumber]:
    "Conecte y verifique su número de teléfono para usar la API de Whatsapp Business",
  [keys.FS_addInfoToBusinessManager]:
    "Para tener una cuenta activa, debe agregar su información en la sección Información Comercial de Configuración del Business Manager.",
  [keys.FS_fbCommercePolicy]:
    "Meta utiliza esta información para comprobar el cumplimiento de la Política de comercio. Si no está configurado correctamente, la cuenta se bloqueará inmediatamente.",
  [keys.FS_embeddedSignupInfo]:
    "<LINK>https://docs.360dialog.com/docs/embedded-signup | Más información<LINK>",
  [keys.WF_fullName]: "Ingrese su nombre completo",
  [keys.WF_noAccount]: "No tienes cuenta todavia?",
  [keys.WF_signUpHere]: "Regístrate aquí",
  [keys.WF_loginHere]: "Inicia sesión aquí",
  [keys.WF_passwordError6]:
    "La contraseña no debe contener información sobre el usuario",
  [keys.WF_comapnyNameTooltip]:
    "Ingresa el nombre legal de tu empresa. Tu nombre de perfil de WhatsApp se puede elegir más tarde y puede diferir si se aplican las condiciones",
  [keys.WF_vatIdTooltip]:
    "El VAT ID (Número de identificación fiscal intracomunitario) es obligatorio para empresas con sede en la UE. Indica tu VAT ID (con prefijo incluido). Ejemplo - ES25084476",
  [keys.WF_vatIdNotValid]: "El VAT ID no es válido",
  [keys.WF_noVatId]: "Mi empresa no tiene VAT ID",
  [keys.WF_noVATMessage]:
    "Si tu empresa no tiene un número de identificación fiscal intracomunitario o no es válido, te cobraremos el  IVA alemán.",
  [keys.WF_vatInfoUpdated]: "Información de empresa e IVA actualizada",
  [keys.WF_governmentalCheck]: "Governmental check",
  [keys.WF_notGovernmental]: "No soy una agencia gubernamental",
  [keys.WF_facebookBusinessSetup]: "Configuración de Meta Business",
  [keys.WF_ukraineExcludedAreasNote]:
    "La empresa no forma parte de las regiones de Crimea, Donetsk y Lugansk.",
  //  [keys.FS_comeBackFromMeta]: "translation_missing",
  //  [keys.FS_completeMataOnboarding]: "translation_missing",
  //  [keys.FS_completeMataOnboardingInfo]: "translation_missing",
  //  [keys.CC_openCookiePreferences]: "translation_missing",
  //  [keys.CC_disabledCookiePreferences]: "translation_missing",
  // [keys.ON_paymentDebitNotice]: "translation_missing",
};

export default translate;
