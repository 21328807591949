// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.SIGNALS_trackYourCtwa]:
    "Track your click to WhatsApp ads, measure their success and receive insights on how to reach your users most efficiently!",
  [keys.SIGNALS_enableTool]:
    "Enable this tool and allow our services to analyze the content of messages to provide you with detailed insights.",
  [keys.SIGNALS_enableSignalsPage]: "Enable Signals page",
  [keys.SIGNALS_learnMore]: "Learn more",
  [keys.SIGNALS_oneStepAway]: "One step away!",
  [keys.SIGNALS_inOrderToUseFeature]:
    "In order to use this feature and start viewing all the insights it provides, you first have to connect a Meta Account.",
  [keys.SIGNALS_connectFacebookAccount]: "Connect Meta Account",
  [keys.SIGNALS_step]: "STEP",
  [keys.SIGNALS_soundsGood]: "Sounds good?",
};

export default translate;
