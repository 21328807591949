import { AxiosError } from "axios";
import { observable } from "mobx";
import qs from "qs";
import { toJS } from "mobx";

import { ServerResponseMeta, UIResponseMeta } from "@/services/api/commonTypes";

export const extractErrorData = (error: AxiosError) => {
  if (error.response) {
    return error.response.data;
  } else if (error.request) {
    return error.request;
  } else {
    return error.message;
  }
};

export interface IObservableMobx<model, request> {
  data: model | null;
  error: any;
  request: request;
  reset?: () => void;
}

export class UIModelResponseMeta {
  @observable meta?: {
    developerMessage?: string;
    httpCode?: number;
    success?: boolean;
    [k: string]: any;
  };

  constructor(response: UIResponseMeta) {
    if (response.meta) {
      const {
        developerMessage = "",
        httpCode = 0,
        success = false,
        ...props
      } = response.meta;

      return {
        meta: {
          developerMessage,
          httpCode,
          success,
          ...props,
        },
      };
    }

    return {};
  }
}

export const unpackMetaResponse = (
  response: ServerResponseMeta
): UIResponseMeta => {
  if (response.meta) {
    const {
      developer_message: developerMessage = "",
      http_code: httpCode = 0,
      success = false,
      ...props
    } = response.meta;

    return {
      meta: {
        developerMessage,
        httpCode,
        success,
        ...props,
      },
    };
  }

  return {};
};

export interface QueryParamsFilters {
  [k: string]:
    | {
        eq?: string;
        like?: string;
        gte?: string;
      }
    | string
    | string[]
    | null;
}

export interface ParamsFilters {
  [k: string]: {
    eq?: string;
    like?: string;
  };
}

export interface QueryParamsFiltersQ {
  q?: string;
}

export interface IQueryParams {
  offset: number;
  limit: number;
  sort: string[];
  filters: QueryParamsFilters | QueryParamsFiltersQ;
}

function camelToSnake(string: string) {
  return string
    .replace(/[\w]([A-Z])/g, function (m) {
      return m[0] + "_" + m[1];
    })
    .toLowerCase();
}

export const packQueryParams = (queryParams: IQueryParams) => ({
  offset: queryParams.offset,
  limit: queryParams.limit,
  sort: queryParams.sort.map((sortElement) => camelToSnake(sortElement)),
  filters: queryParams.filters,
});

export const stringifyQueryParams = (params: any) => {
  const { filters, ...otherParams } = params;
  const serializedQueryString = qs.stringify(otherParams, {
    arrayFormat: "repeat",
  });

  return `${serializedQueryString}&filters=${JSON.stringify(toJS(filters))}`;
};

export const currencySymbol = (currency?: string) => {
  switch (currency?.toUpperCase()) {
    case "EUR":
      return "€";
    case "USD":
      return "$";
    case "GBP":
      return "£";
    default:
      return "";
  }
};
