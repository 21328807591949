// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.AUTH_login]: "Login",
  [keys.AUTH_forgot]: "Esqueceu sua senha?",
  [keys.AUTH_clickHere]: "Clique aqui",
  [keys.AUTH_rightsReserved]: "©2021 Todos os direitos reservados.",
  [keys.AUTH_privacy]: "Privacidade",
  [keys.AUTH_imprint]: "Imprint",
  [keys.AUTH_reset]: "Criar uma nova senha",
  [keys.AUTH_resetHelptext]:
    "Digite o endereço de e-mail associado à sua conta e enviaremos um e-mail com instruções para redefinir a sua senha.",
  [keys.AUTH_resetButtonLabel]: "Enviar orientações",
  [keys.AUTH_resetError]:
    "Desculpe, ocorreu um erro durante o processo de redefinição da senha. Por favor, tente novamente dentro de alguns minutos.",
  [keys.AUTH_resetSuccess]:
    "Pronto! As orientações para redefinição de senha foram enviadas para seu endereço de e-mail. Aguarde alguns minutos se você ainda não recebeu um e-mail.",
  [keys.AUTH_alreadyHaveAccount]: "Já tem uma conta?",
  [keys.AUTH_signup]: "Registrar",
  [keys.AUTH_gethelp]: "Problemas ao fazer login?",
  [keys.AUTH_getHelpLink]: "Peça por ajuda",
  [keys.AUTH_clickAboveButtons]:
    "Clicando em qualquer um dos botões acima para cadastrá-lo",
  [keys.AUTH_termsAndService]: "Termos de Serviço da 360dialog",
  [keys.AUTH_metaTerms]:
    "aceite os Termos de Serviço <LINK>360dialog Terms of Service<LINK>, assim como",
  [keys.AUTH_forWhatsApp]:
    "os termos <LINK>Meta client terms<LINK> para WhatsApp.",
  [keys.AUTH_create]: "Crie sua conta",
  [keys.AUTH_login360]: "Login no 360dialog",
  [keys.AUTH_email]: "Digite seu e-mail",
  [keys.AUTH_password]: "Senha",
  [keys.AUTH_loginEmail]: "Login com e-mail",
  [keys.AUTH_continueEmail]: "Continuar com o e-mail",
  [keys.AUTH_signupTerms]:
    "Ao se inscrever, você concorda com o <LINK>Terms of Service<LINK>",
  [keys.AUTH_checkEmail]: "Verifique seu e-mail!",
  [keys.AUTH_create360Account]: "Criar sua conta 360dialog",
  [keys.AUTH_otpTextOne]:
    "• Para prosseguir, você precisa primeiro verificar seu e-mail",
  [keys.AUTH_otpTextTwo]: "• Uma senha única foi enviada para <EMAIL>",
  [keys.AUTH_otpTextThree]:
    "• Please enter the passcode below to verify your email address",
  [keys.AUTH_otp]: "Senha Única",
  [keys.AUTH_enterOtp]: "Digite o código de acesso aqui",
  [keys.AUTH_namePlaceholder]: "Digite seu nome e sobrenome",
  [keys.AUTH_nameLabel]: "Nome e sobrenome",
  [keys.AUTH_createAccountEmail]: "Criar conta com e-mail",
  [keys.AUTH_otpWindowMessage]:
    "Por favor, certifique-se de não fechar esta janela. Seus dados não serão salvos até que você envie o código de verificação.",
  [keys.AUTH_createAccount]: "Criar conta",
  [keys.AUTH_signupPriceList]: "e para <LINK> a Lista de Preços 360dialog",
  [keys.AUTH_visitNewHub]: "Visite o novo Client Hub da 360dialog",
  [keys.AUTH_newHubInfoMessage]:
    "Lançamos uma nova versão do 360dialog Client Hub. Para acessá-lo, você pode, a partir de agora, fazer login em app.360dialog.io. Todos os seus dados já estão lá! Portanto, prossiga para o novo 360dialog Client Hub para ter uma melhor experiência de usuário!",
  [keys.AUTH_governmentAgency]: "Agência governamental",
  [keys.AUTH_organizationIRepresent]: "A organização que represento é uma…",
  [keys.AUTH_chooseFromList]: "Escolha da lista",
  //  [keys.AUTH_governmentalAgencyApproval]: "translation_missing",
  [keys.AUTH_companyEmail]: "E-mail da empresa",
  [keys.AUTH_termsOfServiceSignUp]:
    "Criando a conta você confirma que concorda com nossos <LINK>termos de serviço<LINK>",
  [keys.AUTH_newAccount]: "Nova conta",
  [keys.AUTH_dontHaveAccount]: "Não tem uma conta?",
  //  [keys.AUTH_signIn]: "translation_missing",
  [keys.AUTH_resetHere]: "Redefinir a senha",
  //  [keys.AUTH_twoFactorAuth]: "translation_missing",
  //  [keys.AUTH_OTPSent]: "translation_missing",
  //  [keys.AUTH_ResetPasswordMessage]: "translation_missing",
  //  [keys.AUTH_wantToCancel]: "translation_missing",
  //  [keys.AUTH_backToSignIn]: "translation_missing",
  //  [keys.AUTH_emailSentTo]: "translation_missing",
  //  [keys.AUTH_checkInbox]: "translation_missing",
  //  [keys.AUTH_GoBackToSignIn]: "translation_missing",
  //  [keys.AUTH_logOut]: "translation_missing",
  [keys.AUTH_min8Characters]: "Mínimo 8 caracteres",
  //  [keys.AUTH_noFacebookEmail]: "translation_missing",
  [keys.AUTH_logIn]: "Entrar",
  //  [keys.AUTH_instructions]: "translation_missing",
  [keys.AUTH_passwordNew]: "Senha",
  //  [keys.AUTH_emailVerification]: "translation_missing",
  [keys.AUTH_FBLogin]: "Entrar com Facebook",
  //  [keys.AUTH_passwordSuccess]: "translation_missing",
  //  [keys.AUTH_passwordError]: "translation_missing",
  //  [keys.AUTH_otpTitle]: "translation_missing",
  //  [keys.AUTH_otpDescription]: "translation_missing",
};

export default translate;
