// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.FAQ_q_1]:
    "¿Qué es la verificación de Meta Business y por qué necesito completarla?",
  //  [keys.FAQ_a_1]: "translation_missing",
  [keys.FAQ_q_2]:
    "¿Qué documentos necesito para la verificación de Meta Business?",
  [keys.FAQ_a_2]:
    "En nuestros <DOCS> proporcionados una lista de todos los documentos requeridos para la verificación de Meta Business.",
  [keys.FAQ_q_3]: "¿Puedo continuar utilizando la app de WhatsApp Business?",
  [keys.FAQ_a_3]:
    "Una vez que haya completado el proceso de registro con 360dialog, crearemos una cuenta de WhatsApp Business API para el número elegido y no podrá revertir a la aplicación de WhatsApp Business.",
  [keys.FAQ_q_4]: "No recibí el SMS con el código de verificación. ¿Por qué?",
  [keys.FAQ_a_4]:
    "Para comenzar, verifique si ingresó el número de teléfono correcto. Si sigue sin recibir la clave, podría ser que su número ya está conectado a un perfil privado de WhatsApp o a la aplicación de WhatsApp Business.",
  [keys.FAQ_q_5]: "No veo la opción para crear mi API key. ¿Dónde está?",
  [keys.FAQ_a_5]:
    "Si su integración está funcionando, siempre debería poder crear una API key. Si no puede encontrar el botón en el localizador de WhatsApp Accounts o en la página de detalles del número, existe la posibilidad de que su integración aún no esté completamente configurada.",
  [keys.FAQ_q_6]:
    "¿Qué es el reenvío de mensajes al partner y por qué tengo que activarlo?",
  [keys.FAQ_a_6]:
    "Si ve la opción de reenviar sus mensajes a su partner, lo más probable es que haya registrado un producto con una empresa partner, que necesita recibir sus mensajes para funcionar según lo previsto. Solo usted puede otorgar permiso al partner para recibir estos mensajes habilitando el reenvío de mensajes.",
  //  [keys.FAQ_q_7]: "translation_missing",
  [keys.FAQ_a_7]:
    "Si ha registrado un producto con un partnet de integración, ya no se requiere una API. Lo único que debe hacer es habilitar el reenvío de sus mensajes a su partner. Si está creando una aplicación por su cuenta y, por lo tanto, necesita crear una API key, comuníquese con nuestro equipo de soporte.",
};

export default translate;
