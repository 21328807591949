// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.ERR_templateRenameExists]:
    "Vorlage kann nicht umbenannt werden. Eine Vorlage mit diesem Namen existiert bereits. Bitte geben Sie einen anderen Namen an.",
  [keys.ERR_noStripeCustomer]:
    "Zahlungsoptionen konnten nicht geöffnet werden. Dein Bezahlkonto wurde noch nicht vollständig erstellt. Bitte kontaktiere unseren Support.",
  [keys.ERR_weAreSorry]: "Es tut uns leid",
  [keys.ERR_internalIssuesTeamNotified]:
    "Es sieht so aus, als hätten wir ein paar interne Probleme. Unser Team wurde benachrichtigt. Wenn du uns helfen möchtest, kannst du weiter unten beschreiben, was passiert ist.",
  [keys.ERR_reloadPage]: "Seite neu laden",
  [keys.ERR_letUsKnowWhatHappened]: "Bitte beschriebe, was genau passiert ist",
  [keys.ERR_failedToGetCountry]: "Abfrage des Landes fehlgeschlagen",
  [keys.ERR_errorLoadingPaymentNotification]:
    "Fehler beim Laden der PaymentNotification",
  [keys.ERR_userNotFound]:
    "Wir konnten keinen Benutzer mit dieser E-Mail-Adresse finden. Bitte versuche es erneut. Wenn du dich für ein neues Konto anmelden möchtest, wende dich an deinen Integrationspartner oder besuche 360dialog.com",
  [keys.ERR_partnerNotPermitted]:
    "Es tut uns leid, der angegebene Partner darf die eingebettete Anmeldung nicht verwenden. Bitte kontaktiere uns",
  [keys.ERR_inputFields]: "Fehler in Eingabefeldern",
  [keys.ERR_fbConnectionError]:
    "Beim Versuch, dein Meta-Konto zu verbinden, ist ein Fehler aufgetreten.",
  [keys.ERR_fbNoRegisteredEmail]:
    "In deinem Konto ist keine E-Mail-Adresse registriert. Bitte füge eine zu deinem Meta-Konto hinzu.",
  [keys.ERR_userNoExists]:
    "Dieser Benutzer existiert nicht. Bitte melde dich über die App deines Partners oder über 360dialog.com an.",
  [keys.ERR_addFundsNumber]:
    "Kann kein Guthaben zu fehlerhaften Nummern hinzufügen",
  [keys.ERR_autoRenewalNumber]:
    "Einstellungen für die automatische Aufladung von fehlerhaften Nummern können nicht bearbeitet werden",
  [keys.ERR_stripeErrorNote]:
    "Beim Laden der Stripe-Skripte ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen Sie es erneut.",
  [keys.ERR_filterOptionsErrorNote]:
    "Beim Laden der Sprach- und Zeitzonenfilteroptionen ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen Sie es noch einmal.",
  [keys.ERR_publicDataErrorText]:
    "Die Seite konnte nicht korrekt geladen werden. Bitte versuche die Seite neu zu laden. {{break}} Sollte das Problem weiterhin bestehen, schaue bitte auf unsere Statusseite {{statusPageLink}}, ob es aktuelle Störungen gibt oder kontaktiere unseren Support.",
  [keys.ERR_somethingUnexpected]:
    "Es ist etwas Unerwartetes passiert, bitte versuche es später noch einmal",
  [keys.ERR_phoneRegistrationError]:
    "Der Verifizierungscode konnte während der Abschlussphase der Registrierung nicht gesetzt werden. Stelle sicher, dass der Code gültig ist und versuche es erneut.",
  [keys.ERR_userWithNoClientPermissionError]:
    "Der Vorgang konnte nicht abgeschlossen werden. Dein Benutzerkonto hat nicht die Berechtigung, um im Namen dieses Kundenkontos zu handeln. Überprüfe bitte, ob du die richtige URL aufrufst und die richtige Kunden-ID angibst, die zu deinem Benutzer gehört. Im Zweifelsfall melde dich ab und melde dich in einem privaten Browserfenster wieder an.",
  // [keys.ERR_somethingWentWrong]: "translation_missing"
};

export default translate;
