import * as Types from "./jiraTicket.types";
import { IQueryParams } from "@/services/api/utils";

export const packUpdateBilling = (
  request: Types.IUpdateBillingPayload
): Types.IServerRequestCreateTicket => ({
  summary: `Number billing update - ${
    request.type === "revoke"
      ? "Payment revocation requested"
      : "Termination requested"
  } (${request.number})`,
  description: `Number billing update 
  - ${
    request.type === "revoke"
      ? "Payment revocation requested (by PARTNER)"
      : "Termination requested (by CLIENT)"
  },
  - number: ${request.number},
  - cancelled at: ${
    request.cancelledAt
      ? `${new Date(request.cancelledAt).toISOString().split(".")[0]}Z`
      : "undefined"
  },
  - terminated at: ${
    request.terminatedAt
      ? `${new Date(request.terminatedAt).toISOString().split(".")[0]}Z`
      : "undefined"
  }
  - channel URL: ${request.channelURL}
  `,
});

export const packJiraTicket = (
  request: Types.IUIRequestCreateTicket
): Types.IServerRequestCreateTicket => ({
  summary: request.summary,
  description: request.description,
  request_type: request.requestType,
  is_premium: request.isPremium,
  channel_tier: request.channelTier,
});

export const packJiraTickets = (queryParams: IQueryParams) => ({
  maxResults: queryParams.limit,
  startAt: queryParams.offset,
  sort: queryParams.sort,
});

export const unpackTickets = (response: Types.IUIResponseTickets) => ({
  expand: response.expand,
  issues: response.issues,
  startAt: response.startAt,
  total: response.total,
  maxResults: response.maxResults,
});

export const packTicketComment = (
  request: Types.IUIRequestAddComment
): Types.IServerRequestAddComment => ({
  issueIdOrKey: request.issueIdOrKey,
  commentBody: request.commentBody,
});

export const unpackJiraCreateTicket = (
  request: Types.IUIServerResponseCreateTicket
): Types.IServerResponseCreateTicket => {
  return {
    meta: {
      jiraTicket: {
        id: request.meta.jira_ticket.id,
        key: request.meta.jira_ticket.key,
        url: request.meta.jira_ticket.url,
      },
      developerMessage: request.meta.developer_message,
      httpCode: request.meta.http_code,
      success: request.meta.success,
    },
  };
};
