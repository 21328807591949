import * as Styled from "./LoadingAnimationStyles";
import * as React from "react";
import Logo360_3 from "@/assets/svg/Logo360_3";
import Logo360_6 from "@/assets/svg/Logo360_6";
import Logo360_0 from "@/assets/svg/Logo360_0";
import Logo360_D from "@/assets/svg/Logo360_D";

interface Props {
  isTextShown?: boolean;
}

const LoadingAnimation: React.FC<Props> = ({ isTextShown = true }) => {
  return (
    <Styled.Wrapper>
      <Styled.CircleWrapper>
        <Styled.Circle></Styled.Circle>
        <Styled.Holder3>
          <Logo360_3 />
        </Styled.Holder3>
        <Styled.Holder6>
          <Logo360_6 />
        </Styled.Holder6>
        <Styled.Holder0>
          <Logo360_0 />
        </Styled.Holder0>
        <Styled.HolderD>
          <Logo360_D />
        </Styled.HolderD>
      </Styled.CircleWrapper>

      {isTextShown && (
        <Styled.TitleWrapper>
          Building your Hub
          <Styled.Dots />
        </Styled.TitleWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default LoadingAnimation;
