import * as Types from "./wabaPolicyViolations.types";

export const unpackWabaPolicyViolation = (
  response: Types.IServerResponseWabaPolicyViolation
): Types.IUIResponseWabaPolicyViolation => ({
  createdAt: response.created_at,
  event: response.event,
  expiresAt: response.expires_at || "",
  id: response.id,
  modifiedAt: response.modified_at,
  payload: {
    event: response.payload.event || "",
    phoneNumber: response.payload.phone_number || "",
    restrictionInfo: response.payload.restriction_info
      ? response.payload.restriction_info!.map((restrictionInfo) =>
          unpackRestrictionInfo(restrictionInfo)
        )
      : null,
    violationInfo: response.payload.violation_info
      ? {
          violationType: response.payload.violation_info!.violation_type,
        }
      : null,
    wabaAccountId: response.payload.waba_account_id,
  },
  type: response.type || "",
  wabaAccountId: response.waba_account_id,
});

export const unpackRestrictionInfo = (
  response: Types.IServerResponseRestrictionInfo
): Types.IUIResponseRestrictionInfo => ({
  expiration: response.expiration,
  restrictionType: response.restriction_type,
});

export const unpackWabaPolicyViolations = (
  response: Types.IServerResponseWabaPolicyViolations
): Types.IUIResponseWabaPolicyViolations => ({
  wabaPolicyViolations: response.wabaPolicyViolations.map(
    (wabaPolicyViolation) => unpackWabaPolicyViolation(wabaPolicyViolation)
  ),
  total: response.total,
});
