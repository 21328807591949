// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.NOT_allNotifications]: "Tudo",
  [keys.NOT_archivedNotifications]: "Arquivado",
  [keys.NOT_closeButtonTooltip]: "Arquivar essa notificação",
  [keys.NOT_archiveAllBtn]: "Arquivar tudo",
  [keys.NOT_noNewNotifications]:
    "Essas são todas as suas notificações dos últimos 30 dias.",
  //  [keys.NOT_clientAdded]: "translation_missing",
  //  [keys.NOT_clientAddedWithMigrationDate]: "translation_missing",
  [keys.NOT_rejectionReason]: "Razão da rejeição:",
  [keys.NOT_alreadyApproved]: "Você já aprovou este pedido de alteração",
  [keys.NOT_apiKeyRecommendation]: "Recomendamos gerar uma nova API KEY",
  [keys.NOT_generateNewAPIKey]: "Gerar nova API KEY",
  [keys.NOT_partnerRequestFailed]: "Falha: O processo não pode ser concluído",
  [keys.NOT_partnerRequestCancelled]: "Cliente cancelou o pedido.",
  [keys.NOT_needCaptcha]: "Por favor, verifique se você não é um robô",
  [keys.NOT_errorUpdatingChatSupport]:
    "Há um problema com a atualização do status do suporte por bate-papo. Tente mais tarde.",
  // [keys.NOT_allNumbersAffected]: "translation_missing",
};

export default translate;
