// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  KEY_existingTitle: "KEY_existingTitle",
  KEY_existingHelp1: "KEY_existingHelp1",
  KEY_existingHelp2: "KEY_existingHelp2",
  KEY_newTitle: "KEY_newTitle",
  KEY_newHelp: "KEY_newHelp",
  KEY_key: "KEY_key",
  KEY_copy: "KEY_copy",
  KEY_copied: "KEY_copied",
  KEY_address: "KEY_address",
  KEY_confirmCopy: "KEY_confirmCopy",
  KEY_partnerInfoText: "KEY_partnerInfoText",
  KEY_partnerInfoButtonLabel: "KEY_partnerInfoButtonLabel",
  KEY_generateKeyIntro: "KEY_generateKeyIntro",
};

export default keys;
