import { MPInstancesHub } from "@/services/api/RequestAxios/RequestAxiosHub";
import { IQueryParams, packQueryParams, stringifyQueryParams } from "../utils";
import { UIModelProject, UIModelProjects } from "./project.model";
import { unpackProject, packProject, unpackProjects } from "./project.mapper";
import * as Types from "./project.types";

const projectsURL = ({
  partnerId,
  clientId,
}: {
  partnerId: string;
  clientId: string;
}) => `/partners/${partnerId}/clients/${clientId}/projects`;

const ApiKeyURL = ({
  partnerId,
  clientId,
  projectId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
}) =>
  `/partners/${partnerId}/clients/${clientId}/projects/${projectId}/control/get_api_key`;

const projectURL = ({
  partnerId,
  clientId,
  projectId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
}) => `/partners/${partnerId}/clients/${clientId}/projects/${projectId}`;

export const getProjectsList = async (
  { partnerId, clientId }: { partnerId: string; clientId: string },
  queryParams?: IQueryParams
): Promise<UIModelProjects> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseProjects>(
      projectsURL({ partnerId, clientId }),
      {
        params: queryParams && packQueryParams(queryParams),
        paramsSerializer: stringifyQueryParams,
      }
    );

  return new UIModelProjects(unpackProjects(data));
};

export const getProject = async ({
  partnerId,
  clientId,
  projectId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
}) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseProject>(
      projectURL({ partnerId, clientId, projectId })
    );

  return new UIModelProject(unpackProject(data));
};

export const addProject = async (
  { partnerId, clientId }: { partnerId: string; clientId: string },
  payload: Types.IProjectFormValues
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseProject>(
      projectsURL({ partnerId, clientId }),
      packProject(payload)
    );

  return new UIModelProject(unpackProject(data));
};

export const editProject = async (
  {
    partnerId,
    clientId,
    projectId,
  }: { partnerId: string; clientId: string; projectId: string },
  payload: Types.IProjectFormValues
) => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.patch<Types.IServerResponseProject>(
      projectURL({ partnerId, clientId, projectId }),
      packProject(payload)
    );

  return unpackProject(data);
};

export const getApiKey = async ({
  partnerId,
  clientId,
  projectId,
}: {
  partnerId: string;
  clientId: string;
  projectId: string;
}) => {
  const { data } = await MPInstancesHub.buffedInstances.newCrm.get<{
    api_key: string | null;
  }>(ApiKeyURL({ partnerId, clientId, projectId }));

  return data;
};
