import {
  buttonComponent,
  Components,
  headerComponent,
  normalComponent,
  singleComponent,
} from "@/modules/Templates/useTemplateComponents";
import * as Types from "./wabaTemplates.types";

export const unpackWabaTemplate = (
  response: Types.IServerResponseWabaTemplate
): Types.IUIResponseWabaTemplate => ({
  id: response.id,
  wabaAccountId: response.waba_account_id,
  partnerId: response.partner_id,
  category: response.category,
  name: response.name,
  status: response.status,
  language: response.language,
  components: response.components,
  modifiedAt: response.modified_at,
  createdAt: response.created_at,
  rejectedReason: response.rejected_reason,
});

export const unpackWabaTemplates = (
  response: Types.IServerResponseWabaTemplates
): Types.IUIResponseWabaTemplates => ({
  total: response.total,
  wabaTemplates: response.waba_templates.map((wabaTemplate) =>
    unpackWabaTemplate(wabaTemplate)
  ),
});

const getRestOfContentOfComponent = (
  componentType: Types.ComponentsTypes,
  value:
    | singleComponent<buttonComponent>
    | singleComponent<normalComponent>
    | singleComponent<headerComponent>
) => {
  switch (componentType) {
    case "BUTTONS": {
      const content =
        value.content as singleComponent<buttonComponent>["content"];
      const example =
        value.example as singleComponent<normalComponent>["example"];
      return {
        type: componentType,
        buttons: content.buttons.map((button) => ({
          type: button.type,
          text: button.text,
          ...(button.type === "URL"
            ? {
                url: button.url,
                ...(example.length !== 0 ? { example: example } : {}),
              }
            : button.type === "PHONE_NUMBER"
            ? { phone_number: button.phone_number }
            : {}),
        })),
      };
    }
    case "HEADER": {
      const content =
        value.content as singleComponent<headerComponent>["content"];
      const example =
        value.example as singleComponent<normalComponent>["example"];
      return {
        type: componentType,
        format: content.format,
        ...(content.format === "TEXT"
          ? {
              text: content.text,
              ...(example.length !== 0
                ? { example: { header_text: example } }
                : {}),
            }
          : {}),
        ...(content.format === "IMAGE" ||
        content.format === "DOCUMENT" ||
        content.format === "VIDEO"
          ? { example: { header_handle: example } }
          : {}),
      };
    }
    case "FOOTER": {
      const content =
        value.content as singleComponent<normalComponent>["content"];
      return {
        type: componentType,
        text: content.text,
      };
    }
    case "BODY": {
      const content =
        value.content as singleComponent<normalComponent>["content"];
      const example =
        value.example as singleComponent<normalComponent>["example"];
      return {
        type: componentType,
        text: content.text,
        ...(example.length !== 0 ? { example: { body_text: [example] } } : {}),
      };
    }
  }
};

type cmp = singleComponent<buttonComponent | normalComponent | headerComponent>;

function checkForComponentType<T extends cmp>(
  component: cmp,
  wantedComponent: Types.ComponentsTypes,
  componentType: Types.ComponentsTypes
): component is T {
  return wantedComponent === componentType;
}

const isComponentNotFilled = (
  componentType: Types.ComponentsTypes,
  component: cmp
) => {
  return (
    (checkForComponentType<singleComponent<buttonComponent>>(
      component,
      "BUTTONS",
      componentType
    ) &&
      !component.content.buttons.length) ||
    (checkForComponentType<singleComponent<headerComponent>>(
      component,
      "HEADER",
      componentType
    ) &&
      component.content.format === "TEXT" &&
      !component.content.text) ||
    (checkForComponentType<singleComponent<normalComponent>>(
      component,
      "BODY",
      componentType
    ) &&
      !component.content.text) ||
    (checkForComponentType<singleComponent<normalComponent>>(
      component,
      "FOOTER",
      componentType
    ) &&
      !component.content.text)
  );
};

export const packWabaTemplatesComponents = (
  components: Components
): Types.IServerResponseWabaTemplate["components"] => {
  const as = Array.from(components);
  return as.reduce<Types.IServerResponseWabaTemplate["components"]>(
    (acc, [key, value]) => {
      if (isComponentNotFilled(key, value)) {
        return acc;
      }
      return value.visible
        ? [...acc, getRestOfContentOfComponent(key, value)]
        : acc;
    },
    []
  );
};
