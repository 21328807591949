// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.INS_understandWhatWorksBest]: "Entiende qué funciona mejor",
  [keys.INS_trackedConversion]: "Conversión seleccionada",
  [keys.INS_conversationsFrom]: "Conversaciones de",
  [keys.INS_whereLeads]: "donde los leads",
  [keys.INS_editConversions]: "Editar conversión",
  [keys.INS_edit]: "Editar",
  [keys.INS_allSources]: "Todas las fuentes",
  [keys.INS_incoming]: "Entrantes",
  [keys.INS_outgoing]: "Salientes",
  [keys.INS_organic]: "Mensajes orgánicos",
  [keys.INS_sourceName]: "Nombre de fuente",
  [keys.INS_sourceBreakdown]: "Desglose de fuentes",
  [keys.INS_startedConversations]: "Conversaciones iniciadas",
  [keys.INS_conversionRate]: "Tasa de conversión",
  [keys.INS_convertedLeads]: "Leads convertidos",
  [keys.INS_invested]: "Invertido",
  [keys.INS_revenue]: "Ingresos",
  [keys.INS_downloadLeads]: "Descargar leads",
  [keys.INS_searchNumber]: "Buscar un número",
  [keys.INS_downloadAllLeads]: "Leads",
  [keys.INS_saveEvent]: "Salvar Evento",
  [keys.INS_saveEventSubtitle]:
    "Asegúrese de que los caracteres coincidan exactamente al rastrear un mensaje de WhatsApp, para obtener resultados precisos.",
  [keys.INS_notificationEventCreated]: "Evento creado exitosamente",
  [keys.INS_saveEventTooltip]: "Seguimiento de mensajes de WhatsApp",
  [keys.INS_writeTheEvent]: "Escribe el mensaje del evento",
  [keys.INS_saveEventLabel]: "Mensaje de WhatsApp que se debe rastrear",
  [keys.INS_confirm]: "Confirmar",
  [keys.INS_downloadAllLeadsDescription]:
    "Descarga todos los leads registrados dentro del lapso de tiempo seleccionado, sin aplicar ningún filtro específico.",
  [keys.INS_leadsDescription]: "Leads que iniciaron una conversación",
  [keys.INS_percentageLeads]:
    "Porcentaje de leads que activaron el evento de conversión",
  [keys.INS_leadsConversion]: "Leads que activaron el evento de conversión",
  [keys.INS_investedDescription]:
    "Cantidad invertida en activar eventos de conversión",
  [keys.INS_conversionCost]:
    "El costo asociado con cada adquisición exitosa de un cliente potencial, en función del monto invertido",
  [keys.INS_revenueDescription]:
    "Cantidad de ingresos generados a través de este evento de conversión",
  [keys.INS_returnDescription]:
    "El retorno de la inversión publicitaria (ROAS) son los ingresos generados por cada unidad de moneda invertida",
  [keys.INS_impressionsTitle]: "Impresiones Totales",
  [keys.INS_impressionsDescription]:
    "Número de veces que se cargó un anuncio en la página",
  [keys.INS_paidClicksTitle]: "Clics Pagados",
  [keys.INS_paidClicksDescription]: "Cantidad de clics en un anuncio CTWA",
  [keys.INS_roasDescription]: "Datos de ROAS no disponibles.",
  [keys.INS_learnEnable]: "Aprenda cómo habilitarlo",
  [keys.INS_costPerConversion]: "Costo Por Conversión",
  [keys.INS_revenueNotAvailable]: "Datos de Ingresos no disponibles",
  [keys.INS_adsFrom]: "Anuncios de → Todos los Conjuntos de Anuncios",
  [keys.INS_sourceInfo]: "Información de la fuente",
  [keys.INS_detailedInfo]: "Información detallada de la fuente seleccionada",
  [keys.INS_typeLabel]: "Tipo",
  [keys.INS_typeInfo]: "Meta",
  [keys.INS_typeDescription]:
    "Las fuentes de Meta son los anuncios que hacen click a WhatsApp",
  [keys.INS_incomingCustom]: "Fuente entrante",
  [keys.INS_incomingCustomDescription]:
    "Las fuentes entrantes son aquellas donde la conversation fue iniciada por el lead. Estas pueden ser: códigos QR, links WA.me, etc.",
  [keys.INS_giveThisSourceName]: "Dale un nombre a esta fuente",
  [keys.INS_yesterday]: "Ayer",
  [keys.INS_enableIt]: "Habilitar",
  [keys.INS_steps]: "step(s)",
  [keys.INS_createNewFunnel]: "Crear nuevo funnel",
  [keys.INS_nameFunnel]: "Nombrar funnel",
  [keys.INS_replaceEvent]: "Reemplazar con otro evento",
  [keys.INS_selectEvent]: "Seleccione un evento",
  [keys.INS_removeEvent]: "Eliminar evento del funnel",
  [keys.INS_mustNotExceed20]: "El nombre no debe exceder 20 caracteres",
  [keys.INS_costPerAcquisition]: "Costo por Adquisición",
  [keys.INS_inThreadEvent]: "Evento in-thread",
  [keys.INS_offThreadEvent]: "Evento off-thread",
  [keys.INS_funnelUpdated]: "Funnel actualizado",
  [keys.INS_sourceTypes]: "Todas las fuentes",
  [keys.INS_sources]: "Fuentes",
  [keys.INS_sourcesDescription]:
    "Tipos de fuentes utilizadas para promover conversaciones",
  [keys.INS_messages]: "Mensajes",
  [keys.INS_adSets]: "Anuncios",
  [keys.INS_adSet]: "Anuncio",
  [keys.INS_folders]: "Carpetas",
  [keys.INS_ads]: "Anuncios",
  [keys.INS_campaigns]: "Campañas",
  [keys.INS_metaClickToWhatsApp]: "Campañas CTWA",
  [keys.INS_metaCTWA]: "Meta CTWA",
  [keys.INS_incomingCampaigns]: "Mensajes entrantes",
  [keys.INS_outgoingTemplates]: "Mensajes salientes",
  [keys.INS_open]: "Abrir",
  [keys.INS_custom]: "Personalizado",
  [keys.INS_lastWeek]: "Última semana",
  [keys.INS_last2Weeks]: "Últimas 2 semanas",
  [keys.INS_lastMonth]: "Último mes",
  [keys.INS_cancel]: "Cancelar",
  [keys.INS_apply]: "Aplicar",
  [keys.INS_bestRoas]: "Alto nivel de compromiso",
  [keys.INS_bestRoasTooltip]:
    "Parece que este anuncio funciona considerablemente mejor que los demás en este mismo conjunto de anuncios.",
  [keys.INS_bestRoasTooltipLink]: "Aprenda a mejorar el rendimiento general",
  [keys.INS_worstRoas]: "Bajo nivel de compromiso",
  [keys.INS_worstRoasTooltip]:
    "Parece que este anuncio tiene un rendimiento considerablemente peor que los demás en este mismo conjunto de anuncios.",
  [keys.INS_worstRoasTooltipLink]: "Aprenda a mejorar su rendimiento",
  // [keys.INS_orderingFilter]: "translation_missing",
  //  [keys.INS_selectEventToViewDetails]: "translation_missing",
  [keys.INS_details]: "Detalles",
  [keys.INS_orderingBtnDescription]: "Ordenar las columnas por",
  [keys.INS_applyFunnel]: "Aplicar embudo",
  [keys.INS_funnelApplied]: "Embudo aplicado",
  [keys.INS_applyFunnelButtonTooltip]:
    "Obtenga las columnas Tasa de conversión y Leads convertidos en función de sus eventos de embudo.",
  [keys.INS_applyFunnelDisabledButtonTooltip]:
    "Agregue al menos 2 eventos en el embudo para aplicarlo en la tabla.",
  [keys.INS_overallAverage]: "Promedio general para las fechas seleccionadas",
};

export default translate;
