// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.KEY_existingTitle]: "API key existente",
  [keys.KEY_existingHelp1]:
    "Já existe uma API key ativa. Gerar uma nova API key vai sobrescrever a atual e deletar a conexão do webhook.",
  [keys.KEY_existingHelp2]:
    "Ao continuar você precisará enviar a nova API key para a aplicação que desejar usar.",
  [keys.KEY_newTitle]: "Sua API key",
  [keys.KEY_newHelp]:
    "Por favor copie a sua nova API key e guarde em um lugar seguro. Quando você fechar essa janela, a API key não poderá ser acessada por você ou pela 360dialog.",
  [keys.KEY_key]: "API key: ",
  [keys.KEY_copy]: "Copiar",
  [keys.KEY_copied]: "Copiado",
  [keys.KEY_address]: "Endereço:",
  [keys.KEY_confirmCopy]: "Copiei e guardei a API key",
  [keys.KEY_partnerInfoText]:
    "Clique aqui para colocar a sua API key na aplicação <PARTNER>.",
  [keys.KEY_partnerInfoButtonLabel]: "Continuar para <PARTNER>",
  [keys.KEY_generateKeyIntro]:
    "Gerar uma chave API para usar em aplicativos de terceiros para se conectar ao WhatsApp Business API.",
};

export default translate;
