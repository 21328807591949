// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.DASH_welcome]: "translation_missing",
  //  [keys.DASH_welcomeBack]: "translation_missing",
  //  [keys.DASH_mainTemplateManagerInfo]: "translation_missing",
  //  [keys.DASH_learnMore]: "translation_missing",
  //  [keys.DASH_goToTemplates]: "translation_missing",
  //  [keys.DASH_nextSteps]: "translation_missing",
  //  [keys.DASH_firstSteps]: "translation_missing",
  //  [keys.DASH_yourWabasList]: "translation_missing",
  //  [keys.DASH_resources]: "translation_missing",
  //  [keys.DASH_faq]: "translation_missing",
  //  [keys.DASH_mainVerified]: "translation_missing",
  //  [keys.DASH_mainGoToFbmButton]: "translation_missing",
  //  [keys.DASH_mainDocsLearnMore]: "translation_missing",
  //  [keys.DASH_showDocsButton]: "translation_missing",
  //  [keys.DASH_popupIntro]: "translation_missing",
  //  [keys.DASH_popupBullet1]: "translation_missing",
  //  [keys.DASH_popupBullet2]: "translation_missing",
  //  [keys.DASH_generateKeyButton]: "translation_missing",
  //  [keys.DASH_fetchingNumberPopup]: "translation_missing",
  //  [keys.DASH_settingUpPopup]: "translation_missing",
  //  [keys.DASH_numbersSectionEmpty]: "translation_missing",
  //  [keys.DASH_integrationPartnerInfo]: "translation_missing",
  //  [keys.DASH_lastStepApi]: "translation_missing",
  //  [keys.DASH_lastStepForwarding]: "translation_missing",
  //  [keys.DASH_lastStepTitle]: "translation_missing",
  //  [keys.DASH_lastStepButtonLabel]: "translation_missing",
  //  [keys.DASH_lastStepButtonLabelClose]: "translation_missing",
  //  [keys.DASH_lastStepVerifiedTitle]: "translation_missing",
  //  [keys.DASH_lastStepVerifiedText]: "translation_missing",
  //  [keys.DASH_timeoutErrorText]: "translation_missing",
  //  [keys.DASH_popupBulletBypassBeta]: "translation_missing",
  //  [keys.DASH_submissionSuccessHelptext]: "translation_missing",
  //  [keys.DASH_serverDelayMessage]: "translation_missing",
  //  [keys.DASH_buttonLabelNewButton]: "translation_missing",
  //  [keys.DASH_popupBullet3]: "translation_missing",
  //  [keys.DASH_popupBullet5]: "translation_missing",
  //  [keys.DASH_createBtnInbox]: "translation_missing",
  //  [keys.DASH_generateKeyButtonInstead]: "translation_missing",
  //  [keys.DASH_chooseDifferentName]: "translation_missing",
  //  [keys.DASH_displayNamePendingMessage]: "translation_missing",
  //  [keys.DASH_displayNameRejectedMessage]: "translation_missing",
  //  [keys.DASH_displayNameRejectedMessagePipe]: "translation_missing",
  //  [keys.DASH_integrationSetup]: "translation_missing",
  //  [keys.DASH_migrateNumberFromAnotherBSP]: "translation_missing",
  //  [keys.DASH_useClassicSignup]: "translation_missing",
  //  [keys.DASH_addNewPhoneNumber]: "translation_missing",
  //  [keys.DASH_importFromWBA]: "translation_missing",
  //  [keys.DASH_importFromBS]: "translation_missing",
  //  [keys.DASH_stackSetup]: "translation_missing",
  //  [keys.DASH_test]: "translation_missing",
  //  [keys.DASH_testWebhookSuccess]: "translation_missing",
  //  [keys.DASH_testWebhookError]: "translation_missing",
  //  [keys.DASH_integrationSettings]: "translation_missing",
  //  [keys.DASH_partnerWebhookUrl]: "translation_missing",
  //  [keys.DASH_webhookUrlTootip]: "translation_missing",
  //  [keys.DASH_redirectUrl]: "translation_missing",
  //  [keys.DASH_redirectUrlToolip]: "translation_missing",
  //  [keys.DASH_defaultHosting]: "translation_missing",
  //  [keys.DASH_cloudApiHosted]: "translation_missing",
  //  [keys.DASH_onPremiseHosted]: "translation_missing",
  //  [keys.DASH_infoOnHostingPlatform]: "translation_missing",
  //  [keys.DASH_partnerUpdatedSuccess]: "translation_missing",
  //  [keys.DASH_redirectUrlNorValid]: "translation_missing",
  //  [keys.DASH_paymentPlan]: "translation_missing",
  //  [keys.DASH_vsPreviousWeek]: "translation_missing",
  //  [keys.DASH_conversations]: "translation_missing",
  //  [keys.DASH_expenses]: "translation_missing",
  //  [keys.DASH_lastWeekActivity]: "translation_missing",
  //  [keys.DASH_channel]: "translation_missing",
};

export default translate;
