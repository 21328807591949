// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.APP_inbox_title]:
    "Configurar y conectar una cuenta de WhatsApp Business con get.chat",
  [keys.APP_inbox_step1]: "Selección de plan y checkout",
  [keys.APP_inbox_step2]: "Configure su cuenta de WhatsApp Business",
  [keys.APP_inbox_step3]: "Termine su onboarding con get.chat",
  [keys.APP_inbox_bullet1]: "Cuenta gratuita de WhatsApp Business",
  [keys.APP_inbox_bullet2]:
    "19 EUR para la bandeja de entrada compartida de get.chat",
  [keys.APP_inbox_termsTitle]: "Términos y condiciones de la Get.chat",
  [keys.APP_apps]: "Aplicaciones",
  [keys.APP_addApps]: "Agregar aplicaciones",
  [keys.APP_browse]: "Explorar mercado",
  [keys.APP_addDescription]:
    "Cada uno de sus números de teléfono se puede conectar a una aplicación de terceros, como bandejas de entrada, chatbots y sistemas CRM.",
  [keys.APP_addSubDescription]:
    "Actualmente estamos construyendo nuestro mercado junto con más de 700 socios. Ahora mismo puede comenzar con una <BOLD>bandeja de entrada de equipo<BOLD> provista por <BOLD>get.chat<BOLD>",
  [keys.APP_getChat]: "get.chat",
  [keys.APP_setUp]: "Configurar bandeja de entrade de get.chat",
  [keys.APP_connectApp]: "Conectar aplicación",
  [keys.APP_numberRegistration]: "Continuar al registro del número",
  [keys.APP_addGetChat]: "Agregar get.chat",
  [keys.APP_activateInbox]: "Activa tu bandeja de entrada",
  [keys.APP_faq1]: "¿Qué es una bandeja de entrada de equipo de WhatsApp?",
  [keys.APP_answer1]:
    "Una bandeja de entrada del equipo de WhatsApp es una interfaz gráfica de usuario para usted y su equipo. La bandeja de entrada web es técnicamente una interfaz de la API de integración. Le permite tener acceso de múltiples usuarios cuando se trata de enviar y recibir sus mensajes de cuenta comercial de WA. En términos simples, Web Inbox es una bandeja de entrada colaborativa que brinda a varios miembros del equipo la capacidad de responder a los clientes vía WhatsApp a través de un tablero compartido.",
  [keys.APP_faq2]: "¿Qué bandeja de entrada estoy activando?",
  [keys.APP_answer2]:
    "Se generará una bandeja de entrada de Get.chat para usted. La bandeja de entrada de Get.chat le permite a su equipo de atención al cliente responder a las consultas de los clientes a través de WhatsApp simultáneamente y desde diferentes dispositivos. Convierte su bandeja de entrada de WhatsApp en un espacio de comunicación agradable tanto para los clientes como para el equipo, lo que hace que sea fácil y eficiente administrar la atención al cliente para su negocio",
  [keys.APP_faq3]: "¿Cuál es el precio de este servicio?",
  [keys.APP_answer3]:
    "Como eres cliente de 360dialog, este servicio es totalmente gratuito para ti.",
  [keys.APP_activateInboxCheckbox]:
    "Acepto los <LINK>https://get.chat/terms-of-service | Terms of service<LINK> de  Get.chat",
  [keys.APP_captchaNewApiMessage]:
    "Tenga en cuenta que al activar su bandeja de entrada, se generará una nueva API key y la anterior dejará de funcionar. Si tienes alguna integración activa, también dejará de funcionar.",
  [keys.APP_captchaNewApiCheckbox]:
    "Entiendo que mi antigua API key, si tengo una, ya no funcionará",
  [keys.APP_inboxAccess]: "Acceso a la bandeja de entrada",
  [keys.APP_inboxCredentials]:
    "Utiliza las siguientes credenciales para iniciar sesión en tu bandeja de entrada",
  [keys.APP_inboxAccessClickButton]:
    "Para acceder a su bandeja de entrada, haga clic en el 'botón de acceso a continuación'",
  [keys.APP_accessInbox]: "Acceder a la bandeja de entrada",
  [keys.APP_settingUpInboxTitle]: "Configurando tu bandeja de entrada",
  [keys.APP_settingUpInbox]:
    "Configurando tu bandeja de entrada para el número <NUMBER>",
  [keys.APP_pageTitle]: "360dialog - Conectar Apps a números ",
  [keys.APP_pageDescription]:
    "Ver aplicaciones conectadas y conectar aplicaciones a números",
  //  [keys.APP_notificationEmail]: "translation_missing",
  //  [keys.APP_checkYourEmail]: "translation_missing",
  //  [keys.APP_emailSent]: "translation_missing",
  //  [keys.APP_followSteps]: "translation_missing",
};

export default translate;
