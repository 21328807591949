// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.PRE_title]: "translation_missing",
  //  [keys.PRE_display]: "translation_missing",
  //  [keys.PRE_themeTitle]: "translation_missing",
  //  [keys.PRE_themeSubtitle]: "translation_missing",
  //  [keys.PRE_theme_light]: "translation_missing",
  //  [keys.PRE_theme_dark]: "translation_missing",
  //  [keys.PRE_theme_system]: "translation_missing",
  //  [keys.PRE_behavior]: "translation_missing",
  //  [keys.PRE_shortcutsTitle]: "translation_missing",
  //  [keys.PRE_shortcutsSubtitle]: "translation_missing",
  //  [keys.PRE_shortcutsCardTitle]: "translation_missing",
  //  [keys.PRE_showAll]: "translation_missing",
  //  [keys.PRE_back]: "translation_missing",
  //  [keys.PRE_nextTab]: "translation_missing",
  //  [keys.PRE_prevTab]: "translation_missing",
  //  [keys.PRE_nextPage]: "translation_missing",
  //  [keys.PRE_prevPage]: "translation_missing",
  //  [keys.PRE_focusSearch]: "translation_missing",
  //  [keys.PRE_areaGeneral]: "translation_missing",
  //  [keys.PRE_areaNav]: "translation_missing",
  //  [keys.PRE_areaNavAdmin]: "translation_missing",
  //  [keys.PRE_areaNumbers]: "translation_missing",
  //  [keys.PRE_areaTemplates]: "translation_missing",
  //  [keys.PRE_areaPreferences]: "translation_missing",
  //  [keys.PRE_integrations]: "translation_missing",
  //  [keys.PRE_partners]: "translation_missing",
  //  [keys.PRE_showHide]: "translation_missing",
  //  [keys.PRE_openTemplateManager]: "translation_missing",
  //  [keys.PRE_showDetails]: "translation_missing",
  //  [keys.PRE_newTemplate]: "translation_missing",
  //  [keys.PRE_openPreview]: "translation_missing",
  //  [keys.PRE_duplicateTemplate]: "translation_missing",
  //  [keys.PRE_addLang]: "translation_missing",
  //  [keys.PRE_themeToggle]: "translation_missing",
  //  [keys.PRE_disableShortcuts]: "translation_missing",
  //  [keys.PRE_langTitle]: "translation_missing",
  //  [keys.PRE_langSubtitle]: "translation_missing",
  //  [keys.PRE_loadingBillingPortal]: "translation_missing",
  //  [keys.PRE_alertNoPayment]: "translation_missing",
  //  [keys.PRE_alertNoPaymentLinkLabel]: "translation_missing",
  //  [keys.PRE_alertNoPaymentCountry]: "translation_missing",
  //  [keys.PRE_alertNoPaymentCountryLinkLabe]: "translation_missing",
  //  [keys.PRE_copyUrl]: "translation_missing",
  //  [keys.PRE_otherAdmin]: "translation_missing",
  //  [keys.PRE_Privacy]: "translation_missing",
  //  [keys.PRE_preferencesTitle]: "translation_missing",
  //  [keys.PRE_managePreferencesDescription]: "translation_missing",
  //  [keys.PRE_cookiesChanged]: "translation_missing",
  //  [keys.PRE_tos]: "translation_missing",
  //  [keys.PRE_openTerms]: "translation_missing",
  //  [keys.PRE_accepted]: "translation_missing",
  //  [keys.PRE_notAccepted]: "translation_missing",
  //  [keys.PRE_tosAcceptMsg]: "translation_missing",
  // [keys.PRE_metaAccount]: "translation_missing",
  // [keys.PRE_metaAccountSubtitle]: "translation_missing",
  // [keys.PRE_metaAccountCTA]: "translation_missing",
};

export default translate;
