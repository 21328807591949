// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  DASH_welcome: "DASH_welcome",
  DASH_welcomeBack: "DASH_welcomeBack",
  DASH_mainTemplateManagerInfo: "DASH_mainTemplateManagerInfo",
  DASH_learnMore: "DASH_learnMore",
  DASH_goToTemplates: "DASH_goToTemplates",
  DASH_nextSteps: "DASH_nextSteps",
  DASH_firstSteps: "DASH_firstSteps",
  DASH_yourWabasList: "DASH_yourWabasList",
  DASH_resources: "DASH_resources",
  DASH_faq: "DASH_faq",
  DASH_mainVerified: "DASH_mainVerified",
  DASH_mainGoToFbmButton: "DASH_mainGoToFbmButton",
  DASH_mainDocsLearnMore: "DASH_mainDocsLearnMore",
  DASH_showDocsButton: "DASH_showDocsButton",
  DASH_popupIntro: "DASH_popupIntro",
  DASH_popupBullet1: "DASH_popupBullet1",
  DASH_popupBullet2: "DASH_popupBullet2",
  DASH_generateKeyButton: "DASH_generateKeyButton",
  DASH_fetchingNumberPopup: "DASH_fetchingNumberPopup",
  DASH_settingUpPopup: "DASH_settingUpPopup",
  DASH_numbersSectionEmpty: "DASH_numbersSectionEmpty",
  DASH_integrationPartnerInfo: "DASH_integrationPartnerInfo",
  DASH_lastStepApi: "DASH_lastStepApi",
  DASH_lastStepForwarding: "DASH_lastStepForwarding",
  DASH_lastStepTitle: "DASH_lastStepTitle",
  DASH_lastStepButtonLabel: "DASH_lastStepButtonLabel",
  DASH_lastStepButtonLabelClose: "DASH_lastStepButtonLabelClose",
  DASH_lastStepVerifiedTitle: "DASH_lastStepVerifiedTitle",
  DASH_lastStepVerifiedText: "DASH_lastStepVerifiedText",
  DASH_timeoutErrorText: "DASH_timeoutErrorText",
  DASH_popupBulletBypassBeta: "DASH_popupBulletBypassBeta",
  DASH_submissionSuccessHelptext: "DASH_submissionSuccessHelptext",
  DASH_serverDelayMessage: "DASH_serverDelayMessage",
  DASH_buttonLabelNewButton: "DASH_buttonLabelNewButton",
  DASH_popupBullet3: "DASH_popupBullet3",
  DASH_popupBullet5: "DASH_popupBullet5",
  DASH_createBtnInbox: "DASH_createBtnInbox",
  DASH_generateKeyButtonInstead: "DASH_generateKeyButtonInstead",
  DASH_chooseDifferentName: "DASH_chooseDifferentName",
  DASH_displayNamePendingMessage: "DASH_displayNamePendingMessage",
  DASH_displayNameRejectedMessage: "DASH_displayNameRejectedMessage",
  DASH_displayNameRejectedMessagePipe: "DASH_displayNameRejectedMessagePipe",
  DASH_integrationSetup: "DASH_integrationSetup",
  DASH_migrateNumberFromAnotherBSP: "DASH_migrateNumberFromAnotherBSP",
  DASH_useClassicSignup: "DASH_useClassicSignup",
  DASH_addNewPhoneNumber: "DASH_addNewPhoneNumber",
  DASH_importFromWBA: "DASH_importFromWBA",
  DASH_importFromBS: "DASH_importFromBS",
  DASH_stackSetup: "DASH_stackSetup",
  DASH_test: "DASH_test",
  DASH_testWebhookSuccess: "DASH_testWebhookSuccess",
  DASH_testWebhookError: "DASH_testWebhookError",
  DASH_integrationSettings: "DASH_integrationSettings",
  DASH_partnerWebhookUrl: "DASH_partnerWebhookUrl",
  DASH_webhookUrlTootip: "DASH_webhookUrlTootip",
  DASH_redirectUrl: "DASH_redirectUrl",
  DASH_redirectUrlToolip: "DASH_redirectUrlToolip",
  DASH_defaultHosting: "DASH_defaultHosting",
  DASH_cloudApiHosted: "DASH_cloudApiHosted",
  DASH_onPremiseHosted: "DASH_onPremiseHosted",
  DASH_infoOnHostingPlatform: "DASH_infoOnHostingPlatform",
  DASH_partnerUpdatedSuccess: "DASH_partnerUpdatedSuccess",
  DASH_redirectUrlNorValid: "DASH_redirectUrlNorValid",
  DASH_paymentPlan: "DASH_paymentPlan",
  DASH_vsPreviousWeek: "DASH_vsPreviousWeek",
  DASH_conversations: "DASH_conversations",
  DASH_expenses: "DASH_expenses",
  DASH_lastWeekActivity: "DASH_lastWeekActivity",
  DASH_channel: "DASH_channel",
};

export default keys;
