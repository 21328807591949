import * as Types from "./comments.types";
import { unpackComment, unpackComments } from "./comments.mapper";
import { UIModelComment, UIModelComments } from "./comments.model";
import { IQueryParams, packQueryParams, stringifyQueryParams } from "../utils";
import { MPInstancesHub } from "@/api/RequestAxios/RequestAxiosHub";

const commentURL = ({
  channelId,
  partnerId,
  commentId,
}: {
  partnerId: string;
  channelId: string;
  commentId: string;
}) => `/partners/${partnerId}/channels/${channelId}/comments/${commentId}`;
const commentsURL = ({
  channelId,
  partnerId,
}: {
  partnerId: string;
  channelId: string;
}) => `/partners/${partnerId}/channels/${channelId}/comments`;

export const getComment = async ({
  channelId,
  partnerId,
  commentId,
}: {
  partnerId: string;
  channelId: string;
  commentId: string;
}): Promise<UIModelComment> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseComment>(
      commentURL({ partnerId, channelId, commentId })
    );

  return new UIModelComment(unpackComment(data));
};

export const getComments = async (
  { channelId, partnerId }: { partnerId: string; channelId: string },
  queryParams?: IQueryParams
): Promise<UIModelComments> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.get<Types.IServerResponseComments>(
      commentsURL({ partnerId, channelId }),
      {
        params: queryParams && packQueryParams(queryParams),
        paramsSerializer: stringifyQueryParams,
      }
    );

  return new UIModelComments(unpackComments(data));
};

export const addComment = async (
  { channelId, partnerId }: { partnerId: string; channelId: string },
  payload: { content: string }
): Promise<UIModelComment> => {
  const { data } =
    await MPInstancesHub.buffedInstances.newCrm.post<Types.IServerResponseComment>(
      commentsURL({ partnerId, channelId }),
      payload
    );

  return new UIModelComment(unpackComment(data));
};
