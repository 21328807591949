// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.BV_advertising]: "Publicidade",
  [keys.BV_automotive]: "Automotivo",
  [keys.BV_beauty]: "Beleza, Spa e Salão",
  [keys.BV_clothing]: "Vestuário",
  [keys.BV_consumer_goods]: "Bens de consumo embalados",
  [keys.BV_ecommerce]: "Comércio eletrônico",
  [keys.BV_education]: "Educação",
  [keys.BV_energy]: "Energia e utilidades",
  [keys.BV_entertainment]: "Entretenimento",
  [keys.BV_entertainment_media]: "Entretenimento e Mídia",
  [keys.BV_event_planning]: "Planejamento e serviço de eventos",
  [keys.BV_banking]: "Finanças e bancos",
  [keys.BV_food]: "Comida e mercearia",
  [keys.BV_gaming]: "Jogos",
  [keys.BV_government]: "Governo e política",
  [keys.BV_hotel]: "Hotel e Hospedagem",
  [keys.BV_IT]: "Serviços de TI",
  [keys.BV_marketing]: "Marketing",
  [keys.BV_medical]: "Medicina e Saúde",
  [keys.BV_non_profit]: "Sem fins lucrativos",
  [keys.BV_organizations]: "Organizações e associações",
  [keys.BV_other]: "Outros",
  [keys.BV_professional]: "Serviços profissionais",
  [keys.BV_public]: "Serviço público",
  [keys.BV_retail]: "Varejo",
  [keys.BV_restaurant]: "Restaurantes",
  [keys.BV_shopping]: "Shopping e Varejo",
  [keys.BV_technology]: "Tecnologia",
  [keys.BV_telecom]: "Telecomunicações",
  [keys.BV_travel]: "Viagem",
  [keys.BV_transportation]: "Viagens e Transportes",
  [keys.BV_financial]: "Finanças e bancos",
};

export default translate;
