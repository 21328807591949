// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.BILL_header]: "Willkommen",
  [keys.BILL_balance]: "Saldo",
  [keys.BILL_addFunds]: "Guthaben hinzufügen",
  [keys.BILL_addFundsPopup]: "Guthaben für Nachrichtenvorlagen hinzufügen",
  [keys.BILL_addedFundsDescription]:
    "Hinzugefügte Guthaben werden nur auf die Nutzung von Nachrichtenvorlagen angerechnet. Monatliche oder jährliche Pläne für WhatsApp Business API Accounts werden separat berechnet und in Rechnung gestellt.",
  [keys.BILL_addFundsPopupQuestion]:
    "Wie viele Nachrichtenvorlagen planst du pro Monat zu versenden?",
  [keys.BILL_addFundsPopupTooltip]:
    "Auf der Grundlage der von dir geplanten Nutzung von Nachrichtenvorlagen können wir die Gesamtkosten für deine Nachrichtenvorlagen pro Abrechnungszeitraum schätzen. Die Preise für Vorlagen variieren jedoch von Land zu Land, daher können die Kosten pro Nachricht nur geschätzt werden.",
  [keys.BILL_costBreakdown]: "Aufschlüsselung der Kosten",
  [keys.BILL_estTemplateCost]: "Geschätzte Kosten für Nachrichtenvorlagen",
  [keys.BILL_transFees]: "Transaktionsgebühren",
  [keys.BILL_subtotal]: "Zwischensumme",
  [keys.BILL_subAndPay]: "Jetzt einreichen und bezahlen",
  [keys.BILL_monthlyUsage]: "Monatliche Nutzung",
  [keys.BILL_numTemplatesSent]: "Number of templates sent",
  [keys.BILL_costsByTemplates]: "Durch Nachrichtenvorlagen verursachte Kosten",
  [keys.BILL_noData]: "Keine Daten für diesen Bericht",
  [keys.BILL_filterByNum]: "Nach Nummer filtern",
  [keys.BILL_selectAll]: "Alle auswählen",
  [keys.BILL_numberNotLive]: "Diese Nummer ist noch nicht live",
  [keys.BILL_noBillingCountry]: "Du musst das Rechnungsland einstellen!",
  [keys.BILL_amountDue]: "Fälliger Betrag",
  [keys.BILL_notApplicable]: "K.A.",
  [keys.BILL_vat]: "MWST",
  [keys.BILL_addFundsSectionMsg]:
    "Der gewählte Betrag wird sofort abgebucht und auf dein aktuelles Guthaben angerechnet. Es handelt sich um eine einmalige Gebühr, die deine Einstellungen für die automatische Aufladung nicht beeinflusst.",
  [keys.BILL_autoRenewalSuccess]:
    "Automatische Aufladung erfolgreich aktiviert. Schwellenwert und Betrag können manuell eingestellt werden.",
  [keys.BILL_thresholdChangeSuccess]:
    "Schwellenwert für automatische Aufladung erfolgreich geändert",
  [keys.BILL_amountChangeSuccess]:
    "Betrag der automatischen Aufladung erfolgreich geändert",
  [keys.BILL_renewalHeading]: "Nummern verwalten",
  [keys.BILL_renewalTooltip]:
    "Fällt das Guthaben unter diesen Betrag, wird das Guthaben um den Betrag der automatischen Aufladung aufgeladen.",
  [keys.BILL_thresholdSection]: "Schwellenwert für die automatische Aufladung",
  [keys.BILL_excludingTransactionFees]:
    "ohne Transaktionsgebühren und Mehrwertsteuer",
  [keys.BILL_autoRenewal]: "Automatische Aufladung",
  [keys.BILL_autoRenewalAmount]: "Betrag für die automatische Aufladung",
  [keys.BILL_oneOffCharge]: "Einmalige Aufladung",
  [keys.BILL_changeSettings]: "Einstellungen ändern",
  [keys.BILL_renewalPopupHeading]: "Betrag der automatischen Aufladung ändern",
  [keys.BILL_renewalPopupSectionMsg]:
    "Dadurch werden die Einstellungen für die automatische Aufladung geändert. Der gewählte Betrag wird gebucht, wenn dein Guthaben das nächste Mal unter den Schwellenwert fällt. Wenn dein Guthaben noch über dem Schwellenwert liegt, wird dir jetzt nichts berechnet.",
  [keys.BILL_submitChanges]: "Änderungen einreichen",
  [keys.BILL_balanceBreakDown]: "Aufschlüsselung des Saldos",
  [keys.BILL_selectNumberAction]: "Nummer und Aktion auswählen",
  [keys.BILL_estimateCosts]: "Kosten abschätzen",
  [keys.BILL_confirmCheckout]: "Kauf bestätigen",
  [keys.BILL_selectAction]: "Wähle, welche Aktion du durchführen möchtest",
  [keys.BILL_addOneOffFunds]:
    "Guthaben zu dieser Nummer mit einer einmaligen Gebühr hinzufügen",
  [keys.BILL_addAutorenewalSettings]:
    "Einstellungen für die automatische Aufladung dieser Rufnummer ändern",
  //  [keys.BILL_autoRenewalSectionMsg]: "translation_missing",
  [keys.BILL_changeAutoRenewalThreshold]:
    "Schwellenwert für die automatische Aufladung ändern",
  [keys.BILL_thresholdAmount]:
    "Betrag des Schwellenwerts für die automatische Aufladung",
  [keys.BILL_thresholdRenewalMsg]:
    "Wenn das Guthaben für die ausgewählte Nummer unter den Schwellenwert für die automatische Aufladung fällt, wird das Guthaben mit dem Betrag für die automatische Aufladung aufgeladen, der separat angegeben werden kann.",
  [keys.BILL_processingPayment]:
    "Die Zahlung wird gerade bearbeitet. Der Saldo wird in Kürze aktualisiert.",
  [keys.BILL_businessInitiatedQuestion]:
    "Wie viele vom Unternehmen initiierte Konversationen planst du zu erstellen?",
  [keys.BILL_userInitiatedQuestion]:
    "Wie viele von Nutzern initiierte Konversationen wirst du voraussichtlich haben?",
  [keys.BILL_estimatedConversationCost]: "Geschätzte Kosten für Konversationen",
  [keys.BILL_previousMonth]: "Vormonat",
  [keys.BILL_addFundsNotAllowed]:
    "Kann kein Guthaben zu unvollständig registrierten Nummern hinzufügen",
  [keys.BILL_othersCombined]: "Andere (kombiniert)",
  [keys.BILL_approxCharges]: "Ungefähre Kosten",
  [keys.BILL_allConversations]: "Alle Konversationen",
  [keys.BILL_insights]: "Berichte",
  [keys.BILL_insightsAndBilling]: "Berichte & Rechnungen",
  [keys.BILL_businessInitiatedAmountTooltip]:
    "Ausgehend von der Anzahl der geplanten geschäftlich initiierten Konversationen können wir die Konversationskosten schätzen, die du wahrscheinlich generieren wirst. Die Preise variieren jedoch von Land zu Land und daher können die Kosten pro Nachricht nur geschätzt werden. Diese Schätzungen basieren auf der Annahme, dass du nur Gespräche innerhalb des Landes initiierst, aus dem deine registrierte Telefonnummer stammt.",
  [keys.BILL_userInitiatedAmountTooltip]:
    "Ausgehend von der Anzahl der geplanten nutzerinitiierten Konversationen können wir die Konversationskosten schätzen, die du wahrscheinlich generieren wirst. Die Preise variieren jedoch von Land zu Land und daher können die Kosten pro Nachricht nur geschätzt werden. Diese Schätzungen basieren auf der Annahme, dass du nur nutzerinitiierte Gespräche innerhalb des Landes, aus dem deine registrierte Telefonnummer stammt, erhalten wirst.",
  [keys.BILL_costBreakdownTooltip]:
    "Anhand der Anzahl der geplanten Konversationen, die von Nutzern und Unternehmen initiiert werden, können wir die Konversationskosten abschätzen, die du wahrscheinlich generieren wirst. Die Preise variieren jedoch von Land zu Land und daher können die Kosten pro Nachricht nur geschätzt werden. Diese Schätzungen beruhen auf der Annahme, dass du nur Gespräche innerhalb des Landes führen wirst, aus dem deine registrierte Telefonnummer stammt.",
  [keys.BILL_customize]: "Anpassen",
  [keys.BILL_noCharges]:
    "Es werden keine Gebühren erhoben, wenn die geschätzten Gespräche in den Rahmen der monatlich verfügbaren kostenlosen Gespräche (1000) fallen.",
  [keys.BILL_fundsWereAdded]: "Guthaben wurde erfolgreich hinzugefügt",
  [keys.BILL_enabled]: "aktiviert",
  [keys.BILL_disabled]: "deaktiviert",
  [keys.BILL_vat2]: "Hinzufügen des Guthabens",
  [keys.BILL_zeroBalanceText_one]:
    "Das Guthaben für die Nummer {{numbers}} ist 0. Du kannst keine Nachrichten innerhalb bezahlter Konversation mehr senden. Bitte füge Guthaben hinzu, wenn du diese Nummer weiterhin in einer bezahlten Konversation verwenden möchtest.",
  [keys.BILL_zeroBalanceText_other]:
    "Der Kontostand für diese Nummern {{numbers}} ist 0 Du kannst keine Nachrichten innerhalb bezahlter Konversation mehr senden. Bitte füge Guthaben hinzu, wenn du diese Nummern weiterhin benutzen möchtest.",
  [keys.BILL_lowBalanceText_one]:
    "Das Guthaben für die Nummer {{numbers}} ist niedrig. Um weiterhin an bezahlten Konversationen teilnehmen zu können, füge bitte manuell Guthaben hinzu oder aktiviere die automatische Erneuerung. Wenn das Guthaben für diese Nummer 0 erreicht, kannst du keine Nachrichten mehr im Rahmen einer bezahlten Konversation senden.",
  [keys.BILL_lowBalanceText_other]:
    "Das Guthaben für diese Nummern {{numbers}} ist niedrig. Um weiterhin an bezahlten Konversationen teilnehmen zu können, füge bitte manuell Guthaben hinzu oder aktiviere die automatische Aufladung. Wenn das Guthaben dieser Nummern 0 erreicht, kannst du keine Nachrichten mehr innerhalb einer bezahlten Konversation senden.",
  [keys.BILL_automatic]: "Automatisch",
  [keys.BILL_manageFunds]: "Guthaben verwalten",
  [keys.BILL_refund]: "Erstattung",
  [keys.BILL_refundAccountTitle]: "Erstattungssaldo dieser Nummer",
  [keys.BILL_refundingDetails]: "Details zur Rückerstattung",
  [keys.BILL_channelFunds]: "Kanal-Guthaben",
  [keys.BILL_noPendingBalance]: "Kein ausstehendes Guthaben zur Rückerstattung",
  [keys.BILL_totalAmountToRefund]: "Zu erstattender Gesamtbetrag",
  [keys.BILL_refundInfomationText]:
    "Das Guthaben wird über deine Standardzahlungsmethode zurückerstattet.",
  [keys.BILL_refundInformationText2]:
    "Wenn du nach deiner letzten Guthabenaufladung keine bezahlte Konversation geführt und somit nichts von deinem Guthaben ausgegeben hast, umfasst die Rückerstattung auch die bezahlte Bearbeitungsgebühr. Andernfalls erhältst du eine Rückerstattung deines aktuellen Restguthabens ohne bezahlte Bearbeitungsgebühren.",
  [keys.BILL_fundsBalance]: "Guthaben",
  [keys.BILL_automaticAutoRenewal]: "Intelligente automatische Aufladung",
  [keys.BILL_descriptionText1]:
    "Wir freuen uns, dass du unsere neue Funktion so gut annimmst!",
  [keys.BILL_descriptionText2]:
    "Wir haben die intelligente Aufladung eingeführt, um dir zu helfen, deine Ausgaben genau zu kontrollieren.",
  [keys.BILL_descriptionText3]:
    "Auf der Grundlage deiner Konversationen aus dem Vormonat und den Kostentrends legen wir den Schwellenwert und den Betrag für deine Nummer fest:",
  [keys.BILL_threshold]: "Schwellenwert",
  [keys.BILL_autoMaticSettingMessage]:
    "Bitte beachte, dass diese Einstellungen variieren können, um sich an die Ausgaben deiner Nummer anzupassen.",
  [keys.BILL_setAutomaticRenewal]:
    "Intelligente Automatische Aufladung einstellen",
  [keys.BILL_automaticTooltip]:
    "Bei der intelligenten automatischen Aufladung werden der Schwellenwert und der Betrag automatisch auf der Grundlage der Konversationen des Vormonats und der Kostentrends festgelegt. Wir empfehlen dir, diese Funktion zu aktivieren, damit dir nie das Guthaben ausgeht.",
  [keys.BILL_enableAutoSettingsMessage]:
    "Intelligente automatische Aufladung erfolgreich aktiviert. Schwellenwert und Betrag werden automatisch berechnet.",
  [keys.BILL_disableAutoSettingsMessage]:
    "Einstellungen für automatische Aufladung erfolgreich deaktiviert für intelligente automatische Aufladung",
  [keys.BILL_AutoSettingsErrorMessage]:
    "Die Einstellungen für die automatische Aufladung konnten nicht geändert werden. Bitte versuche es erneut.",
  [keys.BILL_refundSuccessNotification]:
    "Deine Rückerstattung wurde erfolgreich eingeleitet! Dein Guthaben wird in Kürze entsprechend aktualisiert.",
  [keys.BILL_autoRenewalDisabled]: "Automatische Aufladung ausgeschalten",
  [keys.BILL_offOptionTooltip]:
    "Dein Guthaben wird nicht automatisch erneuert. Wenn dein Guthaben zur Neige geht, musst du es manuell aufladen, um weiterhin an bezahlten Konversationen teilnehmen zu können. Wir empfehlen, die automatische Aufladung zu aktivieren, damit dein Guthaben nie zur Neige geht.",
  [keys.BILL_manualOptionTooltip]:
    "Die automatische Aufladung ist aktiviert und lädt dein Guthaben automatisch auf der Grundlage eines Schwellenwerts und eines Aufladebetrags auf, den du manuell über die Optionen auf der rechten Seite einstellst.",
  [keys.BILL_smartOptionTooltip]:
    "Die automatische Aufladung ist aktiviert. Dein Schwellenwert und der Betrag werden automatisch berechnet und auf der Grundlage der Konversationen des Vormonats und der Kostenentwicklung angepasst.",
  [keys.BILL_paymentSucceeded]: "Zahlung erfolgreich",
  [keys.BILL_paymentFailed]:
    "Die Authentifizierung der angegebenen Zahlungsmethode ist fehlgeschlagen. Bitte gib eine neue Zahlungsmethode an, um erneut zu versuchen, die Zahlung durchzuführen",
  [keys.BILL_radioDisabledTooltipText]:
    'Um deine Einstellungen für die automatische Aufladung manuell anzupassen, musst du die automatische Aufladung auf "manuell" umstellen.',
  //  [keys.BILL_topupDurationMessage]: "translation_missing",
  [keys.BILL_billingOptions]: "Optionen für die Rechnungsstellung",
  [keys.BILL_fundNegativeBalance]:
    "Negativen Saldo für diesen Kanal ausgleichen",
  [keys.BILL_noRefundFunds]: "Kein Guthaben für Erstattung verfügbar",
  //  [keys.BILL_noAddingFunds]: "translation_missing",
  [keys.BILL_pendingBalanceBilling]:
    "Der ausstehende Negativsaldo wird deinem Konto in Rechnung gestellt.",
  [keys.BILL_refundAccount]: "Kontoguthaben zurückerstatten",
  [keys.BILL_negativeBalance]: "Konto mit negativem Saldo!",
  [keys.BILL_negativeBalanceMessage]:
    "Einige Kanäle haben einen negativen Saldo. Du kannst deinen Antrag auf Partnerwechsel einreichen, sobald dein negativer Saldo ausgeglichen ist. Bitte füge deinem Konto Guthaben hinzu.",
  [keys.BILL_totalPendingCharge]: "Gesamte ausstehende Abbuchung",
  [keys.BILL_addFundsProceed]: "Fortfahren, um Guthaben hinzuzufügen",
  [keys.BILL_clickToManageFunds]:
    "Klicke hier, um das Guthaben für diese Nummer zu verwalten",
  [keys.BILL_billingInformation]: "Informationen zur Abrechnung",
  [keys.BILL_billingInfoName]: "Name",
  [keys.BILL_billingInfoEmail]: "E-Mail",
  [keys.BILL_billingInfoAddress]: "Anschrift",
  [keys.BILL_billingInfoVatId]: "UMSATZSTEUER-ID",
  [keys.BILL_billingInfoEdit]: "Informationen bearbeiten",
  [keys.BILL_invoices]: "Rechnungen",
  [keys.BILL_payInvoice]: "Rechnung bezahlen",
  // [keys.BILL_unpaidInvoiceTooltip]: "translation_missing",
  [keys.BILL_invoiceViewMore]: "Mehr sehen",
  [keys.BILL_cloudApiPlan]: "Cloud-API-Tarif, mit dediziertem Support",
  [keys.BILL_partnerAccess]: "Tarif für Partnerzugang",
  [keys.BILL_userInitiatedConversations]:
    "Vom Benutzer initiierte und vom Unternehmen initiierte Konversationen. Kein Aufschlag.",
  [keys.BILL_withRegularSupport]: "mit regulärem Support",
  [keys.BILL_stripeBillingOptionsMissing]:
    "Stripe-Abrechnungsoptionen fehlen, kontaktiere den Support für Hilfe",
  [keys.BILL_requestRefund]: "Erstattung anfordern",
  [keys.BILL_fundsAmount]: "Guthabenbetrag",
  [keys.BILL_fundsAmountDescription]:
    "Wähle direkt den Betrag aus, den du auf dein Guthaben aufladen möchtest, oder die Anzahl der Konversationen, die du durchführen möchtest. Der gewählte Betrag wird sofort mit deinem aktuellen Guthaben verrechnet und angerechnet. Es handelt sich um eine einmalige Gebühr, die deine Einstellungen für die automatische Verlängerung nicht beeinflusst.",
  [keys.BILL_estimatedConversations]: "Geschätzte Konversationen",
  [keys.BILL_selectedFundsAmount]: "Geldbetrag (von dir ausgewählt)",
  [keys.BILL_costBreakdownDescriptionOneTime]:
    "Der gewählte Betrag wird sofort berechnet und auf dein aktuelles Guthaben angerechnet. Es handelt sich um eine einmalige Gebühr, die sich nicht auf deine Einstellungen für die automatische Verlängerung auswirkt.",
  //  [keys.BILL_costBreakdownDescriptionAutoRenewal]: translation_missing
  [keys.BILL_keepInMind]: "Denke daran:",
  [keys.BILL_keepInMindList1]:
    "Meta bietet 1.000 kostenlose Gespräche pro WABA und Monat.",
  [keys.BILL_keepInMindList2]:
    "Du kannst jederzeit eine Rückerstattung deines Guthabens beantragen.",
  [keys.BILL_breakdown]: "Aufschlüsselung",
  [keys.BILL_provideFundsAmount]: "Guthabenbetrag bereitstellen",
  [keys.BILL_allYourNumbersWillContinue]:
    "Alle deine Nummern werden weiterhin nach unseren Standardpreisen abgerechnet. Du kannst deinen Abonnementplan für jede einzelne Nummer jederzeit hoch- oder runterstufen.",
  [keys.BILL_allNumbersThatWereUpgraded]:
    "Alle Nummern, die zuvor auf einen Premium-Tarif hochgestuft wurden, werden weiterhin mit der Premium-Gebühr belastet.",
  [keys.BILL_total]: "Total",
  [keys.BILL_billingInformationDesc]:
    "Aktualisiere deine Zahlungsarten und prüfe deine Rechnungen oder lade sie herunter.",
  [keys.BILL_checkAndDownloadInvoices]:
    "Überprüfe deine Rechnungen oder lade sie herunter.",
  [keys.BILL_noInvoiceInfo]:
    "Es scheint, als hättest du noch keine Rechnungen.",
  [keys.BILL_currentBalance]: "Aktueller Kontostand",
  [keys.BILL_spentLastMonth]: "Ausgegeben im letzten Monat",
  [keys.BILL_fundsManager]: "Guthabenverwaltung",
  [keys.BILL_autoRenewalInfo]:
    "Die automatische Aufladung bestimmt deinen Schwellenwert und Betrag auf der Grundlage deiner Konversationen und Kostentrends des letzten Monats. Wir empfehlen dir, diese Funktion zu aktivieren, um sicherzustellen, dass dir nie das Geld ausgeht und du einen genauen Finanzierungssaldo hast.",
  [keys.BILL_setAutomaticFundingRenewal]: "Automatische Aufladung einstellen",
  [keys.BILL_billingInfoUpdated]: "Abrechnungsinformationen aktualisiert",
  [keys.BILL_noBillingInfoAddedInfo]:
    "Es scheint, als hättest du noch keine Rechnungsdetails hinzugefügt. Füge Rechnungsdetails hinzu, um Rechnungen zu erhalten.",
  [keys.BILL_addBillingDetails]: "Rechnungsdetails hinzufügen",
  [keys.BILL_paymentDetails]: "Zahlungsdetails",
  [keys.BILL_update]: "Aktualisieren",
  [keys.BILL_expire]: "Ablaufen",
  [keys.BILL_holder]: "Inhaber",
  [keys.BILL_noPaymentDetailsAddedInfo]:
    "Es scheint, als hättest du noch keine Zahlungsdetails hinzugefügt. Füge Zahlungsdetails hinzu, um mit dem Senden von Konversationen zu beginnen.",
  [keys.BILL_nextBillingInfo]:
    "Die Abrechnung erfolgt am Ersten eines jeden Monats. Nächste Abrechnung am",
  [keys.BILL_billingDetails]: "Abrechnungsdetails",
  [keys.BILL_billingDetailsFormDesc]: "Gib deine Rechnungsdetails unten ein.",
  [keys.BILL_payPendingInvoices]: "Ausstehende Rechnungen bezahlen",
  //  [keys.BILL_updateAutoRechargeTitle]: "translation_missing",
  //  [keys.BILL_updateAutoRechargeDesc]: "translation_missing",
  //  [keys.BILL_thresholdAmountErrorMessage]: "translation_missing",
  //  [keys.BILL_autoRenewalAmountDesc]: "translation_missing",
  //  [keys.BILL_thresholdAmountDesc]: "translation_missing",
  //  [keys.BILL_paymentInformation]: "translation_missing",
  //  [keys.BILL_changeMethod]: "translation_missing",
  //  [keys.BILL_addNewPaymentMethod]: "translation_missing",
  //  [keys.BILL_radioDisabledTooltipText]: "translation_missing",
  //  [keys.BILL_fundNegativeBalance]: "translation_missing",
  //  [keys.BILL_unpaidInvoice1]: "translation_missing",
  //  [keys.BILL_unpaidInvoice2]: "translation_missing",
};

export default translate;
