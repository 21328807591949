import React from "react";

import { UIModelTickets } from "@/services/api/jiraTicket/jiraTicket.model";
import { ListState } from "./listState";

const wabaSupportState = new ListState<UIModelTickets>({
  withoutAppId: true,
  limit: 20,
  offset: 0,
  sortFields: ["key"],
  apiDataInitial: {
    issues: [],
    total: 0,
  },
  filtersLoadSave: {},
});

export const WabaSupportState = React.createContext<ListState<UIModelTickets>>(
  {} as ListState<UIModelTickets>
);

const WabaSupportStateStoreProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <WabaSupportState.Provider value={wabaSupportState}>
      {children}
    </WabaSupportState.Provider>
  );
};

export default WabaSupportStateStoreProvider;
