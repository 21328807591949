// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.CH_showDetailed]: "Mostrar status detalhado",
  [keys.CH_hideDetailed]: "Ocultar status detalhado",
  [keys.CH_templatesButton]: "Gerenciar templates",
  [keys.CH_showDetailsButton]: "Mostrar detalhes",
  [keys.CH_mobButton]: "Requisição de Message on Behalf",
  [keys.CH_triggerButton]: "Registrar o número",
  [keys.CH_finishButton]: "Terminar registro",
  [keys.CH_keyButton]: "Criar API key",
  [keys.CH_addNumber]: "Adicionar um novo número de telefone",
  [keys.CH_exportClientData]: "Exportar dados de clientes",
  [keys.CH_exporting]: "Exportando...",
  [keys.CH_filterPlaceholder]: "todos os status",
  [keys.CH_details]: "Detalhes",
  [keys.CH_templates]: "Templates",
  [keys.CH_billingInfo]: "Informações de faturamento",
  [keys.CH_billingStartDate]: "O faturamento começou em (hora local)",
  [keys.CH_billingStartButtonLabel]: "Iniciar o faturamento",
  [keys.CH_numberReg]: "Registrar o número",
  [keys.CH_pin]: "Verificação de Pin",
  [keys.CH_defaultLang]: "Idioma padrão para os templates",
  [keys.CH_ivrEx]: "URA existente",
  [keys.CH_ivrAct]: "URA ativo",
  [keys.CH_verificationMethod]: "Método de verificação",
  [keys.CH_voice]: "Voz",
  [keys.CH_sms]: "SMS",
  [keys.CH_cert]: "Certificado",
  [keys.CH_consent]: "Status de permissão",
  [keys.CH_consentPage]: "Página de assinaturas de permissões",
  [keys.CH_displayNameErrorEmoji]: "emoji",
  [keys.CH_displayNameErrorIntro]: "Display Name não pode conter:",
  [keys.CH_displayNameErrorWhatsApp]: "'WhatsApp'",
  [keys.CH_displayNameErrorUrl]: "um endereço URL",
  [keys.CH_displayNameErrorPunct]: "pontuação",
  [keys.CH_displayNameErrorCap]:
    "O Display Name só pode ser capitalizado quando a empresa já utiliza a marca totalmente em letras maiúsculas",
  [keys.CH_displayNameErrorMin]:
    "O Display Name deve conter um mínimo de 3 caracteres",
  [keys.CH_whatsAppDisplayName]: "Display Name do WhatsApp",
  [keys.CH_confirmDisplayNameChange1]:
    "Seu Display Name será primeiro revisado pelo Meta antes de ser finalmente alterado, e poderá ser rejeitado se não seguir as diretrizes de Display Name do Meta. Você tem certeza de que deseja submeter este Display Name?",
  [keys.CH_confirmDisplayNameChange2]:
    "Por favor aguarde até 48 horas para que o seu display name seja avaliado pelo time de Trust & Safety do Meta.",
  [keys.CH_confirmDisplayNameChangeTooltip]:
    "Você pode mudar o Display Name até três vezes nos primeiros 30 dias após o perfil ter sido criado e o número ter sido ativado. Uma vez atingido esse limite, você deve esperar 30 dias antes de submeter novamente uma mudança de nome.",
  [keys.CH_confirmDisplayNameChange3]:
    "FAQ: Com qual frequência posso alterar o meu Display Name?",
  [keys.CH_confirmDisplayNameChange4]: "Favor confirmar esta ação",
  [keys.CH_numberReadyToRegister]:
    "Seu numero ainda não está registrado ao WhatsApp API, por favor prossiga com o registro para utilizar nossos serviços.",
  [keys.CH_pending]: "Pendente",
  [keys.CH_wabaChannelExtId]: "WABA Channel External ID",
  [keys.CH_wabaAccountName]: "Nome da conta de WhatsApp Business",
  [keys.CH_mobName]: "Nome de Message on Behalf",
  [keys.CH_timezone]: "ID do fuso horário",
  [keys.CH_wabaAccountId]: "ID da conta de WhatsApp Business",
  [keys.CH_namespace]: "Namespace",
  [keys.CH_dangerZone]: "Zona de Perigo",
  [keys.CH_deleteNumber]: "Deletar número",
  [keys.CH_deleteNumberText]:
    "Isto colocará o WhatsApp Business API client na fila para ser deletado. O número não será mais capaz de enviar ou receber mensagens usando a WhatsApp Business API. O número permanecerá visível na lista de números, mas não será faturado após a data de término.",
  [keys.CH_revoke]: "Revogar o pagamento em nome do seu cliente",
  [keys.CH_revokeText]:
    "Esta ação revogará seus pagamentos e solicitará ao cliente que acrescente suas próprias informações de pagamento a fim de continuar usando nosso produto.",
  [keys.CH_requestRevocationButton]: "Solicitar a revogação",
  [keys.CH_deleteSuccess]:
    "Recebemos seu pedido. Seu número será cancelado em breve.",
  [keys.CH_deletePopupText]:
    "Se você deseja prosseguir, por favor digite DELETAR no campo abaixo.",
  [keys.CH_confirmationFieldInputValue]: "DELETAR",
  [keys.CH_deletePopup1]:
    "Você tem certeza de que deseja excluir a conta WhatsApp Business API para o número de telefone <NUMBER>?",
  [keys.CH_detailsTab]: "Detalhes",
  [keys.CH_templatesTab]: "Templates",
  [keys.CH_wabaProfileTab]: "Perfil WABA",
  [keys.CH_profileTab]: "Perfil",
  [keys.CH_integrationTab]: "Integração",
  [keys.CH_commentsTab]: "Comentários",
  [keys.CH_listHeaderTitle]: "Conta de WhatsApp",
  [keys.CH_manageKeys]: "Gerenciar API keys",
  [keys.CH_manageKeysHelptext]:
    "Sua integração está funcionando e pode enviar e receber mensagens. Se você ainda não tiver criado uma API key, você pode criá-la agora através do botão abaixo.",
  [keys.CH_addNumberTitle]:
    "Conecte um novo número de telefone à conta WhatsApp Business",
  [keys.CH_addNumberHelptext]:
    "Este formulário pode ser usado para conectar um novo número de telefone à WhatsApp Business API. Ao clicar 'Salvar' você estará oficialmente enviando um novo número para a 360dialog e poderá ser cobrado de acordo.",
  [keys.CH_addNumberSelectHelptext]:
    "Por favor, selecione a conta WhatsApp Business que você quer usar:",
  [keys.CH_addNumberSuccess]:
    "Sucesso! O seu número foi adicionado e estará disponível em breve.",
  [keys.CH_addNumberEmbeddedTitle]: "Conecte um novo número de telefone",
  [keys.CH_addNumberEmbeddedHelptext]:
    "Este formulário pode ser usado para conectar um novo número de telefone à WhatsApp Business API. Ao continuar com o Meta Embedded Form você estará oficialmente enviando um novo número de telefone à 360dialog e poderá ser cobrado de acordo.",
  [keys.CH_addNumberSelectError]: "Por favor selecione a conta",
  [keys.CH_pendingDeletion]: "Aguardando exclusão",
  [keys.CH_pendingDeletionHelptext]:
    "Sua integração foi enviada para ser deletada e está pendente no momento. Este número não pode receber ou enviar mensagens no momento, até ser deletado em <date>. Até lá, você tem a possibilidade de reativar o seu número abaixo.",
  [keys.CH_reEnableButton]: "Reativar número",
  [keys.CH_revokeNumberDeletionSuccess]:
    "O seu número foi reativado com sucesso!",
  [keys.CH_emptyStateTitle]: "Você ainda não registrou uma conta WhatsApp.",
  [keys.CH_emptyStateHelptext]:
    "Para começar a enviar novas contas, é necessário que os seus clientes preencham o formulário individual. Todas as contas enviadas por esse formulário aparecerão aqui.",
  [keys.CH_emptyStateHelptextIO]:
    "Para começar a enviar contas, certifique-se de implementar a experiência Integrated Onboarding, e peça aos seus clientes para acionar o processo de configuração de dentro da sua aplicação.",
  [keys.CH_openSubmissionForm]: "Abrir formulário de novas contas",
  [keys.CH_openIODoc]: "Leia a documentação do Integrated Onboarding",
  [keys.CH_copyLink]: "Copiar link",
  [keys.CH_copiedLink]: "Link copiado",
  [keys.CH_emptyStateTitleClient]:
    "Parece que você ainda não terminou o onboarding inicial e por isso esta lista está vazia.",
  [keys.CH_emptyStateHelptextClient]:
    "Para acessar a WhatsApp Business API você precisa criar uma conta WhatsApp Business primeiro, o que é feito durante o nosso processo de onboarding. Por favor siga os passos necessários.",
  [keys.CH_emptyStateButtonLabelClient]: "Finalizar onboarding",
  [keys.CH_noChannelsFound]: "Sem resultados para essa busca.",
  [keys.CH_setupProgress]: "Progresso da configuração",
  [keys.CH_showMore]: "Mostre mais",
  [keys.CH_showLess]: "Mostre menos",
  [keys.CH_percentageDone]: "finalizado",
  [keys.CH_actionRequired]: "Requer ação",
  [keys.CH_noActionRequired]: "Nenhuma ação necessária",
  [keys.CH_setupNumberRunning]: "Número ativo",
  [keys.CH_setupVerificationStarted]: "Business verification iniciada",
  [keys.CH_setupVerificationCompleted]: "Business verification finalizada",
  [keys.CH_setupDisplayNameApproved]: "Display name aprovado",
  [keys.CH_setupCommercePolicyChecked]:
    "Conferência de Commerce policy finalizada",
  [keys.CH_ctaBusinessRejected]: "A verificação da sua empresa foi reprovada.",
  [keys.CH_ctaNumberSetup]:
    "O seu número está sendo configurado no momento. Isso demorará alguns minutos. Por favor atualize a página em cerca de 5 minutos.",
  [keys.CH_ctaStartVerification]:
    "Acesse o Meta Business Manager e comece a verificação do seu negócio.",
  [keys.CH_ctaVerificationPending]:
    "A verificação da sua empresa está pendente.",
  [keys.CH_ctaDisplayNameRejected]:
    "O seu <LINK>https://developers.facebook.com/docs/whatsapp/guides/display-name/ | display name<LINK> foi reprovado. Por favor, use o Gerenciador de WhatsApp do Meta ou entre em contato com nosso suporte para alterá-lo e reenviar.",
  [keys.CH_ctaButtonLabelOpenWaManager]: "Abrir Gerenciador do WhatsApp",
  [keys.CH_ctaLiveMode]:
    "Parabéns! Sua conta está ativa e você tem acesso total à WhatsApp Business API.",
  [keys.CH_ctaButtonLabelFbm]: "Abrir Meta Business Manager",
  [keys.CH_ctaButtonLabelStartVerification]: "Começar a verificação da empresa",
  [keys.CH_ctaButtonLabelOpenSettings]: "Abrir configurações",
  [keys.CH_ctaButtonLabelAppeal]: "Abrir formulário de appeal",
  [keys.CH_ctaCommercePolicyFailed]:
    "Sua empresa não foi aprovada na <LINK>https://www.whatsapp.com/legal/commerce-policy/?lang=en | Verificação de WhatsApp Commerce Policy<LINK>. Se você acredita que a sua empresa está de acordo com essas políticas, você pode começar um processo de appeal.",
  [keys.CH_ctaPendingDeletion]:
    "O seu número está pendente, aguardando ser deletado. Nenhuma ação extra é necessária.",
  [keys.CH_tooltipSandbox]:
    "Saiba mais sobre a experiência de unverified trial.",
  [keys.CH_wabaPhotoChanged]:
    "A foto da conta WhatsApp Business foi alterada com sucesso.",
  [keys.CH_wabaProfile]: "Perfil WhatsApp Business",
  [keys.CH_businessProfile]: "Perfil Business",
  [keys.CH_changeProfilePic]: "Alterar foto de perfil",
  [keys.CH_profilePic]: "Foto de perfil",
  //  [keys.CH_urlErrorMessage]: "translation_missing",
  [keys.CH_portNumberButtonLabel]: "Migrar número existente à 360dialog",
  [keys.CH_portNumberText]:
    "Este formulário pode ser usado para iniciar a migração de um número de telefone existente para sua conta 360dialog. Depois de fornecer o número e o destino da conta de WhatsApp Business (WABA), você será orientado durante o processo de migração. Ao continuar, você também reconhece que estará ativando oficialmente um novo número de telefone para a 360dialog, e poderá ser cobrado de acordo.",
  [keys.CH_createNewWaba]: "Criar uma nova conta comercial WhatsApp",
  [keys.CH_phoneNumberAlreadyExists]:
    "Este número de telefone já foi adicionado.",
  [keys.CH_migrateNumberNextStepText]:
    "Favor continuar o processo de portabilidade do número na nova aba, que deveria ter sido aberta. Caso contrário, por favor, verifique se seu navegador está bloqueado e <LINK>tente novamente<LINK>.",
  [keys.CH_readyForMigrationCtaText]:
    "Favor abrir a página de verificação e seguir os passos para finalizar a portabilidade de seu número.",
  [keys.CH_waitingForMigrationCtaText]:
    "A verificação do número ainda não foi concluída. Por favor, siga os passos na página de verificação para acionar novamente e enviar um código de verificação. Esta etapa é necessária para finalizar a portabilidade de seu número.",
  [keys.CH_invalidBusinessId]: "Esta ID do Meta Business Manager não é válida",
  [keys.CH_migratedNumberLabel]: "Número migrado",
  [keys.CH_type]: "Tipo",
  [keys.CH_retrySetupText]:
    "Sua integração ainda não começou. É possível que o 2FA ainda esteja ativo. Por favor, tente novamente.",
  [keys.CH_setTerminationDate]: "Definir data para exclusão",
  [keys.CH_successChannelAdded]: "Canal adicionado com sucesso",
  [keys.CH_addBusinessInfo]: "Adicionar Informações da Empresa",
  [keys.CH_businessInfoPrompt]:
    "Por favor adicione as Informações da Empresa no Meta Business Manager para ter acesso completo à API. Você já passou por todas as aprovações, mas precisa aumentar os limites de mensagem?",
  [keys.CH_startBusinessVerification]: "Começar Verificação da Empresa",
  [keys.CH_deletedChannel]: "Canal inativo",
  [keys.CH_deletedChannelHelptextShort]:
    "A integração foi deletada e este número não está mais ativo. Ele será deletado irrevogavelmente no dia <date>.",
  [keys.CH_deletedChannelHelptext]:
    "A integração foi deletada e este número não está mais ativo. Ele será deletado irrevogavelmente no dia <date>. Depois dessa data ele não estará mais visível no 360dialog Client Hub.",
  [keys.CH_reEnableHelptext]:
    "Se você quer restaurar o seu número, por favor entre em contato com <PARTNER>.",
  [keys.CH_partnerFallback]: "seu parceiro 360dialog",
  [keys.CH_recentSearches]: "Buscas recentes",
  [keys.CH_applyFilter]: "Aplicar filtro",
  [keys.CH_sortByDate]: "Organizado por data da última modificação",
  [keys.CH_searchBy]:
    "Buscar por número de telefone, Display Name ou nome da empresa",
  [keys.CH_filters]: "Filtros",
  [keys.CH_filterSingular]: "Filtro",
  [keys.CH_variableCountError]:
    "Você excedeu o número de variáveis permitidas.",
  [keys.CH_phoneNumber]: "Número de telefone",
  [keys.CH_clientEmail]: "Email do cliente",
  [keys.CH_submissionType]: "Tipo de submissão",
  [keys.CH_portedNumber]: "Número portado",
  [keys.CH_channelStatus]: "Status do canal",
  [keys.CH_pendingCancellation]: "Cancelamento pendente",
  [keys.CH_accountMode]: "Modo da conta",
  [keys.CH_errorNotEverythingLoaded]:
    "Nem todos os componentes da página foram carregados. Por favor, tente novamente. Se o problema continuar, por favor entre em contato com o nosso suporte.",
  [keys.CH_buttonLabelGotToSupport]: "Ir para a página de suporte",
  [keys.CH_emptyStateOption]: "Nenhum resultado para essa busca",
  [keys.CH_billingStatus]: "Status de cobrança",
  [keys.CH_cancelledOn]: "Cancelado em",
  [keys.CH_terminatedOn]: "Deletado em",
  [keys.CH_startedOn]: "Iniciado em",
  [keys.CH_notStarted]: "Ainda não foi iniciado",
  [keys.CH_compressedListView]: "Visualização comprimida da lista",
  [keys.CH_externalId]: "ID Externo",
  [keys.CH_status]: "Status",
  [keys.CH_deleteNumber]: "Cancelar inscrição",
  [keys.CH_deleteNumberText]:
    "Isso colocará o cliente da API do WhatsApp Business hospedado para exclusão em fila. Após a data de rescisão definida de acordo com os termos de cancelamento, o número não poderá mais enviar ou receber mensagens usando a API do WhatsApp Business. Observação: o número permanecerá visível na lista de números, mas você não será cobrado após a data de rescisão.",
  [keys.CH_deleteNumberTextTerms]:
    "Termos: A assinatura pode ser encerrada com trinta (30) dias de antecedência ao final de um mês.",
  [keys.CH_deletePopupText]:
    "Se desejar continuar, digite CANCELAR no campo abaixo.",
  [keys.CH_confirmationFieldInputValue]: "CANCELAR",
  [keys.CH_deletePopup1]:
    "Tem certeza de que deseja cancelar sua assinatura do cliente WhatsApp Business API para o número de telefone <NUMBER>?",
  [keys.CH_confirmDeleteNumberFBManagerText1]:
    "Cancelar sua assinatura colocará o número na fila para exclusão. Observe que não é uma exclusão instantânea. O status do número será alterado para “Exclusão pendente”, enquanto o número estiver nesse estágio, ele ainda poderá enviar e receber mensagens normalmente.",
  [keys.CH_confirmDeleteNumberFBManagerText2]:
    "O numero será permanentemente deletado no final deste mês. Nesse ponto o faturamento irá parar.",
  [keys.CH_confirmDeleteNumberFBManagerText3]:
    "Se você deseja que esse número seja excluído antes desse período, marque a caixa “Excluir imediatamente meu número no Gerenciador de Negócios do Meta” antes de confirmar.",
  [keys.CH_confirmDeleteNumberFBManager]:
    "Excluir imediatamente meu número no Gerenciador de Negócios do Meta. Esteja ciente de que esta ação criará automaticamente um ticket de suporte e levará à exclusão do número em aproximadamente 48 horas. Ele não estará mais disponível para uso com a API do WhatsApp Business. Isso não cancelará a obrigação de pagar as taxas de licença antes da data de sua exclusão completa de acordo com os Termos do 360dialog.",
  [keys.CH_deleteNumberSuccess]:
    "Seu número será removido do Gerenciador de Negócios do Meta (Meta Business Manager).",
  [keys.CH_messagingLimit]: "Limite de Mensagens",
  [keys.CH_learnHowDelete]: "Más información sobre cómo eliminar su número",
  [keys.CH_emailErrorMessage]: "Digite um endereço de e-mail válido",
  [keys.CH_partnerButtonDisabled]:
    "Indisponível para usuários com permissão de partner.",
  [keys.CH_usageAndBalance]: "Uso & Saldo",
  [keys.CH_prepaidSettings]: "Configurações de pré-pagamento",
  [keys.CH_lastRenewal]: "Última recarga",
  [keys.CH_usage]: "Uso",
  [keys.CH_paymentNotEnabled]: "Cliente não habilitado para pré-pagamento",
  [keys.CH_hasInboxTooltip1]:
    "Este número atualmente é usado em combinação com o inbox da get.chat. Portanto, você não pode gerar uma chave de API para ser utilizada com a API de WhatsApp da 360dialog. Entretanto, você pode usar as funcionalidades da API através da API de WhatsApp da get.chat.",
  [keys.CH_hasInboxTooltip2]:
    "Para desconectar seu inbox e criar uma chave de API da 360dialog, visite a página de detalhes pelo botão 'Mostrar detalhes'.",
  [keys.CH_hasInboxActionBlock1]:
    "Este número atualmente é usado em combinação com o inbox da get.chat. Portanto, não pode gerar uma chave de API para ser utilizada com a API de WhatsApp da 360dialog. Entretanto, você pode usar as funcionalidades da API através da API de WhatsApp da get.chat.",
  [keys.CH_hasInboxActionBlock2]:
    "Você pode desconectar seu inbox de get.chat e criar uma chave de API da 360dialog mais tarde.",
  [keys.CH_disconnectInboxButtonLabel]: "Desconectar inbox agora.",
  [keys.CH_disconnectNotificationSuccess]:
    "Seu inbox da get.chat foi desconectado deste número.",
  [keys.CH_disconnectNotificationError]:
    "Não conseguimos desconectar seu inbox da get.chat. Tente novamente mais tarde. Se este problema persistir, entre em contato com o time de suporte.",
  [keys.CH_openConsentPage]: "Abrir página de assinatura de permissões",
  [keys.CH_close]: "Fechar",
  [keys.CH_accountViolation]: "Violação de conta",
  [keys.CH_businessManagerDetails]:
    "Ver detalhes no Gerenciador de Negócio do Meta (Meta Business Manager)",
  [keys.CH_accountRestriction]: "Restrição de conta",
  [keys.CH_noInformationAvailable]: "Informação indisponível",
  [keys.CH_restrictionText]: "Sua conta foi restrita pela Meta.",
  [keys.CH_restrictionTextBusiness]:
    "Você não tem mais permissão para enviar mensagens iniciadas pela empresa.",
  [keys.CH_restrictionTextUser]:
    "Você não tem mais permissão para responder mensagens iniciadas pelo usuário.",
  [keys.CH_restrictionTextNumber]:
    "Você não tem mais permissão para adicionar novos números de telefone.",
  [keys.CH_clientName]: "Nome do cliente",
  [keys.CH_premiumPlanPricing]: "Preço do plano Premium",
  [keys.CH_standardPlanPricing]: "Preço do plano Standard",
  [keys.CH_upgrade]:
    "Ao seguir com os próximos passos, você fará upgrade do plano para o número <NUMBER>.",
  [keys.CH_downgrade]:
    "Se seguir para os próximos passos, fará o downgrade de plano para o número <NUMBER>.",
  [keys.CH_stantard]: "Standard",
  [keys.CH_premium]: "Premium",
  [keys.CH_month]: "Mês",
  [keys.CH_billedMonthly]: "Faturado mensalmente",
  [keys.CH_unlimitedIncomingMsg]:
    "Mensagens de entrada e saída de sessão de WhatsApp ilimitadas",
  [keys.CH_noMarkup]:
    "Conversas iniciadas pelo cliente e pela empresa de acordo com  <LINK>https://developers.facebook.com/docs/whatsapp/pricing | WhatsApp pricing<LINK>. Sem sobretaxa.",
  [keys.CH_98serviceLevel]: "Nível de Serviço de Hospedagem 98%",
  [keys.CH_99serviceLevel]: "Nível de Serviço de Hospedagem 99%",
  [keys.CH_premiumSupport]:
    "Suporte durante a semana inteira (24 horas Segunda - Sexta, 9am - 5pm CET Sábado e Domingo)",
  [keys.CH_standardSupport]:
    "Suporte em dias úteis (2am - 10pm CET Segunda - Sexta)",
  [keys.CH_premiumSLA]: "Até < 30min SLA de Suporte (para tickets elegíveis)",
  [keys.CH_standardSLA]: "Até < 4h SLA de Suporte (para tickets elegíveis)",
  [keys.CH_costBreakdown]: "Continuar para discriminação de custos",
  [keys.CH_description]: "Descrição",
  [keys.CH_qty]: "Qtd",
  [keys.CH_amount]: "Quantidade",
  [keys.CH_estimatedCost]:
    "Seus custos mensais estimados de assinatura, excluindo custos de conversação e os possíveis pagamentos de rateio para o mês corrente, serão os seguintes.",
  [keys.CH_unitPrice]: "Preço por unidade",
  [keys.CH_standardPricing]: "Preço Standard",
  [keys.CH_premiumPricing]: "Preço Premium",
  [keys.CH_premiumAccount]: "Conta Premium",
  [keys.CH_lastDowngradeTooltip1]:
    "Contas podem receber upgrade 30 dias depois do último downgrade. Seu último downgrade foi <DATE>.",
  [keys.CH_lastDowngradeTooltip2]:
    "O próximo upgrade pode ser acionado <DATE>.",
  [keys.CH_lastUpgradeTooltip1]:
    "Contas podem receber downgrade 30 dias depios do último upgrade. Se último upgrade foi <DATE>.",
  [keys.CH_lastUpgradeTooltip2]:
    "O próximo downgrade pode ser acionado <DATE>.",
  [keys.CH_reRegisterNumber]: "Re-registrar número",
  [keys.CH_reRegisterNumberText]:
    'Seu número foi desregistrado pelo Meta. Para continuar com nossos serviços de mensagens, você tem que registrá-lo novamente. Clique no botão "Re-registrar número" e siga o passo-a-passo.',
  [keys.CH_saveComment]: "Salvar comentário",
  [keys.CH_activityLogs]: "Logs de atividades",
  [keys.CH_admin]: "Administrador",
  [keys.CH_dateAndTime]: "Dia e horário",
  [keys.CH_actor]: "Usuario",
  [keys.CH_action]: "Ação",
  [keys.CH_businessAccount]: "Conta de negócios",
  [keys.CH_profilePreview]: "Pré-visualização do Perfil",
  [keys.CH_noLogsYet]: "Sem logs ainda",
  [keys.CH_noLogsYetDescription]: "Não há atualmente nenhum log a ser exibido.",
  [keys.CH_useFilteredData]: "Usar dados filtrados?",
  [keys.CH_selectDataset]:
    "Por favor, selecione se você quer baixar o conjunto de dados completo ou se os filtros selecionados devem ser aplicados ao conjunto de dados exportado.",
  [keys.CH_filteredExportTitle]: "Exportar dados filtrados",
  [keys.CH_filteredExport]: "Conjunto de dados filtrados para exportação",
  [keys.CH_fullExportTitle]: "Exportação total",
  [keys.CH_fullExport]: "Exportar conjunto de dados totais",
  [keys.CH_contactSupport]: "Entre em contato com nosso suporte",
  [keys.CH_contactSupportText]:
    "A configuração do seu número está inesperadamente lenta. Por favor entre em contato com nossa equipe de suporte para assistência.",
  [keys.CH_proceedWithRegistration]:
    "Seu número ainda não foi cadastrado na API do WhatsApp, por favor, prossiga com o cadastro para utilizar nossos serviços.",
  [keys.CH_readyToRegister]: "Pronto para registro",
  [keys.CH_addNumberDisabledTwoNumbers]:
    "No momento, você não pode adicionar mais números à sua conta. Se você precisar de mais números, entre em contato com nosso suporte.",
  [keys.CH_addNumberDisabledTwoNumbers2]:
    "Se você precisar de mais números, por gentileza entre em contato com seu partner de integração ou com o nosso suporte via hub.360dialog.com",
  [keys.CH_addNumberLimitExceeded]:
    "Atualmente, você não pode adicionar mais números à sua conta devido ao limite da conta. Se precisar de mais números, entre em contato com seu parceiro de integração ou com nosso suporte.",
  [keys.CH_lockedTitle]: "Sua conta está temporariamente bloqueada",
  [keys.CH_lockedText]:
    "Detectamos atividade suspeita em um dos seus números de telefone do WhatsApp e, portanto, bloqueamos sua conta temporariamente. Certifique-se de que pagou todas as suas faturas pendentes de taxas de assinatura, bem como custos de conversação para continuar a enviar mensagens. Se você acha que isso está errado e deseja que sua conta seja ativada novamente, entre em contato com nosso suporte.",
  [keys.CH_ctaPendingReview]:
    "Sua conta está sendo analisada por nossa equipe no momento.  Isto não deve levar mais de 2 horas. Até que o processo de revisão seja concluído, você está impedido de enviar qualquer template. Você ainda pode receber mensagens e responder a conversas iniciadas pelo usuário.",
  [keys.CH_apiKeyDisabledMessage]:
    'As chaves API estão desabilitadas para este número. O número foi compartilhado com o seu parceiro de integração. Com sua permissão, o parceiro tem acesso ao WhatsApp Business API em seu nome. Para gerenciar as permissões, vá para "Configurações da organização" ou entre em contato com seu parceiro.',
  [keys.CH_emptyStateHelptextClientIO]:
    "Para acessar a WhatsApp Business API você precisa primeiro criar uma conta WhatsApp Business. Por favor, continue o processo de configuração através do aplicativo de seu partner.",
  [keys.CH_successfullyAddedNumber]:
    "Seu número foi adicionado com sucesso. Nós estamos configurando-o e ele estará visível nesta página em alguns minutos. Você será notificado aqui e via e-mail.",
  [keys.CH_numberNowLive]: "Seu número agora se encontra ativo",
  [keys.CH_generateApiKeyNow]: "Gerar chave API agora",
  [keys.CH_settingUpNumber]:
    "Estamos atualmente configurando o seu número e isso pode levar alguns minutos. Você será notificado por e-mail quando o seu número estiver completamente configurado. Depois disso, por favor, continue o registro do número.",
  [keys.CH_accountDeletionCheckbox]:
    "Eu entendo que minha conta será excluída em <DATE> e eu serei cobrado até essa data.",
  [keys.CH_deleteAccount]: "Deletar conta",
  [keys.CH_dataNotAccessible]:
    "Os dados dessa página não estão acessiveis para seu usuario",
  [keys.CH_addNumberDisabledSandbox]:
    "Você atingiu sua quantidade máxima de 2 números de sandbox. Para adicionar novos números, certifique-se de prosseguir para o modo live com seus números existentes.",
  [keys.CH_hostingPlatformType]: "Tipo de Plataforma de Hospedagem",
  [keys.CH_aboutTextRequired]: "O campo Sobre é obrigatório",
  [keys.CH_synced]: "Sincronizado",
  [keys.CH_wabaType]: "Tipo de conta do WhatsApp Business",
  [keys.CH_disableTracker]: "Desativar o rastreador CTWA",
  [keys.CH_trackerNotificationContent]:
    "Se você desativar o rastreador Click To WhatsApp, todos os dados coletados ainda estarão visíveis, mas os eventos rastreados serão arquivados automaticamente.",
  [keys.CH_trackingBannerContent]:
    "Você tem o rastreador Click To WhatsApp desativado para esse número. Ative a ferramenta para que você possa continuar coletando informações de suas conversas!",
  [keys.CH_deleteTitle]:
    "Se você deseja cancelar sua assinatura do número de telefone <NÚMERO>, digite CANCEL neste campo abaixo.",
  [keys.CH_goBack]: "Voltar",
  [keys.CH_activateInbox]: "Ativar Inbox",
  [keys.CH_verifyBusiness]: "Verificar Meta Business",
  [keys.CH_contactPartner]:
    "Entre em contato com o seu Partner para adicionar ou transferir o número de telefone",
  //  [keys.CH_cancelPayment]: "translation_missing",
  //  [keys.CH_cancelPaymentInfo]: "translation_missing",
  //  [keys.CH_pleaseLimitDescription]: "translation_missing",
  //  [keys.CH_addNumberDisabledTwoNumbersAlt]: "translation_missing",
  //  [keys.CH_currentPlanNotSupportChangingHostingPlatform]: "translation_missing",
  //  [keys.CH_pleaseBeAwareMigrationBack]: "translation_missing",
  //  [keys.CH_signalDisabledMsg]: "translation_missing",
  //  [keys.CH_inboxDisabledMsg]: "translation_missing",
  [keys.CH_partnerApiKeyPermission]: "Permissão de chave API do partner",
  //  [keys.CH_subscriptionPlan]: "translation_missing",
  //  [keys.CH_changePlan]: "translation_missing",
  //  [keys.CH_downgradeScheduleInfo]: "translation_missing",
  //  [keys.CH_upgradeScheduleInfo]: "translation_missing",
  //  [keys.CH_subscriptionSuccessful]: "translation_missing",
  //  [keys.CH_selectSubscription]: "translation_missing",
  //  [keys.CH_subscriptionSelectionInfo]: "translation_missing",
  //  [keys.CH_savingPlan]: "translation_missing",
  //  [keys.CH_savePlan]: "translation_missing",
  //  [keys.CH_downgradeDisabledTooltip]: "translation_missing",
  //  [keys.CH_pendingClientApprovalInfo]: "translation_missing",
  //  [keys.CH_verifyOwnership]: "translation_missing",
  //  [keys.CH_pendingClientApprovalInfoNoClientInfo]: "translation_missing",
  //  [keys.CH_viewDocumentation]: "translation_missing",
  //  [keys.CH_pendingClientApprovalInfoWithClientInfo]: "translation_missing",
  //  [keys.CH_clientUserApproved]: "translation_missing",
  //  [keys.CH_channelVerified]: "translation_missing",
  [keys.CH_displayName]: "Nome de exibição",
  [keys.CH_qualityRating]: "Class. de qualidade",
  [keys.CH_manage]: "Gerencie",
  [keys.CH_actions]: "Ações",
  //  [keys.CH_wabaWebhookUrlError]: "translation_missing",
  //  [keys.CH_hostingType]: "translation_missing",
  //  [keys.CH_partnerPermitted]: "translation_missing",
  //  [keys.CH_partnerNotPermitted]: "translation_missing",
  //  [keys.CH_missing]: "translation_missing",
  //  [keys.CH_shared]: "translation_missing",
  //  [keys.CH_managePermissions]: "translation_missing",
  [keys.CH_planRecommended]: "Recomendado",
  [keys.CH_planBasic]: "Básico",
  [keys.CH_planRegular]: "Regular",
  [keys.CH_planPremium]: "Premium",
  [keys.CH_planMonth]: "mês",
  [keys.CH_planIncludes]: "Inclui",
  [keys.CH_planPlus]: "mais",
  [keys.CH_planCloudHosting]: "Hospedagem Cloud API",
  [keys.CH_planNinetyServiceLevel]: "Nível de Serviço de Hospedagem de 90%",
  [keys.CH_planTicketBased]:
    "Suporte baseado em tickets com tempo de resposta de 24 horas.",
  [keys.CH_planOnPremiseHosting]: "Hospedagem On-premise",
  [keys.CH_planWabaOfficial]: "Pedido de Conta Oficial Business no WhatsApp.",
  [keys.CH_planHighServiceLevel]: ">99% Nível de Serviço de Hospedagem",
  [keys.CH_planIncludesAdsFunnel]: "Inclui tracking de Anúncios & Funis",
  [keys.CH_planSelect]: "Selecione",
  [keys.CH_planSelected]: "Selecionado",
  [keys.CH_active]: "Ativo",
  [keys.CH_manageWaba]: "Gerencia sua conta Whatsapp Business",
  [keys.CH_numberDetails]: "Detalhes",
  [keys.CH_numberBusinessAccount]: "Conta Business",
  [keys.CH_funds]: "Fundos",
  [keys.CH_plan]: "Planos",
  [keys.CH_accountName]: "Nome da conta",
  [keys.CH_dataRegions]: "Região de armazenamento de dados",
  [keys.CH_numberQuality]: "Classificação de qualidade",
  [keys.CH_numberMessagingLimit]: "Limite de mensagens",
  [keys.CH_low]: "Baixo",
  [keys.CH_medium]: "Médio",
  [keys.CH_high]: "Alto",
  [keys.CH_numberPending]: "Pendente",
  [keys.CH_unlimited]: "Ilimitado",
  [keys.CH_newConversationsPerDay]: "Novas conversas por dia",
  [keys.CH_onlyAvailableCloudNumbers]: "",
  [keys.CH_qualityRatingTooltipOne]:
    "Sua avaliação de qualidade é baseada em como as mensagens foram recebidas pelos destinatários nos últimos sete dias e é ponderada pela recenticidade. ",
  [keys.CH_qualityRatingTooltipTwo]:
    "É determinada por uma combinação de sinais de qualidade de conversas entre empresas e usuários. Exemplos incluem sinais de feedback do usuário, como bloqueios, relatórios e as razões que os usuários fornecem quando bloqueiam uma empresa.",
  [keys.CH_qualityRatingTooltipStatus]:
    "A Avaliação de Qualidade pode ter os seguintes status",
  [keys.CH_qualityRatingTooltipPending]:
    "<b>Ativo - Qualidade pendente</b> O modelo de mensagem ainda não recebeu feedback de qualidade dos clientes. Modelos de mensagem com este status podem ser enviados aos clientes e serão monitorados para avaliação.",
  [keys.CH_qualityRatingTooltipHigh]:
    "<b>Ativo - Alta Qualidade</b> O modelo recebeu pouco ou nenhum feedback negativo dos clientes. Modelos de mensagem com este status podem ser enviados aos clientes.",
  [keys.CH_qualityRatingTooltipMedium]:
    "<b>Ativo - Qualidade Média</b> O modelo recebeu feedback negativo de múltiplos clientes, mas pode logo se tornar pausado ou desativado. Modelos de mensagem com este status podem ser enviados aos clientes.",
  [keys.CH_qualityRatingTooltipLow]:
    "<b>Ativo - Baixa Qualidade</b> O modelo recebeu feedback negativo de múltiplos clientes. Modelos de mensagem com este status podem ser enviados aos clientes, mas estão em perigo de serem pausados ou desativados em breve, portanto é recomendável que você aborde os problemas que os clientes estão relatando.",
  [keys.CH_messagingLimitTooltip]:
    "O Limite de Mensagens determina o número máximo de conversas iniciadas por empresas que cada número de telefone pode iniciar em um período de 24 horas contínuo.",
  [keys.CH_messagingLimitTooltipLimits]:
    "Limites de conversas iniciadas por empresas são: <b>250</b> · <b>1K</b> · <b>10K</b> · <b>100K</b>",
  [keys.CH_numberQualityRating]: "Classificação de qualidade do número",
  [keys.CH_learnMore]: "Aprenda mais",
  [keys.CH_numberMessagingLimitTitle]: "Limite de mensagens por número",
  [keys.CH_seeMore]: "Ver mais",
};

export default translate;
