// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.NAV_whatsappAccounts]: "Cuentas de WhatsApp",
  [keys.NAV_partners]: "Partners",
  [keys.NAV_integrations]: "Integraciones",
  [keys.NAV_support]: "Soporte",
  [keys.NAV_profile]: "Perfil",
  [keys.NAV_billing]: "Opciones de facturación",
  [keys.NAV_helpAndFeedback]: "Ayuda y Feedback",
  [keys.NAV_preferences]: "Ajustes",
  [keys.NAV_signOut]: "Cerrar sesión",
  [keys.NAV_dock]: "Fijar Menú",
  [keys.NAV_undock]: "Ocultar Menú",
  [keys.NAV_templateBilling]: "Facturación de Templates",
  [keys.NAV_conversationBilling]: "Facturación de Conversas",
  [keys.NAV_billingText]: "Facturación",
  [keys.NAV_insights]: "Insights",
  [keys.NAV_insightsAndBilling]: "Insights & Billing",
  [keys.NAV_organizationSettings]: "Configuración de la organización",
  [keys.NAV_connectedApps]: "Apps conectadas",
  [keys.NAV_notifications]: "Notificaciones",
  [keys.NAV_logout]: "Cerrar sesión",
  [keys.NAV_gettingStarted]: "Empezando",
  [keys.NAV_roadmap]: "Roadmap",
  //  [keys.NAV_billinOptions]: "translation_missing",
  [keys.NAV_company]: "Empresa",
  //  [keys.NAV_adAccounts]: "translation_missing",
  //  [keys.NAV_pageUnavailable]: "translation_missing",
  [keys.NAV_team]: "Equipo",
  // [keys.NAV_numberAccess]: "translation_missing",
};

export default translate;
