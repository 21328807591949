import axios, { AxiosInstance } from "axios";
import {
  beforeRequestHandler,
  requestErrorHandler,
} from "./HandleGlobalInterceptors";
import "../../../utilities/variables";

const isProduction = ["production", "rc"].includes(
  process.env.NEXT_PUBLIC_APP_ENV || ""
);

const adsUrlV1 = isProduction
  ? "https://api.signals.360dialog.io/v1"
  : "https://api.insights-staging.360dialog.io/v1";

const adsUrlV2 = isProduction
  ? "https://api.signals.360dialog.io/v2"
  : "https://api.insights-staging.360dialog.io/v2";

const instanceV1 = axios.create({ baseURL: adsUrlV1 });
const instanceV2 = axios.create({ baseURL: adsUrlV2 });

class APIInstanceAds {
  private instance: AxiosInstance;

  constructor(instance: AxiosInstance) {
    this.instance = instance;
    this.registerGlobalRequestInterceptors();
  }

  public get buffedInstances() {
    return this.instance;
  }

  public setAuthorizationToken(token: string) {
    try {
      this.instance.defaults.headers["Authorization"] = `Bearer ${token}`;
    } catch (error) {
      console.error(`Failed to set authorization token: ${error}`);
    }
  }

  private registerGlobalRequestInterceptors = () => {
    this.instance.interceptors.request.use(
      beforeRequestHandler,
      requestErrorHandler
    );
    this.instance.interceptors.response.use(null, requestErrorHandler);
  };
}

export const MPInstanceAds = new APIInstanceAds(instanceV1);
export const MPInstanceAdsV2 = new APIInstanceAds(instanceV2);
