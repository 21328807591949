import { observable, makeObservable, action } from "mobx";
import i18n from "@/services/store/i18n";
import LanguageDetector from "i18next-browser-languagedetector";
import { UIModelPartnerChannel } from "@/services/api/partnerChannels/partnerChannels.model";
import {
  UIModelPartner,
  UIModelPartnerPaymentInfo,
} from "@/services/api/partners/partners.model";
import { UIModelSingleClient } from "@/services/api/clients/clients.model";
import {
  IClientPaymentInfo,
  IUIResponsePaymentCheck,
  IClientPaymentMethod,
  IClientAddPaymentMethod,
  TBundleTier,
} from "@/services/api/clients/clients.types";
import { ITransformedChannel } from "@/shared/ui.types";
import { IMappedAdAccount } from "@/services/api/insights/insights.types";
import { IExtendedServerResponseClientInfo } from "@/services/api/admin/profile.types";

const allowLanguage = ["en", "ru", "pt", "de"];

class AdminState {
  currentUser: null | IExtendedServerResponseClientInfo = null;
  currentAppId: null | string = null;
  currentLanguage: string;
  paymentInfo: null | IClientPaymentInfo = null;
  paymentMethodData: null | IClientAddPaymentMethod = null;
  pbsPaymentInfo: IUIResponsePaymentCheck | null = null;
  channel: null | ITransformedChannel = null;
  isFetchingChannels = false;
  channels: null | UIModelPartnerChannel[] = null;
  transformedChannels: null | ITransformedChannel[] = null;
  currentPartner: null | UIModelPartner = null;
  currentClient: null | UIModelSingleClient = null;
  partnerPaymentInfo: null | UIModelPartnerPaymentInfo = null;
  paymentMethods: null | IClientPaymentMethod[] = null;
  channelIdForMigration: null | string = null;
  pendingMigration: null | boolean = false;
  pbsBundleId: number = undefined;
  selectedPlan: TBundleTier = undefined;
  selectedPlanPrice: string = null;
  didInitPageLoad = false;
  checkedToken = false;
  costPerLeads: Record<string, any> = {};
  investedValues: Record<string, any> = {};
  hasInsightAccess = null;
  adAccounts: IMappedAdAccount[] = [];
  isNumberConnectedToWABA: boolean = undefined;
  isNumberConnectedToBusinessApp: boolean = undefined;

  setChannelIdForMigration = (channelIdForMigration: string) => {
    this.channelIdForMigration = channelIdForMigration;
  };

  setPendingMigration = (pendingMigration: boolean) => {
    this.pendingMigration = pendingMigration;
  };

  setCurrentClient = (client: UIModelSingleClient) => {
    this.currentClient = client;
  };

  setCurrentAppId = (currentAppId: string) => {
    this.currentAppId = currentAppId;
  };

  reset = () => {
    this.currentUser = null;
    this.currentAppId = null;
    this.paymentInfo = null;
    this.paymentMethodData = null;
    this.pbsPaymentInfo = null;
    this.channels = null;
    this.channel = null;
    this.channelIdForMigration = null;
    this.currentPartner = null;
    this.currentClient = null;
    this.partnerPaymentInfo = null;
    this.paymentMethods = null;
    this.channelIdForMigration = null;
    this.pendingMigration = false;
    this.pbsBundleId = undefined;
    this.selectedPlan = undefined;
    this.selectedPlanPrice = null;
    this.didInitPageLoad = false;
    this.checkedToken = false;
    this.costPerLeads = {};
    this.investedValues = {};
    this.hasInsightAccess = null;
    this.adAccounts = [];
    this.isNumberConnectedToWABA = undefined;
    this.isNumberConnectedToBusinessApp = undefined;
  };

  destroyLoggedUser = () => {
    this.reset();
  };

  setCurrentUser = (user) => {
    this.currentUser = {
      ...user,
      isClient: user.roles.includes("client-owner"),
      isPartner: user.roles.includes("partner-owner"),
      isAdmin: user.roles.includes("admin"),
    };
  };

  setCurrentPartner = (partner) => {
    this.currentPartner = partner;
  };

  setChannel = (channel: ITransformedChannel) => {
    this.channel = channel;
  };

  setIsFetchingChannels = (isFetchingChannels: boolean) => {
    this.isFetchingChannels = isFetchingChannels;
  };

  setChannels = (channels: UIModelPartnerChannel[]) => {
    this.channels = channels;
  };

  removeChannels = () => {
    this.channels = null;
  };

  setTransformedChannels = (transformedChannels: ITransformedChannel[]) => {
    this.transformedChannels = transformedChannels;
  };

  removeTransformedChannels = () => {
    this.transformedChannels = null;
  };

  get getCurrentUser() {
    if (this.currentUser) {
      return this.currentUser;
    } else {
      throw new Error("Can not get user");
    }
  }

  constructor() {
    if (i18n.language) {
      this.currentLanguage = allowLanguage.includes(i18n.language)
        ? i18n.language
        : "en";
    } else {
      const languageDetector = new LanguageDetector();

      // this.setLanguage(detectBrowserLanguage);
      /**
       * TODO: remove detect-browser-language from packages if modified implementation works
       */
      this.setLanguage(languageDetector.detect());
    }
    makeObservable(this, {
      currentLanguage: observable,
      currentUser: observable,
      paymentInfo: observable,
      paymentMethodData: observable,
      paymentMethods: observable,
      pbsPaymentInfo: observable,
      setCurrentUser: action,
      setCurrentPartner: action,
      channel: observable,
      isFetchingChannels: observable,
      setIsFetchingChannels: action,
      channels: observable,
      removeChannels: action,
      transformedChannels: observable,
      setTransformedChannels: action,
      removeTransformedChannels: action,
      currentPartner: observable,
      currentAppId: observable,
      setCurrentAppId: action,
      currentClient: observable,
      partnerPaymentInfo: observable,
      setCurrentClient: action,
      channelIdForMigration: observable,
      setChannelIdForMigration: action,
      pendingMigration: observable,
      setPendingMigration: action,
      pbsBundleId: observable,
      selectedPlan: observable,
      selectedPlanPrice: observable,
      didInitPageLoad: observable,
      checkedToken: observable,
      costPerLeads: observable,
      investedValues: observable,
      hasInsightAccess: observable,
      setLanguage: action,
      adAccounts: observable,
      isNumberConnectedToWABA: observable,
      isNumberConnectedToBusinessApp: observable,
    });
  }

  setLanguage = (language: string | string[]) => {
    this.currentLanguage =
      typeof language === "string" ? language : language[0];
    i18n.changeLanguage(this.currentLanguage);
  };
}

export default AdminState;
