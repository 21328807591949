// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.KEY_existingTitle]: "Existing API key",
  [keys.KEY_existingHelp1]:
    "There is already an active API key existing. Generating a new API key will overwrite the current API key and delete the current webhook destination.",
  [keys.KEY_existingHelp2]:
    "By continuing you will need to provide the newly generated API key to the application you are going to use.",
  [keys.KEY_newTitle]: "Your API key",
  [keys.KEY_newHelp]:
    "Please copy your new API key and store it in a secure place. After you close this window, the API Key will not be accessible to you or 360dialog anymore.",
  [keys.KEY_key]: "API key:",
  [keys.KEY_copy]: "Copy",
  [keys.KEY_copied]: "Copied",
  [keys.KEY_address]: "Address:",
  [keys.KEY_confirmCopy]: "I've copied and stored the API key",
  [keys.KEY_partnerInfoText]:
    "Click here to place your API key within your <PARTNER> application.",
  [keys.KEY_partnerInfoButtonLabel]: "Continue to <PARTNER>",
  [keys.KEY_generateKeyIntro]:
    "Generate an API key to use in 3rd party apps to connect to the WhatsApp Business API.",
};

export default translate;
