// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.CH_showDetailed]: "Показать подробный статус",
  [keys.CH_hideDetailed]: "Скрыть подробный статус",
  [keys.CH_templatesButton]: "Управление шаблонами",
  [keys.CH_showDetailsButton]: "Показать подробности",
  [keys.CH_mobButton]:
    "Запрос на отправку сообщений от имени бизнеса (Message on behalf)",
  [keys.CH_triggerButton]: "Инициировать регистрацию",
  [keys.CH_finishButton]: "Завершить регистрацию",
  [keys.CH_keyButton]: "Сгенерировать API ключ",
  [keys.CH_addNumber]: "Добавить новый номер телефона",
  [keys.CH_exportClientData]: "Экспорт клиентских данных",
  [keys.CH_exporting]: "Экспортирование ...",
  [keys.CH_filterPlaceholder]: "все статусы",
  [keys.CH_details]: "Подробности",
  [keys.CH_templates]: "Шаблоны",
  [keys.CH_billingInfo]: "Платежная информация",
  [keys.CH_billingStartDate]: "Время начала оплаты (местное время)",
  [keys.CH_billingStartButtonLabel]: "Начать тарификацию",
  [keys.CH_numberReg]: "Регистрация номера",
  [keys.CH_pin]: "Проверка pin-кода",
  [keys.CH_defaultLang]: "Язык шаблонов по умолчанию",
  [keys.CH_ivrEx]: "Наличие IVR",
  [keys.CH_ivrAct]: "Активный IVR",
  [keys.CH_verificationMethod]: "Способ проверки",
  [keys.CH_voice]: "Голосовой вызов",
  [keys.CH_sms]: "SMS",
  [keys.CH_cert]: "Сертификат",
  [keys.CH_consent]: "Статус соглашения",
  [keys.CH_consentPage]: "Страница подтвердждения согласия",
  [keys.CH_displayNameErrorEmoji]: "эмодзи",
  [keys.CH_displayNameErrorIntro]: "Отображаемое имя не должно включать:",
  [keys.CH_displayNameErrorWhatsApp]: '"WhatsApp"',
  [keys.CH_displayNameErrorUrl]: "URL-адрес",
  [keys.CH_displayNameErrorPunct]: "пунктуацию",
  [keys.CH_displayNameErrorCap]:
    "Отображаемое имя может содержать заглавные буквы только в том случае, если компания уже использует брендинг с заглавными буквами",
  [keys.CH_displayNameErrorMin]:
    "Отображаемое имя должно содержать не менее 3 символов",
  [keys.CH_whatsAppDisplayName]: "Отображаемое имя WhatsApp",
  [keys.CH_confirmDisplayNameChange1]:
    "Ваше новое отображаемое имя сначала будет рассмотрено Meta, прежде чем оно будет окончательно изменено, и может быть отклонено, если оно не соответствует рекомендациям Meta по отображаемому имени. Вы уверены, что хотите отправить это отображаемое имя?",
  [keys.CH_confirmDisplayNameChange2]:
    "Проверка нового отображаемого имени специалистами Meta может занять до 48 часов. Пожалуйста, подождите.",
  [keys.CH_confirmDisplayNameChangeTooltip]:
    "Вы можете изменить отображаемое имя до трех раз в течение первых 30 дней после создания профиля и активации номера. При достижении этого предела вы должны будете подождать 30 дней, прежде чем снова подавать заявку на изменение имени.",
  [keys.CH_confirmDisplayNameChange3]:
    "FAQ: Как часто я могу менять свое отображаемое имя?",
  [keys.CH_confirmDisplayNameChange4]: "Пожалуйста, подтвердите это действие",
  [keys.CH_numberReadyToRegister]:
    "Ваш номер еще не зарегистрирован в WhatsApp API, пожалуйста, пройдите регистрацию, чтобы пользоваться нашими услугами.",
  [keys.CH_pending]: "Обрабатывается",
  [keys.CH_wabaChannelExtId]: "Внешний идентификатор канала WABA (External ID)",
  [keys.CH_wabaAccountName]: "Имя бизнес-аккаунта WhatsApp",
  [keys.CH_mobName]: "Имя Message on Behalf",
  [keys.CH_timezone]: "Идентификатор часового пояса",
  [keys.CH_wabaAccountId]: "Идентификатор бизнес-аккаунта WhatsApp",
  [keys.CH_namespace]: "Namespace",
  [keys.CH_dangerZone]: "Зона риска",
  [keys.CH_deleteNumber]: "Удалить номер",
  [keys.CH_deleteNumberText]:
    "Это действие поставит размещенный аккаунт WhatsApp Business API в очередь на удаление. Этот номер больше не сможет отправлять или получать сообщения с помощью WhatsApp Business API. Примечание: номер останется видимым в списке номеров в 360dialog Hub, но счета после даты удаления выставляться не будут.",
  [keys.CH_revoke]: "Отменить платеж от имени парнера",
  [keys.CH_revokeText]:
    "Это действие приведет к отмене ваших платежей и предложит клиенту добавить свою собственную платежную информацию, чтобы продолжить использование нашего продукта.",
  [keys.CH_requestRevocationButton]: "Запрос отмены платежей",
  [keys.CH_deleteSuccess]:
    "Мы получили ваш запрос. Ваш номер будет удален в ближайшее время.",
  [keys.CH_deletePopupText]:
    "Если вы хотите продолжить, пожалуйста, введите УДАЛИТЬ в поле ниже.",
  [keys.CH_confirmationFieldInputValue]: "УДАЛИТЬ",
  [keys.CH_deletePopup1]:
    "Вы уверены, что хотите удалить клиент WhatsApp Business API для телефонного номера <NUMBER>?",
  [keys.CH_detailsTab]: "Подробности",
  [keys.CH_templatesTab]: "Шаблоны",
  [keys.CH_wabaProfileTab]: "Профиль WABA",
  [keys.CH_profileTab]: "Профиль",
  [keys.CH_integrationTab]: "Интеграция",
  [keys.CH_commentsTab]: "Комментарии",
  [keys.CH_listHeaderTitle]: "Аккаунт WhatsApp",
  [keys.CH_manageKeys]: "Управление API ключами",
  [keys.CH_manageKeysHelptext]:
    "Ваша интеграция запущена, и вы можете отправлять и получать сообщения. Если вы еще не создали API ключ, вы можете сделать его сейчас с помощью кнопки ниже.",
  [keys.CH_addNumberTitle]:
    "Подключите новый номер телефона к WhatsApp Business API",
  [keys.CH_addNumberHelptext]:
    'Эта форма предназначена для подключения нового телефонного номера к WhatsApp Business API. Нажав "Сохранить", вы подтверждите отправку нового номера телефона в 360dialog, и с вас может взиматься соответствующая плата.',
  [keys.CH_addNumberSelectHelptext]:
    "Пожалуйста, выберите бизнес-аккаунт WhatsApp, который вы хотите использовать:",
  [keys.CH_addNumberSuccess]:
    "Поздравляем! Ваш номер был успешно добавлен и будет доступен через несколько минут.",
  [keys.CH_addNumberEmbeddedTitle]: "Подключить новый номер телефона",
  [keys.CH_addNumberEmbeddedHelptext]:
    "Эта форма предназначена для подключения нового телефонного номера к WhatsApp Business API. Продолжая заполнение формы встроенной регистрации Meta (Embedded Signup), вы подвтержаете свое намерение отправить новый номер телефона в 360dialog, и с вас может взиматься соответствующая плата.",
  [keys.CH_addNumberSelectError]:
    "Пожалуйста, выберите соответствующий аккаунт",
  [keys.CH_pendingDeletion]: "Ожидание удаления",
  [keys.CH_pendingDeletionHelptext]:
    "Ваша интеграция была отправлена на удаление и в настоящее время находится на рассмотрении. Этот номер все еще может принимать и отправлять сообщения, пока он не будет окончательно удален в <date>. До тех пор у вас есть возможность повторно активировать свой номер ниже.",
  [keys.CH_reEnableButton]: "Повторно активировать номер",
  [keys.CH_revokeNumberDeletionSuccess]:
    "Ваш номер был успешно активирован повторно!",
  [keys.CH_emptyStateTitle]:
    "Вы еще не зарегистрировали ни одного аккаунта WhatsApp.",
  [keys.CH_emptyStateHelptext]:
    "Чтобы начать регистрировать аккаунты WABA, попросите своих клиентов заполнить вашу индивидуальную форму рагистрации. Все аккаунты, отправленные через эту форму, будут отображаться здесь.",
  [keys.CH_emptyStateHelptextIO]:
    "Чтобы начать подключение новых учетных записей, обязательно внедрите Интегрированный Онбординг и попросите своих клиентов инициировать процесс настройки из вашего приложения.",
  [keys.CH_openSubmissionForm]: "Открыть регистрационную форму",
  [keys.CH_openIODoc]: "Прочитайте документацию по Интегрированному Онбордингу",
  [keys.CH_copyLink]: "Копировать ссылку",
  [keys.CH_copiedLink]: "Ссылка скопирована",
  [keys.CH_emptyStateTitleClient]:
    "Похоже, вы пока еще не закончили свою первоначальную регистрацию.",
  [keys.CH_emptyStateHelptextClient]:
    "Чтобы получить доступ к WhatsApp Business API, вам необходимо сначала создать аккаунт WhatsApp Business с помощью нашего процесса регистрации. Пожалуйста, строго следуйте приведенным ниже инструкциям.",
  [keys.CH_emptyStateButtonLabelClient]: "Завершить регистрацию",
  [keys.CH_noChannelsFound]:
    "По этому поисковому запросу результаты отсутствуют.",
  [keys.CH_setupProgress]: "Процесс установки",
  [keys.CH_showMore]: "Показать больше",
  [keys.CH_showLess]: "Показать меньше",
  [keys.CH_percentageDone]: "готово",
  [keys.CH_actionRequired]: "Необходимые действия",
  [keys.CH_noActionRequired]: "Никаких действий не требуется",
  [keys.CH_setupNumberRunning]: "Номер активирован",
  [keys.CH_setupVerificationStarted]: "Начата проверка бизнеса",
  [keys.CH_setupVerificationCompleted]: "Проверка бизнеса завершена",
  [keys.CH_setupDisplayNameApproved]: "Отображаемое имя утверждено",
  [keys.CH_setupCommercePolicyChecked]:
    "Проверка соответствия торговой политике завершена",
  [keys.CH_ctaBusinessRejected]:
    "Ваша заявка на проверку бизнеса была отклонена.",
  [keys.CH_ctaNumberSetup]:
    "Ваш номер в настоящее время настраивается. Это займет несколько минут. Пожалуйста, перезагрузите эту страницу приблизительно через 5 минут.",
  [keys.CH_ctaStartVerification]:
    "Перейдите к бизнес-менеджеру Meta и начните верификацию своего бизнеса.",
  [keys.CH_ctaVerificationPending]:
    "В настоящее время заявка на проверку вашего бизнеса находится на рассмотрении.",
  [keys.CH_ctaDisplayNameRejected]:
    "Ваше <LINK>https://developers.facebook.com/docs/whatsapp/guides/display-name / | отображаемое имя<LINK> было отклонено. Пожалуйста, воспользуйтесь Meta WhatsApp Manager или обратитесь в нашу службу поддержки, чтобы изменить его и  отправить повторно.",
  [keys.CH_ctaButtonLabelOpenWaManager]: "Открыть WhatsApp Manager",
  [keys.CH_ctaLiveMode]:
    "Поздравляю! Ваш аккаунт активирован, и у вас есть полный доступ к WhatsApp Buisness API.",
  [keys.CH_ctaButtonLabelFbm]: "Открыть бизнес-менеджер",
  [keys.CH_ctaButtonLabelStartVerification]: "Начать проверку бизнеса",
  [keys.CH_ctaButtonLabelOpenSettings]: "Открыть настройки",
  [keys.CH_ctaButtonLabelAppeal]: "Открыть форму апелляции",
  [keys.CH_ctaCommercePolicyFailed]:
    "Ваша компания не прошла <LINK>https://www.whatsapp.com/legal/commerce-policy/?lang=en | проверку на соответсвие торговой политике WhatsApp<LINK>. Если вы считаете, что ваша компания не нарушает эти правила, вы можете подать апелляцию.",
  [keys.CH_ctaPendingDeletion]:
    "Ваш номер ожидает удаления. Никаких дальнейших действий не требуется.",
  [keys.CH_tooltipSandbox]:
    "Узнать больше о пробном периоде (бизнес-менеджер не проходил верификацию)",
  [keys.CH_wabaPhotoChanged]:
    "Изображение бизнес-аккаунта Whatsapp успешно изменено.",
  [keys.CH_wabaProfile]: "Бизнес-профиль WhatsApp",
  [keys.CH_businessProfile]: "Бизнес-профиль",
  [keys.CH_changeProfilePic]: "Изменить изображение профиля WhatsApp",
  [keys.CH_profilePic]: "Изображение профиля",
  //  [keys.CH_urlErrorMessage]: "translation_missing",
  [keys.CH_portNumberButtonLabel]: "Перенести существующий номер к 360dialog",
  [keys.CH_portNumberText]:
    "Эта форма предназначена для иницииации переноса существующего телефонного номера в ваш аккаунт 360dialog. После предоставления номера и целевого аккаунта WhatsApp вы смодете пройти процесс миграции. Продолжив, вы подтверждаете, что предоставляете 360dialog доступ к новому номеру телефона и с вас может взиматься соответствующая плата.",
  [keys.CH_createNewWaba]: "Создать новый бизнес-аккаунт WhatsApp",
  [keys.CH_phoneNumberAlreadyExists]: "Этот номер телефона уже был добавлен.",
  [keys.CH_migrateNumberNextStepText]:
    "Пожалуйста, продолжите процесс переноса номера в новой вкладке, которая должна была открыться. В противном случае, пожалуйста, проверьте свой браузер на наличие блокировок всплывающих окон и <LINK>повторите попытку<LINK>.",
  [keys.CH_readyForMigrationCtaText]:
    "Пожалуйста, откройте страницу подтверждения и следуйте инструкциям, чтобы завершить перенос вашего номера.",
  [keys.CH_waitingForMigrationCtaText]:
    "Проверка номера еще не завершена. Пожалуйста, следуйте инструкциям на странице подтверждения, чтобы повторно отправить проверочный код. Этот шаг необходим для завершения переноса вашего номера.",
  [keys.CH_invalidBusinessId]:
    "Этот идентификатор бизнес-менеджера Meta (Business Manager ID) недействителен",
  [keys.CH_migratedNumberLabel]: "Перенесенный номер",
  [keys.CH_type]: "Тип",
  [keys.CH_retrySetupText]:
    "Ваша интеграция еще не началась. Скорее всего, из-за того, что двухфакторная аутентификация (2FA) все еще включена. Пожалуйста, повторите попытку настройки.",
  [keys.CH_setTerminationDate]: "Установить дату удаления",
  [keys.CH_successChannelAdded]: "Канал успешно добавлен",
  [keys.CH_addBusinessInfo]: "Добавить информацию о бизнесе",
  [keys.CH_businessInfoPrompt]:
    "Пожалуйста, не забудьте заполнить информацию о вашем бизнесе в бизнес-менеджере Meta, чтобы получить полный доступ к API. Вы уже прошли все проверки, но хотите увеличить лимиты обмена сообщениями?",
  [keys.CH_startBusinessVerification]: "Начать проверку бизнеса",
  [keys.CH_deletedChannel]: "Канал неактивен",
  [keys.CH_deletedChannelHelptextShort]:
    "Интеграция была удалена, и этот номер больше не активен. Он будет удален безвозвратно <date>.",
  [keys.CH_deletedChannelHelptext]:
    "Интеграция была удалена, и этот номер больше не активен. Он будет удален безвозвратно <date>. После этой даты он также больше не будет отображаться в dialog Client Hub.",
  [keys.CH_reEnableHelptext]:
    "Если вы хотите восстановить свой номер, пожалуйста, свяжитесь с <PARTNER>.",
  [keys.CH_partnerFallback]: "ваш партнер 360dialog",
  [keys.CH_recentSearches]: "История поиска",
  [keys.CH_applyFilter]: "Применить фильтр",
  [keys.CH_sortByDate]: "Сортировка по дате изменения",
  [keys.CH_searchBy]:
    "Поиск по номеру телефона, отображаемому имени или названию компании",
  [keys.CH_filters]: "Фильтры",
  [keys.CH_filterSingular]: "Фильтр",
  [keys.CH_variableCountError]: "Превышено количество разрешенных переменных.",
  [keys.CH_phoneNumber]: "Номер телефона",
  [keys.CH_clientEmail]: "Электронная почта клиента",
  [keys.CH_submissionType]: "Тип регистрации",
  [keys.CH_portedNumber]: "Перенесенный номер",
  [keys.CH_channelStatus]: "Статус канала",
  [keys.CH_pendingCancellation]: "Ожидающий аннулирования",
  [keys.CH_accountMode]: "Режим аккаунта",
  [keys.CH_errorNotEverythingLoaded]:
    "Не все компоненты страницы могут быть загружены. Пожалуйста, попробуйте снова. Если эта проблема не устранена, пожалуйста, обратитесь в службу поддержки.",
  [keys.CH_buttonLabelGotToSupport]: "Перейти в центр поддержки",
  [keys.CH_emptyStateOption]: "Этот поиск не дал результатов",
  [keys.CH_billingStatus]: "Статус выставления счетов",
  [keys.CH_cancelledOn]: "Аннулировано",
  [keys.CH_terminatedOn]: "Удалено",
  [keys.CH_startedOn]: "Тарифицируется с",
  [keys.CH_notStarted]: "Еще не тарифицируется",
  [keys.CH_compressedListView]: "Сжатый вид списка",
  [keys.CH_externalId]: "Внешний идентификатор (External ID)",
  [keys.CH_status]: "Статус",
  [keys.CH_deleteNumber]: "Отменить подписку",
  [keys.CH_deleteNumberText]:
    "Это поставит размещенный клиент WhatsApp Business API в очередь на удаление. После даты удаления, установленной в соответствии с условиями отмены подписки, номер больше не сможет отправлять или получать сообщения с использованием WhatsApp Business API. Примечание: номер останется видимым в списке номеров в 360dialog Hub, но счета после даты удаления выставляться не будут.",
  [keys.CH_deleteNumberTextTerms]:
    "Условия: Подписка может быть отменена с уведомлением за тридцать (30) дней до конца месяца.",
  [keys.CH_deletePopupText]:
    'Если вы хотите продолжить, пожалуйста, введите "ОТМЕНА" в поле ниже.',
  [keys.CH_confirmationFieldInputValue]: "ОТМЕНА",
  [keys.CH_deletePopup1]:
    "Вы уверены, что хотите отменить подписку для клиента WhatsApp Business API с номером телефона <NUMBER>?",
  [keys.CH_confirmDeleteNumberFBManagerText1]:
    "Отмена подписки поставит номер в очередь на удаление. Обратите внимание, что это не мгновенное удаление. Статус номера изменится на «Ожидание удаления», пока номер находится в стадии, он по-прежнему может отправлять и получать сообщения в обычном режиме.",
  [keys.CH_confirmDeleteNumberFBManagerText2]:
    "Номер будет безвозвратно удален в конце текущего месяца. В этот момент выставление счетов будет остановлено.",
  [keys.CH_confirmDeleteNumberFBManagerText3]:
    "Если вы хотите, чтобы этот номер был удален до указанного периода, перед подтверждением установите флажок «Немедленно удалить мой номер в Meta Business Manager».",
  [keys.CH_confirmDeleteNumberFBManager]:
    "Немедленно удалите мой номер в Meta Business Manager. Имейте в виду, что это действие автоматически создаст заявку в службу поддержки и приведет к удалению номера примерно в течение 48 часов. Он больше не будет доступен для использования с WhatsApp Business API. Это не отменяет обязательств по уплате лицензионных сборов до даты ее полного удаления согласно Условиям 360dialog.",
  [keys.CH_deleteNumberSuccess]:
    "Ваш номер будет удален из бизнес-менеджера Meta.",
  [keys.CH_messagingLimit]: "Лимиты обмена сообщениями",
  [keys.CH_qualityRating]: "Рейтинг качества",
  [keys.CH_learnHowDelete]: "Узнайте больше о том, как удалить ваш номер",
  [keys.CH_emailErrorMessage]:
    "Пожалуйста, введите действительный адрес электронной почты",
  [keys.CH_partnerButtonDisabled]:
    "В настоящее время это действие недоступно пользователям с правами партнера. Пожалуйста, попросите вашего клиента войти в dialog Client Hub с его логином и паролем, чтобы сгенерировать API ключ.",
  [keys.CH_usageAndBalance]: "Использование и баланс",
  [keys.CH_prepaidSettings]: "Настройки предоплаты",
  [keys.CH_lastRenewal]: "Последнее обновление",
  [keys.CH_usage]: "Использование",
  [keys.CH_paymentNotEnabled]:
    "Клиенту недоступна возможность внесения предоплаты",
  [keys.CH_hasInboxTooltip1]:
    "В настоящее время этот номер используется в сочетании с get.chat inbox. Поэтому вы не можете сгенерировать API ключ для использования 360dialog WhatsApp API. Однако вы можете использовать функции API через get.chat WhatsApp API.",
  [keys.CH_hasInboxTooltip2]:
    'Чтобы отключить get.chat inbox и создать API ключ 360dialog, перейдите на страницу cведений с помощью кнопки "Показать подробности".',
  [keys.CH_hasInboxActionBlock1]:
    "В настоящее время этот номер используется в сочетании с get.chat inbox. Поэтому вы не можете сгенерировать API ключ для использования 360dialog WhatsApp API. Однако вы можете использовать функции API через get.chat WhatsApp API.",
  [keys.CH_hasInboxActionBlock2]:
    "Вы можете отключить get.chat inbox ниже и после этого и создать API ключ 360dialog.",
  [keys.CH_disconnectInboxButtonLabel]: "Отключить get.chat inbox прямо сейчас",
  [keys.CH_disconnectNotificationSuccess]:
    "Ваш get.chat inbox был успешно отключен от этого номера",
  [keys.CH_disconnectNotificationError]:
    "Мы не смогли отключить ваш get.chat inbox. Пожалуйста, повторите попытку позже. Если эта проблема не устранена, пожалуйста, обратитесь в службу поддержки.",
  [keys.CH_openConsentPage]: "Открыть страницу подтвердждения согласия",
  [keys.CH_close]: "Закрыть",
  [keys.CH_accountViolation]: "Нарушение политики",
  [keys.CH_businessManagerDetails]: "Уточнить детали в Meta Business Manager",
  [keys.CH_accountRestriction]: "Возможности аккаунта ограничены",
  [keys.CH_noInformationAvailable]: "Информация отсутствует.",
  [keys.CH_restrictionText]:
    "Возможности вашего аккаунта были ограничены Meta.",
  [keys.CH_restrictionTextBusiness]:
    "Вам больше не разрешается отправлять исходящие сообщения от имени бизнеса.",
  [keys.CH_restrictionTextUser]:
    "Вам больше не разрешается отвечать на сообщения от пользователей.",
  [keys.CH_restrictionTextNumber]:
    "Вам больше не разрешается добавлять новые телефонные номера.",
  [keys.CH_clientName]: "Имя клиента",
  [keys.CH_premiumPlanPricing]: "Цена премиального плана",
  [keys.CH_standardPlanPricing]: "Цена стандартного плана",
  [keys.CH_upgrade]:
    "Выполнив следующие шаги, вы повысите план обслуживания для номера <NUMBER>.",
  [keys.CH_downgrade]:
    "Выполнив следующие шаги, вы понизите плана обслуживания для номера <NUMBER>.",
  [keys.CH_stantard]: "Стандарт",
  [keys.CH_premium]: "Премиум",
  [keys.CH_month]: "Месяц",
  [keys.CH_billedMonthly]: "Счета выставляются ежемесячно",
  [keys.CH_unlimitedIncomingMsg]:
    "Неограниченное количество входящих и исходящих сообщений WhatsApp в рамках сессии",
  [keys.CH_noMarkup]:
    "Переписки, инициированные пользователями и бизнесом, в соответствии с <LINK>https://developers.facebook.com/docs/whatsapp/pricing | прайс-листом WhatsApp<LINK>. Без наценок.",
  [keys.CH_98serviceLevel]: "98% Hosting Service Level",
  [keys.CH_99serviceLevel]: "99% Hosting Service Level",
  [keys.CH_premiumSupport]:
    "Поддержка 7 дней в неделю (круглосуточно с понедельника по пятницу, с 9:00 до 17:00 CET в субботу и воскресенье)",
  [keys.CH_standardSupport]:
    "Поддержка в будние дни (понедельник - пятница с 2:00 до 22:00 CET)",
  [keys.CH_premiumSLA]:
    "SLA поддержки до < 30 минут (для подходящих типов запросов)",
  [keys.CH_standardSLA]:
    "SLA поддержки до < 4 часов (для подходящих типов запросов)",
  [keys.CH_costBreakdown]: "Перейти к структуре затрат",
  [keys.CH_description]: "Описание",
  [keys.CH_qty]: "Кол-во",
  [keys.CH_amount]: "Сумма",
  [keys.CH_estimatedCost]:
    "Предполагаемая ежемесячная стоимость вашей подписки, исключая расходы на переписки и возможную частичную оплату за текущий месяц, будет следующей.",
  [keys.CH_unitPrice]: "Цена за шт.",
  [keys.CH_standardPricing]: "Стандартная цена",
  [keys.CH_premiumPricing]: "Премиальная цена",
  [keys.CH_premiumAccount]: "Премиум-аккаунт",
  [keys.CH_lastDowngradeTooltip1]:
    "План обслуживания аккаунта может быть повышен спустя 30 дней после последнего понижения. Последнее понижение плана обслуживания произошло <DATE>.",
  [keys.CH_lastDowngradeTooltip2]:
    "Следущее возможное повщение плана обслуживания может быть инициировано <DATE>.",
  [keys.CH_lastUpgradeTooltip1]:
    "План обслуживания аккаунта может быть понижен спустя 30 дней после последнего повышения. Последнее повышения плана обслуживания произошло <DATE>.",
  [keys.CH_lastUpgradeTooltip2]:
    "Следующее возможное понижение плана обслуживания может быть инициировано <DATE>.",
  [keys.CH_reRegisterNumber]: "Повторить регистрацию номера",
  [keys.CH_reRegisterNumberText]:
    "Ваш номер вернулся в статус незарегистрированного в Meta. Чтобы продолжить пользоваться нашими сервисами для обмена сообщениями, вам необходимо повторно зарегистрировать его. Нажмите кнопку “Повторить регистрацию номера” и следуйте инструкциям.",
  [keys.CH_saveComment]: "Сохранить комментарий",
  [keys.CH_activityLogs]: "Журнал активности",
  [keys.CH_admin]: "Администратор",
  [keys.CH_dateAndTime]: "Дата и время",
  [keys.CH_actor]: "Субъект",
  [keys.CH_action]: "Действие",
  [keys.CH_businessAccount]: "Бизнес-аккаунт",
  [keys.CH_profilePreview]: "Предварительный просмотр профиля",
  [keys.CH_noLogsYet]: "Журнал пока пуст",
  [keys.CH_noLogsYetDescription]:
    "В настоящее время в журнале активности отсутствуют записи для отображения.",
  [keys.CH_useFilteredData]: "Использовать отфильтрованные данные?",
  [keys.CH_selectDataset]:
    "Пожалуйста, выберите выгрузку полного набора данных или выгрузку с применением выбранных фильтров.",
  [keys.CH_filteredExportTitle]: "Выгрузка с применением фильтров",
  [keys.CH_filteredExport]: "Выгрузка отфильтрованного набора данных",
  [keys.CH_fullExportTitle]: "Полная выгрузка",
  [keys.CH_fullExport]: "Выгрузка полного набора данных",
  [keys.CH_contactSupport]: "Обратитесь в службу поддержки",
  [keys.CH_contactSupportText]:
    "Настройка вашего номера происходит неожиданно медленно. Пожалуйста, свяжитесь с нашей службой поддержки для получения помощи.",
  [keys.CH_proceedWithRegistration]:
    "Ваш номер еще не зарегистрирован в WhatsApp API, пожалуйста, пройдите регистрацию, чтобы пользоваться нашими услугами.",
  [keys.CH_readyToRegister]: "Готов к регистрации",
  [keys.CH_addNumberDisabledTwoNumbers]:
    "В настоящее время вы не можете добавлять дополнительные номера в свою учетную запись. Если вам нужно больше номеров, пожалуйста, свяжитесь с нашей службой поддержки.",
  [keys.CH_addNumberDisabledTwoNumbers2]:
    "В данный момент вы не можете добавлять больше номеров в свою учетную запись. Если вам необходимо добавить больше номеров, свяжитесь, пожалуйста, с вашим партнером по интеграции или нашей поддержкой через hub.360dialog.com",
  // [keys.CH_addNumberLimitExceeded]: "translation_missing",
  [keys.CH_lockedTitle]: "Ваш аккаунт временно заблокирован",
  [keys.CH_lockedText]:
    "Мы обнаружили подозрительную активность на одном из ваших телефонных номеров WhatsApp и поэтому временно заблокировали вашу учетную запись. Пожалуйста, убедитесь, что вы оплатили все неоплаченные счета за абонентскую плату, а также расходы на разговоры, чтобы продолжить обмен сообщениями. Если вы считаете, что это было сделано по ошибке, и хотите снова активировать свою учетную запись, обратитесь в нашу службу поддержки.",
  [keys.CH_ctaPendingReview]:
    "Ваша учетная запись в настоящее время проверяется нашей командой. Это не должно занимать более 2 часов. Пока процесс проверки не будет завершен, вы не можете отправлять какие-либо шаблонные сообщения. Вы по-прежнему можете получать сообщения и отвечать на инициированные пользователем разговоры.",
  [keys.CH_apiKeyDisabledMessage]:
    "Ключи API отключены для этого номера. Номер был передан вашему партнеру по интеграции. С вашего разрешения партнер имеет доступ к WhatsApp Business API от вашего имени. Для управления разрешениями перейдите в «Настройки организации» или обратитесь к своему партнеру.",
  [keys.CH_emptyStateHelptextClientIO]:
    "Чтобы получить доступ к WhatsApp Business API, вам необходимо сначала создать учетную запись WhatsApp Business. Продолжите процесс настройки через приложение вашего партнера.",
  [keys.CH_successfullyAddedNumber]:
    "Ваш номер был успешно добавлен. Мы настраиваем его, и через несколько минут он появится на этой странице. Вы получите уведомление здесь и по электронной почте.",
  [keys.CH_numberNowLive]: "Ваш номер теперь активен",
  [keys.CH_generateApiKeyNow]: "Сгенерировать API ключ сейчас",
  [keys.CH_settingUpNumber]:
    "В настоящее время мы настраиваем ваш номер, и это может занять несколько минут. Вы будете уведомлены по электронной почте, когда ваш номер будет полностью настроен. После этого, пожалуйста, продолжайте регистрацию номера.",
  [keys.CH_accountDeletionCheckbox]:
    "Mir ist klar, dass meine Nummer am Ende des aktuellen Abonnementzeitraums deaktiviert wird.",
  [keys.CH_deleteAccount]: "Удалить учетную запись",
  [keys.CH_dataNotAccessible]:
    "Данные на этой странице недоступны для вашего пользователя",
  [keys.CH_addNumberDisabledSandbox]:
    "Вы достигли своего максимального количества в 2 тестовых номера. Чтобы добавить новые номера, перейдите в режим полноценный режим с вашими существующими номерами.",
  [keys.CH_hostingPlatformType]: "Тип хостинговой платформы",
  //  [keys.CH_aboutTextRequired]: "translation_missing",
  [keys.CH_synced]: "Синхронизировано",
  //  [keys.CH_wabaType]: "translation_missing",
  //  [keys.CH_disableTracker]: "translation_missing",
  //  [keys.CH_trackerNotificationContent]: "translation_missing",
  //  [keys.CH_trackingBannerContent]: "translation_missing",
  //  [keys.CH_deleteTitle]: "translation_missing",
  //  [keys.CH_goBack]: "translation_missing",
  //  [keys.CH_activateInbox]: "translation_missing",
  //  [keys.CH_verifyBusiness]: "translation_missing",
  //  [keys.CH_contactPartner]: "translation_missing",
  //  [keys.CH_cancelPayment]: "translation_missing",
  //  [keys.CH_cancelPaymentInfo]: "translation_missing",
  //  [keys.CH_pleaseLimitDescription]: "translation_missing",
  //  [keys.CH_addNumberDisabledTwoNumbersAlt]: "translation_missing",
  //  [keys.CH_currentPlanNotSupportChangingHostingPlatform]: "translation_missing",
  //  [keys.CH_pleaseBeAwareMigrationBack]: "translation_missing",
  //  [keys.CH_signalDisabledMsg]: "translation_missing",
  //  [keys.CH_inboxDisabledMsg]: "translation_missing",
  //  [keys.CH_partnerApiKeyPermission]: "translation_missing",
  //  [keys.CH_subscriptionPlan]: "translation_missing",
  //  [keys.CH_changePlan]: "translation_missing",
  //  [keys.CH_downgradeScheduleInfo]: "translation_missing",
  //  [keys.CH_upgradeScheduleInfo]: "translation_missing",
  //  [keys.CH_subscriptionSuccessful]: "translation_missing",
  //  [keys.CH_selectSubscription]: "translation_missing",
  //  [keys.CH_subscriptionSelectionInfo]: "translation_missing",
  //  [keys.CH_savingPlan]: "translation_missing",
  //  [keys.CH_savePlan]: "translation_missing",
  //  [keys.CH_downgradeDisabledTooltip]: "translation_missing",
  //  [keys.CH_pendingClientApprovalInfo]: "translation_missing",
  //  [keys.CH_verifyOwnership]: "translation_missing",
  //  [keys.CH_pendingClientApprovalInfoNoClientInfo]: "translation_missing",
  //  [keys.CH_viewDocumentation]: "translation_missing",
  //  [keys.CH_pendingClientApprovalInfoWithClientInfo]: "translation_missing",
  //  [keys.CH_clientUserApproved]: "translation_missing",
  //  [keys.CH_channelVerified]: "translation_missing",
  //  [keys.CH_wabaWebhookUrlError]: "translation_missing",
  //  [keys.CH_hostingType]: "translation_missing",
  //  [keys.CH_partnerPermitted]: "translation_missing",
  //  [keys.CH_partnerNotPermitted]: "translation_missing",
  //  [keys.CH_missing]: "translation_missing",
  //  [keys.CH_shared]: "translation_missing",
  //  [keys.CH_managePermissions]: "translation_missing",
  //  [keys.CH_planRecommended]: "translation_missing",
  //  [keys.CH_planBasic]: "translation_missing",
  //  [keys.CH_planRegular]: "translation_missing",
  //  [keys.CH_planPremium]: "translation_missing",
  //  [keys.CH_planMonth]: "translation_missing",
  //  [keys.CH_planIncludes]: "translation_missing",
  //  [keys.CH_planPlus]: "translation_missing",
  //  [keys.CH_planCloudHosting]: "translation_missing",
  //  [keys.CH_planNinetyServiceLevel]: "translation_missing",
  //  [keys.CH_planTicketBased]: "translation_missing",
  //  [keys.CH_planOnPremiseHosting]: "translation_missing",
  //  [keys.CH_planWabaOfficial]: "translation_missing",
  //  [keys.CH_planHighServiceLevel]: "translation_missing",
  //  [keys.CH_planIncludesAdsFunnel]: "translation_missing",
  //  [keys.CH_planSelect]: "translation_missing",
  //  [keys.CH_planSelected]: "translation_missing",
  //  [keys.CH_active]: "translation_missing",
  //  [keys.CH_manageWaba]: "translation_missing",
  //  [keys.CH_numberDetails]: "translation_missing",
  //  [keys.CH_numberBusinessAccount]: "translation_missing",
  //  [keys.CH_funds]: "translation_missing",
  //  [keys.CH_plan]: "translation_missing",
  //  [keys.CH_accountName]: "translation_missing",
  //  [keys.CH_dataRegions]: "translation_missing",
  //  [keys.CH_numberQuality]: "translation_missing",
  //  [keys.CH_numberMessagingLimit]: "translation_missing",
  //  [keys.CH_low]: "translation_missing",
  //  [keys.CH_medium]: "translation_missing",
  //  [keys.CH_high]: "translation_missing",
  //  [keys.CH_numberPending]: "translation_missing",
  //  [keys.CH_unlimited]: "translation_missing",
  //  [keys.CH_newConversationsPerDay]: "translation_missing",
  //  [keys.CH_onlyAvailableCloudNumbers]: "translation_missing",
  //  [keys.CH_qualityRatingTooltipOne]: "translation_missing",
  //  [keys.CH_qualityRatingTooltipTwo]: "translation_missing",
  //  [keys.CH_qualityRatingTooltipStatus]: "translation_missing",
  //  [keys.CH_qualityRatingTooltipPending]: "translation_missing",
  //  [keys.CH_qualityRatingTooltipHigh]: "translation_missing",
  //  [keys.CH_qualityRatingTooltipMedium]: "translation_missing",
  //  [keys.CH_qualityRatingTooltipLow]: "translation_missing",
  //  [keys.CH_messagingLimitTooltip]: "translation_missing",
  //  [keys.CH_messagingLimitTooltipLimits]: "translation_missing",
  //  [keys.CH_numberQualityRating]: "translation_missing",
  //  [keys.CH_learnMore]: "translation_missing",
  //  [keys.CH_numberMessagingLimitTitle]: "translation_missing",
  //  [keys.CH_seeMore]: "translation_missing",
};

export default translate;
