// File generated automatically. Do not edit manually.
const keys: { [k: string]: string } = {
  NOT_allNotifications: "NOT_allNotifications",
  NOT_archivedNotifications: "NOT_archivedNotifications",
  NOT_closeButtonTooltip: "NOT_closeButtonTooltip",
  NOT_archiveAllBtn: "NOT_archiveAllBtn",
  NOT_noNewNotifications: "NOT_noNewNotifications",
  NOT_clientAdded: "NOT_clientAdded",
  NOT_clientAddedWithMigrationDate: "NOT_clientAddedWithMigrationDate",
  NOT_rejectionReason: "NOT_rejectionReason",
  NOT_alreadyApproved: "NOT_alreadyApproved",
  NOT_apiKeyRecommendation: "NOT_apiKeyRecommendation",
  NOT_generateNewAPIKey: "NOT_generateNewAPIKey",
  NOT_partnerRequestFailed: "NOT_partnerRequestFailed",
  NOT_partnerRequestCancelled: "NOT_partnerRequestCancelled",
  NOT_needCaptcha: "NOT_needCaptcha",
  NOT_errorUpdatingChatSupport: "NOT_errorUpdatingChatSupport",
  NOT_approved: "NOT_approved",
  NOT_pending: "NOT_pending",
  NOT_affectedNumbers: "NOT_affectedNumbers",
  NOT_allNumbersAffected: "NOT_allNumbersAffected",
  NOT_minutesAgo: "NOT_minutesAgo",
  NOT_hoursAgo: "NOT_hoursAgo",
  NOT_emptyStateMessage: "NOT_emptyStateMessage",
};

export default keys;
