import Head from "next/head";
import config from "@/config";

const Meta = ({ title, keywords, description }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="keywords" content={keywords} />
      <meta name="description" content={description} />
    </Head>
  );
};

Meta.defaultProps = {
  title: "360dialog",
  keywords: "next.js, jest testing, babel",
  description: config.SEOMetaDescription,
};

export default Meta;
