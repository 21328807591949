// File generated automatically. Do not edit manually.
import app from "./app/ru";
import auth from "./auth/ru";
import billing from "./billing/ru";
import channels from "./channels/ru";
import dashboard from "./dashboard/ru";
import errors from "./errors/ru";
import facebook from "./facebook/ru";
import faq from "./faq/ru";
import general from "./general/ru";
import inbox from "./inbox/ru";
import key from "./key/ru";
import languages from "./languages/ru";
import migrate from "./migrate/ru";
import navigation from "./navigation/ru";
import onboarding from "./onboarding/ru";
import preferences from "./preferences/ru";
import settings from "./settings/ru";
import support from "./support/ru";
import templates from "./templates/ru";
import wizard from "./wizard/ru";
import notifications from "./notifications/ru";
import appconnection from "./appconnection/ru";
import signals from "./signals/ru";
import insights from "./insights/ru";
import funnels from "./funnels/ru";
import integrations from "./integrations/ru";
import numbers from "./numbers/ru";
import audiences from "./audiences/ru";
import tour from "./tour/ru";
import tos from "./tos/ru";

const allTranslations: { [k: string]: string } = {
  ...app,
  ...auth,
  ...billing,
  ...channels,
  ...dashboard,
  ...errors,
  ...facebook,
  ...faq,
  ...general,
  ...inbox,
  ...key,
  ...languages,
  ...migrate,
  ...navigation,
  ...onboarding,
  ...preferences,
  ...settings,
  ...support,
  ...templates,
  ...wizard,
  ...notifications,
  ...appconnection,
  ...signals,
  ...insights,
  ...funnels,
  ...integrations,
  ...numbers,
  ...audiences,
  ...tour,
  ...tos,
};

export default allTranslations;
