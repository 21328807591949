import { createContext } from "react";

import { ListState } from "@/store/listState";
import { UIModelProjects } from "@/services/api/projects/project.model";
import { UIModelWabaAccounts } from "@/services/api/wabaAccount/wabaAccount.model";

const projectsState = new ListState<UIModelProjects>({
  withoutAppId: true,
  limit: 20,
  offset: 0,
  sortFields: ["-modifiedAt"],
  apiDataInitial: {
    projects: [],
    total: 0,
  },
  filtersLoadSave: {},
});

const wabasAccountState = new ListState<UIModelWabaAccounts>({
  withoutAppId: true,
  limit: 20,
  offset: 0,
  sortFields: ["-modifiedAt"],
  apiDataInitial: {
    wabaAccounts: [],
    total: 0,
  },
  filtersLoadSave: {},
});

export const ProjectsAndWabaAccountState = createContext<{
  projectsState: ListState<UIModelProjects>;
  wabasAccountState: ListState<UIModelWabaAccounts>;
}>(
  {} as {
    projectsState: ListState<UIModelProjects>;
    wabasAccountState: ListState<UIModelWabaAccounts>;
  }
);

const WabaListStateStoreProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <ProjectsAndWabaAccountState.Provider
      value={{
        projectsState,
        wabasAccountState,
      }}
    >
      {children}
    </ProjectsAndWabaAccountState.Provider>
  );
};

export default WabaListStateStoreProvider;
