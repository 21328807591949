import * as Types from "./public.types";

export const unpackOutageEvent = (
  data: Array<Types.IServerResponseOutage>
): Array<Types.IServerResponseOutage> =>
  data.map((feed) => ({
    title: feed.title,
    description: feed.description,
    date: feed.date,
  }));
