// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  //  [keys.TOUR_eventFunnelTitle]: "translation_missing",
  //  [keys.TOUR_eventFunnelDesc]: "translation_missing",
  //  [keys.TOUR_addStepsTitle]: "translation_missing",
  //  [keys.TOUR_addStepsDesc]: "translation_missing",
  //  [keys.TOUR_highlightCardsTitle]: "translation_missing",
  //  [keys.TOUR_highlightCardsDesc]: "translation_missing",
  //  [keys.TOUR_understandSourcesTitle]: "translation_missing",
  //  [keys.TOUR_understandSourcesDescTitle]: "translation_missing",
  //  [keys.TOUR_understandSourcesDescOne]: "translation_missing",
  //  [keys.TOUR_understandSourcesDescTwo]: "translation_missing",
  //  [keys.TOUR_understandSourcesDescThree]: "translation_missing",
  //  [keys.TOUR_understandSourcesDescFour]: "translation_missing",
  //  [keys.TOUR_dateFiltersTitle]: "translation_missing",
  //  [keys.TOUR_dateFiltersDesc]: "translation_missing",
  [keys.TOUR_audiencesAdAccountsTitle]: "Contas de Anúncios",
  [keys.TOUR_audiencesAdAccountsDescription]:
    "Alterne entre as contas de anúncios para ver seus públicos.",
  [keys.TOUR_audiencesAdAccountsTosMissingDescription]:
    "Alterne entre as contas de anúncios para ver seus públicos. Esta conta de anúncios ainda não aceitou os Termos de Serviço do Meta.",
  [keys.TOUR_audiencesMetasTosTitle]: "Termos de Serviço da Meta",
  [keys.TOUR_audiencesMetasTosDescription]:
    "Você deve aceitar os Termos de Serviço do Meta para ver seu público.",
  [keys.TOUR_audiencesLeadsInteractionsTitle]: "Interações de Leads",
  [keys.TOUR_audiencesLeadsInteractionsDescription]:
    "Performance Audiences é um recurso que direciona usuários com base em suas interações com seu negócio. No lado esquerdo, você pode ver qual foi a interação.",
  [keys.TOUR_audiencesRedirectLinkTitle]: "Link de Redirecionamento",
  [keys.TOUR_audiencesRedirectLinkDescription]:
    "Clique aqui e confira no site da Meta.",
};

export default translate;
