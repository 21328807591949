// File generated automatically. Do not edit manually.
import keys from "./keys";

const translate: { [k: string]: string } = {
  [keys.TMP_name]: "Name",
  [keys.TMP_cat]: "Category",
  [keys.TMP_preview]: "Preview",
  [keys.TMP_lang]: "Languages",
  [keys.TMP_tooltipAddLang]: "Add language",
  [keys.TMP_tooltipCopy]: "Duplicate",
  [keys.TMP_addTemplateButtonLabel]: "Add template",
  [keys.TMP_emptyStateTitle]: "You haven’t created any message templates yet.",
  [keys.TMP_emptyStateHelptext]:
    "Message templates can be used to send out notifications to people that have given you permission to send them messages. You can use message templates to start a conversation with a client after the 24-hour window for session messages has closed.",
  [keys.TMP_cardHeader]: "New template",
  [keys.TMP_namePlaceholder]: "Template name",
  [keys.TMP_nameLabel]: "Name",
  [keys.TMP_cat1]: "Auto Reply",
  [keys.TMP_cat2]: "Account Update",
  [keys.TMP_cat3]: "Payment Update",
  [keys.TMP_cat4]: "Personal Finance Update",
  [keys.TMP_cat5]: "Shipping Update",
  [keys.TMP_cat6]: "Reservation Update",
  [keys.TMP_cat7]: "Issue Resolution",
  [keys.TMP_cat8]: "Appointment Update",
  [keys.TMP_cat9]: "Transportation Update",
  [keys.TMP_cat10]: "Ticket Update",
  [keys.TMP_cat11]: "Alert Update",
  [keys.TMP_langLabel]: "Language",
  [keys.TMP_typeLabel]: "Template type",
  [keys.TMP_type1]: "Standard (text only)",
  [keys.TMP_type2]: "Media & Interactive",
  [keys.TMP_bodyPlaceholder]: "Type your template body text here...",
  [keys.TMP_blocksHelptext]: "Select message template building blocks.",
  [keys.TMP_header]: "Header",
  [keys.TMP_body]: "Body",
  [keys.TMP_footer]: "Footer",
  [keys.TMP_button]: "Button",
  [keys.TMP_buttons]: "Buttons",
  [keys.TMP_chars]: "characters",
  [keys.TMP_vars]: "variables",
  [keys.TMP_headerMedia1]: "Text",
  [keys.TMP_headerMedia2]: "Image",
  [keys.TMP_headerMedia3]: "Document",
  [keys.TMP_headerMedia4]: "Video",
  [keys.TMP_footerPlaceholder]: "Type your template footer text here...",
  [keys.TMP_headerPlaceholder]: "Type your template header text here...",
  [keys.TMP_quickReply]: "Quick reply",
  [keys.TMP_cta]: "Call to action",
  [keys.TMP_phoneNumber]: "Phone number",
  [keys.TMP_url]: "Url",
  [keys.TMP_addButtonLabel]: "Add button",
  [keys.TMP_discardButtonLabel]: "Discard changes",
  [keys.TMP_previewButtonLabel]: "Preview and submit",
  [keys.TMP_buttonLabelPlaceholder]: "Start typing your button label here...",
  [keys.TMP_buttonVarHelpText]: "(must be at the end of text)",
  [keys.TMP_previewHeader1]: "Template",
  [keys.TMP_previewHeader2]: "Preview",
  [keys.TMP_exampleValues]: "Example values",
  [keys.TMP_varEmptyStateHelptext1]:
    "You haven't used any variable placeholders in your text.",
  [keys.TMP_varEmptyStateHelptext2]:
    "In order to add a variable, go back to the editor and insert {{#}} at the given position, where # represents the variable index, which needs to start at {{1}}.",
  [keys.TMP_varEmptyStateHelptext3]:
    "For more information please refer to our Template Manager Documentation.",
  [keys.TMP_docsLinkLabel]: "Template Manager Documentation",
  [keys.TMP_saveSuccess]: "Template has been successfully saved!",
  [keys.TMP_namePatternError]:
    "Name can only contain lowercase alphanumeric characters and underscores ( _ )",
  [keys.TMP_backToList]: "Back to list",
  [keys.TMP_backToEditor]: "Back to editor",
  [keys.TMP_submit]: "Submit template",
  [keys.TMP_headerImgPlaceholder]: "Header image URL",
  [keys.TMP_buttonUrlVarPlaceholder]: "Button URL",
  [keys.TMP_headerDocumentPlaceholder]: "Document URL",
  [keys.TMP_tooltipDelete]: "Delete template",
  [keys.TMP_deleteAllButtonLabel]: "Delete all template languages",
  [keys.TMP_deleteConfirmText]:
    "Are you sure you want to delete all language templates with the name <TEMPLATE_NAME>?",
  [keys.TMP_deleteAllConfirmButtonLabel]: "Delete all",
  [keys.TMP_deleteAllSuccess]:
    "All template languages have been deleted succesfully!",
  [keys.TMP_deleteSingleSuccess]: "Template was succesfully deleted!",
  [keys.TMP_buttonsCounter]: "buttons",
  [keys.TMP_pleaseNote]: "Please note:",
  [keys.TMP_helperBulletText1]:
    "If that message template name exists in multiple languages, all languages will be deleted.",
  [keys.TMP_helperBulletText2]:
    "Messages that have been sent but not delivered will attempt to be delivered for 30 days.",
  [keys.TMP_helperBulletText3]:
    'If a message from an approved message template is sent 30 days after deletion, you will receive a "Structure Unavailable" error and the customer will not receive the message.',
  [keys.TMP_helperBulletText4]:
    "Once deleted, the name of an approved template cannot be used again for 30 days. Use a different name to create future templates.",
  [keys.TMP_sampleValueInfoText]:
    "The provided example values will be submitted to Meta as part of the template submission process. If no relevant examples are provided, templates may be rejected by Meta.",
  [keys.TMP_missingExamplesError]:
    "Please provide examples for all your variables.",
  [keys.TMP_buttonPreviewHelptext]:
    "Click the button in the preview to validate your URL.",
  [keys.TMP_syncTemplates]: "Synchronize with Meta",
  [keys.TMP_successSyncTemplates]: "Success! Templates are up to date!",
  [keys.TMP_syncDone]: "Templates are up to date.",
  [keys.TMP_justNow]: "Just now",
  [keys.TMP_lastSync]: "Last synchronization:",
  [keys.TMP_headerVideoPlaceholder]: "Header video URL",
  [keys.TMP_errorInvalidUrl]:
    "The provided example URL does not link to a valid media file. Please correct the URL and try again.",
  [keys.TMP_nameProposal]: "Use <PROPOSAL> instead",
  [keys.TMP_status]: "Status",
  [keys.TMP_balance]: "Balance",
  [keys.TMP_autoRenewalStatus]: "Auto-renewal Enabled",
  [keys.TMP_autoRenewalThreshold]: "Auto-renewal Threshold",
  [keys.TMP_autoRenewalAmount]: "Auto-renewal Amount",
  [keys.TMP_addFunds]: "Add Funds",
  [keys.TMP_editAutoRenewalAmount]: "Edit auto-renewal amount",
  [keys.TMP_editAutoRenewalThreshold]: "Edit auto-renewal threshold",
  [keys.TMP_errorInvalidCharacter]: "Invalid character",
  [keys.TMP_errorImageExtensions]: "Allowed file extensions are JPG and PNG",
  [keys.TMP_errorVideoExtensions]: "Allowed file extensions are MP4 and 3gpp",
  [keys.TMP_buttonAddVariable]: "Add variable",
  [keys.TMP_newLineFooterWarning]:
    "The footer should not contain a line break.",
  [keys.TMP_emojiWarning]: "Emojis can only be placed in the body",
  [keys.TMP_searchPlaceholder]: "Search by name",
  [keys.TMP_cat12]: "Transactional",
  [keys.TMP_cat13]: "Marketing",
  [keys.TMP_cat14]: "One-time passwords",
  [keys.TMP_tooltipReplicate]: "Replicate template",
  [keys.TMP_tooltipAddLangToTemplate]: "Add different language to a template",
  [keys.TMP_tooltipEditTemplate]: "Edit template",
  [keys.TMP_tooltiMetaSync]:
    "This status shows if a template was already submitted to Meta or if recent changes to a template are still waiting to be submitted",
  [keys.TMP_ditFieldDisabled]: "Field Cannot be edited",
  [keys.TMP_editSuccess]:
    "Template has been successfully updated! Based on Meta restrictions, there can be a delay up to 24 hours before the changes are synced with Meta.",
  [keys.TMP_editFieldDisabled]:
    "This field cannot be edited, template already submitted",
  [keys.TMP_sampleRequiredValueInfoText]:
    "The provided example values will be submitted to Meta as part of the template submission process. It is required to add example values for all variables in order to submit the template.",
  [keys.TMP_metaSync]: "Sync Status (Meta)",
  [keys.TMP_cat15]: "Utility",
  [keys.TMP_cat16]: "Authentication",
  [keys.TMP_categoryTooltip_1]:
    "Meta assigned all existing templates a <LINK>https://developers.facebook.com/docs/whatsapp/updates-to-pricing/launch-timeline?content_id=VXiW9EWvTRnVVQ4#new-template-categories | new template category<LINK> based on their content and <LINK>https://developers.facebook.com/docs/whatsapp/updates-to-pricing/new-template-guidelines | template guidelines.<LINK> This process only affects a template's category. It has no effect on template status.",
  [keys.TMP_categoryTooltip_2]:
    "If you disagree with the assigned category, you can appeal the categorization until May 15, 2023 by selecting the template and clicking the Edit category button in <LINK>Meta's WhatsApp Manager<LINK>",
  [keys.TMP_categoryTooltip_3]:
    "To replicate your templates to new category types, please click on the replicate icon located on the right of the template card, change the name and the category of the template and re-submit it.",
  [keys.TMP_categoryRejectionTooltip_1a]:
    "This template contains content that does not comply with WhatsApp's policies.",
  [keys.TMP_categoryRejectionTooltip_1b]:
    "We recommend you to read the <LINK>https://developers.facebook.com/docs/whatsapp/message-templates/guidelines/ | Template<LINK> and <LINK>https://developers.facebook.com/docs/whatsapp/updates-to-pricing/new-template-guidelines | Template category<LINK> guidelines, edit the template and re-submit it for review.",
  [keys.TMP_categoryRejectionTooltip_2_embedded]:
    "If you believe that this is incorrect, you can request a review in <LINK>Account Quality.<LINK>",
  [keys.TMP_categoryRejectionTooltip_2_classic]:
    "If you believe that this is incorrect, you can request a review",
  [keys.TMP_categoryRejectionTooltip_3]:
    "You can also replicate the template, click on the replicate icon located on the right\n of the template card, change the name, category(if required), and the content of\n the template and re-submit it.",
  [keys.TMP_allowCategoryChange]: "Allow Category Change",
  [keys.TMP_headerMedia5]: "Location",
  [keys.TMP_allowCategoryChangeTooltip_1]:
    "Enabling category change allows Meta to assign whatever category they determine to be appropriate. We recommend having this enabled to avoid rejected templates due to miscategorization.",
  [keys.TMP_allowCategoryChangeTooltip_2]:
    "Please note that templates can still get rejected for other reasons unrelated to their categorization.",
  [keys.TMP_headerLocationPlaceholder]: "Location URL",
  [keys.TMP_timeIndicatorTooltip]:
    "This value would indicate the number of minutes the password or code is valid. Minimum 1 and maximum 90 minutes.",
  [keys.TMP_securityDesclaimer]: "Security desclaimer",
  [keys.TMP_expirationWarning]: "Expiration warning",
  [keys.TMP_timeIndicator]: "Time Indicator",
  [keys.TMP_timeIndicatorErrorMessage]:
    "Value must be between 1 and 90 minutes inlcusive",
  [keys.TMP_textCopiedToClipboard]: "Text copied to clipboard",
  [keys.TMP_seeAllOptions]: "See all options",
};

export default translate;
