export default function removeNullValues(obj: { [k: string]: any }) {
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === "object" && Object.keys(val).length > 0) {
      removeNullValues(val);
    } else if (
      val === null ||
      (typeof val === "object" && Object.keys(val).length === 0)
    ) {
      delete obj[key];
    }
  });
  return obj;
}
